var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import InputTitle from './InputTitle';
import { TextOverflow } from '../TextOverflow';
import clsx from 'clsx';
import { useInputStyles } from '../../styles';
//import ExpandMore from "@material-ui/icons/ExpandMore";
//const useStyles = makeStyles((theme) => ({
//  icon: {
//    "& .MuiSelect-icon": {
//      color: theme.palette.secondary.main,
//    },
//  },
//}));
export var FormikMultiSelect = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g;
    //  const classes = useStyles();
    var _h = props.helperText, helperText = _h === void 0 ? '' : _h, onChange = props.onChange, _j = props.margin, margin = _j === void 0 ? 'dense' : _j, _k = props.fullWidth, fullWidth = _k === void 0 ? true : _k, _l = props.variant, variant = _l === void 0 ? 'standard' : _l, title = props.title;
    var classes1 = useInputStyles();
    var _m = __read(useField(props), 3), field = _m[0], meta = _m[1], helpers = _m[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    var titlePlace = props.placeholder || (typeof title === 'string' && title) || null;
    var errorText = touchedError || helperText;
    return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(TextField, __assign({ id: props.id || (props.title || 'text-field-input').toString().replace(/ /gi, '').toLowerCase(), select: true, SelectProps: {
                multiple: true,
                value: props.value,
                onChange: _onChange,
            }, autoComplete: "off", placeholder: titlePlace, title: titlePlace, variant: variant, margin: margin, fullWidth: fullWidth }, field, props, { className: clsx((_a = {}, _a[classes1.adorRootV18] = props.v18, _a), (_b = {}, _b[classes1.adorRootV17] = props.v17, _b), (_c = {}, _c[classes1.adorRootV17WithValues] = Array.isArray(field.value) && field.value.length, _c), (_d = {}, _d[classes1.adorRootV17WithoutValues] = Array.isArray(field.value) && !field.value.length, _d), (_e = {}, _e[classes1.rootBase] = props.v5, _e[classes1.root2] = props.v1, _e), (_f = {}, _f[classes1.root4] = props.v4, _f), props.className, (_g = props.InputProps) === null || _g === void 0 ? void 0 : _g.className), style: style, error: !!touchedError, 
            // onChange={_onChange}
            helperText: errorText && React.createElement(TextOverflow, { v2: true }, errorText) }))));
};
