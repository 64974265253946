var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_CARGO_PRODUCT_TYPE_BASE = "\n    " + BASE_QUERY + "\n      name\n      description\n      origin\n      approvedAt\n      createdAt\n      updatedAt\n";
export var UpdateOrderCargoProductType = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateOrderCargoProductType($input: OrderCargoProductTypeInputUpdate!) {\n        updateOrderCargoProductType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCargoProductType($input: OrderCargoProductTypeInputUpdate!) {\n        updateOrderCargoProductType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PRODUCT_TYPE_BASE);
export var CreateOrderCargoProductType = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderCargoProductType($input: OrderCargoProductTypeInput!) {\n        createOrderCargoProductType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCargoProductType($input: OrderCargoProductTypeInput!) {\n        createOrderCargoProductType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PRODUCT_TYPE_BASE);
export var FetchOrderCargoProductType = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderCargoProductTypes($filter:MainFilter) {\n        orderCargoProductTypes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderCargoProductTypes($filter:MainFilter) {\n        orderCargoProductTypes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_CARGO_PRODUCT_TYPE_BASE);
export var DeleteOrderCargoProductType = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderCargoProductType($_id: MongoId!) {\n        deleteOrderCargoProductType(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderCargoProductType($_id: MongoId!) {\n        deleteOrderCargoProductType(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
