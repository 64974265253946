var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import * as material from '@material-ui/core';
import { IconButton, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { BasicDialog, BASIC_DIALOG_ACTIONS } from '../../Dialog';
import { useLocalStore, observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../../common';
import { FormikTextField } from '../../Formik';
import { Link } from 'react-router-dom';
import BaseTooltip from '../../BaseTooltip';
var Box = material.Box;
export default observer(function OrderMakeTemplateView(_a) {
    var _this = this;
    var store = _a.store, order = _a.order, props = __rest(_a, ["store", "order"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var label = langOrderForm.t('create_order_create_template_checkbox');
    var formRef = React.useRef();
    var _b = __read(React.useState(null), 2), template = _b[0], setTemplate = _b[1];
    var theme = useTheme();
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        defaultValue: {
            templateName: '',
        },
    }); });
    var icon = (React.createElement(BaseTooltip, { title: label },
        React.createElement(IconButton, { id: 'make-template-btn' },
            React.createElement(FileCopyIcon, { color: 'primary' }))));
    var onOk = function () { return __awaiter(_this, void 0, void 0, function () {
        var v, _a, templateResponce;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = formRef.current;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    v = _a;
                    return [4 /*yield*/, store.orderStore.createOrderTemplate({
                            _id: order._id,
                            templateName: v.templateName,
                        })];
                case 3:
                    templateResponce = _b.sent();
                    if (!templateResponce)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    setTemplate(templateResponce);
                    throw Error(BASIC_DIALOG_ACTIONS.APPROVE);
            }
        });
    }); };
    var dialogBody = (React.createElement(Formik, { key: "make-template-form", innerRef: formRef, initialValues: localStore.defaultValue, validationSchema: Yup.object({
            templateName: Yup.string()
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .required(langForm.t('form_input_validate_required')),
        }), onSubmit: function (e) { return e; } }, function (formik) {
        return (React.createElement(Form, { id: 'create-template', name: 'create-template' },
            React.createElement(FormikTextField, { autoFocus: true, title: langOrderForm.t('create_order_template_name_label'), help: langForm.t('form_input_help_order_template_as'), variant: "outlined", name: "templateName", value: formik.values.templateName })));
    }));
    if (template) {
        dialogBody = (React.createElement(Box, { display: "flex", justifyContent: "center", gridGap: theme.spacing(1), alignItems: "center", width: "100%", height: theme.spacing(10) },
            i18n
                .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order_template')) + '.',
            React.createElement(Link, { to: "/orders/" + template._id },
                React.createElement("a", null, i18n.getResource(i18n.language, KEYS.APP, 'main_order_template') +
                    ' ' +
                    i18n.getResource(i18n.language, KEYS.APP, 'main_link').toLowerCase()))));
    }
    var onClose = function () {
        if (template) {
            setTemplate(null);
        }
    };
    return (React.createElement(BasicDialog, { withoutWrapper: true, shouldNotPrevent: true, isMiddleBody: true, onOk: onOk, hidActionOkBth: template ? true : false, body: dialogBody, onClose: onClose, title: langOrderForm.t('create_order_create_template_checkbox'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, bodyStyle: { alignItems: 'center' }, parentStyles: __assign({ textDecoration: 'none' }, (props.label && { width: '100%' })), actionInit: function (handleClickOpen) {
            return props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: label, handleClickOpen: handleClickOpen }))) : (React.createElement(Box, { onClick: handleClickOpen }, icon));
        } }));
});
