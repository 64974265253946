var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var formatMessages = function (messages) {
    var list = [];
    var lastAuthor;
    var lastMessageTime;
    for (var i = 0; i < messages.length; i++) {
        var m = messages[i];
        if (!lastAuthor ||
            (lastAuthor === m.createdBy._id && (m.isTemp || new Date(m.createdAt).getTime() - lastMessageTime < 1000 * 60))) {
            if (list[list.length - 1]) {
                list[list.length - 1].messages.push(__assign(__assign({}, m), { _id: m._id + list[list.length - 1].messages.length + 1, text: m.text }));
            }
            else {
                list.push(__assign(__assign({}, m), { messages: [
                        __assign(__assign({}, m), { _id: m._id + 1, text: m.text }),
                    ] }));
            }
        }
        else {
            list.push(__assign(__assign({}, m), { messages: [
                    __assign(__assign({}, m), { _id: m._id + 1, text: m.text }),
                ] }));
        }
        lastAuthor = m.createdBy._id;
        lastMessageTime = new Date(m.createdAt).getTime();
    }
    return list;
};
