import { types } from "mobx-state-tree";
import { AsyncActionStatus } from "@izi-logistics/common/lib/stores";
import { Order } from "@izi-logistics/common/lib/models/Order";

export const OrdersCollectionStore = types
  .compose(
    AsyncActionStatus,
    types.model("OrdersCollectionStore", {
      orders: types.array(Order),
    })
  )
  .actions((self) => ({}))
  .named("OrdersCollectionStore");
