import { makeStyles, createStyles } from '@material-ui/core/styles';
var userCancelColor = 'rgb(253 219 219)';
var userDeletedColor = '#F0E0EE';
export var useQuoteStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return createStyles({
        container: {
            transition: 'all 0.3s ease-in 0.3s',
            '& *': {
                transition: 'all 0.3s ease-in 0.3s',
            },
        },
        expand: {
            // marginTop: theme.spacing(-1),
            transform: 'rotate(0deg)',
            // marginLeft: "auto",
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            color: theme.palette.primary.main,
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        userDeleted: {
            background: userDeletedColor,
            '& .route-start-preview': (_a = {},
                _a[theme.breakpoints.up('sm')] = {
                    '&::before': {
                        borderColor: "transparent transparent transparent " + userDeletedColor + "!important",
                    },
                },
                _a[theme.breakpoints.down('sm')] = {
                    '&::before': {
                        borderColor: userDeletedColor + " transparent transparent transparent!important",
                    },
                },
                _a),
        },
        userCanceled: {
            background: userCancelColor,
            '& .route-start-preview': (_b = {},
                _b[theme.breakpoints.up('sm')] = {
                    '&::before': {
                        borderColor: "transparent transparent transparent " + userCancelColor + "!important",
                    },
                },
                _b[theme.breakpoints.down('sm')] = {
                    '&::before': {
                        borderColor: userCancelColor + " transparent transparent transparent!important",
                    },
                },
                _b),
        },
        canceled: {
            background: theme.palette.grey['300'],
            '& .route-start-preview': (_c = {},
                _c[theme.breakpoints.up('sm')] = {
                    '&::before': {
                        borderColor: "transparent transparent transparent " + theme.palette.grey['300'] + "!important",
                    },
                },
                _c[theme.breakpoints.down('sm')] = {
                    '&::before': {
                        borderColor: theme.palette.grey['300'] + " transparent transparent transparent!important",
                    },
                },
                _c),
        },
        badge: {
            color: theme.palette.secondary.main,
        },
    });
});
