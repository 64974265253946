var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useLocalStore } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DeleteUploadType, FetchUploadTypes } from '../../../graphql/uploadType';
import { formUserErrorMessage, onlySpacesRegExp } from '../../../common';
import { BasicDialog, onPreventEvent } from '../../../components/Dialog';
import { i18n, KEYS } from '../../../i18n';
import { FormikAutocompleteAsyncAdd, FormikTextField } from '../../../components/Formik';
import { TextOverflow } from '../../../components';
import DropDownItemActions from '../../../components/Order/form/dialogs/DropDownItemActions';
import { theme } from '../../../theme';
export default inject('store')(function (_a) {
    var autocompleteProps = _a.autocompleteProps, props = __rest(_a, ["autocompleteProps"]);
    var langForm = useTranslation(KEYS.FORM);
    var store = props.store, onChange = props.onChange, client = props.config.client;
    var formRef = React.useRef();
    var _b = __read(React.useState({ name: '', description: '' }), 2), initialValue = _b[0], setInitialValue = _b[1];
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (input) {
            localStore.error = '';
            localStore.isLoading = true;
            return new Promise(function (resolve, reject) {
                console.log('input', input);
                if (input === null || input === void 0 ? void 0 : input._id) {
                    store.orderQuoteStore
                        .updateUploadType(input)
                        .then(resolve)
                        .catch(function (error) {
                        localStore.error = formUserErrorMessage(error);
                        reject(localStore.error);
                    });
                }
                else {
                    store.orderQuoteStore
                        .createUploadType(input)
                        .then(resolve)
                        .catch(function (error) {
                        localStore.error = formUserErrorMessage(error);
                        reject(localStore.error);
                    });
                }
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    return [4 /*yield*/, localStore.onSubmit(createdItem)];
                case 2:
                    createdItem = _a.sent();
                    if (onChange && createdItem)
                        onChange(createdItem);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { key: localStore.error || 1, innerRef: formRef, initialValues: initialValue || { name: '', description: '' }, validationSchema: Yup.object({
                name: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                description: Yup.string()
                    .max(1050, langForm.t('form_input_validate_max_char').replace(/_X_/, '1050'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
            }), onSubmit: function (e) { return e; } }, function (formik) {
            return (React.createElement(Form, { id: "form-upload", "data-modal": "on-foregound" },
                (localStore.error && React.createElement(Typography, { color: "error" }, localStore.error)) || null,
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, container: true, direction: "column", style: { marginBottom: theme.spacing(1) } },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_name'), variant: "outlined", type: "text", name: "name", value: formik.values.name })),
                    React.createElement(Grid, { item: true, container: true, direction: "column", style: { marginBottom: theme.spacing(1) } },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_description'), variant: "outlined", multiline: true, rows: 4, name: "description", value: formik.values.description })))));
        })));
    return (React.createElement(BasicDialog, { id: "upload-type-dialog", askCanClose: true, body: dialogBody, title: langForm.t('form_input_upload_type'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, onOk: onOk, actionInit: function (handleClickOpen) { return (React.createElement(FormikAutocompleteAsyncAdd, __assign({ v17: true, autocompleteOnBlur: true }, props, { isClient: true, onOpenDialog: function (e, _a) {
                var item = _a.item;
                return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_b) {
                        handleClickOpen(e);
                        setInitialValue(__assign(__assign({}, initialValue), item));
                        return [2 /*return*/];
                    });
                });
            }, removeQuery: DeleteUploadType, deleteTitle: function (option) { return option.name; }, onChange: function (e, item) { return (item === null || item === void 0 ? void 0 : item._id) && onChange && onChange(item); }, onClear: function () { return onChange && onChange(); }, client: client, query: FetchUploadTypes, addTitle: "" + langForm.t('form_btn_add'), autocompleteProps: __assign(__assign({}, autocompleteProps), { getOptionLabel: function (option) { return option.name; } }), renderOption: function (option) { return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
                React.createElement(TextOverflow, null, option.name),
                React.createElement(DropDownItemActions, { option: option, canEdit: !option.approvedAt, isFreeActions: !option.isGlobal }))); } }))); } }));
});
