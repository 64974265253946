var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { USER_COMPANY_BASE } from './base'; //
import { USER_BASE_V1 } from './user';
export var StatisticTotalOfferPerCaCompare = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query statisticTotalOfferPerCaCompare($filter: MainFilter) {\n    statisticTotalOfferPerCaCompare(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSubmitted\n        totalAccepted \n      }\n      count\n      totalSubmitted \n    }\n  }\n"], ["\n  query statisticTotalOfferPerCaCompare($filter: MainFilter) {\n    statisticTotalOfferPerCaCompare(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSubmitted\n        totalAccepted \n      }\n      count\n      totalSubmitted \n    }\n  }\n"])), USER_BASE_V1, USER_COMPANY_BASE);
export var StatisticTotalOfferPerDirectionCompare = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query statisticTotalOfferPerDirectionCompare($filter: MainFilter) {\n    statisticTotalOfferPerDirectionCompare(filter: $filter) {\n      data {\n        time\n        sorting_deliveryRegion\n        sorting_pickupRegion\n        sorting_countryPickup\n        sorting_countryDelivery\n        totalSubmitted\n        totalAccepted\n      }\n      count\n      totalSubmitted\n    }\n  }\n"], ["\n  query statisticTotalOfferPerDirectionCompare($filter: MainFilter) {\n    statisticTotalOfferPerDirectionCompare(filter: $filter) {\n      data {\n        time\n        sorting_deliveryRegion\n        sorting_pickupRegion\n        sorting_countryPickup\n        sorting_countryDelivery\n        totalSubmitted\n        totalAccepted\n      }\n      count\n      totalSubmitted\n    }\n  }\n"])));
export var StatisticTotalOfferPerCa = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query statisticTotalOfferPerCa($filter: MainFilter) {\n    statisticTotalOfferPerCa(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSubmitted\n        totalAccepted \n      }\n      count\n      totalSubmitted \n    }\n  }\n"], ["\n  query statisticTotalOfferPerCa($filter: MainFilter) {\n    statisticTotalOfferPerCa(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSubmitted\n        totalAccepted \n      }\n      count\n      totalSubmitted \n    }\n  }\n"])), USER_BASE_V1, USER_COMPANY_BASE);
export var StatisticTruckPlatesBooked = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query statisticTruckPlateBooked($filter: MainFilter) {\n    statisticTruckPlateBooked(filter: $filter) {\n      data {\n        ", "\n        totalHasBookings\n        totalTrailerBooked\n        totalTrackBooked\n        totalTimeBooking\n        totalAccepted\n      }\n      count\n      totalSubmitted \n      totalAccepted \n    }\n  }\n"], ["\n  query statisticTruckPlateBooked($filter: MainFilter) {\n    statisticTruckPlateBooked(filter: $filter) {\n      data {\n        ", "\n        totalHasBookings\n        totalTrailerBooked\n        totalTrackBooked\n        totalTimeBooking\n        totalAccepted\n      }\n      count\n      totalSubmitted \n      totalAccepted \n    }\n  }\n"])), USER_BASE_V1);
export var StatisticUserActivity = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query statisticUserActivity($filter: MainFilter) {\n    statisticUserActivity(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSpendTime\n        totalVisited\n        totalLogedIn \n      }\n      totalLoggedIn\n      count \n    }\n  }\n"], ["\n  query statisticUserActivity($filter: MainFilter) {\n    statisticUserActivity(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        }\n        totalSpendTime\n        totalVisited\n        totalLogedIn \n      }\n      totalLoggedIn\n      count \n    }\n  }\n"])), USER_BASE_V1, USER_COMPANY_BASE);
export var StatisticNewUserActivity = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query statisticNewUserActivity($filter: MainFilter) {\n    statisticNewUserActivity(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        } \n        latestVisitAt\n      }\n      count \n    }\n  }\n"], ["\n  query statisticNewUserActivity($filter: MainFilter) {\n    statisticNewUserActivity(filter: $filter) {\n      data {\n        ", "\n        company{\n          ", "\n        } \n        latestVisitAt\n      }\n      count \n    }\n  }\n"])), USER_BASE_V1, USER_COMPANY_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
