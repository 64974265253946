import { client } from "../api/apolloClient";
import { RegisterStoreFactory } from "@izi-logistics/common/lib/stores";

interface ISignUpDataInterfase {
  companyType: string;
  companyName: string;
  email: string;
  lastName: string;
  firstName: string;
  applicationType: string;
}

export const RegisterStore = RegisterStoreFactory({ client });
