var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { createStyles, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { withBaseListStore } from '../store';
import { BaseListHeaderFilter } from './Filters';
import Checkbox from '@material-ui/core/Checkbox';
import { theme } from '../../../theme';
import { DeleteDialog } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { KEYS } from '../../../i18n';
import { useMainStyles } from '../../../styles';
import { makeStyles } from '@material-ui/core/styles';
import { MainFilterSortDir } from '../types.enums';
import BaseTooltip from '../../BaseTooltip';
import { TextOverflow } from '../../TextOverflow';
import Searching from './Searching';
import BaseListTitle from './BaseListTitle';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            marginBottom: theme.spacing(2),
        },
        columnSortRoot: {
            width: '100%',
        },
        filters: {
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 3,
        // padding: theme.spacing(1, 0),
        },
    });
});
export var BaseListHeader = withBaseListStore(function (_a) {
    var _b;
    var _c;
    var config = _a.config, state = _a.state, onMultiDeletion = _a.onMultiDeletion, onToggleAllSelection = _a.onToggleAllSelection, onChangeFilter = _a.onChangeFilter, isSkeleton = _a.isSkeleton;
    var langToast = useTranslation(KEYS.TOAST);
    var langApp = useTranslation(KEYS.APP);
    var classesM = useStyles();
    var classes = useMainStyles();
    var onMultiDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = config.onMultiDelete;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.onMultiDelete(state.selected)];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    onToggleAllSelection();
                    onMultiDeletion(state.selected);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeSort = function (column) {
        return function () {
            return onChangeFilter({
                sortBy: column,
                sortDir: state.filter.sortBy === column
                    ? (state.filter.sortDir === MainFilterSortDir.DESC && MainFilterSortDir.ASC) || MainFilterSortDir.DESC
                    : MainFilterSortDir.ASC,
            });
        };
    };
    var selectionContentActions = config.onSelectActions && config.onSelectActions(state.selected, { filter: state.filter });
    var selectionContent = !config.isSelectable || !(state.loaded || isSkeleton) || !state.count ? null : (React.createElement(Grid, { container: true, alignItems: 'center' },
        React.createElement(Checkbox, { indeterminate: state.selected.length > 0 && state.selected.length < state.filter.limit, checked: state.selected.length > 0 && state.selected.length === state.filter.limit, onChange: onToggleAllSelection, color: 'primary', inputProps: { 'aria-label': 'select all desserts' } }),
        React.createElement(Grid, { container: true, alignItems: 'center', style: { maxWidth: "calc(100% - 42px)" } },
            React.createElement(Grid, null,
                selectionContentActions,
                config.onMultiDelete ? (React.createElement(DeleteDialog, { actionInit: function (handleClickOpen) {
                        var _a;
                        return (React.createElement(BaseTooltip, { title: langApp.t('modal_delete_title') },
                            React.createElement(IconButton, { edge: "start", color: "primary", "aria-label": "menu", onClick: handleClickOpen, className: clsx((_a = {}, _a[classes.disabled] = !state.selected.length, _a)), disabled: !state.selected.length },
                                React.createElement(DeleteForeverIcon, null))));
                    }, onOk: onMultiDelete, value: langToast.t('toast_items_selected').replace(/_X_/, state.selected.length.toString()) })) : null),
            React.createElement(Typography, { variant: 'subtitle1', style: {
                    visibility: state.selected.length ? 'initial' : 'hidden',
                } }, langToast.t('toast_items_selected').replace(/_X_/, state.selected.length.toString())))));
    var shiftStyles = config.isSelectable ? {} : null;
    var isUseTypography = config.isFullHeaderName;
    var headerColumns = config.headerColumns, headerStyle = config.headerStyle, hasHeaderSort = config.hasHeaderSort;
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var hasHeader = !isSmall && headerColumns && (state.loaded || isSkeleton) && state.count;
    var headerColumnsV = headerColumns;
    var isExpandVersion = hasHeader && headerColumnsV[headerColumnsV.length - 1].version === 1;
    var isExpandVersion2 = hasHeader && headerColumnsV[headerColumnsV.length - 1].version === 2;
    var columns = isExpandVersion
        ? __spread(headerColumnsV.filter(function (e, index) { return index < headerColumnsV.length - 1; })) : headerColumnsV;
    var headerColumnItem = function (e) {
        var headerName = e.name;
        var headerSubNames = e.names;
        var headerProps = {
            variant: 'subtitle1',
            style: __assign({ fontWeight: 700 }, e.nameStyle),
            children: typeof headerName === 'string' ? e.name.charAt(0).toUpperCase() + e.name.substr(1).toLowerCase() : headerName,
        };
        var headerContent = isUseTypography ? React.createElement(Typography, __assign({}, headerProps)) : React.createElement(TextOverflow, __assign({}, headerProps));
        if (!headerName && (headerSubNames === null || headerSubNames === void 0 ? void 0 : headerSubNames.length)) {
            headerContent = (React.createElement(React.Fragment, null, headerSubNames.map(function (subName) { return (React.createElement(TextOverflow, { variant: 'subtitle1', style: __assign({ fontWeight: 700 }, e.namesStyle) }, subName)); })));
        }
        return (React.createElement(Grid, { xs: 12, key: (headerName === null || headerName === void 0 ? void 0 : headerName.toString()) || (headerSubNames === null || headerSubNames === void 0 ? void 0 : headerSubNames.join(' ')), item: true, md: (e.size || Math.ceil(12 / columns.length)), style: __assign({}, e.style) }, hasHeaderSort && e.sortKey ? (React.createElement(TableSortLabel, { style: __assign({}, e.style), className: classesM.columnSortRoot, active: state.filter.sortBy === e.sortKey, direction: state.filter.sortBy === e.sortKey ? state.filter.sortDir : 'asc', onClick: onChangeSort(e.sortKey) }, headerContent)) : (headerContent)));
    };
    var width = config.fullWidth ? 0 : 48;
    var headerContent = hasHeader ? (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, alignItems: 'center', style: __assign(__assign(__assign(__assign({}, (selectionContent ? { marginLeft: 42 } : null)), headerStyle), (!isSmall && isExpandVersion ? { maxWidth: "calc(100% - " + width + "px)" } : {})), (!isSmall && isExpandVersion2 ? { maxWidth: "calc(100% - " + width + "px)" } : {})) }, columns.map(headerColumnItem)),
        isExpandVersion ? (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: width } }, headerColumnItem(headerColumnsV[headerColumnsV.length - 1]))) : null)) : null;
    var headerTitle = config.hideTitleHeader ? null : (React.createElement(React.Fragment, null, (config.title && (React.createElement(Grid, { container: true, justify: 'space-between', style: { marginBottom: theme.spacing(1), minHeight: theme.spacing(6.34) }, spacing: 1 },
        React.createElement(Searching, { config: config, content: function (size) { return (React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center', item: true, xs: 12, md: (size ? 12 - size : 12) }, typeof config.title === 'function' ? (config.title(state)) : typeof config.title === 'string' ? (React.createElement(BaseListTitle, { title: config.title })) : (config.title))); } })))) ||
        (!config.hideSearch && React.createElement(Searching, { v1: true, config: config })) ||
        null));
    return (React.createElement(Grid, { container: true, justify: 'space-between', className: clsx(classesM.root, (_c = config.headerProps) === null || _c === void 0 ? void 0 : _c.className) },
        headerTitle,
        config.hideAllFilters ? ((headerContent && (React.createElement(Grid, { container: true, justify: 'space-between', style: __assign({}, config.mainHeaderStyle) }, headerContent))) ||
            null) : (React.createElement(Grid, { container: true, justify: 'space-between', className: clsx((_b = {},
                _b[classesM.filters] = (!state.loaded && state.loading) || (state.loaded && (state.count || config.filters)),
                _b)), style: __assign(__assign(__assign({}, config.mainHeaderStyle), (config.isSelectable && { paddingLeft: 0 })), (config.noSpace && { paddingLeft: 0, paddingRight: 0 })) },
            React.createElement(BaseListHeaderFilter, { style: __assign({}, shiftStyles), config: config }),
            headerContent,
            selectionContent ||
                (selectionContentActions && (React.createElement(Grid, { container: true, alignItems: 'center' }, selectionContentActions)))))));
});
