import { BASE_QUERY, FORWARDER_USER_BASE, LOCATION_ADDRESS, UPLOAD_BASE, USER_COMPANY_BASE, USER_WITH_COMPANY_BASE_I, } from '../base';
import { ORDER_TRANSPORTATION_BASE_MORE } from '../order/types';
import { USER_BASE_MIN } from '../user';
import { ORDER_PAYMENT_BASE } from '../order/orderPayment';
export var INVOICE_SERVICE_UNIT_BASE = "\n      " + BASE_QUERY + "\n     name\n     description\n";
export var INVOICE_SERVICE_VAT_BASE = "\n      " + BASE_QUERY + "\n     value\n     description\n";
export var INVOICE_NOTE_TEMPLATE_BASE = "\n      " + BASE_QUERY + "\n     name\n     html\n";
export var INVOICE_SERVICE_BASE = "\n      " + BASE_QUERY + "\n     name\n     description\n     showDescriptionInPdf\n     unit{\n          " + INVOICE_SERVICE_UNIT_BASE + "\n     }\n     vat{\n          " + INVOICE_SERVICE_VAT_BASE + "\n     }\n     quantity\n     discount\n     discountUnit\n     price\n     total\n     vatAmount\n";
export var INVOICE_REQUEST_BASE = "\n      " + BASE_QUERY + "\n     comment \n";
export var INVOICE_TYPE_BASE = "\n      " + BASE_QUERY + "\n     name\n     serialPrefix\n     format\n     description\n";
export var INVOICE_BASE = "\n    " + BASE_QUERY + "\n    category\n    upload{\n        " + UPLOAD_BASE + "\n    }\n    parent{\n        _id\n    }\n    refunds{\n        _id\n    }\n     lastPayment{\n                   " + ORDER_PAYMENT_BASE + "\n                }\n    quote{\n        _id\n        approvedAt\n        order{\n              forwarders{\n                " + FORWARDER_USER_BASE + "\n            }\n            _id\n            orderId\n            sorting_totalWeight\n            sorting_deliveryAt\n            sorting_countryDelivery\n            sorting_countryPickup\n            sorting_pickupAt\n            vehicleType{\n                name\n            }\n            createdAt\n            loadingType\n            transport_other\n             transportations{\n                " + ORDER_TRANSPORTATION_BASE_MORE + "\n             }\n              createdBy{\n                  " + BASE_QUERY + "\n                  company{\n                  " + USER_COMPANY_BASE + "\n                 }\n            }\n             lastPayment{\n                   " + ORDER_PAYMENT_BASE + "\n                }\n            manager{\n                  " + USER_BASE_MIN + "\n                      appType\n            }\n            latestQuote{\n                amount\n                currency\n                createdBy{\n                    " + USER_BASE_MIN + "\n                      appType\n                }\n            }\n        }\n    }\n    showOrderDetails\n    client_id{\n     " + USER_COMPANY_BASE + "\n    }\n    seller_id{\n     " + USER_COMPANY_BASE + "\n    }\n    client_emails\n    client_phone\n    client_name\n     payedAt\n     discount\n     discountUnit\n     isAllDiscount\n    sentAt\n    total\n    serial\n    templateHTML\n    startDate\n    endDate\n    typeSerial\n    currency\n    totalFiles\n    status\n    type{\n        " + INVOICE_TYPE_BASE + "\n    }\n    asTemplate\n    isTemplate\n    templateName\n    printLanguage\n";
export var INVOICE_MORE_BASE = "\n" + INVOICE_BASE + "\n            seller_name\n            seller_pay_type\n            seller_phone\n            seller_vatCode\n            seller_bankAccountCode\n            seller_bankSwiftCode\n            seller_bankCode\n            seller_bank\n            seller_companyCode\n            seller_address{\n                " + LOCATION_ADDRESS + "\n            }\n            seller_id{\n                " + USER_COMPANY_BASE + "\n            }\n \n            client_name\n            client_vatCode\n            client_bankAccountCode\n            client_companyCode\n            client_address{\n                " + LOCATION_ADDRESS + "\n            }\n            client_id{\n                " + USER_COMPANY_BASE + "\n            }\n            client_pay_type\n            client_emails\n            client_phone\n            \n            \n            hasComment\n            hasNote\n            note\n            comment\n            accepted{\n                " + USER_WITH_COMPANY_BASE_I + "\n            }\n";
export var INOVICE_COMPANY_BASE = "\n    " + BASE_QUERY + "\n     name\n     companyCode\n     address{\n        " + LOCATION_ADDRESS + "\n     }\n     vatCode\n     bankAccountCode\n     emails\n     pay_type\n     phone\n";
