var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { BASE_QUERY } from '../base';
import { gql } from '@apollo/client';
export var ORDER_PAYMENT_BASE = "\n    " + BASE_QUERY + "\n       order{\n        _id\n       }\n       releasedBy{\n       firstName\n       lastName\n       company{\n        _id\n        name\n       }\n        _id\n       }\n       approvedBy{\n        _id\n          firstName\n           lastName\n           company{\n            _id\n            name\n           }\n       }\n       releasedAt\n       approvedAt\n       comment\n       status\n       forwarderConfirmApprovedAt\n       forwarderConfirmReleasedAt\n       forwarderConfirmApprovedComment\n       forwarderConfirmReleasedComment\n";
export var ApproveOrderPayment = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation approveOrderPayment($input: OrderPaymentInputUpdate!) {\n        approveOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation approveOrderPayment($input: OrderPaymentInputUpdate!) {\n        approveOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_PAYMENT_BASE);
export var ReleaseOrderPayment = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation releaseOrderPayment($input: OrderPaymentInputUpdate!) {\n        releaseOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation releaseOrderPayment($input: OrderPaymentInputUpdate!) {\n        releaseOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_PAYMENT_BASE);
export var ForwarderConfirmOrderPayment = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation forwarderConfirmOrderPayment($input: OrderPaymentInputUpdate!) {\n        forwarderConfirmOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation forwarderConfirmOrderPayment($input: OrderPaymentInputUpdate!) {\n        forwarderConfirmOrderPayment(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_PAYMENT_BASE);
var templateObject_1, templateObject_2, templateObject_3;
