var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { theme } from "../../theme";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: 228,
            minHeight: 104,
        },
        actions: {
            "& button": {
                borderRadius: 0,
            },
        },
        details: {
            borderColor: "#BAB8B8",
            borderTop: "1px solid",
            borderLeft: "1px solid",
            borderRight: "1px solid",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
        },
        button: {
            flex: 1,
            borderRadius: 0,
        },
    });
});
export var BaseCardHeader = function (_a) {
    var header = _a.header, noSpacing = _a.noSpacing;
    var className = useStyles();
    var nestedStyles = (!noSpacing && {
        padding: theme.spacing(0, 2),
    }) ||
        {};
    return (React.createElement(Grid, { container: true, alignItems: "center", className: (!noSpacing && className.details) || "" }, header(nestedStyles)));
};
export var BaseCard = function (_a) {
    var actions = _a.actions, props = __rest(_a, ["actions"]);
    var className = useStyles();
    return (React.createElement(Grid, { container: true, className: clsx(className.root), justify: "space-between", direction: "row" },
        React.createElement(BaseCardHeader, __assign({}, props)),
        React.createElement(Grid, { container: true, className: clsx(className.actions) }, actions)));
};
