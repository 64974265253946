var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { types } from 'mobx-state-tree';
import { OrderCurrency } from './types';
import { NOTIFICATION_ACTIONS } from '../notifications';
import { GuideTypes } from '../guides';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var BaseUserEmailSettings = types.model('BaseUserEmailSettings', __assign({}, Object.values(NOTIFICATION_ACTIONS)
    .map(function (e) {
    var _a;
    return (_a = {}, _a[e] = types.maybeNull(types.optional(types.boolean, false)), _a);
})
    .reduce(function (acc, action) { return (__assign(__assign({}, acc), action)); }, {})));
export var BaseUserGuideSettings = types.model('BaseUserGuideSettings', __assign({}, Object.values(GuideTypes)
    .map(function (e) {
    var _a;
    return (_a = {}, _a[e] = types.maybeNull(types.optional(types.boolean, false)), _a);
})
    .reduce(function (acc, action) { return (__assign(__assign({}, acc), action)); }, {})));
var BaseUserSettingsFilters = types.model('BaseUserSettingsFilters', {
    name: types.maybeNull(types.optional(types.string, '')),
    id: types.maybeNull(types.optional(types.string, '')),
    value: types.maybeNull(types.optional(types.string, '')),
    type: types.maybeNull(types.optional(types.string, '')),
});
export var BaseUserSettings = types.model('BaseUserSettings', {
    _id: types.maybeNull(types.optional(types.string, '')),
    terms: types.maybeNull(types.optional(types.array(types.union(types.string)), [])),
    selectedVehicleTypesAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    ratingCaModalViewAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    forwarderOrderMarkUpAuto: types.maybeNull(types.optional(types.boolean, false)),
    forwarderOrderMarkUpPercentage: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPrice: types.maybeNull(types.optional(types.number, 0)),
    forwarderOrderMarkUpMinPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPriceCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    forwarderOrderMarkUpMaxPrice: types.maybeNull(types.optional(types.number, 0)),
    hideToDo: types.maybeNull(types.optional(types.boolean, false)),
    showPlatformDuty: types.maybeNull(types.optional(types.boolean, false)),
    openWizardOnClick: types.maybeNull(types.optional(types.boolean, false)),
    isOneTimeWizardClosed: types.maybeNull(types.optional(types.boolean, false)),
    disablePushNotificationModal: types.maybeNull(types.optional(types.boolean, false)),
    orderAutoArchiveDelay: types.maybeNull(types.optional(types.number, 40)),
    filters: types.maybeNull(types.optional(types.array(BaseUserSettingsFilters), function () { return []; })),
    personalAffiliateRevenue: types.maybeNull(types.number),
    emailSettings: types.maybeNull(types.optional(BaseUserEmailSettings, function () { return BaseUserEmailSettings.create(); })),
    phoneSettings: types.maybeNull(types.optional(BaseUserEmailSettings, function () { return BaseUserEmailSettings.create(); })),
    guides: types.maybeNull(types.optional(BaseUserGuideSettings, function () { return BaseUserGuideSettings.create(); })),
});
