var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { inject, observer } from 'mobx-react';
import { KEYS } from '../../i18n';
import { ACTION_CHANGE } from '../BasicList';
import { CompanyForm } from './CompanyProfile';
import { BasicDialog } from '../Dialog';
import { MainButton } from '../MainButton';
import { TextOverflow } from '../TextOverflow';
import OrderRouteMenuItem from '../Order/List/OrderRoutesMenuItem';
import BaseTooltip from '../BaseTooltip';
export default inject('store')(observer(function (_a) {
    var record = _a.record, onChange = _a.onChange, isAdmin = _a.isAdmin, accountStore = _a.store.accountStore, config = _a.config, props = __rest(_a, ["record", "onChange", "isAdmin", "store", "config"]);
    var _b = __read(React.useState(JSON.parse(JSON.stringify(record))), 2), value = _b[0], setValue = _b[1];
    var formRef = React.useRef(null);
    var langForm = useTranslation(KEYS.FORM);
    /*eslint-disable */
    var onOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var item, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    item = _a.sent();
                    if (!item)
                        throw 'No Valid';
                    if (item.name && !accountStore.currentCompany.companyEmail) {
                        item.companyEmail = item.name.replace(/ /g, '-');
                    }
                    if (item.accounting_emails && item.settings) {
                        item.settings = __assign(__assign({}, item.settings), { accounting_emails: item.accounting_emails });
                    }
                    if (!item._id) return [3 /*break*/, 3];
                    return [4 /*yield*/, accountStore.currentCompany.update(item)];
                case 2:
                    res = _a.sent();
                    Object.assign(record, res);
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, accountStore.currentCompany.create(item)];
                case 4:
                    res = _a.sent();
                    _a.label = 5;
                case 5:
                    setValue(res);
                    if (onChange) {
                        onChange(record, ACTION_CHANGE.DELETED);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    /*eslint-enable */
    var _onChange = function (e) {
        var _a;
        var res = __assign(__assign({}, value), (_a = {}, _a[e.target.name] = e.target.value, _a));
        if (e.target.name === 'logo') {
            res.logo = {
                _id: res.logo._id,
                path: res.logo.path,
            };
        }
        if (res.name && !accountStore.currentCompany.companyEmail) {
            res.companyEmail = res.name.replace(/ /g, '-');
        }
        setValue(res);
    };
    var dialogBody = (React.createElement(CompanyForm, { isP44: props.isP44, config: config, key: value.updatedAt + "-" + ((value.logo && value.logo.updatedAt) || 1), ref: formRef, onChange: _onChange, record: value, isModal: true, isAdmin: isAdmin }));
    var title = record && record._id
        ? langForm.t('form_btn_edit_company')
        : props.isCarrier
            ? langForm.t('form_btn_register_carrier_company')
            : langForm.t('form_btn_new_company');
    var _title = langForm.t('form_btn_edit_company') + (props.isP44 ? " Carrier Project44 id" : '');
    return (React.createElement(BasicDialog, { askCanClose: true, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: props.isP44 ? 'xs' : 'lg' }, onOk: onOk, actionOkTitle: title, body: dialogBody, title: React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column' },
            React.createElement(TextOverflow, null, title)), actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: _title },
                React.createElement(IconButton, { onClick: handleClickOpen },
                    React.createElement(EditIcon, { color: 'primary' }))));
            return record && record._id ? (props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: _title, handleClickOpen: handleClickOpen }))) : (icon)) : (React.createElement(MainButton, __assign({}, props.buttonProps, { onClick: handleClickOpen, color: 'primary', title: props.isCarrier ? langForm.t('form_btn_register_carrier_company') : langForm.t('form_btn_new_company') })));
        } }));
}));
