import React from 'react';
import { TERMS_REQUEST } from '@izi-logistics/common/lib/models/Terms';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { client } from '../../api/apolloClient';

const List = React.lazy(() => import('@izi-logistics/common/lib/pages/Terms'));

const Terms = () => {
  return (
    <PreloadingComponent>
      <List
        config={{ client }}
        defaultFilter={{
          fields: [{ name: 'request', value: TERMS_REQUEST.ORDER_SHIPPING_APPROVED_FOR_CARRIER }],
        }}
      />
    </PreloadingComponent>
  );
};
export default Terms;
