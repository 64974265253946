import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
export var useSectionRowStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            borderBottom: "1px solid " + theme.palette.grey['300'],
            minHeight: 31,
            color: theme.palette.primary.main,
        },
        rootV14: {
            border: "1px solid " + theme.palette.grey['400'],
            minHeight: 31,
            color: theme.palette.primary.main,
        },
        rootSuccessIndication: {
            borderRight: theme.spacing(0.5) + "px solid " + theme.palette.success.light,
            paddingRight: theme.spacing(0.5),
        },
        rootCancelIndication: {
            borderRight: theme.spacing(0.5) + "px solid " + theme.palette.error.light,
            paddingRight: theme.spacing(0.5),
            backgroundColor: "#f0f0f0",
        },
        textFieldV1: {
            '& .MuiInputBase-root': {
                '& div': {
                    flexWrap: 'initial',
                },
            },
            '& input': {
                width: 'calc(100% - 10px)',
            },
        },
        textField: {
            margin: 0,
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 2,
            },
            '& input': {
                padding: theme.spacing(0.5, 0.1, 0.5, 1) + "!important",
                margin: 0,
            },
        },
    });
});
