import { applySnapshot, types } from 'mobx-state-tree';
import { OrderCurrency } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderLoanFactoringType;
(function (OrderLoanFactoringType) {
    OrderLoanFactoringType["Standard"] = "Standard";
    OrderLoanFactoringType["Custom"] = "Custom";
})(OrderLoanFactoringType || (OrderLoanFactoringType = {}));
export var OrderLoanType;
(function (OrderLoanType) {
    OrderLoanType["Factoring"] = "Factoring";
    OrderLoanType["Personal"] = "Personal";
})(OrderLoanType || (OrderLoanType = {}));
export var OrderLoan = types
    .model('OrderLoan', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    contactPhone: types.maybeNull(types.optional(types.string, '')),
    contactEmail: types.maybeNull(types.optional(types.string, '')),
    contactName: types.maybeNull(types.optional(types.string, '')),
    companyDebtorVAT: types.maybeNull(types.optional(types.string, '')),
    companyDebtorName: types.maybeNull(types.optional(types.string, '')),
    loanTerm: types.maybeNull(types.optional(types.number, 30)),
    loanAmount: types.maybeNull(types.union(types.null, types.undefined, types.string, types.number)),
    companyIncorporationAt: types.maybeNull(types.optional(types.string, '')),
    companyTurnover: types.maybeNull(types.union(types.null, types.undefined, types.string, types.number)),
    companyTurnoverCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    companyVAT: types.maybeNull(types.optional(types.string, '')),
    companyName: types.maybeNull(types.optional(types.string, '')),
    factoringType: types.maybeNull(types.optional(types.enumeration('Standard', Object.keys(OrderLoanFactoringType)), OrderLoanFactoringType.Standard)),
    // type: types.maybeNull(
    //   types.optional(
    //     types.enumeration("Factoring", Object.keys(OrderLoanType)),
    //     OrderLoanType.Factoring
    //   )
    // ),
    loanAmountCurrency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
