import { applySnapshot, types } from 'mobx-state-tree';
import { castToSnapshot } from 'mobx-state-tree';
import { Order } from '.';
import { MaybeEmptyModel } from '../stores';
import { BaseModel } from './BaseModel';
export var OrderGroup = types
    .compose(Order, types
    .model('OrderGroup', {
    generatedAt: types.maybeNull(types.optional(types.string, '')),
    totalGoodsPerOrder: types.maybeNull(types.number),
    lastStep: types.optional(types.maybeNull(types.number), function () { return 0; }),
    totalOrders: types.maybeNull(types.number),
    orders: types.maybeNull(types.optional(types.array(MaybeEmptyModel(BaseModel)), [])),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(OrderGroup.create());
        applySnapshot(self, snap);
    },
}); }))
    .named('OrderGroup');
