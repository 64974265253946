var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { Typography } from '@material-ui/core';
import { dialogStyles } from '../../components/Dialog/styles';
import { MainButton } from '../../components';
import { theme } from '../../theme';
export var ApproveCompanyDialog = function (_a) {
    var _b, _c, _d;
    var props = __rest(_a, []);
    var item = props.item;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var classesDialogStyles = dialogStyles();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onPreventEvent(e);
            if (props.onOk)
                props.onOk();
            return [2 /*return*/];
        });
    }); };
    var dialogBody = (React.createElement(Typography, { className: classesDialogStyles.text }, ((_b = item.joinRequest) === null || _b === void 0 ? void 0 : _b._id) === item.company._id
        ? [
            langApp.t('app_approve_create_company_text'),
            'User was created new company and dont want to join to exist company',
        ].join('. ')
        : langApp.t('app_approve_join_company').replace('_X_', (_c = item.joinRequest) === null || _c === void 0 ? void 0 : _c.name)));
    return (React.createElement(BasicDialog, { askCanClose: true, withoutWrapper: true, body: dialogBody, title: ((_d = item.joinRequest) === null || _d === void 0 ? void 0 : _d._id) === item.company._id
            ? langApp.t('app_approve_create_company')
            : langApp.t('app_approve_join_company_title'), onOk: onOk, moreActions: function (_a) {
            var handleClose = _a.handleClose, loading = _a.loading;
            return (React.createElement(MainButton, { disabled: props.loading || loading, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, props.onDeclineCompanyJoin(e)];
                            case 1:
                                _a.sent();
                                handleClose();
                                return [2 /*return*/];
                        }
                    });
                }); }, title: langForm.t('form_btn_decline'), style: { marginRight: theme.spacing(1) }, id: 'decline' }));
        }, actionOkTitle: langApp.t('app_approve'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: props.handleClickOpen }));
};
