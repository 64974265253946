var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import List from '@material-ui/core/List';
import { theme } from '../../theme';
import Grid from '@material-ui/core/Grid';
import { useCMRStyles } from './List/OrderCMR';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { inject } from 'mobx-react';
import { MainButton } from '../MainButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
export default inject('store')(withRouter(function (_a) {
    var _b = _a.store, orderStore = _b.orderStore, currentUser = _b.accountStore.currentUser, match = _a.match;
    var _c = __read(React.useState(null), 2), quote = _c[0], setQuote = _c[1];
    var classes = useCMRStyles();
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    /*eslint-disable */
    React.useEffect(function () {
        orderStore.loadOrderQuote(match.params.quoteId).then(function (quote) { return setQuote(quote); });
    }, []);
    /*eslint-enable */
    return (React.createElement(Grid, { container: true, direction: 'column' },
        (currentUser._id && (React.createElement(Link, { to: "/open-orders" },
            React.createElement(MainButton, { id: 'back_to_orders', title: langForm.t('form_btn_back') + " " + langApp.t('main_orders').toLowerCase(), variant: "outlined", v1: true, icon: React.createElement(ArrowBackIosIcon, null) })))) ||
            null,
        React.createElement(List, { className: classes.ul, style: {
                padding: theme.spacing(2),
                width: 740,
                position: 'relative',
                margin: 'auto',
                transform: 'scale(2)',
                transformOrigin: 'top',
            } }, quote ? (React.createElement("div", { dangerouslySetInnerHTML: {
                __html: quote.templateCMR || '',
            } })) : (React.createElement(Grid, { justify: 'center' },
            React.createElement(CircularIndeterminate, null))))));
}));
