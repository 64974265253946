var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from './base';
export var USER_ROLES_BASE = "\n " + BASE_QUERY + "\n       name\n       description\n       tools{\n            general\n            admin\n            settings\n       }\n        access\n        isPublic\n";
export var FetchUserRoles = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query userRoles($filter: MainFilter) {\n        userRoles(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query userRoles($filter: MainFilter) {\n        userRoles(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), USER_ROLES_BASE);
export var UpdateUserRole = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateUserRole($input: UserRolesInputUpdate!) {\n        updateUserRole(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateUserRole($input: UserRolesInputUpdate!) {\n        updateUserRole(input: $input) {\n            ", "\n        }\n    }\n"])), USER_ROLES_BASE);
export var CreateUserRole = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation createUserRole($input: UserRolesInput!) {\n        createUserRole(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createUserRole($input: UserRolesInput!) {\n        createUserRole(input: $input) {\n            ", "\n        }\n    }\n"])), USER_ROLES_BASE);
export var DeleteUserRole = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteUserRole($_id: MongoId!) {\n        deleteUserRole(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteUserRole($_id: MongoId!) {\n        deleteUserRole(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
