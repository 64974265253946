import { applySnapshot, types } from 'mobx-state-tree';
import { MaybeEmptyModel } from '../stores/common';
import { OrderMore } from './Order';
import { OrderQuoteFastPaymentStatus, OrderQuoteStatus } from './OrderQuotes';
export var OrderQuote = types
    .model('OrderQuote', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    approvedAt: types.maybeNull(types.optional(types.string, '')),
    order: types.optional(MaybeEmptyModel(OrderMore), function () {
        return OrderMore.create({
            loadingType: [],
            transportations: [],
            vehicleType: [],
        });
    }),
    status: types.optional(types.enumeration('FullTrackLoad', Object.values(OrderQuoteStatus)), OrderQuoteStatus.Requested),
    fastPaymentStatus: types.optional(types.enumeration('FullTrackLoad', Object.values(OrderQuoteFastPaymentStatus)), OrderQuoteFastPaymentStatus.NotAvailable),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
