import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const MainTabsRoutesC = React.lazy(() => import('@izi-logistics/common/lib/pages/warehouse/More'));
const WarehousePageC = React.lazy(() => import('@izi-logistics/common/lib/pages/warehouse'));

export default function MainTabsRoutes(props) {
  return (
    <PreloadingComponent>
      <MainTabsRoutesC {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
export function WarehousePage(props) {
  return (
    <PreloadingComponent>
      <WarehousePageC {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
