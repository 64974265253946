var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { BaseLisProvider } from './store';
import BaseListComponent from './BaseListComponent';
export var BaseList = function (_a) {
    var options = _a.options, props = __rest(_a, ["options"]);
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var events = [
        {
            name: 'click',
            ctx: document.body,
            callback: function (e) {
                var _a;
                if (e.target.getAttribute('data-fresh') === 'izi') {
                    if (Array.isArray(props.config.events)) {
                        //TODO: should refactoring tables to refresh depend from notification event
                        if ((_a = props.config.events) === null || _a === void 0 ? void 0 : _a.includes(e.target.getAttribute('data-event'))) {
                            setTimeout(function () { return setUpdated(Date.now()); });
                        }
                    }
                    else {
                        setTimeout(function () { return setUpdated(Date.now()); });
                    }
                }
            },
        },
    ];
    useEffect(function () {
        events.forEach(function (ev) { return ev.ctx.addEventListener(ev.name, ev.callback); });
        return function () {
            events.forEach(function (ev) { return ev.ctx.removeEventListener(ev.name, ev.callback); });
        };
    }, []);
    return (React.createElement(BaseLisProvider, __assign({}, options, { config: props.config, key: updated }),
        React.createElement(BaseListComponent, __assign({}, props, { uselessFilter: options.defaultFilter }))));
};
