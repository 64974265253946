var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { ACTION_CHANGE } from '../../components/BasicList';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { MainButton } from '../../components';
import { AddMembersForm } from './AddMembersForm';
import BaseTooltip from '../../components/BaseTooltip';
export var AddMembersFormModal = function (_a) {
    var props = __rest(_a, []);
    var langMenu = useTranslation(KEYS.MENU);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var onChange = props.onChange;
    var formRef = React.useRef();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItems;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItems = _a.sent();
                    if (!createdItems || (Array.isArray(createdItems) && !createdItems.length)) {
                        throw Error(langToast.t('toast_form_not_valid'));
                    }
                    if (onChange)
                        onChange(createdItems[0], ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = React.createElement(AddMembersForm, __assign({ ref: formRef }, props, { isModalForm: true }));
    return (React.createElement(BasicDialog, { askCanClose: true, withoutWrapper: true, body: dialogBody, isMiddleBody: true, v1: true, title: props.title || (props.onAddCompany ? langMenu.t('menu_sidebar_carriers') : langApp.t('team_label')), onOk: onOk, actionOkTitle: props.item && props.item._id ? '' : langForm.t('form_btn_invite'), dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: props.handleClickOpen
            ? props.handleClickOpen
            : function (handleClickOpen) {
                return ((props.item && props.item._id && (React.createElement(BaseTooltip, { title: langApp.t('team_label_edit') },
                    React.createElement(IconButton, { onClick: handleClickOpen, color: 'primary', id: 'edit-btn', style: { padding: 0 } },
                        React.createElement(EditIcon, null))))) || React.createElement(MainButton, { onClick: handleClickOpen, color: 'primary', title: langApp.t('team_new') }));
            } }));
};
