var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Divider, Grid, useMediaQuery } from '@material-ui/core';
import { OrderCargoType, } from '../../../models';
import EmptyItem from './Empty';
import { theme } from '../../../theme';
import { TextOverflow } from '../../TextOverflow';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
export var listCargo = function (index, el, order) {
    var dimension = el.dimension || (el.palletType && el.palletType.dimension) || '';
    return [
        {
            key: 'cargoId',
            size: 2,
            render: function (value) {
                return i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_cargo_label') + " " + (index + 1) + ": " + value;
            },
        },
        {
            key: 'productType',
            size: 3,
            render: function (productType) { return (productType && productType.name) || '-'; },
        },
        {
            key: '_id',
            size: 2,
            render: function () {
                return ((el.palletType && el.palletType.length && "" + el.palletType.length + dimension) || '-') + "/" + ((el.palletType && el.palletType.width && "" + el.palletType.width + dimension) || '-') + "/" + ((el.height && "" + el.height + dimension) || '-');
            },
        },
        {
            key: 'weight',
            size: 1,
            render: function () {
                return (el.weight &&
                    "" + el.weight + ((el.weightMeasure &&
                        i18n.getResource(i18n.language, KEYS.ORDER_FORM, "create_order_cargo_weight_" + (el.weightMeasure || '') + "_short")) ||
                        '')) ||
                    '-';
            },
        },
        {
            key: 'amount',
            size: 1,
            render: function () { return (el.amount && el.amount + "pcs") || '-'; },
        },
        {
            key: 'package',
            size: 2,
            render: function (value) { return (value && value.name) || '-'; },
        },
        {
            key: '_id',
            size: 1,
            render: function () { return (order && order.cargoType === OrderCargoType.PTLTrackLoad ? 'PTL' : 'FTL') || '-'; },
        },
    ];
};
export var OrderCargoes = function (_a) {
    var order = _a.order, v1 = _a.v1, props = __rest(_a, ["order", "v1"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var empty = React.createElement(EmptyItem, null);
    var mainBodyStyles = { padding: theme.spacing(2) };
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    if (v1) {
        Object.assign(mainBodyStyles, { padding: theme.spacing(3, 4) });
    }
    (order.transportations || []).forEach(function (_a) {
        var cargoes = _a.cargoes, routes = _a.routes, e = __rest(_a, ["cargoes", "routes"]);
        cargoes.forEach(function (f) { return (f.orderTransportation = e); });
    });
    var cargoes = (order.transportations || []).map(function (e) { return e.cargoes; }).reduce(function (a, b) { return __spread(a, b); }, []);
    if (!cargoes.length && props.cargoes)
        cargoes = props.cargoes;
    if (!cargoes.length)
        return null;
    var totalSize = listCargo(0, {}, order).length;
    if (order.transportations && order.transportations.length > 1) {
        return (React.createElement(React.Fragment, null,
            (v1 && React.createElement(Divider, { style: { width: '100%' } })) || null,
            React.createElement(Grid, { xs: 12, container: true, style: mainBodyStyles }, order.transportations.map(function (transportation, index) { return (React.createElement(Grid, { container: true, item: true, xs: 12, key: transportation._id, direction: 'column' },
                React.createElement(TextOverflow, null,
                    langOrderForm.t('create_order_transportation_label'),
                    " ",
                    index + 1),
                React.createElement(Grid, { xs: 12, container: true, style: mainBodyStyles }, transportation.cargoes.map(function (el, index) { return (React.createElement(Grid, { key: el._id, container: true, alignItems: 'center', spacing: 1, justify: 'space-between' }, listCargo(index, el, order).map(function (e, indexItem) { return (React.createElement(Grid, { item: true, md: e.size, xs: 12 },
                    React.createElement(TextOverflow, { variant: 'subtitle1', key: e.key, style: __assign({}, (indexItem === totalSize - 1 ? { textAlign: isSmall ? 'left' : 'right' } : null)) }, el[e.key] ? e.render(el[e.key]) : empty))); }))); })))); }))));
    }
    return (React.createElement(React.Fragment, null,
        (v1 && React.createElement(Divider, { style: { width: '100%' } })) || null,
        React.createElement(Grid, { xs: 12, container: true, style: mainBodyStyles }, cargoes.map(function (el, index) { return (React.createElement(Grid, { key: el._id, container: true, alignItems: 'center', spacing: 1, justify: 'space-between' }, listCargo(index, el, order).map(function (e, indexItem) { return (React.createElement(Grid, { item: true, md: e.size, xs: 12 },
            React.createElement(TextOverflow, { variant: 'subtitle1', key: e.key, style: __assign({}, (indexItem === totalSize - 1 ? { textAlign: isSmall ? 'left' : 'right' } : null)) }, el[e.key] ? e.render(el[e.key]) : empty))); }))); }))));
};
