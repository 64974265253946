var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus } from '../stores/common';
export var TERMS_REQUEST;
(function (TERMS_REQUEST) {
    // ORDER_SHIPPING_APPROVED_FOR_CUSTOMER = 'ORDER_SHIPPING_APPROVED_FOR_CUSTOMER',
    TERMS_REQUEST["ORDER_SHIPPING_APPROVED_FOR_CARRIER"] = "ORDER_SHIPPING_APPROVED_FOR_CARRIER";
    TERMS_REQUEST["ORDER_SHIPPING_APPROVED_FOR_CARRIER_FROM_CUSTOMER_FORWARDER"] = "ORDER_SHIPPING_APPROVED_FOR_CARRIER_FROM_CUSTOMER_FORWARDER";
    TERMS_REQUEST["ORDER_PUBLISHED"] = "ORDER_PUBLISHED";
    TERMS_REQUEST["QUOTE_REQUESTED"] = "QUOTE_REQUESTED";
})(TERMS_REQUEST || (TERMS_REQUEST = {}));
export var Terms = types
    .compose(AsyncActionStatus, types.model('Terms', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.optional(types.string, '')),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    companyRelated: types.maybeNull(types.optional(types.string, '')),
    // companyRelated: types.maybeNull(MaybeEmptyModel(BaseModel)),
    request: types.optional(types.array(types.union(types.string, types.number)), []),
}))
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Terms.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); });
