var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { langOptions } from './utils';
import resources from './locales';
export var KEYS;
(function (KEYS) {
    KEYS["AUTH"] = "Auth";
    KEYS["CHAT"] = "Chat";
    KEYS["FORM"] = "Form";
    KEYS["FORM_LOCATIONS"] = "FormLocations";
    KEYS["INFO"] = "Info";
    KEYS["ERRORS"] = "Errors";
    KEYS["APP"] = "App";
    KEYS["MENU"] = "Menu";
    KEYS["OFFER_FORM"] = "OfferForm";
    KEYS["ORDER_FORM"] = "OrderForm";
    KEYS["ORDER_LIST"] = "OrderList";
    KEYS["SETTINGS"] = "Settings";
    KEYS["TOAST"] = "Toast";
    KEYS["COUNTRIES"] = "Countries";
})(KEYS || (KEYS = {}));
var options = __assign(__assign({}, langOptions), { resources: resources });
export var i18nOptions = options;
export var i18n = _i18n;
export default {
    init: function (resources) {
        Object.keys(resources).forEach(function (lang) {
            if (!options.resources[lang])
                options.resources[lang] = {};
            Object.keys(resources[lang]).forEach(function (key) {
                if (!options.resources[lang][key])
                    options.resources[lang][key] = {};
                Object.assign(options.resources[lang][key], resources[lang][key]);
            });
        });
        i18n.use(initReactI18next).init(options);
        return i18n;
    },
};
