import { applySnapshot, types } from 'mobx-state-tree';
export var OrderPreparingStatuses = types
    .model('OrderPreparingStatuses', {
    _id: types.optional(types.string, ''),
    name: types.maybeNull(types.optional(types.array(types.string), [])),
    order: types.maybeNull(types.number),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
