var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var StatisticTotalAvgFtlPricePerKm = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query statisticTotalAvgFtlPricePerKm($filter: MainFilter) {\n    statisticTotalAvgFtlPricePerKm(filter: $filter) {\n      data {\n        time\n        avg_price_distance\n        sorting_deliveryRegion\n        sorting_pickupRegion\n        sorting_countryPickup\n        sorting_countryDelivery\n      }\n      count\n      totalAVGPrice\n    }\n  }\n"], ["\n  query statisticTotalAvgFtlPricePerKm($filter: MainFilter) {\n    statisticTotalAvgFtlPricePerKm(filter: $filter) {\n      data {\n        time\n        avg_price_distance\n        sorting_deliveryRegion\n        sorting_pickupRegion\n        sorting_countryPickup\n        sorting_countryDelivery\n      }\n      count\n      totalAVGPrice\n    }\n  }\n"])));
export var StatisticsTotalPrices = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query statisticTotalPrices($filter: MainFilter) {\n    statisticTotalPrices(filter: $filter) {\n      data {\n        _id\n        time\n        max_sorting_price\n        min_sorting_price\n        avg_sorting_price\n        max_sorting_totalWeight\n        min_sorting_totalWeight\n        avg_sorting_totalWeight\n        max_price_distance\n        min_price_distance\n        avg_price_distance\n        currency\n      }\n    }\n  }\n"], ["\n  query statisticTotalPrices($filter: MainFilter) {\n    statisticTotalPrices(filter: $filter) {\n      data {\n        _id\n        time\n        max_sorting_price\n        min_sorting_price\n        avg_sorting_price\n        max_sorting_totalWeight\n        min_sorting_totalWeight\n        avg_sorting_totalWeight\n        max_price_distance\n        min_price_distance\n        avg_price_distance\n        currency\n      }\n    }\n  }\n"])));
export var StatisticsTotalRealTimeSped = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query statisticTotalRealTimeSpend($filter: MainFilter) {\n    statisticTotalRealTimeSpend(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n    }\n  }\n"], ["\n  query statisticTotalRealTimeSpend($filter: MainFilter) {\n    statisticTotalRealTimeSpend(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n    }\n  }\n"])));
export var StatisticsTotalInTime = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query statisticTotalInTime($filter: MainFilter) {\n    statisticTotalInTime(filter: $filter) {\n      totalPickup {\n        _id\n        time\n        value\n      }\n      totalDelivery {\n        _id\n        time\n        value\n      }\n    }\n  }\n"], ["\n  query statisticTotalInTime($filter: MainFilter) {\n    statisticTotalInTime(filter: $filter) {\n      totalPickup {\n        _id\n        time\n        value\n      }\n      totalDelivery {\n        _id\n        time\n        value\n      }\n    }\n  }\n"])));
export var StatisticsTotalOrderCostPerDestination = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query statisticTotalOrderCostPerDestination($filter: MainFilter) {\n    statisticTotalOrderCostPerDestination(filter: $filter) {\n      data {\n        _id\n        time\n        sorting_totalWeight\n        country\n        sorting_price\n        color\n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalOrderCostPerDestination($filter: MainFilter) {\n    statisticTotalOrderCostPerDestination(filter: $filter) {\n      data {\n        _id\n        time\n        sorting_totalWeight\n        country\n        sorting_price\n        color\n      }\n      count\n    }\n  }\n"])));
export var StatisticTotalOrdersDelivered = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query statisticTotalOrdersDelivered($filter: MainFilter) {\n    statisticTotalOrdersDelivered(filter: $filter) {\n      totalPickup {\n        _id\n        time\n        value\n      }\n      totalDelivery {\n        _id\n        time\n        value\n      }\n      totalCanceled {\n        _id\n        time\n        value\n      }\n    }\n  }\n"], ["\n  query statisticTotalOrdersDelivered($filter: MainFilter) {\n    statisticTotalOrdersDelivered(filter: $filter) {\n      totalPickup {\n        _id\n        time\n        value\n      }\n      totalDelivery {\n        _id\n        time\n        value\n      }\n      totalCanceled {\n        _id\n        time\n        value\n      }\n    }\n  }\n"])));
export var StatisticTotalWeightOrderCountCancelledPickup = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query statisticTotalWeightOrderCountCancelledPickup($filter: MainFilter) {\n    statisticTotalWeightOrderCountCancelledPickup(filter: $filter) {\n      totalApprovedCanceled {\n        _id\n        country\n        value\n        color\n      }\n      totalOrdersPickup {\n        _id\n        country\n        value\n        color\n      }\n      totalWeightPickup {\n        _id\n        country\n        value\n        color\n      }\n    }\n  }\n"], ["\n  query statisticTotalWeightOrderCountCancelledPickup($filter: MainFilter) {\n    statisticTotalWeightOrderCountCancelledPickup(filter: $filter) {\n      totalApprovedCanceled {\n        _id\n        country\n        value\n        color\n      }\n      totalOrdersPickup {\n        _id\n        country\n        value\n        color\n      }\n      totalWeightPickup {\n        _id\n        country\n        value\n        color\n      }\n    }\n  }\n"])));
export var StatisticTotalPriceApproved = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query statisticTotalPriceApproved($filter: MainFilter) {\n    statisticTotalPriceApproved(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalPriceApproved($filter: MainFilter) {\n    statisticTotalPriceApproved(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n      count\n    }\n  }\n"])));
export var StatisticTotalAffiliateRevenue = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query totalAffiliateRevenue($filter: MainFilter) {\n    totalAffiliateRevenue(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n      count\n    }\n  }\n"], ["\n  query totalAffiliateRevenue($filter: MainFilter) {\n    totalAffiliateRevenue(filter: $filter) {\n      data {\n        _id\n        time\n        value\n      }\n      count\n    }\n  }\n"])));
export var StatisticTotalUserComparisonActivity = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query statisticTotalUserComparisonActivity($filter: MainFilter) {\n    statisticTotalUserComparisonActivity(filter: $filter) {\n      data {\n        _id\n        totalUserJoined\n        totalUserActivity\n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalUserComparisonActivity($filter: MainFilter) {\n    statisticTotalUserComparisonActivity(filter: $filter) {\n      data {\n        _id\n        totalUserJoined\n        totalUserActivity\n      }\n      count\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
