var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from './base';
import { WAREHOUSE_BASE } from './warehouse/types';
export var HOLIDAY_BASE = "\n      " + BASE_QUERY + "\n      warehouse{\n        " + WAREHOUSE_BASE + "\n      }\n      start\n      end\n      name\n      description\n      repeat\n";
export var FetchHolidays = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query holidays($filter:MainFilter){\n        holidays(filter:$filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n    query holidays($filter:MainFilter){\n        holidays(filter:$filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), HOLIDAY_BASE);
export var CreateHoliday = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n mutation createHoliday($input:HolidayInput!){\n   createHoliday(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createHoliday($input:HolidayInput!){\n   createHoliday(input:$input) { \n      ", "\n    }\n  }\n"])), HOLIDAY_BASE);
export var UpdateHoliday = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n mutation updateHoliday($input:HolidayInputUpdate!){\n     updateHoliday(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateHoliday($input:HolidayInputUpdate!){\n     updateHoliday(input:$input) { \n      ", "\n    }\n  }\n"])), HOLIDAY_BASE);
export var DeleteHoliday = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteHoliday($_id: MongoId!) {\n        deleteHoliday(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteHoliday($_id: MongoId!) {\n        deleteHoliday(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
