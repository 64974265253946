import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const Dashboard = React.lazy(() => import('@izi-logistics/common/lib/pages/Dashboard'));

export default function DashboardPage(props) {
  return (
    <PreloadingComponent>
      <Dashboard {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
