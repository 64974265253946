var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { BaseUserAppTYpe } from '../../stores/common/types';
export var defaultChatUsers = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var accountStore = _a.accountStore, order = _a.order;
    if (!order)
        return [];
    var is_customer = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var is_carrier = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isForwarder = accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isForwarderOwnOffer = isForwarder && ((_b = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _b === void 0 ? void 0 : _b.createdBy.company._id) === accountStore.currentCompany._id;
    if (isForwarder &&
        !((_c = order.forwarders) === null || _c === void 0 ? void 0 : _c.find(function (e) { return (e.company._id || e.company).toString() === accountStore.currentCompany._id; }))) {
        return __spread(order.forwarders, [(_d = order.latestQuote) === null || _d === void 0 ? void 0 : _d.createdBy]);
        // if (order.manager?.email) return [order.manager].filter((e: any) => e?.email);
        // return [order.createdBy].filter((e: any) => e?.email);
    }
    var list = is_carrier || isForwarder
        ? is_carrier && ((_e = order.forwarders) === null || _e === void 0 ? void 0 : _e.length)
            ? __spread(order.forwarders, [is_carrier && ((_f = order.latestQuote) === null || _f === void 0 ? void 0 : _f.createdBy)]) : [
            (is_carrier || isForwarderOwnOffer) && order.createdBy,
            ((is_carrier || isForwarderOwnOffer) && order.createdBy._id !== order.manager._id && order.manager) ||
                false,
            !isForwarderOwnOffer && ((_g = order.latestQuote) === null || _g === void 0 ? void 0 : _g.createdBy),
        ].filter(function (e) { return e; })
        : is_customer
            ? (((_h = accountStore.currentUser.forwarders) === null || _h === void 0 ? void 0 : _h.length) && __spread(accountStore.currentUser.forwarders, [order === null || order === void 0 ? void 0 : order.createdBy])) ||
                (order.latestQuote && [order.latestQuote.createdBy]) ||
                []
            : [];
    if (is_carrier && ((_k = (((_j = accountStore.currentCompany) === null || _j === void 0 ? void 0 : _j.companiesParents) || [])) === null || _k === void 0 ? void 0 : _k.includes(((_l = order.company) === null || _l === void 0 ? void 0 : _l._id) || ((_o = (_m = order.createdBy) === null || _m === void 0 ? void 0 : _m.company) === null || _o === void 0 ? void 0 : _o._id)))) {
        list = [
            is_carrier && order.createdBy,
            (is_carrier && order.createdBy._id !== order.manager._id && order.manager) || false,
            (_p = order.latestQuote) === null || _p === void 0 ? void 0 : _p.createdBy,
        ].filter(function (e) { return e; });
    }
    else if (is_customer &&
        (((_s = (_r = (_q = order.latestQuote) === null || _q === void 0 ? void 0 : _q.createdBy) === null || _r === void 0 ? void 0 : _r.company) === null || _s === void 0 ? void 0 : _s.companiesParents) || []).includes(accountStore.currentCompany._id)) {
        list = [(_t = order.latestQuote) === null || _t === void 0 ? void 0 : _t.createdBy];
    }
    return list.filter(function (e) { return e === null || e === void 0 ? void 0 : e.email; });
};
