var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Grid, useMediaQuery } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as material from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import { ErrorBoundary, onPreventEvent, TextOverflow } from '../components';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { AvatarItemView } from './Account/Avatar';
import { GlobalSearch } from './GlobalSearch';
import { KEYS } from '../i18n';
import { Logo } from './Logo';
import { BaseUserAppTYpe } from '../stores/common';
import { globalUtils, IS_DEV_ENV } from '../utils';
import { SOUND_KEY } from './Sounds';
import BaseTooltip from './BaseTooltip';
import { useIconStyles, useMainAreaStyles } from '../styles';
import PreloadingComponent from './PreloadingComponent';
import BusinessIcon from '@material-ui/icons/Business';
import { GlobalSidebarChannelsSocket } from './Socket/Chat/ChannelsChat/store';
import { theme } from '../theme';
import { withRouter } from 'react-router';
var JoinAffiliateModal = React.lazy(function () { return import('./JoinAffiliateModal'); });
var ChatSideBar = React.lazy(function () { return import('./Socket/Chat/SideBar'); });
var SettingsDrawer = React.lazy(function () { return import('./Settings/index'); });
var GuidesDialog = React.lazy(function () { return import('./Guides/index'); });
var Notifications = React.lazy(function () { return import('./Socket/Notifications'); });
var UserSummaryToDo = React.lazy(function () { return import('./UserSummaryToDo'); });
var UserSummaryRateCa = React.lazy(function () { return import('./UserSummaryRateCa'); });
var UserSettingsTerm = React.lazy(function () { return import('./UserSettingsPaymentTerm'); });
var NotificationMessageRequest = React.lazy(function () { return import('./NotificationMessageRequest'); });
var WizzardSettingsModal = React.lazy(function () { return import('./WizzardSettingsModal'); });
var CarrierDutyModal = React.lazy(function () { return import('./CarrierDutyModal'); });
var LoginWithEmail = React.lazy(function () { return import('./LoginWithEmail'); });
var CountryFiltersDialog = React.lazy(function () { return import('./Settings/OrderPreferenceSettings/CountryFiltersDialog'); });
var BasicTour = React.lazy(function () { return import('./BasicTour'); });
var SwipeableDrawer = material.SwipeableDrawer;
var Hidden = material.Hidden;
var Box = material.Box;
var IconButton = material.IconButton, Card = material.Card;
var MenuMui = material.Menu;
var AppBarMui = material.AppBar;
var isNewFontSize = 1; // process.env.REACT_APP_APP_ENV === 'development';
var drawerWidth = 300;
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return ({
        root: {
            display: 'flex',
        },
        menuIcon: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                marginLeft: theme.spacing(0.3),
            },
            _a),
        appBarToolBar: (_b = {
                boxShadow: 'none',
                borderBottom: "1px solid " + theme.palette.grey[300],
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0)
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(0, 0, 0, 0.5),
                maxWidth: '100vw',
            },
            _b),
        appBarUserTools: (_c = {
                maxWidth: 'calc(100% - 330px)'
            },
            _c[theme.breakpoints.down('md')] = {
                maxWidth: 'calc(100% - 200px)',
            },
            _c),
        appBar: {
            boxShadow: 'none',
            zIndex: 1201,
            backgroundColor: theme.palette.background.paper,
            // zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: "calc(100% - " + drawerWidth + "px)",
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawer: (_d = {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap'
            },
            _d[theme.breakpoints.down('xs')] = {
                '&.MuiDrawer-docked': {
                    '& ul': {
                        paddingTop: theme.spacing(3),
                        '&.MuiList-root': {
                            '& .MuiListItem-root': {
                                padding: theme.spacing(1, 0),
                            },
                        },
                    },
                },
            },
            _d),
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '& .bottom-menu': {
                '& .lang-menu img': {
                    marginLeft: theme.spacing(-1),
                    marginRight: theme.spacing(1),
                },
                '& .MuiIconButton-root': {
                    marginLeft: theme.spacing(-1),
                    marginRight: theme.spacing(1),
                },
            },
        },
        drawerClose: (_e = {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })
            },
            _e[theme.breakpoints.down('sm')] = {
                '-ms-overflow-style': ' none',
                'scrollbar-width': 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
            _e.overflowX = 'hidden',
            _e.width = theme.spacing(7) + 1,
            _e[theme.breakpoints.up('sm')] = {
                width: theme.spacing(9) + 1,
            },
            _e['& ul'] = {
                paddingTop: theme.spacing(2),
                width: '100%',
                '&.MuiList-root': {
                    '& .sub-menu-stat': {
                        display: 'block',
                        margin: 0,
                    },
                    '& .Mui-selected': {
                        '& .sub-menu-stat': {
                            span: {
                                color: theme.palette.primary.main,
                            },
                        },
                    },
                    '& .menu-collapse': {
                        '&.Mui-selected': {
                            '& .MuiGrid-root': {
                                borderBottom: "2px solid " + theme.palette.primary.main,
                            },
                        },
                    },
                    '& .MuiListItemIcon-root': {
                        width: '100%',
                    },
                    '& a': {
                        '& .MuiListItem-root': {
                            '& .MuiTypography-root': {
                                display: 'none',
                            },
                            '& h6': {
                                display: 'none',
                            },
                        },
                    },
                    '& .MuiListItem-root': {
                        padding: theme.spacing(1),
                        '& .MuiListItem-root': {},
                        '& h6': {
                            display: 'none',
                        },
                        '& .sub-menu-title': {
                            display: 'none',
                        },
                    },
                    '& #report-btn': {
                    // ...((isNewFontSize && { marginLeft: -theme.spacing(0.65) }) || {}),
                    },
                    '& .lang-menu': {
                        '& .lang': {
                            display: 'none',
                        },
                        '& img': __assign({}, ((isNewFontSize && { marginLeft: -theme.spacing(2) }) || {})),
                        '& .MuiInput-formControl': {
                            '& svg': {
                                display: 'none',
                            },
                        },
                    },
                    '& .icon-more': {
                        display: 'none',
                    },
                },
            },
            _e),
        toolbar: __assign(__assign({ display: 'flex', alignItems: 'right', justifyContent: 'right', padding: theme.spacing(0, 1), minHeight: 76 }, theme.mixins.toolbar), (_f = {}, _f[theme.breakpoints.down('xs')] = {
            padding: theme.spacing(0.5),
        }, _f)),
        content: (_g = {
                flexGrow: 1,
                width: '90%',
                padding: theme.spacing(3, 1)
            },
            // width: '200px', // find out why it works
            _g[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(3, 0.5),
            },
            _g),
        contentMax: (_h = {
                maxWidth: 1320,
                padding: theme.spacing(3)
            },
            _h[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(3, 0.5),
                width: window.innerWidth - theme.spacing(2),
                maxWidth: window.innerWidth - theme.spacing(2),
                margin: 'auto',
            },
            _h),
        contentMainOpen: (_j = {},
            _j[theme.breakpoints.up('sm')] = {
                paddingRight: theme.spacing(14),
            },
            _j),
        contentMainClose: (_k = {},
            _k[theme.breakpoints.up('sm')] = {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(20),
            },
            _k),
        contentMainWithoutChat: (_l = {},
            _l[theme.breakpoints.up('sm')] = {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
            },
            _l),
        drawerPaper: {
            width: drawerWidth,
        },
        card: (_m = {
                margin: theme.spacing(0),
                padding: theme.spacing(2)
            },
            _m[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(0.5),
            },
            _m),
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        avatar: {
            backgroundColor: theme.palette.primary.main,
        },
        userInfo: {
            marginBottom: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        loading: {
            'z-index': 9999,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
        },
        icon: {
            color: theme.palette.secondary[900],
        },
        switchAccount: {
            fontSize: theme.spacing(2),
        },
        userName: {
            fontSize: theme.spacing(2),
            color: theme.palette.secondary.dark,
        },
        hide: {
            display: 'none',
        },
        drawerHeader: __assign(__assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(0, 1) }, theme.mixins.toolbar), { justifyContent: 'flex-end' }),
        appBarForwarder: (_o = {
                background: theme.palette.grey['600'],
                '& * ': {
                    color: theme.palette.common.white,
                    fill: theme.palette.common.white,
                },
                '& .MuiTypography-caption': {
                    color: theme.palette.common.white + "!important",
                },
                '& .MuiOutlinedInput-root': {
                    '& input': {
                        color: theme.palette.common.white,
                    },
                },
                '& .app-logo': {
                    '& path': {
                    // fill: theme.palette.common.white,
                    },
                },
                '& .MuiDivider-root': {
                    borderColor: theme.palette.common.white,
                },
                '& #global-notifications': {
                    '& path': {
                        color: theme.palette.common.white,
                        fill: theme.palette.common.white,
                    },
                }
            },
            _o[theme.breakpoints.down('md')] = {
                '& #global-notifications': {
                    '& path': {
                        fill: 'initial',
                    },
                    '& #global-notifications-content': {
                        background: theme.palette.grey['600'],
                    },
                },
            },
            _o[theme.breakpoints.up('sm')] = {
                '& #global-notifications': {
                    '& #global-notifications-content': {
                        background: theme.palette.grey['600'],
                    },
                },
            },
            _o['& #global-search'] = {
                '& * ': {
                    background: theme.palette.grey['600'],
                },
                '& input': {
                    '&::placeholder': {
                        opacity: 1,
                        color: theme.palette.common.white + "!important",
                    },
                },
                '& fieldset': {
                    background: 'none',
                    borderColor: theme.palette.common.white,
                    '& *': {
                        background: 'none',
                    },
                },
            },
            _o),
        chatIconBox: {
            backgroundColor: theme.palette.primary.main,
            '& path': {
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
            },
        },
        chatIconBoxForwarder: {
            backgroundColor: theme.palette.primary.main,
        },
    });
});
var AccountInfo = inject('store')(observer(function (props) {
    var _a, _b, _c, _d;
    var store = props.store, open = props.open, anchorEl = props.anchorEl, onClick = props.onClick, toggleSettingsDrawer = props.toggleSettingsDrawer;
    var globalIconClasses = useIconStyles();
    var classes = useStyles();
    var history = useHistory();
    var accountStore = store.accountStore;
    var langAuth = useTranslation(KEYS.AUTH);
    var langMenu = useTranslation(KEYS.MENU);
    var langApp = useTranslation(KEYS.APP);
    var _e = accountStore.currentUser, firstName = _e.firstName, lastName = _e.lastName, appType = _e.appType;
    var userFullName = (firstName || '') + " " + (lastName || '');
    var companyName = accountStore.currentCompany.name || '';
    var userAvatar = (React.createElement("span", { style: { width: 40, height: 40 } },
        React.createElement(AvatarItemView, { user: accountStore.currentUser })));
    var _f = __read(React.useState(false), 2), affiliateModalOpen = _f[0], setAffiliateModalOpen = _f[1];
    var affiliateRequestAvailable = !(((_b = (_a = store === null || store === void 0 ? void 0 : store.accountStore) === null || _a === void 0 ? void 0 : _a.currentUser) === null || _b === void 0 ? void 0 : _b.appType) || []).includes(BaseUserAppTYpe.AFFILIATE);
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { id: 'header-user-menu-details', component: Grid, container: true, style: { width: 'initial', maxWidth: 'calc(100% - 70px)' }, direction: "row", justify: "flex-end", alignItems: "center" },
            React.createElement("div", { style: { maxWidth: 70 } },
                React.createElement(Hidden, { mdDown: true },
                    React.createElement(TextOverflow, { variant: "subtitle2", align: "right", style: { maxWidth: 140 } }, userFullName),
                    React.createElement(TextOverflow, { variant: "body2", align: "right", style: { maxWidth: 140 } }, companyName))),
            React.createElement(IconButton, { onClick: function (e) { return onClick(e); }, edge: "end", "data-id": 'izi-user-account-menu' }, userAvatar)),
        React.createElement(MenuMui, { id: 'dropdown-user-menu-details', open: open, keepMounted: true, anchorEl: anchorEl, onClick: function (e) { return onClick(e); } },
            React.createElement(Card, { elevation: 0, className: classes.card }, store.accountStore.isLoggedIn ? (React.createElement(MenuList, null,
                React.createElement(MenuItem, { "data-id": "user-account-details", onClick: function (event) {
                        history.push('/account/details');
                    } },
                    React.createElement(ListItemIcon, null,
                        React.createElement(AccountCircleOutlinedIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, { variant: "inherit" }, langApp.t('profile_label'))),
                props.hasAffiliate ? (affiliateRequestAvailable ? (React.createElement(MenuItem, { "data-id": "user-account-afiiliate", onClick: function (e) {
                        setAffiliateModalOpen(true);
                    } },
                    React.createElement(ListItemIcon, null,
                        React.createElement(BusinessIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, { variant: "inherit" }, langMenu.t('menu_join_affiliate_program')))) : (React.createElement(Link, { to: { pathname: ((_d = (_c = store === null || store === void 0 ? void 0 : store.accountStore) === null || _c === void 0 ? void 0 : _c.currentUser) === null || _d === void 0 ? void 0 : _d.affiliateUrl) || '' }, target: "_blank", "data-id": "user-account-open-afiiliate", rel: "noopener noreferrer", style: { textDecoration: 'none' } },
                    React.createElement(MenuItem, null,
                        React.createElement(ListItemIcon, null,
                            React.createElement(BusinessIcon, { className: globalIconClasses.menuIcon })),
                        React.createElement(Typography, { variant: "inherit" }, langMenu.t('menu_open_affiliate')))))) : null,
                React.createElement(MenuItem, { onClick: toggleSettingsDrawer('right', true), "data-id": "user-account-settings" },
                    React.createElement(ListItemIcon, null,
                        React.createElement(SettingsIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, { variant: "inherit" }, langMenu.t('menu_sidebar_settings_title'))),
                React.createElement(MenuItem, { onClick: accountStore.logout, "data-id": "user-account-logout" },
                    React.createElement(ListItemIcon, null,
                        React.createElement(ExitToAppOutlinedIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, null, langMenu.t('menu_account_sign_out'))),
                (!accountStore.showSupport && (React.createElement(MenuItem, { onClick: function () {
                        var _a, _b;
                        accountStore.toggleShowSupport();
                        var parentNode = (_b = (_a = document.getElementById('launcher')) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.parentNode;
                        if (parentNode) {
                            parentNode.style.display = '';
                        }
                    }, "data-id": "support" },
                    React.createElement(ListItemIcon, null,
                        React.createElement(HelpOutlineIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, null, langApp.t('app_help'))))) ||
                    null,
                React.createElement(Divider, { variant: "middle" }),
                React.createElement(MenuItem, { style: { padding: theme.spacing(2) }, "data-id": "user-account", onClick: function (event) {
                        history.push('/account');
                    } },
                    React.createElement(ListItemIcon, null, userAvatar),
                    React.createElement(Grid, { container: true, direction: "column" },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { className: classes.userName }, userFullName)),
                        React.createElement(Grid, { item: true },
                            React.createElement(TextOverflow, null, appType.map(function (e) { return langApp.t("user_app_type_" + e.toLowerCase()); }).join(', '))))))) : (React.createElement(MenuList, null,
                React.createElement(MenuItem, { onClick: function (event) {
                        history.push('/login');
                    } },
                    React.createElement(ListItemIcon, null,
                        React.createElement(AccountCircleOutlinedIcon, { className: globalIconClasses.menuIcon })),
                    React.createElement(Typography, { variant: "inherit" }, langAuth.t('login_page_log_in'))))))),
        (props.hasAffiliate && (React.createElement(PreloadingComponent, { withoutLoader: true },
            React.createElement(JoinAffiliateModal, { config: props.config, open: affiliateModalOpen, handleClose: function (e) { return setAffiliateModalOpen(false); } })))) ||
            null));
}));
var isLocal = IS_DEV_ENV;
var timeout;
export var MainLayout = withRouter(inject('store')(observer(function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g, _h, _j, _k, _l, _m;
    var titleContent = props.titleContent, pageContent = props.pageContent, sideBarMenuContent = props.sideBarMenuContent, accountStore = props.store.accountStore;
    var langApp = useTranslation(KEYS.APP);
    var classes = useStyles();
    var classesMainArea = useMainAreaStyles();
    var _o = __read(React.useState(null), 2), anchorEl = _o[0], setAnchorEl = _o[1];
    var _p = __read(React.useState(false), 2), isProfileOpen = _p[0], setOpen = _p[1];
    var _q = __read(React.useState(false), 2), isLoaded = _q[0], setIsLoaded = _q[1];
    React.useEffect(function () {
        var onPageLoad = function () {
            timeout = setTimeout(function () { return setIsLoaded(true); }, isLocal ? 0 : 500);
            return function () {
                timeout && clearTimeout(timeout);
            };
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        }
        else {
            window.addEventListener('load', onPageLoad);
            return function () {
                window.removeEventListener('load', onPageLoad);
                timeout && clearTimeout(timeout);
            };
        }
    }, []);
    var handleProfile = function (e) {
        setOpen(!isProfileOpen);
        setAnchorEl(e.currentTarget);
        globalUtils.sounds.play(SOUND_KEY.BUTTON_SLIDE_CLICK);
    };
    var isDrawerOpen = accountStore.menuIsOpened;
    var handleDrawer = function (e) {
        accountStore.toggleMenuVisible();
        return onPreventEvent(e);
    };
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var isOpen = isDrawerOpen && !isSmall;
    var isOpenMobile = isDrawerOpen && isSmall;
    var isCarrier = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var _r = __read(React.useState({
        right: false,
    }), 2), drawerState = _r[0], setDrawerState = _r[1];
    var toggleSettingsDrawer = function (anchor, open) { return function (event) {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[anchor] = open, _a));
        });
        if (open) {
            globalUtils.sounds.play(SOUND_KEY.MODAL_SETTINGS_OPEN);
        }
        else {
            globalUtils.sounds.play(SOUND_KEY.MODAL_CLOSE);
        }
    }; };
    var isAffiliatePortal = ['http://localhost:3006', 'affiliate'].find(function (str) { return location.href && location.href.includes(str); });
    var isWarehouseCalendar = ['calendar'].find(function (str) { return location.href && location.href.includes(str); });
    var isWithoutChat = isWarehouseCalendar;
    var isWithoutNavbar = false;
    var chatSidebar = (accountStore.isLoggedIn && !props.isAdmin && !isAffiliatePortal && !isWithoutChat && (React.createElement(Box, { "data-id": "izi-main-chat-sidebar", display: 'flex', alignItems: 'center', justifyContent: 'center', width: isSmall ? 'none' : '80px', className: classes.chatIconBox }, IS_DEV_ENV ? null : (React.createElement(PreloadingComponent, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
        React.createElement(ChatSideBar, { config: props.config })))))) ||
        null;
    var spacing = React.createElement("span", { style: { marginRight: theme.spacing(1) } });
    var countriesValue = ((_g = (JSON.parse(JSON.stringify(accountStore.currentUser.settings.filters)) || []).find(function (e) { return e.name === 'countries'; })) === null || _g === void 0 ? void 0 : _g.value) || '';
    var isCustomerPortal = props.appType === BaseUserAppTYpe.CUSTOMER && accountStore.currentCompany.type === BaseUserAppTYpe.CUSTOMER;
    var isCarrierPortal = props.appType === BaseUserAppTYpe.CARRIER && accountStore.currentCompany.type === BaseUserAppTYpe.CARRIER;
    var GuideComponent = (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
        React.createElement(PreloadingComponent, null,
            React.createElement(GuidesDialog, { config: props.config }))));
    var toolbar = (React.createElement(Toolbar, { id: "izi-main-toolbar", className: clsx(classes.appBarToolBar, 'izi-main-toolbar'), component: Grid, container: true, direction: "row", justify: "space-between" },
        React.createElement(Grid, { item: true, xs: 3, md: 3 },
            React.createElement(Grid, { container: true, alignItems: 'center' },
                React.createElement(BaseTooltip, { title: langApp.t("collapse_" + (isOpen ? 'less' : 'more')) },
                    React.createElement(IconButton, { color: "primary", "aria-label": "menu", className: clsx(classes.menuIcon, {
                        // [classes.hide]: isOpen,
                        }), onClick: handleDrawer },
                        React.createElement(MenuIcon, null))),
                React.createElement(Logo, null))),
        React.createElement(Grid, { item: true, xs: 4, md: 5 }, (accountStore.isLoggedIn && React.createElement(GlobalSearch, { config: props.config })) || null),
        React.createElement(Grid, { item: true, xs: 5, md: 4, container: true, justify: "flex-end" },
            React.createElement(Grid, { container: true, alignItems: 'center', className: clsx((_a = {}, _a[classes.appBarUserTools] = isCustomerPortal, _a)), style: { width: 'initial' } }, accountStore.isLoggedIn && !isLocal ? (React.createElement(React.Fragment, null,
                (isCustomerPortal && (React.createElement(PreloadingComponent, { withoutLoader: true },
                    React.createElement(UserSettingsTerm, { config: props.config })))) ||
                    null,
                React.createElement(PreloadingComponent, { withoutLoader: true },
                    React.createElement(UserSummaryToDo, { config: props.config })))) : null),
            accountStore.isLoggedIn && !props.isAdmin && !isLocal ? (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial' } },
                React.createElement(PreloadingComponent, { withoutLoader: true },
                    React.createElement(UserSummaryRateCa, { config: props.config })))) : null,
            spacing,
            (accountStore.isLoggedIn && (React.createElement(PreloadingComponent, null,
                React.createElement(Notifications, null)))) ||
                null,
            (isCarrierPortal && GuideComponent) || null,
            (!accountStore.isLoggedIn && (React.createElement(PreloadingComponent, null,
                React.createElement(Grid, { container: true, style: { width: 'initial' }, alignContent: "center" },
                    React.createElement(LoginWithEmail, { config: props.config }))))) ||
                null,
            React.createElement(AccountInfo, { hasAffiliate: props.hasAffiliate, open: isProfileOpen, toggleSettingsDrawer: toggleSettingsDrawer, keepMounted: true, anchorEl: anchorEl, onClick: function (e) { return handleProfile(e); }, config: props.config }),
            spacing,
            spacing,
            chatSidebar)));
    if (isSmall) {
        toolbar = (React.createElement(Toolbar, { "data-id": "izi-main-toolbar", className: clsx(classes.appBarToolBar, 'izi-main-toolbar'), component: Grid, container: true, direction: "row", justify: "space-between" },
            React.createElement(Grid, { item: true, xs: 3, md: 3 },
                React.createElement(Grid, { container: true, alignItems: 'center' },
                    React.createElement(BaseTooltip, { title: langApp.t("collapse_" + (isOpen ? 'less' : 'more')) },
                        React.createElement(IconButton, { color: "primary", "aria-label": "menu", className: clsx(classes.menuIcon, (_b = {},
                                _b[classes.hide] = isOpen,
                                _b)), onClick: handleDrawer },
                            React.createElement(MenuIcon, null))),
                    React.createElement(Logo, { style: { width: 40, height: 40 } }))),
            React.createElement(Grid, { item: true, xs: 9, md: 3, container: true, justify: "flex-end" },
                accountStore.isLoggedIn && !isLocal ? (React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end' } },
                    React.createElement("div", { style: { padding: '12px', display: 'flex' } },
                        React.createElement(GlobalSearch, { config: props.config })),
                    React.createElement(PreloadingComponent, { withoutLoader: true },
                        React.createElement(UserSummaryToDo, { config: props.config })),
                    (isCustomerPortal && (React.createElement(PreloadingComponent, { withoutLoader: true },
                        React.createElement(UserSettingsTerm, { config: props.config })))) ||
                        null,
                    React.createElement(PreloadingComponent, null,
                        React.createElement(Notifications, null)),
                    (isCarrierPortal && GuideComponent) || null)) : null,
                React.createElement(AccountInfo, { hasAffiliate: props.hasAffiliate, open: isProfileOpen, toggleSettingsDrawer: toggleSettingsDrawer, keepMounted: true, anchorEl: anchorEl, onClick: function (e) { return handleProfile(e); } }),
                spacing,
                chatSidebar)));
    }
    var isPublic = (_j = (_h = props.location) === null || _h === void 0 ? void 0 : _h.pathname) === null || _j === void 0 ? void 0 : _j.match(/\/public\//gi);
    var contentMain = (React.createElement("div", { style: { position: 'relative' }, id: 'izi-main-container' },
        React.createElement("div", { className: classes.root, "data-id": 'main-body' },
            React.createElement(CssBaseline, null),
            !isWithoutNavbar ? (React.createElement(AppBarMui, { position: "fixed", id: 'izi-main-app-menu-drawer', className: clsx(classes.appBar, {
                // [classes.appBarShift]: isOpen,
                // [classes.appBarForwarder]: accountStore.isForwarderNotIziLogisticsEnv,
                }, 'izi-main-app-menu') }, toolbar)) : null,
            isSmall ? (React.createElement(SwipeableDrawer, { id: 'izi-main-app-menu-swipeable', open: isOpenMobile, onOpen: handleDrawer, onClose: handleDrawer, className: classes.drawer, classes: {
                    paper: classes.drawerPaper,
                } }, isOpenMobile ? (React.createElement("div", { onClick: handleDrawer },
                React.createElement("div", { className: classes.toolbar },
                    React.createElement(BaseTooltip, { title: langApp.t("collapse_" + (isOpen ? 'less' : 'more')) },
                        React.createElement(IconButton, { onClick: handleDrawer }, theme.direction === 'rtl' ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null)))),
                sideBarMenuContent)) : null)) : (React.createElement(Drawer, { id: 'izi-main-app-menu-drawer', className: clsx(classes.drawer, (_c = {},
                    _c[classes.drawerOpen] = isOpen,
                    _c[classes.drawerClose] = !isOpen,
                    _c)), classes: {
                    paper: clsx((_d = {},
                        _d[classes.drawerOpen] = isOpen,
                        _d[classes.drawerClose] = !isOpen,
                        _d)),
                }, variant: "permanent", open: isOpen },
                React.createElement("div", { className: classes.toolbar }),
                sideBarMenuContent)),
            React.createElement("main", { className: clsx(classes.content, isWithoutChat
                    ? classes.contentMainWithoutChat
                    : (_e = {},
                        _e[classes.contentMainClose] = !isOpen,
                        _e[classes.contentMainOpen] = isOpen,
                        _e)), style: __assign({ paddingTop: 64 }, (isWithoutNavbar ? { paddingTop: theme.spacing(4) } : {})) },
                React.createElement(Toolbar, { className: clsx(classes.toolbar, classesMainArea.root) },
                    React.createElement("div", { className: clsx(classes.content, (_f = {},
                            _f[classes.contentMax] = props.v1 && ((_l = (_k = props.location) === null || _k === void 0 ? void 0 : _k.pathname) === null || _l === void 0 ? void 0 : _l.match(/\/public\/check-in/gi)),
                            _f)) },
                        React.createElement(ErrorBoundary, null,
                            titleContent,
                            pageContent)))),
            (accountStore.isLoggedIn && (React.createElement(PreloadingComponent, { withoutLoader: true },
                React.createElement(SettingsDrawer, { settings: props.settings, isAdmin: props.isAdmin, toggleDrawer: toggleSettingsDrawer, drawerState: drawerState, config: props.config })))) ||
                null),
        (accountStore.isLoggedIn && !accountStore.currentUser.settings.disablePushNotificationModal && (React.createElement(React.Fragment, null,
            React.createElement(PreloadingComponent, { withoutLoader: true }, isLoaded ? React.createElement(NotificationMessageRequest, null) : null)))) ||
            null,
        IS_DEV_ENV ? (React.createElement(PreloadingComponent, { withoutLoader: true },
            React.createElement(WizzardSettingsModal, { config: props.config }))) : (React.createElement(PreloadingComponent, { withoutLoader: true }, !isPublic && isCarrier && !drawerState.right && countriesValue === '' ? (React.createElement(CountryFiltersDialog, { config: props.config })) : isLoaded && !props.isAdmin ? (React.createElement(WizzardSettingsModal, { config: props.config })) : null)),
        (accountStore.isLoggedIn &&
            (((_m = accountStore.currentUser) === null || _m === void 0 ? void 0 : _m.appType) || []).find(function (e) {
                return [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER].includes(e);
            }) &&
            accountStore.currentUser.settings.showPlatformDuty &&
            !IS_DEV_ENV && (React.createElement(React.Fragment, null,
            React.createElement(PreloadingComponent, { withoutLoader: true }, React.createElement(CarrierDutyModal, { config: props.config }))))) ||
            null,
        accountStore.globalTourSteps.length && !IS_DEV_ENV ? (React.createElement(PreloadingComponent, null,
            React.createElement(BasicTour, { fastLoad: true, isOpen: !!accountStore.globalTourSteps.length, client: props.config.client, steps: JSON.parse(JSON.stringify(accountStore.globalTourSteps)), onClose: function (e) {
                    accountStore.update({
                        globalTourSteps: [],
                    });
                } }))) : null));
    if (accountStore.isLoggedIn) {
        contentMain = (React.createElement(GlobalSidebarChannelsSocket, { client: props.config.client }, contentMain));
    }
    return React.createElement(ErrorBoundary, null, contentMain);
})));
