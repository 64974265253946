var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { KEYS } from '../i18n';
import BaseTooltip from './BaseTooltip';
var useStyles = makeStyles(function (theme) { return ({
    button: {
        cursor: 'pointer',
        width: 'auto',
    },
    disabled: {
        opacity: 0.6,
        pointerEvents: 'none',
    },
    color: {
    // color: 'red',
    },
}); });
export var DeleteButtonIcon = function (props) {
    var langApp = useTranslation(KEYS.APP);
    var classes = useStyles();
    return (React.createElement(BaseTooltip, { title: (props.title || langApp.t('modal_delete_title')) },
        React.createElement(IconButton, __assign({ edge: "start", "aria-label": "menu" }, props, { className: classes.color }),
            React.createElement(DeleteForeverIcon, null))));
};
export var DeleteButton = function (props) {
    var _a;
    var langApp = useTranslation(KEYS.APP);
    var addForm = props.addForm, onClick = props.onClick, disabled = props.disabled;
    var classes = useStyles();
    var title = props.title || langApp.t('modal_delete_title');
    return (React.createElement(Grid, { className: clsx(classes.button, (_a = {}, _a[classes.disabled] = disabled, _a)), container: true, direction: "row", alignItems: "center" },
        React.createElement(IconButton, { edge: "start", color: "primary", "aria-label": "menu", onClick: addForm || onClick, className: classes.color, id: props.id || (title || 'delete-btn').toString().replace(/ /gi, '').toLowerCase() },
            React.createElement(DeleteForeverIcon, null)),
        title ? (React.createElement(Typography, { className: classes.color, onClick: addForm || onClick }, title)) : null));
};
