import { types } from 'mobx-state-tree';
import { AsyncActionStatus } from './AsyncActionStatus';
import { OrderLocationAddress } from '../../models/LocationAddress';
export var BaseCompanyForwarderSettings = types.model('BaseCompanyForwarderSettings', {
    isIziBranding: types.maybeNull(types.optional(types.boolean, false)),
});
export var CompanyForwarder = types.compose(AsyncActionStatus, types.model('CompanyForwarder', {
    _id: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    vatCode: types.maybeNull(types.optional(types.string, '')),
    settings: types.maybeNull(types.optional(BaseCompanyForwarderSettings, function () { return BaseCompanyForwarderSettings.create({}); })),
}));
export var ShortCompany = types.model('ShortCompany', {
    _id: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    addressV1: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
});
