var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { observer, useLocalStore } from 'mobx-react-lite';
import { i18n, KEYS } from '../../i18n';
import { FormikSelect, onPreventEvent } from '../../components';
import { ACTION_CHANGE } from '../../components/context.store.enums';
import { UpdateIssue } from '../../graphql/issues';
import { ISSUE_STATUS } from '../../models/Issues';
import IssueStatus from './IssueStatus';
function ItemHeaderFormItem(_a) {
    var _this = this;
    var item = _a.item, props = __rest(_a, ["item"]);
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var formRef = React.useRef();
    var client = props.config.client;
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        updated: 1,
        defaultValue: __assign(__assign({}, JSON.parse(JSON.stringify(item))), { files: [] }),
        onSubmit: function (_a) {
            var _id = _a._id, status = _a.status, _input = __rest(_a, ["_id", "status"]);
            return __awaiter(this, void 0, void 0, function () {
                var input, result, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, 5, 6]);
                            input = {
                                status: parseInt(status.toString()),
                                _id: _id,
                            };
                            localStore.error = '';
                            localStore.isLoading = true;
                            result = void 0;
                            if (!!_id) return [3 /*break*/, 1];
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, client.mutate({
                                mutation: UpdateIssue,
                                variables: {
                                    input: input,
                                },
                            })];
                        case 2:
                            result = (_b.sent()).data.updateIssue;
                            toast.success(i18n
                                .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_issue').toLowerCase()));
                            _b.label = 3;
                        case 3:
                            localStore.updated = Date.now();
                            return [2 /*return*/, result];
                        case 4:
                            e_1 = _b.sent();
                            console.log(e_1);
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onSubmit = function (item) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    if (!item)
                        throw Error(langToast.t('toast_form_not_valid'));
                    return [4 /*yield*/, localStore.onSubmit(item)];
                case 1:
                    _a.sent();
                    if (props.onChange) {
                        props.onChange(item, ACTION_CHANGE.DELETED);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_2 = _a.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 4];
                case 3: return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defaultValue, onSubmit: function (e) { return e; } }, function (formik) {
            var onChange = function (e) {
                var _a;
                formik.setFieldValue(e.target.name, e.target.value);
                onSubmit(__assign(__assign({}, formik.values), (_a = {}, _a[e.target.name] = e.target.value, _a)));
                if (props.onChange)
                    props.onChange(e);
            };
            return (React.createElement(Form, { onClick: onPreventEvent },
                React.createElement(Grid, { container: true, direction: "column" },
                    React.createElement(FormikSelect, { variant: "standard", value: formik.values.status, name: "status", onChange: onChange }, (props.v1 ? [ISSUE_STATUS.REQUESTED, ISSUE_STATUS.RESOLVED] : Object.values(ISSUE_STATUS)).map(function (status, index) {
                        return (React.createElement(MenuItem, { key: status, value: status },
                            React.createElement(IssueStatus, { v1: props.v1, record: { status: status } })));
                    })))));
        })));
}
export default observer(ItemHeaderFormItem);
