var context = { keys: function () { return []; } };
var resources = {};
context
    .keys()
    .filter(function (key) { return !key.match('all.keys.json'); })
    .forEach(function (key) {
    var _a;
    var fileName = key.replace('./', '');
    var resource = require("./" + fileName);
    var namespace = fileName.replace('.json', '');
    var lan = namespace.split('/')[0];
    var variable = namespace.split('/')[1];
    if (!resources[lan])
        resources[lan] = {};
    Object.assign(resources[lan], (_a = {},
        _a[variable] = JSON.parse(JSON.stringify(resource)),
        _a));
});
export default resources;
