var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { MainLogoIcon, MainLogoNoneBrandIcon } from '../components/icons';
import { BaseUserAppTYpe, CompanyState } from '../stores/common';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { useMediaQuery } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import BaseTooltip from './BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
export var useMainStyles = makeStyles(function (theme) {
    return createStyles({
        icon: {
            '& path': {
                fill: theme.palette.primary.light,
            },
        },
        logo: {
            '& path': {
            // fill: `${theme.palette.common.white}!important`,
            },
        },
    });
});
export var Logo = inject('store')(observer(function (_a) {
    var _b, _c;
    var style = _a.style, props = __rest(_a, ["style"]);
    var _d = props.store.accountStore, currentUser = _d.currentUser, currentCompany = _d.currentCompany;
    var langApp = useTranslation(KEYS.APP);
    var iconStyle = { width: 62, height: 62 };
    var classes = useMainStyles();
    var restrictedAccess = currentCompany.state.includes(CompanyState.ORDER_ACCESS_RESTRICTION);
    var logo = React.createElement(MainLogoIcon, { style: __assign(__assign({}, iconStyle), style) });
    if (process.env.REACT_APP_NONE_BRAND) {
        logo = React.createElement(MainLogoNoneBrandIcon, { style: __assign(__assign({}, iconStyle), style) });
    }
    if (currentUser) {
        if (process.env.REACT_APP_NONE_BRAND) {
            logo = (React.createElement(MainLogoNoneBrandIcon, { style: __assign(__assign({}, iconStyle), style), className: clsx(classes.icon, (_b = {},
                    _b[classes.logo] = props.store.accountStore.isForwarderEnvOnly || currentUser.appType.includes(BaseUserAppTYpe.FORWARDER),
                    _b)) }));
        }
        else {
            logo = (React.createElement(MainLogoIcon, { style: __assign(__assign({}, iconStyle), style), className: clsx(classes.icon, (_c = {},
                    _c[classes.logo] = props.store.accountStore.isForwarderNotIziLogisticsEnv &&
                        (props.store.accountStore.isForwarderEnvOnly ||
                            currentUser.appType.includes(BaseUserAppTYpe.FORWARDER)),
                    _c)) }));
        }
    }
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return (React.createElement(Link, { to: '/', style: __assign({ position: 'relative', display: 'flex' }, (!isSmall && { paddingLeft: 12 })), className: 'app-logo' },
        logo,
        (restrictedAccess && (React.createElement(BaseTooltip, { title: langApp.t('limited_access') },
            React.createElement(ErrorIcon, { style: { color: theme1.palette.warning.light, position: 'absolute', right: -20, top: 0 } })))) ||
            null));
}));
