import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const LoginPage = React.lazy(() => import('@izi-logistics/common/lib/pages/LoginPage'));

const Login = () => (
  <PreloadingComponent>
    <LoginPage />
  </PreloadingComponent>
);
export default Login;
