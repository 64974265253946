var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, UPLOAD_BASE } from '../base';
import { INVOICE_SERVICE_BASE, INVOICE_TYPE_BASE } from './base';
import { USER_BASE_MIN } from '../user';
import { USER_COMPANY_BASE } from '../base';
import { INVOICE_BASE, INVOICE_MORE_BASE } from './base';
import { ORDER_BASE_TO_VIEW_MORE } from '../order/types';
var INVOICE_ITEM = " " + INVOICE_MORE_BASE + "\n            services{\n                " + INVOICE_SERVICE_BASE + "\n            }\n            issuedBy{\n                " + USER_BASE_MIN + "\n            }\n            files{\n                " + UPLOAD_BASE + "\n            }";
export var InvoiceSerial = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query invoiceSerial($filter: MainFilter) {\n    invoiceSerial(filter: $filter) {\n      count\n    }\n  }\n"], ["\n  query invoiceSerial($filter: MainFilter) {\n    invoiceSerial(filter: $filter) {\n      count\n    }\n  }\n"])));
export var FetchInvoiceClients = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query loadInvoiceClients($filter: MainFilter) {\n        loadInvoiceClients(filter: $filter) {\n            data {\n                ", "\n                emails\n            }\n            count\n        }\n    }\n"], ["\n    query loadInvoiceClients($filter: MainFilter) {\n        loadInvoiceClients(filter: $filter) {\n            data {\n                ", "\n                emails\n            }\n            count\n        }\n    }\n"])), USER_COMPANY_BASE);
export var FetchInvoice = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query invoice($_id:MongoId!){\n        invoice(_id:$_id) {\n           ", "\n        }\n    }\n"], ["\n    query invoice($_id:MongoId!){\n        invoice(_id:$_id) {\n           ", "\n        }\n    }\n"])), INVOICE_ITEM);
export var FetchInvoiceCreateDetails = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query invoiceCreateDetails($filter:MainFilter){\n        invoiceCreateDetails(filter:$filter) {\n            quote{\n                order {\n                    orderId\n                    status\n                    sorting_countryPickup\n                    sorting_countryDelivery\n                    sorting_deliveryAt\n                    sorting_pickupAt\n                    sorting_totalWeight\n                    ", "\n                    ", "\n                }\n            }\n            type{\n                ", "\n            }\n                    serial{\n                        count\n                    }\n        }\n    }\n"], ["\n    query invoiceCreateDetails($filter:MainFilter){\n        invoiceCreateDetails(filter:$filter) {\n            quote{\n                order {\n                    orderId\n                    status\n                    sorting_countryPickup\n                    sorting_countryDelivery\n                    sorting_deliveryAt\n                    sorting_pickupAt\n                    sorting_totalWeight\n                    ",
    "\n                    ", "\n                }\n            }\n            type{\n                ", "\n            }\n                    serial{\n                        count\n                    }\n        }\n    }\n"])), BASE_QUERY.replace(/company{/gi, 'company{\n' +
    'phone\n' +
    'emails\n' +
    'bankAccountCode\n' +
    'bankCode\n' +
    'companyCode\n' +
    'vatCode\n'), ORDER_BASE_TO_VIEW_MORE, INVOICE_TYPE_BASE);
export var FetchInvoices = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query invoices($filter:MainFilter){\n        invoices(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query invoices($filter:MainFilter){\n        invoices(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), INVOICE_BASE);
export var CreateInvoice = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation createInvoice($input:InvoiceInput!){\n        createInvoice(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createInvoice($input:InvoiceInput!){\n        createInvoice(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_MORE_BASE);
export var CreateInvoiceTemplate = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation createInvoiceTemplate($_id: MongoId!) {\n        createInvoiceTemplate(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation createInvoiceTemplate($_id: MongoId!) {\n        createInvoiceTemplate(_id: $_id) {\n            ", "\n        }\n    }\n"])), INVOICE_MORE_BASE);
export var CreateQuoteInvoice = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation createQuoteInvoice($quoteId:MongoId!){\n        createQuoteInvoice(quoteId:$quoteId) {\n            ", " \n        }\n    }\n"], ["\n    mutation createQuoteInvoice($quoteId:MongoId!){\n        createQuoteInvoice(quoteId:$quoteId) {\n            ", " \n        }\n    }\n"])), INVOICE_ITEM);
export var FetchRequestRefundInvoice = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    query requestRefundInvoice($invoiceId:MongoId!){\n        requestRefundInvoice(invoiceId:$invoiceId) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            }\n        }\n    }\n"], ["\n    query requestRefundInvoice($invoiceId:MongoId!){\n        requestRefundInvoice(invoiceId:$invoiceId) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            }\n        }\n    }\n"])), INVOICE_MORE_BASE, INVOICE_SERVICE_BASE, USER_BASE_MIN, UPLOAD_BASE);
export var FetchCreditInvoice = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query createCreditInvoice($invoiceId:MongoId!){\n        createCreditInvoice(invoiceId:$invoiceId) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            }\n        }\n    }\n"], ["\n    query createCreditInvoice($invoiceId:MongoId!){\n        createCreditInvoice(invoiceId:$invoiceId) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            }\n        }\n    }\n"])), INVOICE_MORE_BASE, INVOICE_SERVICE_BASE, USER_BASE_MIN, UPLOAD_BASE);
export var FetchInvoiceStats = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  query invoiceStats($filter: MainFilter) {\n    invoiceStats(filter: $filter) {\n      clients {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      sellers {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      types {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      status {\n        _id\n        count\n      }\n      rangeTotal {\n        min\n        max\n      }\n    }\n  }\n"], ["\n  query invoiceStats($filter: MainFilter) {\n    invoiceStats(filter: $filter) {\n      clients {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      sellers {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      types {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      status {\n        _id\n        count\n      }\n      rangeTotal {\n        min\n        max\n      }\n    }\n  }\n"])));
export var SendInvoice = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation sendInvoice($input:InvoiceInputUpdate!){\n        sendInvoice(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation sendInvoice($input:InvoiceInputUpdate!){\n        sendInvoice(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_MORE_BASE);
export var PrintInvoice = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  mutation printInvoice($input: InvoiceInputUpdate!) {\n    printInvoice(input: $input) {\n      pdfLink\n    }\n  }\n"], ["\n  mutation printInvoice($input: InvoiceInputUpdate!) {\n    printInvoice(input: $input) {\n      pdfLink\n    }\n  }\n"])));
export var UpdateInvoice = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation updateInvoice($input:InvoiceInputUpdate!){\n        updateInvoice(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateInvoice($input:InvoiceInputUpdate!){\n        updateInvoice(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_MORE_BASE);
export var DeleteInvoice = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    mutation deleteInvoice($_id: MongoId!) {\n        deleteInvoice(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteInvoice($_id: MongoId!) {\n        deleteInvoice(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var DuplicateInvoice = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    mutation duplicateInvoice($_id: MongoId!) {\n        duplicateInvoice(_id: $_id) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            } \n        }\n    }\n"], ["\n    mutation duplicateInvoice($_id: MongoId!) {\n        duplicateInvoice(_id: $_id) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            } \n        }\n    }\n"])), INVOICE_MORE_BASE, INVOICE_SERVICE_BASE, USER_BASE_MIN, UPLOAD_BASE);
export var CopyInvoice = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    mutation copyInvoice($input: InvoiceCopyInput!) {\n        copyInvoice(input: $input) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            } \n        }\n    }\n"], ["\n    mutation copyInvoice($input: InvoiceCopyInput!) {\n        copyInvoice(input: $input) {\n            ", "\n            services{\n                ", "\n            }\n            issuedBy{\n                ", "\n            }\n            files{\n                ", "\n            } \n        }\n    }\n"])), INVOICE_MORE_BASE, INVOICE_SERVICE_BASE, USER_BASE_MIN, UPLOAD_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
