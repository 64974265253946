var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { withRouter } from 'react-router';
import { TextOverflow } from '../../components';
import { Grid, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import ChannelItemDetailsChatHistoryItem from './ChannelItemDetailsChatHistoryItem';
import { withChatStore } from './stores/ChannelChat';
import { formatMessages } from './stores/utils';
import ChatUserTyping from './ChatUserTyping';
import { theme } from '../../theme';
import ChatTotalMessages from '../../components/Socket/Chat/ChannelChat/ChatTotalMessages';
var timeout;
var ChannelItemDetailsChatHistory = function (_a) {
    var match = _a.match, chatStore = _a.chatStore, props = __rest(_a, ["match", "chatStore"]);
    var container = React.useRef(null);
    var langChat = useTranslation(KEYS.CHAT);
    var _b = __read(React.useState(), 2), prevMessage = _b[0], setPrevMessage = _b[1];
    var chatId = match.params.chatId;
    var _c = __read(React.useState({
        fields: [{ name: 'chat', value: (chatStore.state.item && chatStore.state.item._id) || chatId }],
        skip: 0,
        limit: 10,
    }), 2), filter = _c[0], setFilter = _c[1];
    var loadData = function (_f) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, chatStore.loadData(_f)];
                case 1:
                    _a.sent(); //
                    return [2 /*return*/];
            }
        });
    }); };
    var onScroll = function (e) {
        if (e.target.scrollTop === 0 && chatStore.state.data.length < chatStore.state.count) {
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(function () {
                var f = __assign(__assign({}, filter), { skip: filter.skip + filter.limit });
                setFilter(f);
                loadData(f);
            }, 500);
        }
    };
    React.useEffect(function () {
        loadData(filter);
        return function () {
            if (timeout)
                clearTimeout(timeout);
            timeout = null;
        };
    }, []);
    React.useEffect(function () {
        var prevV = chatStore.state.data.length && chatStore.state.data[chatStore.state.data.length - 1]._id;
        if (prevMessage !== prevV) {
            setTimeout(function () {
                if (container.current) {
                    container.current.scrollBy({
                        behavior: 'smooth',
                        top: container.current.scrollHeight,
                    });
                    setTimeout(function () {
                        try {
                            document.getElementById('chat-text-input').focus();
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }, 500);
                }
            }, 1300);
        }
        if (prevV !== 'temp') {
            setPrevMessage(prevV);
        }
    }, [chatStore.state.data.length]);
    return (React.createElement(Grid, { container: true, style: { position: 'relative' } },
        (!chatStore.state.loadingHistory && !chatStore.state.count && (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(TextOverflow, { variant: 'subtitle1' }, langChat.t('default_no_text_chat'))))) ||
            null,
        (chatStore.state.loadingHistory && (React.createElement(Grid, { container: true, justify: 'center', style: { position: 'relative' } },
            React.createElement(LinearProgress, { color: "primary", style: { position: 'absolute', top: 0, width: '100%' } })))) ||
            null,
        React.createElement(Grid, { container: true, ref: container, onScroll: onScroll, style: {
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: theme.spacing(2),
                maxHeight: "calc(100vh - 550px)",
                minHeight: 240,
            } }, formatMessages(chatStore.state.data).map(function (e) { return (React.createElement(ChannelItemDetailsChatHistoryItem, __assign({}, props, { key: e.updatedAt || e._id, record: e }))); })),
        React.createElement(ChatUserTyping, { record: { _id: chatId } }),
        React.createElement(ChatTotalMessages, { state: chatStore.state })));
};
export default withChatStore(withRouter(ChannelItemDetailsChatHistory));
