var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { WAREHOUSE_NONE_WORKING_DAY_TIME_BASE } from './types';
import { BASE_RESPONSE } from '../base';
export var FetchWarehouseNoneWorkingDayTimes = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query warehouseNoneWorkingDayTimes($filter: MainFilter)  {\n        warehouseNoneWorkingDayTimes(filter:$filter) {\n            data {\n                ", "\n                \n            }\n            count\n        }\n    }\n"], ["\n    query warehouseNoneWorkingDayTimes($filter: MainFilter)  {\n        warehouseNoneWorkingDayTimes(filter:$filter) {\n            data {\n                ", "\n                \n            }\n            count\n        }\n    }\n"])), WAREHOUSE_NONE_WORKING_DAY_TIME_BASE);
export var CreateWarehouseNoneWorkingDayTime = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createWarehouseNoneWorkingDayTime($input: WarehouseNoneWorkingDayTimeInput!)  {\n        createWarehouseNoneWorkingDayTime(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createWarehouseNoneWorkingDayTime($input: WarehouseNoneWorkingDayTimeInput!)  {\n        createWarehouseNoneWorkingDayTime(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_NONE_WORKING_DAY_TIME_BASE);
export var UpdateWarehouseNoneWorkingDayTime = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateWarehouseNoneWorkingDayTime($input: WarehouseNoneWorkingDayTimeInputUpdate!)  {\n        updateWarehouseNoneWorkingDayTime(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateWarehouseNoneWorkingDayTime($input: WarehouseNoneWorkingDayTimeInputUpdate!)  {\n        updateWarehouseNoneWorkingDayTime(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_NONE_WORKING_DAY_TIME_BASE);
export var DeleteWarehouseNoneWorkingDayTime = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteWarehouseNoneWorkingDayTime($_id: MongoId!)  {\n        deleteWarehouseNoneWorkingDayTime(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteWarehouseNoneWorkingDayTime($_id: MongoId!)  {\n        deleteWarehouseNoneWorkingDayTime(_id:$_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
