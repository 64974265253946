import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
var styles = function (theme) {
    return createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        paperScrollPaper: {
            alignItems: 'center',
        },
    });
};
export var useStyles = makeStyles(styles);
