var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { FetchHolidays } from '../../graphql/holiday';
import { FetchWarehouseWorkingDays } from '../../graphql/warehouse/warehouseWorkingDay';
import { FetchCalendarTransportations } from '../../graphql/warehouse/calendar';
import { FetchAllCalendarData } from '../../graphql/warehouse/calendar.more';
import { FetchWarehouses, UpdateLocationWarehouseV1 } from '../../graphql';
import { toast } from 'react-toastify';
import { i18n, KEYS } from '../../i18n';
import { getQueryParams } from '../../utils';
import browserHistory from '../../browserHistory';
import { browserStorage } from '../../browserStorage';
import { initialState } from './store.types';
import { SAVABLE_CALENDAR_FIELDS } from '../..';
var CalendarStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = CalendarStore.Provider, Consumer = CalendarStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["START_LOAD_DATA"] = "START_LOAD_DATA";
    ACTIONS["FINISH_LOAD_DATA"] = "FINISH_LOAD_DATA";
    ACTIONS["LOAD_DATA_ERROR"] = "LOAD_DATA_ERROR";
    ACTIONS["CHANGE_FILTERS"] = "CHANGE_FILTERS";
    ACTIONS["TOGGLE_SELECT_ALL"] = "TOGGLE_SELECT_ALL";
    ACTIONS["TOGGLE_SELECT_ITEM"] = "TOGGLE_SELECT_ITEM";
    ACTIONS["DELETE_ITEM"] = "DELETE_ITEM";
    ACTIONS["UPDATE_ITEM"] = "UPDATE_ITEM";
})(ACTIONS || (ACTIONS = {}));
export var CalendarProvider = function (_a) {
    var defaultFilter = _a.defaultFilter, warehouse = _a.warehouse, _b = _a.variables, variables = _b === void 0 ? {} : _b, children = _a.children, client = _a.client;
    var initialFilter = function (defaultFilter) {
        var filter = __assign(__assign({}, JSON.parse(JSON.stringify(initialState.filter))), (defaultFilter && JSON.parse(JSON.stringify(defaultFilter))));
        return filter;
    };
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), { warehouse: warehouse, isPublic: !!location.pathname.match(/^\/public\//gi), filter: initialFilter(defaultFilter || { fields: [] }) });
    var _c = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.START_LOAD_DATA: {
                return __assign(__assign({}, state), { error: null, loading: true });
            }
            case ACTIONS.LOAD_DATA_ERROR:
            case ACTIONS.CHANGE_FILTERS:
            case ACTIONS.UPDATE_ITEM:
            case ACTIONS.FINISH_LOAD_DATA: {
                return __assign(__assign(__assign({}, state), action.payload), { loading: false });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _c[0], dispatch = _c[1];
    var loadData = function (key, query, filter) { return __awaiter(void 0, void 0, void 0, function () {
        var _variables, ramps_1, result, _a, data, count, e_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    // await client.resetStore(); //TODO:need to clear only cache by query
                    dispatch({
                        type: ACTIONS.START_LOAD_DATA,
                    });
                    _variables = __assign(__assign({}, variables), { filter: __assign(__assign({}, state.filter), filter) });
                    if (['holidays', 'workingDays'].includes(key)) {
                        ramps_1 = _variables.filter.fields.find(function (e) { return e.name === 'ramps'; });
                        _variables.filter.fields = _variables.filter.fields.map(function (e) {
                            return e.name === 'warehouse' ? __assign(__assign({}, e), { value: __spread([e.value], ramps_1.value) }) : e;
                        });
                    }
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: query,
                            variables: _variables,
                        })];
                case 1:
                    result = _c.sent();
                    _a = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]])), data = _a.data, count = _a.count;
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign((_b = {
                                loaded: true
                            },
                            _b[key] = {
                                data: data,
                                count: count,
                            },
                            _b)),
                    });
                    return [2 /*return*/, data];
                case 2:
                    e_1 = _c.sent();
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_1.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var loadAll = function (filters, opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var f;
            return __generator(this, function (_a) {
                f = __assign(__assign(__assign({}, state.filter), filters), { fields: __spread(state.filter.fields, ((filters && filters.fields) || [])) });
                return [2 /*return*/, loadCalendarData(f, opt)];
            });
        });
    };
    var loadAllDay = function (day, fields) {
        if (fields === void 0) { fields = []; }
        return __awaiter(void 0, void 0, void 0, function () {
            var result, data_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.query({
                                fetchPolicy: 'no-cache',
                                query: FetchCalendarTransportations,
                                variables: __assign(__assign({}, variables), { filter: __assign(__assign({}, state.filter), { fields: __spread(state.filter.fields.filter(function (e) { return !['start', 'end'].includes(e.name); }), [
                                            { name: 'day', value: day }
                                        ], fields) }) }),
                            })];
                    case 1:
                        result = _a.sent();
                        data_1 = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]])).data;
                        dispatch({
                            type: ACTIONS.FINISH_LOAD_DATA,
                            payload: Object.assign({
                                loaded: true,
                                transportations: {
                                    data: state.transportations.data.map(function (e) { return (e.date === day ? data_1[0] : e); }),
                                    count: state.transportations.count,
                                },
                                transportationsBusy: {
                                    data: state.transportationsBusy.data.map(function (e) { return (e.date === day ? data_1[0] : e); }),
                                    count: state.transportationsBusy.count,
                                },
                            }),
                        });
                        return [2 /*return*/, data_1];
                    case 2:
                        e_2 = _a.sent();
                        dispatch({
                            type: ACTIONS.LOAD_DATA_ERROR,
                            payload: { error: { message: e_2.message } },
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var toggleCalendarElements = function (element) {
        var calendarElements = __spread(state.calendarElements);
        if (calendarElements.includes(element)) {
            calendarElements = calendarElements.filter(function (e) { return e !== element; });
        }
        else {
            calendarElements.push(element);
        }
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: { calendarElements: calendarElements },
        });
    };
    var changeRamps = function (event, opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var evValue, filter, updates, query_1, localStoreKey, storageValue;
            var _a;
            return __generator(this, function (_b) {
                evValue = (event.target.value || []).map(function (e) { return e._id; });
                // await value.loadTransportations(filter);
                // await value.loadWorkingDays(filter);
                // await value.loadHolidays(filter);
                if (opt.v1) {
                    filter = __assign(__assign({}, state.filter), { fields: __spread(state.filter.fields.filter(function (e) { return e.name !== event.target.name; }), [
                            {
                                name: event.target.name,
                                value: evValue,
                            },
                        ]).filter(function (e) { return e; }) });
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: { filter: filter, loading: false },
                    });
                }
                else if (opt.v2) {
                    updates = __spread(state.ramps.data);
                    updates.forEach(function (e) {
                        for (var i = 0; i < event.target.value.length; i++) {
                            if (e._id === event.target.value[i]._id) {
                                Object.assign(e, event.target.value[i]);
                            }
                        }
                    });
                    console.log(updates, event.target.value);
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: { ramps: __assign(__assign({}, state.ramps), { data: updates }) },
                    });
                }
                else {
                    query_1 = getQueryParams(browserHistory.location.search);
                    browserHistory.push({
                        pathname: browserHistory.location.pathname,
                        search: '?' +
                            new URLSearchParams(__assign(__assign({}, query_1), { ramps: evValue || [] })).toString(),
                    });
                    if ((_a = state === null || state === void 0 ? void 0 : state.warehouse) === null || _a === void 0 ? void 0 : _a._id) {
                        localStoreKey = "calendar-v2-" + state.warehouse._id;
                        storageValue = new URLSearchParams(__assign(__assign({}, SAVABLE_CALENDAR_FIELDS.map(function (field) {
                            var _a;
                            return (_a = {},
                                _a[field] = query_1.field || '',
                                _a);
                        }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { ramps: evValue || [] })).toString();
                        browserStorage.setUserItem(localStoreKey, storageValue);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    var changeOrderBooking = function (changes) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _id_1, keyUpdate, transportationUpdates_1, dayUpdated_1, transportationList_1, updatedLocation, e_3;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 3, , 4]);
                    _a = __read(changes.oldData._id.split('-'), 2), _id_1 = _a[0], keyUpdate = _a[1];
                    transportationUpdates_1 = {};
                    if (parseInt(changes.oldData.time[0]) !== changes.newData.hour) {
                        Object.assign(transportationUpdates_1, (_b = {},
                            _b[((keyUpdate.match('start') && 'start') || 'end') + "Time"] = [changes.newData.hour],
                            _b));
                    }
                    if (changes.oldData.date !== changes.newData.day) {
                        Object.assign(transportationUpdates_1, (_c = {}, _c[keyUpdate] = changes.newData.day, _c));
                        dayUpdated_1 = changes.newData.day;
                    }
                    transportationList_1 = JSON.parse(JSON.stringify(state.transportations.data));
                    updatedLocation = void 0;
                    if (!(changes.oldData.item.ramp._id !== changes.newData.item.ramp._id ||
                        Object.keys(transportationUpdates_1).length)) return [3 /*break*/, 2];
                    return [4 /*yield*/, client.mutate({
                            mutation: UpdateLocationWarehouseV1,
                            variables: {
                                input: {
                                    transportationUpdates: __assign({ _id: _id_1 }, transportationUpdates_1),
                                    _id: changes.oldData.route.origin._id,
                                    warehouse: changes.newData.item.ramp._id,
                                },
                            },
                        })];
                case 1:
                    updatedLocation = (_d.sent()).data.updateLocationWarehouseV1;
                    _d.label = 2;
                case 2:
                    if (!(updatedLocation === null || updatedLocation === void 0 ? void 0 : updatedLocation._id)) {
                        return [2 /*return*/];
                    }
                    if (changes.oldData.item.ramp._id !== changes.newData.item.ramp._id) {
                        // await client.mutate({
                        //   mutation: UpdateLocationWarehouse,
                        //   variables: { input: { _id: changes.oldData.route.origin._id, warehouse: changes.newData.item.ramp._id } },
                        // });
                        transportationList_1.forEach(function (item) {
                            item.items.forEach(function (el) {
                                if (el._id === _id_1) {
                                    el.routes.forEach(function (r) {
                                        if (r._id === changes.oldData.route._id) {
                                            r.origin.warehouse._id = changes.newData.item.ramp._id;
                                        }
                                    });
                                }
                            });
                        });
                    }
                    if (Object.keys(transportationUpdates_1).length) {
                        // await client.mutate({
                        //   mutation: UpdateOrderTransportation,
                        //   variables: { input: { _id, ...transportationUpdates } },
                        // });
                        transportationList_1.forEach(function (item) {
                            var order;
                            item.items.forEach(function (el) {
                                if (el._id === _id_1) {
                                    Object.assign(el, transportationUpdates_1);
                                    order = el;
                                    return false;
                                }
                            });
                            if (dayUpdated_1 && order) {
                                item.items = item.items.filter(function (e) { return e._id !== _id_1; });
                                var _item = transportationList_1.find(function (e) { return e.date === dayUpdated_1; });
                                _item.items.push(order);
                                _item.count++;
                                item.count--;
                                return false;
                            }
                        });
                    }
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign({
                            loaded: true,
                            transportations: __assign(__assign({}, state.transportations), { data: transportationList_1 }),
                        }),
                    });
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _d.sent();
                    toast.error(e_3.message || e_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var loadCalendarFilters = function (filters) { return __awaiter(void 0, void 0, void 0, function () {
        var result, _a, data, count, query, value_1, ramps, filter, e_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    dispatch({
                        type: ACTIONS.START_LOAD_DATA,
                    });
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: FetchWarehouses,
                            variables: __assign(__assign({}, variables), { filter: __assign(__assign({}, state.filter), { fields: __spread(state.filter.fields.filter(function (e) { return e.name !== 'warehouse'; }), [
                                        {
                                            name: 'parent',
                                            value: state.filter.fields.find(function (e) { return e.name === 'warehouse'; }).value,
                                        },
                                    ]) }) }),
                        })];
                case 1:
                    result = _b.sent();
                    _a = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]])), data = _a.data, count = _a.count;
                    query = getQueryParams(browserHistory.location.search);
                    value_1 = data.map(function (e) { return e._id; });
                    if (typeof query.ramps !== 'undefined') {
                        ramps = (query.ramps || '').split(',') || [];
                        if (!Array.isArray(ramps)) {
                            ramps = [ramps];
                        }
                        value_1 = ramps;
                    }
                    filter = __assign(__assign({}, filters), { fields: __spread(((filters === null || filters === void 0 ? void 0 : filters.fields.filter(function (e) { return e.name !== 'ramps'; })) || []), [{ name: 'ramps', value: value_1 }]).filter(function (e) { return e; }) });
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign({
                            loaded: true,
                            filter: filter,
                            ramps: { data: data, count: count },
                        }),
                    });
                    return [2 /*return*/, filter];
                case 2:
                    e_4 = _b.sent();
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_4.message } },
                    });
                    return [3 /*break*/, 4];
                case 3: return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var changeData = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch({
                type: ACTIONS.FINISH_LOAD_DATA,
                payload: __assign(__assign({}, state), payload),
            });
            return [2 /*return*/];
        });
    }); };
    var loadCalendarData = function (filters, opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var query, value_2, ramps, stateRamps, filter, result, resultC_1, warehouses, transportationsBusy, res, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (!opt.softRefresh) {
                            dispatch({
                                type: ACTIONS.START_LOAD_DATA,
                            });
                        }
                        query = getQueryParams(browserHistory.location.search);
                        value_2 = [];
                        if (typeof query.ramps !== 'undefined') {
                            ramps = (query.ramps || '').split(',') || [];
                            if (!Array.isArray(ramps)) {
                                ramps = [ramps];
                            }
                            value_2 = ramps;
                        }
                        stateRamps = initState.filter.fields.filter(function (e) { return e.name === 'ramps'; });
                        if (!value_2.length && stateRamps.length) {
                            value_2 = stateRamps[0].value || [];
                        }
                        value_2 = value_2.filter(function (e) { return e; });
                        filter = __assign(__assign({}, filters), { fields: __spread(((filters === null || filters === void 0 ? void 0 : filters.fields.filter(function (e) { return e.name !== 'ramps'; })) || []), [{ name: 'ramps', value: value_2 }]).filter(function (e) { return e; }) });
                        if (location.pathname.match(/^\/public\//i)) {
                            filter.fields.push({ name: 'isPublic', value: query.WAREHOUSE_SHARE_LINK_VERSION || 1 });
                        }
                        return [4 /*yield*/, client.query({
                                fetchPolicy: 'no-cache',
                                query: FetchAllCalendarData,
                                variables: __assign(__assign({}, variables), { filter: filter }),
                            })];
                    case 1:
                        result = _a.sent();
                        resultC_1 = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                        warehouses = resultC_1.warehouses, transportationsBusy = resultC_1.transportationsBusy, res = __rest(resultC_1, ["warehouses", "transportationsBusy"]);
                        if (!value_2.length) {
                            // value = warehouses.data.map((e) => e._id);
                        }
                        filter = __assign(__assign({}, filters), { fields: __spread(((filters === null || filters === void 0 ? void 0 : filters.fields.filter(function (e) { return e.name !== 'ramps'; })) || []), [{ name: 'ramps', value: value_2 }]).filter(function (e) { return e; }) });
                        // if (res.noneWorkingDayTime.data.length && res.transportations.data.length) {
                        //   res.transportations.data[0].items.push({
                        //     ...res.transportations.data[0].items[0],
                        //     endTime: ['15:00'],
                        //   });
                        // }
                        // res.transportations.
                        dispatch({
                            type: ACTIONS.FINISH_LOAD_DATA,
                            payload: Object.assign(__assign({ loaded: true, loading: false, filter: filter,
                                transportationsBusy: transportationsBusy, ramps: warehouses }, res)),
                        });
                        return [2 /*return*/, Object.keys(resultC_1)
                                .map(function (e) {
                                var _a;
                                return (_a = {}, _a[e] = resultC_1[e].data, _a);
                            })
                                .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})];
                    case 2:
                        e_5 = _a.sent();
                        console.log(e_5, '-----::::::');
                        dispatch({
                            type: ACTIONS.LOAD_DATA_ERROR,
                            payload: {
                                loading: false,
                                error: { message: e_5.message },
                            },
                        });
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var setDragId = function (dragId) {
        dispatch({
            type: ACTIONS.FINISH_LOAD_DATA,
            payload: {
                dragId: dragId,
            },
        });
    };
    var value = {
        state: state,
        loadTransportations: function (filters) { return loadData('transportations', FetchCalendarTransportations, filters); },
        loadHolidays: function (filters) {
            return loadData('holidays', FetchHolidays, __assign(__assign({}, filters), { fields: __spread(((filters === null || filters === void 0 ? void 0 : filters.fields) || []), [[{ name: 'withoutOwner', value: 1 }]]) }));
        },
        loadWorkingDays: function (filters) { return loadData('workingDays', FetchWarehouseWorkingDays, filters); },
        toggleCalendarElements: toggleCalendarElements,
        loadCalendarFilters: loadCalendarFilters,
        changeRamps: changeRamps,
        changeData: changeData,
        changeOrderBooking: changeOrderBooking,
        loadAllDay: loadAllDay,
        setDragId: setDragId,
        loadAll: loadAll,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withCalendarStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) { return React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps))); })); };
};
