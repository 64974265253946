var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useSectionStyles } from '../../styles';
import { Channels } from './Channels';
import { theme } from '../../theme';
import { DefaultChanel, ChatItem } from './ChatItem';
import { GlobalChannelListSocket } from './stores/ChannelList';
var OrderChannels = function (props) {
    var _a;
    var _b = __read(React.useState(null), 2), channel = _b[0], setChannel = _b[1];
    var langChat = useTranslation(KEYS.CHAT);
    var classes = useSectionStyles();
    var onChange = function (e, channel) {
        setChannel(channel);
    };
    var onRemove = function (_channel) {
        if (!channel || !_channel || _channel._id === channel._id)
            setChannel(null);
    };
    return (React.createElement(Grid, { container: true, direction: 'column' },
        (!props.hideTitle && (React.createElement(Grid, { container: true, justify: 'space-between', style: { marginBottom: theme.spacing(2) } },
            React.createElement(TextOverflow, { variant: 'h5' }, langChat.t('main_title'))))) ||
            null,
        React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { item: true, xs: 12, md: 4 },
                React.createElement(Grid, { className: classes.section, style: { padding: theme.spacing(2) } },
                    React.createElement(GlobalChannelListSocket, { client: (_a = props.config) === null || _a === void 0 ? void 0 : _a.client, id: 'order-chats-tab' },
                        React.createElement(Channels, __assign({}, props, { onClick: onChange, onRemove: onRemove, channel: channel, noQueryParams: true }))))),
            React.createElement(Grid, { item: true, xs: 12, md: 8 },
                React.createElement(Grid, { className: classes.section, style: { padding: 0 } },
                    React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' }, (channel && React.createElement(ChatItem, __assign({ key: channel && channel._id, record: channel }, props))) || React.createElement(DefaultChanel, null)))))));
};
export default OrderChannels;
