var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { GlobalChannelListSocket } from '../Chat/stores/ChannelList';
import Issues from './list';
export default function (props) {
    var _a;
    return (React.createElement(GlobalChannelListSocket, { client: (_a = props.config) === null || _a === void 0 ? void 0 : _a.client, isAllChats: true, id: 'issues' },
        React.createElement(Issues, __assign({}, props))));
}
