export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["START_LOAD_HISTORY_DATA"] = "START_LOAD_HISTORY_DATA";
    ACTIONS["START_LOAD_DATA"] = "START_LOAD_DATA";
    ACTIONS["CREATE_MESSAGE"] = "CREATE_MESSAGE";
    ACTIONS["CHANGE_DATA"] = "CHANGE_DATA";
    ACTIONS["FINISH_CREATE_MESSAGE"] = "FINISH_CREATE_MESSAGE";
    ACTIONS["FINISH_LOAD_DATA"] = "FINISH_LOAD_DATA";
    ACTIONS["FINISH_LOAD_ALL_DATA"] = "FINISH_LOAD_ALL_DATA";
    ACTIONS["LOAD_DATA_ERROR"] = "LOAD_DATA_ERROR";
    ACTIONS["CHANGE_FILTERS"] = "CHANGE_FILTERS";
})(ACTIONS || (ACTIONS = {}));
