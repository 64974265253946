var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var COMPANY_STATUS_BASE = "\n    _id\n    name\n    description\n";
export var FetchCompanyStatus = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query companyStatusList($filter: MainFilter) {\n        companyStatusList(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query companyStatusList($filter: MainFilter) {\n        companyStatusList(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), COMPANY_STATUS_BASE);
export var CreateCompanyStatus = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createCompanyStatus($input: CompanyStatusInput!) {\n        createCompanyStatus(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createCompanyStatus($input: CompanyStatusInput!) {\n        createCompanyStatus(input: $input) {\n            ", "\n        }\n    }\n"])), COMPANY_STATUS_BASE);
export var UpdateCompanyStatus = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateCompanyStatus($input: CompanyStatusInputUpdate!) {\n        updateCompanyStatus(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateCompanyStatus($input: CompanyStatusInputUpdate!) {\n        updateCompanyStatus(input: $input) {\n            ", "\n        }\n    }\n"])), COMPANY_STATUS_BASE);
export var DeleteCompanyStatus = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteCompanyStatus($_id: MongoId!) {\n        deleteCompanyStatus(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteCompanyStatus($_id: MongoId!) {\n        deleteCompanyStatus(_id: $_id) {\n            ", "\n        }\n    }\n"])), COMPANY_STATUS_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
