var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { OrderCurrency, OrderLoan, OrderLoanFactoringType, OrderStatus, } from '../../../models';
import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { toast } from 'react-toastify';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { getSnapshot } from 'mobx-state-tree';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikRadioGroupField, FormikSelect, FormikTextField } from '../../Formik';
import { FormikSingleDatePicker } from '../../DateTimePicker';
import { useFormStyles } from '../../../styles/form';
import { onlySpacesRegExp, phoneRegExp } from '../../../common';
import { TextOverflow } from '../../TextOverflow';
import { CreateOrderLoan } from '../../../graphql/order/orderLoan';
import { i18n, KEYS } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { FetchOrder, FetchOrderQuoteDetails } from '../../../graphql/order';
import BaseTooltip from '../../BaseTooltip';
import { HelpIconComponent } from '../../HelpIcon';
import { theme } from '../../../theme';
export var OrderRequestPaymentAction = function (_a) {
    var config = _a.config, order = _a.order, quote = _a.quote, _b = _a.defaultValue, defaultValue = _b === void 0 ? {} : _b, props = __rest(_a, ["config", "order", "quote", "defaultValue"]);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langToast = useTranslation(KEYS.TOAST);
    var initValue = function (order, quote) {
        var _value = __assign({ order: order }, getSnapshot(OrderLoan.create(order.lastLoan || defaultValue)));
        if (!_value._id && order.createdBy && order.createdBy.company) {
            _value.companyName = order.createdBy.company.name;
            _value.companyVAT = order.createdBy.company.vatCode;
            _value.companyTurnover = order.createdBy.company.turnover;
            if (order.createdBy.company.turnoverCurrency)
                _value.companyTurnoverCurrency = order.createdBy.company.turnoverCurrency;
            _value.companyIncorporationAt = order.createdBy.company.createdAt;
            if (quote && quote.createdBy && quote.createdBy.company) {
                _value.companyDebtorName = quote.createdBy.company.name;
                _value.companyDebtorVAT = quote.createdBy.company.vatCode;
                _value.contactPhone = quote.createdBy.phone;
                _value.contactEmail = quote.createdBy.email;
                _value.contactName = [quote.createdBy.fistName, quote.createdBy.lastName].filter(function (e) { return e; }).join(' ');
            }
        }
        [
            'loanAmount',
            'companyDebtorVAT',
            'companyDebtorName',
            'contactPhone',
            'contactEmail',
            'contactName',
            'companyName',
            'companyVAT',
            'companyTurnoverCurrency',
            'companyIncorporationAt',
            'companyTurnover',
        ].forEach(function (e) {
            if (!_value[e])
                _value[e] = '';
        });
        return _value;
    };
    var _c = __read(React.useState(initValue(order, quote)), 2), record = _c[0], setRecord = _c[1];
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var formRef = React.useRef();
    var classes = useFormStyles();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    // createdItem = await _onSubmit(createdItem);
                    if (props.onChange) {
                        props.onChange(e, createdItem);
                    }
                    else if (props.onClose)
                        props.onClose(e, createdItem);
                    return [2 /*return*/];
            }
        });
    }); };
    var _onChange = function (e) {
        var _a;
        if (!e.target)
            return;
        var updates = __assign(__assign({}, record), (_a = {}, _a[e.target.name] = e.target.value, _a));
        if (e.target.name === 'factoringType')
            Object.assign(updates, { updatedAt: new Date().toString() });
        setRecord(updates);
    };
    var _onSubmit = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _b, updates, e_1;
        var updatedAt = _a.updatedAt, _id = _a._id, data = __rest(_a, ["updatedAt", "_id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    _b = config;
                    if (!_b) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: CreateOrderLoan,
                            variables: {
                                input: Object.assign(__assign(__assign({}, data), { order: record.order._id || record.order }), {
                                    companyTurnover: parseFloat(data.companyTurnover),
                                    loanAmount: parseFloat(data.loanAmount),
                                }),
                            },
                        })];
                case 1:
                    _b = (_c.sent());
                    _c.label = 2;
                case 2:
                    res = _b;
                    updates = __assign(__assign({}, record), res.data.createOrderLoan);
                    setRecord(updates);
                    if (!order.lastLoan)
                        order.lastLoan = {};
                    Object.assign(order.lastLoan, updates);
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_requested')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_payment_title')));
                    return [2 /*return*/, updates];
                case 3:
                    e_1 = _c.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, orderFull, res1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!config) return [3 /*break*/, 7];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    setLoading(true);
                    return [4 /*yield*/, config.client.query({
                            query: FetchOrder,
                            variables: {
                                _id: order._id,
                            },
                        })];
                case 2:
                    res = _a.sent();
                    delete res.data.order.price;
                    orderFull = JSON.parse(JSON.stringify(__assign({}, res.data.order)));
                    if (!order.latestQuote) return [3 /*break*/, 4];
                    return [4 /*yield*/, (config &&
                            config.client.query({
                                query: FetchOrderQuoteDetails,
                                variables: {
                                    _id: order.latestQuote._id,
                                },
                            }))];
                case 3:
                    res1 = _a.sent();
                    orderFull.latestQuote = order.latestQuote = __assign(__assign({}, order.latestQuote), res1.data[Object.keys(res1.data)[0]]);
                    _a.label = 4;
                case 4:
                    order.createdBy = orderFull.createdBy; //TODO: better to load it where we need
                    order.forwarders = orderFull.forwarders; //TODO: better to load it where we need
                    delete orderFull.firstRoute;
                    delete orderFull.lastRoute;
                    setRecord(__assign(__assign(__assign({}, record), initValue(orderFull, orderFull.latestQuote)), { updatedAt: new Date().toString() }));
                    return [3 /*break*/, 7];
                case 5:
                    e_2 = _a.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var validationSchema = {
        loanAmount: Yup.number()
            .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
            .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
            .required(langForm.t('form_input_validate_required')),
        loanTerm: Yup.number()
            .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
            .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, '100000000'))
            .required(langForm.t('form_input_validate_required')),
        companyTurnover: Yup.number()
            .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
            .required(langForm.t('form_input_validate_required')),
        companyName: Yup.string()
            .max(100, langForm.t('form_input_validate_max_char').replace(/_X_/, '100'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
        companyVAT: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
        companyDebtorName: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
        companyDebtorVAT: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
        contactPhone: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(phoneRegExp, langForm.t('form_input_validate_invalid_phone'))
            .required('Required'),
        contactEmail: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .email(langForm.t('form_input_validate_invalid_email'))
            .required(langForm.t('form_input_validate_required')),
        contactName: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
    };
    validationSchema = Yup.object(validationSchema);
    /*eslint-disable */
    React.useEffect(function () {
        loadData();
    }, []);
    /*eslint-enable */
    var styles = { marginBottom: theme.spacing(1) };
    var dialogBody = (React.createElement(Formik, { key: record.updatedAt, innerRef: formRef, initialValues: record, validationSchema: validationSchema, onSubmit: _onSubmit }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-request-payment' },
            React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_company'), variant: "outlined", name: "companyName", value: formik.values.companyName, onChange: _onChange })),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_vat_code'), variant: "outlined", name: "companyVAT", value: formik.values.companyVAT, onChange: _onChange }))),
            React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(Grid, { container: true, direction: "column" },
                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                            React.createElement(Grid, { item: true, xs: 7 },
                                React.createElement(FormikTextField, { fullWidth: true, className: classes.priceInput, title: langForm.t('form_input_name_turnover'), variant: "outlined", type: "number", name: "companyTurnover", value: formik.values.companyTurnover, onChange: _onChange })),
                            React.createElement(Grid, { item: true, xs: 5 },
                                React.createElement(FormikSelect, { fullWidth: true, className: classes.currencyInput, variant: "outlined", type: "text", name: "companyTurnoverCurrency", value: formik.values.companyTurnoverCurrency, onChange: _onChange }, Object.keys(OrderCurrency).map(function (value) {
                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                })))))),
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(FormikSingleDatePicker, { minDate: new Date(0), variant: "outlined", title: langForm.t('form_input_name_incorporation_at'), name: "companyIncorporationAt", value: formik.values.companyIncorporationAt, onChange: _onChange }))),
            React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(Grid, { container: true, direction: "column" },
                        React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                            React.createElement(Grid, { item: true, xs: 7 },
                                React.createElement(FormikTextField, { fullWidth: true, title: langForm.t('form_input_name_price'), className: classes.priceInput, variant: "outlined", type: "number", name: "loanAmount", value: formik.values.loanAmount, onChange: _onChange })),
                            React.createElement(Grid, { item: true, xs: 5 },
                                React.createElement(FormikSelect, { fullWidth: true, className: classes.currencyInput, variant: "outlined", type: "text", name: "loanAmountCurrency", value: formik.values.loanAmountCurrency, onChange: _onChange }, Object.keys(OrderCurrency).map(function (value) {
                                    return (React.createElement(MenuItem, { key: value, value: value }, value));
                                })))))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikSelect, { fullWidth: true, title: langForm.t('form_input_name_company_factoring_loan_term'), variant: "outlined", name: "loanTerm", value: formik.values.loanTerm, onChange: _onChange }, [30, 60, 90, 120, 160].map(function (value) {
                        return (React.createElement(MenuItem, { key: value, value: value }, value));
                    }))),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikRadioGroupField, { title: langForm.t('form_input_name_company_factoring_type'), name: 'factoringType', value: formik.values.factoringType, onChange: _onChange, options: Object.keys(OrderLoanFactoringType).map(function (value) { return ({
                            value: value,
                            label: (React.createElement(Grid, { container: true, alignItems: 'center' },
                                React.createElement(TextOverflow, null, langForm.t("form_input_name_company_factoring_type_checkbox_" + value.toLowerCase())),
                                React.createElement(HelpIconComponent, { title: (value === OrderLoanFactoringType.Standard
                                        ? langForm.t('form_input_name_company_factoring_type_standard')
                                        : langForm.t('form_input_name_company_factoring_type_custom')) }))),
                        }); }) }))),
            React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_contact_name'), variant: "outlined", name: "contactName", value: formik.values.contactName, onChange: _onChange })),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_contact_email'), variant: "outlined", name: "contactEmail", value: formik.values.contactEmail, onChange: _onChange })),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_phone'), variant: "outlined", name: "contactPhone", value: formik.values.contactPhone, onChange: _onChange }))),
            React.createElement(Grid, { container: true, direction: "row", spacing: 2, style: styles },
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_company_debtor_name'), variant: "outlined", name: "companyDebtorName", value: formik.values.companyDebtorName, onChange: _onChange })),
                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_name_company_debtor_vat'), variant: "outlined", name: "companyDebtorVAT", value: formik.values.companyDebtorVAT, onChange: _onChange })))));
    }));
    var actionOk = langForm.t('form_btn_get_payment');
    var dialogProps = {};
    var paymentAction = (order === null || order === void 0 ? void 0 : order.status) === OrderStatus.ApprovedQuote ? (React.createElement(BasicDialog, __assign({}, dialogProps, { body: dialogBody, onOk: onOk, actionOkTitle: actionOk, title: actionOk, isMiddleBody: true, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (onOpen) {
            var onClick = onOpen;
            var icon = record._id ? (React.createElement(BaseTooltip, { title: langToast
                    .t('toast_items_requested')
                    .replace(/_X_/, langOrderList.t('in_progress_payment_title')) },
                React.createElement(IconButton, { "aria-label": "menu", style: { cursor: 'initial' }, color: 'secondary', id: 'btn-request-payment' },
                    React.createElement(AccountBalanceWalletIcon, null)))) : (React.createElement(BaseTooltip, { title: langForm.t('form_btn_get_payment') },
                React.createElement(IconButton, __assign({ id: 'btn-request-payment', "aria-label": "menu", color: 'primary', disabled: loading }, __assign({}, (!props.label && { onClick: onClick }))),
                    React.createElement(AccountBalanceWalletIcon, null))));
            if (props.label) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: !record._id && onClick }));
            }
            return icon;
        } }))) : null;
    return paymentAction;
};
