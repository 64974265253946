var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaseUserAppTYpe } from '../stores/common';
var VERSION = process.env.REACT_APP_VERSION;
export var APP_TYPE = process.env.REACT_APP_TYPE;
export var appType = function (langApp) {
    var CUSTOMER_OLD_LINK = 'app.izi-logistics.com';
    var apps = [
        {
            name: langApp.t('user_app_type_customer').toLowerCase(),
            opositeApp: 'customer',
            access: location.origin.toLowerCase().match('.customer.') ||
                location.origin.toLowerCase().match('3000') ||
                location.origin.toLowerCase().match(CUSTOMER_OLD_LINK),
        },
        {
            name: langApp.t('user_app_type_carrier').toLowerCase(),
            opositeApp: 'carrier',
            access: location.origin.toLowerCase().match('.carrier.') || location.origin.toLowerCase().match('3001'),
        },
        {
            name: langApp.t('user_app_type_warehouse').toLowerCase(),
            opositeApp: 'warehouse',
            access: location.origin.toLowerCase().match('.warehouse.') || location.origin.toLowerCase().match('3003'),
        },
        {
            name: langApp.t('user_app_type_forwarder').toLowerCase(),
            opositeApp: 'forwarder',
            access: location.origin.toLowerCase().match('.forwarder.') || location.origin.toLowerCase().match('3004'),
            storeUrl: (location.origin.toLowerCase().match('izi-logistics-') &&
                'https://izi-logistics-forwarder-master.netlify.app') ||
                'https://forwarder.izi-expedition.com',
        },
    ];
    if (APP_TYPE === BaseUserAppTYpe.FORWARDER) {
        apps = [
            apps[0],
            __assign(__assign({}, apps[1]), { storeUrl: (location.origin.toLowerCase().match('izi-logistics-') &&
                    'https://izi-logistics-carrier-master.netlify.app') ||
                    'https://carrier.izi-logistics.com/' }),
            apps[3],
        ];
    }
    var links = apps.filter(function (e) { return !e.access; });
    var _location = location.origin.match('https') ? location.origin : 'https://admin.izi-logistics.com';
    if (_location.match(CUSTOMER_OLD_LINK))
        _location = 'http://customer.izi-logistics.com/';
    var currentApp = apps.find(function (e) { return _location.toLowerCase().match(e.opositeApp); }) ||
        (_location.match('admin') && { opositeApp: 'admin' });
    var origin = (currentApp && _location.replace(new RegExp(currentApp.opositeApp, 'gi'), '____')) || '';
    var app = apps.find(function (e) { return e.access; }) || {};
    return {
        links: links,
        origin: origin,
        currentApp: currentApp,
        app: app.opositeApp,
    };
};
