var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { HOLIDAY_BASE } from '../holiday';
import { WAREHOUSE_BASE, WAREHOUSE_NONE_WORKING_DAY_TIME_BASE } from './types';
import { WAREHOUSE_WORKING_DAY_MORE_v1 } from './warehouseWorkingDay';
import { CALENDAR_ORDER_TRANSPORTATION_MORE } from './calendar';
export var FetchAllCalendarData = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query calendarData($filter: MainFilter) {\n    calendarData(filter: $filter) {\n      holidays{\n          data{\n             ", "\n          }\n          count\n      }\n      warehouses{\n          data{\n             ", "\n          }\n          count\n      }\n      workingDays{\n          data{\n             ", "\n          }\n          count\n      }\n      transportations{\n          data{\n             ", "\n          }\n          count\n      }\n      transportationsBusy{\n          data{\n             ", "\n          }\n          count\n      }\n      noneWorkingDayTime{\n          data{\n             ", "\n          }\n          count\n      }\n      reservedDays{\n          data\n          count\n      }\n    }\n  }\n"], ["\n  query calendarData($filter: MainFilter) {\n    calendarData(filter: $filter) {\n      holidays{\n          data{\n             ", "\n          }\n          count\n      }\n      warehouses{\n          data{\n             ", "\n          }\n          count\n      }\n      workingDays{\n          data{\n             ", "\n          }\n          count\n      }\n      transportations{\n          data{\n             ", "\n          }\n          count\n      }\n      transportationsBusy{\n          data{\n             ", "\n          }\n          count\n      }\n      noneWorkingDayTime{\n          data{\n             ", "\n          }\n          count\n      }\n      reservedDays{\n          data\n          count\n      }\n    }\n  }\n"])), HOLIDAY_BASE, WAREHOUSE_BASE, WAREHOUSE_WORKING_DAY_MORE_v1, CALENDAR_ORDER_TRANSPORTATION_MORE, CALENDAR_ORDER_TRANSPORTATION_MORE, WAREHOUSE_NONE_WORKING_DAY_TIME_BASE);
var templateObject_1;
