import { applySnapshot, types } from 'mobx-state-tree';
export var OrderCargoPackage = types
    .model('OrderCargoPackage', {
    _id: types.optional(types.string, ''),
    name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.maybeNull(types.optional(types.string, ''))),
    isGlobal: types.maybeNull(types.maybeNull(types.optional(types.boolean, false))),
    createdAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
