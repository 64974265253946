var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { AvatarItemView } from '../../components/Account/Avatar';
import { theme } from '../../theme';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withChannelStore } from './stores/Channel';
import BaseTooltip from '../../components/BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseUserAppTYpe, USER_ROLE } from '../../stores';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            textAlign: 'center',
            position: 'relative',
            // width: 'initial',
            width: 42,
        },
        avatar: {
            position: 'absolute',
            bottom: 3,
            right: 3,
            zIndex: 1,
            width: 12,
            height: 12,
            border: "2px solid " + theme.palette.common.white,
            borderRadius: '50%',
        },
        inactive: {
            background: theme.palette.warning.main,
        },
        offline: {
            background: theme.palette.grey['300'],
        },
        online: {
            background: theme.palette.success.main,
        },
    });
});
//user.appType[0]
var getAvatarStyle = function (appType) {
    if (appType === BaseUserAppTYpe.CARRIER) {
        return {
            backgroundColor: '#002B68',
            color: theme.palette.common.white,
        };
    }
    else if (appType === BaseUserAppTYpe.FORWARDER) {
        return {
            backgroundColor: '#004F98',
            color: theme.palette.common.white,
        };
    }
    else if (appType === BaseUserAppTYpe.CUSTOMER) {
        return {
            backgroundColor: '#6F6F6F',
            color: theme.palette.common.white,
        };
    }
    else if (appType === BaseUserAppTYpe.SENDER || appType === BaseUserAppTYpe.CONSIGNEE) {
        return {
            backgroundColor: '#D5DDE5',
            color: theme.palette.primary.main,
        };
    }
    return null;
};
var ChatUserAvatar = function (_a) {
    var _b;
    var _c;
    var user = _a.user, channelStore = _a.channelStore, className = _a.className, props = __rest(_a, ["user", "channelStore", "className"]);
    var classes = useStyles();
    var langApp = useTranslation(KEYS.APP);
    var online = [];
    var inactive = [];
    for (var i = 0; i < channelStore.state.allUsers.length; i++) {
        var e = channelStore.state.allUsers[i];
        if (!e.lastestAction || Date.now() - moment(e.latestAction.time).valueOf() < 1000 * 60 * 3) {
            online.push(e._id);
        }
        else if (e.lastestAction && Date.now() - moment(e.latestAction.time).valueOf() >= 1000 * 60 * 3) {
            inactive.push(e._id);
        }
    }
    var onlineState = online.includes(user._id);
    var inactiveState = !onlineState && inactive.includes(user._id);
    if (props.isShort) {
        return (React.createElement(AvatarItemView, { border: true, moreDetail: true, avatarStyle: { width: 26, height: 26 }, typographyProps: {
                variant: 'h6',
                style: {
                    fontSize: 12,
                    fontWeight: 600,
                    color: theme.palette.common.white,
                },
            }, user: user }));
    }
    var title = langApp.t("main_status_" + (onlineState ? 'online' : !onlineState && !inactiveState ? 'offline' : 'inactive'));
    var avatarStyle = props.appType && getAvatarStyle(user.appType[0]);
    return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', direction: 'column', className: classes.root },
        React.createElement(AvatarItemView, { hideType: (_c = user === null || user === void 0 ? void 0 : user.roles) === null || _c === void 0 ? void 0 : _c.includes(USER_ROLE.ADMIN), isRoleOnly: props.appType, border: true, moreDetail: true, className: className, typographyProps: {
                variant: 'h6',
                style: {
                    fontWeight: 600,
                    color: (avatarStyle === null || avatarStyle === void 0 ? void 0 : avatarStyle.color) || theme.palette.common.white,
                },
            }, avatarStyle: { backgroundColor: avatarStyle === null || avatarStyle === void 0 ? void 0 : avatarStyle.backgroundColor }, user: user }),
        React.createElement(BaseTooltip, { title: title },
            React.createElement("div", { className: clsx(classes.avatar, (_b = {},
                    _b[classes.online] = onlineState,
                    _b[classes.offline] = !onlineState && !inactiveState,
                    _b[classes.inactive] = !inactiveState,
                    _b)) }))));
};
export default withChannelStore(ChatUserAvatar);
