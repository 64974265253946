var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { formUserErrorMessage, pswRegExp } from '../../common';
import { FormikTextField } from '../../components/Formik';
import { i18n, KEYS } from '../../i18n';
import { useLocalStore } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { UpdateCurrentUser } from '../../graphql';
import { ACTION_CHANGE } from '../../components/BasicList';
import SecurityIcon from '@material-ui/icons/Security';
export default (function (_a) {
    var onChange = _a.onChange, config = _a.config, record = _a.record, handleClickOpen = _a.handleClickOpen;
    var formRef = React.useRef();
    var langForm = useTranslation(KEYS.FORM);
    var theme = useTheme();
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_a) {
            var password = _a.password;
            return __awaiter(this, void 0, void 0, function () {
                var res, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            if (!config) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UpdateCurrentUser,
                                    variables: {
                                        input: {
                                            _id: record._id,
                                            password: password,
                                        },
                                    },
                                })];
                        case 2:
                            res = _b.sent();
                            toast.success(i18n
                                .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_user')));
                            return [2 /*return*/, res.data.updateUser];
                        case 3: return [3 /*break*/, 6];
                        case 4:
                            error_1 = _b.sent();
                            localStore.error = formUserErrorMessage(error_1);
                            toast.error(localStore.error);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (onChange)
                        onChange(createdItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { innerRef: formRef, initialValues: {
                password: '',
                confirmPassword: '',
            }, validationSchema: Yup.object({
                password: Yup.string()
                    .min(8, langForm.t('form_input_validate_min_char').replace(/_X_/g, '8'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(pswRegExp, langForm.t('form_input_validate_invalid_psw'))
                    .required(langForm.t('form_input_validate_required')),
                confirmPassword: Yup.string().when('password', {
                    is: function (val) { return (val && val.length > 0 ? true : false); },
                    then: Yup.string().oneOf([Yup.ref('password')], langForm.t('form_input_validate_match')),
                }),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, null,
                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(1) } },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_name_password'), variant: "outlined", name: "password", value: formik.values.password })),
                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(1) } },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_name_password_confirm'), variant: "outlined", name: "confirmPassword", value: formik.values.confirmPassword })))));
        })));
    return (React.createElement(BasicDialog, { dialogProps: { fullWidth: true, maxWidth: 'md' }, withoutWrapper: true, onOk: onOk, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' }, "Change password"), actionInit: handleClickOpen
            ? handleClickOpen
            : function (handleClickOpen) { return (React.createElement(IconButton, { onClick: handleClickOpen, color: 'primary', id: 'edit-btn', style: { padding: 0 } },
                React.createElement(SecurityIcon, null))); }, body: dialogBody }));
});
