var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback } from 'react';
import { BasicDialog } from './BasicDialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FetchUploadByPathPreview, FetchUploadPreview } from '../../graphql';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';
import DownLoadDocument from './DownLoadFile';
import { SignDocument } from '../../pages/Quotes/Documents/SignDocument';
import { inject } from 'mobx-react';
import { TextOverflow } from '../TextOverflow';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& *': {
            margin: 'auto',
            height: '100%',
        },
    },
}); });
export var PreviewComponent = function (_a) {
    var item = _a.item, config = _a.config, setUpload = _a.setUpload, props = __rest(_a, ["item", "config", "setUpload"]);
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(true), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(null), 2), upload1 = _c[0], setUpload1 = _c[1];
    var classes = useStyles();
    var loadData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, _b, _c, e_1;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 8, 9, 10]);
                    if (!(item && item.pathBlob)) return [3 /*break*/, 1];
                    (setUpload || setUpload1)(__assign(__assign({}, item), { path: item.pathBlob }));
                    return [3 /*break*/, 7];
                case 1:
                    _a = config;
                    if (!_a) return [3 /*break*/, 6];
                    _c = item._id;
                    if (!_c) return [3 /*break*/, 3];
                    return [4 /*yield*/, config.client.query({
                            query: FetchUploadPreview,
                            variables: {
                                _id: item._id,
                            },
                        })];
                case 2:
                    _c = (_d.sent());
                    _d.label = 3;
                case 3:
                    _b = (_c);
                    if (_b) return [3 /*break*/, 5];
                    return [4 /*yield*/, config.client.query({
                            query: FetchUploadByPathPreview,
                            variables: {
                                path: item.path,
                            },
                        })];
                case 4:
                    _b = (_d.sent());
                    _d.label = 5;
                case 5:
                    _a = (_b);
                    _d.label = 6;
                case 6:
                    res = _a;
                    (setUpload || setUpload1)(res.data.uploadPreview || res.data.uploadPreviewByPath);
                    _d.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    e_1 = _d.sent();
                    console.log(e_1);
                    return [3 /*break*/, 10];
                case 9:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [config, item]);
    React.useEffect(function () {
        loadData();
    }, []);
    if (loading)
        return (React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', style: { width: 'initial' } },
            React.createElement(CircularIndeterminate, null)));
    var upload = props.upload || upload1;
    if (!upload || !upload.path)
        return React.createElement(Typography, { color: 'error' },
            langApp.t('main_permission_denied'),
            "!");
    var onLoad = function () {
        var iframe = document.getElementById('file-preview');
        if (iframe && iframe.contentWindow) {
            var style = document.createElement('style');
            style.textContent = "body{text-align:'center'} \n                img{height:'100%'}";
            // iframe.contentWindow.document.head.appendChild(style);
        }
    };
    return (React.createElement("iframe", { id: 'file-preview', onLoad: onLoad, frameBorder: 'none', src: upload.path, className: classes.root, style: {
            width: '100%',
            height: 'calc(100vh - 260px)',
        } }));
};
export var FilePreview = inject('store')(function (_a) {
    var item = _a.item, v1 = _a.v1, props = __rest(_a, ["item", "v1"]);
    var _b = __read(React.useState(null), 2), upload = _b[0], setUpload = _b[1];
    var langApp = useTranslation(KEYS.APP);
    var actonInit = props.actionInit
        ? props.actionInit
        : v1
            ? function (handleClickOpen) { return (React.createElement(TextOverflow, { v6: true, style: __assign({}, props.style), onClick: handleClickOpen }, item.name || 'File')); }
            : function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: props.title || 'Click to preview file' },
                React.createElement(IconButton, { onClick: handleClickOpen, style: __assign({}, props.style) },
                    React.createElement(VisibilityIcon, null)))); };
    var body = function (open) {
        if (open) {
            return React.createElement(PreviewComponent, __assign({ item: item, upload: upload, setUpload: setUpload }, props));
        }
        else {
            return null;
        }
    };
    return (React.createElement(BasicDialog, __assign({ withoutWrapper: true, fullWidth: true, moreActions: function (_a) {
            var handleClose = _a.handleClose, open = _a.open;
            return open && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(SignDocument, __assign({}, props, { item: item, store: props.store, handleClose: handleClose }))),
                React.createElement(DownLoadDocument, __assign({ item: item, handleClose: handleClose }, props, { style: { marginRight: theme.spacing(1) } }))));
        }, dialogProps: {
            fullWidth: true,
            maxWidth: 'xl',
        } }, props, { actionInit: actonInit, actionOkTitle: langApp.t('app_open'), onOk: function () {
            var w = window.open(upload.path, '_blank');
            if (w) {
                w.focus();
            }
        }, body: body })));
});
