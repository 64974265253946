var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { createStyles, Grid } from '@material-ui/core';
import { FormikAutocompleteAsync } from '../../components/Formik';
import { phoneRegExp, specialChar } from '../../common';
import { inject } from 'mobx-react';
import { TextOverflow } from '../../components';
import PhoneIcon from '@material-ui/icons/Phone';
import { theme } from '../../theme';
import { getSnapshot } from 'mobx-state-tree';
import { CreateOrderQuoteTransport, UpdateOrderQuoteTransport } from '../../graphql/order/orderQuoteTransport';
import { OrderQuoteTransport } from '../../models/OrderQuoteTransport';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import { FetchGeneralModelFieldValues } from '../../graphql/general';
import { makeStyles } from '@material-ui/core/styles';
import { orderHasContainerVehicleType } from '../../utils1';
import { SOCKET_CUSTOM_EVENT_NAME } from '../../components/Socket/types';
import { withOrderListItemStore } from '../../components/Order/List/Customer/storeListItem';
import { useAnimationStyles } from '../../styles/main';
var useTransportStyles = makeStyles(function (theme) {
    return createStyles({
        select: {
            '& input': {
            // padding: `0!important`,
            },
        },
        root: {
            '& .MuiInputBase-root': {
                paddingTop: '0!important',
                paddingBottom: '0!important',
            },
        },
    });
});
export var TransportForm = inject('store')(withOrderListItemStore(function (_a) {
    var _b, _c, _d, _e;
    var _f, _g, _h, _j, _k;
    var transportation = _a.transportation, item = _a.item, onChange = _a.onChange, config = _a.config, keyUpdate = _a.keyUpdate, order = _a.order, orderListItemProps = _a.orderListItemProps, props = __rest(_a, ["transportation", "item", "onChange", "config", "keyUpdate", "order", "orderListItemProps"]);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var classes = useTransportStyles();
    var formRef = React.useRef();
    var _l = __read(React.useState(false), 1), loading = _l[0];
    var _m = __read(React.useState(getSnapshot(OrderQuoteTransport.create((transportation.transports && transportation.transports[0]) || {}))), 2), record = _m[0], setRecord = _m[1];
    var id = ((_f = transportation.transports) === null || _f === void 0 ? void 0 : _f.length) ? transportation.transports[0]._id : record._id;
    var classesAnimation = useAnimationStyles();
    useEffect(function () {
        formRef && formRef.current && formRef.current.submitForm();
        setTimeout(function () {
            formRef && formRef.current && formRef.current.submitForm();
        });
    }, []);
    var onSubmit = function (e) { return e; };
    function onChangeInput(e) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, value, name;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = e.target, value = _a.value, name = _a.name;
                        if (record[name] === value)
                            return [2 /*return*/, console.warn('No Changes')];
                        return [4 /*yield*/, formRef.current.setFieldValue(name, value)];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, formRef.current.validateField(name)];
                    case 2:
                        _c.sent();
                        if (formRef.current.errors[name]) {
                            toast.error(formRef.current.errors[name]);
                            return [2 /*return*/, formRef.current.setFieldTouched(name)];
                        }
                        return [4 /*yield*/, _onChange((_b = {}, _b[name] = value, _b))];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    var _onChange = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var recordItem, rawUpdates, res, _b, res, _c;
        var approvedAt = _a.approvedAt, origin = _a.origin, updates = __rest(_a, ["approvedAt", "origin"]);
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    rawUpdates = JSON.parse(JSON.stringify(updates));
                    Object.assign(updates, {
                        quote: item._id,
                        orderTransportation: transportation._id,
                    });
                    if (!id) return [3 /*break*/, 3];
                    _b = config;
                    if (!_b) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.query({
                            query: UpdateOrderQuoteTransport,
                            variables: {
                                input: __assign(__assign({}, updates), { _id: id }),
                            },
                        })];
                case 1:
                    _b = (_d.sent());
                    _d.label = 2;
                case 2:
                    res = _b;
                    recordItem = res.data.updateOrderQuoteTransport;
                    toast.success("" + langToast.t('toast_items_saved').replace(/_X_/, langOfferForm.t('offer_transport')));
                    return [3 /*break*/, 6];
                case 3:
                    _c = config;
                    if (!_c) return [3 /*break*/, 5];
                    return [4 /*yield*/, config.client.query({
                            query: CreateOrderQuoteTransport,
                            variables: {
                                input: updates,
                            },
                        })];
                case 4:
                    _c = (_d.sent());
                    _d.label = 5;
                case 5:
                    res = _c;
                    recordItem = res.data.createOrderQuoteTransport;
                    toast.success("" + langToast.t('toast_items_created').replace(/_X_/, langOfferForm.t('offer_transport')));
                    _d.label = 6;
                case 6:
                    recordItem = __assign(__assign(__assign({}, record), recordItem), { orderTransportation: transportation });
                    setRecord(recordItem);
                    onChange && onChange(recordItem, rawUpdates);
                    formRef && formRef.current && formRef.current.submitForm();
                    return [2 /*return*/];
            }
        });
    }); };
    var hasUpdates = [SOCKET_CUSTOM_EVENT_NAME.QUOTE_ASSIGN_TRANSPORT].includes((_h = (_g = orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.state) === null || _g === void 0 ? void 0 : _g.lastUpdates) === null || _h === void 0 ? void 0 : _h.event) && ((_k = (_j = orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.state) === null || _j === void 0 ? void 0 : _j.lastUpdates) === null || _k === void 0 ? void 0 : _k._updates);
    if (props.is_review_offer) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, style: { flexWrap: 'wrap' } },
                React.createElement(TextOverflow, { style: { zIndex: 1 }, className: clsx((_b = {},
                        _b[classesAnimation.pulse] = hasUpdates && hasUpdates.container,
                        _b)) },
                    React.createElement("b", null, record.trailer || '-')),
                React.createElement(TextOverflow, { className: clsx((_c = {},
                        _c[classesAnimation.pulse] = hasUpdates && hasUpdates.track,
                        _c)), style: { zIndex: 1, padding: theme.spacing(0, 1) } },
                    React.createElement("b", null,
                        " ",
                        record.track || '-')),
                React.createElement(TextOverflow, null,
                    record.vehicleType || '-',
                    " ")),
            React.createElement(Grid, { container: true, alignItems: 'center' },
                React.createElement(TextOverflow, { className: clsx((_d = {},
                        _d[classesAnimation.pulse] = hasUpdates && hasUpdates.contactName,
                        _d)), style: { zIndex: 1, maxWidth: '50%', paddingLeft: theme.spacing(0, 1) } }, record.contactName || '-'),
                React.createElement(Grid, { className: clsx((_e = {},
                        _e[classesAnimation.pulse] = hasUpdates && hasUpdates.phone,
                        _e)), container: true, style: {
                        zIndex: 1,
                        display: 'flex',
                        marginLeft: theme.spacing(2),
                        maxWidth: '50%',
                    } },
                    React.createElement(PhoneIcon, null),
                    React.createElement(TextOverflow, { style: { maxWidth: '50%', marginLeft: theme.spacing(1) } }, record.phone || '-')))));
    }
    var hasContainer = (order === null || order === void 0 ? void 0 : order.vehicleType) && orderHasContainerVehicleType(order === null || order === void 0 ? void 0 : order.vehicleType);
    var availableKeys = ['track', 'trailer', 'contactName', 'phone', 'container'];
    if (keyUpdate)
        availableKeys = availableKeys.filter(function (e) { return e === keyUpdate; });
    var validateSchema = __assign(__assign({}, (hasContainer
        ? {
            container: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .required(langForm.t('form_input_validate_required')),
        }
        : {})), { track: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .required(langForm.t('form_input_validate_required'))
            .test('track', langForm.t('form_input_no_special'), function (val) {
            return !specialChar.test(val);
        }), trailer: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .required(langForm.t('form_input_validate_required'))
            .test('track', langForm.t('form_input_no_special'), function (val) {
            return !specialChar.test(val);
        }), contactName: Yup.string().max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50')), phone: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .matches(phoneRegExp, langForm.t('form_input_validate_invalid_phone')) });
    var initValues = JSON.parse(JSON.stringify(record));
    availableKeys.forEach(function (e) {
        if (!initValues[e])
            initValues[e] = '';
    });
    Object.keys(validateSchema).forEach(function (key) {
        if (!availableKeys.includes(key))
            delete validateSchema[key];
    });
    return (React.createElement(Formik, { innerRef: formRef, key: [record.updatedAt, loading, props.hasUpdates].join('_'), initialValues: initValues, validationSchema: Yup.object(validateSchema), onSubmit: onSubmit }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-transport' },
            React.createElement(Grid, __assign({ container: true, direction: "row" }, (!keyUpdate && { spacing: 2 }), { className: keyUpdate && classes.root, style: props.style }), [
                hasContainer
                    ? { name: 'container', title: langOfferForm.t('form_input_name_container_number'), md: 3 }
                    : null,
                { name: 'track', title: langOfferForm.t('offer_form_truck_plate_number'), md: 2 },
                { name: 'trailer', title: langOfferForm.t('offer_form_trailer_plate_number'), md: 2 },
                { name: 'contactName', title: langOfferForm.t('offer_form_transport_driver'), md: 3 },
                { name: 'phone', title: langOfferForm.t('offer_form_driver'), md: 2 },
            ]
                .filter(function (e) { return e && availableKeys.includes(e.name); })
                .map(function (e) {
                var _a, _b;
                var _c;
                return (React.createElement(Grid, __assign({ item: true, xs: 12, md: keyUpdate ? 12 : e.md, key: [e._id, props.hasUpdates].join('_'), style: { zIndex: 1, position: 'relative' } }, (_a = {},
                    _a['data-title-1'] = [
                        i18n.getResource(i18n.language, KEYS.APP, 'click_to_book'),
                        (_c = e.title) === null || _c === void 0 ? void 0 : _c.toLowerCase(),
                    ].join(' '),
                    _a), { className: clsx((_b = {},
                        _b[classes.select] = props.isSimple,
                        _b[classesAnimation.pulse] = props.hasUpdates,
                        _b)) }),
                    React.createElement(FormikAutocompleteAsync, { v16: true, v17: true, v18: true, v21: true, required: true, title: props.v21 ? langApp.t('app_select') : e.title, variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, name: e.name, client: config.client, query: FetchGeneralModelFieldValues, variables: { filter: { fields: [{ name: 'field', value: e.name }] } }, value: formik.values[e.name], autocompleteProps: {
                            defaultValue: formik.values[e.name]
                                ? {
                                    _id: formik.values[e.name],
                                }
                                : null,
                            getOptionSelected: function (option, value) {
                                return parseFloat(option._id) === parseFloat(value._id);
                            },
                            getOptionLabel: function (option) {
                                return (option.title || option._id || '').toString();
                            },
                        }, onChange: onChangeInput })));
            }))));
    }));
}));
