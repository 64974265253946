var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { CreateOrderGroup, FetchOrderGroup, GenerateOrderGroups, PublishOrderGroups, UpdateOrderGroup, } from './../graphql/order/orderGroup';
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { toast } from 'react-toastify';
import browserHistory from '../browserHistory';
import { NAVIGATION } from '../HOK/LocationHistory';
import { googleTagManager } from '../utils1';
import { OrderGroup } from '../models/OrderGroup';
import { OrderStoreGeneralFactory } from './OrderStoreGeneralFactory';
import { onUpdateItem } from './OrderStoreFactory';
import { i18n } from '../i18n';
import { CopyOrderGroup, DuplicateOrderGroup } from '../graphql/order/orderGroup';
import { KEYS } from '../i18n';
function mapOrderGroupInput(input) {
    return Object.assign.apply(Object, __spread([__assign({}, input)], onUpdateItem(input), [typeof input.totalOrders !== 'undefined'
            ? {
                totalOrders: parseInt(input.totalOrders.toString()),
            }
            : {},
        typeof input.totalGoodsPerOrder !== 'undefined'
            ? {
                totalGoodsPerOrder: parseInt(input.totalGoodsPerOrder.toString()),
            }
            : {},
        typeof input.lastStep !== 'undefined'
            ? {
                lastStep: parseInt(input.lastStep.toString()),
            }
            : {}]));
}
export var OrderGroupStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(OrderStoreGeneralFactory({ client: client, model: OrderGroup }), types.model('OrderGroupStore', {}))
        .actions(function (self) { return ({
        createItem: flow(function (_a, additional) {
            var beforeCreate, usedTypes_1, dirtyRoutes, routes_1, result, updates, e_1;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        beforeCreate = getSnapshot(self.item);
                        usedTypes_1 = [];
                        dirtyRoutes = __spread([
                            additional
                        ], beforeCreate.transportations
                            .map(function (e) { return e.routes.find(function (r) { var _a; return ((_a = r.origin) === null || _a === void 0 ? void 0 : _a._id) && r.email.length && r.startDate && r.endDate; }); })
                            .filter(function (e) { return e; })).filter(function (e) {
                            if (!e || usedTypes_1.includes(e.type))
                                return false;
                            usedTypes_1.push(e.type);
                            return true;
                        });
                        routes_1 = [];
                        dirtyRoutes.forEach(function (dirtyRoute) {
                            var _a;
                            if ((_a = dirtyRoute === null || dirtyRoute === void 0 ? void 0 : dirtyRoute.origin) === null || _a === void 0 ? void 0 : _a._id) {
                                var _id_1 = dirtyRoute._id, warehouse = dirtyRoute.warehouse, orderTransportation = dirtyRoute.orderTransportation, origin_1 = dirtyRoute.origin, rangeDates = dirtyRoute.rangeDates, updatedAt = dirtyRoute.updatedAt, remainRoute = __rest(dirtyRoute, ["_id", "warehouse", "orderTransportation", "origin", "rangeDates", "updatedAt"]);
                                routes_1.push(__assign(__assign({}, remainRoute), { origin: origin_1._id }));
                            }
                        });
                        googleTagManager('event', 'izi_form_order_group_create', { event_name: "order_group_create" });
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderGroup,
                                variables: {
                                    input: __assign(__assign({}, mapOrderGroupInput(input)), { transportations: [{ routes: routes_1.length ? routes_1 : [] }] }),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        updates = result.data.createOrderGroup;
                        self.item && self.item.reset(updates);
                        setTimeout(function () { return browserHistory.push("/order-groups/" + self.item._id); }, 100);
                        return [2 /*return*/, self.item];
                    case 2:
                        e_1 = _b.sent();
                        console.log(e_1);
                        toast.error(e_1.message);
                        googleTagManager('event', 'izi-error', { value: e_1.message || e_1, event_name: 'error_order_group_create' });
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateItem: flow(function (_a) {
            var order_1, keys, result, e_2;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        order_1 = self.item;
                        if (!(!order_1 || !order_1._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createItem(input)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        keys = Object.keys(input);
                        if (!keys.filter(function (e) { return e; }).length || keys.filter(function (e) { return input[e] === order_1[e]; }).length === keys.length) {
                            self.isUpdating = false;
                            return [2 /*return*/, console.warn('No Changes')];
                        }
                        googleTagManager('event', 'izi_form_order_group_update', { event_name: "order_group_update" });
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderGroup,
                                variables: {
                                    input: __assign(__assign({}, mapOrderGroupInput(input)), { _id: _id || (order_1 && order_1._id) }),
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        self.item &&
                            self.item.reset(__assign(__assign(__assign({}, getSnapshot(order_1)), input), result.data.updateOrderGroup));
                        return [2 /*return*/, self.item];
                    case 4:
                        e_2 = _b.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        loadItem: flow(function (_id, noStore) {
            var result, _a, _b, _c, _d, e_3;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, 3, 4]);
                        if (!noStore && self.item && self.item._id === _id) {
                            console.warn('already loaded');
                            return [2 /*return*/, self.item];
                        }
                        self.isLoading = true;
                        _b = (_a = JSON).parse;
                        _d = (_c = JSON).stringify;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderGroup,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _b.apply(_a, [_d.apply(_c, [_e.sent()])]);
                        // self.isOrderLoading = false;
                        if (noStore)
                            return [2 /*return*/, result.data.orderGroup];
                        self.clearItemForce(OrderGroup.create(result.data.orderGroup));
                        return [2 /*return*/, self.item];
                    case 2:
                        e_3 = _e.sent();
                        console.log(e_3);
                        setTimeout(function () { return NAVIGATION.reset(); });
                        browserHistory.push('/order-groups');
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        generateGroupOrders: flow(function (_id) {
            var result, item_1, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: GenerateOrderGroups,
                                variables: {
                                    _id: _id || self.item._id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        item_1 = result.data.generateGroupOrders;
                        googleTagManager('event', 'izi_form_order_group_generate', __assign({ event_name: "izi_form_order_group_generate" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return item_1[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = item_1[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.item &&
                            self.item.reset(__assign(__assign({}, getSnapshot(self.item)), { generatedAt: item_1.generatedAt, lastStep: item_1.lastStep, orders: item_1.orders }));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_new_order_title_group')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_4 = _a.sent();
                        toast.error(e_4.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        publishGroupOrders: flow(function (_id) {
            var result, item_2, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: PublishOrderGroups,
                                variables: {
                                    _id: _id || self.item._id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        item_2 = result.data.publishGroupOrders;
                        googleTagManager('event', 'izi_form_order_group_pubish', __assign({ event_name: "izi_form_order_group_pubish" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return item_2[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = item_2[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.item && self.item.reset(item_2);
                        toast.success(i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_general_published'));
                        browserHistory.push("/open-orders");
                        return [3 /*break*/, 4];
                    case 2:
                        e_5 = _a.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        duplicateItem: flow(function (_id) {
            var result, item_3, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateOrderGroup,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        item_3 = result.data.duplicateOrderGroup;
                        browserHistory.push("/orders/groupes" + item_3._id);
                        googleTagManager('event', 'izi_form_order_duplicate_from_template', __assign({ event_name: "order_duplicate_from_template" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return item_3[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = item_3[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.clearItemForce(OrderGroup.create(item_3));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_new_order_title_group')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_6 = _a.sent();
                        toast.error(e_6.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        copyItem: flow(function (source) {
            var _id, result, e_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        _id = self.item._id.toString();
                        return [4 /*yield*/, client.mutate({
                                mutation: CopyOrderGroup,
                                variables: {
                                    input: {
                                        target: _id,
                                        source: source,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_new_order_title_group')));
                        self.clearItemForce(OrderGroup.create(JSON.parse(JSON.stringify(result.data.copyOrderGroup))));
                        self.updatedAt = Date.now();
                        return [3 /*break*/, 4];
                    case 2:
                        e_7 = _a.sent();
                        toast.error(e_7.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .views(function (self) { return ({
        get order() {
            return self.item;
        },
    }); })
        .named('OrderGroupStore');
};
