import { applySnapshot, types } from 'mobx-state-tree';
import { MaybeEmptyModel } from '../stores/common';
import { OrderRoute } from './OrderRoute';
import { OrderCargo } from './OrderCargo';
import { OrderQuoteTransport } from './OrderQuoteTransport';
export var OrderTransportation = types
    .model('OrderTransportation', {
    _id: types.optional(types.string, ''),
    unloadedAt: types.maybeNull(types.optional(types.string, '')),
    inTransitAt: types.maybeNull(types.optional(types.string, '')),
    isInTransitForced: types.maybeNull(types.boolean),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    startManufacturingDate: types.maybeNull(types.string),
    endManufacturingDate: types.maybeNull(types.string),
    startTime: types.maybeNull(types.array(types.union(types.string, types.number))),
    endTime: types.maybeNull(types.array(types.union(types.string, types.number))),
    transports: types.optional(types.array(MaybeEmptyModel(OrderQuoteTransport)), []),
    routes: types.optional(types.array(MaybeEmptyModel(OrderRoute)), []),
    cargoes: types.optional(types.array(MaybeEmptyModel(OrderCargo)), []),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
