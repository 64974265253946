var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCalendarStore } from '../store';
import { toDateOnly } from '../../../utils';
import { Grid, MenuItem } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { TextOverflow } from '../../TextOverflow';
import { theme } from '../../../theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import { COLOR_DELIVERY, COLOR_PICKUP } from '../../../styles';
import { BasicDialog } from '../../Dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikSelect } from '../../Formik';
import { WAREHOUSE_BOOKING_EVENT } from '../BookingNewOrder/types';
import { OrderRouteType } from '../../../models';
export default withCalendarStore(function (_a) {
    var _b = _a.state, ramps = _b.ramps, isPublic = _b.isPublic, day = _a.day, isPickup = _a.isPickup;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var formRef = React.useRef();
    if (!ramps.count)
        return null;
    var title = langApp.t(isPickup ? 'calendar_add_pickup_order' : 'calendar_add_delivery_order');
    if (ramps.count === 1) {
        var onClick = function () {
            document.body.dispatchEvent(new CustomEvent(WAREHOUSE_BOOKING_EVENT.SHOW, {
                detail: {
                    date: toDateOnly(day),
                    time: '',
                    item: { ramp: ramps.data[0] },
                    direction: isPickup ? OrderRouteType.Preferred : OrderRouteType.Delivery,
                },
            }));
        };
        return (React.createElement(Grid, { container: true, onClick: onClick, alignItems: 'center', justify: 'space-between' },
            React.createElement(TextOverflow, null, title),
            React.createElement(ControlPointIcon, { style: { color: isPickup ? COLOR_PICKUP : COLOR_DELIVERY, marginLeft: theme.spacing(1) } })));
    }
    var onOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var item;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    item = _a.sent();
                    if (!item)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    document.body.dispatchEvent(new CustomEvent(WAREHOUSE_BOOKING_EVENT.SHOW, {
                        detail: {
                            date: toDateOnly(day),
                            time: '',
                            item: item,
                            direction: isPickup ? OrderRouteType.Preferred : OrderRouteType.Delivery,
                        },
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
    var initialValues = { ramp: ramps.data[0] || { _id: '' } };
    var dialogBody = (React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, validationSchema: Yup.object({
            ramp: Yup.object({
                _id: Yup.string(),
            }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                return !!val._id;
            }),
        }), onSubmit: function (e) { return e; } }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-warehouse-add-order' },
            React.createElement(FormikSelect, { v1: true, v17: true, title: langApp.t('warehouse_ramps'), fullWidth: true, variant: "outlined", name: "ramp", value: formik.values.ramp }, ramps.data.map(function (ramp) {
                return (React.createElement(MenuItem, { key: ramp._id, value: ramp }, ramp.name));
            }))));
    }));
    return (React.createElement(BasicDialog, { body: dialogBody, onOk: onOk, shouldNotPrevent: true, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'sm' }, title: title, actionInit: function (onOpen) { return (React.createElement(Grid, { container: true, onClick: onOpen, alignItems: 'center', justify: 'space-between' },
            React.createElement(TextOverflow, null, title),
            React.createElement(ControlPointIcon, { style: { color: isPickup ? COLOR_PICKUP : COLOR_DELIVERY, marginLeft: theme.spacing(1) } }))); } }));
});
