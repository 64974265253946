var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ListItem, ListItemText } from '@material-ui/core';
import { useStyles } from '../styles';
import { useMenuItemStyles } from '../MenuItem';
import { useObserver } from 'mobx-react-lite';
import { NavLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
import { isOpen } from '../utils';
var CollapseListItemComponent = function (_a) {
    var location = _a.location, item = _a.item, props = __rest(_a, ["location", "item"]);
    var title = props.title, path = props.path;
    var classes = useStyles();
    var classesMenu = useMenuItemStyles();
    var selected = isOpen(path, location.pathname, item);
    return useObserver(function () { return (React.createElement(ListItem, { button: true, to: path, component: NavLink, className: clsx(classes.nested, classesMenu.select), selected: selected },
        React.createElement(ListItemText, null, title))); });
};
export var CollapseListItem = withRouter(CollapseListItemComponent);
