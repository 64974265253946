var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
export var useContainerDimensions = function (myRef) {
    var getDimensions = function () { return ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
    }); };
    var _a = __read(React.useState({ width: 0, height: 0 }), 2), dimensions = _a[0], setDimensions = _a[1];
    React.useEffect(function () {
        var handleResize = function () {
            setDimensions(getDimensions());
        };
        if (myRef.current) {
            setDimensions(getDimensions());
        }
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef]);
    return dimensions;
};
