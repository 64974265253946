var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { onPreventEvent } from './Dialog';
import { Grid, IconButton, Menu, useMediaQuery, useTheme } from '@material-ui/core';
import { MoreIcon } from './icons';
import ReactDOM from 'react-dom';
import { theme } from '../theme';
import { TextOverflow } from './TextOverflow';
import BaseTooltip from './BaseTooltip';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import OrderRouteMenuItem from './Order/List/OrderRoutesMenuItem';
var timeout;
export default function PopUpHover(props) {
    var _a = __read(React.useState(null), 2), anchorElOnEnter = _a[0], setAnchorElOnEnter = _a[1];
    var _b = __read(React.useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = __read(React.useState(false), 2), opened = _c[0], setOpened = _c[1];
    var show = props.show, style = props.style;
    var langApp = useTranslation(KEYS.APP);
    React.useEffect(function () {
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    });
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        setOpened(true);
        if (props.onMouseEnter)
            props.onMouseEnter();
        if (!event.target.files)
            return onPreventEvent(event);
    };
    var handleClose = function (event) {
        setAnchorEl(null);
        setAnchorElOnEnter(null);
        return onPreventEvent(event);
    };
    var onMouseEnter = function (e) {
        setAnchorElOnEnter(e.target);
        handleClick(e);
        if (props.onMouseEnter)
            props.onMouseEnter(e);
    };
    var onMouseEnterV1 = function (e) {
        if (timeout)
            clearTimeout(timeout);
    };
    var onMouseLeave = function (e) {
        if (anchorElOnEnter) {
            handleClose(e);
        }
    };
    var onMouseLeaveTemp = function (e) {
        timeout = setTimeout(function () { return handleClose(new Event('click')); }, 100);
    };
    var open = Boolean(anchorEl);
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var _icon = (React.createElement(IconButton, { "aria-controls": "order-actions", "aria-haspopup": "true", onMouseEnter: onMouseEnter, onClick: handleClick, disabled: !show, style: __assign(__assign({ padding: 4, position: 'absolute' }, style), (!show ? { opacity: '0.7' } : null)) }, props.icon || React.createElement(MoreIcon, { style: { width: 22, height: 22 } })));
    if (props.v1 || props.v2) {
        _icon = (React.createElement(BaseTooltip, { title: props.title },
            React.createElement(IconButton, { onClick: handleClick, disabled: !show }, props.icon || React.createElement(MoreIcon, { style: { width: 22, height: 22 } }))));
        if (props.v2) {
            _icon = (React.createElement(OrderRouteMenuItem, __assign({}, { style: { margin: '0px 12px' }, icon: _icon, label: props.title, handleClickOpen: handleClick })));
        }
    }
    return (React.createElement("div", { style: __assign({ position: 'relative', width: 22 }, props.parentStyle), className: props.parentClass, onMouseLeave: onMouseLeave },
        React.createElement("span", { style: __assign({ position: 'relative', width: 22, height: 22, display: 'flex', alignItems: 'center', justifyContent: 'center' }, props.parentSpanStyle) }, _icon),
        show && (React.createElement(React.Fragment, null,
            open &&
                ReactDOM.createPortal(React.createElement("div", { "data-id": 'izi-menu-popup-fixed', onMouseLeave: onMouseLeaveTemp, style: {
                        width: 28,
                        height: 40,
                        zIndex: 9999,
                        position: 'fixed',
                        left: (anchorElOnEnter && anchorElOnEnter.getBoundingClientRect().left) || 0,
                        top: (anchorElOnEnter && anchorElOnEnter.getBoundingClientRect().top) || 0,
                    } }), document.body),
            React.createElement(Menu, { id: "menu-actions", MenuListProps: {
                    onMouseLeave: onMouseLeave,
                    onMouseEnter: onMouseEnterV1,
                }, disableScrollLock: true, anchorEl: anchorEl, keepMounted: true, open: open, onClose: handleClose, getContentAnchorEl: null, style: __assign({}, props.MenuStyles), anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, PaperProps: {
                    style: {
                        maxHeight: props.maxHeight || "calc(100vh - 90px)",
                    },
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } },
                React.createElement(Grid, { container: true, style: __assign(__assign({ padding: theme.spacing(0, 1) }, (props.maxWidth && { maxWidth: !isSmall ? props.maxWidth : '100vw' })), (props.minWidth
                        ? { width: !isSmall ? props.minWidth : '100vw' }
                        : { minWidth: !isSmall ? 320 : props.mobileMinWidth || '100vw' })) },
                    (props.title &&
                        (typeof props.title === 'string' ? (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
                            React.createElement(TextOverflow, { variant: 'h6', style: { maxWidth: 'calc(100% - 48px)' } }, props.title),
                            React.createElement(BaseTooltip, { title: langApp.t('modal_actions_close') },
                                React.createElement(IconButton, { "aria-label": "close", onClick: handleClose },
                                    React.createElement(CloseIcon, null))))) : typeof props.title === 'function' ? (props.title({ handleClose: handleClose })) : (props.title))) ||
                        null,
                    props.content))))));
}
