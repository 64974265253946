import { applySnapshot, types } from 'mobx-state-tree';
export var OrderQuoteRequestChange = types
    .model('OrderQuoteRequestChange', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    comment: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
