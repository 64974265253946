var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TextOverflow } from '../../TextOverflow';
import { theme } from '../../../theme';
import { HelpIconComponent } from '../../HelpIcon';
export default function BaseListTitle(_a) {
    var title = _a.title, style = _a.style, helper = _a.helper, props = __rest(_a, ["title", "style", "helper"]);
    return (React.createElement(React.Fragment, null,
        (typeof title === 'string' && (React.createElement(TextOverflow, { variant: 'h6', style: __assign(__assign(__assign({ fontWeight: 700, margin: 0, width: 'initial', color: theme.palette.primary.main }, (props.v2 && { lineHeight: 1, whiteSpace: 'normal' })), (helper && { maxWidth: 'calc(100% - 25px)' })), style) }, props.v2 ? title : title.toUpperCase()))) ||
            title ||
            null,
        helper && React.createElement(HelpIconComponent, { title: React.createElement("div", { dangerouslySetInnerHTML: { __html: helper } }) })));
}
