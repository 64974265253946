import { getQueryParams } from '../utils';
import { browserStorage } from "../browserStorage";
var availableLangs = ['en', 'lt', 'ru'];
var defaultLang = getQueryParams(location.href).lang;
var systemLang = navigator.language && availableLangs.find(function (e) { return e === (navigator.language.split('-')[0] || '').toLowerCase(); });
export var langOptions = {
    availableLangs: availableLangs,
    debug: false,
    lng: (defaultLang && availableLangs.includes(defaultLang) && defaultLang) ||
        browserStorage.getItem('lang') ||
        systemLang ||
        'en',
    fallbacking: 'en',
};
