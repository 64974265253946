import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const TeamPage = React.lazy(() => import('@izi-logistics/common/lib/pages/TeamPage'));

const Team = ({ store }: any) => (
  <PreloadingComponent>
    <TeamPage store={store} client={client} />
  </PreloadingComponent>
);
export default Team;
