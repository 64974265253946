import { types, flow } from "mobx-state-tree";
import { client } from "../api/apolloClient";
import { AsyncActionStatus } from "@izi-logistics/common/lib/stores";
import { OrderLocation, IZILocation } from "@izi-logistics/common/lib/models";
import {
  CreateLocation,
  FetchLocations,
} from "@izi-logistics/common/lib/graphql";

export const LocationsCollectionStore = types
  .compose(
    AsyncActionStatus,
    types.model("LocationsCollectionStore", {
      list: types.array(OrderLocation),
    })
  )
  .actions((self) => ({
    createLocation: flow(function* (input: IZILocation) {
      const result = yield client.mutate({
        mutation: CreateLocation,
        variables: {
          input,
        },
      });
      const data = client.readQuery({ query: FetchLocations });
      client.writeQuery({
        //Update Apollo Cache
        query: FetchLocations,
        data: {
          locations: {
            count: data.locations.count + 1,
            data: [result.data.createLocation, ...data.locations.data],
          },
        },
      });
      return result.data.createLocation;
    }),
  }))
  .named("LocationsCollectionStore");
