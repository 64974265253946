var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { TextOverflow } from '../TextOverflow';
import { theme } from '../../theme';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
export default function BasicBreadcrumbs(_a) {
    var list = _a.list, style = _a.style;
    return (React.createElement(Breadcrumbs, { maxItems: 4, "aria-label": "breadcrumb", separator: React.createElement(ChevronRightIcon, null), style: __assign({ padding: theme.spacing(1, 2) }, style) }, list.map(function (e) {
        return e.path ? (React.createElement(Link, { color: "primary", to: e.path, style: { textDecoration: 'none' } }, e.title)) : (React.createElement(TextOverflow, null, e.title));
    })));
}
