var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Grid, IconButton, useTheme } from '@material-ui/core';
import { DeleteDialog, onPreventEvent } from '../../../components/Dialog';
import React from 'react';
import ApprovePayment from './ApprovePayment';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { InvoiceCategory, InvoiceStatus } from '../../../models/Invoices';
import { Link } from 'react-router-dom';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EditIcon from '@material-ui/icons/Edit';
import { ACTION_CHANGE } from '../../../components/BasicList';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject } from 'mobx-react';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import BaseTooltip from '../../../components/BaseTooltip';
import MenuListItem from '../../../components/MenuListItem';
import { OrderPaymentStatus } from '../../../models/OrderPayment';
import { BaseUserAppTYpe } from '../../../stores/common';
import OrderRouteMenuItem from '../../../components/Order/List/OrderRoutesMenuItem';
export default inject('store')(function (_a) {
    var _b;
    var onChange = _a.onChange, config = _a.config, hideEdit = _a.hideEdit, record = _a.record, isCost = _a.isCost, _c = _a.store, invoiceStore = _c.invoiceStore, _d = _c.accountStore, currentUser = _d.currentUser, currentCompany = _d.currentCompany;
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var theme = useTheme();
    var onDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, invoiceStore.deleteItem(record._id)];
                case 1:
                    _a.sent();
                    if (onChange)
                        onChange(record, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var canEdit = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var isOwn = currentCompany._id === invoiceStore.item.createdBy.company._id;
    var isOwnV1 = record.createdBy && record.createdBy._id === currentUser._id;
    var iconStyle = { padding: 2 };
    var section = ((_b = window.location.pathname.split('/')[2]) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || '';
    var isCosts = section === 'costs';
    var editItem = (React.createElement(BaseTooltip, { title: (record.sentAt ? langOfferForm.t('offer_overview') : langForm.t('form_btn_edit')) },
        React.createElement(IconButton, { color: 'primary', id: 'edit-btn', style: iconStyle, disabled: !(isOwnV1 || canEdit) },
            React.createElement(EditIcon, null))));
    var disabledRefund = !isOwn && isCost && record.category === InvoiceCategory.INVOICE && !(record.parent && record.parent._id);
    var disabledRefundState = disabledRefund || record.status === InvoiceStatus.DRAFT;
    var refundIcon = (React.createElement(BaseTooltip, { title: langForm.t(record.parent && record.parent._id ? 'form_invoice_view_request_refund' : 'form_invoice_request_refund') },
        React.createElement("span", null,
            React.createElement(IconButton, { color: ((!!(record.parent && record.parent._id) || disabledRefundState) && 'secondary') || 'primary', id: 'refund-btn', style: iconStyle, disabled: disabledRefund },
                React.createElement(AccountBalanceWalletIcon, null)))));
    var disabledCreditIcon = !isOwn && !isCost && record.refunds.length;
    var disabledIconState = disabledCreditIcon || record.status === InvoiceStatus.DRAFT;
    var disabledIcon = (React.createElement(BaseTooltip, { title: langForm.t('form_invoice_create_credit') },
        React.createElement(IconButton, { color: disabledIconState ? 'secondary' : 'primary', id: 'credit-btn', style: iconStyle, disabled: disabledCreditIcon },
            React.createElement(CardMembershipIcon, null))));
    var isCarrier = !isCost ? !isCost : currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    return (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginLeft: theme.spacing(1) }, onClick: onPreventEvent },
        React.createElement(MenuListItem, { show: true, list: [
                {
                    content: (React.createElement(Link, { to: "/finance/invoices/" + record._id + "/credit", style: { textDecoration: 'none', width: '100%' } },
                        React.createElement(OrderRouteMenuItem, { icon: disabledIcon, label: langForm.t(record.parent && record.parent._id
                                ? 'form_invoice_view_request_refund'
                                : 'form_invoice_request_refund') }))),
                    available: !isCost && !disabledIconState,
                },
                {
                    content: (React.createElement(Link, { to: "/finance/costs/" + record._id + "/request-refund", style: { textDecoration: 'none', width: '100%' } },
                        React.createElement(OrderRouteMenuItem, { icon: refundIcon, label: langForm.t(record.parent && record.parent._id
                                ? 'form_invoice_view_request_refund'
                                : 'form_invoice_request_refund') }))),
                    available: isCost && !disabledRefundState,
                },
                {
                    content: (React.createElement(ApprovePayment, { isCost: isCost, record: record, config: config, onChange: onChange, disabled: !(InvoiceCategory.INVOICE === record.category && !(record.parent && record.parent._id)) ||
                            record.status === InvoiceStatus.DRAFT, label: langOrderList.t('in_progress_payment_title') })),
                    available: !(record.status === OrderPaymentStatus.PAID &&
                        ((!isCarrier && record.releasedAt) || (isCarrier && record.approvedAt))) &&
                        !(!(InvoiceCategory.INVOICE === record.category && !(record.parent && record.parent._id)) ||
                            record.status === InvoiceStatus.DRAFT),
                },
                {
                    content: (React.createElement(Link, { to: "/finance/" + section + "/" + record._id, style: { textDecoration: 'none', width: '100%' } },
                        React.createElement(OrderRouteMenuItem, { icon: editItem, label: (record.sentAt || (isCosts && record.status === InvoiceStatus.DRAFT && !isOwnV1)
                                ? langOfferForm.t('offer_overview')
                                : langForm.t('form_btn_edit')), style: { display: 'flex', justifyContent: 'space-between' } }))),
                    available: !hideEdit,
                },
                {
                    content: (React.createElement(DeleteDialog, { onOk: onDelete, withText: true, withoutWrapper: true, value: langApp.t("main_" + section.substr(0, section.length - 1)) })),
                    available: isOwnV1 || canEdit,
                },
            ] })));
});
