var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { USER_BASE, USER_WITH_COMPANY_BASE } from './user';
import { LANGUAGE_BASE } from './langs/base';
import { USER_COMPANY_BASE } from './base';
import { ORDER_PREPATING_STATUS } from './order/types.v2';
export var COMPANY_ADDITIONAL_INFO = "\n  companyAdditionalInfo { \n    isInsuranceExists\n    canP44Sync\n    isWarehouseExists\n    joinRequest {\n      " + USER_COMPANY_BASE + "\n    }\n  }\n";
export var RELATED_APP_URLS = "\n  relatedAppUrls { \n    customerUrl\n  }\n";
export var LOGIN_INFO = "\n  izi_client_id\n  user {\n    " + USER_WITH_COMPANY_BASE + "\n    passwordUpdatedAt\n  }\n  companyAdditionalInfo {\n    canP44Sync\n    isInsuranceExists\n    isWarehouseExists\n    joinRequest {\n      " + USER_COMPANY_BASE + "\n    }\n  }\n    inviteLink\n      inviteCode\n  token\n  " + COMPANY_ADDITIONAL_INFO + "\n  " + RELATED_APP_URLS + "\n";
export var SignUp = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation registerUser($input: CompanyUserInput) {\n    registerUser(input: $input) {\n     ", "\n    }\n  }\n"], ["\n  mutation registerUser($input: CompanyUserInput) {\n    registerUser(input: $input) {\n     ", "\n    }\n  }\n"])), USER_BASE);
export var RequestAccessLink = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation requestAccess($input: UserAuthInputAccess) {\n    requestAccess(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation requestAccess($input: UserAuthInputAccess) {\n    requestAccess(input: $input) {\n      status\n    }\n  }\n"])));
export var LogIn = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation LogIn($input: UserAuthInput) {\n    LogIn(input: $input) {\n      ", "\n      env{\n        name\n        value\n      }\n    }\n  }\n"], ["\n  mutation LogIn($input: UserAuthInput) {\n    LogIn(input: $input) {\n      ", "\n      env{\n        name\n        value\n      }\n    }\n  }\n"])), LOGIN_INFO);
export var LogInAs = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation logInAs($input: UserAuthInput) {\n    logInAs(input: $input) {\n    izi_client_id\n      user {\n        ", "\n      }\n      token\n      appLink\n    }\n  }\n"], ["\n  mutation logInAs($input: UserAuthInput) {\n    logInAs(input: $input) {\n    izi_client_id\n      user {\n        ", "\n      }\n      token\n      appLink\n    }\n  }\n"])), USER_WITH_COMPANY_BASE);
export var ForgotPassword = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation forgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      status\n    }\n  }\n"], ["\n  mutation forgotPassword($email: String!) {\n    forgotPassword(email: $email) {\n      status\n    }\n  }\n"])));
export var ChangePasswordWithToken = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation ChangePasswordWithToken($newPassword: String!, $token: String!, $isAutoLogin: Int) {\n    ChangePasswordWithToken(newPassword: $newPassword, token: $token, isAutoLogin: $isAutoLogin) {\n      status\n      token\n    }\n  }\n"], ["\n  mutation ChangePasswordWithToken($newPassword: String!, $token: String!, $isAutoLogin: Int) {\n    ChangePasswordWithToken(newPassword: $newPassword, token: $token, isAutoLogin: $isAutoLogin) {\n      status\n      token\n    }\n  }\n"])));
export var CheckIfLoggedIn = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation CheckIfLoggedIn($filter: MainFilter) {\n    CheckIfLoggedIn(filter:$filter) {\n      API_VERSION\n      env{\n        name\n        value\n      }\n      izi_client_id\n      error\n      preparingStatuses{\n        ", "\n      }\n      tempUser{\n          email\n          phone\n          appType\n      }\n      user{\n        ", "\n        passwordUpdatedAt\n        affiliateUrl\n      }\n      booking_token\n      inviteLink\n      inviteCode\n      keys\n      langs{\n        ", "\n      }\n      ", "\n      ", "\n    }\n  }\n"], ["\n  mutation CheckIfLoggedIn($filter: MainFilter) {\n    CheckIfLoggedIn(filter:$filter) {\n      API_VERSION\n      env{\n        name\n        value\n      }\n      izi_client_id\n      error\n      preparingStatuses{\n        ", "\n      }\n      tempUser{\n          email\n          phone\n          appType\n      }\n      user{\n        ", "\n        passwordUpdatedAt\n        affiliateUrl\n      }\n      booking_token\n      inviteLink\n      inviteCode\n      keys\n      langs{\n        ", "\n      }\n      ", "\n      ", "\n    }\n  }\n"])), ORDER_PREPATING_STATUS, USER_WITH_COMPANY_BASE, LANGUAGE_BASE, COMPANY_ADDITIONAL_INFO, RELATED_APP_URLS);
export var LogOut = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  mutation LogOut($input: LogoutInput) {\n    LogOut(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation LogOut($input: LogoutInput) {\n    LogOut(input: $input) {\n      status\n    }\n  }\n"])));
export var VerifyUser = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation verifyUser($token: String!) {\n    verifyUser\n  }\n"], ["\n  mutation verifyUser($token: String!) {\n    verifyUser\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
