import { applySnapshot, types } from 'mobx-state-tree';
export var OrderQuoteTransport = types
    .model('OrderQuoteTransport', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    track: types.maybeNull(types.optional(types.string, '')),
    trailer: types.maybeNull(types.optional(types.string, '')),
    contactName: types.maybeNull(types.optional(types.string, '')),
    phone: types.maybeNull(types.optional(types.string, '')),
    container: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
