var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import * as material from '@material-ui/core';
import { createStyles, IconButton, Grid } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import InputTitle from './InputTitle';
import { makeStyles } from '@material-ui/core/styles';
import GradeIcon from '@material-ui/icons/Grade';
import BaseTooltip from '../BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useAvatarStyles } from '../Account/Avatar';
import { theme } from '../..';
var Box = material.Box;
var useStyles = makeStyles(function (theme) {
    return createStyles({
        selected: {
            color: theme.palette.success.light,
        },
    });
});
export var FormikRateField = React.forwardRef(function (props, ref) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title, disabled = props.disabled;
    var classAvatar = useAvatarStyles();
    var _e = __read(React.useState(-1), 2), tempIndex = _e[0], setTempIndex = _e[1];
    var langForm = useTranslation(KEYS.FORM);
    var className = useStyles();
    var _f = __read(useField(props), 3), field = _f[0], meta = _f[1], helpers = _f[2];
    var onMouseEnter = function (e, index) {
        setTempIndex(index);
    };
    var onClick = function (e, index) {
        helpers.setValue(index);
        if (onChange)
            onChange({ target: { name: props.name, value: index } }, e);
    };
    var onMouseLeave = function (e) {
        setTempIndex(-1);
    };
    return useObserver(function () { return (React.createElement(Grid, { container: true, alignItems: 'center' },
        React.createElement(Box, { width: '100%', display: 'flex', alignItems: "center", justifyContent: "center", style: { gap: theme.spacing(1) } }, Array.from({ length: 5 }).map(function (e, indx) {
            var _a;
            var index = indx + 1;
            var _rating = (tempIndex > 0 ? tempIndex : field.value) || -1;
            return (React.createElement(BaseTooltip, { title: langForm.t("form_btn_rating_" + index) },
                React.createElement(IconButton, { disabled: disabled, key: index, onMouseEnter: function (e) { return onMouseEnter(e, index); }, onMouseLeave: onMouseLeave, onClick: function (e) { return onClick(e, index); } },
                    React.createElement(GradeIcon, { className: clsx((_a = {},
                            // [className.selected]: _rating >= index,
                            _a[classAvatar.awfull] = _rating >= index && _rating === 1,
                            _a[classAvatar.ok] = _rating >= index && _rating === 2,
                            _a[classAvatar.good] = _rating >= index && _rating === 3,
                            _a[classAvatar.great] = _rating >= index && _rating === 4,
                            _a[classAvatar.awesome] = _rating >= index && _rating === 5,
                            _a)), style: { transform: 'scale(1.2)' } }))));
        })),
        React.createElement(Grid, { item: true, xs: 12, md: 8 },
            React.createElement(InputTitle, __assign({}, props))))); });
});
