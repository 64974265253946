var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import GoogleMapAutocomplete from '../GoogleMapAutocomplete';
import InputTitle from './InputTitle';
export var FormikGoogleTextField = function (props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title, inputProps = __rest(props, ["helperText", "onChange", "margin", "fullWidth", "variant", "title"]);
    var _e = __read(useField(props), 3), field = _e[0], meta = _e[1], helpers = _e[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (value) {
        helpers.setValue(value);
        if (onChange)
            onChange({ target: __assign(__assign({}, field), { value: value }) });
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(GoogleMapAutocomplete, { id: props.id || (props.title || 'text-field-input').toString().replace(/ /gi, '').toLowerCase(), inputProps: inputProps, title: title, onChange: _onChange, field: field, touchedError: touchedError, helperText: helperText }))); });
};
