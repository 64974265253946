var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { MainFilterSortDir } from '../../../BasicList';
import { GlobalSocketComponent } from '../../index';
import { CreateOrderChatMessage, FetchOrderChatMessages, ReadOrderChatMessage } from '../../../../graphql/chat/message';
import { FetchChat } from '../../../../graphql/chat';
import { SOCKET_CHAT_CUSTOM_EVENT_NAME } from '../../../../pages/Chat/stores/constants';
import browserHistory from '../../../../browserHistory';
import { globalUtils } from '../../../../utils';
import { SOUND_KEY } from '../../../Sounds';
import { withBaseGlobalSocketStoreActiveClient } from '../../global/store';
import { ACTIONS, } from './store.types';
import { toast } from 'react-toastify';
var initialState = {
    item: null,
    updating: false,
    hide: false,
    loadingHistory: false,
    loading: false,
    loaded: false,
    error: null,
    search: '',
    count: 0,
    delayStartTyping: 0,
    delayTimeout: null,
    data: [],
    searchFields: [],
    filter: {
        fields: [],
        sortBy: 'createdAt',
        sortDir: MainFilterSortDir.DESC,
        limit: 25,
        page: 1,
        skip: 0,
    },
};
var SidebarChannelChatStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = SidebarChannelChatStore.Provider, Consumer = SidebarChannelChatStore.Consumer;
export var SidebarChannelChatProvider = function (_a) {
    var children = _a.children, client = _a.client, socketClient = _a.socketClient, defaultValue = _a.defaultValue;
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), JSON.parse(JSON.stringify(defaultValue)));
    var _b = __read(useReducer(function (state, action) {
        var _a, _b, _c, _d;
        switch (action.type) {
            case ACTIONS.CHANGE_DATA: {
                return __assign(__assign({}, state), action.payload);
            }
            case ACTIONS.START_LOAD_DATA: {
                return __assign(__assign({}, state), { error: null, loading: true });
            }
            case ACTIONS.START_LOAD_HISTORY_DATA: {
                return __assign(__assign({}, state), { error: null, loadingHistory: true });
            }
            case ACTIONS.CREATE_MESSAGE: {
                var prevUser = __spread([(_a = state.item) === null || _a === void 0 ? void 0 : _a.createdBy], (((_b = state.item) === null || _b === void 0 ? void 0 : _b.users) || [])).find(function (e) { return (e === null || e === void 0 ? void 0 : e._id) === action.payload.item.createdBy._id; });
                if (prevUser) {
                    Object.assign(action.payload.item.createdBy, prevUser);
                }
                return __assign(__assign({}, state), { data: __spread(state.data, [action.payload.item]), count: state.count + 1, error: null, updating: true });
            }
            case ACTIONS.LOAD_DATA_ERROR:
            case ACTIONS.FINISH_LOAD_DATA: {
                return __assign(__assign(__assign({}, state), action.payload), { loadingHistory: false, loading: false, updating: false });
            }
            case ACTIONS.FINISH_LOAD_ALL_DATA: {
                var exist_1 = state.data.map(function (e) { return e._id; });
                return __assign(__assign(__assign({}, state), action.payload), { data: __spread(action.payload.data.filter(function (e) { return !exist_1.includes(e._id); }), state.data), loadingHistory: false });
            }
            case ACTIONS.FINISH_CREATE_MESSAGE: {
                var isChatChannel = state.item && action.payload.item.chat._id === state.item._id;
                if (isChatChannel && !state.hide) {
                    client.mutate({
                        mutation: ReadOrderChatMessage,
                        variables: {
                            _id: action.payload.item._id,
                        },
                    });
                }
                var prevUser = __spread([(_c = state.item) === null || _c === void 0 ? void 0 : _c.createdBy], (((_d = state.item) === null || _d === void 0 ? void 0 : _d.users) || [])).find(function (e) { return (e === null || e === void 0 ? void 0 : e._id) === action.payload.item.createdBy._id; });
                if (prevUser) {
                    Object.assign(action.payload.item.createdBy, prevUser);
                }
                return __assign(__assign(__assign({}, state), (isChatChannel && {
                    data: __spread(state.data.filter(function (e) { return !e.isTemp; }), [action.payload.item]),
                    count: state.count,
                })), { loadingHistory: false, loading: false, updating: false });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var onToggleShow = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch({
                type: ACTIONS.CHANGE_DATA,
                payload: {
                    hide: !state.hide,
                },
            });
            return [2 /*return*/];
        });
    }); };
    var loadOne = function (_id) { return __awaiter(void 0, void 0, void 0, function () {
        var result, item, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: ACTIONS.START_LOAD_DATA,
                    });
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: FetchChat,
                            variables: {
                                _id: _id,
                            },
                        })];
                case 1:
                    result = _a.sent();
                    item = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                    if (!item)
                        throw 'No chat';
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign({
                            loaded: true,
                            item: item,
                        }),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    toast.error(e_1.message || e_1);
                    console.log(e_1);
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_1.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var loadData = function (filter) { return __awaiter(void 0, void 0, void 0, function () {
        var result, chat, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: ACTIONS.START_LOAD_HISTORY_DATA,
                    });
                    return [4 /*yield*/, client.query({
                            //TODO: somtin stupid happening here
                            fetchPolicy: 'no-cache',
                            query: FetchOrderChatMessages,
                            variables: {
                                filter: __assign(__assign({}, state.filter), filter),
                            },
                        })];
                case 1:
                    result = _a.sent();
                    chat = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_ALL_DATA,
                        payload: Object.assign(__assign({ loaded: true, data: chat.data.reverse() }, chat)),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_2.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var createOrderChatMessage = function (input, opt) { return __awaiter(void 0, void 0, void 0, function () {
        var result, item, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: ACTIONS.CREATE_MESSAGE,
                        payload: Object.assign({
                            item: __assign(__assign({}, input), { isTemp: true, createdBy: opt.createdBy, _id: 'temp', createdAt: Date.now() }),
                        }),
                    });
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: CreateOrderChatMessage,
                            variables: {
                                input: input,
                            },
                        })];
                case 1:
                    result = _a.sent();
                    item = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                    dispatch({
                        type: ACTIONS.FINISH_CREATE_MESSAGE,
                        payload: Object.assign({
                            loaded: true,
                            hide: true,
                            item: item,
                        }),
                    });
                    return [2 /*return*/, item];
                case 2:
                    e_3 = _a.sent();
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_3.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onChangeFilter = function (filter) {
        var updateFilter = __assign(__assign({}, state.filter), filter);
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: {
                filter: updateFilter,
            },
        });
        loadData(updateFilter);
    };
    var onChange = function (item) {
        dispatch({
            type: ACTIONS.FINISH_LOAD_DATA,
            payload: {
                item: __assign(__assign({}, state.item), item),
            },
        });
    };
    var events = socketClient
        ? [
            {
                client: socketClient,
                name: 'chat',
                callback: function (data) {
                    switch (data.event) {
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_LEAVE:
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_REMOVE: {
                            if (state.item && state.item._id === data.chat._id) {
                                browserHistory.goBack();
                            }
                            break;
                        }
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_CREATE_MESSAGE: {
                            dispatch({
                                type: ACTIONS.FINISH_CREATE_MESSAGE,
                                payload: Object.assign({
                                    loaded: true,
                                    item: data.data,
                                }),
                            });
                            globalUtils.sounds.play(SOUND_KEY.NEW_MESSAGE);
                            break;
                        }
                    }
                },
            },
            {
                client: socketClient,
                name: 'channel',
                callback: function (data) {
                    switch (data.event) {
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_FINISH_TYPING: {
                            if (state.delayTimeout)
                                clearTimeout(state.delayTimeout);
                            dispatch({
                                type: ACTIONS.CHANGE_DATA,
                                payload: {
                                    delayStartTyping: 0,
                                    delayTimeout: null,
                                },
                            });
                            break;
                        }
                    }
                },
            },
        ]
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('*******************storeSidebarChannel.tsx:Channel chat no client');
        }
        else {
            console.log('=======storeSidebarChannel.tsx:Channel chat notifications listen');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('*******************storeSidebarChannel.tsx. Channel chat un listen');
        }
        else {
            console.log('=======storeSidebarChannel.tsx:Channel chat notifications un listen');
        }
    };
    var typing = function (data) {
        if (state.delayStartTyping) {
            if (state.delayTimeout)
                clearTimeout(state.delayTimeout);
            var delayTimeout = setTimeout(function () {
                socketClient === null || socketClient === void 0 ? void 0 : socketClient.emit(SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_TYPING, data);
            }, state.delayStartTyping);
            dispatch({
                type: ACTIONS.CHANGE_DATA,
                payload: {
                    delayTimeout: delayTimeout,
                },
            });
        }
        else {
            socketClient === null || socketClient === void 0 ? void 0 : socketClient.emit(SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_TYPING, data);
            dispatch({
                type: ACTIONS.CHANGE_DATA,
                payload: {
                    delayStartTyping: 2000,
                },
            });
        }
    };
    var value = {
        state: state,
        onToggleShow: onToggleShow,
        listen: listen,
        loadOne: loadOne,
        typing: typing,
        unListen: unListen,
        onChange: onChange,
        loadData: loadData,
        createOrderChatMessage: createOrderChatMessage,
        onChangeFilter: onChangeFilter,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withSidebarChannelChatStore = function (Component) {
    return function (props) {
        return (React.createElement(Consumer, { key: props.socketClient ? 1 : -1 }, function (_a) {
            var listen = _a.listen, unListen = _a.unListen, chatStore = __rest(_a, ["listen", "unListen"]);
            return (React.createElement(Component, __assign({}, __assign(__assign({}, props), { listen: listen, unListen: unListen }), { sidebarChannelChat: chatStore })));
        }));
    };
};
var SidebarChannelChatComponent = withSidebarChannelChatStore(GlobalSocketComponent);
export var GlobalSidebarChannelChatSocket = withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    var content = React.createElement(SidebarChannelChatComponent, __assign({}, props));
    return (React.createElement(SidebarChannelChatProvider, __assign({ client: client }, p, { socketClient: p.globalSocketState.client }), content));
});
