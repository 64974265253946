var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Typography, Grid, useMediaQuery, useTheme, Box } from '@material-ui/core';
import { WholePageFormFooterNavLink } from './WholePageFormFooterNavLink';
import { WholePageFormHelperNavLink } from './WholePageFormHelperNavLink';
import { SubmitButtonWithLoading } from '../SubmitButtonWithLoading';
import { useStyles, useStylesMain } from './styles';
import { Form } from 'formik';
import { SuccessMessage } from '../SuccessMessage';
import { TextOverflow } from '../TextOverflow';
import { MainLogoIcon } from '../icons';
import { theme } from '../../theme';
import Recaptcha from '../Recaptcha';
import AffiliateBackgorundImg from '-!svg-react-loader!./../assets/AffiliateBackgorundImg.svg';
export var WholePageFormLayout = function (_a) {
    var error = _a.error, wholePageFormBackButton = _a.wholePageFormBackButton, children = _a.children, _b = _a.noApps, noApps = _b === void 0 ? false : _b, _c = _a.noRegister, noRegister = _c === void 0 ? false : _c, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, _e = _a.wholePageFormBackButtonVisible, wholePageFormBackButtonVisible = _e === void 0 ? true : _e, _f = _a.hideRecapture, hideRecapture = _f === void 0 ? false : _f, wholePageFormTitle = _a.wholePageFormTitle, submitButtonWithLoading = _a.submitButtonWithLoading, wholePageFormHelperNavLink = _a.wholePageFormHelperNavLink, wholePageFormHelperNavLinkVisible = _a.wholePageFormHelperNavLinkVisible, wholePageFormFooterNavLinkVisible = _a.wholePageFormFooterNavLinkVisible, _g = _a.wholePageFormFooterNavLink, wholePageFormFooterNavLink = _g === void 0 ? {} : _g, successfulRequest = _a.successfulRequest, message = _a.message, subText = _a.subText, buttonText = _a.buttonText, path = _a.path, v1 = _a.v1, v2 = _a.v2, v3 = _a.v3;
    var _h = __read(React.useState(false), 2), checked = _h[0], setChecked = _h[1];
    var classes = useStyles();
    var classesMain = useStylesMain();
    var onSuccessRecaptcha = function () { return setChecked(true); };
    var theme1 = useTheme();
    var isNotSmall = useMediaQuery(theme1.breakpoints.up('sm'));
    var isNotMiddle = useMediaQuery(theme1.breakpoints.up('md'));
    var isNotLarge = useMediaQuery(theme1.breakpoints.up('lg'));
    var isRegisterView = v1 && isNotSmall;
    return (React.createElement(Grid, { container: true, direction: isRegisterView ? 'row' : 'column', justify: 'center', className: classesMain.root, alignItems: 'center', style: __assign({}, (isRegisterView ? { maxWidth: 'calc(100% - 260px)' } : null)) },
        process.env.REACT_APP_NONE_BRAND ? null : (React.createElement("a", { href: "https://izi-logistics.com", style: { textDecoration: 'none' }, target: "_blank" },
            React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', style: __assign(__assign({ marginBottom: theme.spacing(4) }, (isRegisterView && {
                    maxWidth: '84px',
                    transform: 'rotate(-180deg) scale(1.5)',
                    marginBottom: 0,
                    maxHeight: 380,
                    marginRight: theme.spacing(8),
                    writingMode: 'vertical-rl',
                    textOrientation: 'mixed',
                })), (v2 && { maxWidth: isNotLarge ? '600px' : '450px', transform: undefined })) }, v2 ? (isNotMiddle ? (React.createElement(Box, { style: { paddingTop: 200, width: 'inherit' } },
                React.createElement(AffiliateBackgorundImg, { style: { width: 'inherit' } }))) : null) : (React.createElement(React.Fragment, null,
                React.createElement(MainLogoIcon, { style: __assign({ width: 84, height: 84 }, (isRegisterView && { transform: 'rotate(-90deg)' })) }),
                React.createElement(Typography, { variant: 'h3', style: __assign({ color: theme.palette.common.white, fontWeight: 900 }, (isRegisterView ? { marginTop: theme.spacing(2) } : { marginLeft: theme.spacing(2) })) }, document.title.toLowerCase())))))),
        React.createElement("div", { className: classes.paper, style: __assign({}, (isRegisterView && { maxWidth: v2 ? '350px' : "calc(100% - 300px)" })) },
            wholePageFormTitle && wholePageFormTitle.text && (React.createElement(TextOverflow, { v1: true, variant: 'h4', style: __assign({ textAlign: 'left', fontWeight: 700, color: 'black', marginBottom: theme.spacing(2) }, wholePageFormTitle.style) }, wholePageFormTitle.text)),
            successfulRequest ? (React.createElement(SuccessMessage, { message: message, subText: subText, buttonText: buttonText, path: path })) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { color: "error", id: "errorMessage" }, error),
                React.createElement(Form, __assign({ className: classes.form }, { id: (wholePageFormTitle === null || wholePageFormTitle === void 0 ? void 0 : wholePageFormTitle.text) || 'auth' }, { style: { marginTop: '0.5rem' } }),
                    children,
                    !hideRecapture ? (React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
                        React.createElement(Recaptcha, { onChange: onSuccessRecaptcha }))) : null,
                    React.createElement(SubmitButtonWithLoading, { v1: true, isLoading: isLoading, text: submitButtonWithLoading.text, id: submitButtonWithLoading.id, style: __assign({}, (v3 && { marginTop: '0.5rem' })), disabled: submitButtonWithLoading.disabled || !(checked || hideRecapture) }),
                    React.createElement(Grid, { container: true, direction: "row", alignItems: "center", justify: "flex-start", spacing: 2 }, wholePageFormFooterNavLinkVisible && React.createElement(WholePageFormFooterNavLink, __assign({}, wholePageFormFooterNavLink))),
                    React.createElement(Grid, { container: true, justify: "flex-start", className: classes.navLinkToResetPassword },
                        React.createElement(Grid, { item: true }, wholePageFormHelperNavLinkVisible && React.createElement(WholePageFormHelperNavLink, __assign({}, wholePageFormHelperNavLink))))))))));
};
