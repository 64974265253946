import { applySnapshot, types } from 'mobx-state-tree';
export var CompanyStatusV1 = types
    .model('CompanyStatus', {
    _id: types.optional(types.string, ''),
    name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
