var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { OrderCurrency } from '../models';
import { theme } from '../theme';
import { TextOverflow } from './TextOverflow';
import { i18n, KEYS } from '../i18n';
export var CurrencyFormat = function (currency, isWord, language) {
    var currencyOutPut;
    switch (currency) {
        case OrderCurrency.USD: {
            currencyOutPut = isWord ? i18n.getResource(language ? language : i18n.language, KEYS.APP, 'currency_USD') : '$';
            break;
        }
        case OrderCurrency.EUR: {
            currencyOutPut = isWord ? i18n.getResource(language ? language : i18n.language, KEYS.APP, 'currency_EUR') : '€';
            break;
        }
        default: {
            currencyOutPut = currency;
        }
    }
    return currencyOutPut;
};
export var priceValue = function (opt) {
    if (opt === void 0) { opt = {}; }
    if (!opt)
        opt = {};
    var currency = opt.currency, currencyChar = opt.currencyChar, amount = opt.amount, price = opt.price, balance = opt.balance, budget = opt.budget;
    try {
        var str = new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency || 'EUR' }).format(parseFloat((amount || price || balance || budget || 0).toString()));
        if (currencyChar) {
            str = str.split('');
            str.pop();
            str = str.join('') + (" " + currencyChar);
        }
        return str;
    }
    catch (e) {
        return [amount || price, currencyChar || currency].filter(function (e) { return e; }).join(' ');
    }
};
export var PriceComponent = function (_a) {
    var amount = _a.amount, currency = _a.currency, style = _a.style, hide = _a.hide, v1 = _a.v1, remainProps = __rest(_a, ["amount", "currency", "style", "hide", "v1"]);
    return (React.createElement(TextOverflow, __assign({ "data-id": 'price', style: __assign(__assign({}, (!v1 && { color: theme.palette.primary.main, fontSize: '1rem', fontWeight: 700 })), style) }, remainProps), hide ? '--,--' : priceValue({ amount: amount, currency: currency })));
};
