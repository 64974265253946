import { createStyles, makeStyles } from '@material-ui/core';
export var useMainStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                width: '100%',
                height: 'auto',
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(4, 10)
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(1, 2),
            },
            _a),
        section: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3, 4),
            marginBottom: theme.spacing(2),
        },
    });
});
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: '100%',
        },
        stepper: {
            backgroundColor: theme.palette.background.default,
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        emptyStep: {
            padding: theme.spacing(3),
        },
        icon: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.paper,
        },
        activeIcon: {
            borderColor: theme.palette.primary.main,
        },
        stepNumber: {
            textAlign: 'center',
        },
        activeStepNumber: {
            color: theme.palette.primary.main,
        },
        label: {
            paddingLeft: theme.spacing(2),
        },
        activeLabel: {
            color: theme.palette.primary.main,
        },
        labelText: {
            color: theme.palette.primary.main,
        },
        stepLine: {
            marginLeft: theme.spacing(2),
            padding: theme.spacing(2, 2, 2, 4),
            borderLeftStyle: 'solid',
            borderLeftWidth: '2px',
            borderLeftColor: theme.palette.secondary.main,
        },
        activeStepLine: {
            borderLeftColor: theme.palette.primary.main,
        },
    });
});
export var useStepperStyles = makeStyles(function (theme) {
    return createStyles({
        stepNav: {
            width: '100%',
            textDecoration: 'none',
            '& .step-label': {
                fontWeight: 700,
                position: 'relative',
                background: "linear-gradient(to right, " + theme.palette.primary.main + ",  " + theme.palette.primary.main + " 50%, " + theme.palette.grey[400] + " 50%)",
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                backgroundSize: '200% 100%',
                backgroundPosition: '100%',
                transition: "background-position 270ms ease",
            },
            '&:hover': {
                '& .step-label': {
                    backgroundPosition: '0 100%',
                },
            },
        },
        root: {
            width: '100%',
        },
        stepper: {
            backgroundColor: theme.palette.background.default,
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        icon: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: 4,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.paper,
        },
        iconSmall: {
            width: '20px',
            height: '20px',
            marginLeft: 7,
            borderWidth: 2,
        },
        activeIcon: {
            position: 'relative',
            borderColor: theme.palette.primary.main,
            '&::after': {
                content: "' '",
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                borderRadius: '50%',
                width: '70%',
                height: '70%',
                backgroundColor: theme.palette.primary.main,
            },
        },
        stepNumber: {
            textAlign: 'center',
        },
        activeStepNumber: {
            color: theme.palette.primary.main,
        },
        label: {
            paddingLeft: theme.spacing(2),
        },
        activeLabel: {
            color: theme.palette.primary.main,
        },
        labelText: {
            color: theme.palette.primary.main,
        },
        stepLine: {
            marginLeft: theme.spacing(3),
            padding: theme.spacing(2, 2, 2, 4),
            borderLeftStyle: 'solid',
            borderLeftWidth: '2px',
            borderLeftColor: theme.palette.secondary.main,
        },
        activeStepLine: {
            borderLeftColor: theme.palette.primary.main,
        },
    });
});
