var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { makeStyles } from "@material-ui/core";
import { AddFormButton } from "../AddFormButton";
var useStyles = makeStyles(function (theme) { return ({
    root: {
        cursor: "pointer",
        color: theme.palette.common.white,
        fontSize: theme.spacing(2),
        "& *": {
            fill: theme.palette.common.white,
        },
    },
}); });
export var PrimaryButton = function (props) {
    var classes = useStyles();
    return (React.createElement(AddFormButton, __assign({ variant: "contained", color: "primary", className: classes.root }, props), props.children));
};
