var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { BasicDialog, FilePreview } from '../Dialog';
import { inject } from 'mobx-react';
import { BaseList, BaseListItem } from '../BasicList';
import { FetchLogs } from '../../graphql/logs';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { theme } from '../../theme';
import { AvatarItemView } from '../Account/Avatar';
import { TextOverflow } from '../TextOverflow';
import { dateViewFormat } from '../DateView';
import RequestSign from '../../pages/Quotes/DocumentSendNotificationToSign';
import { browserStorage } from '../../browserStorage';
import BaseTooltip from '../BaseTooltip';
export var SignPdf = inject('store')(function (_a) {
    var item = _a.item, store = _a.store, props = __rest(_a, ["item", "store"]);
    var langApp = useTranslation(KEYS.APP);
    var onCLick = function () {
        var API_URL = ((props.config && props.config.URL) || '').replace(/graphql/, "ds/pdf-sign?token=" + browserStorage.getItem('token') + "&client_id=" + browserStorage.getItem('client_id') + "&upload=" + item._id);
        window.open(API_URL, langApp.t('sign_document'), 'location=yes,scrollbars=yes,status=yes,resizable=yes');
    };
    var actonInit = (React.createElement(BaseTooltip, { title: langApp.t('sign_document') },
        React.createElement(IconButton, { onClick: onCLick, color: 'primary' },
            React.createElement(AssignmentTurnedInIcon, null))));
    if (!item.needSign ||
        !store.accountStore.currentUser._id ||
        !props.config ||
        !props.quote ||
        !props.quote.createdBy ||
        !props.quote.createdBy.company)
        return null;
    var canRequestSign = true;
    var canSign = props.quote.createdBy.company._id === store.accountStore.currentCompany._id ||
        props.quote.order.transportations.find(function (e) {
            return e.routes.find(function (e) { return e.email.map(function (e) { return e.toLowerCase(); }).includes(store.accountStore.currentUser.email); });
        });
    var signers = ['consignee', 'sender', 'carrier'];
    var signCOntent = (canRequestSign &&
        props.quote.order.transportations.find(function (e) { return (e.endTime && e.endTime[0]) || (e.startDate && e.startDate[0]); }) &&
        item.logs.filter(function (e) { return signers.includes(JSON.parse(e.changes).user_access); }).length < signers.length && (React.createElement(RequestSign, { item: item, config: props.config }))) ||
        null;
    if (!canSign)
        return signCOntent;
    var log = item.logs.find(function (e) { return e.createdBy.company._id === store.accountStore.currentCompany._id; });
    if (log) {
        var client = props.config.client;
        var baseListProps = {
            client: client,
            query: FetchLogs,
            defaultFilter: {
                fields: [
                    {
                        name: 'object',
                        value: item._id,
                    },
                    {
                        name: 'action',
                        value: 'DOCUMENT_SIGN',
                    },
                ],
            },
        };
        var config = {
            title: '',
            hideSort: true,
            rowContent: function (record, onChange) {
                return (React.createElement(BaseListItem, { record: record },
                    React.createElement(Grid, { container: true, spacing: 2, style: { padding: theme.spacing(1) } },
                        React.createElement(Grid, { item: true, xs: 12, md: 6, container: true, alignItems: 'center' },
                            React.createElement(AvatarItemView, { typographyProps: {
                                    variant: 'h6',
                                    style: {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: theme.palette.common.white,
                                    },
                                }, user: record.createdBy }),
                            React.createElement(TextOverflow, { style: {
                                    paddingLeft: theme.spacing(2),
                                    maxWidth: "calc(100% - 56px)",
                                } }, record.createdBy.company.name)),
                        React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' }, dateViewFormat(record.createdAt, 1)),
                        React.createElement(Grid, { item: true, xs: 12, md: 4, container: true, alignItems: 'center', justify: 'flex-end' },
                            React.createElement(FilePreview, { item: { path: JSON.parse(record.changes).path }, title: "" + langApp.t('sign_overview_document_before_sign'), config: props.config }),
                            React.createElement(FilePreview, { item: { path: JSON.parse(record.changes).newPath }, title: "" + langApp.t('sign_overview_document_after_sign'), config: props.config })))));
            },
        };
        var dialogBody = React.createElement(BaseList, { title: langApp.t('signed_documents'), options: baseListProps, config: config });
        return (React.createElement(React.Fragment, null,
            React.createElement(BasicDialog, { body: dialogBody, title: langApp.t('signed_documents'), dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: langApp.t('signed_documents') },
                    React.createElement(IconButton, { onClick: handleClickOpen },
                        React.createElement(AssignmentTurnedInIcon, null)))); } }),
            signCOntent));
    }
    return actonInit;
});
