var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_QUOTE_DRIVER_BASE = "\n    " + BASE_QUERY + "\n       origin\n       approvedAt\n       quote{\n            _id\n       }\n       name\n       phone\n";
export var FetchOrderQuoteDrivers = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderQuoteDrivers($filter: MainFilter) {\n        orderQuoteDrivers(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderQuoteDrivers($filter: MainFilter) {\n        orderQuoteDrivers(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_QUOTE_DRIVER_BASE);
export var CreateOrderQuoteDriver = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderQuoteDriver($input: OrderQuoteDriverInput!) {\n        createOrderQuoteDriver(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderQuoteDriver($input: OrderQuoteDriverInput!) {\n        createOrderQuoteDriver(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_DRIVER_BASE);
export var DeleteOrderQuoteDriver = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation deleteOrderQuoteDriver($_id:MongoId!){\n        deleteOrderQuoteDriver(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderQuoteDriver($_id:MongoId!){\n        deleteOrderQuoteDriver(_id:$_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3;
