var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles, createStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
            '& .MuiListItem-root': {
                paddingLeft: 0,
            },
        },
        popOverRoot: {
            pointerEvents: 'none',
        },
    });
});
var index = 1;
export default function MenuOverPopover(props) {
    var currentlyHovering = false;
    var styles = useStyles();
    var _a = __read(React.useState('mouse-over-popover-' + index++), 1), id = _a[0];
    var classes = useStyles();
    var _b = __read(React.useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var handlePopoverClose = function () {
        setAnchorEl(null);
    };
    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }
    function handleHover() {
        currentlyHovering = true;
    }
    function handleClose() {
        setAnchorEl(null);
    }
    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(function () {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }
    var open = Boolean(anchorEl);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "aria-owns": open ? id : undefined, "aria-haspopup": "true", onClick: handleClick, onMouseOver: handleClick, onMouseLeave: handleCloseHover }, props.children),
        React.createElement(Menu, { id: id, className: classes.popover, classes: {
                paper: classes.paper,
            }, anchorEl: anchorEl, open: open, onClose: handlePopoverClose, MenuListProps: { onMouseEnter: handleHover, onMouseLeave: handleCloseHover, style: { pointerEvents: 'auto' } }, anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            }, PopoverClasses: {
                root: styles.popOverRoot,
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, disableRestoreFocus: true },
            React.createElement("div", { onClick: handleClose }, props.content))));
}
