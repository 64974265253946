import { makeStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) { return ({
    menuIcon: {
        marginRight: theme.spacing(2),
    },
    supportText: {
        color: theme.palette.text.secondary,
        fontSize: 15,
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.text.secondary,
    },
    accordion: {
        display: 'block',
    },
    nested: {
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(8.8),
        justifyContent: 'space-between',
        borderLeft: '2px solid transparent',
        '&.Mui-selected': {
            borderColor: theme.palette.primary.main,
        },
    },
    handleLineActive: {
        height: 'auto',
        width: '4px',
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    handleLinePassive: {
        height: 'auto',
        width: '4px',
        backgroundColor: 'transparent',
        marginRight: theme.spacing(1),
    },
    icon: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}); });
