var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { BaseList } from '../../../components/BasicList';
import { FetchUploads } from '../../../graphql';
import { UploadList } from '../../../components/Uploads/List';
import OrderQuoteDocumentItem from './DocumentItem';
import { useTranslation } from 'react-i18next';
import { KEYS, i18n } from '../../../i18n';
import { CUSTOM_EVENTS, withNotificationStore } from '../../../components/Socket/Notifications/store';
import { withBaseGlobalSocketStoreActiveClient } from '../../../components/Socket/global/store';
import { TextOverflow } from '../../../components';
import { BaseUserAppTYpe } from '../../../stores/common';
import { inject } from 'mobx-react';
import ConsigneeDocumentTypes from './ConsigneDocumentTypes';
import { theme } from '../../../theme';
import { checkIfOrderDetailsIsHide, createAppTypeUser, getAllBaseUserAppTypeAccessForDocuments } from '../../../utils1';
import { Grid, useMediaQuery } from '@material-ui/core';
import { GuideTypes, getTourSteps } from '../../../guides';
import PreloadingComponent from '../../../components/PreloadingComponent';
import { TimerToRefresh } from './TimerToRefresh';
var BasicTour = React.lazy(function () { return import('../../../components/BasicTour'); });
var timeOut;
export var OrderQuoteDocumentsComponent = inject('store')(function (p) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q = p.store.accountStore, currentUser = _q.currentUser, isLoggedIn = _q.isLoggedIn, props = __rest(p, ["store"]);
    var config = props.config, parent = props.parent, id = props.id;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var _r = __read(React.useState(0), 2), updates = _r[0], setUpdates = _r[1];
    var _s = __read(React.useState(0), 2), updatesToRefresh = _s[0], setUpdatesToRefresh = _s[1];
    var _t = __read(React.useState(false), 2), hasTimerToRefresh1 = _t[0], setHasTimerToRefresh1 = _t[1];
    var _u = __read(React.useState(null), 2), lastDocumentSendEmail = _u[0], setLastDocumentSendEmail = _u[1];
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var guides = currentUser.settings.guides;
    var showDocumentsDialog = isCarrier && isLoggedIn && (!guides || (guides && !guides[GuideTypes.CARRIER_UPLOAD_OFFER_DOCUMENT]));
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    if (!config || !config.client)
        return null;
    React.useEffect(function () {
        return function () {
            if (timeOut)
                clearTimeout(timeOut);
        };
    }, []);
    var onChangeNotify = function () {
        document.body.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.FRESH_DOCUMENT_NOTIFIER));
        setUpdatesToRefresh(Date.now());
    };
    var _onChange = function (e) {
        if (props.onChange) {
            props.onChange(e);
        }
        else {
            setUpdates(Date.now());
        }
        // if (!onChange) {
        // } else {
        //   if (timeOut) clearTimeout(timeOut);
        //   timeOut = setTimeout(() => onChange && onChange(e), 1000);
        // }
    };
    var _access = getAllBaseUserAppTypeAccessForDocuments(props.quote, currentUser);
    var isCarrierFromCustomerList = ((_d = (_c = (_b = (_a = props.quote) === null || _a === void 0 ? void 0 : _a.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.companiesParents) === null || _d === void 0 ? void 0 : _d.length) &&
        props.quote.createdBy.company.companiesParents
            .map(function (e) { return e.toString(); })
            .includes((_h = (_g = (_f = (_e = props.quote.order) === null || _e === void 0 ? void 0 : _e.createdBy) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g._id) === null || _h === void 0 ? void 0 : _h.toString());
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var hideForwarderToCarrier = isCustomer && p.store.accountStore.isForwarderEnvOnly && !isCarrierFromCustomerList;
    var hideForwarderToCustomer = isCarrier && ((_k = (_j = props.quote.order) === null || _j === void 0 ? void 0 : _j.forwarders) === null || _k === void 0 ? void 0 : _k.length) && !isCarrierFromCustomerList;
    var visibleToName = Object.values(BaseUserAppTYpe)
        .filter(function (e) {
        return ![
            BaseUserAppTYpe.WAREHOUSE,
            BaseUserAppTYpe.CONSIGNEE,
            BaseUserAppTYpe.SYSTEM,
            BaseUserAppTYpe.AFFILIATE,
        ].includes(e);
    })
        .filter(function (e) { return !(_access && !_access(e)); })
        .map(function (e) {
        if (hideForwarderToCarrier) {
            e = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CARRIER : e;
        }
        if (hideForwarderToCustomer) {
            e = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CUSTOMER : e;
        }
        var appUser = createAppTypeUser(e);
        return (appUser.firstName + appUser.lastName).toUpperCase();
    });
    var headerColumns = [
        {
            name: langForm.t('form_input_upload_type'),
            sortable: true,
            size: 2,
            style: { paddingLeft: isSmall ? 0 : theme.spacing(1) },
        },
        {
            name: langApp.t('app_file'),
            size: 3,
        },
        {
            name: langToast.t('toast_email_to_notify'),
            sortable: true,
            size: 3,
        },
        {
            name: langForm.t('form_input_name_created_at'),
            size: 1,
        },
        props.isPreparation && {
            name: langOfferForm.t('Preparation step'),
            namesStyle: {
                paddingLeft: theme.spacing(4),
            },
            size: 2,
        },
        ![BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (e) { return currentUser.appType.includes(e); }) &&
            !props.isPreparation && {
            name: isSmall && langOfferForm.t('offer_form_document_visible'),
            names: visibleToName,
            namesStyle: {
                paddingLeft: theme.spacing(4),
            },
            size: 2,
            style: {
                display: 'flex',
                width: '100%',
                justifyContent: isSmall ? 'flex-start' : 'flex-end',
                paddingRight: theme.spacing(2.8),
                textTransform: !isSmall && 'uppercase',
            },
        },
        {
            name: langOfferForm.t('offer_form_document_actions'),
            size: 1,
            style: { display: 'flex', width: '100%', justifyContent: isSmall ? 'flex-start' : 'flex-end' },
        },
    ].filter(function (e) { return e; });
    return (React.createElement(React.Fragment, null,
        showDocumentsDialog ? (React.createElement(PreloadingComponent, null,
            React.createElement(BasicTour, { client: config.client, steps: getTourSteps(GuideTypes.CARRIER_UPLOAD_OFFER_DOCUMENT, i18n, KEYS), updateSettings: true }))) : null,
        React.createElement(Grid, { container: true, style: { paddingTop: theme.spacing(2) }, "data-id": 'order-list-documents-container', key: updates + "-" + props.state.newDocumentSigned },
            React.createElement("div", { style: { paddingLeft: 6 } },
                props.hasTimerToRefresh && hasTimerToRefresh1 && (React.createElement(TimerToRefresh, { key: updatesToRefresh, onChange: onChangeNotify })),
                !props.isPreparation ? React.createElement(ConsigneeDocumentTypes, { order: props.quote.order }) : null),
            React.createElement(BaseList, { options: {
                    client: config.client,
                    query: FetchUploads,
                    onLoad: function (data) {
                        var item = data.data.find(function (e) { return Date.now() - new Date(e.updatedAt).getTime() < 1000 * 60 * 1; });
                        if (item) {
                            setHasTimerToRefresh1(true);
                            setLastDocumentSendEmail(item);
                        }
                        else {
                            setLastDocumentSendEmail(null);
                        }
                    },
                    defaultFilter: props.defaultFilter ||
                        {
                            fields: [
                                { name: 'parent', value: parent },
                                { name: 'parentOffer', value: 1 },
                                {
                                    name: 'canAccess',
                                    value: true,
                                },
                                ((_m = (_l = props.quote) === null || _l === void 0 ? void 0 : _l.order.view_access) === null || _m === void 0 ? void 0 : _m.length) && {
                                    name: 'view_access',
                                    value: (_o = props.quote) === null || _o === void 0 ? void 0 : _o.order.view_access,
                                },
                                ((_p = props.quote) === null || _p === void 0 ? void 0 : _p.order) &&
                                    checkIfOrderDetailsIsHide(p.store, props.quote.order) && {
                                    name: 'access',
                                    value: [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                                },
                            ].filter(function (e) { return e; }),
                        },
                }, config: __assign(__assign({ mainHeaderStyle: { padding: 0, paddingTop: 6 }, id: "" + (id || 'quote-uploads'), hideSearch: true, hideAllFilters: true, dontSaveFilters: true }, (!props.rowItem && {
                    headerColumns: headerColumns,
                })), { title: '', rowContent: function (record, onChange) {
                        return props.rowItem ? (props.rowItem(record, onChange)) : (React.createElement(OrderQuoteDocumentItem, { isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, folder: props.folder, updateList: _onChange, record: record, quote: props.quote, headerColumns: headerColumns, onChange: function () {
                                var _a, _b;
                                var e = [];
                                for (var _i = 0; _i < arguments.length; _i++) {
                                    e[_i] = arguments[_i];
                                }
                                if (e[1] === 'ONLY_EMAIL_UPDATED') {
                                    setHasTimerToRefresh1(true);
                                    setLastDocumentSendEmail(e[0]);
                                }
                                else {
                                    onChange.apply(void 0, __spread(e));
                                    if (['path', 'updatedAt'].find(function (key) { return record[key] !== e[0][key]; }) ||
                                        ((_a = record.type) === null || _a === void 0 ? void 0 : _a._id) !== ((_b = e[0].type) === null || _b === void 0 ? void 0 : _b._id)) {
                                        props.onChange && props.onChange.apply(props, __spread(e));
                                    }
                                }
                            }, config: config }));
                    }, noDataImageStyle: props.noDataImageStyle, noData: props.noData || (React.createElement(React.Fragment, null,
                        React.createElement(TextOverflow, { variant: 'subtitle1' }, langOfferForm.t('offer_form_document_no_data')))) }) }),
            !props.hideAdd && (React.createElement(UploadList, __assign({}, props, { isPreparation: props.isPreparation, lastActiveStep: props.lastActiveStep, isBatch: true, onChange: function () {
                    var e = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        e[_i] = arguments[_i];
                    }
                    if (e[0])
                        _onChange(e[0]);
                } }))))));
});
var OrderQuoteDocumentsConsumer = withNotificationStore(OrderQuoteDocumentsComponent);
export default withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    return React.createElement(OrderQuoteDocumentsConsumer, __assign({}, props));
});
