import React from 'react';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const OrderGroupingForm = React.lazy(() => import('@izi-logistics/common/lib/pages/OrderGroup/Form'));

export default function OrderGrouping(props: any) {
  return (
    <PreloadingComponent>
      <OrderGroupingForm {...props} />
    </PreloadingComponent>
  );
}
