var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Grid, createStyles, useMediaQuery } from '@material-ui/core';
import { withBaseListStore } from '../store';
import { MainFilterSortDir } from '../types.enums';
import { theme } from '../../../theme';
import { BaseSelect } from '../../Formik/FormikSelect';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            background: theme.palette.primary.main,
            border: "2px solid #002B69",
            boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.2)',
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
            '& fieldset': {
                display: 'none',
            },
            '& .MuiTypography-root': {
            // color: theme.palette.common.white,
            },
            '& .input-label': {
                color: theme.palette.common.white,
                minHeight: 20,
                '& *': {
                    opacity: 1,
                    color: theme.palette.common.white,
                    fill: theme.palette.common.white,
                },
            },
            '& .MuiButton-outlined': {
                marginTop: theme.spacing(1),
                borderColor: theme.palette.common.white,
                color: theme.palette.common.white,
                '& *': {
                    color: theme.palette.common.white,
                },
            },
            '& input': {
                paddingLeft: theme.spacing(1),
            },
            '& .MuiSlider-valueLabel>span>span': {
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                color: theme.palette.primary.light,
                display: 'block',
                overflow: 'hidden',
            },
            '& .MuiCheckbox-root': {
                color: theme.palette.common.white + "!important",
                paddingBottom: theme.spacing(0.5),
                paddingTop: theme.spacing(0.5),
            },
            '& .MuiFormControlLabel-label': {
                color: theme.palette.common.white,
            },
        },
    });
});
export var BaseListHeaderFilter = inject('store')(withBaseListStore(function (_a) {
    var currentUser = _a.store.accountStore.currentUser, state = _a.state, defaultFilter = _a.defaultFilter, onChangeFilter = _a.onChangeFilter, onSearch = _a.onSearch, style = _a.style, config = _a.config;
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var classesStyles = useStyles();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var history = useHistory();
    // if (!state.loaded /*|| !state.count*/) return null;
    var onChange = function (ev) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, name, value;
        var _b;
        return __generator(this, function (_c) {
            _a = ev.target, name = _a.name, value = _a.value;
            onChangeFilter((_b = { skip: 0, page: 1 }, _b[name] = value, _b), { ev: ev, currentUser: currentUser, defaultFilter: defaultFilter });
            return [2 /*return*/];
        });
    }); };
    var handleDelete = function (filter) {
        if (filter.name === 'search') {
            onSearch({ target: { name: filter.name, value: '' } }, 1);
        }
        history.push({
            pathname: history.location.pathname,
            search: '',
        });
    };
    var inputStyles = __assign(__assign({ minWidth: 'fit-content' }, (!isSmall && { padding: theme.spacing(0, 3, 0, 0) })), (isSmall && { padding: theme.spacing(0) }));
    var hasSort = state.sortFields.length && !config.hideSort;
    var baseFilters = hasSort && (state.hasData || config.showFilters === true || typeof config.showFilters === 'undefined');
    var filterInputes = [
        {
            name: 'sortDir',
            content: (React.createElement(Grid, { xs: isSmall ? 12 : undefined, item: true, style: inputStyles },
                React.createElement(BaseSelect, { v12: true, v14: true, style: { minWidth: theme.spacing(22) }, name: "sortDir", title: langOrderList.t('order_list_filter_sort_dir_label'), value: state.filter.sortDir, variant: 'outlined', onChange: onChange, options: [
                        { name: langOrderList.t('order_list_filter_sort_dir_newest'), value: MainFilterSortDir.DESC },
                        { name: langOrderList.t('order_list_filter_sort_dir_latest'), value: MainFilterSortDir.ASC },
                    ] }))),
        },
        {
            name: 'sortBy',
            content: (React.createElement(Grid, { xs: isSmall ? 12 : undefined, item: true, style: inputStyles },
                React.createElement(BaseSelect, { v12: true, v14: true, noCaseChange: true, style: { minWidth: theme.spacing(22) }, variant: 'outlined', name: "sortBy", title: langOrderList.t('order_list_filter_sort_by_label'), value: state.filter.sortBy, onChange: onChange, options: state.sortFields }))),
        },
    ];
    var baseFiltersContent = (baseFilters && (React.createElement(React.Fragment, null, filterInputes.map(function (filter) { return (React.createElement(React.Fragment, { key: filter.name || '1' }, filter.content)); })))) ||
        null;
    var filtersLoaded = (config.filtersV1 &&
        config.filtersV1(state.filter, onChange, {
            filtersStats: state.filtersStats,
            inputStyles: inputStyles,
            filterInputes: filterInputes,
            config: config,
        })) ||
        (config.filters &&
            config.filters(state.filter, onChange, {
                filtersStats: state.filtersStats,
                inputStyles: inputStyles,
                baseFiltersContent: baseFiltersContent,
                config: config,
            })) ||
        null;
    if (!filtersLoaded && !baseFilters) {
        return null;
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', className: classesStyles.root, style: style, key: state.filtersUpdated }, config.filtersV1 && filtersLoaded ? (filtersLoaded) : (React.createElement(Grid, { container: true, alignItems: 'flex-end', style: isSmall ? {} : { width: 'initial' } }, filtersLoaded ? filtersLoaded : React.createElement(React.Fragment, null, baseFiltersContent)))));
}));
