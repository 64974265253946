import React from 'react';
import { Grid } from '@material-ui/core';
import SectionDetails from '../../../components/SectionDetails';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { useSectionRowStyles } from '../../../components/SectionDetails/styles';
import { dateViewFormat } from '../../../components/DateView';
export default (function (_a) {
    var record = _a.record;
    var langForm = useTranslation(KEYS.FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var className = useSectionRowStyles();
    return (React.createElement(Grid, { item: true, xs: 12, md: 3 },
        React.createElement(SectionDetails, { title: langForm.t('form_invoice_information'), rows: [
                {
                    name: langOrderList.t('in_progress_payment_made'),
                    value: dateViewFormat(record.startDate) || '-',
                },
                {
                    name: langForm.t('form_invoice_term'),
                    value: dateViewFormat(record.endDate) || '-',
                },
                {
                    name: langForm.t('form_invoice_payments_sent'),
                    value: dateViewFormat(record.sentAt) || '-',
                },
            ] })));
});
