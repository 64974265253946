var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '../stores/common';
import { browserStorage } from '../browserStorage';
export var NAVIGATION = {
    reset: function () {
        NAVIGATION.prevLocation = '';
        NAVIGATION.hash = '';
    },
    prevLocation: '',
    hash: '',
};
var AppTitle = inject('store')(observer(function (_a) {
    var store = _a.store;
    React.useEffect(function () {
        if (process.env.REACT_APP_NONE_BRAND) {
            document.title = 'App';
        }
        else if (store.accountStore.isForwarderNotIziLogisticsEnv ||
            store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER) ||
            window.location.pathname.match(/forwarder/gi)) {
            document.title = 'IZI Expedition';
        }
        else {
            document.title = 'IZI Logistics';
        }
    }, [store.accountStore.currentUser._id]);
    return null;
}));
var LocationListenComponent = /** @class */ (function (_super) {
    __extends(LocationListenComponent, _super);
    function LocationListenComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LocationListenComponent.prototype.componentWillMount = function () {
        if (process.env.REACT_APP_NONE_BRAND) {
            document.title = 'App';
        }
        else if (this.props.store.accountStore.isForwarderNotIziLogisticsEnv) {
            document.title = 'IZI Expedition';
        }
        // window
        //   .fetch(this.props.URL.replace('/graphql', '/version'))
        //   .then((r) => r.json())
        //   .then((res: any) => {
        //     const v = browserStorage.getItem('IZI-api-version');
        //     if (!v || parseInt(v.toString()) !== parseInt(res.version)) {
        //       browserStorage.setItem('IZI-api-version', res.version);
        //       // window.location.href += `?apiVersion=${res.version}`;
        //     }
        //   });
    };
    LocationListenComponent.prototype.componentWillReceiveProps = function (nextProps) {
        var _a, _b, _c, _d;
        try {
            var lastUrl = ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.pathname) + ((_d = (_c = this.props) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.search);
            var isLoggedOut = this.props.store.accountStore.isLoggedOut;
            if (!isLoggedOut) {
                if (!['login', 'register', 'reset-password', 'change-password'].includes(this.props.location.pathname.replace(/\//gi, ''))) {
                    browserStorage.setItem('last-url', lastUrl);
                }
                if (nextProps.location !== this.props.location) {
                    NAVIGATION.prevLocation = this.props.location.pathname;
                    NAVIGATION.hash = nextProps.location.hash;
                    this.props.store.accountStore.setNewLocation(nextProps.location.pathname);
                    if (!['login', 'register', 'reset-password', 'change-password'].includes(nextProps.location.pathname.replace(/\//gi, ''))) {
                        browserStorage.setItem('last-url', lastUrl);
                    }
                }
            }
            if (isLoggedOut && nextProps.location.pathname !== this.props.location.pathname) {
                this.props.store.accountStore.update({
                    isLoggedOut: false,
                });
            }
            if (process.env.REACT_APP_NONE_BRAND) {
                document.title = 'App';
            }
            else if (nextProps.store.accountStore.currentUser._id !== this.props.store.accountStore.currentUser._id) {
                if (nextProps.store.accountStore.isForwarderNotIziLogisticsEnv) {
                    document.title = 'IZI Expedition';
                }
            }
        }
        catch (e) { }
    };
    LocationListenComponent.prototype.render = function () {
        return React.createElement(AppTitle, null);
    };
    return LocationListenComponent;
}(React.Component));
// const LocationListenComponent = ({ history }: any) => {
//   const [preLoc, setPrevLoc] = React.useState(history.location.pathname);
//
//   const location = useLocation();
//   // React.useEffect(() => {
//   //   history.listen((location, action, ...e) => {
//   //     console.log(action, location, ...e);
//   //   });
//   // }, []);
//
//   useEffect(() => {
//     console.log(location,history);
//   }, [location.pathname]);
//
//   return null;
// };
export var LocationListen = inject('store')(observer(withRouter(LocationListenComponent)));
