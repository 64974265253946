import { applySnapshot, types } from 'mobx-state-tree';
import { OrderCargoSizeType } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderCargoPalletType = types
    .model('OrderCargoPalletType', {
    _id: types.optional(types.string, ''),
    length: types.maybeNull(types.optional(types.union(types.number, types.string), '')),
    width: types.maybeNull(types.optional(types.union(types.number, types.string), '')),
    isGlobal: types.maybeNull(types.optional(types.boolean, false)),
    dimension: types.maybeNull(types.optional(types.enumeration(OrderCargoSizeType.CM, Object.values(OrderCargoSizeType)), OrderCargoSizeType.CM)),
    name: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
