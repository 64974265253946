var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as reactRouter from 'react-router';
import { useLocalStore, observer, inject } from 'mobx-react';
import { formUserErrorMessage, pswRegExp } from '../common';
import { KEYS } from '../i18n';
import { FormikTextField, FormikTextFieldForPassword, EmptyLayout, WholePageFormLayout } from '../components';
import { googleTagManager } from '../utils1';
var Redirect = reactRouter.Redirect;
export var LoginPage = inject('store')(observer(function (_a) {
    var store = _a.store, noRegister = _a.noRegister, noApps = _a.noApps, props = __rest(_a, ["store", "noRegister", "noApps"]);
    var accountStore = store.accountStore;
    var langAuth = useTranslation(KEYS.AUTH);
    var langForm = useTranslation(KEYS.FORM);
    var isAsyncActionInProgress = accountStore.isAsyncActionInProgress;
    var localStore = useLocalStore(function () { return ({
        userErrorMessage: '',
        onLoginRequested: function (data) {
            localStore.userErrorMessage = '';
            accountStore
                .login(data)
                .then(function () {
                googleTagManager('event', 'izi_form_user_login', {
                    event_name: "user_login",
                    value: data,
                });
            })
                .catch(function (error) {
                localStore.userErrorMessage = formUserErrorMessage(error);
            });
        },
        clearUserErrorMessage: function () {
            localStore.userErrorMessage = '';
        },
    }); });
    useEffect(function () {
        localStore.clearUserErrorMessage();
    }, [localStore]);
    if (accountStore.isLoggedIn) {
        return React.createElement(Redirect, { to: "/" });
    }
    var error = localStore.userErrorMessage;
    return (React.createElement(EmptyLayout, null,
        React.createElement(Formik, { initialValues: {
                email: '',
                password: '',
            }, validationSchema: Yup.object({
                email: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .email(langForm.t('form_input_validate_invalid_email'))
                    .required(langForm.t('form_input_validate_required')),
                password: Yup.string()
                    .min(8, langForm.t('form_input_validate_min_char').replace(/_X_/g, '8'))
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .matches(pswRegExp, langForm.t('form_input_validate_invalid_psw'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: function (values, _a) {
                var setSubmitting = _a.setSubmitting;
                localStore.onLoginRequested({
                    email: values.email,
                    password: values.password,
                });
            } }, function (formik) {
            var onFieldChangeHandler = function (e) {
                localStore.clearUserErrorMessage();
                formik.handleChange(e);
            };
            var propsLayout = {};
            if (!noRegister) {
                Object.assign(propsLayout, {
                    wholePageFormFooterNavLink: {
                        paragraphText: langAuth.t('login_page_i_dont_have_account_explanatory_text'),
                        navPath: '/register',
                        linkText: langAuth.t('sign_up_page_title'),
                    },
                });
            }
            var loginText = langAuth.t("login_page_log_in");
            return (React.createElement(WholePageFormLayout, __assign({ noApps: noApps, noRegister: noRegister, isLoading: isAsyncActionInProgress, error: error, wholePageFormBackButtonVisible: false, wholePageFormTitle: {
                    text: loginText,
                }, submitButtonWithLoading: {
                    text: loginText,
                    id: 'login-page-button',
                }, wholePageFormHelperNavLinkVisible: true, wholePageFormHelperNavLink: {
                    navPath: '/reset-password',
                    linkText: langAuth.t('login_page_did_you_forget_your_password_explanatory_text'),
                    id: 'forgot-password-link',
                }, wholePageFormFooterNavLinkVisible: !props.isAdmin }, propsLayout, { successfulRequest: false }),
                React.createElement(FormikTextField, { v3: true, type: "email", name: "email", label: langForm.t('form_input_name_email'), value: formik.values.email, onChange: onFieldChangeHandler }),
                React.createElement(FormikTextFieldForPassword, { v3: true, name: "password", label: langForm.t('form_input_name_password'), value: formik.values.password, onChange: onFieldChangeHandler })));
        })));
}));
export default LoginPage;
