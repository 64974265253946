var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { withNotificationStore, NotificationProvider } from './store';
import { withBaseGlobalSocketStoreActiveClient } from '../global/store';
import { inject, observer } from 'mobx-react';
export var GlobalSocketComponent = inject('store')(observer(function (_a) {
    var listen = _a.listen, unListen = _a.unListen, children = _a.children, state = _a.state, store = _a.store;
    /*eslint-disable */
    useEffect(function () {
        listen();
        return function () {
            unListen();
        };
    }, []);
    useEffect(function () {
        if (state && state.updatedAt) {
            store.accountStore.refreshMenu(Date.now());
        }
        return function () { };
    }, [state && state.updatedAt]);
    /*eslint-enable */
    return children;
}));
export var GlobalBlankComponent = function (_a) {
    var children = _a.children;
    return children;
};
export var GlobalSocketComponentWithStore = withNotificationStore(GlobalSocketComponent);
export var GlobalNotificationsSocket = inject('store')(withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, store = p.store, props = __rest(p, ["client", "store"]);
    var content = React.createElement(GlobalSocketComponentWithStore, __assign({}, props));
    return (React.createElement(NotificationProvider, { client: p.globalSocketState.client, mobxStore: store }, content));
}));
