var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Grid, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { inject } from 'mobx-react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import React from 'react';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
export var useStyles = makeStyles(function (theme) { return ({
    actions: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        background: 'linear-gradient(to top, rgba(0,0,0,.8) 0%, transparent 100px, transparent 100%)',
        opacity: 0,
        transition: 'opacity 0.6s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity 0s ease',
        },
    },
}); });
export default inject('store')(function BaseImage(props) {
    var upload = props.upload, style = props.style, onClickFullScreen = props.onClickFullScreen;
    var _a = __read(React.useState(false), 2), isFullScreen = _a[0], setFullScreen = _a[1];
    var classes = useStyles();
    var ref = React.useRef(null);
    var theme = useTheme();
    React.useEffect(function () {
        function fullscreenchanged(event) {
            if (!document.fullscreenElement) {
                setFullScreen(false);
            }
        }
        document.addEventListener('fullscreenchange', fullscreenchanged);
        return function () {
            document.removeEventListener('fullscreenchange', fullscreenchanged);
        };
    }, []);
    var enterFullscreen = function () {
        var elem = ref.current;
        if (elem === null || elem === void 0 ? void 0 : elem.requestFullscreen) {
            elem.requestFullscreen();
        }
        else if (elem === null || elem === void 0 ? void 0 : elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        }
        else if (elem === null || elem === void 0 ? void 0 : elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        }
    };
    var exitFullscreen = function () {
        var _document = document;
        if (_document === null || _document === void 0 ? void 0 : _document.exitFullscreen) {
            _document.exitFullscreen();
        }
        else if (_document === null || _document === void 0 ? void 0 : _document.mozCancelFullScreen) {
            _document.mozCancelFullScreen();
        }
        else if (_document === null || _document === void 0 ? void 0 : _document.webkitExitFullscreen) {
            _document.webkitExitFullscreen();
        }
    };
    var handleFullscreen = function () {
        if (isFullScreen) {
            exitFullscreen();
        }
        else {
            enterFullscreen();
        }
        setTimeout(function () {
            setFullScreen(!isFullScreen);
        }, 100);
    };
    return (React.createElement(Grid, { container: true, justify: "center", alignItems: "center", style: {
            position: 'relative',
            margin: theme.spacing(2),
            width: 'fit-content',
            height: 'fit-content',
        }, ref: ref },
        React.createElement("img", { src: upload.path, alt: upload.name, style: __assign(__assign({}, style), (isFullScreen ? { width: undefined, height: undefined, maxWidth: '100vw', maxHeight: '100vh' } : {})) }),
        React.createElement(Grid, { container: true, className: classes.actions },
            React.createElement(IconButton, { onClick: handleFullscreen, style: {
                    color: theme.palette.common.white,
                } }, !isFullScreen ? React.createElement(FullscreenIcon, null) : React.createElement(FullscreenExitIcon, null)))));
});
