import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { useSectionRowStyles } from './styles';
export default function AdditionalRquirementsSectionRow(props) {
    var name = props.name, value = props.value, sizeName = props.sizeName, sizeValue = props.sizeValue, additional = props.additional, nameStyle = props.nameStyle;
    var className = useSectionRowStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: className.root },
        React.createElement(Grid, { item: true, xs: 12, container: true, md: sizeName || 6, alignItems: 'center', style: {
                width: 'initial',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: nameStyle }, name),
            additional),
        React.createElement(Grid, { item: true, xs: 12, md: sizeValue || 6, style: {
                display: 'grid',
            } },
            React.createElement(Typography, { variant: 'caption', style: { textAlign: isSmall ? 'left' : 'right' } }, value))));
}
