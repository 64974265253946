import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { inject, observer, useLocalStore } from 'mobx-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { WholePageFormLayout, EmptyLayout, FormikTextField } from '../components';
import { formUserErrorMessage } from '../common';
import { KEYS } from '../i18n';
import { googleTagManager } from '../utils1';
export var ResetPasswordPage = inject('store')(observer(function (_a) {
    var store = _a.store, noRegister = _a.noRegister, noApps = _a.noApps;
    var langAuth = useTranslation(KEYS.AUTH);
    var langForm = useTranslation(KEYS.FORM);
    var preLoginStore = store.preLoginStore;
    var isAsyncActionInProgress = preLoginStore.isAsyncActionInProgress;
    var localStore = useLocalStore(function () { return ({
        userErrorMessage: '',
        isOk: false,
        onForgotPasswordRequest: function (data) {
            localStore.isOk = false;
            localStore.userErrorMessage = '';
            googleTagManager('event', 'izi_form_user_reset_psw', {
                event_name: "user_reset_psw",
                value: data,
            });
            preLoginStore
                .requestEmailOnForgottenPassword(data)
                .then(function () {
                localStore.isOk = true;
                //history.push("/")
            })
                .catch(function (error) {
                localStore.userErrorMessage = formUserErrorMessage(error);
            });
        },
        clearUserErrorMessage: function () {
            localStore.userErrorMessage = '';
        },
    }); });
    useEffect(function () {
        localStore.clearUserErrorMessage();
    }, [localStore]);
    var error = localStore.userErrorMessage;
    var isOk = localStore.isOk;
    return (React.createElement(EmptyLayout, { key: localStore.isOk },
        React.createElement(Formik, { initialValues: {
                email: '',
            }, validationSchema: Yup.object({
                email: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                    .email(langForm.t('form_input_validate_invalid_email'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: function (values, _a) {
                var setSubmitting = _a.setSubmitting;
                localStore.onForgotPasswordRequest(values.email);
            } }, function (formik) {
            var onFieldChangeHandler = function (e) {
                localStore.clearUserErrorMessage();
                formik.handleChange(e);
            };
            return (React.createElement(WholePageFormLayout, { noApps: noApps, noRegister: noRegister, isLoading: isAsyncActionInProgress, error: error, wholePageFormBackButtonVisible: true, wholePageFormBackButton: {
                    navPath: '/login',
                }, wholePageFormTitle: isOk
                    ? {
                        text: langAuth.t('set_new_password_page_requested_explanatory_text'),
                        style: { textAlign: 'center' },
                    }
                    : {
                        text: langAuth.t('set_new_password_page_set_password_explanatory_text'),
                    }, submitButtonWithLoading: {
                    text: langForm.t('form_btn_confirm'),
                }, wholePageFormFooterNavLink: {
                    paragraphText: langAuth.t('login_page_i_have_account_explanatory_text'),
                    navPath: '/login',
                    linkText: langAuth.t('login_page_log_in'),
                }, wholePageFormHelperNavLinkVisible: false, wholePageFormFooterNavLinkVisible: true, successfulRequest: isOk, subText: langAuth.t('reset_password_page_please_check_the_mail_for_next_step_explanatory_text'), buttonText: langAuth.t('login_page_log_in'), path: '/login' },
                React.createElement(FormikTextField, { v3: true, type: "email", name: "email", label: langForm.t('form_input_name_email'), value: formik.values.email, onChange: onFieldChangeHandler })));
        })));
}));
export default ResetPasswordPage;
