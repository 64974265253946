var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import InputTitle from './InputTitle';
import { theme } from '../../theme';
export var FormikRadioGroupField = function (props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title, disabled = props.disabled;
    var _e = __read(useField(props), 3), field = _e[0], meta = _e[1], helpers = _e[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        // helpers.setValue(e.target.value);
        // if (onChange) onChange(e);
    };
    var onClick = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(RadioGroup, __assign({ id: props.id || (props.title || 'radio-group-input').toString().replace(/ /gi, '').toLowerCase(), "aria-label": "gender", style: { width: '100%' } }, field, props, { error: !!touchedError, onChange: _onChange, helperText: touchedError || helperText }),
            React.createElement(Grid, null, props.options.map(function (e) { return (React.createElement(FormControlLabel, __assign({ onClick: function () { return onClick({ target: { name: field.name, value: e.value } }); }, key: e.value, disabled: disabled, control: React.createElement(Radio, { color: "primary", style: { padding: theme.spacing(1) } }) }, e))); }))))); });
};
