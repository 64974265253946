var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { FormikAutocomplete, FormikAutocompleteAsync } from '../../components';
import { FetchCompanies } from '../../graphql';
import { theme } from '../../theme';
import clsx from 'clsx';
import React from 'react';
import { useChipStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import { ISSUE_STATUS } from '../../models/Issues';
import BaseChip from '../../components/BaseChip';
export default function IssueFilters(_a) {
    var filter = _a.filter, onChange = _a.onChange, options = __rest(_a, ["filter", "onChange"]);
    var client = options.config.client;
    var classesChip = useChipStyles();
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(null), 2), loadedFilters = _b[0], setLoadedFilters = _b[1];
    var initialValues = {};
    var status = filter.fields.find(function (e) { return e.name === 'status'; });
    var company = filter.fields.find(function (e) { return e.name === 'company'; });
    var statusOptions = (options.v1 ? ['REQUESTED', 'RESOLVED'] : Object.keys(ISSUE_STATUS)).map(function (_id) { return ({
        label: (options.v1 && 'REQUESTED' === _id && i18n.getResource(i18n.language, KEYS.FORM, "form_new")) ||
            i18n.getResource(i18n.language, KEYS.APP, "main_status_" + (_id === null || _id === void 0 ? void 0 : _id.toLowerCase())) ||
            i18n.getResource(i18n.language, KEYS.OFFER_FORM, "offer_" + (_id === null || _id === void 0 ? void 0 : _id.toLowerCase())) ||
            _id,
        value: ISSUE_STATUS[_id],
    }); });
    if (status) {
        Object.assign(initialValues, {
            status: status.value.map(function (value) {
                var _a;
                return {
                    label: (_a = statusOptions.find(function (e) { return e.value === value; })) === null || _a === void 0 ? void 0 : _a.label,
                    value: value,
                };
            }),
        });
    }
    if (company && loadedFilters) {
        Object.assign(initialValues, {
            company: loadedFilters.company.map(function (e) {
                return e._id
                    ? {
                        label: e.name,
                        value: e._id,
                    }
                    : e;
            }),
        });
    }
    var inputStyles = { marginRight: theme.spacing(1), marginBottom: theme.spacing(1) };
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: function (e) { return e; }, key: JSON.stringify(filter) }, function (formik) {
        var _a;
        var _b;
        return (React.createElement(Form, null,
            React.createElement(Grid, { container: true, direction: "row", alignItems: 'flex-end' }, options === null || options === void 0 ? void 0 :
                options.baseFiltersContent,
                (options.isAdmin && (React.createElement(Grid, { item: true, style: __assign(__assign({}, inputStyles), { minWidth: 120 }) },
                    React.createElement(FormikAutocompleteAsync, { v14: true, v17: true, client: client, onChangeText: function () { return 0; }, isClient: true, query: FetchCompanies, style: { maxHeight: theme.spacing(5) }, onChange: function (e, _value) {
                            if (!_value)
                                return;
                            var value = (filter.fields || []).filter(function (e) { return e.name !== 'company'; });
                            if (Array.isArray(_value) && _value.length)
                                value.push({
                                    name: 'company',
                                    value: _value.map(function (e) { return e._id || e.value || e; }),
                                });
                            setLoadedFilters(__assign(__assign({}, loadedFilters), { company: _value }));
                            onChange({
                                target: {
                                    name: 'fields',
                                    value: value,
                                },
                            });
                        }, autocompleteProps: {
                            multiple: true,
                            value: formik.values.company,
                            className: clsx((_a = {},
                                _a[classesChip.chipParentRootActive] = formik.values.company && formik.values.company.length,
                                _a)),
                            defaultValue: formik.values.company,
                            getOptionLabel: function (option) { return option && option._id; },
                            renderOption: function (option) { return option && option.name; },
                            filterOptions: function (options) { return options; },
                            freeSolo: false,
                            renderTags: function (value, getTagProps) {
                                var re = (Array.isArray(value) && value) || [];
                                return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index })))); });
                            },
                        }, filterFields: ['name'], title: langApp.t('company_label'), variant: "outlined", margin: 'dense', name: 'company', canSearch: true, value: formik.values.company, defaultValue: formik.values.company })))) ||
                    null,
                React.createElement(Grid, { item: true, style: inputStyles },
                    React.createElement(FormikAutocomplete, { v14: true, v17: true, onChageText: function () { return 0; }, autocompleteProps: {
                            multiple: true,
                            value: formik.values.status,
                            onChange: function (e) {
                                var value = (filter.fields || []).filter(function (e) { return e.name !== 'status'; });
                                if (Array.isArray(e.target.value) && e.target.value.length)
                                    value.push({
                                        name: 'status',
                                        value: e.target.value.map(function (e) { return e.value; }),
                                    });
                                onChange({
                                    target: {
                                        name: 'fields',
                                        value: value,
                                    },
                                });
                            },
                            filterOptions: function (options, params) {
                                var fields = (options.length && Object.keys(options[0])) || [];
                                var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                var res = filterText && fields.length
                                    ? options.filter(function (el) { return el.value.toLowerCase().match(filterText); })
                                    : options; //filterOptions(options, params);
                                if (Array.isArray(formik.values.status)) {
                                    var values_1 = formik.values.status.map(function (e) { return e.value || e; });
                                    return res.filter(function (e) { return !values_1.includes(e.value); });
                                }
                                return res;
                            },
                            getOptionLabel: function (option) { return option && option.label; },
                            renderOption: function (option) { return option && option.label; },
                            freeSolo: false,
                            renderTags: function (value, getTagProps) {
                                var re = (Array.isArray(value) && value) || [];
                                console.log(re);
                                return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index })))); });
                            },
                        }, title: langForm.t('form_status'), variant: "outlined", name: "status", value: formik.values.status, options: statusOptions })),
                React.createElement(Grid, { item: true, style: inputStyles }, (_b = options === null || options === void 0 ? void 0 : options.options) === null || _b === void 0 ? void 0 : _b.selectedSearch))));
    }));
}
