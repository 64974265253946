var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { inject, observer, useLocalStore } from 'mobx-react';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { KEYS } from '../../i18n';
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { 
//
ACTION_CHANGE, FormikAutocomplete, FormikAutocompleteAsync, formikAutocompleteMultiOptionsUsersProps, MainButton, } from '../../components';
import { getSnapshot } from 'mobx-state-tree';
import { theme } from '../../theme';
import { FetchOrders, FetchUsers } from '../../graphql';
import { OrderChat } from '../../models/OrderChat';
import { BaseUserAppTYpe } from '../../stores/common';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BaseTooltip from '../../components/BaseTooltip';
import { onlySpacesRegExp } from '../../common';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import { defaultChatUsers } from './utils';
export var ChatCreateEditForm = function (_a) {
    var item = _a.item, config = _a.config, isModalForm = _a.isModalForm, disabled = _a.disabled, props = __rest(_a, ["item", "config", "isModalForm", "disabled"]);
    var langChat = useTranslation(KEYS.CHAT);
    var order = props.order;
    var formRef = React.useRef();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error('No Valid');
                    if (props.onChange)
                        props.onChange(createdItem, ACTION_CHANGE.UPDATED);
                    return [2 /*return*/];
            }
        });
    }); };
    var _onChange = function (createdItem) {
        if (props.onNewChat)
            props.onNewChat(createdItem, ACTION_CHANGE.UPDATED);
    };
    var dialogBody = (React.createElement(ChatForm, { ref: formRef, requireOrder: props.requireOrder, config: config, onChange: _onChange, order: order, item: item, isModalForm: true }));
    var disabledStyles = __assign({}, (disabled && { cursor: 'not-allowed' }));
    return (React.createElement(BasicDialog, { askCanClose: true, shouldPreventClick: true, body: dialogBody, withoutWrapper: true, title: (item && item._id && langChat.t('form_btn_edit_chat')) || langChat.t('form_btn_new_chat'), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: langChat.t('form_btn_edit_chat') },
                React.createElement(IconButton, { disabled: disabled, color: 'primary', onClick: handleClickOpen, id: 'edit-btn', style: __assign({ padding: 0 }, disabledStyles) },
                    React.createElement(EditIcon, null))));
            var titleAdd = langChat.t('form_btn_new_chat');
            return ((item &&
                item._id &&
                (props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, disabled: disabled, label: langChat.t('form_btn_edit_chat'), handleClickOpen: handleClickOpen }))) : (icon))) ||
                (props.isIcon ? (React.createElement(BaseTooltip, { title: titleAdd },
                    React.createElement(IconButton, { disabled: disabled, onClick: handleClickOpen, style: __assign({ padding: 0 }, disabledStyles) },
                        React.createElement(ControlPointIcon, { style: { color: theme.palette.success.light } })))) : props.isShort ? (React.createElement(BaseTooltip, { title: titleAdd },
                    React.createElement(IconButton, { disabled: disabled, onClick: handleClickOpen, color: 'primary', style: disabledStyles },
                        React.createElement(AddCircleIcon, null)))) : (React.createElement(MainButton, { disabled: disabled, onClick: handleClickOpen, style: disabledStyles, color: 'primary', variant: 'outlined', title: titleAdd }))));
        } }));
};
export var ChatForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var _b;
    var item = _a.item, order = _a.order, requireOrder = _a.requireOrder, _c = _a.store, accountStore = _c.accountStore, chatStore = _c.chatStore, config = _a.config, isModalForm = _a.isModalForm, onChange = _a.onChange;
    var client = config.client;
    var langChat = useTranslation(KEYS.CHAT);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var warningRef = React.useRef();
    var _d = __read(React.useState(null), 2), newValueAfterWarn = _d[0], setNewValueAfterWarn = _d[1];
    var _e = __read(React.useState({}), 2), updates = _e[0], setUpdates = _e[1];
    var users = (item === null || item === void 0 ? void 0 : item.users) || defaultChatUsers({ accountStore: accountStore, order: order });
    var isForwarder = accountStore.currentCompany.type === BaseUserAppTYpe.FORWARDER;
    var isCustomerTo = users.length && users[0].appType[0] === BaseUserAppTYpe.CUSTOMER;
    var isCarrierTo = users.length && users[0].appType[0] === BaseUserAppTYpe.CARRIER;
    var _f = __read(React.useState(__assign(__assign(__assign(__assign({}, getSnapshot(OrderChat.create())), item), { description: (item === null || item === void 0 ? void 0 : item.description) || '', name: (item === null || item === void 0 ? void 0 : item.name) || '' }), (order && __assign({ order: order,
        users: users }, (users.length &&
        users[0].appType &&
        users[0].appType.length &&
        !(item === null || item === void 0 ? void 0 : item._id) && {
        name: [
            langApp.t("user_app_type_" + (isForwarder && (isCustomerTo || isCarrierTo)
                ? (isCustomerTo && BaseUserAppTYpe.CARRIER) || BaseUserAppTYpe.CUSTOMER
                : accountStore.currentCompany.type).toLowerCase()),
            langApp.t("user_app_type_" + ((_b = users[0].appType[0]) === null || _b === void 0 ? void 0 : _b.toLowerCase())),
        ]
            .filter(function (e) { return e; })
            .join('-'),
    }))))), 2), defaultValue = _f[0], setDefaultValue = _f[1];
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_a) {
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __awaiter(this, void 0, void 0, function () {
                var result, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 5, 6, 7]);
                            localStore.error = '';
                            localStore.isLoading = true;
                            result = void 0;
                            if (!!_id) return [3 /*break*/, 2];
                            return [4 /*yield*/, chatStore.createChat(input)];
                        case 1:
                            result = _b.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, chatStore.updateChat(__assign(__assign({}, input), { _id: _id }))];
                        case 3:
                            result = _b.sent();
                            _b.label = 4;
                        case 4:
                            setDefaultValue(result);
                            if (onChange)
                                onChange(result);
                            return [2 /*return*/, result];
                        case 5:
                            e_1 = _b.sent();
                            console.log(e_1);
                            return [3 /*break*/, 7];
                        case 6:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onAdd = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, ref.current.submitForm()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { innerRef: ref, initialValues: defaultValue, validationSchema: Yup.object(__assign(__assign({}, (requireOrder && {
                order: Yup.object({
                    _id: Yup.string(),
                }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                    return !!val._id;
                }),
            })), { 
                // name: Yup.string()
                //   .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                //   .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                //   .required(langForm.t('form_input_validate_required')),
                description: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')), users: Yup.array()
                    .typeError(langForm.t('form_input_validate_required'))
                    .of(Yup.lazy(function (value) {
                    if (value && Object.values(value).some(function (v) { return !(v === null || v === undefined || v === ''); })) {
                        return Yup.object({
                            _id: Yup.string().required(langForm.t('form_input_validate_required')),
                        }).required(langForm.t('form_input_validate_required'));
                    }
                    return Yup.mixed()
                        .typeError(langForm.t('form_input_validate_required'))
                        .required(langForm.t('form_input_validate_required'));
                }))
                    .min(1, langForm
                    .t('form_input_validate_field_must_have_at_least')
                    .replace(/_X_/, '1')
                    .replace(/_XX_/, langChat.t('form_new_chat_input_users').toLowerCase()))
                    .max(10, langForm
                    .t('form_input_validate_field_must_have_no_more')
                    .replace(/_X_/, '10')
                    .replace(/_XX_/, langChat.t('form_new_chat_input_users').toLowerCase())) })), onSubmit: localStore.onSubmit }, function (formik) {
            var onChange = function (e) {
                var d = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    d[_i - 1] = arguments[_i];
                }
                formik.setFieldValue(e.target.name, e.target.name === 'order' ? d[0] : e.target.value);
            };
            var onBlur = function (e) { };
            var usersProps = {
                query: FetchUsers,
                variables: {
                    filter: {
                        fields: [
                            {
                                name: 'order',
                                value: (formik.values.order && formik.values.order._id) || formik.values.order,
                            },
                        ],
                    },
                },
            };
            // if (accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER)) {
            //   Object.assign(usersProps, {
            //     query: FetchForwardUsers,
            //     variables: { filter: { fields: [{ name: 'appType', value: BaseUserAppTYpe.CUSTOMER }] } },
            //   });
            // }
            var userProps = formikAutocompleteMultiOptionsUsersProps(formik.values.users, onBlur);
            var disabled = (typeof formik.values.order !== 'string' && !formik.values.order._id) || !formik.values.order;
            if (disabled)
                userProps.autocompleteProps.disabled = true;
            return (React.createElement(Form, null,
                isForwarder ? (React.createElement(BasicDialog, { withoutWrapper: true, fullWidth: true, title: langApp.t('app_warning'), onClose: function () { return setUpdates(__assign(__assign({}, updates), { users: Date.now() })); }, dialogProps: {
                        fullWidth: true,
                        maxWidth: 'sm',
                    }, actionInit: function (onOpen, handleClose) { return React.createElement("span", { onClick: onOpen, ref: warningRef }); }, body: React.createElement(Grid, { container: true, style: { height: '100%', alignItems: 'center', justifyContent: 'center' } },
                        React.createElement(Typography, null, langForm.t('form_cu_ca_one_chat_warn'))), onOk: function () {
                        onChange({ target: { name: 'users', value: newValueAfterWarn } });
                    } })) : null,
                React.createElement(Grid, { container: true, direction: "column" },
                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(2) } }, (order && order._id && (React.createElement(FormikAutocomplete, { v17: true, onChageText: function () { return 0; }, disabled: true, autocompleteProps: {
                            defaultValue: defaultValue.order,
                            getOptionSelected: function (option, value) { return option._id === value._id; },
                            getOptionLabel: function (option) {
                                return [
                                    option.orderId,
                                    option.sorting_totalWeight,
                                    option.sorting_countryPickup,
                                    option.sorting_pickupAt,
                                    option.sorting_countryDelivery,
                                    option.sorting_deliveryAt,
                                ]
                                    .filter(function (e) { return e || ''; })
                                    .join(', ');
                            },
                        }, title: langApp.t('main_order'), variant: "outlined", margin: 'dense', name: 'order', value: formik.values.order, options: [order].map(function (_a) {
                            var _id = _a._id, option = __rest(_a, ["_id"]);
                            return ({
                                value: _id,
                            });
                        }) }))) || (React.createElement(FormikAutocompleteAsync, { query: FetchOrders, v17: true, variables: {
                            filter: {
                                fields: __spread([
                                    {
                                        name: 'all-in-progress',
                                        value: 1,
                                    }
                                ], (isForwarder
                                    ? [
                                        { name: 'all-open', value: 1, category: 1 },
                                        { name: 'viewAll', value: true },
                                    ]
                                    : [])),
                            },
                        }, title: langApp.t('main_order'), name: "order", client: client, isClient: true, variant: "outlined", margin: 'dense', filterFields: ['orderId'], fullWidth: true, autocompleteProps: {
                            disabled: !!defaultValue._id,
                            defaultValue: defaultValue.order,
                            getOptionSelected: function (option, value) { return option._id === value._id; },
                            getOptionLabel: function (option) {
                                return [
                                    option.orderId,
                                    option.sorting_totalWeight,
                                    option.sorting_countryPickup,
                                    option.sorting_pickupAt,
                                    option.sorting_countryDelivery,
                                    option.sorting_deliveryAt,
                                ]
                                    .filter(function (e) { return e || ''; })
                                    .join(', ');
                            },
                        }, onChange: onChange }))),
                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, v8: true, v17: true, key: formik.values.order + " " + (updates === null || updates === void 0 ? void 0 : updates.users), filterFields: ['firstName', 'lastName'] }, userProps, usersProps, { client: client, disabled: disabled, title: langChat.t('form_new_chat_input_users'), placeholder: langChat.t('form_new_chat_input_users'), name: "users", value: formik.values.users, onChange: function (e) {
                                var _a;
                                if (isForwarder) {
                                    var checkAppType = function (appType, users) {
                                        return users.find(function (e) { var _a; return (_a = e === null || e === void 0 ? void 0 : e.appType) === null || _a === void 0 ? void 0 : _a.includes(appType); });
                                    };
                                    var isChatWithOneSide = checkAppType(BaseUserAppTYpe.CUSTOMER, __spread([item === null || item === void 0 ? void 0 : item.createdBy], formik.values.users)) &&
                                        checkAppType(BaseUserAppTYpe.CARRIER, __spread([item === null || item === void 0 ? void 0 : item.createdBy], formik.values.users));
                                    var isNewChatWithOneSide = checkAppType(BaseUserAppTYpe.CUSTOMER, __spread([item === null || item === void 0 ? void 0 : item.createdBy], e.target.value)) &&
                                        checkAppType(BaseUserAppTYpe.CARRIER, __spread([item === null || item === void 0 ? void 0 : item.createdBy], e.target.value));
                                    var isFirstTimeChatWithBoth = isForwarder && !isChatWithOneSide && isNewChatWithOneSide;
                                    if (isFirstTimeChatWithBoth) {
                                        (_a = warningRef === null || warningRef === void 0 ? void 0 : warningRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                        setNewValueAfterWarn(e.target.value);
                                        return;
                                    }
                                }
                                onChange(e);
                            } }))),
                    !isModalForm ? (React.createElement(Grid, { container: true, justify: 'flex-end', style: { marginTop: theme.spacing(1) } },
                        React.createElement(MainButton, { onClick: onAdd, color: 'primary', title: "" + (defaultValue._id ? langChat.t('form_btn_edit_chat') : langChat.t('form_btn_new_chat')) }))) : null)));
        })));
})));
