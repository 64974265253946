import React from 'react';
import { client } from '../../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const InvoicesForm = React.lazy(() => import('@izi-logistics/common/lib/pages/Invoices/Form'));

export default function CostForm() {
  return (
    <PreloadingComponent>
      <InvoicesForm config={{ client }} />
    </PreloadingComponent>
  );
}
