var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid, makeStyles } from '@material-ui/core';
import { TextOverflow } from '../../components';
import { theme } from '../../theme';
import { TERM_STATUS } from '../../stores';
import React from 'react';
import { i18n, KEYS } from '../../i18n';
import BaseChip from '../../components/BaseChip';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        padding: 0,
        '& .MuiChip-label': {
            padding: theme.spacing(1),
        },
    },
}); });
export default function TermStatus(_a) {
    var record = _a.record, v1 = _a.v1, v2 = _a.v2, props = __rest(_a, ["record", "v1", "v2"]);
    var classes = useStyles();
    var _id = Object.keys(TERM_STATUS).find(function (e) { return TERM_STATUS[e] === record.status; });
    var label = i18n.getResource(i18n.language, KEYS.APP, "main_status_" + _id.toLowerCase()) ||
        i18n.getResource(i18n.language, KEYS.OFFER_FORM, "offer_" + _id.toLowerCase()) ||
        _id;
    return (React.createElement(BaseChip, { className: classes.root, label: React.createElement(Grid, { container: true, alignItems: 'center', style: __assign({}, (v2 && { width: 65 })) },
            React.createElement(TextOverflow, { style: { color: theme.palette.common.white, margin: 'auto' } }, label)), style: __assign(__assign(__assign({}, props.style), (v1 && { height: theme.spacing(2.8) })), { backgroundColor: record.status === TERM_STATUS.APPROVED
                ? theme.palette.success.light
                : [TERM_STATUS.REJECTED, TERM_STATUS.CANCELED].includes(record.status)
                    ? theme.palette.error.light
                    : theme.palette.warning.light, color: theme.palette.common.white }) }));
}
