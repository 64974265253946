export var BaseUserAppTYpe;
(function (BaseUserAppTYpe) {
    BaseUserAppTYpe["SYSTEM"] = "SYSTEM";
    BaseUserAppTYpe["CUSTOMER"] = "CUSTOMER";
    BaseUserAppTYpe["FORWARDER"] = "FORWARDER";
    BaseUserAppTYpe["CARRIER"] = "CARRIER";
    BaseUserAppTYpe["WAREHOUSE"] = "WAREHOUSE";
    BaseUserAppTYpe["SENDER"] = "SENDER";
    BaseUserAppTYpe["CONSIGNEE"] = "CONSIGNEE";
    BaseUserAppTYpe["AFFILIATE"] = "AFFILIATE";
})(BaseUserAppTYpe || (BaseUserAppTYpe = {}));
export var USER_ROLE;
(function (USER_ROLE) {
    USER_ROLE["ADMIN"] = "ADMIN";
    USER_ROLE["MEMBER"] = "MEMBER";
})(USER_ROLE || (USER_ROLE = {}));
export var CompanyStatus;
(function (CompanyStatus) {
    CompanyStatus["ACTIVE"] = "ACTIVE";
    CompanyStatus["INACTIVE"] = "INACTIVE";
})(CompanyStatus || (CompanyStatus = {}));
