import React from 'react';
import { AssignedUnLoadedIcon, AssignedUnLoadingTimeIcon, AssignedInTransitIcon, OfferAcceptedLogoIcon, AssignedTransportIcon, ConfirmedIcon, AssignedLoadingTimeIcon, } from '../../icons';
import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';
export var allOptions = [
    {
        value: 'notify_customer_quote_updated',
        icon: React.createElement(OfferAcceptedLogoIcon, null),
    },
    {
        value: 'notify_carrier_order_quote_approved',
        icon: React.createElement(ConfirmedIcon, null),
    },
    {
        value: 'notify_customer_order_quote_assign_transport',
        icon: React.createElement(AssignedTransportIcon, null),
    },
    {
        value: 'notify_customer_order_quote_loading_time_booked',
        icon: React.createElement(AssignedLoadingTimeIcon, null),
    },
    {
        value: 'notify_customer_order_quote_unloading_time_booked',
        icon: React.createElement(AssignedUnLoadingTimeIcon, null),
    },
    {
        value: 'notify_customer_order_quote_loading_time_changed',
        icon: React.createElement(AssignedLoadingTimeIcon, null),
    },
    {
        value: 'notify_customer_order_quote_unloading_time_changed',
        icon: React.createElement(AssignedUnLoadingTimeIcon, null),
    },
    {
        value: 'notify_order_quote_loading_at',
        icon: React.createElement(AssignedInTransitIcon, null),
    },
    {
        value: 'notify_order_quote_loading_at_by_system',
        icon: React.createElement(AssignedInTransitIcon, null),
    },
    {
        value: 'notify_order_quote_un_loading_at',
        icon: React.createElement(AssignedUnLoadedIcon, null),
    },
    {
        value: 'notify_order_quote_un_loading_at_by_system',
        icon: React.createElement(AssignedUnLoadedIcon, null),
    },
    {
        value: 'notify_order_quote_no_loading_at',
        icon: (React.createElement("div", { style: { position: 'relative' } },
            React.createElement(AssignedInTransitIcon, null),
            React.createElement(CloseIcon, { style: { position: 'absolute', right: -6, top: -6, width: 12, height: 12 } }))),
    },
    {
        value: 'notify_order_quote_no_un_loading_at',
        icon: (React.createElement("div", { style: { position: 'relative' } },
            React.createElement(AssignedUnLoadedIcon, null),
            React.createElement(CloseIcon, { style: { position: 'absolute', right: -6, top: -6, width: 12, height: 12 } }))),
    },
    {
        value: 'notify_carrier_order_canceled',
        icon: React.createElement(BlockIcon, null),
    },
];
