var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
var useStylesV1 = makeStyles(function (theme) { return ({
    arrow: {
        '&:before': {
            //
            border: "1px solid " + theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.light,
        },
    },
    tooltip: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '5px',
        border: "1px solid " + theme.palette.secondary.main,
    },
}); });
export default function BaseTooltip(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    var classes = useStylesV1();
    if (props.disabled) {
        return children;
    }
    return (React.createElement(Tooltip, __assign({ classes: { arrow: classes.arrow, tooltip: classes.tooltip }, arrow: true, TransitionComponent: Zoom, PopperProps: { style: { zIndex: 9999999 } } }, props), children));
}
