var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import { ORDER_PREPATING_STATUS } from './types.v2';
import { ORDER_ALL_BASE } from './index';
export var FetchOrderPreparingStatuses = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderPreparingStatuses($filter: MainFilter) {\n      orderPreparingStatuses(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderPreparingStatuses($filter: MainFilter) {\n      orderPreparingStatuses(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_PREPATING_STATUS);
export var UpdateOrderPreparingStatus = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateOrderPreparingStatuses($input: OrderPreparingStatusesInputUpdate) {\n        updateOrderPreparingStatuses(input: $input) {\n             ", "\n        }\n    }\n"], ["\n    mutation updateOrderPreparingStatuses($input: OrderPreparingStatusesInputUpdate) {\n        updateOrderPreparingStatuses(input: $input) {\n             ", "\n        }\n    }\n"])), ORDER_PREPATING_STATUS);
export var UpdateOrderPreparing = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateOrderPreparing($input: OrderPreparing!) {\n        updateOrderPreparing(input: $input) {\n             ", "\n        }\n    }\n"], ["\n    mutation updateOrderPreparing($input: OrderPreparing!) {\n        updateOrderPreparing(input: $input) {\n             ", "\n        }\n    }\n"])), ORDER_ALL_BASE);
var templateObject_1, templateObject_2, templateObject_3;
