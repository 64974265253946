var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { priceValue, TextOverflow } from '../../../components';
import { KEYS, i18n } from '../../../i18n';
import { useSectionRowStyles } from '../../../components/SectionDetails/styles';
import PreloadingComponent from '../../../components/PreloadingComponent'; //
var NumberToWords = React.lazy(function () { return import('./NumberToWords'); });
export default (function (_a) {
    var _b, _c, _d, _e, _f;
    var item = _a.item, services = _a.services, isPreview = _a.isPreview, props = __rest(_a, ["item", "services", "isPreview"]);
    var className = useSectionRowStyles();
    var theme = useTheme();
    var language = isPreview ? i18n.language : item.printLanguage;
    var allExempt = services
        .filter(function (e) { return !(e.vat && e.vat._id) && e.price && e.quantity; })
        .map(function (e) {
        var total = e.quantity * e.price || 0;
        var discount = item.isAllDiscount
            ? item.discountUnit === '%'
                ? total * (item.discount / 100)
                : item.discount
            : e.discountUnit === '%'
                ? total * (e.discount / 100)
                : e.discount;
        return total - discount;
    })
        .reduce(function (a, b) { return a + b; }, 0);
    var allWithoutVat = services
        .filter(function (e) { return e.vat && e.vat._id && e.price && e.quantity; })
        .map(function (e) {
        var total = e.quantity * (e.price || 0);
        var discount = item.isAllDiscount
            ? item.discountUnit === '%'
                ? total * (item.discount / 100)
                : item.discount
            : e.discountUnit === '%'
                ? total * (e.discount / 100)
                : e.discount;
        total -= discount;
        return total;
    })
        .reduce(function (a, b) { return a + b; }, 0);
    var allVatAmount = services
        .filter(function (e) { return e.vat && e.vat._id && e.price && e.quantity; })
        .map(function (e) {
        var vatPercent = ((e.vat && e.vat.value) || 0) / 100;
        var total = e.quantity * (e.price || 0);
        var discount = item.isAllDiscount
            ? item.discountUnit === '%'
                ? total * (item.discount / 100)
                : item.discount
            : e.discountUnit === '%'
                ? total * (e.discount / 100)
                : e.discount;
        total -= discount;
        return total * vatPercent;
    })
        .reduce(function (a, b) { return a + b; }, 0);
    var totalSum = allExempt + allWithoutVat + allVatAmount;
    var allDiscount = services
        .filter(function (e) { return e.price && e.quantity; })
        .map(function (e) {
        var total = e.quantity * (e.price || 0);
        return item.isAllDiscount
            ? item.discountUnit === '%'
                ? total * (item.discount / 100)
                : item.discount
            : e.discountUnit === '%'
                ? total * (e.discount / 100)
                : e.discount;
    })
        .reduce(function (a, b) { return a + b; }, 0);
    var vatPercent = ((item.services || []).find(function (e) { return e.vat && e.vat._id; }) &&
        (item.services.find(function (e) { return e.vat && e.vat.value; }) || { vat: { value: null } }).vat.value) ||
        '';
    var numberToWords = (React.createElement(PreloadingComponent, null,
        React.createElement(NumberToWords, { value: totalSum, lang: language, currency: item.currency })));
    var totalInWords = (React.createElement("div", { style: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 32,
        } },
        React.createElement("span", { style: { fontSize: '0.9rem', paddingLeft: 0, color: props.v1 ? theme.palette.primary.main : 'black' } },
            i18n.getResource(language, KEYS.FORM, 'form_invoice_total_in_words'),
            ":"),
        React.createElement("span", { style: { fontSize: '0.9rem', paddingLeft: 10, color: props.v1 ? theme.palette.primary.main : 'black' } }, numberToWords)));
    if (props.v1) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    letterSpacing: '0.01em',
                } }, [
                {
                    label: i18n.getResource(language, KEYS.FORM, 'form_invoice_create_discount'),
                    value: allDiscount,
                    text: priceValue({ amount: allDiscount, currency: item.currency }),
                },
                {
                    label: i18n.getResource(language, KEYS.FORM, 'form_invoice_exempt'),
                    value: allExempt,
                    text: priceValue({ amount: allExempt, currency: item.currency }),
                },
                {
                    label: i18n
                        .getResource(language, KEYS.FORM, 'form_invoice_without_vat_amount')
                        .replace(/_X_/, vatPercent),
                    value: allWithoutVat,
                    text: priceValue({ amount: allWithoutVat, currency: item.currency }),
                },
                {
                    label: i18n.getResource(language, KEYS.FORM, 'form_invoice_vat'),
                    value: allVatAmount,
                    text: priceValue({ amount: allVatAmount, currency: item.currency }),
                },
                {
                    label: i18n.getResource(language, KEYS.FORM, 'form_invoice_total_sum'),
                    value: totalSum,
                    text: priceValue({ amount: totalSum, currency: item.currency }),
                },
            ]
                .filter(function (e) { return e.value; })
                .map(function (e, index) { return (React.createElement("div", { key: e.label, style: {
                    width: '45%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: index === 0 ? 0 : theme.spacing(1.5),
                } },
                React.createElement("label", { style: { fontWeight: 600, textAlign: 'right', fontSize: '15px' } }, e.label),
                React.createElement("span", { style: { fontWeight: 700, paddingRight: 0, paddingLeft: 8, textAlign: 'right', fontSize: '15px' } }, e.text))); })),
            React.createElement("div", { style: {
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    margin: theme.spacing(0, 1.5),
                    letterSpacing: '0.01em',
                    borderBottom: "1px solid " + theme.palette.primary.main,
                    padding: '1rem 0',
                } },
                React.createElement("span", { style: {
                        fontWeight: 700,
                        fontSize: '17px',
                        paddingLeft: 0,
                        color: props.v1 ? theme.palette.primary.main : 'black',
                    } },
                    i18n.getResource(language, KEYS.FORM, 'form_invoice_total_in_words'),
                    ":",
                    ' '),
                React.createElement("span", { style: {
                        fontSize: '12px',
                        paddingLeft: 10,
                        paddingTop: 3,
                        fontWeight: 700,
                        color: props.v1 ? theme.palette.primary.main : 'black',
                    } }, numberToWords))));
    }
    var sizeLabel = isPreview ? 10 : 9;
    var sizeValue = isPreview ? 2 : 3;
    var mainSize = isPreview ? 12 : 4;
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var otherJustifyContent = isSmall ? 'flex-start' : 'flex-end';
    if (isSmall) {
        sizeLabel = 6;
        sizeValue = 6;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true, container: true, justify: "flex-end" },
            React.createElement(Grid, { xs: 12, md: mainSize, container: true, justify: "flex-end" },
                (allDiscount && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', className: clsx((_b = {}, _b[className.root] = isPreview, _b)) },
                    React.createElement(Grid, { xs: sizeLabel, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'right' } }, i18n.getResource(language, KEYS.FORM, 'form_invoice_create_discount'))),
                    React.createElement(Grid, { xs: sizeValue, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { style: { fontWeight: 700 }, variant: 'subtitle1' }, priceValue({ amount: allDiscount, currency: item.currency })))))) ||
                    null,
                (allExempt && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', className: clsx((_c = {}, _c[className.root] = isPreview, _c)) },
                    React.createElement(Grid, { xs: sizeLabel, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'right' } }, i18n.getResource(language, KEYS.FORM, 'form_invoice_exempt'))),
                    React.createElement(Grid, { xs: sizeValue, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { style: { fontWeight: 700 }, variant: 'subtitle1' }, priceValue({ amount: allExempt, currency: item.currency })))))) ||
                    null,
                (allWithoutVat && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', className: clsx((_d = {}, _d[className.root] = isPreview, _d)) },
                    React.createElement(Grid, { xs: sizeLabel, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'right' } }, i18n.getResource(language, KEYS.FORM, 'form_invoice_without_vat_amount').replace(/_X_/, vatPercent))),
                    React.createElement(Grid, { xs: sizeValue, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { style: { fontWeight: 700 }, variant: 'subtitle1' }, priceValue({ amount: allWithoutVat, currency: item.currency })))))) ||
                    null,
                (allVatAmount && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', className: clsx((_e = {}, _e[className.root] = isPreview, _e)) },
                    React.createElement(Grid, { xs: sizeLabel, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'right' } }, i18n.getResource(language, KEYS.FORM, 'form_invoice_vat_amount'))),
                    React.createElement(Grid, { xs: sizeValue, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { style: { fontWeight: 700 }, variant: 'subtitle1' }, priceValue({ amount: allVatAmount, currency: item.currency })))))) ||
                    null,
                (totalSum && (React.createElement(Grid, { container: true, justify: 'flex-end', alignItems: 'center', className: clsx((_f = {}, _f[className.root] = isPreview, _f)) },
                    React.createElement(Grid, { xs: sizeLabel, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'right' } }, i18n.getResource(language, KEYS.FORM, 'form_invoice_total_sum'))),
                    React.createElement(Grid, { xs: sizeValue, container: true, justify: otherJustifyContent },
                        React.createElement(TextOverflow, { style: { fontWeight: 700 }, variant: 'subtitle1' }, priceValue({ amount: totalSum, currency: item.currency })))))) ||
                    null)),
        totalInWords));
});
