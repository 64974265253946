var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
export var BROWSER_STORAGE = {
    NO_BRAND_KEY: 'noBrand',
};
var isDev = !!(process.env.REACT_APP_API_URL || '').match(/-master|-stag|localhost|127.0.0.1/);
var BrowserStorage = /** @class */ (function () {
    function BrowserStorage() {
        this.storage =
            new URLSearchParams(window.location.search).get('temp') ||
                window.storeType === 1 ||
                sessionStorage.getItem('izi-temp')
                ? sessionStorage
                : localStorage;
    }
    BrowserStorage.prototype.get = function (key) {
        return this.storage.getItem("izi-" + key);
    };
    BrowserStorage.prototype.getUserItem = function (key) {
        return this.storage.getItem("izi-" + key + "-" + (this.getItem('user-id') || ''));
    };
    BrowserStorage.prototype.setUserItem = function (key, value) {
        return this.storage.setItem("izi-" + key + "-" + (this.getItem('user-id') || ''), typeof value === 'string' ? value : JSON.stringify(value));
    };
    BrowserStorage.prototype.removeUserItem = function (key) {
        return this.storage.removeItem("izi-" + key + "-" + (this.getItem('user-id') || ''));
    };
    BrowserStorage.prototype.getItem = function (key) {
        if (key === 'testing' && !isDev) {
            return '';
        }
        return this.storage.getItem("izi-" + key);
    };
    BrowserStorage.prototype.set = function (key, value) {
        return this.storage.setItem("izi-" + key, typeof value === 'string' ? value : JSON.stringify(value));
    };
    BrowserStorage.prototype.setItem = function (key, value) {
        return this.storage.setItem("izi-" + key, typeof value === 'string' ? value : JSON.stringify(value));
    };
    BrowserStorage.prototype.removeItem = function (key) {
        return this.storage.removeItem("izi-" + key);
    };
    BrowserStorage.prototype.keys = function () {
        return this.storage;
    };
    BrowserStorage.prototype.hasKeys = function () {
        for (var key in this.storage) {
            return 1;
        }
    };
    BrowserStorage.prototype.clearKeys = function (except, isAll) {
        if (except === void 0) { except = []; }
        var excludeKeys = isAll ? [] : __spread(['booking_access_token'], except).map(function (e) { return "izi-" + e; });
        for (var key in this.storage) {
            if (!excludeKeys.includes(key)) {
                this.storage.removeItem(key);
            }
        }
    };
    return BrowserStorage;
}());
export var browserStorage = new BrowserStorage();
