var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { BasicDialog } from '../../components/Dialog';
import { IconButton, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { toast } from 'react-toastify';
import { LogInAs } from '../../graphql';
import BaseTooltip from '../../components/BaseTooltip';
import { i18n, KEYS } from '../../i18n';
import { browserStorage } from '../../browserStorage';
export var LoginAs = function (_a) {
    var record = _a.record, config = _a.config, handleClickOpen = _a.handleClickOpen;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _onLoginAs = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: LogInAs,
                            variables: {
                                input: {
                                    email: record.email,
                                },
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    res = _a;
                    window.open(res.data.logInAs.appLink +
                        ("?isManual=1&temp=1&token=" + res.data.logInAs.token.replace("Bearer ", '') + "&client_id=" + browserStorage.getItem('client-id')), "Logged in as " + (record.firstName || '') + " " + (record.lastName || '') + "(" + record.email + ")", 'location=yes,scrollbars=yes,status=yes,resizable=yes');
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(BasicDialog, { body: React.createElement(Typography, { variant: 'subtitle1', dangerouslySetInnerHTML: {
                __html: (i18n.getResource(i18n.language, KEYS.APP, 'login_as_ask') || '').replace(/_X_/, "<b>" + record.email + "</b>"),
            }, style: { margin: 'auto' } }), onOk: _onLoginAs, title: i18n.getResource(i18n.language, KEYS.APP, 'login_as'), withoutWrapper: true, actionInit: handleClickOpen
            ? handleClickOpen
            : function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.APP, 'login_as') },
                React.createElement(IconButton, { disabled: loading, "aria-label": "menu", color: 'primary', onClick: handleClickOpen, style: { padding: 0 } },
                    React.createElement(PersonIcon, null)))); } }));
};
