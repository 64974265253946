var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Divider, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { theme } from '../../../theme';
import { OrderPaymentAction } from './OrderPaymentActions';
import RoomIcon from '@material-ui/icons/Room';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe, USER_ROLE } from '../../../stores/common';
import { OrderRequestPaymentAction } from './OrderRequestPaymentAction';
import { OrderQuoteStatistics } from '../dialogs/OrderQuoteStatistics';
import { OrderCancel } from './OrderCancel';
import { OrderRestore } from './OrderEditAfterDelivered';
import { OrderQuoteRequestChanges } from './OrderQuoteRequestChanges';
import OrderCMR from './OrderCMR';
import OrderPDFV1 from './OrderPDFV1';
import SendInvoice from '../../../pages/Invoices/Send';
import OrderMoreView from './OrderMoreView';
import OrderEditView from './OrderEditView';
import { DeleteDialog } from '../../Dialog';
import { OrderQuoteFastPaymentStatus, OrderQuoteStatus, OrderStatus } from '../../../models';
import PreloadingComponent from '../../PreloadingComponent';
import OrderUserRating from './OrderUserRating';
import { checkIfOrderDetailsIsHide } from '../../../utils1';
import OrderMakeTemplateView from './OrderMakeTemplateView';
import { canEdit } from '../form/utils';
import OrderArchive from './OrderArchive';
import { OrderUnarchive } from './OrderUnarchive';
import OrderFastPayment from './OrderFastPayment';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import { getSnapshot } from 'mobx-state-tree';
import { Issues } from '../../../models/Issues';
import FormItem from '../../../pages/Issues/FormItem';
var SectionHistory = React.lazy(function () { return import('../../SectionDetails/SectionHistory'); });
var OrderSyncRemove = React.lazy(function () { return import('./OrderSyncRemove'); });
var OrderSync = React.lazy(function () { return import('./OrderSync'); });
var OrderRoutesMenuDocuments = React.lazy(function () { return import('./OrderRoutesMenuDocuments'); });
var OrderNotifyPublished = React.lazy(function () { return import('./OrderNotifyPublished'); });
var OrderShareComponent = React.lazy(function () { return import('./OrderShare'); });
var LinkToBook = React.lazy(function () { return import('./LinkToBook'); });
var OrderPostToMyCA = React.lazy(function () { return import('./OrderPostToMyCA'); });
var ResendNotificaion = React.lazy(function () { return import('./ResendNotifications'); });
export var OrderQuoteActions = inject('store')(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var additionalContent = _a.additionalContent, props = __rest(_a, ["additionalContent"]);
    var _y = props.store.accountStore, currentUser = _y.currentUser, currentCompany = _y.currentCompany, hideActions = props.hideActions;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var isCompaniesOrder = ((props.order && props.order.companies) || []).includes(currentCompany._id);
    var isCompanyOrder = currentCompany._id === props.order.createdBy.company._id || currentCompany._id === props.order.manager.company._id;
    var isHide = checkIfOrderDetailsIsHide(props.store, props.order);
    var canEditOrder = canEdit(props.order, props.store, { inProgress: true });
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) || isForwarder; //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCAAllowed = !isCarrier || !((_c = (_b = props.order) === null || _b === void 0 ? void 0 : _b.approvedCarrier) === null || _c === void 0 ? void 0 : _c._id) || currentCompany._id === props.order.approvedCarrier._id;
    if (props.quote)
        props.order.latestQuote = __assign(__assign({}, props.order.latestQuote), props.quote);
    var isFoManager = (_d = props.order) === null || _d === void 0 ? void 0 : _d.forwarders.find(function (e) { var _a; return (((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) || e.company) === currentCompany._id; });
    var isCaApproved = ((_e = props.order.approvedCarrier) === null || _e === void 0 ? void 0 : _e._id) && currentCompany._id === ((_g = (_f = props.order) === null || _f === void 0 ? void 0 : _f.approvedCarrier) === null || _g === void 0 ? void 0 : _g._id);
    var orderWithApprovedQuote = props.quote &&
        (isCAAllowed || isCompanyOrder || isFoManager) &&
        [OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(props.quote.status);
    var canViewOffer = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_view_offer); });
    var isWarehouse = currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isSenderConsignee = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
        return currentUser.appType.includes(t);
    });
    var customerOrderAvailable = (isCustomer || ((isWarehouse || isSenderConsignee) && isCompaniesOrder)) &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(props.order.status);
    var senderConsigneeOrderAvailable = (isCustomer || isForwarder || isWarehouse || isSenderConsignee) &&
        isCompaniesOrder &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(props.order.status);
    var customerAvailable = isCustomer && props.order.status === OrderStatus.ApprovedQuote;
    var customerAvailableFinished = isCustomer && props.order.status === OrderStatus.Finished;
    var isOwnOrderFinished = props.order &&
        props.order.createdBy &&
        props.order.publishedAt &&
        (customerAvailableFinished || customerAvailable) &&
        ([props.order.createdBy._id || props.order.createdBy].includes(currentUser._id) ||
            [props.order.manager._id || props.order.manager].includes(currentUser._id) ||
            canEditOrder ||
            currentUser.roles.includes(USER_ROLE.ADMIN));
    var order = props.order;
    var lastTransportation = order && order.transportations && order.transportations[order.transportations.length - 1];
    var isDeliveredLastTransportation = lastTransportation === null || lastTransportation === void 0 ? void 0 : lastTransportation.unloadedAt;
    var isInProgressOrders = location.href.toLowerCase().match('/in-progress');
    var isPreparation = location.href.toLowerCase().match('/preparation');
    var isOpenOrders = location.href.toLowerCase().match('/open-orders');
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var timacomSynced;
    try {
        timacomSynced = JSON.parse(((_h = order.sync_logs) === null || _h === void 0 ? void 0 : _h.length) && order.sync_logs[0].changes).status === 'STORED';
    }
    catch (e) { }
    var gpsDetails;
    try {
        gpsDetails = JSON.parse((_k = (_j = props.itemRecordOrder) === null || _j === void 0 ? void 0 : _j.latestQuote) === null || _k === void 0 ? void 0 : _k.p44ShipmentLog.changes);
    }
    catch (e) { }
    var content = (React.createElement(React.Fragment, null,
        !(isCarrier || isCaApproved) && ((_l = gpsDetails === null || gpsDetails === void 0 ? void 0 : gpsDetails.result) === null || _l === void 0 ? void 0 : _l.mapUrl) && isCAAllowed ? (React.createElement(PreloadingComponent, null,
            React.createElement(OrderRouteMenuItem, __assign({}, {
                v1: true,
                icon: (React.createElement(BaseTooltip, { title: langApp.t('traking_order') },
                    React.createElement(IconButton, { color: 'primary' },
                        React.createElement(RoomIcon, null)))),
                handleClickOpen: function (ev) {
                    var _a;
                    window.open((_a = gpsDetails === null || gpsDetails === void 0 ? void 0 : gpsDetails.result) === null || _a === void 0 ? void 0 : _a.mapUrl, '_blank');
                },
            })))) : null,
        props.order.approvedCarrier &&
            (isFoManager || isCompanyOrder) &&
            props.order.noQuotes &&
            [OrderStatus.ApprovedQuote].includes(props.order.status) ? (React.createElement(PreloadingComponent, null,
            React.createElement(LinkToBook, { quote: props.quote, order: props.order, config: props.config }))) : null,
        !isHide && isCaApproved && [OrderStatus.ApprovedQuote].includes(props.order.status) ? (React.createElement(OrderRequestPaymentAction, { quote: props.quote, order: props.order, config: props.config })) : null,
        !isHide &&
            isCaApproved &&
            [OrderStatus.ApprovedQuote].includes(props.order.status) &&
            (((_o = (_m = props.order.createdBy) === null || _m === void 0 ? void 0 : _m.company) === null || _o === void 0 ? void 0 : _o.isFastPayment) || ((_r = (_q = (_p = props.order.forwarders[0]) === null || _p === void 0 ? void 0 : _p.company) === null || _q === void 0 ? void 0 : _q.settings) === null || _r === void 0 ? void 0 : _r.isDefaultFastPayment)) &&
            ((_t = (_s = props.order) === null || _s === void 0 ? void 0 : _s.latestQuote) === null || _t === void 0 ? void 0 : _t.fastPaymentStatus) === OrderQuoteFastPaymentStatus.Available ? (React.createElement(OrderFastPayment, { quote: props.order.latestQuote, onChange: props.onChangeV1, order: props.order, config: props.config })) : null,
        isCarrier && isCAAllowed && [OrderStatus.ApprovedQuote].includes(order.status) ? (React.createElement(OrderQuoteRequestChanges, { quote: props.quote, order: props.order, config: props.config })) : null,
        ((isPreparation || isInProgressOrders) &&
            (order.approvedCarrier || order.hasPreparing || order.preparedAt) &&
            (isFoManager || isCompanyOrder) && (React.createElement(PreloadingComponent, null,
            React.createElement(ResendNotificaion, { quote: order.latestQuote, order: order, config: props.config })))) ||
            null,
        isOpenOrders &&
            !isHide &&
            (isCompanyOrder || isFoManager) &&
            [OrderStatus.WaitingQuotes, OrderStatus.AcceptedQuote, OrderStatus.HasQuotes].includes((_u = props.order) === null || _u === void 0 ? void 0 : _u.status) ? (React.createElement(PreloadingComponent, null,
            React.createElement(OrderNotifyPublished, { order: props.order, config: props.config }))) : null,
        (!isHide && customerOrderAvailable) || senderConsigneeOrderAvailable ? (React.createElement(OrderCMR, { order: props.order, config: props.config, onChange: props.onChange })) : null,
        !isHide && (isCompanyOrder || isFoManager) ? React.createElement(OrderPDFV1, { order: props.order, config: props.config }) : null,
        orderWithApprovedQuote ? (React.createElement(PreloadingComponent, null,
            React.createElement(OrderRoutesMenuDocuments, { order: props.order, record: props.quote, config: props.config }))) : null,
        ([BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CUSTOMER].find(function (t) {
            return currentUser.appType.includes(t);
        }) &&
            isCAAllowed &&
            !isHide && React.createElement(OrderPaymentAction, { order: props.order, config: props.config })) ||
            null,
        props.quote &&
            !isHide &&
            [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(props.order.status) &&
            (isCaApproved || isFoManager) ? (React.createElement(SendInvoice, { order: props.order, quote: props.quote, config: props.config })) : null,
        !isHide && (customerAvailable || isFoManager) ? (React.createElement(OrderQuoteStatistics, { order: props.order, config: props.config, quote: props.quote })) : null,
        !isHide && !isCarrier ? React.createElement(OrderMakeTemplateView, { order: props.order, store: props.store }) : null,
        !isHide && (isFoManager || isCompanyOrder) && canEditOrder ? React.createElement(OrderEditView, { record: props.order }) : null,
        (!isHide && React.createElement(OrderCancel, { order: props.order, config: props.config, onChange: props.onChange })) || null,
        !isHide && isOwnOrderFinished ? React.createElement(OrderRestore, { order: props.order, config: props.config }) : null,
        !isHide && (isFoManager || isCompanyOrder) ? (React.createElement(OrderArchive, { order: props.order, config: props.config, onChange: props.onChange })) : null,
        !isHide && (isFoManager || isCompanyOrder) ? (React.createElement(OrderUnarchive, { order: props.order, config: props.config, onChange: props.onChange })) : null,
        !isHide && isCompanyOrder && !!((_v = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _v === void 0 ? void 0 : _v.approvedAt) ? (React.createElement(FormItem, { v3: true, label: true, withText: true, item: __assign(__assign({}, getSnapshot(Issues.create())), (parent && { parent: order.latestQuote._id, parent_collection_name: 'order_quotes' })), onChange: props.onChangeV2, config: props.config })) : null,
        !isHide && (isFoManager || isCompanyOrder) && canEditOrder && props.onDeleteItem && (React.createElement(DeleteDialog, { value: props.order.orderId, onOk: function (e) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, props.onDeleteItem(e, props.order)];
            }); }); } })),
        (!hideActions && orderWithApprovedQuote && canViewOffer && (React.createElement(OrderMoreView, { record: props.quote, order: props.order }))) ||
            null,
        (!isHide &&
            !timacomSynced &&
            (currentCompany.sync.includes(1) || location.href.match(/localhost|127.0.0.1/gi)) &&
            [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(props.order.status) &&
            isFoManager && React.createElement(OrderSync, { config: props.config, order: props.order })) ||
            null,
        (!timacomSynced &&
            !isHide &&
            currentCompany.sync.includes(1) && ((_w = props.order.approvedCarrier) === null || _w === void 0 ? void 0 : _w._id) &&
            isFoManager && React.createElement(OrderSync, { config: props.config, order: props.order })) ||
            null,
        (timacomSynced &&
            !isHide &&
            (currentCompany.sync.includes(1) || 1) && ((_x = props.order.approvedCarrier) === null || _x === void 0 ? void 0 : _x._id) &&
            isFoManager && React.createElement(OrderSyncRemove, { config: props.config, order: props.order, onChange: props.onChangeV1 })) ||
            null,
        (!isHide &&
            props.order &&
            props.order.latestQuote &&
            props.order.createdBy &&
            props.order.latestQuote.createdBy &&
            props.order.publishedAt &&
            (isDeliveredLastTransportation || [OrderStatus.Finished].includes(props.order.status)) && (React.createElement(OrderUserRating, { onChangeV1: props.onChangeV1, quote: props.quote, order: props.order, config: props.config }))) ||
            null,
        (!isHide &&
            !props.order.isTemplate &&
            [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(props.order.status) && (React.createElement(OrderShareComponent, { order: props.order, config: props.config }))) ||
            null,
        (!isHide &&
            !props.order.isTemplate &&
            isCompanyOrder &&
            [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(props.order.status) && (React.createElement(PreloadingComponent, null,
            React.createElement(OrderPostToMyCA, { order: props.order, config: props.config, onChange: props.onChangeV1 })))) ||
            null,
        (!isHide && (React.createElement(PreloadingComponent, null,
            React.createElement(SectionHistory, { v1: true, filters: {
                    fields: [
                        { name: 'orders', value: [props.order._id] },
                        { name: 'type', value: 2 },
                    ],
                }, type: 1, record: props.order, order: props.order })))) ||
            null,
        (!isHide && additionalContent) || null));
    if (props.noParent)
        return content;
    return (React.createElement(Grid, { container: true, style: __assign(__assign({}, props.style), { padding: theme.spacing(isSmall ? 1 : 2) }), justify: isSmall ? 'center' : 'flex-end', alignItems: 'center' },
        React.createElement(Divider, { style: { margin: theme.spacing(2, 0) } }),
        content));
});
