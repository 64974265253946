export var GuideTypes = {
    CARRIER_OPEN_ORDERS_LIST: 'CARRIER_OPEN_ORDERS_LIST',
    CARRIER_CREATE_OFFER: 'CARRIER_CREATE_OFFER',
    CARRIER_RECEIVE_COUNTER_OFFER: 'CARRIER_RECEIVE_COUNTER_OFFER',
    CARRIER_ACCEPT_DECLINE_COUNTER_OFFER: 'CARRIER_ACCEPT_DECLINE_COUNTER_OFFER',
    CARRIER_BOOKING_TIME_AND_TRUCK: 'CARRIER_BOOKING_TIME_AND_TRUCK',
    CARRIER_MARK_PICKED_UP_DELIVERED: 'CARRIER_MARK_PICKED_UP_DELIVERED',
    CARRIER_JOIN_P44: 'CARRIER_JOIN_P44',
    CARRIER_SYNC_P44_COMPANY_CODE: 'CARRIER_SYNC_P44_COMPANY_CODE',
    CARRIER_UPLOAD_OFFER_DOCUMENT: 'CARRIER_UPLOAD_OFFER_DOCUMENT',
};
export var getTourSteps = function (type, i18n, KEYS) {
    var _a;
    var openOrdersSelector = "[id=\"base-table-" + i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_open_orders_title') + "-body\"]";
    var acceptDeclineCounterOfferStep = {
        selector: openOrdersSelector + " [id=\"submitted-offers\"] [id=\"order-offer-actions-counter-offer\"]",
        type: GuideTypes.CARRIER_ACCEPT_DECLINE_COUNTER_OFFER,
    };
    var syncP44CompanyCodeStep = {
        selector: "[id=\"order-gps-tracking-need-code\"]",
        type: GuideTypes.CARRIER_SYNC_P44_COMPANY_CODE,
    };
    var TourSteps = (_a = {},
        _a[GuideTypes.CARRIER_OPEN_ORDERS_LIST] = [
            {
                type: GuideTypes.CARRIER_OPEN_ORDERS_LIST,
                selector: openOrdersSelector + " .base-list-item",
            },
        ],
        _a[GuideTypes.CARRIER_CREATE_OFFER] = [
            {
                selector: openOrdersSelector + " [id=\"form-order-offer-create\"]",
                type: GuideTypes.CARRIER_CREATE_OFFER,
            },
        ],
        _a[GuideTypes.CARRIER_RECEIVE_COUNTER_OFFER] = [
            {
                selector: openOrdersSelector + " [id=\"submitted-offers\"] [data-id=\"offer-record-counter-offer\"]",
                type: GuideTypes.CARRIER_RECEIVE_COUNTER_OFFER,
            },
            acceptDeclineCounterOfferStep,
        ],
        _a[GuideTypes.CARRIER_ACCEPT_DECLINE_COUNTER_OFFER] = [acceptDeclineCounterOfferStep],
        _a[GuideTypes.CARRIER_BOOKING_TIME_AND_TRUCK] = [
            {
                selector: "[id=\"simple-tabpanel-0\"] [data-id=\"order-booking-area\"]",
                type: GuideTypes.CARRIER_BOOKING_TIME_AND_TRUCK,
            },
        ],
        _a[GuideTypes.CARRIER_MARK_PICKED_UP_DELIVERED] = [
            {
                selector: "[data-id=\"izi-booking-section\"]",
                type: GuideTypes.CARRIER_MARK_PICKED_UP_DELIVERED,
            },
        ],
        _a[GuideTypes.CARRIER_JOIN_P44] = [
            {
                selector: "[id=\"order-gps-tracking-need-code\"]",
                type: GuideTypes.CARRIER_JOIN_P44,
            },
            syncP44CompanyCodeStep,
        ],
        _a[GuideTypes.CARRIER_SYNC_P44_COMPANY_CODE] = [syncP44CompanyCodeStep],
        _a[GuideTypes.CARRIER_UPLOAD_OFFER_DOCUMENT] = [
            {
                selector: "[data-id=\"order-list-documents-container\"]",
                type: GuideTypes.CARRIER_UPLOAD_OFFER_DOCUMENT,
            },
        ],
        _a);
    return TourSteps[type];
};
