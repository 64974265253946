var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { IconButton, useMediaQuery } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { TextOverflow } from '../TextOverflow';
var styles = function (theme) {
    return createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            position: 'relative',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: '50%',
            color: theme.palette.grey[500],
            transform: 'translateY(-50%)',
            '&:hover': {
                color: theme.palette.primary.light,
            },
        },
    });
};
export var DialogTitle = withStyles(styles)(function (props) {
    var children = props.children, classes = props.classes, onClose = props.onClose, other = __rest(props, ["children", "classes", "onClose"]);
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var langApp = useTranslation(KEYS.APP);
    return (React.createElement(MuiDialogTitle, __assign({ disableTypography: true, className: classes.root }, other),
        React.createElement(TextOverflow, { variant: "h6", style: __assign({ textAlign: 'center', marginRight: props.v4 ? theme.spacing(4) : 'none' }, (props.v6 && { color: theme.palette.primary.main })) }, children),
        onClose ? (React.createElement(BaseTooltip, { title: langApp.t('modal_actions_close') },
            React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: onClose, style: __assign({}, (props.v4 && { top: isSmall ? '50%' : '25%' })), id: 'modal_close_icon_button' },
                React.createElement(CloseIcon, null)))) : null));
});
