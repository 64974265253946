import moment from 'moment';
import { browserStorage } from '../browserStorage';
export var timeViewFormat = function (value) {
    return ((value || typeof value === 'number') &&
        ((value.toString().match(':') && value) || "" + (value < 10 ? '0' : '') + value + ":00")) ||
        '-';
};
export var timeViewFormat1 = function (value) {
    return ((typeof value === 'number' || value) && moment(value).format('HH:mm')) || '-';
};
export var timeViewFormat2 = function (min) {
    return timeViewFormat1(new Date(new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60)).setMinutes(min)));
};
export var timeViewFormat3 = function (hour, duration) {
    var d = new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60));
    d.setHours(parseInt(hour.toString().split(':')[0]));
    d.setMinutes(parseInt(hour.toString().split(':')[1]));
    d.setMinutes(d.getMinutes() + duration);
    return timeViewFormat1(d);
};
export var timeViewFormat4 = function (hour, duration) {
    if (duration === void 0) { duration = 0; }
    if (!hour)
        return 0;
    var d = new Date(new Date(0).setHours(new Date(0).getHours() + new Date(0).getTimezoneOffset() / 60));
    d.setHours(parseInt(hour.split(':')[0]));
    d.setMinutes(parseInt(hour.split(':')[1]));
    if (duration > 0)
        d.setMinutes(d.getMinutes() + duration);
    return d.getTime();
};
export var langTranslate = function (value, lang) {
    var date = new Date(value);
    var _lang = lang || browserStorage.getItem('lang') || 'en';
    if (['lt'].includes(_lang)) {
        _lang = 'en';
    }
    return date.toLocaleString(_lang, { month: 'short' }).toCapitalize();
};
export var formatDateChars = function (s) {
    var _a;
    try {
        if (s) {
            var c = (_a = s.match(/[!\D]/gi)) === null || _a === void 0 ? void 0 : _a.filter(function (e) { return !['.', ':', ' '].includes(e); });
            if (c.length) {
                c[0] = c[0].toUpperCase();
                s = s.replace(new RegExp(c.join(''), 'gi'), c.join(''));
            }
        }
    }
    catch (e) { }
    return s;
};
export var dateViewFormat = function (value, withTime, withScn, lang) {
    var s = formatDateChars(value && moment(value).format("YYYY MMM DD" + ((withTime && ' HH:mm') || '') + ((withScn && ':SS') || '')));
    return s || '-';
};
export var dateViewFormatV1 = function (value, withTime, withScn, lang) {
    return (formatDateChars(value && (moment(value).format("MMM DD" + ((withTime && ' HH:mm') || '') + ((withScn && ':SS') || '')) || '')) || '-');
};
export var DateView = function (_a) {
    var value = _a.value;
    return moment(value).format('DD.MM.YYYY');
};
export var dateDifference = function (date) {
    if (date === void 0) { date = new Date(); }
    var diff = Date.now() - new Date(date).getTime();
    if (Math.round(diff / 1000) === 0) {
        return "just now";
    }
    else if (diff < 1000 * 60) {
        return Math.round(diff / 1000) + " scn ago";
    }
    else if (diff < 1000 * 60 * 60) {
        return Math.round(diff / (1000 * 60)) + " min ago";
    }
    else if (diff < 1000 * 60 * 60 * 24) {
        return Math.round(diff / (1000 * 60 * 60)) + " hours ago";
    }
    else if (diff < 1000 * 60 * 60 * 24 * 7) {
        return Math.round(diff / (1000 * 60 * 60 * 24)) + " days ago";
    }
    else {
        return dateViewFormat(date, 1);
    }
};
