var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
import React from 'react';
export default (function (_a) {
    var _b, _c, _d, _e, _f;
    var manager = _a.manager, createdBy = _a.createdBy, style = _a.style, v1 = _a.v1, props = __rest(_a, ["manager", "createdBy", "style", "v1"]);
    var order = props.order;
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var isForwarderManager = ((_b = order === null || order === void 0 ? void 0 : order.forwarders) === null || _b === void 0 ? void 0 : _b.length) && (order === null || order === void 0 ? void 0 : order.forwarders[0]) &&
        createdBy &&
        ((_c = order.forwarders[0]._id) === null || _c === void 0 ? void 0 : _c.toString()) === ((_d = createdBy === null || createdBy === void 0 ? void 0 : createdBy._id) === null || _d === void 0 ? void 0 : _d.toString());
    var managerFullName = manager && (manager.fullName || [manager.firstName, manager.lastName].filter(function (e) { return e; }).join(' '));
    var createdByFullName = createdBy && (createdBy.fullName || [createdBy.firstName, createdBy.lastName].filter(function (e) { return e; }).join(' '));
    return (React.createElement(Grid, { container: true, alignItems: v1 || isSmall ? 'flex-start' : 'flex-end', direction: 'column', style: __assign({ width: 'initial', maxWidth: '100%' }, style) },
        React.createElement(TextOverflow, { variant: 'caption', style: __assign({ lineHeight: '1rem' }, props.textStyle) }, createdBy && createdBy.company && createdBy.company.name),
        !isForwarderManager ? (React.createElement(TextOverflow, { variant: 'caption', style: __assign({ lineHeight: '1rem' }, props.textStyle) }, (((_e = managerFullName === null || managerFullName === void 0 ? void 0 : managerFullName.trim()) === null || _e === void 0 ? void 0 : _e.length) && managerFullName) ||
            (((_f = createdByFullName === null || createdByFullName === void 0 ? void 0 : createdByFullName.trim()) === null || _f === void 0 ? void 0 : _f.length) && createdByFullName) || (manager === null || manager === void 0 ? void 0 : manager.email) || (createdBy === null || createdBy === void 0 ? void 0 : createdBy.email) ||
            '--')) : null));
});
