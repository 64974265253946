import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { menu } from './types';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { inject } from 'mobx-react';
import { client } from '../../api/apolloClient';

const CustomerSidebarMenu: any = inject('store')(
  ({
    store: {
      accountStore: { currentUser },
    },
  }) => {
    const langMenu = useTranslation(KEYS.MENU);
    const langApp = useTranslation(KEYS.APP);
    return <BasicSideBar menu={menu(langMenu, langApp, currentUser)} isShowOthersEnabled={true} config={{ client }} />;
  }
);
export default CustomerSidebarMenu;
