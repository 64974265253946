var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Grid, useMediaQuery } from '@material-ui/core';
import { theme } from '../../theme';
import { AvatarItemView } from '../Account/Avatar';
import { TextOverflow } from '../TextOverflow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
export default function SectionHistoryParsedActionsItem(_a) {
    var log = _a.log, index = _a.index, actions = _a.actions, total = _a.total;
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var langApp = useTranslation(KEYS.APP);
    return (React.createElement(Grid, { key: log._id, container: true, alignItems: 'flex-start', justify: 'space-between', style: __assign({ position: 'relative', borderBottom: "1px solid " + theme.palette.grey[300] }, (index < total - 1 && { marginBottom: theme.spacing(1.5) })) },
        React.createElement("div", { style: { maxWidth: isSmall ? '50px' : '26px' } },
            React.createElement(AvatarItemView, { moreDetail: true, avatarStyle: { width: 26, height: 26 }, typographyProps: {
                    variant: 'h6',
                    style: {
                        fontSize: 14,
                        fontWeight: 600,
                        color: theme.palette.common.white,
                    },
                }, user: log.createdBy })),
        React.createElement(Grid, { container: true, direction: 'column', style: {
                maxWidth: "calc(100% - " + (isSmall ? '50px' : '26px') + ")",
            } }, actions.map(function (e) { return (React.createElement(Grid, { container: true, key: e.label, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, md: 5, container: true, justify: isSmall ? 'flex-start' : 'flex-end', alignItems: isSmall ? 'center' : 'flex-start' },
                React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: isSmall ? 'left' : 'right', width: '100%', fontWeight: 700 } }, e.label)),
            React.createElement(Grid, { item: true, xs: 12, md: 7, container: true, justify: 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' },
                React.createElement(TextOverflow, { v1: e.breakValueLine, style: {
                        textAlign: isSmall ? 'left' : 'right',
                        fontSize: '0.7rem',
                        width: '100%',
                    } }, e.value || langApp.t('removed'))))); }))));
}
