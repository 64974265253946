import { types, addMiddleware } from 'mobx-state-tree';
import { actionLogger } from 'mst-middlewares';
export var statusTypes = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESSFULL: 'SUCCESSFULL',
    FAILED: 'FAILED',
};
export var AsyncActionStatus = types
    .model('AsyncActionStatus', {})
    .volatile(function (self) { return ({
    asyncActionStatus: statusTypes.SUCCESSFULL,
}); })
    .actions(function (self) { return ({
    newAsyncAction: function () {
        self.asyncActionStatus = statusTypes.IN_PROGRESS;
    },
    endAsyncAction: function (isSuccessfull) {
        if (self.asyncActionStatus === statusTypes.IN_PROGRESS) {
            self.asyncActionStatus = isSuccessfull ? statusTypes.SUCCESSFULL : statusTypes.FAILED;
        }
    },
}); })
    .actions(function (self) {
    addMiddleware(self, function (call, next, abort) {
        if (call.type === 'flow_spawn' || call.type === 'flow_resume') {
            self.newAsyncAction();
        }
        else if (call.type === 'flow_return') {
            self.endAsyncAction(true);
        }
        else if (call.type === 'flow_resume_error' || call.type === 'flow_throw') {
            self.endAsyncAction(false);
        }
        // console.log(call.type);
        next(call);
    });
    addMiddleware(self, actionLogger);
    return {};
})
    .views(function (self) { return ({
    get isAsyncActionInProgress() {
        return self.asyncActionStatus === statusTypes.IN_PROGRESS;
    },
}); });
