var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import React from 'react';
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { ACTION_CHANGE } from '../../components/BasicList';
import { Grid, IconButton } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../common';
import { FormikTextField } from '../../components/Formik';
import { useLocalStore } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { theme } from '../../theme';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import BaseTooltip from '../../components/BaseTooltip';
export default inject('store')(function (_a) {
    var item = _a.item, config = _a.config, warehouseStore = _a.store.warehouseStore, props = __rest(_a, ["item", "config", "store"]);
    var langForm = useTranslation(KEYS.FORM);
    var onChange = props.onChange;
    var formRef = React.useRef();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error('No Valid');
                    if (onChange)
                        onChange(createdItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_a) {
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __awaiter(this, void 0, void 0, function () {
                var result, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, 3, 4]);
                            localStore.error = '';
                            localStore.isLoading = true;
                            return [4 /*yield*/, warehouseStore.updateWarehouse(__assign(__assign({}, input), { _id: item._id, inactiveAt: item.inactiveAt ? null : new Date() }))];
                        case 1:
                            result = _b.sent();
                            return [2 /*return*/, result];
                        case 2:
                            e_1 = _b.sent();
                            console.log(e_1);
                            return [3 /*break*/, 4];
                        case 3:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var record = {
        comment: '',
    };
    var dialogBody = (React.createElement(Grid, { container: true, direction: 'column' },
        React.createElement(Formik, { innerRef: formRef, initialValues: record, validationSchema: Yup.object({
                comment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, { id: "form-warehouse-deactivate", style: { width: '100%' } },
                React.createElement(Grid, { container: true, direction: "column" },
                    React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment }))));
        })));
    return (React.createElement(BasicDialog, { askCanClose: true, body: dialogBody, title: langForm.t('form_input_comment_reason'), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (handleClickOpen) {
            var title = (item.inactiveAt ? 'Activate' : 'Deactivate') + " warehouse." + (item.comment || '');
            var icon = (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { onClick: handleClickOpen, color: 'primary', id: 'deactivate-btn', style: { padding: 0 } }, item.inactiveAt ? (React.createElement(HighlightOffIcon, { style: { color: theme.palette.error.light } })) : (React.createElement(CheckCircleIcon, { style: { color: theme.palette.success.light } })))));
            return props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen })) : icon;
        } }));
});
