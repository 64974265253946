var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from './DialogTitle';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { dialogActionsStyles, dialogBackDropActionsStyles, dialogContentStyles, dialogStyles } from './styles';
import { onPreventEvent } from './utill';
import { Transition, TransitionDown } from './Transition';
import { MainButton } from '../MainButton';
import { withRef } from '../../HOK/withRef';
import { TIME_OUT } from '../types';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BasicDialogPrompt } from './BasicDialogPrompt';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { toast } from 'react-toastify';
import { globalUtils, wrapTextToId } from '../../utils';
import { SOUND_KEY } from '../Sounds';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PaddingSpace from '../Order/form/formsV1/PaddingSpce';
var StyledDialog = withStyles({
    root: {
        position: 'fixed',
        // @ts-ignore
        zIndex: '9999999 !important',
    },
})(Dialog);
var styles = function (theme) {
    return createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        paperScrollPaper: {
            alignItems: 'center',
        },
        transparentBackground: {
            backgroundColor: 'transparent',
        },
        blurBackground: {
            background: "url('https://izi-logistics-prod.s3.us-east-2.amazonaws.com/public/icons/Background-Blur.webp'), #fff",
        },
        topScrollPaper: {
            alignItems: 'flex-start',
        },
        topPaperScrollBody: {
            verticalAlign: 'top',
        },
    });
};
var useStyles = makeStyles(styles);
export var BASIC_DIALOG_ACTIONS = {
    APPROVE: 'approve',
    WAIT: 'wait',
};
var timeout;
export var BasicDialog = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var body = _a.body, dialogProps = _a.dialogProps, parentStyles = _a.parentStyles, bodyStyle = _a.bodyStyle, bodyHeader = _a.bodyHeader, withoutWrapper = _a.withoutWrapper, isMiddleBody = _a.isMiddleBody, hidActionOkBth = _a.hidActionOkBth, hideActions = _a.hideActions, pending = _a.pending, isNotAbleToClose = _a.isNotAbleToClose, askCanClose = _a.askCanClose, isOpenByDefault = _a.isOpenByDefault, notClose = _a.notClose, shouldNotPrevent = _a.shouldNotPrevent, allowOkClose = _a.allowOkClose, v1 = _a.v1, v2 = _a.v2, v3 = _a.v3, v4 = _a.v4, v5 = _a.v5, v6 = _a.v6, v7 = _a.v7, shouldPreventClick = _a.shouldPreventClick, id = _a.id, props = __rest(_a, ["body", "dialogProps", "parentStyles", "bodyStyle", "bodyHeader", "withoutWrapper", "isMiddleBody", "hidActionOkBth", "hideActions", "pending", "isNotAbleToClose", "askCanClose", "isOpenByDefault", "notClose", "shouldNotPrevent", "allowOkClose", "v1", "v2", "v3", "v4", "v5", "v6", "v7", "shouldPreventClick", "id"]);
    var langApp = useTranslation(KEYS.APP);
    var classes = useStyles();
    var classesDialogStyles = dialogStyles();
    var classesDialogBackDropActionsStyles = dialogBackDropActionsStyles();
    var classesDialogActionsStyles = dialogActionsStyles();
    var classesDialogContentStyles = dialogContentStyles();
    var inputRef = React.useRef();
    var _h = __read(React.useState(isOpenByDefault || false), 2), open = _h[0], setOpen = _h[1];
    var _j = __read(React.useState(false), 2), error = _j[0], setError = _j[1];
    var _k = __read(React.useState(isOpenByDefault || false), 2), isOpen = _k[0], setIsOpen = _k[1];
    var _l = __read(React.useState(false), 2), loading = _l[0], setLoading = _l[1];
    // useEffect(() => {
    //   const events: any = [
    //     {
    //       name: 'keydown',
    //       context: document.body,
    //       callback: (e: KeyboardEvent) => {
    //         switch (e.key) {
    //           case 'Enter': {
    //             // onOk(e);
    //             // if (e) onPreventEvent(e);
    //             break;
    //           }
    //         }
    //       },
    //     },
    //   ];
    //   events.forEach((e) => e.context.addEventListener(e.name, e.callback));
    //   return () => {
    //     events.forEach((e) => e.context.removeEventListener(e.name, e.callback));
    //   };
    // }, []);
    var handleClickOpen = function (e) {
        if (!shouldNotPrevent && e) {
            onPreventEvent(e);
        }
        if (timeout)
            clearTimeout(timeout);
        setOpen(true);
        setIsOpen(true);
        if (props.onOpen)
            props.onOpen();
        if (globalUtils && globalUtils.sounds)
            globalUtils.sounds.play(SOUND_KEY.MODAL_OPEN);
        if (!shouldNotPrevent && e)
            return onPreventEvent(e);
    };
    var handleClose = function (e, action, opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var error, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isNotAbleToClose && !allowOkClose)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        if (!(action === 'cancel' && props.onCancel)) return [3 /*break*/, 3];
                        setLoading(true);
                        return [4 /*yield*/, props.onCancel(e)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (globalUtils && globalUtils.sounds)
                            globalUtils.sounds.play(SOUND_KEY.MODAL_CLOSE);
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _a.sent();
                        error = e_1;
                        return [3 /*break*/, 6];
                    case 5:
                        setLoading(false);
                        if (error && error.toLowerCase() === 'invalid form')
                            return [2 /*return*/];
                        setOpen(false);
                        if (timeout)
                            clearTimeout(timeout);
                        timeout = setTimeout(function () { return setIsOpen(false); }, TIME_OUT);
                        if (props.onClose)
                            props.onClose(e);
                        if (props.onCloseWithoutOk && !opt.isOkayClose)
                            props.onCloseWithoutOk(e);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    var _onHandleClose = function (e) {
        if (isNotAbleToClose)
            return;
        if (askCanClose && isDesktop) {
            return inputRef.current.click();
        }
        handleClose(e);
    };
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setError(false);
                    setLoading(true);
                    if (!props.onOk) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.onOk(e)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    handleClose(e, null, { isOkayClose: 1 });
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    console.log('Basic dialog error:', e_2);
                    if ((e_2.message || e_2) === BASIC_DIALOG_ACTIONS.APPROVE) {
                    }
                    else {
                        if ((e_2.message || e_2) === BASIC_DIALOG_ACTIONS.WAIT) {
                        }
                        else {
                            console.log(e_2);
                            toast.error(e_2.message);
                        }
                        setError(true);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    /*eslint-disable */
    React.useEffect(function () {
        // if (isOpenByDefault && v4) {
        // const item: any = document.body.querySelector('div[data-id="main-body"]');
        // if (item) item.parentNode.classList.add('app-blured');
        // }
        return function () {
            if (timeout)
                clearTimeout(timeout);
            // const item: any = document.body.querySelector('div[data-id="main-body"]');
            // if (item) {
            //   item.parentNode.className = item.parentNode.className.replace(/ app-blured/, '');
            // }
        };
    }, []);
    /*eslint-enable */
    var styles = props.fullWidth ? { width: '100%' } : {};
    var theme = useTheme();
    var isDesktop = useMediaQuery(theme.breakpoints.up('xs'));
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var bodyContent = (React.createElement(React.Fragment, null,
        v3 ? null : (React.createElement(DialogTitle, __assign({ v4: v4, v6: v6 }, (props.isTabs && { style: { padding: 0 } }), { id: wrapTextToId(props.title || 'delete-item'), onClose: !isNotAbleToClose && handleClose }), props.title || langApp.t('modal_delete_title'))),
        open ? (React.createElement(DialogContent, { id: wrapTextToId(id), style: __assign({}, bodyStyle), classes: {
                root: clsx(classesDialogContentStyles.root, (_b = {},
                    _b[classesDialogContentStyles.rootSidebar] = props.isSideBar,
                    _b[classesDialogContentStyles.rootBlock] = v1,
                    _b[classesDialogContentStyles.rootMiddle] = isMiddleBody,
                    _b[classesDialogContentStyles.rootWithoutBorder] = v2,
                    _b)),
            } },
            bodyHeader,
            (body && typeof body === 'function' && body(open)) || body)) : null,
        !hideActions ? (React.createElement(DialogActions, { classes: {
                root: classesDialogActionsStyles.root,
            } }, props.actions ? (props.actions({ handleClose: handleClose })) : (React.createElement(Grid, { container: true, direction: !isMobile ? 'row' : 'column', justify: 'flex-end', alignItems: 'center', spacing: 2, style: { padding: theme.spacing(2) } },
            typeof props.moreActions === 'function'
                ? props.moreActions({ handleClose: handleClose, open: open, loading: isNotAbleToClose || pending || loading })
                : props.moreActions,
            (isMobile && props.moreActions && React.createElement("div", { style: { paddingBottom: theme.spacing(1) } })) || null,
            (!notClose && (React.createElement(MainButton, { style: __assign(__assign({}, (!hidActionOkBth && !isMobile ? { marginRight: theme.spacing(2) } : null)), (isMobile && { width: '100%' })), onClick: function (e) { return handleClose(e, 'cancel'); }, disabled: isNotAbleToClose || pending || loading, title: props.actionCancelTitle || langApp.t('modal_actions_close'), color: 'secondary', id: 'modal_close_button' }))) ||
                null,
            (isMobile && React.createElement("div", { style: { paddingBottom: theme.spacing(1) } })) || null,
            !hidActionOkBth ? (React.createElement(MainButton, { isOkScreen: true, disabled: isNotAbleToClose || pending || loading, loading: pending || loading, onClick: onOk, style: __assign({}, (isMobile && { width: '100%' })), title: props.actionOkTitle || langApp.t('modal_actions_ok'), id: 'modal_success_button' })) : null,
            (props.isSideBar && (React.createElement(React.Fragment, null,
                React.createElement(PaddingSpace, null),
                React.createElement(PaddingSpace, null)))) ||
                null)))) : null));
    var allDialogProps = __assign(__assign(__assign({}, dialogProps), (shouldPreventClick && { onClick: onPreventEvent })), { open: open, TransitionComponent: v3 || v5 ? TransitionDown : Transition, keepMounted: false, disableEnforceFocus: true, disableScrollLock: true, disableRestoreFocus: true, disableAutoFocus: true, trapFocus: false, scroll: 'body', onClose: _onHandleClose, ariaLabelledby: 'alert-dialog-slide-title', ariaDescribedby: 'alert-dialog-slide-description', BackdropProps: {
            classes: {
                root: clsx(classesDialogBackDropActionsStyles.root, (_c = {},
                    _c[classesDialogBackDropActionsStyles.rootMiddle] = hidActionOkBth,
                    _c[classes.transparentBackground] = v3 || v5,
                    _c[classes.blurBackground] = v4,
                    _c)),
            },
        } });
    var modalId = wrapTextToId((typeof props.title === 'string' && props.title) || id || 'modal');
    var dialogFinalProps = __assign(__assign({ 'data-id': "modal-" + modalId }, allDialogProps), { classes: {
            root: classesDialogStyles.root,
            paper: clsx(classesDialogStyles.paper, (_d = {},
                _d[classesDialogStyles.paperWithoutShadow] = !(v3 || v4 || v5),
                _d[classesDialogStyles.error] = error,
                _d[classesDialogStyles.paperV5] = v5,
                _d)),
            scrollPaper: v3 || v5 ? classes.topScrollPaper : classes.paperScrollPaper,
            paperScrollBody: clsx((_e = {}, _e[classes.topPaperScrollBody] = v3 || v5, _e)),
        } });
    var wrapperContent = (React.createElement(React.Fragment, null,
        props.actionInit && props.actionInit(handleClickOpen, handleClose, { loading: loading }),
        (askCanClose && (React.createElement(BasicDialogPrompt, { "data-id": "prompt-" + modalId, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                React.createElement(ErrorIcon, { color: 'primary' }),
                " ",
                langApp.t('modal_close_modal')), actionInit: function (handleClickOpen) { return React.createElement("input", { ref: inputRef, type: 'hidden', onClick: handleClickOpen }); }, body: React.createElement(Typography, { className: classesDialogStyles.text, style: {
                    lineBreak: 'anywhere',
                    textAlign: 'center',
                } }, langApp.t('modal_prompt_question_close_modal')), onOk: handleClose }))) ||
            null,
        (open || isOpen) &&
            (props.isSideBar ? (React.createElement(SwipeableDrawer, __assign({ "data-id": "modal-" + modalId, anchor: 'right' }, allDialogProps, { classes: {
                    root: classesDialogStyles.root,
                    paper: clsx(classesDialogStyles.paperSideBar, classesDialogStyles.paper, (_f = {},
                        _f[classesDialogStyles.paperWithoutShadow] = !(v3 || v4),
                        _f[classesDialogStyles.error] = error,
                        _f)),
                    scrollPaper: v3 ? classes.topScrollPaper : classes.paperScrollPaper,
                    paperScrollBody: clsx((_g = {}, _g[classes.topPaperScrollBody] = v3, _g)),
                } }), bodyContent)) : v7 ? (React.createElement(StyledDialog, __assign({}, dialogFinalProps), bodyContent)) : (React.createElement(Dialog, __assign({}, dialogFinalProps), bodyContent)))));
    return withoutWrapper ? (wrapperContent) : (React.createElement("span", { onClick: onPreventEvent, style: __assign(__assign({}, styles), parentStyles) }, wrapperContent));
};
export var BasicDialogRef = withRef(BasicDialog);
