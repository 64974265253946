var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { toDateOnly } from '../../../utils';
import moment from 'moment';
export var monthDaysData = function (current) {
    var weekDays = moment.weekdays();
    weekDays = __spread(weekDays.filter(function (e, i) { return i > 0; }), [weekDays[0]]);
    var firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
    var startFrom = firstDay;
    var diff = 0;
    while (startFrom.getDay() !== 1) {
        var d = new Date(startFrom);
        d.setDate(startFrom.getDate() - 1);
        startFrom = d;
        diff++;
    }
    var monthView = [];
    var index = 0;
    var nextMonth = toDateOnly(new Date(new Date(current).setMonth(current.getMonth() + 1)));
    do {
        var days = [];
        for (var j = 0; j < weekDays.length; j++) {
            var d = new Date(startFrom);
            d.setDate(startFrom.getDate() + index++);
            days.push(d);
        }
        monthView.push(days);
    } while (toDateOnly(new Date(new Date(current).setDate(current.getDate() + index - diff))) < nextMonth);
    return monthView;
};
