var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { WAREHOUSE_BASE, WAREHOUSE_BASE_V1, WAREHOUSE_RAMP_BASE } from './types';
import { LOGIN_INFO } from '../auth';
export var FetchWarehousesRamps = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_RAMP_BASE);
export var FetchWarehouses = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n                ramps{\n                    _id\n                    name\n                }\n            }\n            count\n        }\n    }\n"], ["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n                ramps{\n                    _id\n                    name\n                }\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_BASE);
export var FetchWarehousesMore = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n                ramps{\n                    _id\n                }\n            }\n            count\n        }\n    }\n"], ["\n    query warehouses($filter: MainFilter)  {\n        warehouses(filter:$filter) {\n            data {\n                ", "\n                ramps{\n                    _id\n                }\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_BASE);
export var FetchWarehouse = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query warehouse($_id: MongoId!)  {\n        warehouse(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    query warehouse($_id: MongoId!)  {\n        warehouse(_id:$_id) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_BASE);
export var CreateWarehouse = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation createWarehouse($input: WarehouseInput!)  {\n        createWarehouse(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createWarehouse($input: WarehouseInput!)  {\n        createWarehouse(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_BASE);
export var UpdateWarehouse = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation updateWarehouse($input: WarehouseInputUpdate!)  {\n        updateWarehouse(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateWarehouse($input: WarehouseInputUpdate!)  {\n        updateWarehouse(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_BASE);
export var MergeWarehouses = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation mergeWarehouse($input: ModelMergeInput!) {\n    mergeWarehouse(input: $input) {\n      _id\n    }\n  }\n"], ["\n  mutation mergeWarehouse($input: ModelMergeInput!) {\n    mergeWarehouse(input: $input) {\n      _id\n    }\n  }\n"])));
export var MergeWarehouseRamp = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  mutation mergeWarehouseRamps($input: ModelMergeInput!) {\n    mergeWarehouseRamps(input: $input) {\n      _id\n    }\n  }\n"], ["\n  mutation mergeWarehouseRamps($input: ModelMergeInput!) {\n    mergeWarehouseRamps(input: $input) {\n      _id\n    }\n  }\n"])));
export var DeleteWarehouse = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation deleteWarehouse($_id: MongoId!)  {\n        deleteWarehouse(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteWarehouse($_id: MongoId!)  {\n        deleteWarehouse(_id:$_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var FetchCompanyWarehousesAddresses = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query loadCompanyWarehousesAddresses($filter: MainFilter) {\n        loadCompanyWarehousesAddresses(filter: $filter) {\n            data {\n                ", "\n                place_id\n            }\n            count\n        }\n    }\n"], ["\n    query loadCompanyWarehousesAddresses($filter: MainFilter) {\n        loadCompanyWarehousesAddresses(filter: $filter) {\n            data {\n                ", "\n                place_id\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_BASE_V1);
export var ProceedWarehouseBooking = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation proceedWarehouseBooking($input: ProceedWarehouseBookingInput) {\n        proceedWarehouseBooking(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation proceedWarehouseBooking($input: ProceedWarehouseBookingInput) {\n        proceedWarehouseBooking(input: $input) {\n            ", "\n        }\n    }\n"])), LOGIN_INFO);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
