import React from 'react';
import { TextOverflow } from '../../components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { withChannelStore } from './stores/Channel';
var ChatUserTyping = function (_a) {
    var record = _a.record, channelStore = _a.channelStore;
    var langChat = useTranslation(KEYS.CHAT);
    var usersTyping = channelStore.state.usersTyping.filter(function (e) { return e.chat._id === record._id; });
    return ((usersTyping.length && (React.createElement(Grid, { container: true, justify: 'center' },
        React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1.1 } }, langChat
            .t('form_user_is_typing')
            .replace('_X_', usersTyping.map(function (e) { return [e.user.firstName, e.user.lastName].filter(function (f) { return f; }).join(' '); }).join(', ')))))) ||
        null);
};
export default withChannelStore(ChatUserTyping);
