var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { theme } from '../theme';
import { browserStorage } from '../browserStorage';
var totalCheck = 0;
var isDev = !!(process.env.REACT_APP_API_URL || '').match(/-master|-stag|localhost/);
var REACT_APP_RECAPTCHA_KEY = !isDev && process.env.REACT_APP_RECAPTCHA_KEY;
window.REACT_APP_RECAPTCHA_KEY = REACT_APP_RECAPTCHA_KEY;
export default function Recaptcha(_a) {
    var onChange = _a.onChange;
    var _b = __read(React.useState(true), 2), show = _b[0], setShow = _b[1];
    var ref = React.useRef(null);
    var isTesting = !!browserStorage.getItem('testing') || window.is_testing;
    var onloadCallback = function () {
        try {
            window.grecaptcha.render(ref.current, {
                sitekey: REACT_APP_RECAPTCHA_KEY,
                callback: onChange,
                theme: 'light',
            });
        }
        catch (e) {
            console.log(e);
        }
    };
    var onload = function () {
        if (!window.grecaptcha || !window.grecaptcha.render) {
            if (totalCheck++ > 5) {
                setShow(false);
                onChange();
                // document.body.innerHTML = `<h1>Sorry, make sure you are have access to google services!!!</h1>`;
            }
            else {
                setTimeout(onload, 1000);
            }
        }
        else {
            onloadCallback();
        }
    };
    React.useEffect(function () {
        if (!isTesting && REACT_APP_RECAPTCHA_KEY) {
            if (!window.grecaptchaInited) {
                window.grecaptchaInited = 1;
                var script = document.createElement('script');
                script.setAttribute('src', "https://www.google.com/recaptcha/api.js?render=explicit");
                script.onload = onload;
                script.onerror = function () {
                    setShow(false);
                    onChange();
                    // document.body.innerHTML = `<h1>Sorry, make sure you are have access to google services</h1>`;
                };
                document.body.appendChild(script);
            }
            else {
                onload();
            }
        }
        else {
            onChange();
        }
    }, []);
    if (!REACT_APP_RECAPTCHA_KEY || !show || isTesting)
        return null;
    return (React.createElement("div", { ref: ref, style: {
            padding: theme.spacing(1, 0),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        } }));
}
