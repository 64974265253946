import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { useStyles } from "./styles";
export var WholePageFormFooterNavLink = function (_a) {
    var _b = _a.paragraphText, paragraphText = _b === void 0 ? "" : _b, _c = _a.navPath, navPath = _c === void 0 ? "" : _c, _d = _a.linkText, linkText = _d === void 0 ? "" : _d;
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true },
            React.createElement(Typography, null, paragraphText)),
        React.createElement(Grid, { item: true }, navPath.match("http") ? (React.createElement("a", { href: navPath, className: classes.navLink }, linkText)) : (React.createElement(NavLink, { className: classes.navLink, to: navPath }, linkText)))));
};
