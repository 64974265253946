var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { UPLOAD_BASE, USER_COMPANY_BASE } from './base';
import { USER_BASE_MIN } from './user';
export var COMPANY_DOCUMENT_BASE = "\n    _id\n    type\n    createdAt\n    deletedAt\n    expireAt\n    amount\n    currency\n    createdBy {\n        " + USER_BASE_MIN + "\n    }\n    upload {\n        " + UPLOAD_BASE + "\n    }\n    company {\n        " + USER_COMPANY_BASE + "\n    }\n";
export var FetchCompanyDocuments = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query companyDocuments($filter: MainFilter) {\n        companyDocuments(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query companyDocuments($filter: MainFilter) {\n        companyDocuments(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), COMPANY_DOCUMENT_BASE);
export var CreateCompanyDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createCompanyDocument($input: CompanyDocumentInput!) {\n        createCompanyDocument(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createCompanyDocument($input: CompanyDocumentInput!) {\n        createCompanyDocument(input: $input) {\n            ", "\n        }\n    }\n"])), COMPANY_DOCUMENT_BASE);
var templateObject_1, templateObject_2;
