var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Grid, makeStyles, Typography, MenuItem, useTheme } from '@material-ui/core';
import { SubmitButtonWithLoading } from '../../components/SubmitButtonWithLoading';
import { useTranslation } from 'react-i18next';
import { AddFormButton } from '../../components/AddFormButton';
import { FetchAdminUser, InviteTeamMembers } from '../../graphql/user';
import * as formikA from 'formik';
import { FormikAutocompleteAsync, FormikSelect, FormikTextField } from '../../components/Formik';
import { BaseUserRole } from '../../stores/common/BaseUser';
import { AppType } from '../Quotes/Documents/DocumentItemVisibleTo';
import { toast } from 'react-toastify';
import { formUserErrorMessage } from '../../common/formUserErrorMessage';
import * as Yup from 'yup';
import { KEYS, i18n } from '../../i18n';
import clsx from 'clsx';
import { DeleteDialog } from '../../components/Dialog';
import { onlySpacesRegExp, phoneRegExp, vatCodeRegExp } from '../../common';
import { BaseUserAppTYpe, USER_ROLE } from '../../stores/common';
import { FetchUserRoles } from '../../graphql/userRoles';
import { USER_ROLE_TOOLS } from '../../models/UserRoles';
import { TextOverflow } from '../../components';
import { HelpIconComponent } from '../../components/HelpIcon';
import { forEach, get } from '../../lodashUtils';
import BaseChip from '../../components/BaseChip';
import Loader from '../../components/Loader';
import PreloadingComponent from '../../components/PreloadingComponent';
var FieldArray = formikA.FieldArray;
var Formik = formikA.Formik, Form = formikA.Form;
var SettingsVehicleType = React.lazy(function () { return import('../../components/Settings/OrderPreferenceSettings/SettingsVehicleType'); });
var useStyles = makeStyles(function (theme) { return ({
    section: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 4),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    marginForm: {
        marginBottom: theme.spacing(1),
    },
    emptyForm: {
        minWidth: 1000,
    },
}); });
export var AddMembersForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var _b;
    var _c, _d;
    var isModalForm = _a.isModalForm, emptyForm = _a.emptyForm, props = __rest(_a, ["isModalForm", "emptyForm"]);
    var config = props.config, store = props.store, onChange = props.onChange, onAddCompany = props.onAddCompany, onAddUserCompany = props.onAddUserCompany;
    var _e = __read(React.useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(React.useState(null), 2), record = _f[0], setRecord = _f[1];
    var langApp = useTranslation(KEYS.APP);
    var langMenu = useTranslation(KEYS.MENU);
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var _g = __read(React.useState({}), 2), joinCompanyMap = _g[0], setJoinCompanyMap = _g[1];
    var theme = useTheme();
    var _h = __read(React.useState({
        usersData: emptyForm
            ? []
            : [
                __assign({ firstName: (props.item && props.item.firstName) || '', totalVehicleQTY: (props.item && props.item.totalVehicleQTY) || '', phone: (props.item && props.item.phone) || '', lastName: (props.item && props.item.lastName) || '', roles: (props.item && props.item.roles) || BaseUserRole.MEMBER, email: (props.item && props.item.email) || '', rolesV1: (props.item && props.item.rolesV1) || [], appType: (props.item && props.item.appType) || [BaseUserAppTYpe.CUSTOMER] }, (onAddCompany && {
                    companyName: '',
                    vatCode: '',
                })),
            ],
    }), 1), defaultValue = _h[0];
    var classes = useStyles();
    var accountStore = store.accountStore;
    var appType = props.item && props.item.appType[0];
    var client = (config && config.client) || props.client;
    var localStore = useLocalStore(function () { return ({
        userErrorMessage: '',
        _users: [],
        isLoading: false,
        createInvitedUsers: function (invitedUsersData) {
            return __awaiter(this, void 0, void 0, function () {
                var result, invitedUsers;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStore.userErrorMessage = '';
                            localStore.isLoading = true;
                            return [4 /*yield*/, client.mutate({
                                    mutation: InviteTeamMembers,
                                    variables: invitedUsersData,
                                })];
                        case 1:
                            result = _a.sent();
                            invitedUsers = result.data.inviteMembers;
                            forEach(invitedUsers, function (qUser) {
                                var user = localStore.findUserById(qUser._id);
                                if (!user) {
                                    localStore._users.push(qUser);
                                }
                            });
                            localStore.isLoading = false;
                            onChange && onChange(invitedUsers);
                            toast.success(langToast.t('toast_items_invitation_sent'));
                            return [2 /*return*/, invitedUsers];
                    }
                });
            });
        },
        findUserById: function (id) {
            return localStore._users.find(function (user) { return get(user, '_id') === id; });
        },
        clearUserErrorMessage: function () {
            localStore.userErrorMessage = '';
        },
    }); });
    useEffect(function () {
        localStore.clearUserErrorMessage();
    }, [localStore._users, localStore]);
    useEffect(function () {
        var _a;
        if (((_a = props.item) === null || _a === void 0 ? void 0 : _a._id) && props.isAdmin) {
            setLoading(true);
            client
                .query({ query: FetchAdminUser, variables: { _id: props.item._id } })
                .then(function (res) {
                setRecord(res.data.user);
            })
                .finally(function () {
                setLoading(false);
            });
        }
    }, [props.isAdmin, (_c = props.item) === null || _c === void 0 ? void 0 : _c._id]);
    var canAssignRole = props.store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_assign_roles); });
    var onSubmit = function (values, actions) { return __awaiter(void 0, void 0, void 0, function () {
        var res, user, update, i, user, e_1, input, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 16, 17, 18]);
                    if (!(props.item && props.item._id)) return [3 /*break*/, 2];
                    localStore.isLoading = true;
                    user = values.usersData[0];
                    update = __assign(__assign({ _id: props.item._id, firstName: user.firstName, lastName: user.lastName, roles: user.roles, phone: user.phone, email: user.email, appType: Array.isArray(user.appType) ? user.appType : [user.appType] }, (props.isAdmin && { totalVehicleQTY: parseInt(user.totalVehicleQTY) })), ((props.isAdmin || canAssignRole) && {
                        rolesV1: user.rolesV1.map(function (e) { return e._id; }),
                    }));
                    return [4 /*yield*/, props.store.accountStore.updateUser(update)];
                case 1:
                    res = [(_a.sent()).data.updateUser];
                    return [3 /*break*/, 15];
                case 2:
                    if (!onAddCompany) return [3 /*break*/, 7];
                    localStore.isLoading = true;
                    i = 0;
                    _a.label = 3;
                case 3:
                    if (!(i < values.usersData.length)) return [3 /*break*/, 6];
                    user = values.usersData[i];
                    return [4 /*yield*/, onAddCompany(__assign(__assign({}, user), { companyType: user.appType || BaseUserAppTYpe.CARRIER }))];
                case 4:
                    res = _a.sent();
                    _a.label = 5;
                case 5:
                    i++;
                    return [3 /*break*/, 3];
                case 6:
                    toast.success(langToast.t('toast_items_invitation_sent'));
                    onChange && onChange();
                    return [3 /*break*/, 15];
                case 7:
                    if (!onAddUserCompany) return [3 /*break*/, 13];
                    _a.label = 8;
                case 8:
                    _a.trys.push([8, 10, 11, 12]);
                    return [4 /*yield*/, onAddUserCompany(values.usersData)];
                case 9:
                    res = (_a.sent()).data.inviteMembers;
                    toast.success(langToast.t('toast_items_invitation_sent'));
                    onChange && onChange(res);
                    return [3 /*break*/, 12];
                case 10:
                    e_1 = _a.sent();
                    throw e_1;
                case 11: return [7 /*endfinally*/];
                case 12: return [3 /*break*/, 15];
                case 13:
                    input = values.usersData.map(function (user) {
                        return {
                            user: {
                                firstName: user.firstName,
                                lastName: user.lastName,
                                roles: user.roles,
                                phone: user.phone,
                                email: user.email,
                                additionalEmail: user.additionalEmail,
                                appType: Array.isArray(user.appType) ? user.appType : [user.appType],
                            },
                            company: (props.company && props.company._id) || accountStore.currentCompany._id,
                        };
                    });
                    return [4 /*yield*/, localStore.createInvitedUsers({
                            input: input,
                        })];
                case 14:
                    res = _a.sent();
                    _a.label = 15;
                case 15:
                    actions.resetForm();
                    return [3 /*break*/, 18];
                case 16:
                    error_1 = _a.sent();
                    localStore.userErrorMessage = formUserErrorMessage(error_1);
                    return [3 /*break*/, 18];
                case 17:
                    localStore.isLoading = false;
                    return [7 /*endfinally*/];
                case 18: return [2 /*return*/, res];
            }
        });
    }); };
    var validationSchema = Yup.object().shape({
        usersData: Yup.array()
            .typeError(langForm.t('form_input_validate_required'))
            .of(Yup.object().shape(Object.assign({
            firstName: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            lastName: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
            email: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
                .email(langForm.t('form_input_validate_invalid_email'))
                .required(langForm.t('form_input_validate_required')),
            phone: Yup.string()
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .matches(phoneRegExp, langForm.t('form_input_validate_invalid_phone')),
        }, onAddCompany
            ? {
                companyName: Yup.string()
                    .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                vatCode: Yup.string()
                    .min(5, langForm.t('form_input_validate_min_char').replace(/_X_/g, '5'))
                    .max(200, langForm.t('form_input_validate_max_char').replace(/_X_/g, '200'))
                    .matches(vatCodeRegExp, langForm.t('form_input_invalid_vat_code'))
                    .required(langForm.t('form_input_validate_required')),
            }
            : null))),
    });
    var isLoading = localStore.isLoading;
    var error = localStore.userErrorMessage;
    var size = onAddCompany && !props.hideRole ? 2 : 3;
    return (React.createElement(React.Fragment, null,
        props.isAdmin || isModalForm ? null : (React.createElement(Typography, { variant: "h6", style: { fontWeight: 600 }, className: classes.title }, props.title || (onAddCompany ? langMenu.t('menu_sidebar_carriers') : langApp.t('team_label')))),
        (loading && (React.createElement(Grid, { container: true, direction: "row" },
            React.createElement(Loader, null)))) || (React.createElement("div", { className: clsx((_b = {}, _b[classes.section] = !isModalForm, _b)), style: { width: '100%' } },
            React.createElement(Grid, { container: true, direction: "row" },
                React.createElement(Formik, { initialValues: defaultValue, validationSchema: validationSchema, onSubmit: onSubmit, innerRef: ref }, function (_a) {
                    var values = _a.values, handleChange = _a.handleChange;
                    var onFieldChangeHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a, name, value, companyWithSameVatCode;
                        var _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _a = e.target, name = _a.name, value = _a.value;
                                    name = name === null || name === void 0 ? void 0 : name.split('.')[2];
                                    if (!(name === 'vatCode')) return [3 /*break*/, 2];
                                    return [4 /*yield*/, accountStore.currentCompany.checkVatCodeExists(value)];
                                case 1:
                                    companyWithSameVatCode = _c.sent();
                                    if (companyWithSameVatCode) {
                                        setJoinCompanyMap(__assign(__assign({}, joinCompanyMap), (_b = {}, _b[value] = companyWithSameVatCode, _b)));
                                    }
                                    _c.label = 2;
                                case 2:
                                    localStore.clearUserErrorMessage();
                                    handleChange(e);
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Form, { style: { width: '100%' }, id: 'form-user' },
                            React.createElement(FieldArray, { name: "usersData" }, function (_a) {
                                var insert = _a.insert, remove = _a.remove, push = _a.push;
                                return (React.createElement("div", null, values.usersData.length > 0 ? (React.createElement(React.Fragment, null,
                                    values.usersData.map(function (user, index) {
                                        var _a, _b;
                                        return (React.createElement(Grid, { container: true, direction: "row", key: index },
                                            React.createElement(Typography, { color: "error" }, error),
                                            props.item && props.item._id ? null : (React.createElement(Grid, { container: true, item: true, className: classes.marginForm },
                                                React.createElement(Grid, { item: true, container: true, xs: 10, alignItems: "center", justify: "flex-start" },
                                                    React.createElement(Typography, { variant: "h5" }, (props.newLabelText || langApp.t('team_new')) + " " + (index + 1) + " ")),
                                                values.usersData.length > 1 ? (React.createElement(Grid, { item: true, container: true, xs: 2, alignItems: "center", justify: "flex-end" },
                                                    React.createElement(DeleteDialog, { onOk: function () { return remove(index); } }))) : null)),
                                            React.createElement(Grid, { container: true, spacing: 2 },
                                                React.createElement(Grid, { item: true, xs: 12, sm: (!(props.isAdmin && !props.hideRole) && 3) || 2, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".firstName", title: langForm.t('form_input_name_first_name'), onChange: onFieldChangeHandler })),
                                                React.createElement(Grid, { item: true, xs: 12, sm: (!(props.isAdmin && !props.hideRole) && 3) || 2, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".lastName", title: langForm.t('form_input_name_last_name'), onChange: onFieldChangeHandler })),
                                                React.createElement(Grid, { item: true, xs: 12, sm: (onAddCompany && 6) || size, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".email", title: langForm.t('form_input_name_email'), onChange: onFieldChangeHandler })),
                                                React.createElement(Grid, { item: true, xs: 12, sm: (!(props.isAdmin && !props.hideRole) && 3) || 2, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".phone", title: langForm.t('form_input_name_phone'), onChange: onFieldChangeHandler })),
                                                (props.isAdmin && !props.hideRole && (React.createElement(Grid, { item: true, xs: 12, sm: size, id: "user-form-access-" + index, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikSelect, { disabled: !props.isAdmin, title: 'Admin access', variant: "outlined", type: "text", name: "usersData." + index + ".roles" }, Object.values(USER_ROLE).map(function (role, index) {
                                                        return (React.createElement(MenuItem, { key: index, value: role }, role));
                                                    }))))) ||
                                                    null,
                                                ((props.isAdmin || canAssignRole) && (React.createElement(Grid, { item: true, xs: 12, id: "user-form-roleV1-" + index, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikAutocompleteAsync, { v17: true, isClient: true, filterFields: ['name'], title: langForm.t('form_input_name_roles'), variant: "outlined", margin: 'dense', fullWidth: true, name: "usersData." + index + ".rolesV1", client: client, query: FetchUserRoles, variables: {
                                                            filter: {
                                                                fields: [
                                                                    {
                                                                        name: 'access',
                                                                        value: props.company
                                                                            ? Array.isArray(props.company.type)
                                                                                ? props.company.type
                                                                                : [props.company.type]
                                                                            : Array.isArray(user.appType)
                                                                                ? user.appType
                                                                                : [user.appType],
                                                                    },
                                                                ],
                                                            },
                                                        }, autocompleteProps: {
                                                            multiple: true,
                                                            defaultValue: user.rolesV1,
                                                            getOptionSelected: function (option, value) {
                                                                return option._id === value._id;
                                                            },
                                                            getOptionLabel: function (option) {
                                                                (option.name || option._id || '').toString();
                                                            },
                                                            renderOption: function (option) {
                                                                return (React.createElement(Grid, { container: true, alignItems: 'center' },
                                                                    React.createElement(HelpIconComponent, { title: option.description || option.name }),
                                                                    React.createElement(TextOverflow, null, (option.name || option._id || '').toString())));
                                                            },
                                                            renderTags: function (value, getTagProps) {
                                                                var re = (Array.isArray(value) && value) || [];
                                                                return re.map(function (option, index) {
                                                                    return option && (React.createElement(BaseChip, __assign({ label: React.createElement(Grid, { container: true, alignItems: 'center' },
                                                                            React.createElement(HelpIconComponent, { title: option.description || option.name }),
                                                                            React.createElement(TextOverflow, null, (option.name || option._id || '').toString())) }, getTagProps({ index: index }), { color: 'primary' })));
                                                                });
                                                            },
                                                        }, onChange: onFieldChangeHandler })))) ||
                                                    null,
                                                (props.isAdmin && (React.createElement(Grid, { item: true, xs: 12, sm: size, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikSelect, { title: langForm.t('form_input_name_app_type'), variant: "outlined", multiple: true, renderValue: function (selected) {
                                                            return (React.createElement(Grid, { container: true, alignItems: 'center' }, selected.map(function (item) { return (React.createElement(MenuItem, { key: item, value: item, style: {
                                                                    padding: theme.spacing(0),
                                                                    marginRight: theme.spacing(0.5),
                                                                } },
                                                                React.createElement(AppType, { type: item }))); })));
                                                        }, name: "usersData." + index + ".appType" }, [
                                                        BaseUserAppTYpe.CARRIER,
                                                        BaseUserAppTYpe.CUSTOMER,
                                                        BaseUserAppTYpe.FORWARDER,
                                                        BaseUserAppTYpe.SENDER,
                                                        BaseUserAppTYpe.CONSIGNEE,
                                                        BaseUserAppTYpe.AFFILIATE,
                                                    ].map(function (type) {
                                                        return (React.createElement(MenuItem, { key: type, value: type }, type));
                                                    }))))) ||
                                                    null,
                                                onAddCompany ? (React.createElement(React.Fragment, null,
                                                    React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginBottom: theme.spacing(1.5) } },
                                                        React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".companyName", title: langForm.t('form_input_name_company'), onChange: onFieldChangeHandler })),
                                                    React.createElement(Grid, { item: true, xs: 12, sm: 4, style: { marginBottom: theme.spacing(1.5) } },
                                                        React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".vatCode", title: langForm.t('form_input_name_vat_code'), warning: ((_a = joinCompanyMap[values.usersData[index].vatCode || '']) === null || _a === void 0 ? void 0 : _a.name) &&
                                                                langForm
                                                                    .t('form_ask_for_company_join_body')
                                                                    .replace('_X_', (_b = joinCompanyMap[values.usersData[index].vatCode || '']) === null || _b === void 0 ? void 0 : _b.name), onChange: onFieldChangeHandler })))) : null,
                                                props.isAdmin && (React.createElement(Grid, { item: true, xs: 12, sm: (!(props.isAdmin && !props.hideRole) && 3) || 2, style: { marginBottom: theme.spacing(1.5) } },
                                                    React.createElement(FormikTextField, { variant: "outlined", name: "usersData." + index + ".totalVehicleQTY", title: 'Vehicle qty', onChange: onFieldChangeHandler }))),
                                                (props.isAdmin && (React.createElement(React.Fragment, null,
                                                    __spread([
                                                        {
                                                            name: 'totalCompanyUsers',
                                                            label: 'Total users in system',
                                                        }
                                                    ], (([BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.FORWARDER].includes(appType) && [
                                                        {
                                                            name: 'totalOffers',
                                                            label: 'Total offers',
                                                        },
                                                        {
                                                            name: 'totalOffersToday',
                                                            label: 'Total offers today',
                                                        },
                                                        {
                                                            name: 'totalOffersMonth',
                                                            label: 'Total offers last month',
                                                        },
                                                    ]) ||
                                                        []), (([BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER].includes(appType) && [
                                                        {
                                                            name: 'totalOrders',
                                                            label: i18n.getResource(i18n.language, KEYS.APP, 'total_orders'),
                                                        },
                                                        {
                                                            name: 'totalOrdersToday',
                                                            label: 'Total orders today',
                                                        },
                                                        {
                                                            name: 'totalOrdersMonth',
                                                            label: 'Total orders last month',
                                                        },
                                                    ]) ||
                                                        [])).filter(function (e) { return e; })
                                                        .map(function (e) { return (React.createElement(Grid, { key: e.name, item: true, xs: 12 },
                                                        React.createElement(TextOverflow, { variant: "body", style: { width: 'initial', marginRight: theme.spacing(1) } },
                                                            e.label,
                                                            ":"),
                                                        React.createElement(TextOverflow, { variant: "body", style: { width: 'initial' } }, (record && record[e.name]) || '0'))); }),
                                                    React.createElement(Grid, { item: true, xs: 12, sm: size, style: { marginBottom: theme.spacing(1.5) } })))) ||
                                                    null)));
                                    }),
                                    React.createElement(Grid, { container: true, item: true, justify: 'space-between' },
                                        React.createElement(Grid, { item: true, container: true, xs: 12, sm: 4, alignItems: "center", justify: "flex-start" }, values.usersData.length < 10 && !(props.item && props.item._id) ? (React.createElement(AddFormButton, { title: props.addBtnText || "" + langApp.t('team_new_add'), onClick: function () {
                                                return push({
                                                    firstName: '',
                                                    lastName: '',
                                                    roles: BaseUserRole.MEMBER,
                                                    email: '',
                                                });
                                            } })) : null),
                                        (!isModalForm && (React.createElement(Grid, { item: true, container: true, xs: 12, sm: 2, alignItems: "center", justify: "flex-end" },
                                            React.createElement(SubmitButtonWithLoading, { text: langForm.t('form_btn_invite'), isLoading: isLoading })))) ||
                                            null))) : (React.createElement("div", { className: classes.emptyForm }))));
                            }))));
                })),
            (props.isAdmin && [BaseUserAppTYpe.CARRIER].includes(appType) && ((_d = record === null || record === void 0 ? void 0 : record.settings) === null || _d === void 0 ? void 0 : _d._id) && (React.createElement(Grid, { container: true, direction: "row" },
                React.createElement(PreloadingComponent, null,
                    React.createElement(SettingsVehicleType, { client: client, defaultValue: record.settings }))))) ||
                null))));
})));
