export var SOCKET_CHAT_CUSTOM_EVENT_NAME;
(function (SOCKET_CHAT_CUSTOM_EVENT_NAME) {
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_CREATE_MESSAGE"] = "CHAT_CREATE_MESSAGE";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_USER_TYPING"] = "CHAT_USER_TYPING";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_USER_FINISH_TYPING"] = "CHAT_USER_FINISH_TYPING";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_USER_STATUS"] = "CHAT_USER_STATUS";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_TOTAL_UNREAD_MESSAGES"] = "CHAT_TOTAL_UNREAD_MESSAGES";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_CHECK_CHANNELS_TOTAL_UNREAD_MESSAGES"] = "CHAT_CHECK_CHANNELS_TOTAL_UNREAD_MESSAGES";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_CHANNELS_TOTAL_UNREAD_MESSAGES"] = "CHAT_CHANNELS_TOTAL_UNREAD_MESSAGES";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_PAYMENT_TERM_TOTAL_UNREAD_MESSAGES"] = "CHAT_PAYMENT_TERM_TOTAL_UNREAD_MESSAGES";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_ISSUES_TOTAL_UNREAD_MESSAGES"] = "CHAT_ISSUES_TOTAL_UNREAD_MESSAGES";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_JOIN"] = "CHAT_JOIN";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_LEAVE"] = "CHAT_LEAVE";
    SOCKET_CHAT_CUSTOM_EVENT_NAME["CHAT_REMOVE"] = "CHAT_REMOVE";
})(SOCKET_CHAT_CUSTOM_EVENT_NAME || (SOCKET_CHAT_CUSTOM_EVENT_NAME = {}));
