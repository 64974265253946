var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '@material-ui/core';
import TrackingBookingSection from './TrackingBookingSection';
import { theme } from '../../../theme';
export default (function (props) {
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { item: true, xs: 12, md: 6, style: { borderRight: "2px solid " + theme.palette.grey[300], padding: theme.spacing(3, 4) } },
            React.createElement(TrackingBookingSection, __assign({}, props, { keyVal: 'startTime', key: props.transportation.startDate }))),
        React.createElement(Grid, { item: true, xs: 12, md: 6, style: { padding: theme.spacing(3, 4) } },
            React.createElement(TrackingBookingSection, __assign({}, props, { keyVal: 'endTime', key: props.transportation.endDate })))));
});
