var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import InputTitle from './InputTitle';
import { RangeDatePicker } from '../RangeDatePicker';
import * as material from '@material-ui/core';
var Box = material.Box;
export default function RangeDateFilter(_a) {
    var title = _a.title, value = _a.value, name = _a.name, placeholder = _a.placeholder, onChange = _a.onChange, onClose = _a.onClose, props = __rest(_a, ["title", "value", "name", "placeholder", "onChange", "onClose"]);
    var hundredYearsAgo = new Date();
    hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);
    return (React.createElement(Box, { display: "flex", flexDirection: "column" },
        React.createElement(InputTitle, { title: title, v1: true, style: props.titleStyle }),
        React.createElement(Box, null,
            React.createElement(RangeDatePicker, { v14: true, name: name, value: value, placeholder: placeholder, onChange: onChange, styleV1: true, minDate: props.minDate || hundredYearsAgo, maxDate: props.maxDate, onClose: onClose }))));
}
