var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularIndeterminate } from './CircularIndeterminate';
import { globalUtils } from '../utils';
import { SOUND_KEY } from './Sounds';
export var useSubmitButonWithLoadingStyles = makeStyles(function (theme) {
    return ({
        submit: {
            position: 'relative',
            margin: theme.spacing(3, 0, 2),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey['100'],
            textTransform: 'initial',
        },
        submitV1: {
            fontSize: 24,
            borderRadius: theme.spacing(1.2),
        },
    });
});
export var SubmitButtonWithLoading = function (_a) {
    var _b;
    var isLoading = _a.isLoading, text = _a.text, disabled = _a.disabled, props = __rest(_a, ["isLoading", "text", "disabled"]);
    var classes = useSubmitButonWithLoadingStyles();
    var _onClick = function (e) {
        globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK);
        props.onClick && props.onClick(e);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, __assign({ className: clsx(classes.submit, (_b = {}, _b[classes.submitV1] = props.v1, _b)) }, props, { onClick: _onClick, type: "submit", fullWidth: true, color: 'primary', variant: "contained", disabled: disabled || isLoading }),
            text,
            ' ',
            isLoading && (React.createElement("div", { style: { position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' } },
                React.createElement(CircularIndeterminate, null))))));
};
