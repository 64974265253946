var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ItemHeader from '../../Costs/CostItem/ItemHeader';
import ItemBody from './ItemBody';
import { ExpandedRow } from '../../../components/BasicList/Body/ExpandedRow';
export default function InvoiceItem(_a) {
    var config = _a.config, record = _a.record, onChange = _a.onChange, headerColumns = _a.headerColumns;
    return (React.createElement(ExpandedRow, __assign({ v3: true }, { record: record, onChange: onChange }, { config: config, body: React.createElement(ItemBody, { record: record, config: config }), header: function (expandContent, handleExpandClick) { return (React.createElement(ItemHeader, { config: config, record: record, onChange: onChange, expandContent: expandContent, handleExpandClick: handleExpandClick, headerColumns: headerColumns })); } })));
}
