import { makeStyles, createStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                maxWidth: '100%',
                marginBottom: theme.spacing(2.5)
            },
            // padding: theme.spacing(0.2, 0),
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _a),
        orderRoot: {
            marginBottom: theme.spacing(1),
        },
    });
});
