var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus } from '../stores/common';
export var InvoiceType = types
    .compose(AsyncActionStatus, types.model('InvoiceType', {
    _id: types.optional(types.identifier, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.optional(types.string, '')),
    serialPrefix: types.maybeNull(types.optional(types.array(types.string), [])),
    format: types.maybeNull(types.optional(types.array(types.string), [])),
}))
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(InvoiceType.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); });
