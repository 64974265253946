import React from 'react';
import { client } from '../../api/apolloClient';
import { BaseUserAppTYpe } from '@izi-logistics/common/lib/stores/common/types';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const AccountPage = React.lazy(() => import('@izi-logistics/common/lib/pages/AccountPage'));

const Account = (props) => (
  <PreloadingComponent>
    <AccountPage {...props} config={{ client }} appType={BaseUserAppTYpe.CUSTOMER} />
  </PreloadingComponent>
);
export default Account;
