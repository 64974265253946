var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSectionStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../components';
import { GlobalChatSocket } from './stores/ChannelChat';
import ChannelItemDetails from './ChannelItemDetails';
export var DefaultChanel = function () {
    var classes = useSectionStyles();
    var langChat = useTranslation(KEYS.CHAT);
    return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', className: classes.section },
        React.createElement(TextOverflow, { variant: 'subtitle1' }, langChat.t('default_text_chat'))));
};
export var ChatItem = function (props) { return (React.createElement(GlobalChatSocket, { client: props.config && props.config.client },
    React.createElement(ChannelItemDetails, __assign({}, props)))); };
