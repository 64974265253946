var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { BaseUserAppTYpe } from '../../../stores/common';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { AvatarItemView } from '../../../components/Account/Avatar';
import React from 'react';
import { useAppTypeStyles } from '../../../styles';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { theme } from '../../../theme';
import BaseTooltip from '../../../components/BaseTooltip';
import { getAllBaseUserAppTypeAccessForDocuments } from '../../../utils1';
import { isHideForwarderToCustomer } from './utils';
export default inject('store')(function (propsAll) {
    var _a;
    var record = propsAll.record, onChange = propsAll.onChange, item = propsAll.item, quote = propsAll.quote, isEditable = propsAll.isEditable, isLocation = propsAll.isLocation, isAll = propsAll.isAll, currentUser = propsAll.store.accountStore.currentUser, props = __rest(propsAll, ["record", "onChange", "item", "quote", "isEditable", "isLocation", "isAll", "store"]);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var classes = useAppTypeStyles();
    var handleCheckChange = function (e) {
        var name = e.target.name;
        onChange({
            target: {
                name: props.name || 'access',
                value: record.access.includes(name) ? record.access.filter(function (e) { return e !== name; }) : __spread(record.access, [name]),
            },
        });
    };
    var isInvoice = ((_a = record.type) === null || _a === void 0 ? void 0 : _a.name) === 'Transport invoice';
    var canEdit = isEditable;
    var _access = getAllBaseUserAppTypeAccessForDocuments(quote, currentUser, { isLocation: isLocation });
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var _b = isHideForwarderToCustomer({
        quote: quote,
        currentUser: currentUser,
        accountStore: propsAll.store.accountStore,
    }), hideForwarderToCarrier = _b.hideForwarderToCarrier, hideForwarderToCustomer = _b.hideForwarderToCustomer;
    return (React.createElement(React.Fragment, null, Object.values(BaseUserAppTYpe)
        .filter(function (e) {
        return ![
            BaseUserAppTYpe.WAREHOUSE,
            // BaseUserAppTYpe.SENDER,
            BaseUserAppTYpe.CONSIGNEE,
            BaseUserAppTYpe.SYSTEM,
            BaseUserAppTYpe.AFFILIATE,
        ].includes(e);
    })
        .map(function (e) {
        if (!isAll && _access && !_access(e)) {
            return null;
        }
        var updatedE = e;
        if (hideForwarderToCarrier) {
            updatedE = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CARRIER : e;
        }
        if (hideForwarderToCustomer) {
            updatedE = e === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CUSTOMER : e;
        }
        var title = langApp.t("user_app_type_" + updatedE.toLowerCase());
        var user = {
            firstName: title.charAt(0),
            lastName: title.charAt(1),
        };
        if ([BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(e)) {
            title = langOfferForm.t("offer_uploads_" + BaseUserAppTYpe.SENDER.toLowerCase() + "_" + BaseUserAppTYpe.CONSIGNEE.toLowerCase());
            user.lastName = title.split('/')[1].toUpperCase();
            user.firstName = title.split('/')[0].toUpperCase();
        }
        var _canEdit = canEdit;
        if (props.v2 && isInvoice && isCarrier && [BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CUSTOMER].includes(e)) {
            _canEdit = false;
        }
        return (React.createElement(BaseTooltip, { title: title, key: e },
            React.createElement(FormControlLabel, { labelPlacement: "start", style: { margin: 0 }, disabled: !_canEdit, onClick: !_canEdit ? function () { return 0; } : function () { return handleCheckChange({ target: { name: e } }); }, control: React.createElement(Checkbox, { checked: item.access.includes(e), disabled: !_canEdit, name: e, color: "primary" }), label: !props.v1 ? (React.createElement(AvatarItemView, { className: classes[updatedE.toLowerCase()], typographyProps: {
                        variant: 'h6',
                        style: {
                            fontSize: 14,
                            fontWeight: 600,
                            color: theme.palette.common.white,
                        },
                    }, user: user })) : null })));
    })));
});
export var AppType = function (_a) {
    var type = _a.type, typographyProps = _a.typographyProps, props = __rest(_a, ["type", "typographyProps"]);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var title = type === 'NA' ? type : langApp.t("user_app_type_" + type.toLowerCase());
    var classes = useAppTypeStyles();
    var user = {
        firstName: title.charAt(0),
        lastName: title.charAt(1),
    };
    if ([BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(type)) {
        title = langOfferForm.t("offer_uploads_" + BaseUserAppTYpe.SENDER.toLowerCase() + "_" + BaseUserAppTYpe.CONSIGNEE.toLowerCase());
        user.lastName = title.split('/')[1].toUpperCase();
        user.firstName = title.split('/')[0].toUpperCase();
    }
    var content = (React.createElement(AvatarItemView, { avatarStyle: props.avatarStyle, className: classes[type.toLowerCase()], typographyProps: {
            variant: 'h6',
            style: __assign({ fontSize: 14, fontWeight: 600, color: theme.palette.common.white }, typographyProps),
        }, user: user }));
    return (React.createElement(BaseTooltip, { title: title, key: type }, (props.fixed && (React.createElement("div", { style: { position: 'absolute', zIndex: 1, width: 22, height: 22, top: -8, right: -8 } }, content))) || React.createElement("div", null, content)));
};
