import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { AsyncActionStatus } from '../stores/common';
export var UPLOAD_TYPE;
(function (UPLOAD_TYPE) {
    UPLOAD_TYPE["Avatar"] = "Avatar";
    UPLOAD_TYPE["Invoice"] = "Invoice";
    UPLOAD_TYPE["Another"] = "Another";
})(UPLOAD_TYPE || (UPLOAD_TYPE = {}));
export var Upload = types
    .compose(AsyncActionStatus, types
    .model('Upload', {
    _id: types.optional(types.string, function () { return ''; }),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    size: types.maybeNull(types.optional(types.number, 0)),
    access: types.maybeNull(types.optional(types.array(types.string), [])),
    name: types.maybeNull(types.optional(types.string, '')),
    parent_collection_name: types.maybeNull(types.optional(types.string, '')),
    emails: types.maybeNull(types.optional(types.array(types.string), [])),
    parent: types.maybeNull(types.optional(types.string, '')),
    path: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Upload.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); })
    .views(function (self) { return ({}); }))
    .named('Upload');
