var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { withCalendarStore } from '../store';
import { ACTIVE_COLOR, useMonthDayStyles } from '../MonthViewV1/style';
import { toDateOnly } from '../../../utils';
import { DayOrderDetails } from '../MonthViewV1/DayOrderDetails';
import { onPreventEvent } from '../../Dialog';
import { cellTransportations } from './utils';
import { BaseUserAppTYpe } from '../../../stores';
import { inject } from 'mobx-react';
export var DayHourView = inject('store')(withCalendarStore(function (_a) {
    var _b, _c, _d, _e, _f;
    var _g, _h, _j, _k, _l, _m, _o;
    var day = _a.day, hour = _a.hour, item = _a.item, _p = _a.state, warehouse = _p.warehouse, transportations = _p.transportations, workingDays = _p.workingDays, holidays = _p.holidays, dragId = _p.dragId, reservedDays = _p.reservedDays, isStyled = _a.isStyled, allDayTransportations = _a.allDayTransportations, rowSpan = _a.rowSpan, store = _a.store, props = __rest(_a, ["day", "hour", "item", "state", "isStyled", "allDayTransportations", "rowSpan", "store"]);
    var className = useMonthDayStyles();
    if (!rowSpan)
        return null;
    var _q = store.accountStore, currentUser = _q.currentUser, currentCompany = _q.currentCompany, warehouseStore = store.warehouseStore;
    var currentDay = toDateOnly(day);
    var currentDayMoment = moment(toDateOnly(day));
    var currentWeekDay = currentDayMoment.lang('en').format('dddd');
    var dayTransportations = allDayTransportations || cellTransportations({ transportations: transportations, day: day, hour: hour, item: item });
    var isPublic = !!location.pathname.match(/^\/public\//gi);
    var content = (dayTransportations.length && (React.createElement(DayOrderDetails, { dayTransportations: dayTransportations, currentDay: currentDay, hour: hour, rowSpan: rowSpan, config: props.config, item: item, onChange: props.onChange, isStyled: isStyled, canDrag: true, rows: props.rows, hours: props.hours, history: props.history }))) ||
        null;
    if (props.notAvailable) {
        return (React.createElement("td", __assign({ style: __assign({}, props.style) }, (props.colSpan && { colSpan: props.colSpan }), { rowSpan: rowSpan, className: clsx((_b = {},
                _b[className.disabled] = 1,
                _b[className.disabledV1] = 1,
                _b), className.rootHour), id: currentDay + "-" + (hour || 1) }, (_c = {},
            _c['data-id'] = "izi-warehouse-day-" + currentDay,
            _c['data-cell-id'] = ("order-" + item.ramp._id + "-" + currentDay + "-" + hour).replace(/\//g, '-').replace(/\:/g, '-'),
            _c)), content));
    }
    var isToDay = toDateOnly(day) === toDateOnly();
    var currentHourInZone = isToDay && ((_h = (_g = warehouse === null || warehouse === void 0 ? void 0 : warehouse.address) === null || _g === void 0 ? void 0 : _g.location) === null || _h === void 0 ? void 0 : _h.zone)
        ? new Date().toLocaleString('ua', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: (_k = (_j = warehouse === null || warehouse === void 0 ? void 0 : warehouse.address) === null || _j === void 0 ? void 0 : _j.location) === null || _k === void 0 ? void 0 : _k.zone,
        })
        : hour;
    var isHourAvailable = isToDay && hour >= currentHourInZone;
    var isForwarder = currentCompany.type === BaseUserAppTYpe.FORWARDER;
    var isForwarderForManager = isForwarder && ((_o = (_m = (_l = warehouseStore.item) === null || _l === void 0 ? void 0 : _l.manager) === null || _m === void 0 ? void 0 : _m.forwarders) === null || _o === void 0 ? void 0 : _o.find(function (e) { var _a, _b; return ((_a = e === null || e === void 0 ? void 0 : e._id) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser._id) === null || _b === void 0 ? void 0 : _b.toString()); }));
    var isAvailable = (!isToDay || isHourAvailable) &&
        toDateOnly(day) >= toDateOnly() &&
        workingDays.data.find(function (e) { return e.day === currentWeekDay && e.maxTime && e.maxTime._id; }) &&
        !holidays.data.filter(function (e) {
            return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
                moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
                [warehouse === null || warehouse === void 0 ? void 0 : warehouse._id, item.ramp._id].includes(e.warehouse._id);
        }).length;
    var onDragOver = function (e) {
        return onPreventEvent(e);
    };
    var onDragEnter = function (e) {
        if (e.currentTarget.getAttribute('datatype') === 'order-can-drop') {
            if (e.currentTarget.getAttribute('data-none-over')) {
            }
            else {
                e.currentTarget.setAttribute('style', "background-color: " + ACTIVE_COLOR + "!important");
            }
        }
        return onPreventEvent(e);
    };
    var onDragLeave = function (e) {
        e.currentTarget.style.backgroundColor = '';
    };
    var onDrop = function (e) {
        try {
            var day_1 = JSON.parse(e.dataTransfer.getData('day'));
            e.currentTarget.style.backgroundColor = '';
            if (currentDay === day_1.date && hour === day_1.time[0] && item.ramp._id === day_1.item.ramp._id) {
                return console.log('no changes');
            }
            if (e.currentTarget.children.length) {
                return console.log('cell is busy');
            }
            if (props.onChange)
                props.onChange({ oldData: day_1, newData: { day: currentDay, hour: hour, item: item } });
        }
        catch (e) {
            console.log(e);
        }
    };
    var onClick = function () {
        props.onClick &&
            !isForwarderForManager &&
            props.onClick({ date: currentDay, time: hour, item: item, transportations: dayTransportations });
    };
    var available = (dragId && isAvailable) || !dragId;
    var isBusy = dayTransportations.find(function (e) { var _a; return e.isBusy || ((_a = e.record) === null || _a === void 0 ? void 0 : _a.isBusy); });
    var bookCeil = !(!isAvailable || isBusy);
    var dayDateOnly = toDateOnly(day);
    return (React.createElement("td", __assign({ style: __assign({}, props.style) }, (props.colSpan && { colSpan: props.colSpan }), { rowSpan: rowSpan }, (bookCeil && { onClick: onClick }), { className: clsx((_d = {},
            _d[className.bookCell] = bookCeil,
            _d[className.disabled] = !isAvailable,
            _d[className.disabledV1] = isBusy,
            _d[className.reservedDaysBackground] = bookCeil && reservedDays.data.includes(dayDateOnly),
            _d), className.rootHour), id: currentDay + "-" + (hour || 1) }, (available &&
        !isBusy &&
        !isPublic && __assign(__assign((_e = { onDragOver: onDragOver,
            onDragLeave: onDragLeave,
            onDragEnter: onDragEnter,
            onDrop: onDrop }, _e['data-id'] = "izi-warehouse-day-" + currentDay, _e), (!dayTransportations.length && { datatype: 'order-can-drop' })), (_f = {}, _f['data-cell-id'] = ("order-" + item.ramp._id + "-" + currentDay + "-" + hour).replace(/\//g, '-').replace(/\:/g, '-'), _f)))), !isBusy && dayTransportations.length ? content : null));
}));
