var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import { withBaseListStore } from '../store';
import * as material from '@material-ui/core';
import { MainButton } from '../../MainButton';
import { i18n, KEYS } from '../../../i18n';
import { TextOverflow } from '../../TextOverflow';
import { theme } from '../../../theme';
import ResetFilter from '../ResetFilter';
var useMediaQuery = material.useMediaQuery;
function BaseListFiltersWrapper(_a) {
    var _b, _c, _d;
    var list = _a.list, options = _a.options, onToggleAllFilters = _a.onToggleAllFilters, onSaveFilters = _a.onSaveFilters, state = _a.state, style = _a.style, props = __rest(_a, ["list", "options", "onToggleAllFilters", "onSaveFilters", "state", "style"]);
    var langApp = useTranslation(KEYS.APP);
    var langFormLoc = useTranslation(KEYS.FORM_LOCATIONS);
    var onSave = function () {
        onSaveFilters(state.filter, { forceSave: 1 });
        toast.success(i18n
            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_filters')));
    };
    var availableFilters = (((_b = options === null || options === void 0 ? void 0 : options.config) === null || _b === void 0 ? void 0 : _b.availableFilters) || []).map(function (e) { return e.name; });
    var showAll = state.showAdvancedFilters;
    var _options = list.filter(function (e) { return e && (showAll || !(availableFilters === null || availableFilters === void 0 ? void 0 : availableFilters.length) || (!showAll && availableFilters.includes(e.name))); });
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var isMiddle = useMediaQuery(theme.breakpoints.down('md'));
    var isNotOneRow = isMiddle || showAll;
    return (React.createElement(Grid, { container: true, direction: "row", alignItems: "flex-start", justify: "space-between" },
        React.createElement(Grid, { container: isNotOneRow, direction: "row", item: true, xs: 12, md: state.hasUserFilters ? 10 : 11, style: __assign(__assign(__assign(__assign(__assign(__assign({}, style), (props.isAdmin && { flexWrap: 'wrap' })), { alignItems: 'center' }), (isSmall && { marginBottom: theme.spacing(1) })), (!isNotOneRow && { display: 'flex', flexDirection: 'row' })), (showAll && { alignItems: undefined })) }, _options.map(function (e) { return (React.createElement(React.Fragment, { key: e.name }, e.content)); })),
        React.createElement(Grid, { container: true, direction: "row", item: true, xs: 12, md: state.hasUserFilters ? 2 : 1 },
            React.createElement(Grid, { container: true, alignItems: "center", style: { marginBottom: theme.spacing(1) }, spacing: 1 },
                (state.hasUserFilters && (React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(ResetFilter, __assign({}, props, { config: options === null || options === void 0 ? void 0 : options.config, style: { width: '100%', margin: 0 } }))))) ||
                    null,
                React.createElement(Grid, { item: true, xs: 12, md: state.hasUserFilters ? 6 : 12 },
                    React.createElement(MainButton, { onClick: onSave, color: 'primary', style: { width: '100%', margin: 0 }, variant: "outlined", title: langApp.t('save_filter') }))),
            (((_d = (_c = options === null || options === void 0 ? void 0 : options.config) === null || _c === void 0 ? void 0 : _c.availableFilters) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(TextOverflow, { onClick: onToggleAllFilters, style: {
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: state.hasUserFilters ? 'right' : 'center',
                    color: theme.palette.common.white,
                    textDecoration: 'underline',
                }, variant: "caption" }, showAll ? langApp.t('collapse_less') : langFormLoc.t('form_input_location_mode_advanced')))) ||
                null)));
}
export default withBaseListStore(BaseListFiltersWrapper);
