var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { FormikAutocomplete, formikAutocompleteMultiOptionsProps } from '../../Formik';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import * as formikA from 'formik';
import { DeleteDialog } from '../../Dialog';
// import { AddFormButton } from '../../AddFormButton';
import UploadType from '../../../pages/Quotes/dialogs/UploadType';
import { theme } from '../../../theme';
import DocumentItemVisibleTo from '../../../pages/Quotes/Documents/DocumentItemVisibleTo';
import * as Yup from 'yup';
import { TextOverflow } from '../../TextOverflow';
var FieldArray = formikA.FieldArray;
var Form = formikA.Form, Formik = formikA.Formik;
export var LOCATION_DIALOG_EVENTS = {
    LOCATION_DIALOG_ADDITIONAL_ADD: 'LOCATION_DIALOG_ADDITIONAL_ADD',
    LOCATION_DIALOG_ADDITIONAL_UPDATE: 'LOCATION_DIALOG_ADDITIONAL_UPDATE',
};
export default React.forwardRef(function (_a, ref) {
    var lang_key = _a.lang_key, onChange = _a.onChange, props = __rest(_a, ["lang_key", "onChange"]);
    var _b = __read(React.useState(1), 2), updates = _b[0], setUpdates = _b[1];
    var blankAddRef = React.useRef(null);
    var blankRemoveRef = React.useRef(null);
    var _c = __read(React.useState(props.defaultValue || {}), 2), localValues = _c[0], setLocalValues = _c[1];
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langFormLocation = useTranslation(KEYS.FORM_LOCATIONS);
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var _d = __read(React.useState({
        advancedEmails: props.defaultValue
            ? props.defaultValue.map(function (e) { return (__assign(__assign({}, e), { access: e.access || [] })); })
            : [
                {
                    access: [],
                    email: [],
                    documentType: { _id: '' },
                },
            ],
    }), 2), defaultValue = _d[0], setDefaultValue = _d[1];
    var _e = __read(React.useState({}), 2), updated = _e[0], setUpdated = _e[1];
    var _f = __read(React.useState([
        {
            name: LOCATION_DIALOG_EVENTS.LOCATION_DIALOG_ADDITIONAL_ADD,
            context: document.body,
            callback: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (blankAddRef === null || blankAddRef === void 0 ? void 0 : blankAddRef.current)
                        blankAddRef.current.click();
                    return [2 /*return*/];
                });
            }); },
        },
        {
            name: LOCATION_DIALOG_EVENTS.LOCATION_DIALOG_ADDITIONAL_UPDATE,
            context: document.body,
            callback: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (blankRemoveRef === null || blankRemoveRef === void 0 ? void 0 : blankRemoveRef.current)
                        blankRemoveRef.current.click();
                    return [2 /*return*/];
                });
            }); },
        },
    ]), 1), events = _f[0];
    React.useEffect(function () {
        events.forEach(function (_a) {
            var name = _a.name, context = _a.context, callback = _a.callback;
            context.addEventListener(name, callback);
        });
        return function () {
            events.forEach(function (_a) {
                var name = _a.name, context = _a.context, callback = _a.callback;
                context.removeEventListener(name, callback);
            });
        };
    }, []);
    var onSubmit = function (values, actions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // actions.resetForm();
            // console.log('values====', values);
            return [2 /*return*/, values];
        });
    }); };
    return (React.createElement(Grid, null,
        React.createElement(Formik, { key: props.key || props.defaultValue ? 1 : 0, initialValues: defaultValue, validationSchema: Yup.object().shape({
                advancedEmails: Yup.array()
                    .typeError(langForm.t('form_input_validate_required'))
                    .of(Yup.object().shape({
                    email: Yup.array()
                        .typeError(langForm
                        .t('form_input_validate_required_list')
                        .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                        .of(Yup.string()
                        .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                        .email(langForm.t('form_input_validate_invalid_email')))
                        .max(10, langForm
                        .t('form_input_validate_field_must_have_no_more')
                        .replace(/_X_/, '10')
                        .replace(/_XX_/, langForm.t('form_input_name_email').toLowerCase())),
                })),
            }), onSubmit: onSubmit, innerRef: ref }, function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, validateField = _a.validateField, errors = _a.errors, setFieldTouched = _a.setFieldTouched;
            var onFieldChangeHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
                var keys_1, manKey_1, dataValue_1, index;
                var _a;
                return __generator(this, function (_b) {
                    // console.log('onFieldChangeHandler', e.target.name, e.target.value);
                    setFieldValue(e.target.name, e.target.value);
                    if (e.target.name.startsWith('advancedEmails') && typeof e.target.value !== 'string') {
                        keys_1 = e.target.name.split('.');
                        manKey_1 = keys_1[0];
                        dataValue_1 = function (val) {
                            var _a;
                            if (Array.isArray(val)) {
                                return __spread(val).map(function (e) { return dataValue_1(e); });
                            }
                            var newData = __assign(__assign({}, val), (_a = {}, _a[manKey_1] = __spread(val[manKey_1]), _a));
                            newData[manKey_1][keys_1[1]][keys_1[2]] = e.target.value;
                            return newData;
                        };
                        setLocalValues(__assign(__assign({}, localValues), (_a = {}, _a[e.target.name] = e.target.value, _a)));
                        setDefaultValue(dataValue_1(defaultValue));
                        // console.log({ ...defaultValue, [e.target.name]: e.target.value });
                        try {
                            validateField(e.target.name);
                            index = parseInt(keys_1[1]);
                            if (errors && errors['advancedEmails'] && errors['advancedEmails'][index].email) {
                                return [2 /*return*/, setFieldTouched(e.target.name)];
                            }
                        }
                        catch (e) { }
                    }
                    if (onChange)
                        onChange();
                    return [2 /*return*/];
                });
            }); };
            var _onBlur = function (e) {
                setTimeout(function () {
                    if (onChange)
                        onChange();
                }, 500);
            };
            var onBlur = function (e) {
                var value = [];
                if (Array.isArray(values[e.target.name])) {
                    value = values[e.target.name];
                    onFieldChangeHandler({ target: { name: e.target.name, value: value } });
                }
                else if (typeof e.target.value === 'string') {
                    var newValue = e.target.value.trim();
                    value = __spread((localValues[e.target.name] || []), [newValue]);
                    if (newValue !== '') {
                        onFieldChangeHandler({
                            target: { name: e.target.name, value: value },
                        });
                    }
                }
                setUpdates(Date.now());
                _onBlur(e);
            };
            var onDeleteEmail = function (email, name1, index, name2) {
                var value = [];
                // console.log('values[name1][index][name2]', values, email, name1, index, name2);
                try {
                    if (Array.isArray(values[name1][index][name2])) {
                        value = values[name1][index][name2].filter(function (e) { return e !== email; });
                    }
                    else {
                        value = defaultValue[name1][index][name2].filter(function (e) { return e !== email; });
                    }
                }
                catch (e) {
                    // console.log(values[name1], values[name1][index], values[name1][index][name2]);
                }
                onFieldChangeHandler({
                    target: { name: name1 + "." + index + "." + name2, value: value },
                });
                setUpdates(Date.now());
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(Form, { style: { width: '100%' }, id: 'form-order-create-location-shipping-documents' },
                    React.createElement(FieldArray, { name: "advancedEmails" }, function (_a) {
                        var insert = _a.insert, remove = _a.remove, push = _a.push;
                        return (React.createElement("div", null,
                            values.advancedEmails.length < 10 ? (React.createElement("span", { ref: blankAddRef, style: { visibility: 'hidden', position: 'absolute' }, onClick: function () {
                                    var newItem = {
                                        email: [],
                                        access: [],
                                        documentType: { _id: '' },
                                    };
                                    push(newItem);
                                    setDefaultValue(__assign(__assign({}, defaultValue), { advancedEmails: __spread(defaultValue.advancedEmails, [newItem]) }));
                                    setLocalValues(__assign(__assign({}, localValues), { advancedEmails: __spread(defaultValue.advancedEmails, [newItem]) }));
                                    if (onChange)
                                        onChange();
                                } })) : null,
                            values.advancedEmails.map(function (user, index) {
                                return (React.createElement(Grid, { container: true, direction: "row", "data-id": "row-item-" + index + "-" + (user._id || ''), key: index, style: { marginBottom: theme.spacing(1) } },
                                    React.createElement(Grid, { container: true, spacing: 2, alignItems: 'center' },
                                        React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                            React.createElement(FormikAutocomplete, __assign({ v17: true, v25: true }, formikAutocompleteMultiOptionsProps(values.advancedEmails[index].email, onBlur, function (email) { return onDeleteEmail(email, 'advancedEmails', index, 'email'); }), { key: updates, title: langFormLocation.t("form_location_pickup_email"), variant: "outlined", name: "advancedEmails." + index + ".email", value: values.advancedEmails[index].email, onChange: onFieldChangeHandler }))),
                                        React.createElement(Grid, { item: true, xs: 12, sm: 3 },
                                            React.createElement(UploadType, { label: langForm.t('form_input_upload_type'), title: langForm.t('form_input_upload_type'), name: "advancedEmails." + index + ".documentType", key: updated["documentType" + index], defaultValue: values.advancedEmails[index].documentType, autocompleteProps: {
                                                    value: values.advancedEmails[index].documentType,
                                                    defaultValue: values.advancedEmails[index].documentType,
                                                }, config: props.config, onChange: function (value) {
                                                    var _a;
                                                    onFieldChangeHandler({
                                                        target: { name: "advancedEmails." + index + ".documentType", value: value },
                                                    });
                                                    _onBlur(null);
                                                    setUpdated(__assign(__assign({}, updated), (_a = {}, _a["documentType" + index] = Date.now(), _a)));
                                                } })),
                                        React.createElement(Grid, { container: true, item: true, xs: 12, sm: 5, justify: 'flex-end', alignItems: 'center', style: { position: 'relative', paddingRight: 20 } },
                                            React.createElement(Grid, { container: true, item: true, xs: true, style: {
                                                    marginRight: theme.spacing(2),
                                                    justifyContent: !isSmall ? 'flex-end' : undefined,
                                                } },
                                                React.createElement(TextOverflow, null, langOfferForm.t('offer_form_document_visible'))),
                                            React.createElement(Grid, { container: true, item: true, style: { width: 'initial' } },
                                                React.createElement(DocumentItemVisibleTo, { v1: !isSmall, key: values.advancedEmails[index].access.join('_'), quote: { order: { forwarders: [] } }, isEditable: true, isLocation: true, item: { createdBy: {}, access: values.advancedEmails[index].access }, record: { access: values.advancedEmails[index].access }, name: "advancedEmails." + index + ".access", onChange: function (e) {
                                                        onFieldChangeHandler(e);
                                                        _onBlur(e);
                                                    } }),
                                                React.createElement("div", { style: { position: 'absolute', top: 15, right: -4 } },
                                                    React.createElement("span", { onClick: function () {
                                                            var max = 0;
                                                            while (values.advancedEmails.length && max++ < 10) {
                                                                remove(0);
                                                            }
                                                        }, style: { position: 'absolute', visibility: 'hidden' }, ref: blankRemoveRef }),
                                                    React.createElement(DeleteDialog, { onOk: function () {
                                                            // _onBlur(null);
                                                            remove(index);
                                                        }, v1: true, v4: true })))))));
                            })));
                    }))));
        })));
});
