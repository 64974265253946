import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
import { useMonthDayHolidayDetailsStyles } from './style';
import FlightIcon from '@material-ui/icons/Flight';
import { withCalendarStore } from '../store';
import { CALENDAR_ELEMENTS } from '../types';
import BaseTooltip from '../../BaseTooltip';
export var DayHolidayDetails = withCalendarStore(function (_a) {
    var style = _a.style, holidays = _a.holidays, warehouse = _a.warehouse, calendarElements = _a.state.calendarElements;
    var className = useMonthDayHolidayDetailsStyles();
    if (!calendarElements.includes(CALENDAR_ELEMENTS.HOLIDAYS))
        return null;
    var holidaysList = holidays.filter(function (e) { return (warehouse && e.warehouse._id === warehouse._id) || !warehouse; });
    if (!holidaysList.length)
        return null;
    return (React.createElement(Grid, { container: true, direction: 'row', className: className.root, style: style }, holidaysList.map(function (holiday) {
        var content = (React.createElement(Grid, { key: holiday._id, container: true, className: className.item, justify: 'space-between', alignItems: 'center' },
            React.createElement(TextOverflow, { className: className.label }, holiday.name),
            React.createElement(FlightIcon, null)));
        if (!holiday.description)
            return content;
        return React.createElement(BaseTooltip, { title: holiday.description }, content);
    })));
});
