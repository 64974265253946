import { makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        rootWarning: {
            '& .MuiInputLabel-root': {
                color: theme.palette.warning.light,
            },
            '& .Mui-error:after': {
                borderBottomColor: theme.palette.warning.light,
            },
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.warning.light,
            },
        },
        rootPrimaryStyle: {
            '& input': {
                color: theme.palette.primary.main,
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: '3px',
                    borderColor: theme.palette.primary.main,
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        rootOverflowStyle: {
            '& input': { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
        },
        base: {
            '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
                transform: 'translate(8px, 9px) scale(1)',
                textOverflow: 'ellipsis',
                width: '70%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            '& .Mui-error': {
                '& path': {
                    fill: theme.palette.error.main + "!important",
                },
                '& input::placeholder': {
                    color: theme.palette.error.main,
                },
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
                width: '100%',
                height: '25px',
            },
            '& fieldset': {
                minWidth: 20,
            },
            '& input': (_a = {
                    outline: 'none',
                    minWidth: "60px!important"
                },
                _a[theme.breakpoints.down('xs')] = {
                    minWidth: "60px!important",
                },
                _a),
        },
        baseV1: {
            '& input': (_b = {
                    minWidth: "1px!important"
                },
                _b[theme.breakpoints.down('xs')] = {
                    minWidth: "1px!important",
                },
                _b),
        },
        baseV2: {
            '& .MuiInput-root': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiInput-root.Mui-error': {
                marginBottom: '0px!important',
            },
        },
        baseErrorV1: {
            '& .MuiFormHelperText-root.Mui-error': {
                bottom: -4,
            },
            '& label + .MuiInput-formControl': {
                marginTop: theme.spacing(0.5),
            },
        },
        fixedLabel: {
            '& .MuiInputLabel-root': {
                transform: 'translate(8px, 9px) scale(1)',
            },
        },
        baseStartAdornment: {
            '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
                transform: 'translate(30px, 9px) scale(1)',
                textOverfl0ow: 'ellipsis',
                width: '60%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
                width: '100%',
                height: '25px',
            },
            '& fieldset': {
                minWidth: 20,
            },
        },
        updatedLegend: {
            '& legend': {
                maxWidth: '90%',
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                width: '70%',
            },
        },
    });
});
