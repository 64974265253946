var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_CERTIFICATE_BASE = "\n    " + BASE_QUERY + "\n     name\n     description\n";
export var CreateOrderCertificate = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation createOrderCertificate($input: OrderCertificateInput!) {\n        createOrderCertificate(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCertificate($input: OrderCertificateInput!) {\n        createOrderCertificate(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CERTIFICATE_BASE);
export var UpdateOrderCertificate = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateOrderCertificate($input: OrderCertificateInputUpdate!) {\n        updateOrderCertificate(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCertificate($input: OrderCertificateInputUpdate!) {\n        updateOrderCertificate(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CERTIFICATE_BASE);
export var FetchOrderCertificates = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderCertificates($filter:MainFilter){\n        orderCertificates(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderCertificates($filter:MainFilter){\n        orderCertificates(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_CERTIFICATE_BASE);
export var DeleteOrderCertificate = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderCertificate($_id: MongoId!) {\n        deleteOrderCertificate(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderCertificate($_id: MongoId!) {\n        deleteOrderCertificate(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
