var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import moment from 'moment';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import TimeKeeper from 'react-timekeeper';
import { onPreventEvent, Transition } from '../Dialog';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { MainButton } from '../MainButton';
import { makeStyles } from '@material-ui/core/styles';
import InputTitle from './InputTitle';
import { BaseTextField } from './BaseTextField';
export var useStyles = makeStyles(function (theme) { return ({
    root: {
        '&   .react-timekeeper * ': {
            fontFamily: theme.typography.body1.fontFamily,
        },
        '& .react-timekeeper__tb-hour--active': {
            color: theme.palette.primary.light,
        },
        '& .react-timekeeper__tb-minute--active': {
            color: theme.palette.primary.light,
        },
        '& svg line': {
            stroke: theme.palette.primary.light,
        },
        '& svg circle': {
            fill: theme.palette.primary.light,
        },
    },
}); });
export var startDay = function (time) {
    var d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return new Date(d.getTime() + time).toString();
};
export var parseTime = function (_time) {
    if (!_time)
        return _time;
    var time = _time.match(':') ? _time : _time + ":00";
    try {
        return time.toString().match(/[0-9\:]/gi) && time.toString().length < 6
            ? time
            : moment((parseInt(time).toString().length === time.toString().length && parseInt(time)) || time).format('HH:mm');
    }
    catch (e) {
        return '00:00';
    }
};
export var FormikWorkingHour = function (props) {
    var classes = useStyles();
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title;
    var _v = parseTime(props.value);
    var _e = __read(React.useState(_v), 2), timeValue = _e[0], setTimeValue = _e[1];
    var _f = __read(React.useState(false), 2), open = _f[0], setOpen = _f[1];
    var _g = __read(React.useState(0), 2), time = _g[0], setTime = _g[1];
    var _h = __read(useField(props), 3), field = _h[0], meta = _h[1], helpers = _h[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        var value = (e.hour < 10 ? '0' : '') + e.formatted24;
        helpers.setValue(value);
        setTimeValue(value);
        if (onChange)
            onChange({ target: { name: field.name, value: value } });
    };
    var onFocus = function (e) {
        if (props.disabled)
            return;
        if (time > Date.now())
            return;
        setOpen(true);
        return onPreventEvent(e);
    };
    var _onHandleClose = function () {
        setOpen(false);
    };
    var onClose = function (newTime) {
        if (props.onClose) {
            props.onClose((newTime.hour < 10 ? '0' : '') + newTime.formatted24);
        }
        setTime(Date.now() + 3000);
        _onHandleClose();
    };
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(BaseTextField, __assign({ style: __assign({ width: '100%' }, style), margin: "dense", variant: "outlined", InputLabelProps: {
                shrink: true,
            }, inputProps: {
                step: 300,
            }, error: !!touchedError }, field, props, { onChange: null, onClick: onFocus, onFocus: onPreventEvent, type: "text", helperText: touchedError || helperText })),
        (!props.disabled && (React.createElement(Dialog, { disableEnforceFocus: true, open: open, TransitionComponent: Transition, keepMounted: true, scroll: "body", onClose: _onHandleClose, className: classes.root, "aria-labelledby": "alert-dialog-slide-title", "aria-describedby": "alert-dialog-slide-description" },
            React.createElement(TimeKeeper, { time: timeValue, onChange: _onChange, hour24Mode: true, coarseMinutes: 5, forceCoarseMinutes: true, closeOnMinuteSelect: true, switchToMinuteOnHourSelect: true, doneButton: function (newTime) { return (React.createElement(MainButton, { style: { width: '100%', borderRadius: 0 }, onClick: function () { return onClose(newTime); }, title: 'Done' })); } })))) ||
            null)); });
};
