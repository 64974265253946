var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
export default function PaddingSpace(props) {
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    if (isSmall)
        return null;
    return (React.createElement(Grid, __assign({ item: true, xs: 12, md: 1 }, props), props.children));
}
export var PaddingSpaceSmall = function PaddingSpaceSmall(props) {
    var theme1 = useTheme();
    var isNotSmall = useMediaQuery(theme1.breakpoints.up('sm'));
    if (isNotSmall)
        return null;
    return (React.createElement(Grid, __assign({ item: true, xs: 12, md: 1 }, props), props.children));
};
