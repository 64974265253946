import { createStyles, makeStyles } from '@material-ui/core/styles';
export var DISABLED_COLOR = '#F3F6FF';
export var ACTIVE_COLOR = '#d4dfff';
export var useMonthDayHolidayDetailsStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            overflowY: 'auto',
            maxHeight: 120,
            minHeight: 28,
        },
        label: {
            maxWidth: 'calc(100% - 25px)!important',
        },
        item: {
            height: 24,
            background: theme.palette.primary.main,
            marginBottom: 1,
            padding: theme.spacing(0, 1),
            '& *': {
                color: theme.palette.common.white,
            },
        },
    });
});
export var useMonthDayDetailsStyles = makeStyles(function (theme) {
    return createStyles({
        iconArrived: {
            width: 14,
            height: 14,
            opacity: 0.3,
            '&:hover': {
                opacity: 1,
            },
        },
        root: {
            overflowY: 'hidden',
            // maxHeight: 120,
            flexGrow: 1,
        },
        disabledIcon: {
            '& *': {
                fill: theme.palette.secondary.main + "!important",
            },
        },
        item: {
            position: 'relative',
            borderRadius: 2,
            // background: theme.palette.primary.light,
            // marginBottom: 1,
            padding: theme.spacing(0, 1, 0.1, 3),
            '& *': {
                color: theme.palette.primary.main,
            },
            '&.hovered': {
                '& *': {
                    color: theme.palette.common.white + "!important",
                },
                '&::before': {
                    backgroundColor: theme.palette.common.white,
                },
                backgroundColor: theme.palette.success.light,
            },
            '&:hover': {
                '& *': {
                    color: theme.palette.common.white + "!important",
                },
                '&::before': {
                    backgroundColor: theme.palette.common.white,
                },
            },
            '& .inner-marker': {
                '& .MuiChip-root': {
                    height: theme.spacing(2),
                },
                '& *': {
                    color: theme.palette.common.white,
                },
            },
            '&::before': {
                content: "' '",
                width: 7,
                height: 7,
                left: theme.spacing(1),
                // zIndex: 2,
                borderRadius: '50%',
                position: 'absolute',
            },
        },
        itemNonWorking: {
            position: 'relative',
            borderRadius: 2,
            padding: theme.spacing(0, 1, 0.1, 3),
            '& *': {
                color: theme.palette.primary.main,
            },
            '& .inner-marker': {
                '& .MuiChip-root': {
                    height: theme.spacing(2),
                },
                '& *': {
                    color: theme.palette.common.white,
                },
            },
            '&::before': {
                content: "' '",
                width: 7,
                height: 7,
                left: theme.spacing(1),
                borderRadius: '50%',
                position: 'absolute',
            },
        },
    });
});
export var useMonthDayStyles = makeStyles(function (theme) {
    return createStyles({
        disabled: {
            // pointerEvents: 'none',
            background: theme.palette.grey[800] + "!important",
        },
        disabledV1: {
            pointerEvents: 'none',
            background: theme.palette.grey[800] + "!important",
        },
        bookCell: {
            cursor: 'pointer',
            background: DISABLED_COLOR + "!important",
            '&:hover': {
                background: theme.palette.success.light + "!important",
            },
        },
        root: {
            position: 'relative',
            height: 170,
            width: 170,
            maxWidth: 170,
            minWidth: 90,
            background: theme.palette.common.white,
            border: '1px solid #BAB8B8',
            boxSizing: 'border-box',
            verticalAlign: 'top',
            paddingTop: 40,
        },
        rootHour: {
            height: 30,
            position: 'relative',
            background: theme.palette.common.white,
            border: '1px solid #BAB8B8',
            boxSizing: 'border-box',
            verticalAlign: 'bottom',
            '&.dragging': {
                cursor: 'no-drop',
                '& *': {
                    pointerEvents: 'none',
                    opacity: 0.7,
                    cursor: 'cell',
                },
            },
            '&[datatype="order-can-drop"]': {
                cursor: 'initial',
            },
            '&.no-book': {
                cursor: 'no-drop',
                '& *': {
                    pointerEvents: 'none',
                },
            },
            '&.dragging-none': {
                cursor: 'no-drop',
                '& *': {
                // pointerEvents: 'none',
                },
            },
        },
        currentMonth: {
            color: theme.palette.primary.light,
            '&.has-orders': {
                position: 'relative',
                '&::before': {
                    backgroundColor: theme.palette.primary.light + "!important",
                },
            },
        },
        anotherMonthBackground: {
            background: theme.palette.secondary.light + "!important",
        },
        reservedDaysBackground: {
            background: "#e6edf6!important",
        },
        label: {
            fontFamily: 'sans-serif',
            // position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 34,
            height: 34,
            // top: 2,
            // left: 2,
            fontWeight: 600,
            fontSize: 20,
            padding: theme.spacing(1),
            cursor: 'pointer',
            '&:hover': {
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
                '&.has-orders': {
                    position: 'relative',
                    '&::before': {
                        backgroundColor: theme.palette.common.white + "!important",
                    },
                },
            },
            '&.has-orders': {
                position: 'relative',
                '&::before': {
                    content: "' '",
                    width: 7,
                    height: 7,
                    borderRadius: '50%',
                    position: 'absolute',
                    bottom: 2,
                    background: theme.palette.grey[400],
                },
            },
        },
        labelItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 34,
            height: 34,
            padding: theme.spacing(1),
        },
        current: {
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
            '&.has-orders': {
                position: 'relative',
                '&::before': {
                    backgroundColor: theme.palette.common.white + "!important",
                },
            },
        },
        currentV1: {
            width: 'inherit',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        },
    });
});
