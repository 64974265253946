var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY } from './base';
import { LOGS_PHONE_NOTIFICATION_BASE_SHORT } from './logs';
export var CONTACT_BASE = "\n      " + BASE_QUERY + "\n      name\n      value\n      status\n      log{\n        " + LOGS_PHONE_NOTIFICATION_BASE_SHORT + "\n      }\n      responseDetails\n      ";
export var FetchContacts = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query contacts($filter: MainFilter) {\n        contacts(filter: $filter) {\n            data {\n              ", "\n            }\n            count\n        }\n    }\n"], ["\n    query contacts($filter: MainFilter) {\n        contacts(filter: $filter) {\n            data {\n              ", "\n            }\n            count\n        }\n    }\n"])), CONTACT_BASE);
export var UpdateContact = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation updateContact($input: ContactInputUpdate!) {\n    updateContact(input: $input) {\n     ", "\n    }\n  }\n"], ["\n  mutation updateContact($input: ContactInputUpdate!) {\n    updateContact(input: $input) {\n     ", "\n    }\n  }\n"])), CONTACT_BASE);
var templateObject_1, templateObject_2;
