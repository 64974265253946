var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useTheme } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import { HelpIconComponent } from '../HelpIcon';
import InputTitle from './InputTitle';
import { TextOverflow } from '../TextOverflow';
import clsx from 'clsx';
import { useStyles } from './styles';
import { BaseTextField } from './BaseTextField';
export var FormikTextField = React.forwardRef(function (props, ref) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.fullWidth, fullWidth = _b === void 0 ? true : _b, _c = props.variant, variant = _c === void 0 ? 'standard' : _c, title = props.title, warning = props.warning, inputProps = __rest(props, ["helperText", "onChange", "fullWidth", "variant", "title", "warning"]);
    var margin = props.margin || 'dense';
    var classes = useStyles();
    var theme = useTheme();
    var _d = __read(useField(props), 3), field = _d[0], meta = _d[1], helpers = _d[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var onKeyDown = function (e) {
        if (e.key === 'Enter') {
            if (props.onEnter)
                props.onEnter(e);
            if (props.multiline)
                _onChange({ target: { name: props.name, value: [field.value, '\n'].join('') } });
        }
        if (props.onKeyDown)
            props.onKeyDown(e);
    };
    var titlePlace = !props.withoutPlaceholder
        ? props.placeholder || (typeof props.title === 'string' && props.title) || null
        : '';
    var InputProps = __assign({}, inputProps.InputProps);
    if (inputProps.help && !InputProps.endAdornment && !title) {
        Object.assign(InputProps, {
            endAdornment: React.createElement(HelpIconComponent, { title: inputProps.help }),
        });
    }
    else if (inputProps.help && !InputProps.startAdornment) {
        Object.assign(InputProps, {
            startAdornment: React.createElement(HelpIconComponent, { title: inputProps.help }),
        });
    }
    else {
        Object.assign(InputProps, { title: titlePlace });
    }
    var errorText = touchedError || helperText;
    var style = __assign({ outline: 'none', marginTop: 0, marginBottom: 0 }, (props.v3 && { marginTop: theme.spacing(0.5) }));
    var warningText = !errorText ? props.warning : null;
    // if (errorText) Object.assign(style, { marginBottom: 12 });
    var withLabel = variant === 'outlined' && !(props.v1 || props.v14);
    if (withLabel) {
        Object.assign(inputProps, {
            label: props.title || titlePlace,
        });
        delete inputProps.required;
    }
    if (props.style)
        Object.assign(style, props.style);
    return useObserver(function () {
        var _a, _b, _c, _d;
        return (React.createElement(React.Fragment, null,
            React.createElement(InputTitle, __assign({}, props)),
            React.createElement(BaseTextField, __assign({ id: props.id || (props.title || 'text-field-input').toString().replace(/ /gi, '').toLowerCase(), ref: ref, onKeyDown: onKeyDown, inputProps: { maxLength: 1024 } }, inputProps, { variant: variant, margin: margin, fullWidth: fullWidth }, field, props, { className: clsx(props.className, classes.base, (_a = {}, _a[classes.baseStartAdornment] = InputProps.startAdornment, _a), (_b = {}, _b[classes.baseErrorV1] = props.v3 && (errorText || warningText), _b[classes.baseV2] = props.v3, _b), (_c = {}, _c[classes.rootWarning] = warningText, _c)), InputProps: InputProps, InputLabelProps: { shrink: field.value || field.value === 0 || props.value }, autoComplete: "off", style: style, error: !!touchedError || warningText, onChange: _onChange, helperText: (errorText && React.createElement(TextOverflow, { v2: true }, errorText)) ||
                    (warningText && React.createElement(TextOverflow, { warn: true }, warningText)) }, (_d = {}, _d["data-title-1"] = titlePlace || title, _d), (withLabel && {
                required: false,
                placeholder: '',
                title: '',
            })))));
    });
});
// export const FormikTextFieldForw = withRef(FormikTextField);
