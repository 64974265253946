var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { INVOICE_BASE } from './base';
export var FetchCosts = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query costs($filter:MainFilter){\n        costs(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query costs($filter:MainFilter){\n        costs(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), INVOICE_BASE.replace(/company{/g, 'company{ companyCode').replace(/createdBy{/g, 'createdBy{ phone'));
export var ApproveCost = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation approveCost($input:InvoiceInputUpdate!){\n        approveCost(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation approveCost($input:InvoiceInputUpdate!){\n        approveCost(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_BASE);
export var MadePaymentCost = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation madePaymentCost($input:InvoiceInputUpdate!){\n        madePaymentCost(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation madePaymentCost($input:InvoiceInputUpdate!){\n        madePaymentCost(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_BASE);
export var FetchCostsStats = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query costsStats($filter: MainFilter) {\n    costsStats(filter: $filter) {\n      clients {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      sellers {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      types {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      status {\n        _id\n        count\n      }\n      rangeTotal {\n        min\n        max\n      }\n    }\n  }\n"], ["\n  query costsStats($filter: MainFilter) {\n    costsStats(filter: $filter) {\n      clients {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      sellers {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      types {\n        _id {\n          _id\n          name\n        }\n        count\n      }\n      status {\n        _id\n        count\n      }\n      rangeTotal {\n        min\n        max\n      }\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
