import React from 'react';
import { Grid } from '@material-ui/core';
import { BaseUserAppTYpe } from '../stores/common';
import { TextOverflow } from './TextOverflow';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { CarrierIconItem } from './icons';
import PersonIcon from '@material-ui/icons/Person';
export default function UserAppTypView(_a) {
    var type = _a.type;
    var langApp = useTranslation(KEYS.APP);
    return (React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column', style: { padding: "24px 0 10px 0" } },
        type === BaseUserAppTYpe.CARRIER ? (React.createElement(CarrierIconItem, null)) : type === BaseUserAppTYpe.CUSTOMER ? (React.createElement(PersonIcon, { style: { width: 74, height: 54, marginBottom: 16, marginTop: 4 } })) : null,
        React.createElement(TextOverflow, { variant: 'subtitle1' }, langApp.t("user_app_type_" + type.toLowerCase()))));
}
