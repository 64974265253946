var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import { BasicDialog, onPreventEvent } from '../../../components/Dialog';
import { i18n, KEYS } from '../../../i18n';
import { Form, Formik } from 'formik';
import { FormikAutocomplete, formikAutocompleteMultiOptionsProps } from '../../../components/Formik';
import { makeStyles } from '@material-ui/core/styles';
import { documentInitialValues } from '../../../components/Uploads/utils';
import { ResendNotificationUploadCreated } from '../../../graphql';
import { toast } from 'react-toastify';
import BaseTooltip from '../../../components/BaseTooltip';
import OrderRouteMenuItem from '../../../components/Order/List/OrderRoutesMenuItem';
import { BaseUserAppTYpe } from '../../../stores';
var useStyles = makeStyles(function (theme) { return ({
    form: {
        padding: theme.spacing(2),
    },
}); });
export default inject('store')(function (_a) {
    var item = _a.item, store = _a.store, onChange = _a.onChange, props = __rest(_a, ["item", "store", "onChange"]);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState({ users: [], emails: [], show: false }), 2), initialValues = _c[0], setInitialValues = _c[1];
    var langToast = useTranslation(KEYS.TOAST);
    var classes = useStyles();
    var formRef = React.useRef();
    var disabled = store.accountStore.currentCompany._id !== item.createdBy.company._id;
    var onSend = function (model) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    if (!model)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    setLoading(true);
                    _a = props.config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.config.client.mutate({
                            mutation: ResendNotificationUploadCreated,
                            variables: {
                                input: Object.assign({ _id: item._id }, {
                                    emails: (model.users || []).map(function (e) { return e.user && e.user.email; }),
                                }),
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_sent')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_notification')));
                    item.updatedAt = new Date().toISOString();
                    if (props.onRefresh)
                        props.onRefresh();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (model) { return model; };
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    return [2 /*return*/, onSend(__assign(__assign({}, createdItem), { users: createdItem.users.map(function (e) { return ({ user: e.user || { email: e }, company: e.company }); }) }))];
            }
        });
    }); };
    var onClose = function () {
        setInitialValues({
            users: [],
            emails: [],
            show: false,
        });
    };
    var dialogBody = initialValues.show && (React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, onSubmit: onSubmit }, function (formik) {
        var _onChange = function (e) {
            formik.setFieldValue(e.target.name, e.target.value);
        };
        return (React.createElement(Form, { id: "form-document-resend-notification", className: classes.form },
            React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(FormikAutocomplete, __assign({}, formikAutocompleteMultiOptionsProps(formik.values.users, null, null, initialValues.users), { name: "users", onChange: function (e) {
                        if (typeof e.target.value === 'string')
                            return;
                        _onChange(e);
                    }, onEnter: function (e) {
                        var _a = e.target, name = _a.name, stringValue = _a.value;
                        if (!stringValue || stringValue === '')
                            return;
                        var arrayValue = __spread((formik.values.users || []), [stringValue]);
                        _onChange({ target: { name: name, value: arrayValue } });
                    }, value: formik.values.users, title: langToast.t('toast_users_to_notify') })))));
    }));
    var onResetForm = function () {
        var _a;
        var newVal = __assign(__assign(__assign({}, initialValues), { users: [], emails: [], show: true }), documentInitialValues(props.quote.order, store.accountStore.currentUser, props));
        var exist = newVal.users.map(function (e) { return e.user.email || e; });
        (_a = newVal.users).push.apply(_a, __spread((item.emails || [])
            .filter(function (e) { return e && !exist.includes(e); })
            .map(function (email) { return ({ label: email, user: { email: email, firstName: '', lastName: '' }, company: { name: '' } }); })));
        newVal.users = newVal.users.filter(function (e) {
            var _a;
            return !((_a = e === null || e === void 0 ? void 0 : e.appType) === null || _a === void 0 ? void 0 : _a.length) ||
                e.appType.find(function (appType) {
                    return item.access.includes(appType) ||
                        (appType === BaseUserAppTYpe.CONSIGNEE && item.access.includes(BaseUserAppTYpe.SENDER));
                });
        });
        setInitialValues(newVal);
    };
    var actionInit = function (onClick) {
        var _onClick = function () {
            var e = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                e[_i] = arguments[_i];
            }
            onResetForm();
            onClick.apply(void 0, __spread(e));
        };
        return (React.createElement(BaseTooltip, { title: langToast.t('toast_document_send_notification') },
            React.createElement(IconButton, { onClick: _onClick, color: 'primary', disabled: disabled || loading },
                React.createElement(CallToActionIcon, null))));
    };
    if (props.label) {
        actionInit = function (onClick) {
            var handleClickOpen = function () {
                var e = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    e[_i] = arguments[_i];
                }
                onResetForm();
                onClick.apply(void 0, __spread(e));
            };
            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                handleClickOpen: handleClickOpen,
                icon: (React.createElement(IconButton, { color: 'primary' },
                    React.createElement(CallToActionIcon, null))),
                label: langToast.t('toast_document_send_notification'),
            })));
        };
    }
    return (React.createElement(BasicDialog, { withoutWrapper: true, body: dialogBody, title: langToast.t('toast_document_send_notification'), onOk: onOk, onClose: onClose, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: actionInit }));
});
