var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { Typography, Grid } from '@material-ui/core';
import { CollapseListItem } from './CollapseListItem';
import { MenuCollapseItem } from './MenuCollapseItem';
import { theme } from '../../../theme';
import { useMenuItemStyles } from '../../SidebarMenu/MenuItem';
import { useMainStyles } from '../../../styles';
import { BoxSmallIcon } from '../../icons';
import { TextOverflow } from '../../TextOverflow';
import BaseTooltip from '../../BaseTooltip';
export var InProgressCollapseItem = function (p) {
    var menu = p.menu, props = __rest(p, ["menu"]);
    var classesMenuItem = useMenuItemStyles();
    var classesMainStyles = useMainStyles();
    return (React.createElement(MenuCollapseItem, __assign({}, props, { renderChildren: function (stats) {
            return menu &&
                menu.map(function (e) {
                    var stat = stats.find(function (stat) {
                        return stat._id === e._id ||
                            (e._id && stat._id.toLowerCase().replace(/-/, '') == e._id.toLowerCase().replace(/-/, ''));
                    });
                    return (React.createElement(CollapseListItem, { key: e.path, path: e.path, item: e, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
                            React.createElement(Grid, { container: true, alignItems: "center", justify: "center", className: clsx('sub-menu-stat', classesMenuItem.icon, classesMainStyles.hidden) },
                                React.createElement(BaseTooltip, { title: e.title + "(" + ((stat && stat.count) || 0) + ")" },
                                    React.createElement("div", { className: classesMenuItem.smallIcon },
                                        e.icon || React.createElement(BoxSmallIcon, { className: 'sub-menu-icon' }),
                                        (!e.exact || (e.exact && (stat === null || stat === void 0 ? void 0 : stat.count))) && React.createElement("span", null, (stat && stat.count) || 0)))),
                            React.createElement(Typography, { variant: 'subtitle1' },
                                React.createElement(Grid, { container: true, alignItems: 'center', style: { position: 'relative' } },
                                    e.icon && (React.createElement("div", { className: classesMenuItem.smallIcon, style: { position: 'absolute', left: -34 } }, e.icon)),
                                    React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 170, paddingLeft: theme.spacing(2.8) } }, e.title))),
                            (!e.exact || (e.exact && (stat === null || stat === void 0 ? void 0 : stat.count))) && (React.createElement(TextOverflow, { variant: 'subtitle2', style: {
                                    width: theme.spacing(4),
                                    textAlign: 'center',
                                    maxWidth: 40,
                                    marginRight: theme.spacing(2.8),
                                } }, (stat === null || stat === void 0 ? void 0 : stat.count) || 0))) }));
                });
        } })));
};
