var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { useMenuItemStyles } from '../../SidebarMenu/MenuItem';
import browserHistory from '../../../browserHistory';
import { CircularProgress, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import { inject, observer } from 'mobx-react';
import BaseTooltip from '../../BaseTooltip';
import { isOpen } from '../utils';
import { useIconStyles } from '../../../styles';
var MenuCollapseItemComponent = inject('store')(observer(function (p) {
    var title = p.title, renderChildren = p.renderChildren, icon = p.icon, path = p.path, client = p.client, query = p.query, _a = p.variables, variables = _a === void 0 ? { filter: { fields: [] } } : _a, store = p.store, parseLoadData = p.parseLoadData, item = p.item;
    var _b = __read(React.useState(null), 2), fromRoute = _b[0], setFromRoute = _b[1];
    var _c = __read(React.useState(1), 2), updated = _c[0], setUpdated = _c[1];
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(React.useState([]), 2), stats = _e[0], setStats = _e[1];
    var open = isOpen(path, location.pathname, item);
    var classesMenuItem = useMenuItemStyles();
    var classesMainStyles = useIconStyles();
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUpdated(Date.now());
                    if (open) {
                        setFromRoute(null);
                        return [2 /*return*/, browserHistory.push('/')];
                    }
                    if (!fromRoute)
                        setFromRoute(location.pathname);
                    browserHistory.push(path);
                    return [4 /*yield*/, loadData(path)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var loadData = function (newPath) { return __awaiter(void 0, void 0, void 0, function () {
        var result, list, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isOpen(path, newPath || location.pathname, item) && !loading)) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    return [4 /*yield*/, client.query({
                            query: query,
                            variables: variables,
                        })];
                case 2:
                    result = _a.sent();
                    list = result.data[Object.keys(result.data)[0]];
                    setStats(parseLoadData ? parseLoadData(list) : list);
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    /*eslint-disable */
    React.useEffect(function () {
        setTimeout(function () { return loadData(); });
    }, [store.accountStore.menuUpdated, open]);
    /*eslint-disable */
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { key: updated, button: true, onClick: onClick, disabled: loading, selected: open, className: clsx(classesMenuItem.select, 'menu-collapse') },
            React.createElement(ListItemIcon, null,
                React.createElement(Grid, { container: true, alignItems: "center", justify: "center", className: clsx(classesMenuItem.icon, classesMainStyles.menuIcon) },
                    React.createElement(BaseTooltip, { title: title }, icon))),
            React.createElement(ListItemText, { primary: title, className: 'sub-menu-title' }),
            loading ? (React.createElement(CircularProgress, { size: 20, className: 'icon-more' })) : !open ? (React.createElement(ExpandLess, { className: 'icon-more' })) : (React.createElement(ExpandMore, { className: 'icon-more' }))),
        React.createElement(Collapse, { in: open && !loading, timeout: "auto", unmountOnExit: true },
            React.createElement(List, { component: "div", disablePadding: true, key: JSON.stringify(stats) }, renderChildren(stats)))));
}));
export var MenuCollapseItem = withRouter(MenuCollapseItemComponent);
