import { makeStyles } from '@material-ui/core/styles';
import { flexbox } from '@material-ui/system';
export var useStylesMain = makeStyles(function (theme) { return ({
    root: {
        '& .Mui-error': {
            position: 'relative',
            '& .MuiTypography-body1': {
                whiteSpace: 'break-spaces!important',
            },
        },
    },
}); });
export var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        paper: (_a = {
                width: '75%',
                display: flexbox,
                padding: theme.spacing(7, 9),
                maxHeight: 'calc(100vh - 80px)',
                overflowY: 'auto'
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(2),
                width: '90%',
                maxHeight: 'calc(100vh - 80px)',
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2),
                width: '90%',
                maxHeight: 'calc(100vh - 250px)',
            },
            _a.backgroundColor = theme.palette.common.white,
            // borderRadius: theme.spacing(1),
            _a.color = theme.palette.secondary.dark,
            _a),
        form: {
            display: flexbox,
            backgroundColor: theme.palette.common.white,
            marginTop: theme.spacing(1),
            width: '100%',
        },
        arrowBackIcon: {
            color: theme.palette.secondary.dark,
        },
        title: {
            color: theme.palette.secondary.dark,
            fontSize: theme.spacing(4),
        },
        navLink: {
            color: theme.palette.success.light,
        },
        textForPassword: {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
        navLinkToResetPassword: {
            marginTop: theme.spacing(2),
        },
    });
});
