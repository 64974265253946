var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Card from '@material-ui/core/Card/Card';
import clsx from 'clsx';
import { useStyles } from './styles';
export var BaseListItem = function (_a) {
    var _b;
    var children = _a.children, onClick = _a.onClick, record = _a.record, className = _a.className, style = _a.style, props = __rest(_a, ["children", "onClick", "record", "className", "style"]);
    var classes = useStyles();
    var _onClick = function (e) { return onClick && onClick(e, record); };
    return (React.createElement(Card, __assign({}, props, { "data-id": props["data-id"] || "base-list-item-" + (record === null || record === void 0 ? void 0 : record._id), className: clsx(className, classes.root, (_b = {}, _b[classes.orderRoot] = props.v1, _b), 'base-list-item'), style: style, onClick: _onClick }), children));
};
