var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY } from './base';
export var HTML_TEMPLATE_BASE = "\n      " + BASE_QUERY + "\n      html\n      parent{\n        _id\n      } \n";
export var FetchHtmlTemplates = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query htmlTemplates($filter: MainFilter) {\n        htmlTemplates(filter:$filter){\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n    query htmlTemplates($filter: MainFilter) {\n        htmlTemplates(filter:$filter){\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), HTML_TEMPLATE_BASE);
export var CreateHtmlTemplate = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n mutation createHtmlTemplate($input:HtmlTemplateInput!){\n     createHtmlTemplate(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createHtmlTemplate($input:HtmlTemplateInput!){\n     createHtmlTemplate(input:$input) { \n      ", "\n    }\n  }\n"])), HTML_TEMPLATE_BASE);
export var UpdateHtmlTemplate = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n mutation updateHtmlTemplate($input:HtmlTemplateInput!){\n     updateHtmlTemplate(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateHtmlTemplate($input:HtmlTemplateInput!){\n     updateHtmlTemplate(input:$input) { \n      ", "\n    }\n  }\n"])), HTML_TEMPLATE_BASE);
var templateObject_1, templateObject_2, templateObject_3;
