var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE, UPLOAD_BASE } from './base';
import { LOGS_EMAIL_NOTIFICATION_BASE } from './logs';
import { ORDER_PREPATING_STATUS } from './order/types.v2';
var UPLOAD_BASE_MORE = "\n " + UPLOAD_BASE + "\n \n     log{\n     " + LOGS_EMAIL_NOTIFICATION_BASE + "\n     }\n";
export var FetchUploads = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query uploads($filter:MainFilter){\n    uploads(filter:$filter) {\n      data {\n        ", "\n        orderPreparingStep{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"], ["\n  query uploads($filter:MainFilter){\n    uploads(filter:$filter) {\n      data {\n        ", "\n        orderPreparingStep{\n          ", "\n        }\n      }\n      count\n    }\n  }\n"])), UPLOAD_BASE_MORE, ORDER_PREPATING_STATUS);
export var FetchUploadsCount = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query uploadsCount($filter: MainFilter) {\n    uploadsCount(filter: $filter) {\n      count\n    }\n  }\n"], ["\n  query uploadsCount($filter: MainFilter) {\n    uploadsCount(filter: $filter) {\n      count\n    }\n  }\n"])));
export var FetchUploadPreview = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query uploadPreview($_id: MongoId!){\n    uploadPreview(_id:$_id) {\n        ", "\n    }\n  }\n"], ["\n  query uploadPreview($_id: MongoId!){\n    uploadPreview(_id:$_id) {\n        ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var FetchUploadByPathPreview = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query uploadPreviewByPath($path: String) {\n    uploadPreviewByPath(path: $path) {\n      path\n    }\n  }\n"], ["\n  query uploadPreviewByPath($path: String) {\n    uploadPreviewByPath(path: $path) {\n      path\n    }\n  }\n"])));
export var CreateUpload = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n mutation createUpload($input:UploadItemInput){\n     createUpload(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createUpload($input:UploadItemInput){\n     createUpload(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var ResendNotificationUploadCreated = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n mutation resendNotificationUploadCreated($input:UploadItemResentNotificationInput){\n     resendNotificationUploadCreated(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation resendNotificationUploadCreated($input:UploadItemResentNotificationInput){\n     resendNotificationUploadCreated(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var NotifyOnUploadFinish = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation notifyOnUploadFinish($input: UploadOnFinish) {\n    notifyOnUploadFinish(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation notifyOnUploadFinish($input: UploadOnFinish) {\n    notifyOnUploadFinish(input: $input) {\n      status\n    }\n  }\n"])));
export var UpdateUpload = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n mutation updateUpload($input:UploadItemInputUpdate){\n     updateUpload(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateUpload($input:UploadItemInputUpdate){\n     updateUpload(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var DeleteUpload = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n mutation deleteUpload($_id:MongoId!){\n     deleteUpload(_id:$_id) { \n      ", "\n    }\n  }\n"], ["\n mutation deleteUpload($_id:MongoId!){\n     deleteUpload(_id:$_id) { \n      ", "\n    }\n  }\n"])), BASE_RESPONSE);
export var RequestToSign = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n mutation requestSign($_id:MongoId!){\n     requestSign(_id:$_id) { \n      ", "\n    }\n  }\n"], ["\n mutation requestSign($_id:MongoId!){\n     requestSign(_id:$_id) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var RefreshDocumentRequest = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n mutation refreshDocument($_id:MongoId!){\n  refreshDocument(_id:$_id) { \n      ", "\n    }\n  }\n"], ["\n mutation refreshDocument($_id:MongoId!){\n  refreshDocument(_id:$_id) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
export var RequestDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n mutation requestDocument($input:UploadItemRequestDocumentInput!){\n  requestDocument(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation requestDocument($input:UploadItemRequestDocumentInput!){\n  requestDocument(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
