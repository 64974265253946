// const context = require.context('./', true, /.json$/);
const context: any = { keys: () => [] };
const resources = {};
context.keys().forEach((key: any) => {
  const fileName = key.replace('./', '');
  const resource = require(`./${fileName}`);
  const namespace = fileName.replace('.json', '');
  const lan = namespace.split('/')[0];
  const variable = namespace.split('/')[1];
  if (!resources[lan]) resources[lan] = {};
  Object.assign(resources[lan], {
    [variable]: JSON.parse(JSON.stringify(resource)),
  });
});

export default resources;
