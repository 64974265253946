import React from 'react';
import { client } from '../../api/apolloClient';
// import OrderFormComponent from '@izi-logistics/common/lib/pages/Order/FormV1';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const OrderFormComponent = React.lazy(() => import('@izi-logistics/common/lib/pages/Order/FormV1'));

export default function OrderFormV1(props) {
  return (
    <PreloadingComponent>
      <OrderFormComponent {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
