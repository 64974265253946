var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import { TextOverflow } from '../TextOverflow';
import HtmlEditorV from '../HtmlEditor';
var HtmlEditor = HtmlEditorV;
export var FormikHtmlEditor = function (props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, title = props.title, onChange = props.onChange, onBlur = props.onBlur, label = props.label, noTitle = props.noTitle, inputProps = __rest(props, ["helperText", "title", "onChange", "onBlur", "label", "noTitle"]);
    var _b = __read(useField(props), 3), field = _b[0], meta = _b[1], helpers = _b[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var _onBlur = function (e) {
        helpers.setValue(e.target.value);
        if (onBlur)
            onBlur(e);
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        title && React.createElement(TextOverflow, null, title),
        inputProps.disabled ? (React.createElement("div", { dangerouslySetInnerHTML: {
                __html: field.value,
            } })) : (React.createElement(HtmlEditor, __assign({}, inputProps, field, { onChange: _onChange, onBlur: _onBlur, error: !!touchedError, helperText: touchedError || helperText }))))); });
};
