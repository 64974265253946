/**
 * forced to have separated file for enums,constants
 * */
export var MainFilterSortDir;
(function (MainFilterSortDir) {
    MainFilterSortDir["ASC"] = "asc";
    MainFilterSortDir["DESC"] = "desc";
})(MainFilterSortDir || (MainFilterSortDir = {}));
export var ACTION_CHANGE;
(function (ACTION_CHANGE) {
    ACTION_CHANGE["DELETED"] = "DELETED";
    ACTION_CHANGE["UPDATED"] = "UPDATED";
    ACTION_CHANGE["ADDED"] = "ADDED";
    ACTION_CHANGE["SOFT_REFRESH"] = "SOFT_REFRESH";
})(ACTION_CHANGE || (ACTION_CHANGE = {}));
