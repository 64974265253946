var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { wrapTextToId } from '../../utils';
import { useSectionRowStyles } from './styles';
export default function CargoSectionRow(_a) {
    var _b;
    var name = _a.name, value = _a.value, sizeName = _a.sizeName, sizeValue = _a.sizeValue, nameStyle = _a.nameStyle, isSmall = _a.isSmall, p = __rest(_a, ["name", "value", "sizeName", "sizeValue", "nameStyle", "isSmall"]);
    var className = useSectionRowStyles();
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: className.root, id: "row-" + (typeof name === 'string' && wrapTextToId(name)) },
        React.createElement(Grid, { item: true, xs: 12, alignItems: 'flex-start', md: sizeName || 6, style: {
                display: 'grid',
                height: isSmall ? 'fit-content' : '100%',
                paddingTop: '5px',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: nameStyle }, name)),
        React.createElement(Grid, __assign({ item: true, xs: 12, md: sizeValue || 6, alignItems: 'flex-start' }, (((_b = p.attributes) === null || _b === void 0 ? void 0 : _b.length) &&
            p.attributes.map(function (e) {
                var _a;
                return (_a = {}, _a["data-" + e.name] = e.value, _a);
            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { style: {
                display: 'grid',
                paddingTop: '4px',
            } }),
            React.createElement(Typography, { variant: 'caption', style: { textAlign: 'left', lineHeight: 2.2 } }, value))));
}
