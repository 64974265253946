var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus, BaseCompany, BaseUser, MaybeEmptyModel } from '../stores/common';
import { InvoiceType } from './InvoiceType';
import { OrderCurrency } from './types';
import { InvoiceService } from './InvoiceService';
import { OrderLocationAddress } from './Location';
import { OrderQuote } from './OrderQuote';
import { UploadItem } from './UploadItem';
import { OrderPayment } from './OrderPayment';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["UNPAID"] = "UNPAID";
    InvoiceStatus["PAID"] = "PAID";
    InvoiceStatus["DRAFT"] = "DRAFT";
})(InvoiceStatus || (InvoiceStatus = {}));
export var InvoiceCategory;
(function (InvoiceCategory) {
    InvoiceCategory["INVOICE"] = "Invoice";
    InvoiceCategory["COSTS"] = "Costs";
    InvoiceCategory["REFUND"] = "Request-refund";
    InvoiceCategory["CREDIT"] = "Credit";
})(InvoiceCategory || (InvoiceCategory = {}));
export var InvoiceParent = types.compose(AsyncActionStatus, types.model('InvoiceParent', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    note: types.maybeNull(types.optional(types.string, '')),
}));
export var Invoice = types
    .compose(AsyncActionStatus, types.model('Invoice', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    resetAt: types.maybeNull(types.optional(types.string, '')),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    sentAt: types.maybeNull(types.optional(types.string, '')),
    payedAt: types.maybeNull(types.optional(types.string, '')),
    typeSerial: types.maybeNull(types.optional(types.string, '')),
    status: types.maybeNull(types.optional(types.string, '')),
    currency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    services: types.optional(types.array(MaybeEmptyModel(InvoiceService)), function () { return []; }),
    files: types.optional(types.array(MaybeEmptyModel(UploadItem)), function () { return []; }),
    refunds: types.maybeNull(types.optional(types.array(MaybeEmptyModel(InvoiceParent)), function () { return []; })),
    accepted: types.optional(types.array(MaybeEmptyModel(BaseUser)), function () { return []; }),
    upload: types.optional(MaybeEmptyModel(UploadItem), function () { return UploadItem.create(); }),
    // quote: types.optional(MaybeEmptyModel(OrderQuote), () => OrderQuote.create()),
    issuedBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    parent: types.maybeNull(MaybeEmptyModel(InvoiceParent)),
    lastPayment: types.maybeNull(MaybeEmptyModel(OrderPayment)),
    category: types.maybeNull(types.optional(types.enumeration(InvoiceCategory.INVOICE, Object.values(InvoiceCategory)), function () { return InvoiceCategory.INVOICE; })),
    hasComment: types.maybeNull(types.optional(types.boolean, false)),
    hasNote: types.maybeNull(types.optional(types.boolean, false)),
    note: types.maybeNull(types.optional(types.string, '')),
    comment: types.maybeNull(types.optional(types.string, '')),
    startDate: types.maybeNull(types.optional(types.string, '')),
    endDate: types.maybeNull(types.optional(types.string, '')),
    serial: types.maybeNull(types.optional(types.string, '')),
    total: types.maybeNull(types.optional(types.number, 0)),
    isAllDiscount: types.maybeNull(types.optional(types.boolean, false)),
    discount: types.maybeNull(types.optional(types.number, 0)),
    discountUnit: types.maybeNull(types.optional(types.string, '%')),
    seller_id: types.maybeNull(types.optional(BaseCompany, function () { return BaseCompany.create(); })),
    seller_address: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    seller_name: types.maybeNull(types.optional(types.string, '')),
    seller_pay_type: types.maybeNull(types.optional(types.string, '')),
    seller_vatCode: types.maybeNull(types.optional(types.string, '')),
    seller_companyCode: types.maybeNull(types.optional(types.string, '')),
    seller_bankAccountCode: types.maybeNull(types.optional(types.string, '')),
    seller_bankSwiftCode: types.maybeNull(types.optional(types.string, '')),
    seller_bankCode: types.maybeNull(types.optional(types.string, '')),
    seller_bank: types.maybeNull(types.optional(types.string, '')),
    seller_emails: types.maybeNull(types.optional(types.array(types.string), function () { return []; })),
    client_id: types.maybeNull(types.optional(BaseCompany, function () { return BaseCompany.create(); })),
    client_address: types.maybeNull(types.optional(OrderLocationAddress, function () { return OrderLocationAddress.create(); })),
    client_name: types.maybeNull(types.optional(types.string, '')),
    client_pay_type: types.maybeNull(types.optional(types.string, '')),
    client_vatCode: types.maybeNull(types.optional(types.string, '')),
    client_companyCode: types.maybeNull(types.optional(types.string, '')),
    client_bankAccountCode: types.maybeNull(types.optional(types.string, '')),
    client_phone: types.maybeNull(types.optional(types.string, '')),
    client_emails: types.maybeNull(types.optional(types.array(types.string), function () { return []; })),
    type: types.maybeNull(types.optional(MaybeEmptyModel(InvoiceType), function () { return InvoiceType.create(); })),
    quote: types.maybeNull(types.optional(MaybeEmptyModel(OrderQuote), function () { return OrderQuote.create(); })),
    showOrderDetails: types.maybeNull(types.optional(types.boolean, false)),
    asTemplate: types.maybeNull(types.optional(types.boolean, false)),
    isTemplate: types.maybeNull(types.optional(types.boolean, false)),
    templateName: types.maybeNull(types.optional(types.string, '')),
    printLanguage: types.maybeNull(types.optional(types.string, 'en')),
}))
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Invoice.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); });
