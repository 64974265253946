var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useMonthDayDetailsStyles } from './style';
import { useCalendarFilterStyles } from '../../../styles';
import { Grid } from '@material-ui/core';
import { withCalendarStore } from '../store';
import PreloadingComponent from '../../PreloadingComponent';
import { NoneWorkingDayTimeDetails } from './NoneWorkingDayTimeDetails';
import { getQueryParams } from '../../../utils';
import { orderDayDetailsMap } from './utils.2';
import { KEYS, i18n } from '../../../i18n';
var DayOrderDetailsItem = React.lazy(function () { return import('./DayOrderDetailsItem'); });
var DayNoneWorkingDetailsItem = React.lazy(function () { return import('./DayNoneWorkingDetailsItem'); });
export var DayOrderDetails = withCalendarStore(function (_a) {
    var _b, _c;
    var hour = _a.hour, currentDay = _a.currentDay, dayTransportations = _a.dayTransportations, _d = _a.state, calendarElements = _d.calendarElements, ramps = _d.ramps, warehouse = _d.warehouse, noneWorkingDayTime = _d.noneWorkingDayTime, item = _a.item, canDrag = _a.canDrag, isStyled = _a.isStyled, rowSpan = _a.rowSpan, onChange = _a.onChange, noneWorkingTime = _a.noneWorkingTime, config = _a.config, v1 = _a.v1, props = __rest(_a, ["hour", "currentDay", "dayTransportations", "state", "item", "canDrag", "isStyled", "rowSpan", "onChange", "noneWorkingTime", "config", "v1"]);
    var classes = useCalendarFilterStyles();
    var className = useMonthDayDetailsStyles();
    var _e = __read(React.useState([]), 2), daysWithOrder = _e[0], setDaysWithOrder = _e[1];
    var query = getQueryParams(((_c = (_b = props.history) === null || _b === void 0 ? void 0 : _b.location) === null || _c === void 0 ? void 0 : _c.search) || '');
    /*eslint-disable */
    React.useEffect(function () {
        setDaysWithOrder(orderDayDetailsMap({
            i18n: i18n,
            KEYS: KEYS,
            hour: hour,
            item: item,
            noneWorkingDayTime: noneWorkingDayTime,
            currentDay: currentDay,
            dayTransportations: dayTransportations,
            warehouse: warehouse,
            ramps: ramps,
            calendarElements: calendarElements,
            classes: classes,
            v1: v1,
            noneWorkingTime: noneWorkingTime,
            query: query,
            props: props,
        }));
    }, [calendarElements, dayTransportations]);
    /*eslint-enable */
    var size = 1;
    if (!isStyled) {
        size = -1;
    }
    return (React.createElement(Grid, { container: true, direction: 'row', className: className.root, style: __assign({ flexGrow: 'initial', overflowY: 'auto' }, (size === 1 && { height: '100%' })) }, daysWithOrder.map(function (day) {
        var _a;
        return (React.createElement(PreloadingComponent, null, !((_a = day === null || day === void 0 ? void 0 : day.order) === null || _a === void 0 ? void 0 : _a._id) ? (v1 ? (React.createElement(NoneWorkingDayTimeDetails, { key: day._id, v1: true, config: config, style: { minHeight: '21px' }, warehouse: warehouse, items: [day] })) : (React.createElement(DayNoneWorkingDetailsItem, { day: day, key: day._id }))) : (React.createElement(DayOrderDetailsItem, { key: day._id, day: day, rowSpan: rowSpan, isStyled: isStyled, hour: hour, config: config, canDrag: canDrag, onChange: onChange, rows: props.rows, hours: props.hours, history: props.history }))));
    })));
});
