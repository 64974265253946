var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel } from '../stores/common';
import { Warehouse } from './Warehouse';
import { User } from './User';
export var OrderChatMessage = types
    .compose(AsyncActionStatus, types
    .model('OrderChatMessage', {
    _id: types.optional(types.string, function () { return ''; }),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    createdAt: types.maybeNull(types.optional(types.string, '')),
    text: types.maybeNull(types.optional(types.string, '')),
    createdBy: types.maybeNull(types.optional(MaybeEmptyModel(User), function () { return User.create(); })),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Warehouse.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); })
    .views(function (self) { return ({}); }))
    .named('OrderChatMessage');
