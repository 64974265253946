var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import PopUpHover from '../../components/PopupHover';
import { Grid, useTheme } from '@material-ui/core';
import { AvatarItemView } from '../../components/Account/Avatar';
import { inject } from 'mobx-react';
import Loader from '../../components/Loader';
import { TextOverflow } from '../../components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
export default inject('store')(function UserHistory(_a) {
    var _this = this;
    var record = _a.record, accountStore = _a.store.accountStore;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(null), 2), createdBy = _c[0], setCreatedBy = _c[1];
    var theme = useTheme();
    var langApp = useTranslation(KEYS.APP);
    var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (record.invited && record.createdBy) {
                setLoading(true);
                accountStore
                    .loadUser(record.createdBy._id)
                    .then(setCreatedBy)
                    .finally(function () { return setLoading(false); });
            }
            return [2 /*return*/];
        });
    }); };
    return null;
    var content = loading ? (React.createElement(Loader, null)) : (React.createElement(Grid, { container: true, direction: 'column', style: { padding: theme.spacing(2, 1, 1, 1) } },
        React.createElement(Grid, { key: record._id, container: true, alignItems: 'flex-start', justify: 'space-between', spacing: 2 }, createdBy ? (React.createElement(React.Fragment, null,
            React.createElement(Grid, { xs: true, container: true, direction: 'column' },
                React.createElement(TextOverflow, null, langApp.t('app_invited_by'))),
            React.createElement(Grid, { container: true, style: { marginRight: '10px', maxWidth: '30px' } },
                React.createElement(AvatarItemView, { moreDetail: true, avatarStyle: { width: 26, height: 26 }, typographyProps: {
                        variant: 'h6',
                        style: {
                            fontSize: 14,
                            fontWeight: 600,
                            color: theme.palette.common.white,
                        },
                    }, user: createdBy })))) : (React.createElement(Grid, { container: true, direction: 'column', style: { maxWidth: 'calc(100% - 30px)' } },
            React.createElement(TextOverflow, null, langApp.t('app_joined_from_form')))))));
    return (React.createElement(PopUpHover, { minWidth: 200, title: React.createElement(TextOverflow, { variant: 'h6', style: { width: '100%', textAlign: 'right' } }, langApp.t('history')), show: true, icon: React.createElement(HistoryIcon, null), onMouseEnter: loadData, content: content }));
});
