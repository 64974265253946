var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { toDateOnly } from '../../utils';
import { orderHasContainerVehicleType } from '../../utils1';
export var QUOTE_STEP;
(function (QUOTE_STEP) {
    QUOTE_STEP["AcceptedQuote"] = "Accepted quote";
    QUOTE_STEP["Overview"] = "Overview";
    QUOTE_STEP["Transportation"] = "Transportation";
    QUOTE_STEP["Transport"] = "Transport";
    QUOTE_STEP["Booking"] = "Booking";
    QUOTE_STEP["DocumentAttached"] = "Document Attached";
    QUOTE_STEP["Finished"] = "Finished";
})(QUOTE_STEP || (QUOTE_STEP = {}));
export var requiredTransportKeys = ['track', 'trailer'];
export var STEPS = (_a = {},
    _a[QUOTE_STEP.Transport] = function (item) {
        return (item.unloadedAt && toDateOnly(item.unloadedAt) >= toDateOnly(new Date())) || !item.unloadedAt;
    },
    _a[QUOTE_STEP.Transportation] = function (item) {
        return [QUOTE_STEP.Transport, QUOTE_STEP.Booking].map(function (e) { return STEPS[e](item); }).filter(function (e) { return e; }).length === 2;
    },
    _a[QUOTE_STEP.Overview] = function (item) {
        return item.cargoes.length > 0 &&
            item.routes.filter(function (e) { return e.origin && e.origin.address && e.origin.address.country; }).length === 2;
    },
    _a[QUOTE_STEP.DocumentAttached] = function (item) { return item.files && item.files.length > 0; },
    _a[QUOTE_STEP.Booking] = function (item) { return STEP_VALIDATION[QUOTE_STEP.Transport](item); },
    _a);
export var checkSteps = function (item, key) {
    var _a;
    if (key)
        return _a = {}, _a[key] = STEPS[key](item), _a;
    return Object.keys(STEPS)
        .map(function (step) { return STEPS[step](item); })
        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {});
};
export var STEP_VALIDATION = (_b = {},
    _b[QUOTE_STEP.Transportation] = function (item) { return true; },
    _b[QUOTE_STEP.Transport] = function (item) {
        var _requiredTransportKeys = requiredTransportKeys;
        if (item.order.vehicleType && orderHasContainerVehicleType(item.order.vehicleType)) {
            _requiredTransportKeys.push('container');
        }
        return (STEPS[QUOTE_STEP.Transport](item) &&
            item.transports &&
            item.transports[0] &&
            _requiredTransportKeys.length === _requiredTransportKeys.filter(function (e) { return item.transports[0][e]; }).length);
    },
    _b[QUOTE_STEP.Booking] = function (item) {
        return STEPS[QUOTE_STEP.Booking](item) &&
            item.startTime &&
            item.startTime.length > 0 &&
            item.endTime &&
            item.endTime.length > 0 &&
            item.inTransitAt &&
            item.unloadedAt;
    },
    _b);
export var checkStepValidation = function (item, key) {
    var _a;
    if (key)
        return _a = {}, _a[key] = STEP_VALIDATION[key](item), _a;
    return Object.keys(STEP_VALIDATION)
        .map(function (step) { return STEPS[step](item); })
        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {});
};
