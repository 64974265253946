var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { CreateOrderTemplate, FetchOrderFull } from './../graphql/order/index';
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { MaybeEmptyModel } from './common';
import { toast } from 'react-toastify';
import { i18n } from '../i18n';
import { FetchOrder, CreateOrder, UpdateOrder, PublishOrder, CreateOrderVehicle, DuplicateOrder, FetchOrderIndex, UpdateOrderQuoteDetails, UpdateOrderVehicle, CopyOrder, OrderPDF, } from '../graphql';
import { Order } from '../models';
import browserHistory from '../browserHistory';
import { FetchOrderVehicle } from '../graphql';
import { OrderTransportation } from '../models';
import { KEYS } from '../i18n';
import { NAVIGATION } from '../HOK/LocationHistory';
import { FetchOrderQuoteDetails } from '../graphql';
import { FetchUploads } from '../graphql';
import { CreateHtmlTemplate, UpdateHtmlTemplate } from '../graphql/htmlTemplate';
import { googleTagManager } from '../utils1';
import { FetchOrderPreparingStatuses, UpdateOrderPreparing } from '../graphql/order/orderPreparing';
import { OrderPreparingStatuses } from '../models/OrderPreparingStatuses';
import { OrderStoreGeneralFactory } from './OrderStoreGeneralFactory';
export var onUpdateItem = function (input) {
    return Object.assign({}, typeof input.price !== 'undefined'
        ? {
            price: parseFloat(input.price.toString()),
        }
        : {}, typeof input.forwarderOrderMarkUpPercentage !== 'undefined'
        ? {
            forwarderOrderMarkUpPercentage: parseFloat(input.forwarderOrderMarkUpPercentage.toString()),
        }
        : {}, input.vehicleType
        ? {
            vehicleType: (input.vehicleType || []).map(function (e) { return e._id; }),
        }
        : {}, input.certificates
        ? {
            certificates: (input.certificates || []).map(function (e) { return e._id || e; }),
        }
        : {});
};
export var OrderStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(OrderStoreGeneralFactory({ client: client, model: Order, modelOptions: { cargoType: '' } }), types.model('OrderStore', {
        preparingStatuses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderPreparingStatuses)), function () { return []; })),
    }))
        .actions(function (self) { return ({
        loadPreparingStatuses: flow(function (_id) {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (self.preparingStatuses.length)
                            return [2 /*return*/];
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchOrderPreparingStatuses,
                                variables: {
                                    filter: { fields: [], limit: 100, sortBy: 'order', sortDir: 'asc' },
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        self.preparingStatuses = res.data.orderPreparingStatuses.data;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        toast.error(e_1.message || e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        duplicateItem: flow(function (_id) {
            var result_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result_1 = _a.sent();
                        browserHistory.push("/orders/" + result_1.data.duplicateOrder._id);
                        googleTagManager('event', 'izi_form_order_duplicate_from_template', __assign({ event_name: "order_duplicate_from_template" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return result_1.data.duplicateOrder[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = result_1.data.duplicateOrder[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.clearItemForce(Order.create(result_1.data.duplicateOrder));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.updatedAt = Date.now();
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        copyItem: flow(function (source) {
            var _id, result, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        _id = self.order._id.toString();
                        return [4 /*yield*/, client.mutate({
                                mutation: CopyOrder,
                                variables: {
                                    input: {
                                        target: _id,
                                        source: source,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        self.clearItemForce(Order.create(JSON.parse(JSON.stringify(result.data.copyOrder))));
                        self.updatedAt = Date.now();
                        return [3 /*break*/, 4];
                    case 2:
                        e_3 = _a.sent();
                        toast.error(e_3.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        preCreateTransportation: flow(function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        self.isLoading = true;
                        return [4 /*yield*/, self.createOrderCargo({}, true)];
                    case 1:
                        _a.sent();
                        self.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }),
        createOrder: flow(function (_a, additional, noToast) {
            var beforeCreate, usedTypes_1, dirtyRoutes, routes_1, result, updates, e_4;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        beforeCreate = getSnapshot(self.order);
                        self.isUpdating = true;
                        googleTagManager('event', 'izi_form_order_create', { event_name: "order_create" });
                        usedTypes_1 = [];
                        dirtyRoutes = __spread([
                            additional
                        ], beforeCreate.transportations
                            .map(function (e) { return e.routes.find(function (r) { return r.origin && r.origin._id && r.email.length && r.startDate && r.endDate; }); })
                            .filter(function (e) { return e; })).filter(function (e) {
                            if (!e || usedTypes_1.includes(e.type))
                                return false;
                            usedTypes_1.push(e.type);
                            return true;
                        });
                        routes_1 = [];
                        dirtyRoutes.forEach(function (dirtyRoute) {
                            if (dirtyRoute) {
                                var _id_1 = dirtyRoute._id, warehouse = dirtyRoute.warehouse, orderTransportation = dirtyRoute.orderTransportation, origin_1 = dirtyRoute.origin, rangeDates = dirtyRoute.rangeDates, updatedAt = dirtyRoute.updatedAt, remainRoute = __rest(dirtyRoute, ["_id", "warehouse", "orderTransportation", "origin", "rangeDates", "updatedAt"]);
                                routes_1.push(__assign(__assign({}, remainRoute), { origin: origin_1._id }));
                            }
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrder,
                                variables: {
                                    input: Object.assign(__assign({}, input), input.price
                                        ? {
                                            price: parseFloat(input.price.toString()),
                                        }
                                        : {}, input.vehicleType
                                        ? {
                                            vehicleType: input.vehicleType && input.vehicleType._id,
                                        }
                                        : {}, input.certificates
                                        ? {
                                            certificates: (input.certificates || []).map(function (e) { return e._id || e; }),
                                        }
                                        : {}, self.order.noQuotes
                                        ? {
                                            noQuotes: true,
                                        }
                                        : {}, self.order.timePickupBooked
                                        ? {
                                            timePickupBooked: self.order.timePickupBooked,
                                        }
                                        : {}, self.order.timeIsPickup
                                        ? {
                                            timeIsPickup: self.order.timeIsPickup,
                                        }
                                        : {}, self.order.timeToSpend
                                        ? {
                                            timeToSpend: self.order.timeToSpend,
                                        }
                                        : {}, { transportations: [{ routes: routes_1.length ? routes_1 : [] }] }),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        updates = result.data.createOrder;
                        self.order && self.order.reset(updates);
                        setTimeout(function () { return browserHistory.push("/orders/" + self.order._id); }, 100);
                        return [2 /*return*/, self.order];
                    case 2:
                        e_4 = _b.sent();
                        toast.error(e_4.message);
                        googleTagManager('event', 'izi-error', { value: e_4.message || e_4, event_name: 'error_order_create' });
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createItem: flow(function (opt) {
            return __generator(this, function (_a) {
                return [2 /*return*/, self.createOrder(opt)];
            });
        }),
        createOrderTemplate: flow(function (_a) {
            var result, e_5;
            var _id = _a._id, templateName = _a.templateName;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderTemplate,
                                variables: {
                                    input: {
                                        source: _id,
                                        templateName: templateName,
                                    },
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        return [2 /*return*/, result.data.createOrderTemplate];
                    case 2:
                        e_5 = _b.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderPreparing: flow(function (input) {
            var order, result, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderPreparing,
                                variables: { input: input },
                            })];
                    case 1:
                        result = _a.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order)), input), result.data.updateOrderPreparing));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateOrderPreparing];
                    case 2:
                        e_6 = _a.sent();
                        toast.error(e_6.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateItem: flow(function (e) {
            return __generator(this, function (_a) {
                return [2 /*return*/, self.updateOrder(e)];
            });
        }),
        updateOrder: flow(function (_a) {
            var order_1, keys, result, e_7;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        order_1 = self.order;
                        if (!(!order_1 || !order_1._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder(input)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        keys = Object.keys(input);
                        if (!keys.filter(function (e) { return e; }).length || keys.filter(function (e) { return input[e] === order_1[e]; }).length === keys.length) {
                            self.isUpdating = false;
                            return [2 /*return*/, console.warn('No Changes')];
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrder,
                                variables: {
                                    input: Object.assign(__assign(__assign(__assign({}, input), onUpdateItem(input)), { _id: _id || (order_1 && order_1._id) })),
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order_1)), input), result.data.updateOrder));
                        console.log('self.isOrderUpdating');
                        return [2 /*return*/, self.order];
                    case 4:
                        e_7 = _b.sent();
                        toast.error(e_7.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderQuoteItem: flow(function (_a) {
            var result, e_8;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderQuoteDetails,
                                variables: {
                                    input: Object.assign(__assign({ _id: _id }, input), typeof input.price !== 'undefined'),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateOrderQuoteDetails];
                    case 2:
                        e_8 = _b.sent();
                        toast.error(e_8.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createHtmlTemplateItem: flow(function (input) {
            var result, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateHtmlTemplate,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.createHtmlTemplate];
                    case 2:
                        e_9 = _a.sent();
                        toast.error(e_9.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateHtmlTemplateItem: flow(function (_a) {
            var result, e_10;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateHtmlTemplate,
                                variables: {
                                    input: Object.assign(__assign({ _id: _id }, input)),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_order')));
                        return [2 /*return*/, result.data.updateHtmlTemplate];
                    case 2:
                        e_10 = _b.sent();
                        toast.error(e_10.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        publishOrder: flow(function (_a, opt) {
            var order, result_2, e_11;
            if (opt === void 0) { opt = {}; }
            var _id = _a._id, noQuotes = _a.noQuotes, publishAs = _a.publishAs, group = _a.group, input = __rest(_a, ["_id", "noQuotes", "publishAs", "group"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isPublishing = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: PublishOrder,
                                variables: {
                                    input: __assign(__assign(__assign({}, (_id && { _id: _id })), (group && { group: group })), { noQuotes: noQuotes,
                                        publishAs: publishAs }),
                                },
                            })];
                    case 1:
                        result_2 = _b.sent();
                        if (result_2.data.publishOrder._id !== order._id) {
                            self.order &&
                                self.order.reset({
                                    _id: '',
                                });
                            toast.success(i18n.getResource(i18n.language, KEYS.INFO, 'new_order_from_template_published'));
                        }
                        googleTagManager('event', 'izi_form_order_publish', __assign({ event_name: "order_publish" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return result_2.data.publishOrder[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = result_2.data.publishOrder[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        self.order &&
                            self.order.reset(__assign(__assign(__assign(__assign({}, getSnapshot(self.order)), input), result_2.data.publishOrder), { publishedAt: new Date().toString() }));
                        if (!self.order.publishedAt)
                            self.order.publishedAt = new Date().toString();
                        if (opt.freeRedirect) {
                            browserHistory.push("/open-orders");
                        }
                        else {
                            browserHistory.push("/open-orders?search=" + (self.order.orderId || self.order._id));
                        }
                        return [2 /*return*/, self.order];
                    case 2:
                        e_11 = _b.sent();
                        googleTagManager('event', 'izi-error', { value: e_11.message || e_11, event_name: 'error_publish_order' });
                        toast.error(e_11.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isPublishing = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        onTemplate: flow(function (_a) {
            var order, duplicated, updates, result, e_12;
            var _id = _a._id, noQuotes = _a.noQuotes, input = __rest(_a, ["_id", "noQuotes"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        order = self.order;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        duplicated = _b.sent();
                        updates = {};
                        if (!duplicated.data.duplicateOrder.templateName)
                            updates.templateName = input.templateName || duplicated.data.duplicateOrder.orderId;
                        updates.isTemplate = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrder,
                                variables: {
                                    input: __assign({ _id: duplicated.data.duplicateOrder._id }, updates),
                                },
                            })];
                    case 2:
                        _b.sent();
                        delete input.templateName;
                        delete input.isTemplate;
                        return [4 /*yield*/, client.mutate({
                                mutation: PublishOrder,
                                variables: {
                                    _id: _id,
                                    noQuotes: noQuotes,
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        self.order &&
                            self.order.reset(__assign(__assign(__assign({}, getSnapshot(order)), input), result.data.publishOrder));
                        googleTagManager('event', 'izi_form_order_duplicate_from_template_onTemplate', __assign({ event_name: "order_duplicate_from_template_onTemplate" }, [
                            'sorting_countryPickup',
                            'sorting_pickupAt',
                            'sorting_countryDelivery',
                            'sorting_deliveryAt',
                            'sorting_totalWeight',
                            'orderId',
                        ]
                            .filter(function (e) { return self.order[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a["order_" + e] = self.order[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        return [2 /*return*/, self.order];
                    case 4:
                        e_12 = _b.sent();
                        toast.error(e_12.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        loadOrderFull: flow(function (_id, noStore) {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, client.query({
                            query: FetchOrderFull,
                            variables: {
                                _id: _id,
                            },
                        })];
                    case 1:
                        result = _a.sent();
                        self.updatedAt = Date.now();
                        return [2 /*return*/, result.data.order];
                }
            });
        }),
        loadOrder: flow(function (_id, noStore) {
            var result, _a, _b, _c, _d, e_13;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, 3, 4]);
                        if (!noStore && self.order && self.order._id === _id) {
                            return [2 /*return*/, console.warn('already loaded')];
                        }
                        self.isLoading = true;
                        _b = (_a = JSON).parse;
                        _d = (_c = JSON).stringify;
                        return [4 /*yield*/, client.query({
                                query: FetchOrder,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _b.apply(_a, [_d.apply(_c, [_e.sent()])]);
                        if (!result.data.order.transportations.length) {
                            result.data.order.transportations.push(OrderTransportation.create());
                        }
                        // self.isLoading = false;
                        if (noStore)
                            return [2 /*return*/, result.data.order];
                        self.clearItemForce(Order.create(result.data.order));
                        self.updatedAt = Date.now();
                        return [2 /*return*/, self.order];
                    case 2:
                        e_13 = _e.sent();
                        console.log(e_13);
                        setTimeout(function () { return NAVIGATION.reset(); });
                        browserHistory.push('/orders/create');
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderIndex: flow(function (_id) {
            var result, e_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderIndex,
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderIndex.order];
                    case 2:
                        e_14 = _a.sent();
                        console.log(e_14);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderQuote: flow(function (_id) {
            var result, e_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchOrderQuoteDetails,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderQuoteDetails];
                    case 2:
                        e_15 = _a.sent();
                        console.log(e_15);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderPDFPrinted: flow(function (_id, offerId) {
            var result, e_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: OrderPDF,
                                variables: {
                                    _id: _id,
                                    filter: {
                                        fields: [
                                            { name: 'withTerms', value: offerId },
                                            { name: 'print', value: 1 },
                                        ],
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderPdf];
                    case 2:
                        e_16 = _a.sent();
                        console.log(e_16);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderPDF: flow(function (_id, offerId) {
            var result, e_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: OrderPDF,
                                variables: {
                                    _id: _id,
                                    filter: { fields: [{ name: 'withTerms', value: offerId }] },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderPdf];
                    case 2:
                        e_17 = _a.sent();
                        console.log(e_17);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOrderQuoteUploads: flow(function (_id) {
            var result, e_18;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchUploads,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: _id },
                                            { name: 'canAccess', value: true },
                                        ],
                                        limit: 3,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.uploads.data];
                    case 2:
                        e_18 = _a.sent();
                        console.log(e_18);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        clearOrder: function (order) {
            if (self.order) {
                self.order && self.order.reset(__assign(__assign({}, order), { _id: '' }));
            }
        },
        createOrderVehicle: flow(function (_a) {
            var order, res, data, e_19;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({})];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, client.mutate({
                            mutation: CreateOrderVehicle,
                            variables: {
                                input: __assign(__assign({}, input), { order: order && order._id }),
                            },
                        })];
                    case 3:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderVehicle });
                            client.writeQuery({
                                query: FetchOrderVehicle,
                                data: {
                                    orderVehicles: {
                                        count: data.orderVehicles.count + 1,
                                        data: __spread([res.data.createOrderVehicle], data.orderVehicles.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderVehicle];
                    case 4:
                        e_19 = _b.sent();
                        toast.error(e_19.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderVehicle: flow(function (_a) {
            var order, res_1, data, e_20;
            var _id = _a._id, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        order = self.order;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createOrder({})];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, client.mutate({
                            mutation: UpdateOrderVehicle,
                            variables: {
                                input: {
                                    name: name,
                                    description: description,
                                    _id: _id,
                                },
                            },
                        })];
                    case 3:
                        res_1 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderVehicle });
                            client.writeQuery({
                                query: FetchOrderVehicle,
                                data: {
                                    orderVehicles: {
                                        count: data.orderVehicles.count,
                                        data: data.orderVehicles.data.map(function (e) {
                                            if (e._id === res_1.data.updateOrderVehicle._id) {
                                                return res_1.data.updateOrderVehicle;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res_1.data.updateOrderVehicle];
                    case 4:
                        e_20 = _b.sent();
                        toast.error(e_20.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
    }); })
        .views(function (self) { return ({
        get order() {
            return self.item;
        },
        get isOrderUpdating() {
            return self.isUpdating;
        },
        get isOrderLoading() {
            return self.isLoading;
        },
        get isOrderPublishing() {
            return self.isPublishing;
        },
    }); })
        .named('OrderStore');
};
