var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { OrderCargoes, OrderRoutes } from '../../components/Order';
import { Divider, Grid } from '@material-ui/core';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../stores';
import SectionDetails from '../../components/SectionDetails';
import { FetchOrderQuotes } from '../../graphql';
import { theme } from '../../theme';
export var QuoteDetailsComponentOverview = inject('store')(function (_a) {
    var order = _a.order, quote = _a.quote, store = _a.store, props = __rest(_a, ["order", "quote", "store"]);
    var is_fo = store.accountStore.currentCompany.type === BaseUserAppTYpe.FORWARDER;
    var details = (React.createElement(Grid, { container: true, style: {
            overflow: 'hidden',
        } },
        React.createElement(OrderCargoes, { order: order, cargoes: order.cargoes })));
    var isApproved = is_fo && (quote === null || quote === void 0 ? void 0 : quote.approvedAt);
    var offerSubmitted = isApproved && (React.createElement(SectionDetails, { v1: true, withoutTitle: true, order: order, config: props.config, query: FetchOrderQuotes, variables: {
            filter: {
                fields: [
                    { name: '_id', value: quote === null || quote === void 0 ? void 0 : quote._id },
                    { name: 'order', value: order === null || order === void 0 ? void 0 : order._id },
                    { name: 'history_review', value: 1 },
                ].filter(function (e) { return e; }),
                sortBy: 'approvedAt',
            },
        } }));
    return (React.createElement(Grid, { container: true, direction: "row" },
        React.createElement(OrderRoutes, __assign({ order: order, canViewAdditional: true, v1: true, hideActions: true }, props)),
        React.createElement(Divider, { style: { width: '100%' } }),
        (isApproved && (React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(Grid, { item: true, xs: 12, md: 6, style: { borderRight: "1px solid " + theme.palette.grey[300] } }, details),
            React.createElement(Grid, { item: true, xs: 12, md: 6, style: { paddingLeft: theme.spacing(2) } }, offerSubmitted)))) ||
            details));
});
