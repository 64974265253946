import { browserStorage } from './browserStorage';
import { appType } from './pages/help';
import { theme } from './theme';
function initSentry() {
    var script = document.createElement('script');
    script.onload = function () {
        var Sentry = window.Sentry;
        if (!Sentry) {
            console.log('Sentry not found!!!');
            return;
        }
        Sentry.init({
            //
            beforeSend: function (event, hint) {
                var error = hint.originalException;
                if (error &&
                    error.message &&
                    error.message.match(/^ChunkLoadError$|Errorhttps:\/\/customer\.izi-logistics.com\/static|izi-logistics.com\/static|.chunk.js/i)) {
                    return null;
                }
                return event;
            },
            dsn: 'https://a5990df14ef243369f813c0922b96720@o320090.ingest.sentry.io/5682072',
            // integrations: [new Integrations.BrowserTracing()],
            whitelistUrls: [location.origin],
            ignoreErrors: [
                'fb_xd_fragment',
                /^ChunkLoadError$|Errorhttps:\/\/customer\.izi-logistics.com\/static|izi-logistics.com\/static|.chunk.js/,
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
        var script = document.createElement('script');
        script.onload = function () {
            Raven.config('https://a5990df14ef243369f813c0922b96720@o320090.ingest.sentry.io/5682072', {
                whitelistUrls: [location.origin],
                ignoreErrors: [
                    'fb_xd_fragment',
                    /^ChunkLoadError$|Errorhttps:\/\/customer\.izi-logistics.com\/static|izi-logistics.com\/static|.chunk.js/,
                ],
            }).install();
        };
        script.setAttribute('src', 'https://cdn.ravenjs.com/3.26.4/raven.min.js');
        script.setAttribute('crossorigin', 'anonymous');
        document.body.appendChild(script);
        console.log('initSentry done');
    };
    script.setAttribute('src', 'https://browser.sentry-cdn.com/6.11.0/bundle.tracing.min.js');
    document.body.appendChild(script);
}
function initLogRocket() {
    var script = document.createElement('script');
    script.onload = function () {
        var LogRocket = window.LogRocket;
        LogRocket.init('og2n8e/test');
        console.log('initLogRocket done');
    };
    script.setAttribute('src', 'https://cdn.logr-ingest.com/LogRocket.min.js');
    script.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(script);
}
var REACT_APP_VERSION_V1 = process.env.REACT_APP_VERSION_V1;
export var initApp = function () {
    var version = (browserStorage.getItem('APP_VERSION') || '').toString();
    if (REACT_APP_VERSION_V1 && version !== REACT_APP_VERSION_V1) {
        browserStorage.setItem('APP_VERSION', REACT_APP_VERSION_V1);
        // if (version) reloadPageWithoutCache();
    }
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };
    if ((process.env.REACT_APP_API_URL || '').match('app-backend.izi-logistics.com')) {
        initLogRocket();
        setTimeout(initSentry, 10000);
    }
    var app = appType({ t: function (e) { return e; } }).app;
    if (process.env.REACT_APP_NONE_BRAND) {
        document.title = 'App';
    }
    else if (app === 'forwarder') {
        document.title = 'IZI Expedition';
    }
    else {
        document.title = 'IZI Logistics';
    }
    var style = document.createElement('style');
    style.innerText = "\n  .Toastify__toast-container{\n    max-width:210px;\n} \n.Toastify__toast--success{\n    background:" + theme.palette.success.light + "\n}\n.Toastify__toast--error{\n    background:" + theme.palette.error.light + "\n}\n.Toastify__toast{\n    min-height:34px;\n}";
    document.body.appendChild(style);
};
