var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { WAREHOUSE_WORKING_DAY_BASE, WAREHOUSE_WORKING_DAY_PLAN_BASE } from './types';
export var WAREHOUSE_WORKING_DAY_MORE = "\n  " + WAREHOUSE_WORKING_DAY_BASE + "\n                maxTime{\n                    " + WAREHOUSE_WORKING_DAY_PLAN_BASE + "\n                }\n                minTime{\n                    " + WAREHOUSE_WORKING_DAY_PLAN_BASE + "\n                }\n                maxDuration{\n                    " + WAREHOUSE_WORKING_DAY_PLAN_BASE + "\n                }\n                ";
export var WAREHOUSE_WORKING_DAY_MORE_v1 = "\n  " + WAREHOUSE_WORKING_DAY_MORE + "\n                plans{\n                    _id\n                    start\n                    end\n                    duration\n                }\n                ";
export var FetchWarehouseWorkingDays = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query warehouseWorkingDays($filter: MainFilter)  {\n        warehouseWorkingDays(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query warehouseWorkingDays($filter: MainFilter)  {\n        warehouseWorkingDays(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_WORKING_DAY_MORE);
export var DuplicateWarehouseWorkingDay = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation duplicateWarehouseWorkingDay($input: WarehouseWorkingDayInputDuplicate!)  {\n        duplicateWarehouseWorkingDay(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation duplicateWarehouseWorkingDay($input: WarehouseWorkingDayInputDuplicate!)  {\n        duplicateWarehouseWorkingDay(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_WORKING_DAY_PLAN_BASE);
var templateObject_1, templateObject_2;
