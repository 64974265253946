import { types } from 'mobx-state-tree';
import { OrderCurrency } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var CompanyPaymentTermModel = types.model('CompanyPaymentTermModel', {
    _id: types.maybeNull(types.optional(types.string, '')),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    status: types.maybeNull(types.optional(types.union(types.null, types.number, types.string), '')),
    requested_budget: types.maybeNull(types.optional(types.union(types.null, types.number, types.string), '')),
    budget: types.maybeNull(types.optional(types.union(types.null, types.number, types.string), '')),
    balance: types.maybeNull(types.optional(types.union(types.null, types.number, types.string), '')),
    term: types.maybeNull(types.optional(types.union(types.null, types.number, types.string), 60)),
    currency: types.maybeNull(types.optional(types.enumeration('USD', Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
});
