import { types } from 'mobx-state-tree';
export var OrderLocationAddressGPS = types.model('OrderLocationAddressGPS', {
    type: types.maybeNull(types.optional(types.string, 'Point')),
    zone: types.maybeNull(types.optional(types.string, '')),
    rawOffset: types.maybeNull(types.optional(types.number, 0)),
    coordinates: types.maybeNull(types.optional(types.array(types.union(types.null, types.undefined, types.string, types.number)), function () { return []; })),
});
export var OrderLocationAddress = types.model('OrderLocationAddress', {
    address: types.maybeNull(types.optional(types.string, '')),
    company: types.maybeNull(types.optional(types.string, '')),
    streetNumber: types.maybeNull(types.optional(types.string, '')),
    place_id: types.maybeNull(types.optional(types.string, '')),
    streetName: types.maybeNull(types.optional(types.string, '')),
    state: types.maybeNull(types.optional(types.string, '')),
    country: types.maybeNull(types.optional(types.string, '')),
    postal: types.maybeNull(types.optional(types.string, '')),
    area: types.maybeNull(types.optional(types.string, '')),
    location: types.maybeNull(types.optional(OrderLocationAddressGPS, function () { return OrderLocationAddressGPS.create(); })),
});
