import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { theme } from '../theme';
import { SubmitButtonWithLoading } from './SubmitButtonWithLoading';
var useStyles = makeStyles(function (theme) { return ({
    message: {
        color: theme.palette.secondary.dark,
        fontSize: theme.spacing(2),
        textAlign: 'center',
    },
    subText: {
        color: theme.palette.secondary.light,
        fontSize: theme.spacing(2),
        textAlign: 'center',
    },
}); });
export var SuccessMessage = function (props) {
    var message = props.message, subText = props.subText, buttonText = props.buttonText, path = props.path;
    var classes = useStyles();
    var history = useHistory();
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
            React.createElement(Typography, { className: classes.message, style: { textAlign: 'center', marginTop: 8 } }, message)),
        (subText && (React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
            React.createElement(Typography, { style: { textAlign: 'center' } }, subText)))) ||
            null,
        (buttonText && (React.createElement(Grid, { container: true, alignItems: "center", justify: "center", id: 'success-message-button', style: { marginTop: theme.spacing(1) } },
            React.createElement(SubmitButtonWithLoading, { v1: true, onClick: function () { return history.push(path); }, text: buttonText })))) ||
            null));
};
