var keysToRemove = ['__typename'];
export var clearRequest = function (body) {
    try {
        keysToRemove.forEach(function (k) { return delete body[k]; });
        Object.keys(body).forEach(function (key) {
            if (body[key] && typeof body[key] === 'object') {
                clearRequest(body[key]);
            }
        });
    }
    catch (e) { }
    return body;
};
