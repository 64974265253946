import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useRouteFormStyles = makeStyles(function (theme) { return ({
    start: {
        content: "' '",
        right: 24,
        top: 30,
        height: '100%',
        maxHeight: 'calc(100% - 10px)',
        zIndex: 2,
        borderWidth: 1,
        borderStyle: 'dashed',
        position: 'absolute',
        borderColor: theme.palette.success.light,
    },
    startV1: {
        top: 49,
        maxHeight: 'calc(100% - 32px)',
    },
    end: {
        right: 25,
        top: 14,
        position: 'absolute',
        '&::after': {
            content: "' '",
            width: 0,
            height: 0,
            bottom: 0,
            left: '50%',
            top: 0,
            zIndex: 1,
            position: 'absolute',
            border: "solid " + theme.palette.success.light,
            borderWidth: " 0 3px 3px 0",
            display: "inline-block",
            padding: 3,
            transform: "translate(-50%, -50% ) rotate(45deg)",
        },
    },
    endV1: {
        top: 27,
    },
    root: {
        position: 'absolute',
        right: 20,
        top: 20,
        width: 10,
        height: 10,
        border: "2px solid " + theme.palette.success.light,
        borderRadius: '50%',
        '& .line': {
            border: "1px dashed " + theme.palette.success.light,
            width: 60,
            position: 'absolute',
            transform: "translate(-50%, -50% ) rotate( 90deg)",
            transformOrigin: 'top',
            left: 6,
            top: 40,
        },
        '& .circle': {
            width: 10,
            height: 10,
            border: "2px solid " + theme.palette.success.light,
            borderRadius: '50%',
            '&:last-child': {
                marginTop: 60,
            },
        },
        '& .arrow': {
            position: 'absolute',
            border: "solid " + theme.palette.success.light,
            borderWidth: " 0 3px 3px 0",
            display: "inline-block",
            padding: 3,
            left: 5,
            top: 64,
            transform: "translate(-50%, -50% ) rotate(45deg)",
        },
    },
    rootV1: {
        top: 38,
    },
}); });
export var useFormStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: {
            marginBottom: theme.spacing(2),
        },
        title: {},
        sectionFOrm: {
            width: '100%',
            // backgroundColor: theme.palette.background.paper,
            paddingBottom: theme.spacing(3),
            '& form': {
                width: '100%',
            },
        },
        sectionTitle1: {
            fontWeight: 900,
            color: theme.palette.common.black,
        },
        sectionTitle2: {
            fontWeight: 900,
            textTransform: 'uppercase',
            textAlign: 'center',
            margin: 'auto',
            color: theme.palette.primary.light,
        },
        section: (_a = {
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(3, 4),
                marginBottom: theme.spacing(2),
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: 3
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(3, 2),
            },
            _a),
        button: {
            margin: theme.spacing(4, 0, 2, 0),
        },
        priceInput: {
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderRight: 'none',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        betweenInput: {
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderRight: 'none',
                borderRadius: 0,
            },
        },
        currencyInput: {
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        beginInput: {
            minWidth: 'initial!important',
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderRight: 'none',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        middleInput: {
            minWidth: 'initial!important',
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderRight: 'none',
                borderRadius: 0,
            },
        },
        endInput: {
            minWidth: 'initial!important',
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        lineRouter: {
            width: '100%',
            borderTop: "1px solid " + theme.palette.secondary.main,
            position: 'relative',
            '&::before': {
                content: "' '",
                width: 0,
                height: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                top: -2,
                zIndex: 2,
                borderWidth: 16,
                borderStyle: 'solid',
                position: 'absolute',
                borderColor: " " + theme.palette.common.white + " transparent transparent transparent",
            },
            '&::after': {
                content: "' '",
                display: 'inline-block',
                transform: 'translateX(-50%)',
                borderWidth: 16,
                borderStyle: 'solid',
                borderColor: theme.palette.secondary.main + " transparent transparent transparent ",
                width: 0,
                height: 0,
                left: '50%',
                top: 0,
                position: 'absolute',
                zIndex: 1,
            },
        },
    });
});
export var useInputStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        adorRootVLoading: {
            '& .MuiOutlinedInput-root': {
            // paddingRight: `${theme.spacing(0.3)}px!important`,
            },
        },
        adorRootV18: { minWidth: 'initial!important' },
        adorRootV17WithValues: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(8) + "px!important",
                '& .MuiChip-root': {
                    marginRight: theme.spacing(0.5),
                },
            },
        },
        adorRootWithoutRightPadding: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingRight: theme.spacing(1) + "px!important",
            },
        },
        adorRootV17WithoutValues: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingRight: theme.spacing(2) + "px!important",
            },
        },
        adorRootV25: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingRight: theme.spacing(2.8) + "px!important",
            },
        },
        adorRootV28: {
            minWidth: theme.spacing(10) + "px!important",
        },
        adorRootV25WithValues: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(2.8) + "px!important",
                '& .MuiChip-root': {
                    marginRight: theme.spacing(0.5),
                },
            },
        },
        adorRootV25Focused: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                '& input': {
                    width: 'calc(100% - 50px)',
                },
            },
        },
        adorRootV25WithoutValues: {
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingRight: theme.spacing(1) + "px!important",
            },
        },
        adorRootV17: {
            minWidth: theme.spacing(22),
            '& .start-icon': {
                marginRight: theme.spacing(0.5),
            },
            '& .MuiAutocomplete-tag': {
                marginLeft: 0,
            },
            '& .MuiOutlinedInput-root': {
                width: '100%',
                paddingTop: "0!important",
                paddingBottom: "0!important",
                paddingLeft: theme.spacing(1) + "!important",
                '& .MuiAutocomplete-input': {
                    paddingLeft: "0!important",
                },
                '& .MuiChip-root': {
                // margin: 0,
                },
            },
            '& .MuiChip-root': {
                height: 24,
                color: theme.palette.primary.light,
                border: "1px solid " + theme.palette.success.light,
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.common.white,
                '& .MuiChip-label': {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
                '& *': {
                    color: theme.palette.primary.light,
                },
            },
        },
        rootBase: {
            '& .MuiAutocomplete-listbox': {
                width: '100%',
            },
        },
        root4: {
            '& input': {
            // minWidth: 'initial!important',
            },
        },
        root5: {
            '& .MuiSelect-select': {
                paddingLeft: 6,
                paddingRight: 24,
            },
        },
        root6: {
            '& .MuiSelect-select': {
                paddingLeft: 14,
            },
        },
        root2: {
            maxWidth: '100%',
            '& .MuiInputBase-input': {
            // paddingTop: `3px!important`,
            // minWidth: 'initial!important',
            },
            '& input': (_a = {},
                // minWidth: 'initial!important',
                _a[theme.breakpoints.down('md')] = {
                    minWidth: "60px!important",
                },
                _a),
        },
        root3: {
            '& .MuiSelect-root': {
            // paddingTop: 7,
            },
        },
        root: {
            maxWidth: '100%',
            '& .MuiInputBase-root': {
            // paddingBottom: `2px!important`,
            },
        },
        root1: {
            '& input': {
                visibility: 'hidden',
            },
        },
    });
});
export var useInputStylesV1 = makeStyles(function (theme) {
    return createStyles({
        root: {
            marginBottom: theme.spacing(1.5),
        },
    });
});
export var useChipStyles = makeStyles(function (theme) {
    return createStyles({
        chipParentRootActive: {
            '&>.MuiFormControl-root': {
                marginTop: theme.spacing(0.5),
            },
        },
        chipRoot: {
            '&.MuiChip-root': {
                margin: theme.spacing(0, 0.5, 0, 0),
                height: 24,
                borderRadius: theme.spacing(1),
            },
        },
        rootV1: {
            '& input': {
                paddingTop: "3px!important",
                paddingBottom: "3px!important",
            },
        },
        chipRootV1: {
            height: 28,
            margin: 0,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
            '& .MuiChip-label': {
                marginRight: theme.spacing(0.5),
            },
            '& .izi-svg': {
                '& *': {
                    fill: 'inherit',
                    stroke: theme.palette.common.white,
                },
                '& .izi-svg-path': {
                    fill: theme.palette.common.white,
                },
            },
            '& .izi-svg-1': {
                '& *': {
                    stroke: theme.palette.common.white,
                    fill: theme.palette.common.white,
                },
                '& .izi-svg-path': {
                    stroke: theme.palette.primary.light,
                    fill: theme.palette.primary.light,
                },
            },
            '& .izi-svg-fill': {
                '& *': {
                    stroke: theme.palette.common.white,
                },
                '& .izi-svg-path': {
                    fill: theme.palette.common.white,
                },
            },
            '& path': {
                fill: theme.palette.common.white,
            },
            '& *': {
                color: theme.palette.common.white,
            },
        },
        chipRootIcon: {
            marginBottom: 0,
            '& .MuiChip-label': {
                display: 'flex',
                padding: '0px 4px',
            },
        },
        chipRootIcons: {
            '& .MuiInputBase-root': {
                paddingBottom: "6px!important",
            },
        },
        chipRootActive: {
            '&.MuiChip-root': {
                backgroundColor: theme.palette.common.white,
                border: "1px solid " + theme.palette.success.light,
                color: theme.palette.primary.light,
                borderRadius: theme.spacing(1),
                '& .MuiChip-label': {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
                '& .izi-svg': {
                    '& *': {
                        fill: 'inherit',
                        stroke: theme.palette.primary.light,
                    },
                    '& .izi-svg-path': {
                        fill: theme.palette.common.white,
                    },
                },
                '& .izi-svg-1': {
                    '& *': {
                        stroke: theme.palette.primary.light,
                        fill: theme.palette.primary.light,
                    },
                    '& .izi-svg-path': {
                        stroke: theme.palette.primary.light,
                        fill: theme.palette.common.white,
                    },
                },
                '& .izi-svg-fill': {
                    '& *': {
                        stroke: theme.palette.primary.light,
                    },
                    '& .izi-svg-path': {
                        fill: theme.palette.common.white,
                    },
                },
                '& path': {
                    fill: theme.palette.primary.light,
                },
                '& *': {
                    color: theme.palette.primary.light,
                },
            },
        },
    });
});
export var useMiniStyles = makeStyles(function (theme) {
    return createStyles({
        miniInputDate: {
            '& input': {
                textAlign: 'center',
                padding: theme.spacing(0.4, 0),
                fontSize: '0.9rem',
            },
        },
        rooInputDate: {
            '& input': {
                textAlign: 'center',
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    });
});
