var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { CHAT_BASE, CHAT_BASE_v1 } from './types';
export var FetchChat = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderChat($_id: MongoId!) {\n        orderChat(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    query orderChat($_id: MongoId!) {\n        orderChat(_id: $_id) {\n            ", "\n        }\n    }\n"])), CHAT_BASE);
export var FetchOrderChats = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query orderChats($filter: MainFilter) {\n        orderChats(filter: $filter) {\n            data {\n               ", "\n                deletedAt\n                totalUnread\n                lastChatMessage{\n                   text\n                   createdAt\n                   chat{\n                    _id\n                   }\n                }\n            }\n            count\n        }\n    }\n"], ["\n    query orderChats($filter: MainFilter) {\n        orderChats(filter: $filter) {\n            data {\n               ", "\n                deletedAt\n                totalUnread\n                lastChatMessage{\n                   text\n                   createdAt\n                   chat{\n                    _id\n                   }\n                }\n            }\n            count\n        }\n    }\n"])), CHAT_BASE);
export var FetchOrderChatsV1 = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderChatsV1($filter: MainFilter) {\n        orderChatsV1(filter: $filter) {\n            data {\n               ", "\n                deletedAt\n                totalUnread \n            }\n            count\n        }\n    }\n"], ["\n    query orderChatsV1($filter: MainFilter) {\n        orderChatsV1(filter: $filter) {\n            data {\n               ", "\n                deletedAt\n                totalUnread \n            }\n            count\n        }\n    }\n"])), CHAT_BASE_v1);
export var CheckChannelsUnreadMessages = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query checkChannelsUnreadMessages($filter: MainFilter) {\n    checkChannelsUnreadMessages(filter: $filter) {\n      data {\n        _id\n        totalUnread\n      }\n      count\n    }\n  }\n"], ["\n  query checkChannelsUnreadMessages($filter: MainFilter) {\n    checkChannelsUnreadMessages(filter: $filter) {\n      data {\n        _id\n        totalUnread\n      }\n      count\n    }\n  }\n"])));
export var CreateOrderChat = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation createOrderChat($input:OrderChatInput!){\n        createOrderChat(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderChat($input:OrderChatInput!){\n        createOrderChat(input:$input) {\n            ", "\n        }\n    }\n"])), CHAT_BASE);
export var UpdateOrderChat = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation updateOrderChat($input:OrderChatInputUpdate!){\n        updateOrderChat(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderChat($input:OrderChatInputUpdate!){\n        updateOrderChat(input:$input) {\n            ", "\n        }\n    }\n"])), CHAT_BASE);
export var DeleteOrderChat = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation deleteOrderChat($_id: MongoId!) {\n        deleteOrderChat(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderChat($_id: MongoId!) {\n        deleteOrderChat(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var ArchiveOrderChats = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation archiveOrderChats($ids: [MongoId]) {\n        archiveOrderChats(ids: $ids) {\n            ", " \n        }\n    }\n"], ["\n    mutation archiveOrderChats($ids: [MongoId]) {\n        archiveOrderChats(ids: $ids) {\n            ", " \n        }\n    }\n"])), BASE_RESPONSE);
export var DeleteAllOrderChats = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation deleteAllOrderChats($ids: [MongoId]) {\n        deleteAllOrderChats(ids: $ids) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteAllOrderChats($ids: [MongoId]) {\n        deleteAllOrderChats(ids: $ids) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
