var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { TextOverflow } from '../../TextOverflow';
import { MonthDay } from './MonthDay';
import { withCalendarStore } from '../store';
import { getWeekNumber, toDateOnly } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { useTableStyles } from '../../../styles/table';
import { monthDaysData } from './utils';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            borderSpacing: 0,
        },
    });
});
export var MonthView = withCalendarStore(function (_a) {
    var current = _a.current, loadAll = _a.loadAll, onChangeMode = _a.onChangeMode, config = _a.config, history = _a.history;
    var classes = useStyles();
    var classesTable = useTableStyles();
    var langApp = useTranslation(KEYS.APP);
    var theme = useTheme();
    var weekDays = moment.weekdays();
    weekDays = __spread(weekDays.filter(function (e, i) { return i > 0; }), [weekDays[0]]);
    var _b = __read(React.useState([]), 2), days = _b[0], setDays = _b[1];
    useEffect(function () {
        var monthView = monthDaysData(current);
        setDays(monthView);
        var lastWeek = monthView[monthView.length - 1];
        loadAll({
            fields: [
                { name: 'allWorkingDays', value: 1 },
                { name: 'start', value: toDateOnly(monthView[0][0]) },
                { name: 'end', value: toDateOnly(lastWeek[lastWeek.length - 1]) },
            ],
        });
    }, [current]);
    var currentMonth = moment(current).format('MMMM');
    return (React.createElement(Grid, { container: true, direction: 'column', className: classesTable.root },
        React.createElement("table", { className: classes.root, "data-id": "izi-warehouse-month-view" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { key: 'Week', style: { width: 50 } },
                        React.createElement(TextOverflow, { style: { fontWeight: 700, textAlign: 'left', color: theme.palette.primary.light } }, langApp.t('calendar_label_week'))),
                    weekDays.map(function (day) { return (React.createElement("th", { key: day.toString() },
                        React.createElement(TextOverflow, { style: { textAlign: 'center', fontWeight: 700, color: theme.palette.primary.light }, variant: 'subtitle1' }, langApp.t(day.toLowerCase())))); }))),
            React.createElement("tbody", null, days.map(function (weekDays, index) { return (React.createElement("tr", { key: index + "-week" },
                React.createElement("td", { style: {
                        width: '50px',
                        height: '170px',
                    } },
                    React.createElement(TextOverflow, { variant: 'h5', style: { fontWeight: 700, color: theme.palette.success.light } }, getWeekNumber(weekDays[0]))),
                weekDays.map(function (day) { return (React.createElement(MonthDay, { key: index + "-" + day, day: day, config: config, isCurrentMonth: moment(new Date(day)).format('MMMM') === currentMonth, onChangeMode: onChangeMode, history: history })); }))); })))));
});
