var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../components';
import { theme } from '../../theme';
import React from 'react';
import { i18n, KEYS } from '../../i18n';
import { ISSUE_STATUS } from '../../models/Issues';
import BaseChip from '../../components/BaseChip';
import { useTranslation } from 'react-i18next';
export default function IssueStatus(_a) {
    var record = _a.record, props = __rest(_a, ["record"]);
    var _id = Object.keys(ISSUE_STATUS).find(function (e) { return ISSUE_STATUS[e] === record.status; });
    var langForm = useTranslation(KEYS.FORM);
    var label = i18n.getResource(i18n.language, KEYS.APP, "main_status_" + _id.toLowerCase()) ||
        i18n.getResource(i18n.language, KEYS.OFFER_FORM, "offer_" + _id.toLowerCase()) ||
        _id;
    return (React.createElement(BaseChip, { label: React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(TextOverflow, { style: { color: theme.palette.common.white } }, record.status === ISSUE_STATUS.REQUESTED ? langForm.t('form_new') : label)), style: {
            backgroundColor: record.status === ISSUE_STATUS.RESOLVED
                ? theme.palette.success.light
                : [ISSUE_STATUS.REQUESTED].includes(record.status)
                    ? theme.palette.error.light
                    : theme.palette.warning.light,
            color: theme.palette.common.white,
        } }));
}
