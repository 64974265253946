var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Badge, withStyles } from '@material-ui/core';
export var StyledBadge = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
    },
}); })(Badge);
export var StyledBadgeV3 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light,
    },
}); })(Badge);
var styledRootV4 = {
    width: '100%',
    display: 'block',
};
var styledBadgeV4 = {
    marginRight: 12,
    padding: 0,
    '& div': {
        width: 20,
    },
};
export var StyledBadgeV4 = withStyles(function (theme) { return ({
    root: styledRootV4,
    badge: __assign(__assign({}, styledBadgeV4), { color: theme.palette.common.white + "!important", backgroundColor: theme.palette.success.light }),
}); })(Badge);
export var StyledBadgeV16 = withStyles(function (theme) { return ({
    root: styledRootV4,
    badge: __assign(__assign({}, styledBadgeV4), { color: theme.palette.common.white + "!important", backgroundColor: '#FF9900' }),
}); })(Badge);
export var StyledBadgeV15 = withStyles(function (theme) { return ({
    root: styledRootV4,
    badge: __assign(__assign({}, styledBadgeV4), { color: theme.palette.common.white + "!important", backgroundColor: '#FF9900', marginRight: 36 }),
}); })(Badge);
export var StyledBadgeV14 = withStyles(function (theme) { return ({
    badge: {
        color: "#FFBA00!important",
        backgroundColor: "#FFBA00!important",
        right: 3,
        top: 5,
        transform: ' scale(0.8) translate(0%, -50%)',
        overflow: 'visible',
    },
}); })(Badge);
export var StyledBadgeNotification = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.primary.light,
        // left: 0,
        // right: 'inherit',
        // transform: ' scale(1) translate(0%, -50%)',
        overflow: 'visible',
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            animationIterationCount: 3,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV1 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.error.light + "!important",
        left: 0,
        right: 'inherit',
        transform: ' scale(1) translate(0%, -50%)',
        overflow: 'visible',
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            animationIterationCount: 3,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV13 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.error.light + "!important",
        right: 4,
        top: 14,
        transform: ' scale(1) translate(0%, -50%)',
        overflow: 'visible',
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            animationIterationCount: 3,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV5 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light + "!important",
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animationDelay: '1s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
            animationIterationCount: 2,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV7 = withStyles(function (theme) { return ({
    badge: {
        top: 10,
        right: 10,
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light + "!important",
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animationDelay: '1s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
            animationIterationCount: 2,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV8 = withStyles(function (theme) { return ({
    root: {
        width: '100%',
    },
    badge: {
        top: 0,
        right: 0,
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light + "!important",
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animationDelay: '1s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
            animationIterationCount: 5,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV9 = withStyles(function (theme) { return ({
    root: {
        width: '100%',
    },
    badge: {
        top: 0,
        right: 2,
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light + "!important",
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animationDelay: '1s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
            animationIterationCount: 2,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV10 = withStyles(function (theme) { return ({
    root: {
        width: '100%',
    },
    badge: {
        top: 1,
        left: -28,
        right: 'initial',
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.success.light + "!important",
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animationDelay: '1s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
            animationIterationCount: 2,
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV6 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.error.light + "!important",
        '& path': {
            fill: theme.palette.common.white,
        },
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV2 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.error.light + "!important",
        overflow: 'visible',
        right: 12,
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            zIndex: -1,
        },
    },
}); })(Badge);
export var StyledBadgeV12 = withStyles(function (theme) { return ({
    badge: {
        color: theme.palette.common.white + "!important",
        backgroundColor: theme.palette.common.white,
        overflow: 'visible',
        right: theme.spacing(-1),
        top: -2,
        width: 22,
        '&::before': {
            content: "' '",
            display: 'block',
            position: 'absolute',
            width: 22,
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            borderRadius: 'inherit',
            transition: 'opacity .3s, -webkit-transform .3s',
            animation: 'pulse-animation-primary 1s cubic-bezier(0.24, 0, 0.38, 1) infinite',
            animationIterationCount: 5,
            zIndex: -1,
        },
    },
}); })(Badge);
