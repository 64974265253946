var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { theme } from '../theme';
import BaseTooltip from '../components/BaseTooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { StyledBadgeV12 } from './StyledBadge';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
export var HelpIconComponent = function (_a) {
    var title = _a.title, tooltipStyle = _a.tooltipStyle, disabled = _a.disabled, light = _a.light, props = __rest(_a, ["title", "tooltipStyle", "disabled", "light"]);
    if (props.info)
        return (React.createElement(StyledBadgeV12, { badgeContent: React.createElement(InfoOutlinedIcon, { style: __assign({ color: theme.palette.primary.light }, props.style) }) }));
    var icon = (React.createElement(HelpOutlineIcon, { className: props.className, style: __assign(__assign({ width: 20, height: 20 }, (light ? { color: theme.palette.common.white } : {})), props.style) }));
    if (props.infoV1)
        icon = React.createElement(InfoIcon, { style: __assign({ color: theme.palette.primary.light }, props.style) });
    if (props.warn)
        icon = React.createElement(ErrorIcon, { style: __assign({ color: theme.palette.warning.light }, props.style) });
    if (props.warn1)
        icon = React.createElement(WarningIcon, { style: __assign({ color: theme.palette.warning.light }, props.style) });
    if (props.success)
        icon = React.createElement(CheckIcon, { style: __assign({ color: theme.palette.success.light }, props.style) });
    if (props.error)
        icon = React.createElement(ErrorIcon, { style: __assign({ color: theme.palette.error.light }, props.style) });
    return ((title && (React.createElement(BaseTooltip, __assign({ title: title }, (props.isBigTooltip && { id: 'big-tooltip' }), { onMouseEnter: props.onMouseEnter, disabled: disabled }), icon))) ||
        null);
};
