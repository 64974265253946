import { applySnapshot, types } from 'mobx-state-tree';
import { OrderQuoteTransport } from './OrderQuoteTransport';
import { OrderCurrency } from './types';
import { OrderTransportation } from './OrderTransportation';
import { BaseUser, MaybeEmptyModel } from '../stores/common';
import { FastPayment } from './FastPayment';
export var OrderQuoteStatus;
(function (OrderQuoteStatus) {
    OrderQuoteStatus["Requested"] = "Requested";
    OrderQuoteStatus["Accepted"] = "Accepted";
    OrderQuoteStatus["Approved"] = "Approved";
    OrderQuoteStatus["Finished"] = "Finished";
    OrderQuoteStatus["Cancelled"] = "Cancelled";
})(OrderQuoteStatus || (OrderQuoteStatus = {}));
export var OrderQuoteFastPaymentStatus;
(function (OrderQuoteFastPaymentStatus) {
    OrderQuoteFastPaymentStatus["Available"] = "Available";
    OrderQuoteFastPaymentStatus["NotAvailable"] = "NotAvailable";
    OrderQuoteFastPaymentStatus["Accepted"] = "Accepted";
    OrderQuoteFastPaymentStatus["Cancelled"] = "Cancelled";
})(OrderQuoteFastPaymentStatus || (OrderQuoteFastPaymentStatus = {}));
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
// export const OrderQuoteTransport = types.model("OrderQuoteTransport", {
//   vehicleType: types.maybeNull(types.optional(types.string, "")),
//   track: types.maybeNull(types.optional(types.string, "")),
//   trailer: types.maybeNull(types.optional(types.string, "")),
//   driver: types.maybeNull(types.optional(types.string, "")),
//   contact: types.maybeNull(types.optional(types.string, "")),
// });
export var OrderQuoteCOVehicleType = {
    EURO6: 'EURO6',
};
export var OrderQuotes = types
    .model('OrderQuotes', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    transport: types.maybeNull(types.optional(OrderQuoteTransport, function () { return ({}); })),
    comment: types.maybeNull(types.optional(types.string, '')),
    // order: types.maybeNull(
    //     types.optional(MaybeEmptyModel(Order), () => Order.create())
    // ),
    tempTransportations: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderTransportation)), [])),
    forwarderAmount: types.maybeNull(types.optional(types.number, 0)),
    distance: types.maybeNull(types.optional(types.number, 0)),
    co2Emission: types.maybeNull(types.optional(types.number, 0)),
    vehicleCO2Type: types.maybeNull(types.optional(types.string, OrderQuoteCOVehicleType.EURO6)),
    amount: types.maybeNull(types.optional(types.number, 0)),
    daysBeforeExpired: types.maybeNull(types.optional(types.number, 1)),
    needApprove: types.maybeNull(types.optional(types.boolean, false)),
    acceptedAt: types.maybeNull(types.optional(types.string, '')),
    rejectedAt: types.maybeNull(types.optional(types.string, '')),
    expireStart: types.maybeNull(types.optional(types.string, '')),
    expireEnd: types.maybeNull(types.optional(types.string, '')),
    deniedContraOfferAt: types.maybeNull(types.optional(types.string, '')),
    deletedAt: types.maybeNull(types.optional(types.string, '')),
    fastPaymentStatus: types.maybeNull(types.optional(types.string, '')),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    currency: types.maybeNull(types.optional(types.enumeration(OrderCurrency.USD, Object.keys(OrderCurrency)), is_USA_APP ? OrderCurrency.USD : OrderCurrency.EUR)),
    status: types.optional(types.enumeration('FullTrackLoad', Object.values(OrderQuoteStatus)), OrderQuoteStatus.Requested),
    fastPayment: types.maybeNull(types.optional(FastPayment, function () { return ({}); })),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
