var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { inject } from 'mobx-react';
import { OrderStatus } from '../../../models';
import { Grid, IconButton } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import { BaseUserAppTYpe } from '../../../stores/common';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { toast } from 'react-toastify';
import { ApproveOrderPayment, ForwarderConfirmOrderPayment, ReleaseOrderPayment, } from '../../../graphql/order/orderPayment';
import { OrderPaymentStatus } from '../../../models/OrderPayment';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../../Formik';
import { onlySpacesRegExp } from '../../../common';
import { dateViewFormat } from '../../DateView';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { ACTION_CHANGE } from '../../BasicList';
import BaseTooltip from '../../BaseTooltip';
import { useFormStyles } from '../../../styles';
export var OrderPaymentAction = inject('store')(function (props) {
    var _a;
    var config = props.config, onChange = props.onChange, order = props.order, invoice = props.invoice, isInvoice = props.isInvoice, isCost = props.isCost, disabled = props.disabled, currentUser = props.store.accountStore.currentUser;
    var className = useFormStyles();
    var isCarrier = isInvoice
        ? typeof isCost !== undefined && !isCost
        : currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isForwarderEnv = !isInvoice && props.store.accountStore.isForwarderEnvOnly;
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var formRef = React.useRef();
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(__assign(__assign(__assign(__assign(__assign({}, (order || invoice).lastPayment), { comment: ((order || invoice).lastPayment && (order || invoice).lastPayment.comment) || '' }), (isForwarderEnv && {
        comment: ((order || invoice).lastPayment &&
            (order || invoice).lastPayment[(isCarrier && 'forwarderConfirmApprovedComment') || 'forwarderConfirmReleasedComment']) ||
            '',
    })), (order && { order: order._id })), (invoice && { invoice: invoice._id }))), 2), record = _c[0], setRecord = _c[1];
    if (((_a = (order || invoice)) === null || _a === void 0 ? void 0 : _a.status) !== OrderStatus.ApprovedQuote ||
        currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE)) {
        if (isInvoice) {
        }
        else {
            return null;
        }
    }
    if (currentUser.appType.includes(BaseUserAppTYpe.FORWARDER)) {
        if (isInvoice) {
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(OrderPaymentActionForwarder, __assign({}, props)),
                React.createElement(OrderPaymentActionForwarder, __assign({}, props, { isCarrier: true }))));
        }
    }
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var rawData, res, _a, updates, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    if (!(_b.sent()))
                        throw 'invalid form';
                    onPreventEvent(e);
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 5, 6, 7]);
                    setLoading(true);
                    rawData = {
                        status: OrderPaymentStatus.PAID,
                        comment: formRef.current.values.comment,
                    };
                    _a = config;
                    if (!_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: isCarrier ? ApproveOrderPayment : ReleaseOrderPayment,
                            variables: {
                                input: Object.assign(__assign(__assign(__assign({}, rawData), (record.order && { order: record.order._id || record.order })), (isInvoice && invoice && { invoice: invoice._id || invoice })), record._id ? { _id: record._id } : null),
                            },
                        })];
                case 3:
                    _a = (_b.sent());
                    _b.label = 4;
                case 4:
                    res = _a;
                    updates = __assign(__assign({}, record), (res.data.approveOrderPayment || res.data.releaseOrderPayment));
                    setRecord(updates);
                    if (!(order || invoice).lastPayment)
                        (order || invoice).lastPayment = {};
                    Object.assign((order || invoice).lastPayment, updates);
                    toast.success(isCarrier ? langOrderList.t('in_progress_payment_approved') : langOrderList.t('in_progress_payment_made'));
                    if (onChange)
                        onChange(record, ACTION_CHANGE.UPDATED, rawData);
                    return [3 /*break*/, 7];
                case 5:
                    e_1 = _b.sent();
                    console.log(e_1);
                    toast.error(e_1.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    if (!(_b.sent()))
                        throw 'invalid form';
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 5, 6, 7]);
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: ApproveOrderPayment,
                            variables: {
                                input: Object.assign(__assign(__assign({ status: OrderPaymentStatus.UNPAID, comment: formRef.current.values.comment }, (record.order && { order: record.order._id || record.order })), (record.invoice && { invoice: record.invoice._id || record.invoice })), record._id ? { _id: record._id } : null),
                            },
                        })];
                case 3:
                    _a = (_b.sent());
                    _b.label = 4;
                case 4:
                    res = _a;
                    setRecord(__assign(__assign({}, record), res.data.approveOrderPayment));
                    if (!(order || invoice).lastPayment)
                        (order || invoice).lastPayment = {};
                    Object.assign((order || invoice).lastPayment, res.data.approveOrderPayment);
                    toast.success(langOrderList.t('in_progress_payment_denied'));
                    if (onChange)
                        onChange(record, ACTION_CHANGE.UPDATED);
                    return [3 /*break*/, 7];
                case 5:
                    e_2 = _b.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var commentValue = record[(isForwarderEnv && ((isCarrier && 'forwarderConfirmApprovedComment') || 'forwarderConfirmReleasedComment')) ||
        'comment'];
    var title = ((record.status &&
        (record.status === OrderPaymentStatus.PAID
            ? "" + langOrderList.t('in_progress_payment_paid') + ((commentValue && "(" + commentValue + ")") || '') + "."
            : "" + langOrderList.t('in_progress_payment_not_paid') + ((commentValue && "(" + commentValue + ")") || '') + ".")) ||
        '') +
        ((isCarrier &&
            langOrderList.t("in_progress_" + ((isForwarderEnv && 'notify_forwarder_') || '') + ((isInvoice && 'invoice_') || '') + "payment_icon_label_paid")) ||
            langOrderList.t("in_progress_" + ((isForwarderEnv && 'notify_forwarder_') || '') + ((isInvoice && 'invoice_') || '') + "payment_icon_label_paid_released"));
    var actionOk = (isCarrier && langOrderList.t('in_progress_payment_to_approve')) || langOrderList.t('in_progress_payment_to_pay');
    var dialogProps = {};
    if (isCarrier) {
        Object.assign(dialogProps, {
            actionCancelTitle: langOrderList.t('in_progress_payment_to_deny'),
            onCancel: onCancel,
        });
    }
    var dialogBody = (React.createElement(Grid, { container: true, direction: 'column' },
        React.createElement(Formik, { innerRef: formRef, initialValues: record, validationSchema: Yup.object({
                comment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: function (e) { return e; } }, function (formik) {
            return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-payment-action' },
                React.createElement(Grid, { container: true, direction: "column", className: className.root },
                    React.createElement(FormikTextField, { autoFocus: true, multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment }))));
        })));
    var paid = record &&
        record.status === OrderPaymentStatus.PAID &&
        ((!isCarrier && record.releasedAt) || (isCarrier && record.approvedAt));
    var paymentAction = (order || invoice).status === OrderStatus.ApprovedQuote || isInvoice ? (React.createElement(BasicDialog, __assign({}, dialogProps, { shouldNotPrevent: true, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'sm' }, onOk: onOk, actionOkTitle: actionOk, title: langOrderList.t('in_progress_payment_title'), isMiddleBody: true, body: dialogBody, actionInit: function (onOpen) {
            var onClick = function () {
                !paid && onOpen();
            };
            var icon = paid || disabled ? (React.createElement(BaseTooltip, { title: disabled
                    ? "" + langOrderList.t('in_progress_payment_title')
                    : langOrderList.t('in_progress_payment_paid') + " " + dateViewFormat((isCarrier && record.approvedAt) || record.releasedAt, 1) },
                React.createElement(IconButton, { "aria-label": "menu", style: { cursor: 'initial' }, color: 'secondary' },
                    React.createElement(PaymentIcon, null)))) : (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { "aria-label": "menu", color: 'primary', id: 'btn-payment', disabled: loading || paid, onClick: onClick },
                    React.createElement(PaymentIcon, null))));
            if (props.label) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { v1: props.v1, icon: icon, label: props.label, handleClickOpen: onClick }));
            }
            return icon;
        } }))) : null;
    return paymentAction;
});
export var OrderPaymentActionForwarder = function (_a) {
    var order = _a.order, isCarrier = _a.isCarrier, config = _a.config, props = __rest(_a, ["order", "isCarrier", "config"]);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var classes = useFormStyles();
    var formRef = React.useRef();
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(__assign(__assign({}, order.lastPayment), { comment: (order.lastPayment &&
            order.lastPayment[(isCarrier && 'forwarderConfirmApprovedComment') || 'forwarderConfirmReleasedComment']) ||
            '', order: order._id })), 2), record = _c[0], setRecord = _c[1];
    var title = ((record.status &&
        (record.status === OrderPaymentStatus.PAID
            ? "" + langOrderList.t('in_progress_payment_paid') + ((record.comment && "(" + record.comment + ")") || '') + "."
            : "" + langOrderList.t('in_progress_payment_not_paid') + ((record.comment && "(" + record.comment + ")") || '') + ".")) ||
        '') +
        ((isCarrier && langOrderList.t("in_progress_payment_icon_label_paid")) ||
            langOrderList.t("in_progress_payment_icon_label_paid_released"));
    var paid = record &&
        record.status === OrderPaymentStatus.PAID &&
        record[(isCarrier && 'forwarderConfirmApprovedAt') || 'forwarderConfirmReleasedAt'];
    var actionOk = (isCarrier && langOrderList.t('in_progress_payment_to_approve')) || langOrderList.t('in_progress_payment_to_pay');
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, updates, e_3;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    if (!(_c.sent()))
                        throw 'invalid form';
                    onPreventEvent(e);
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 5, 6, 7]);
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: ForwarderConfirmOrderPayment,
                            variables: {
                                input: Object.assign((_b = {
                                        status: OrderPaymentStatus.PAID
                                    },
                                    _b[(isCarrier && 'forwarderConfirmApprovedComment') || 'forwarderConfirmReleasedComment'] = formRef.current
                                        .values.comment,
                                    _b.order = record.order._id || record.order,
                                    _b.action = isCarrier ? 'FORWARDER_CONFIRM_APPROVE' : 'FORWARDER_CONFIRM_RELEASE',
                                    _b), record._id ? { _id: record._id } : null),
                            },
                        })];
                case 3:
                    _a = (_c.sent());
                    _c.label = 4;
                case 4:
                    res = _a;
                    updates = __assign(__assign({}, record), res.data.forwarderConfirmOrderPayment);
                    setRecord(updates);
                    if (!order.lastPayment)
                        order.lastPayment = {};
                    Object.assign(order.lastPayment, updates);
                    toast.success(isCarrier ? langOrderList.t('in_progress_payment_approved') : langOrderList.t('in_progress_payment_made'));
                    return [3 /*break*/, 7];
                case 5:
                    e_3 = _c.sent();
                    toast.error(e_3.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, e_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    if (!(_b.sent()))
                        throw 'invalid form';
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 5, 6, 7]);
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 4];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: ForwarderConfirmOrderPayment,
                            variables: {
                                input: Object.assign({
                                    status: OrderPaymentStatus.UNPAID,
                                    forwarderConfirmApprovedComment: formRef.current.values.comment,
                                    order: record.order._id || record.order,
                                    action: 'FORWARDER_DECLINE',
                                }, record._id ? { _id: record._id } : null),
                            },
                        })];
                case 3:
                    _a = (_b.sent());
                    _b.label = 4;
                case 4:
                    res = _a;
                    setRecord(__assign(__assign({}, record), res.data.forwarderConfirmOrderPayment));
                    if (!order.lastPayment)
                        order.lastPayment = {};
                    Object.assign(order.lastPayment, res.data.forwarderConfirmOrderPayment);
                    toast.success(langOrderList.t('in_progress_payment_denied'));
                    return [3 /*break*/, 7];
                case 5:
                    e_4 = _b.sent();
                    toast.error(e_4.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var dialogProps = {};
    if (isCarrier) {
        Object.assign(dialogProps, {
            actionCancelTitle: langOrderList.t('in_progress_payment_to_deny'),
            onCancel: onCancel,
        });
    }
    var dialogBody = (React.createElement(Formik, { innerRef: formRef, initialValues: record, validationSchema: Yup.object({
            comment: Yup.string()
                .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                .required(langForm.t('form_input_validate_required')),
        }), onSubmit: function (e) { return e; } }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-payment-action' },
            React.createElement(Grid, { container: true, direction: "column", className: classes.root },
                React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment }))));
    }));
    return (React.createElement(BasicDialog, __assign({}, dialogProps, { dialogProps: { fullWidth: true, maxWidth: 'sm' }, onOk: onOk, actionOkTitle: actionOk, title: langOrderList.t('in_progress_payment_title'), isMiddleBody: true, body: dialogBody, withoutWrapper: true, actionInit: function (onOpen) {
            var onClick = function () {
                !paid && onOpen();
            };
            var icon = paid ? (React.createElement(BaseTooltip, { title: langOrderList.t('in_progress_payment_paid') + " " + dateViewFormat(paid, 1), id: 'btn-payment' },
                React.createElement(IconButton, { "aria-label": "menu", style: { cursor: 'initial' }, color: 'secondary' }, (isCarrier && React.createElement(ReceiptIcon, null)) || React.createElement(PaymentIcon, null)))) : (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { "aria-label": "menu", color: 'primary', disabled: loading || paid, onClick: onClick, id: 'btn-payment-approve-receive' }, (isCarrier && React.createElement(ReceiptIcon, null)) || React.createElement(PaymentIcon, null))));
            if (props.label) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: onClick }));
            }
            return icon;
        } })));
};
