import React from 'react';
import { Grid } from '@material-ui/core';
import { LocationCountryFilterItem } from './List/ListFilterLocationCountryFilter';
import { TextOverflow } from '../TextOverflow';
import { dateViewFormat } from '../DateView';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useMainStyles } from '../../styles';
export default (function (_a) {
    var record = _a.record;
    var mainClasses = useMainStyles();
    return (React.createElement(Grid, { container: true, direction: 'column', className: mainClasses.widthInitial },
        React.createElement(Grid, { container: true, alignItems: 'center', className: mainClasses.widthInitial },
            React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', className: mainClasses.widthInitial },
                React.createElement(LocationCountryFilterItem, { short: true, _id: record.order.sorting_countryPickup }),
                React.createElement(TextOverflow, { color: 'primary', variant: 'caption', style: { lineHeight: 1 } }, dateViewFormat(record.order.sorting_pickupAt))),
            React.createElement(ArrowForwardIosIcon, null),
            React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', className: mainClasses.widthInitial },
                React.createElement(LocationCountryFilterItem, { short: true, _id: record.order.sorting_countryDelivery }),
                React.createElement(TextOverflow, { color: 'primary', variant: 'caption', style: { lineHeight: 1 } }, dateViewFormat(record.order.sorting_deliveryAt)))),
        React.createElement(TextOverflow, null, [record.order.orderId, record.order.sorting_totalWeight].filter(function (e) { return e; }).join(', '))));
});
