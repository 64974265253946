var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_CARGO_TAINED_CODE_BASE = "\n    " + BASE_QUERY + "\n      name\n      description\n      origin\n      approvedAt\n      createdAt\n      updatedAt\n";
export var UpdateOrderCargoTaintedCode = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateOrderCargoTaintedCode($input: OrderCargoTaintedCodeInputUpdate!) {\n        updateOrderCargoTaintedCode(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCargoTaintedCode($input: OrderCargoTaintedCodeInputUpdate!) {\n        updateOrderCargoTaintedCode(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_TAINED_CODE_BASE);
export var CreateOrderCargoTaintedCode = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderCargoTaintedCode($input: OrderCargoTaintedCodeInput!) {\n        createOrderCargoTaintedCode(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCargoTaintedCode($input: OrderCargoTaintedCodeInput!) {\n        createOrderCargoTaintedCode(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_TAINED_CODE_BASE);
export var FetchOrderCargoTaintedCode = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderCargoTaintedCodes($filter:MainFilter) {\n        orderCargoTaintedCodes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderCargoTaintedCodes($filter:MainFilter) {\n        orderCargoTaintedCodes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_CARGO_TAINED_CODE_BASE);
export var DeleteOrderCargoTaintedCode = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderCargoTaintedCode($_id: MongoId!) {\n        deleteOrderCargoTaintedCode(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderCargoTaintedCode($_id: MongoId!) {\n        deleteOrderCargoTaintedCode(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
