var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useLocalStore } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { FormikTextField, TextOverflow } from '../../components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikAutocompleteAsyncAdd } from '../../components';
import { BasicDialog } from '../../components';
import { onPreventEvent } from '../../components';
import { getSnapshot } from 'mobx-state-tree';
import { i18n, KEYS } from '../../i18n';
import { CompanyStatusV1 } from '../../models/CompanyStatus';
import { CreateCompanyStatus, DeleteCompanyStatus, FetchCompanyStatus, UpdateCompanyStatus, } from '../../graphql/companyStatus';
import { theme } from '../../theme';
import { formUserErrorMessage, onlySpacesRegExp } from '../../common';
import DropDownItemActions from '../Order/form/dialogs/DropDownItemActions';
export var CompanyStatusDropdown = inject('store')(function (_a) {
    var autocompleteProps = _a.autocompleteProps, props = __rest(_a, ["autocompleteProps"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var store = props.store, onChange = props.onChange, client = props.config.client;
    var _b = __read(React.useState(JSON.parse(JSON.stringify(__assign(__assign({}, getSnapshot(CompanyStatusV1.create())), props.defaultValue)))), 2), _initialValue = _b[0], setInitialValue = _b[1];
    var formRef = React.useRef();
    var addOrEdit = function (_a, localStore) { return __awaiter(void 0, void 0, void 0, function () {
        var result_1, res, res, data, e_1;
        var _id = _a._id, input = __rest(_a, ["_id"]);
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    if (!_id) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.config.client.query({
                            query: UpdateCompanyStatus,
                            variables: {
                                input: __assign({ _id: _id }, input),
                            },
                        })];
                case 1:
                    res = _b.sent();
                    result_1 = res.data.updateCompanyStatus;
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, props.config.client.query({
                        query: CreateCompanyStatus,
                        variables: {
                            input: input,
                        },
                    })];
                case 3:
                    res = _b.sent();
                    result_1 = res.data.createCompanyStatus;
                    _b.label = 4;
                case 4:
                    try {
                        data = client.readQuery({ query: FetchCompanyStatus });
                        client.writeQuery({
                            query: FetchCompanyStatus,
                            data: {
                                companyStatusList: {
                                    count: data.companyStatusList.count,
                                    data: data.companyStatusList.data.map(function (e) {
                                        if (e._id === result_1._id) {
                                            return result_1;
                                        }
                                        return e;
                                    }),
                                },
                            },
                        });
                        //Update Apollo Cache
                    }
                    catch (e) { }
                    return [2 /*return*/, result_1];
                case 5:
                    e_1 = _b.sent();
                    console.log(e_1);
                    localStore.error = formUserErrorMessage(e_1);
                    toast.error((e_1 === null || e_1 === void 0 ? void 0 : e_1.message) || e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var title = 'Company status';
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (input) {
            return __awaiter(this, void 0, void 0, function () {
                var val;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            return [4 /*yield*/, addOrEdit(input, localStore)];
                        case 1:
                            val = _a.sent();
                            if (val)
                                toast.success(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_items_saved').replace(/_X_/, title));
                            return [2 /*return*/, val];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (onChange)
                        onChange(createdItem);
                    return [2 /*return*/];
            }
        });
    }); };
    var initialValue = _initialValue || __assign({}, getSnapshot(CompanyStatusV1.create()));
    var dialogBody = (React.createElement(React.Fragment, null,
        (localStore.error && React.createElement(Typography, { color: "error" }, localStore.error)) || null,
        React.createElement(Formik, { key: initialValue._id, innerRef: formRef, initialValues: __assign(__assign({}, initialValue), { description: initialValue.description || '' }), validationSchema: Yup.object({
                name: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            return (React.createElement(Form, { id: 'company-status' },
                React.createElement(Grid, { container: true, direction: "row", spacing: 2 },
                    React.createElement(Grid, { item: true, container: true, direction: "column" },
                        React.createElement(FormikTextField, { required: true, title: langForm.t('form_input_name'), variant: "outlined", type: "text", name: "name", value: formik.values.name })))));
        })));
    return (React.createElement(BasicDialog, { askCanClose: true, body: dialogBody, title: title, onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (handleClickOpen) { return (React.createElement(FormikAutocompleteAsyncAdd, __assign({}, props, { v7: true, v17: true, v27: true, isClient: true, noLoadOnScroll: true, onOpenDialog: function (e, _a) {
                var item = _a.item;
                return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_b) {
                        handleClickOpen(e);
                        setInitialValue(item);
                        return [2 /*return*/];
                    });
                });
            }, addTitle: title, removeQuery: DeleteCompanyStatus, deleteTitle: function (option) { return option.name; }, onChange: function (e, item) { return item && item._id && onChange && onChange(item); }, onRemove: function () { return onChange && onChange(); }, title: title, client: client, query: FetchCompanyStatus, autocompleteProps: __assign(__assign({}, autocompleteProps), { getOptionSelected: function (option, value) {
                    return option.name === value.name;
                }, getOptionLabel: function (option) { return option.name; } }), renderOption: function (option) {
                return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: { height: theme.spacing(4.7) } },
                    React.createElement(TextOverflow, { style: { maxWidth: "calc(100% - 40px)" } }, option.name),
                    React.createElement(DropDownItemActions, { option: option, canEdit: true })));
            } }))); } }));
});
