import { BaseUserAppTYpe } from '../../stores/common';
import { browserStorage } from '../../browserStorage';
export var isReviewOffer = function (accountStore, item) {
    var _a, _b;
    var isAvailableToBook = accountStore.currentUser._id &&
        accountStore.currentUser.appType.find(function (e) {
            return [
                BaseUserAppTYpe.CUSTOMER,
                // BaseUserAppTYpe.FORWARDER,
                BaseUserAppTYpe.WAREHOUSE,
                BaseUserAppTYpe.SENDER,
                BaseUserAppTYpe.CONSIGNEE,
            ].includes(e);
        });
    try {
        if (browserStorage.getItem('order_to_book') === (item === null || item === void 0 ? void 0 : item.order._id)) {
            return false;
        }
    }
    catch (e) { }
    return (!(isAvailableToBook && ((_a = item === null || item === void 0 ? void 0 : item.order) === null || _a === void 0 ? void 0 : _a.noQuotes) && ((_b = item === null || item === void 0 ? void 0 : item.order.companies) === null || _b === void 0 ? void 0 : _b.includes(accountStore.currentCompany._id))) &&
        (!accountStore.currentUser._id ||
            accountStore.currentUser.appType.find(function (e) {
                return [
                    BaseUserAppTYpe.CUSTOMER,
                    // BaseUserAppTYpe.FORWARDER,
                    BaseUserAppTYpe.WAREHOUSE,
                    BaseUserAppTYpe.SENDER,
                    BaseUserAppTYpe.CONSIGNEE,
                ].includes(e);
            })) &&
        !(isAvailableToBook &&
            item &&
            item.createdBy &&
            (item.createdBy._id === accountStore.currentUser._id ||
                (item.createdBy.company._id || item.createdBy.company) === accountStore.currentCompany._id)));
};
