import { applySnapshot, types } from 'mobx-state-tree';
import { BaseUser, MaybeEmptyModel } from '../stores/common';
export var OrderPayment = types
    .model('OrderPayment', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    comment: types.maybeNull(types.optional(types.string, '')),
    releasedAt: types.maybeNull(types.optional(types.string, '')),
    approvedAt: types.maybeNull(types.optional(types.string, '')),
    status: types.maybeNull(types.optional(types.string, '')),
    approvedBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    releasedBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
    createdBy: types.maybeNull(MaybeEmptyModel(BaseUser)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
export var OrderPaymentStatus;
(function (OrderPaymentStatus) {
    OrderPaymentStatus["PAID"] = "paid";
    OrderPaymentStatus["UNPAID"] = "unpaid";
})(OrderPaymentStatus || (OrderPaymentStatus = {}));
