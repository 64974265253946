import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import clsx from 'clsx';
import { theme } from '../theme';
export var useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    rootV2: {
        transition: '0.3s',
        transform: 'scale(0.5)',
        '&:hover': {
            transform: 'none',
        },
    },
    active: {
        color: theme.palette.primary.main,
    },
    circle: {
        position: 'relative',
        width: 30,
        height: 30,
        borderWidth: 4,
        borderStyle: 'solid',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleActive: {
        borderColor: theme.palette.primary.main,
    },
    circleCompeted: {
        '&::after': {
            content: "' '",
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            borderRadius: '50%',
            width: '70%',
            height: '70%',
            backgroundColor: theme.palette.primary.main,
        },
    },
    circleRegular: {
        borderColor: theme.palette.grey['400'],
    },
    completed: {
        color: theme.palette.common.white,
        zIndex: 1,
        fontSize: 22,
    },
});
export default function QontoStepIcon(props) {
    var _a, _b, _c, _d;
    var classes = useQontoStepIconStyles();
    var active = props.active, completed = props.completed;
    return (React.createElement("div", { className: clsx(classes.root, (_a = {},
            _a[classes.active] = active,
            _a)) },
        React.createElement("div", { className: clsx(classes.circle, (_b = {}, _b[classes.circleRegular] = !completed && !active, _b), (_c = {}, _c[classes.circleActive] = completed || active, _c), (_d = {}, _d[classes.circleCompeted] = completed, _d)) }, completed ? React.createElement(Check, { className: classes.completed }) : null)));
}
