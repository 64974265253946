var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { INVOICE_SERVICE_BASE } from './base';
export var InvoiceServices = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query invoiceServices($filter: MainFilter) {\n        invoiceServices(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query invoiceServices($filter: MainFilter) {\n        invoiceServices(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), INVOICE_SERVICE_BASE);
export var CreateInvoiceService = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createInvoiceService($input:InvoiceServiceInput!){\n        createInvoiceService(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createInvoiceService($input:InvoiceServiceInput!){\n        createInvoiceService(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_SERVICE_BASE);
export var UpdateInvoiceService = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateInvoiceService($input:InvoiceServiceInputUpdate!){\n        updateInvoiceService(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateInvoiceService($input:InvoiceServiceInputUpdate!){\n        updateInvoiceService(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_SERVICE_BASE);
export var DeleteInvoiceService = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteInvoiceService($_id: MongoId!) {\n        deleteInvoiceService(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteInvoiceService($_id: MongoId!) {\n        deleteInvoiceService(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
