var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import { useField } from 'formik';
import BaseTooltip from '../BaseTooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { TextOverflow } from '../TextOverflow';
export var FormikIconCheckboxField = function (props) {
    var title = props.title, onChange = props.onChange, icon = props.icon, checkedIcon = props.checkedIcon, labelStyle = props.labelStyle, noTitle = props.noTitle, inputProps = __rest(props, ["title", "onChange", "icon", "checkedIcon", "labelStyle", "noTitle"]);
    var _a = __read(useField(props), 3), field = _a[0], meta = _a[1], helpers = _a[2];
    var _onChange = function (e) {
        helpers.setValue(!field.value);
        if (onChange)
            onChange(e);
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(BaseTooltip, { title: title },
            React.createElement(Checkbox, __assign({}, inputProps, field, { checked: field.value, icon: icon, checkedIcon: checkedIcon, onClick: _onChange }))))); });
};
export var FormikIconCheckboxFieldV1 = function (props) {
    var title = props.title, onChange = props.onChange, icon = props.icon, checkedIcon = props.checkedIcon, labelStyle = props.labelStyle, label = props.label, noTitle = props.noTitle, inputProps = __rest(props, ["title", "onChange", "icon", "checkedIcon", "labelStyle", "label", "noTitle"]);
    var _a = __read(useField(props), 3), field = _a[0], meta = _a[1], helpers = _a[2];
    var _onChange = function (e) {
        helpers.setValue(!field.value);
        if (onChange)
            onChange(e);
    };
    return useObserver(function () { return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, __assign({}, inputProps, field, { checked: field.value, icon: icon, checkedIcon: checkedIcon, onClick: _onChange })), label: label && (React.createElement(Grid, { container: true, alignItems: 'center', onClick: _onChange },
            React.createElement(TextOverflow, { variant: 'subtitle1' }, label))) })); });
};
