import { types } from 'mobx-state-tree';
import { CompanyForwarder } from './BaseCompanyShort';
import { BaseUserAppTYpe } from './types';
import { AsyncActionStatus } from './AsyncActionStatus';
import { MaybeEmptyModel } from './MaybeEmptyModel';
export var UserForwarder = types.compose(AsyncActionStatus, types.model('UserForwarder', {
    _id: types.maybeNull(types.optional(types.string, '')),
    email: types.maybeNull(types.optional(types.string, '')),
    firstName: types.maybeNull(types.optional(types.string, '')),
    lastName: types.maybeNull(types.optional(types.string, '')),
    company: types.maybeNull(types.optional(MaybeEmptyModel(CompanyForwarder), function () { return CompanyForwarder.create(); })),
    appType: types.maybeNull(types.optional(types.array(types.enumeration('CUSTOMER', Object.keys(BaseUserAppTYpe))), function () { return [
        BaseUserAppTYpe.CUSTOMER,
    ]; })),
}));
