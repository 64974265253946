import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const ChatContent = React.lazy(() => import('@izi-logistics/common/lib/pages/Chat/index'));

export default function ChatContentComponent(props) {
  return (
    <PreloadingComponent>
      <ChatContent {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
