import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { AsyncActionStatus, BaseCompany, BaseUserAppTYpe, MaybeEmptyModel, USER_ROLE } from '../stores/common';
import { Upload } from './Upload';
import { BaseUserRoles } from './UserRoles';
export var USER_STATUS;
(function (USER_STATUS) {
    USER_STATUS["ACTIVE"] = "ACTIVE";
    USER_STATUS["INACTIVE"] = "INACTIVE";
    USER_STATUS["REGISTER"] = "REGISTER";
    USER_STATUS["APPROVE_REGISTER"] = "APPROVE_REGISTER";
})(USER_STATUS || (USER_STATUS = {}));
export var USER_CHAT_STATUS;
(function (USER_CHAT_STATUS) {
    USER_CHAT_STATUS["ONLINE"] = "ONLINE";
    USER_CHAT_STATUS["OFFLINE"] = "OFFLINE";
    USER_CHAT_STATUS["INACTIVE"] = "INACTIVE";
})(USER_CHAT_STATUS || (USER_CHAT_STATUS = {}));
export var User = types
    .compose(AsyncActionStatus, types
    .model('User', {
    _id: types.maybeNull(types.optional(types.string, '')),
    email: types.maybeNull(types.optional(types.string, '')),
    avatar: types.maybeNull(types.optional(Upload, function () { return Upload.create(); })),
    phone: types.maybeNull(types.optional(types.string, '')),
    // forwarders: types.maybeNull(types.array(types.string)),
    // forwarders: types.maybeNull(types.optional(types.array(MaybeEmptyModel(Forwarder)), () => [])),
    // forwarders: types.array(
    //   types.model({
    //     _id: types.string,
    //     firstName: types.string,
    //   })
    // ),
    appType: types.optional(types.array(types.enumeration('CUSTOMER', Object.keys(BaseUserAppTYpe))), function () { return [
        BaseUserAppTYpe.CUSTOMER,
    ]; }),
    roles: types.optional(types.array(types.enumeration('MEMBER', Object.keys(USER_ROLE))), function () { return [
        USER_ROLE.MEMBER,
    ]; }),
    status: types.maybeNull(types.optional(types.string, '')),
    firstName: types.maybeNull(types.optional(types.string, '')),
    lastName: types.maybeNull(types.optional(types.string, '')),
    company: types.maybeNull(types.optional(MaybeEmptyModel(BaseCompany), function () { return BaseCompany.create(); })),
    rolesV1: types.maybeNull(types.optional(types.array(MaybeEmptyModel(BaseUserRoles)), function () { return []; })),
    affiliateUrl: types.maybeNull(types.optional(types.string, '')),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(User.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); })
    .views(function (self) { return ({
    fullName: self.firstName + " " + self.lastName,
}); }))
    .named('User');
