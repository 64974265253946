// import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
// import { TextOverflow } from './TextOverflow';
// import { useTranslation } from 'react-i18next';
// import { KEYS } from '../i18n';
// import { CircularIndeterminate } from './CircularIndeterminate';
// import { FetchTerms } from '../graphql/terms';
// import { FilePreview } from './Dialog';
import { inject } from 'mobx-react';
// import { theme } from '../theme';
export default inject('store')(function (_a) {
    // const [item, setItem] = React.useState(value);
    // const [loading, setLoading] = React.useState(false);
    // const [checked, setChecked] = React.useState(false);
    // const langForm = useTranslation(KEYS.FORM);
    // const onChangeInput = () => {
    //   setChecked(!checked);
    //   accountStore.currentUser.updateSettings({
    //     terms: checked
    //       ? accountStore.currentUser.settings.terms.filter((e) => e !== item._id)
    //       : [...accountStore.currentUser.settings.terms, item._id],
    //   });
    // };
    var value = _a.value, content = _a.content, config = _a.config, filter = _a.filter, accountStore = _a.store.accountStore;
    // React.useEffect(() => {
    //   (filter &&
    //     Object.keys(filter).length &&
    //     config.client
    //       .query({
    //         query: FetchTerms,
    //         variables: { filter },
    //       })
    //       .then(({ data }) => {
    //         const term = data.terms.data[0];
    //         setItem(term);
    //         if (term && accountStore.currentUser.settings.terms.includes(term._id)) {
    //           setChecked(true);
    //         }
    //       })
    //       .finally(() => setLoading(false))) ||
    //     setLoading(false);
    // }, [filter]);
    //
    // if (loading) return <CircularIndeterminate />;
    // if (!item) return content(true);
    return content(true);
    // return (
    //   <Grid container alignItems={'center'} justify="flex-end" key={checked ? 1 : -1}>
    //     <FormControlLabel
    //       style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 0 }}
    //       control={
    //         <Checkbox
    //           checked={checked}
    //           style={{ padding: 0, paddingRight: theme.spacing(0.5) }}
    //           onClick={onChangeInput}
    //           name="onlyMyTransports"
    //           color="primary"
    //         />
    //       }
    //       label={
    //         <Grid container alignItems={'center'}>
    //           <TextOverflow onClick={onChangeInput} style={{ maxWidth: 'calc(100% - 30px)' }}>
    //             {langForm.t('form_terms_read').replace(/_X_/, (item && item.name) || '')}
    //           </TextOverflow>
    //           <Grid container alignItems={'center'} style={{ width: 'initial' }}>
    //             {item.files.map((e) => (
    //               <FilePreview
    //                 style={{ padding: 0, paddingLeft: theme.spacing(0.5) }}
    //                 item={e}
    //                 key={e._id}
    //                 config={config}
    //                 title={(langForm.t(`form_terms_file_overview`) || '').replace(/_X_/, e.name)}
    //               />
    //             ))}
    //           </Grid>
    //         </Grid>
    //       }
    //     />
    //     {content(checked)}
    //   </Grid>
    // );
});
