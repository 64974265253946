var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme';
import SectionDetails from '../../../components/SectionDetails';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { OrderLocation } from '../../../models';
import ItemBodyServices from './ItemBodyServices';
import ItemBodyInformation from './ItemBodyInformation';
import { FetchOrderPayments } from '../../../graphql/invoices/payments';
import PreloadingComponent from '../../../components/PreloadingComponent';
var PaymentItem = React.lazy(function () { return import('./PaymentItem'); });
export default (function (props) {
    var langForm = useTranslation(KEYS.FORM);
    var record = props.record, config = props.config;
    return (React.createElement(Grid, { container: true, direction: 'column', style: { padding: theme.spacing(1) } },
        React.createElement(ItemBodyServices, __assign({}, props)),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, md: 4 },
                React.createElement(SectionDetails, { title: langForm.t('form_invoice_seller'), rows: [
                        {
                            name: langForm.t('form_input_name'),
                            value: record.seller_name || record.createdBy.company.name || '-',
                        },
                        {
                            name: langForm.t('form_input_name_company_code'),
                            value: record.seller_companyCode || record.createdBy.company.companyCode || '-',
                        },
                        {
                            name: langForm.t('form_input_name_address'),
                            value: (record.seller_address &&
                                record.seller_address.streetName &&
                                OrderLocation.create({ address: record.seller_address }).fullAddress) ||
                                record.createdBy.company.address ||
                                '-',
                        },
                        {
                            name: langForm.t('form_input_name_phone'),
                            value: record.createdBy.phone || '-',
                        },
                        {
                            name: langForm.t('form_input_name_email'),
                            value: record.createdBy.email || '-',
                        },
                    ] })),
            React.createElement(ItemBodyInformation, { record: record }),
            React.createElement(Grid, { item: true, xs: 12, md: 5 },
                React.createElement(SectionDetails, { title: langForm.t('form_invoice_payments'), rows: [], config: config, query: FetchOrderPayments, rowContent: function (record) { return (React.createElement(PreloadingComponent, null,
                        React.createElement(PaymentItem, { record: record }))); }, variables: {
                        filter: {
                            fields: [
                                { name: 'invoice', value: record._id },
                                record.quote && record.quote.order && { name: 'order', value: record.quote.order._id },
                            ].filter(function (e) { return e; }),
                        },
                    } })))));
});
