var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, flow } from 'mobx-state-tree';
import { AsyncActionStatus } from './common';
import { toast } from 'react-toastify';
import { i18n } from '../i18n';
import { KEYS } from '../i18n';
import { CreateUploadType, FetchUploadTypes, UpdateUploadType } from '../graphql/uploadType';
export var OrderQuoteStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model('OrderQuoteStore', {
        isOrderUpdating: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        createUploadType: flow(function (_a) {
            var res, data, e_1;
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateUploadType,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchUploadTypes });
                            client.writeQuery({
                                query: FetchUploadTypes,
                                data: {
                                    uploadTypes: {
                                        count: data.uploadTypes.count + 1,
                                        data: __spread([res.data.createUploadType], data.uploadTypes.data),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) {
                            console.log('createUploadType fresh cache error. PLEASE FIX ME', e);
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_input_upload_type')));
                        return [2 /*return*/, res.data.createUploadType];
                    case 2:
                        e_1 = _b.sent();
                        console.log('createUploadType', e_1);
                        toast.error(e_1.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateUploadType: flow(function (_a) {
            var res_1, data, e_2;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, input = __rest(_a, ["_id", "updatedAt", "name"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isOrderUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateUploadType,
                                variables: {
                                    input: { _id: _id, name: name },
                                },
                            })];
                    case 1:
                        res_1 = _b.sent();
                        data = client.readQuery({ query: FetchUploadTypes });
                        client.writeQuery({
                            query: FetchUploadTypes,
                            data: {
                                uploadTypes: {
                                    count: data.uploadTypes.count + 1,
                                    data: data.uploadTypes.data.map(function (uploadType) {
                                        return uploadType._id === res_1.data.updateUploadType._id ? res_1.data.updateUploadType : uploadType;
                                    }),
                                },
                            },
                        });
                        //Update Apollo Cache
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_input_upload_type')));
                        return [2 /*return*/, res_1.data.updateUploadType];
                    case 2:
                        e_2 = _b.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isOrderUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .named('OrderQuoteStore');
};
