var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import GoogleMapReactV from 'google-map-react';
import { makeStyles, Grid } from '@material-ui/core';
import './map.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, withRouter } from 'react-router-dom';
import { MainButton } from '../../../MainButton';
import { inject } from 'mobx-react';
import { onPreventEvent } from '../../../Dialog';
import browserHistory from '../../../../browserHistory';
import { NAVIGATION } from '../../../../HOK/LocationHistory';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { OrderStatus } from '../../../../models';
import { getSnapshot } from 'mobx-state-tree';
import { useIconStyles } from '../../../../styles';
import { TextOverflow } from '../../../TextOverflow';
import { createMapOptions, getMapBounds, MAP_DEFAULT_COORDINATES } from '../../../../utils1';
import { theme } from '../../../../theme';
var GoogleMapReact = GoogleMapReactV;
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
var is_map_hidden = 0; ///parseInt(process.env.REACT_APP_HIDE_MAP || '0') === 1;
export var useStyles3 = makeStyles(function (theme) {
    var _a, _c, _d;
    return ({
        map: (_a = {
                width: '100%',
                height: '168px',
                minHeight: '168px',
                position: 'relative'
            },
            _a[theme.breakpoints.down('sm')] = {
                height: '50vh',
            },
            _a),
        overview: (_c = {
                backgroundColor: theme.palette.common.white,
                border: "2px solid " + theme.palette.primary.light,
                padding: theme.spacing(1),
                position: 'absolute',
                right: 0,
                bottom: 30,
                maxWidth: 200,
                zIndex: 11
            },
            _c[theme.breakpoints.down('sm')] = {
                bottom: 60,
                right: 10,
            },
            _c),
        backButton: (_d = {
                position: 'absolute',
                textDecoration: 'unset',
                zIndex: 2,
                left: 100,
                top: 40
            },
            _d[theme.breakpoints.down('sm')] = {
                left: 'initial',
                top: 'initial',
                bottom: 10,
                right: 10,
            },
            _d),
    });
});
var Marker = function (_a) {
    var text = _a.text, icon = _a.icon, p = __rest(_a, ["text", "icon"]);
    var classesUseIconStyles = useIconStyles();
    return React.createElement("span", { className: classesUseIconStyles.iconMapRoot }, icon);
};
var bindResizeListener = function (map, maps, bounds) {
    maps.event.addDomListenerOnce(map, 'idle', function () {
        maps.event.addDomListener(window, 'resize', function () {
            map.fitBounds(bounds);
        });
    });
};
function makeGradientColor(color1, color2, percent) {
    var newColor = {};
    function makeChannel(a, b) {
        return a + Math.round((b - a) * (percent / 100));
    }
    function makeColorPiece(num) {
        num = Math.min(num, 255); // not more than 255
        num = Math.max(num, 0); // not less than 0
        var str = num.toString(16);
        if (str.length < 2) {
            str = '0' + str;
        }
        return str;
    }
    newColor.r = makeChannel(color1.r, color2.r);
    newColor.g = makeChannel(color1.g, color2.g);
    newColor.b = makeChannel(color1.b, color2.b);
    newColor.cssColor = '#' + makeColorPiece(newColor.r) + makeColorPiece(newColor.g) + makeColorPiece(newColor.b);
    return newColor;
}
function createMarkers(props) {
    var _markers = props.markers;
    if (props.store.orderStore && (!_markers || !_markers.length)) {
        _markers = createMarkersOrder(getSnapshot(props.store.orderStore.order));
    }
    _markers = _markers.filter(function (e) { return e.filter(function (f) { var _a, _c, _d; return (_d = (_c = (_a = f.origin) === null || _a === void 0 ? void 0 : _a.address) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.coordinates[0]; }).length > 1; });
    return _markers;
}
export var createMarkersOrder = function (order) {
    var _markers = order.transportations.map(function (e) {
        return e.routes.map(function (e) {
            var _a;
            return (__assign(__assign({}, e), { origin: {
                    address: (((_a = e.origin) === null || _a === void 0 ? void 0 : _a.address) && __assign(__assign({}, e.origin.address), { lat: (e.origin.address.location && e.origin.address.location.coordinates[1]) || 0, lng: (e.origin.address.location && e.origin.address.location.coordinates[0]) || 0 })) || { lng: 0, lat: 0 },
                } }));
        });
    });
    _markers = _markers.filter(function (e) { return e.filter(function (f) { var _a, _c, _d; return (_d = (_c = (_a = f.origin) === null || _a === void 0 ? void 0 : _a.address) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.coordinates[0]; }).length > 1; });
    return _markers;
};
var SimpleMapComponent = inject('store')(React.memo(function (props) {
    var _a, _c;
    var classes = useStyles3();
    var _markers = props.markers || createMarkers(props);
    // const mapRef: any = React.createRef();
    var _d = __read(React.useState(null), 2), details = _d[0], setDetails = _d[1];
    var _e = __read(React.useState([]), 2), markers = _e[0], setMarkers = _e[1];
    var _f = __read(React.useState({ planned: [], actual: [] }), 2), routes = _f[0], setRoutes = _f[1];
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var store = props.store, v1 = props.v1;
    var defaultProps = __assign({}, MAP_DEFAULT_COORDINATES);
    var drawRoadPath = function (map, _markers, isActual) {
        return new Promise(function (resolve, reject) {
            if (!_markers[0].length ||
                _markers[0].length < 2 ||
                [
                    _markers[0][0].origin.address.location && _markers[0][0].origin.address.location.coordinates[1],
                    _markers[0][0].origin.address.location && _markers[0][0].origin.address.location.coordinates[0],
                    _markers[_markers.length - 1][1].origin.address.location.coordinates[1],
                    _markers[_markers.length - 1][1].origin.address.location.coordinates[0],
                ].filter(function (e) { return e; }).length != 4)
                return console.log('no data for path');
            var start = _markers[0][0].origin.address.location &&
                new google.maps.LatLng(_markers[0][0].origin.address.location.coordinates[1], _markers[0][0].origin.address.location.coordinates[0]);
            var end = _markers[_markers.length - 1][1].origin.address.location &&
                new google.maps.LatLng(_markers[_markers.length - 1][1].origin.address.location.coordinates[1], _markers[_markers.length - 1][1].origin.address.location.coordinates[0]);
            function makeMarker(position) {
                var icon = new google.maps.MarkerImage(
                // URL
                'http://maps.gstatic.com/mapfiles/ms2/micons/blue-pushpin.png', 
                // (width,height)
                new google.maps.Size(44, 32), 
                // The origin point (x,y)
                new google.maps.Point(0, 0), 
                // The anchor point (x,y)
                new google.maps.Point(10, 32));
                new google.maps.Marker({
                    position: position,
                    map: map,
                    icon: icon,
                    zIndex: 999999,
                    title: langApp.t('shipment_cargo_position'),
                });
            }
            var display = new google.maps.DirectionsRenderer(__assign(__assign({ map: map }, (isActual && { suppressMarkers: true })), { polylineOptions: isActual
                    ? {
                        strokeColor: theme.palette.primary.light,
                        strokeOpacity: 0.7,
                        strokeWeight: 4,
                    }
                    : {
                        strokeColor: theme.palette.error.main,
                        strokeOpacity: 0,
                        strokeWeight: 2,
                        icons: [
                            {
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    fillColor: theme.palette.error.light,
                                    fillOpacity: 0.7,
                                    scale: 2,
                                    strokeColor: theme.palette.error.light,
                                    strokeOpacity: 0.7,
                                },
                                offset: '0',
                                repeat: '10px',
                            },
                        ],
                    } }));
            var services = new google.maps.DirectionsService();
            // display.setMap(map);
            if (start && end) {
                var request = {
                    origin: start,
                    destination: end,
                    travelMode: 'DRIVING',
                };
                services.route(request, function (result, status) {
                    if (status == 'OK') {
                        display.setDirections(result);
                        resolve(result);
                        if (isActual) {
                            makeMarker(end);
                        }
                        if (!props.actualPosition &&
                            window.location.pathname.startsWith('/orders/') &&
                            store.orderStore.order._id &&
                            store.orderStore.order.status === OrderStatus.Draft) {
                            try {
                                store.orderStore.updateOrder({
                                    _id: store.orderStore.order._id,
                                    distance: result.routes
                                        .map(function (r) {
                                        return r.legs
                                            .filter(function (e) { return e.distance; })
                                            .map(function (leg) { return leg.distance.text; })
                                            .join(',');
                                    })
                                        .join(','),
                                });
                            }
                            catch (e) {
                                console.log('==========', e);
                            }
                        }
                        /*const route = result.routes[0];
                const summaryPanel = document.getElementById(
                    "directions-panel"
                ) as HTMLElement;
                summaryPanel.innerHTML = "";
        
                // For each route, display summary information.
                for (let i = 0; i < route.legs.length; i++) {
                  const routeSegment = i + 1;
                  summaryPanel.innerHTML +=
                      "<b>Route Segment: " + routeSegment + "</b><br>";
                  summaryPanel.innerHTML += route.legs[i].start_address + " to ";
                  summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
                  summaryPanel.innerHTML += route.legs[i].distance!.text + "<br><br>";
                }*/
                    }
                    else if (store.orderStore.order.distance) {
                        if (!props.actualPosition)
                            store.orderStore.updateOrder({
                                _id: store.orderStore.order._id,
                                distance: '',
                            });
                    }
                });
            }
        });
    };
    var calcBounds = function (d) { return __awaiter(void 0, void 0, void 0, function () {
        var _b, _details, map, maps, bounds, m, result, result1, result1_1;
        var _a;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (is_map_hidden)
                        return [2 /*return*/];
                    _b = _markers
                        .map(function (e) {
                        return e.map(function (f) { return ({
                            geometry: {
                                location: {
                                    lat: f.origin.address.location.coordinates[1],
                                    lng: f.origin.address.location.coordinates[0],
                                },
                            },
                        }); });
                    })
                        .reduce(function (a, b) { return __spread(a, b); }, [])
                        .filter(function (e) { return e; });
                    _details = d || details;
                    if (!_b.length || !(_details === null || _details === void 0 ? void 0 : _details.maps))
                        return [2 /*return*/];
                    map = _details.map, maps = _details.maps;
                    bounds = getMapBounds(map, maps, _b);
                    // Fit map to bounds
                    map.fitBounds(bounds);
                    m = __spread(_markers);
                    result = __assign({}, routes);
                    return [4 /*yield*/, drawRoadPath(map, _markers)];
                case 1:
                    result1 = _c.sent();
                    if (result1)
                        result.planned = result1.routes;
                    if (!((_a = props.actualPosition) === null || _a === void 0 ? void 0 : _a.length)) return [3 /*break*/, 3];
                    return [4 /*yield*/, drawRoadPath(map, props.actualPosition, true)];
                case 2:
                    result1_1 = _c.sent();
                    if (result1_1)
                        result.actual = result1_1.routes;
                    _c.label = 3;
                case 3:
                    setRoutes(result);
                    setMarkers(m);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleApiLoaded = function (_a) {
        var map = _a.map, maps = _a.maps;
        setDetails({ map: map, maps: maps });
        calcBounds({ map: map, maps: maps });
    };
    React.useEffect(function () {
        calcBounds();
    }, [props.markers]);
    if (is_map_hidden)
        return null;
    var mapKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
    var onBack = function (e) {
        var prevLoc = NAVIGATION.prevLocation.toLowerCase();
        if ((prevLoc || '').trim() &&
            browserHistory.length &&
            !NAVIGATION.hash &&
            !prevLoc.startsWith('/orders/create') /* &&
    (prevLoc.startsWith('/orders') || prevLoc.startsWith('/in-progress') || prevLoc.startsWith('/draft'))*/) {
            browserHistory.goBack();
            return onPreventEvent(e);
        }
    };
    var showMap = v1 ? _markers.length : true;
    var content = [
        {
            name: 'planned',
            style: { color: theme.palette.error.main, opacity: 0.8 },
        },
        {
            name: 'actual',
            style: { color: theme.palette.primary.main, opacity: 0.9 },
        },
    ]
        .map(function (e) {
        return (routes[e.name].length && (React.createElement(Grid, { key: e.name, style: { overflow: 'hidden' } }, routes[e.name].map(function (route, index) { return (React.createElement(Grid, { key: "route-" + e.name + "-" + index, style: { width: '100%' } }, route.legs
            .filter(function (e) { return e.distance; })
            .map(function (leg) { return (React.createElement(Grid, { container: true, key: leg.distance.text },
            React.createElement(TextOverflow, { variant: 'subtitle2' }, [leg.start_address, leg.end_address].join('-')),
            React.createElement(TextOverflow, { variant: 'caption', style: __assign({}, e.style) },
                langApp.t("shipment_" + e.name),
                ":",
                ' ',
                React.createElement("b", null, is_USA_APP && !(leg.distance.text || '').match('mi')
                    ? (leg.distance.value * 0.000000621371192).toFixed(2) + ' mi'
                    : leg.distance.text)))); }))); })))) ||
            null;
    })
        .filter(function (e) { return e; });
    return (React.createElement("div", { className: classes.map, id: "map" },
        (!props.hideBack && (React.createElement(Link, { to: "/open-orders", className: classes.backButton },
            React.createElement(MainButton, { id: 'back_to_orders', onClick: onBack, title: langForm.t('form_btn_back') + " " + langApp.t('main_orders').toLowerCase(), variant: "outlined", v1: true, icon: React.createElement(ArrowBackIosIcon, null) })))) ||
            null,
        ((content === null || content === void 0 ? void 0 : content.length) && (React.createElement(Grid, { container: true, className: classes.overview, id: 'map-view' }, content))) ||
            null,
        showMap ? (React.createElement(GoogleMapReact, { bootstrapURLKeys: {
                key: ((_c = (_a = window.envirements) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.name === 'GOOGLE_PLACES_API_KEY'; })) === null || _c === void 0 ? void 0 : _c.value) || mapKey || '',
                libraries: 'places',
                language: 'en',
            }, center: defaultProps.center, defaultCenter: defaultProps.center, defaultZoom: defaultProps.zoom, 
            // yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded: handleApiLoaded, options: createMapOptions })) : null));
}, function (prevProps, nextProps) {
    var prevMarkers = createMarkers(prevProps);
    var nextMarkers = createMarkers(nextProps);
    if (prevMarkers.length !== nextMarkers.length)
        return false;
    for (var i = 0; i < prevMarkers.length; i++) {
        if (prevMarkers[i]._id !== nextMarkers[i]._id)
            return false;
    }
    return true;
}));
export var SimpleMap = withRouter(SimpleMapComponent);
export default withRouter(SimpleMapComponent);
