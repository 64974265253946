import { createStyles, makeStyles } from '@material-ui/core';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: '100%',
        },
        autoCompleteContainer: {
            position: 'relative',
            '& .google-option': {
                padding: theme.spacing(1),
            },
        },
        autocompleteDropdownContainer: {
            backgroundColor: theme.palette.grey['100'],
            position: 'absolute',
            top: 54,
            width: '100%',
            zIndex: 1,
        },
        locationSearchInput: {},
    });
});
