var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY } from './base';
import { NOTIFICATION_ACTIONS } from '../notifications';
import { GuideTypes } from '../guides';
export var USER_EMAIL_SETTINGS_BASE = "\n" + Object.values(NOTIFICATION_ACTIONS).join('\n') + "\n";
export var USER_GUIDE_SETTINGS_BASE = "\n" + Object.values(GuideTypes).join('\n') + "\n";
export var USER_SETTINGS_BASE = "\n  " + BASE_QUERY + "\n  terms\n  orderAutoArchiveDelay\n  ratingCaModalViewAt\n  emailSettings{\n    " + USER_EMAIL_SETTINGS_BASE + "\n  }\n  phoneSettings{\n    " + USER_EMAIL_SETTINGS_BASE + "\n  }\n  guides{\n    " + USER_GUIDE_SETTINGS_BASE + "\n  }\n  updatedAt\n  filters{\n      name\n      value\n      type\n      id\n  }\n  selectedVehicleTypesAt\n  forwarderOrderMarkUpAuto\n  forwarderOrderMarkUpPercentage\n      forwarderOrderMarkUpMinPrice\n      forwarderOrderMarkUpMinPriceCurrency\n      forwarderOrderMarkUpMaxPrice\n      forwarderOrderMarkUpMaxPriceCurrency\n  orderAutoArchiveDelay\n  hideToDo\n  showPlatformDuty\n  openWizardOnClick\n  isOneTimeWizardClosed\n  disablePushNotificationModal\n  personalAffiliateRevenue\n";
export var UpdateUserSettings = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation updateUserSettings($input: UserSettingsInputUpdate!) {\n      updateUserSettings(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation updateUserSettings($input: UserSettingsInputUpdate!) {\n      updateUserSettings(input: $input) {\n      ", "\n    }\n  }\n"])), USER_SETTINGS_BASE);
export var ToggleAllVehicleTypes = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation toggleAllVehicleTypes($input: UserSettingsInputUpdate!) {\n      toggleAllVehicleTypes(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation toggleAllVehicleTypes($input: UserSettingsInputUpdate!) {\n      toggleAllVehicleTypes(input: $input) {\n      ", "\n    }\n  }\n"])), USER_SETTINGS_BASE);
var templateObject_1, templateObject_2;
