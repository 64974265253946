import React from 'react';
import { FetchUsers } from '../../graphql/user';
import { UsersList } from '../../pages/Users';
export var MemberList = function (_a) {
    var client = _a.client;
    return (React.createElement(UsersList, { config: { client: client, query: FetchUsers }, isTeam: true, v1: true, addMemberProps: {
            isTeam: true,
            hideCompany: true,
        } }));
};
