import AssignedUnLoaded from '-!svg-react-loader!./assets/AssignedUnLoaded.svg';
import AssignedUnLoadingTime from '-!svg-react-loader!./assets/AssignedUnLoadingTime.svg';
import Confirmed from '-!svg-react-loader!./assets/confirmed.svg';
import TruckOk from '-!svg-react-loader!./assets/truckOk.svg';
import MdiAccountCogOutline from '-!svg-react-loader!./assets/mdiAccountCogOutline.svg';
import BoxSmall from '-!svg-react-loader!./assets/box_small.svg';
import AssignedInTransit from '-!svg-react-loader!./assets/AssignedInTransit.svg';
import AssignedInTransit1 from '-!svg-react-loader!./assets/AssignedInTransit1.svg';
import AssignedTransport from '-!svg-react-loader!./assets/AssignedTransport.svg';
import AssignedTransport1 from '-!svg-react-loader!./assets/AssignedUnloaded1.svg';
import Warehouse from '-!svg-react-loader!./assets/warehouse.svg';
import InProgress from '-!svg-react-loader!./assets/in-progress.svg';
import Dashboard from '-!svg-react-loader!./assets/dashboard.svg';
import Preparation from '-!svg-react-loader!./assets/preparation.svg';
import Carrier from '-!svg-react-loader!./assets/carrier.svg';
import Customer from '-!svg-react-loader!./assets/customer.svg';
import MainLogo from '-!svg-react-loader!./assets/logo.svg';
import MainLogoNoneBrand from '-!svg-react-loader!./assets/Lintega-Cargo.svg';
import OfferAcceptedLogo from '-!svg-react-loader!./assets/OfferAccepted.svg';
import OfferRequestedLogo from '-!svg-react-loader!./assets/OfferRequested.svg';
import OfferSubmittedLogo from '-!svg-react-loader!./assets/OfferSubmitted.svg';
import ExpeditionLogo from '-!svg-react-loader!./assets/Expedition.svg';
import CarrierExpeditionLogo from '-!svg-react-loader!./assets/Carrier_izi_expedition.svg';
import CustomerExpeditionLogo from '-!svg-react-loader!./assets/Customer_IZI_Expedition.svg';
import More from '-!svg-react-loader!./assets/more.svg';
import OrderViewMore from '-!svg-react-loader!./assets/OrderView.svg';
import CMR from '-!svg-react-loader!./assets/CMR.svg';
import manufacturing from '-!svg-react-loader!./assets/manufacturing.svg';
import timacom from '-!svg-react-loader!./assets/timacom.svg';
import CarrierIconI from '-!svg-react-loader!./assets/type_carrier.svg';
import LangIconI from '-!svg-react-loader!./assets/langs.svg';
import VirtualWarehouseIconI from '-!svg-react-loader!./assets/virtual_warehouse.svg';
import TradeIconI from '-!svg-react-loader!./assets/trade.svg';
import HolidayIconI from '-!svg-react-loader!./assets/holidays.svg';
export var HolidayIcon = HolidayIconI;
export var TradeIcon = TradeIconI;
export var LangIcon = LangIconI;
export var VirtualWarehouseIcon = VirtualWarehouseIconI;
export var TimacomIcon = timacom;
export var ManufacturingIcon = manufacturing;
export var CMRIcon = CMR;
export var MoreIcon = More;
export var OrderViewMoreIcon = OrderViewMore;
export var CustomerExpeditionLogoIcon = CustomerExpeditionLogo;
export var CarrierExpeditionLogoIcon = CarrierExpeditionLogo;
export var ExpeditionLogoIcon = ExpeditionLogo;
export var OfferAcceptedLogoIcon = OfferAcceptedLogo;
export var OfferRequestedLogoIcon = OfferRequestedLogo;
export var OfferSubmittedLogoIcon = OfferSubmittedLogo;
export var CustomerIcon = Customer;
export var MainLogoIcon = MainLogo;
export var MainLogoNoneBrandIcon = MainLogoNoneBrand;
export var CarrierIcon = Carrier;
export var CarrierIconItem = CarrierIconI;
export var AssignedUnLoadedIcon = AssignedUnLoaded;
export var AssignedUnLoadingTimeIcon = AssignedUnLoadingTime;
export var AssignedLoadingTimeIcon = AssignedUnLoadingTime;
export var ConfirmedIcon = Confirmed;
export var TruckOkIcon = TruckOk;
export var MdiAccountCogOutlineIcon = MdiAccountCogOutline;
export var WarehouseIcon = Warehouse;
export var InProgressIcon = InProgress;
export var DashboardIcon = Dashboard;
export var PreparationIcon = Preparation;
export var BoxSmallIcon = BoxSmall;
export var AssignedInTransitIcon = AssignedInTransit;
export var AssignedInTransitIcon1 = AssignedInTransit1;
export var AssignedTransportIcon = AssignedTransport;
export var AssignedTransportIcon1 = AssignedTransport1;
