var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { FORWARDER_USER_BASE, USER_COMPANY_BASE } from './base'; //
export var StatisticsTotalOfferSubmittedAccepted = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query statisticTotalOfferSubmittedAccepted($filter: MainFilter) {\n    statisticTotalOfferSubmittedAccepted(filter: $filter) {\n      data {\n        ", "\n        totalSubmitted \n        totalAccepted \n        totalSubmittedAccepted \n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalOfferSubmittedAccepted($filter: MainFilter) {\n    statisticTotalOfferSubmittedAccepted(filter: $filter) {\n      data {\n        ", "\n        totalSubmitted \n        totalAccepted \n        totalSubmittedAccepted \n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalOfferSubmittedBookedTime = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query statisticTotalOfferSubmittedBookedTime($filter: MainFilter) {\n    statisticTotalOfferSubmittedBookedTime(filter: $filter) {\n      data {\n        ", "\n        totalAccepted \n        totalBookedTime \n        totalBookedTimeAccepted \n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalOfferSubmittedBookedTime($filter: MainFilter) {\n    statisticTotalOfferSubmittedBookedTime(filter: $filter) {\n      data {\n        ", "\n        totalAccepted \n        totalBookedTime \n        totalBookedTimeAccepted \n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticCarrierCompanyActivity = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query statisticCarrierCompanyActivity($filter: MainFilter) {\n    statisticCarrierCompanyActivity(filter: $filter) {\n      data {\n        ", "\n        totalSubmitted\n      }\n      count\n    }\n  }\n"], ["\n  query statisticCarrierCompanyActivity($filter: MainFilter) {\n    statisticCarrierCompanyActivity(filter: $filter) {\n      data {\n        ", "\n        totalSubmitted\n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticsStatisticTotalAVGTimeToFirstOffer = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query statisticTotalAVGTimeToFirstOffer($filter: MainFilter) {\n    statisticTotalAVGTimeToFirstOffer(filter: $filter) {\n      data {\n        ", "\n        avgPublishOfferDiff\n        \n      }\n      count\n      totalAvgPublishOfferDiff\n    }\n  }\n"], ["\n  query statisticTotalAVGTimeToFirstOffer($filter: MainFilter) {\n    statisticTotalAVGTimeToFirstOffer(filter: $filter) {\n      data {\n        ", "\n        avgPublishOfferDiff\n        \n      }\n      count\n      totalAvgPublishOfferDiff\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticsStatisticTotalAvgTotalOfferPublishOrder = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  query statisticTotalAvgTotalOfferPublishOrder($filter: MainFilter) {\n    statisticTotalAvgTotalOfferPublishOrder(filter: $filter) {\n      data {\n        ", "\n        avgPublishOfferDiff\n        \n      }\n      count\n      totalAvgPublishOfferDiff\n    }\n  }\n"], ["\n  query statisticTotalAvgTotalOfferPublishOrder($filter: MainFilter) {\n    statisticTotalAvgTotalOfferPublishOrder(filter: $filter) {\n      data {\n        ", "\n        avgPublishOfferDiff\n        \n      }\n      count\n      totalAvgPublishOfferDiff\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalAvgDeletedOrders = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query statisticTotalAvgDeletedOrders($filter: MainFilter) {\n    statisticTotalAvgDeletedOrders(filter: $filter) {\n      data {\n        ", "\n        totalDeletedOrders\n      }\n      count\n      avgDeletedOrders\n      totalDeletedOrders\n    }\n  }\n"], ["\n  query statisticTotalAvgDeletedOrders($filter: MainFilter) {\n    statisticTotalAvgDeletedOrders(filter: $filter) {\n      data {\n        ", "\n        totalDeletedOrders\n      }\n      count\n      avgDeletedOrders\n      totalDeletedOrders\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalAcceptedOfferPublishOrder = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  query statisticTotalAcceptedOfferPublishOrder($filter: MainFilter) {\n    statisticTotalAcceptedOfferPublishOrder(filter: $filter) {\n      data {\n        ", "\n        totalAccepted\n        totalPublished\n        \n      }\n      count\n    }\n  }\n"], ["\n  query statisticTotalAcceptedOfferPublishOrder($filter: MainFilter) {\n    statisticTotalAcceptedOfferPublishOrder(filter: $filter) {\n      data {\n        ", "\n        totalAccepted\n        totalPublished\n        \n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalCarrierBookingChanges = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query statisticTotalCarrierBookingChanges($filter: MainFilter) {\n    statisticTotalCarrierBookingChanges(filter: $filter) {\n      data {\n        ", "\n        totalDateChanges\n        totalAVGDateChanges \n        totalOffers \n      }\n       totalDateChanges\n      count\n    }\n  }\n"], ["\n  query statisticTotalCarrierBookingChanges($filter: MainFilter) {\n    statisticTotalCarrierBookingChanges(filter: $filter) {\n      data {\n        ", "\n        totalDateChanges\n        totalAVGDateChanges \n        totalOffers \n      }\n       totalDateChanges\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalCanceledDeletedOffers = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query statisticTotalCanceledDeletedOffers($filter: MainFilter) {\n    statisticTotalCanceledDeletedOffers(filter: $filter) {\n      data {\n        ", "\n        totalCanceled \n        totalAccepted \n        totalCanceledAccepted \n      }\n      totalAvgAccepted\n      totalAvgCanceled\n      totalAvgCanceledAccepted\n      count\n    }\n  }\n"], ["\n  query statisticTotalCanceledDeletedOffers($filter: MainFilter) {\n    statisticTotalCanceledDeletedOffers(filter: $filter) {\n      data {\n        ", "\n        totalCanceled \n        totalAccepted \n        totalCanceledAccepted \n      }\n      totalAvgAccepted\n      totalAvgCanceled\n      totalAvgCanceledAccepted\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var StatisticTotalFOProfitability = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query statisticTotalFOProfitability($filter: MainFilter) {\n    statisticTotalFOProfitability(filter: $filter) {\n      data {\n        ", "\n        forwarders{\n                ", "\n             }\n        totalForwarderAmount\n        totalAmount\n      }\n      totalProfit\n      count\n    }\n  }\n"], ["\n  query statisticTotalFOProfitability($filter: MainFilter) {\n    statisticTotalFOProfitability(filter: $filter) {\n      data {\n        ", "\n        forwarders{\n                ", "\n             }\n        totalForwarderAmount\n        totalAmount\n      }\n      totalProfit\n      count\n    }\n  }\n"])), USER_COMPANY_BASE, FORWARDER_USER_BASE);
export var StatisticTotalCaLoggedIn = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  query statisticTotalCaLoggedIn($filter: MainFilter) {\n    statisticTotalCaLoggedIn(filter: $filter) {\n      count\n    }\n  }\n"], ["\n  query statisticTotalCaLoggedIn($filter: MainFilter) {\n    statisticTotalCaLoggedIn(filter: $filter) {\n      count\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
