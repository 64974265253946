var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { BASE_QUERY, BASE_RESPONSE, FORWARDER_USER_BASE } from '../base';
import gql from 'graphql-tag';
import { ORDER_BASE_TO_VIEW_MORE, ORDER_TRANSPORTATION_BASE } from './types';
import { ALL_ORDER, ORDER_ALL_BASE, ORDER_BASE } from '.';
export var ORDER_GROUP_BASE_v1 = "\norders{\n  _id\n}\nlastStep\ntotalOrders\ngeneratedAt\ntotalGoodsPerOrder";
export var ORDER_GROUP_BASE = "\n    " + BASE_QUERY + "\n   " + ORDER_GROUP_BASE_v1;
export var FetchOrderGroup = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery orderGroup($_id: MongoId!) {\n    orderGroup(_id: $_id) {\n     ", "\n          ", "\n           ", "\n     }\n}\n"], ["\nquery orderGroup($_id: MongoId!) {\n    orderGroup(_id: $_id) {\n     ", "\n          ", "\n           ", "\n     }\n}\n"])), ORDER_GROUP_BASE_v1, ORDER_ALL_BASE, ALL_ORDER);
export var FetchOrderGroups = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n query orderGroupes($filter:MainFilter){\n        orderGroupes(filter:$filter) {\n        data{\n            ", "\n             ", "\n             publishedAt\n             orderId\n        }\n        count\n    }\n}\n"], ["\n query orderGroupes($filter:MainFilter){\n        orderGroupes(filter:$filter) {\n        data{\n            ", "\n             ", "\n             publishedAt\n             orderId\n        }\n        count\n    }\n}\n"])), ORDER_GROUP_BASE, ORDER_BASE_TO_VIEW_MORE);
export var CreateOrderGroup = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation createOrderGroup($input: OrderGroupInput!) {\n        createOrderGroup(input: $input) {\n            ", "\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"], ["\n    mutation createOrderGroup($input: OrderGroupInput!) {\n        createOrderGroup(input: $input) {\n            ", "\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"])), ORDER_GROUP_BASE, ORDER_BASE, FORWARDER_USER_BASE, ORDER_TRANSPORTATION_BASE);
export var UpdateOrderGroup = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation updateOrderGroup($input: OrderGroupInputUpdate!) {\n        updateOrderGroup(input: $input) {\n            ", "\n            ", "\n            forwarders{\n              ", "\n            }\n        }\n    }\n"], ["\n    mutation updateOrderGroup($input: OrderGroupInputUpdate!) {\n        updateOrderGroup(input: $input) {\n            ", "\n            ", "\n            forwarders{\n              ", "\n            }\n        }\n    }\n"])), ORDER_GROUP_BASE, ORDER_BASE, FORWARDER_USER_BASE);
export var DeleteOrderGroup = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation deleteOrderGroup($_id: MongoId!) {\n    deleteOrderGroup(_id: $_id){\n        ", "\n    }\n  }\n"], ["\n  mutation deleteOrderGroup($_id: MongoId!) {\n    deleteOrderGroup(_id: $_id){\n        ", "\n    }\n  }\n"])), BASE_RESPONSE);
export var GenerateOrderGroups = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation generateGroupOrders($_id:MongoId!) {\n        generateGroupOrders(_id: $_id) {\n             ", "\n        }\n    }\n"], ["\n    mutation generateGroupOrders($_id:MongoId!) {\n        generateGroupOrders(_id: $_id) {\n             ", "\n        }\n    }\n"])), ORDER_GROUP_BASE);
export var PublishOrderGroups = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation publishGroupOrders($_id:MongoId!) {\n        publishGroupOrders(_id: $_id) {\n             ", "\n        }\n    }\n"], ["\n    mutation publishGroupOrders($_id:MongoId!) {\n        publishGroupOrders(_id: $_id) {\n             ", "\n        }\n    }\n"])), ORDER_GROUP_BASE);
export var DuplicateOrderGroup = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation duplicateOrderGroup($_id:MongoId!) {\n        duplicateOrderGroup(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation duplicateOrderGroup($_id:MongoId!) {\n        duplicateOrderGroup(_id: $_id) {\n            ", "\n        }\n    }\n"])), ORDER_ALL_BASE);
export var CopyOrderGroup = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation copyOrderGroup($input: OrderCopyInput) {\n    copyOrderGroup(input: $input) {\n      ", "\n           ", "\n    }\n  }\n"], ["\n  mutation copyOrderGroup($input: OrderCopyInput) {\n    copyOrderGroup(input: $input) {\n      ", "\n           ", "\n    }\n  }\n"])), ORDER_ALL_BASE, ALL_ORDER);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
