var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { i18n, KEYS } from '../../../i18n';
import { TextOverflow } from '../../../components';
export default function ConsigneeDocumentTypes(_a) {
    var order = _a.order;
    var list = [];
    try {
        order.transportations.forEach(function (e) {
            var item = e.routes
                .filter(function (e) { return e.origin.locationAdvanced.find(function (e) { return e.documentType && e.documentType._id; }); })
                .map(function (e) { return e.origin.locationAdvanced.filter(function (e) { return e.documentType && e.documentType._id; }); })
                .reduce(function (a, b) { return __spread(a, b); }, []);
            if (item && item.length) {
                list.push.apply(list, __spread(item));
            }
        });
    }
    catch (e) { }
    var res = list
        .map(function (e) { var _a; return (_a = e.documentType) === null || _a === void 0 ? void 0 : _a.name; })
        .filter(function (e) { return e === null || e === void 0 ? void 0 : e.trim(); })
        .join(', ');
    if (!res)
        return null;
    return (React.createElement(TextOverflow, { variant: 'body1', color: 'primary' }, (i18n.getResource(i18n.language, KEYS.APP, 'main_require_documents') || '').replace('_X_', res)));
}
