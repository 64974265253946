export var NOTIFICATION_ACTIONS = {
    REGISTER_USER: 'user_request_register',
    REGISTER_USER_SHORT: 'user_request_register_short',
    REGISTER_USER_APPROVED: 'user_registration_approved',
    REGISTER_USER_APPROVED_SORT: 'user_registration_approved_short',
    USER_REQUEST_ACCESS: 'user_request_access',
    REGISTER_USER_APPROVED_INVITE: 'user_registration_approved_invite',
    REGISTER_USER_INVITE_TO_TEAM: 'user_registration_invite_to_team',
    REGISTER_USER_AFFILIATE_INVITE_TO_TEAM: 'user_registration_affiliate_invite_to_team',
    REGISTER_USER_APPROVED_FROM_OLD: 'user_registration_approved_from_old',
    RESET_PSW: 'user_forgot_password',
    USER_JOINED_TO_ENV: 'user_joined_to_env',
    USER_LEFT_ENV: 'user_left_env',
    QUOTE_CREATED: 'notify_customer_order_quote_created',
    QUOTE_UPDATED: 'notify_customer_quote_updated',
    DOCUMENT_UPLOADED_FOR_NONE_APP_USER: 'notify_emails_document_uploaded',
    REQUEST_ORDER_DOCUMENT: 'notify_emails_request_order_document',
    QUOTE_ASSIGN_TRANSPORT: 'notify_customer_order_quote_assign_transport',
    QUOTE_LOADING_AT_BOOKED: 'notify_customer_order_quote_loading_time_booked',
    QUOTE_UNLOADING_AT_BOOKED: 'notify_customer_order_quote_unloading_time_booked',
    QUOTE_UNLOADING_AT_CHANGED: 'notify_customer_order_quote_unloading_time_changed',
    QUOTE_LOADING_AT_CHANGED: 'notify_customer_order_quote_loading_time_changed',
    QUOTE_LOADING_AT: 'notify_order_quote_loading_at',
    QUOTE_LOADING_AT_SYSTEM: 'notify_order_quote_loading_at_by_system',
    QUOTE_UNLOADING_AT: 'notify_order_quote_un_loading_at',
    QUOTE_UNLOADING_AT_SYSTEM: 'notify_order_quote_un_loading_at_by_system',
    QUOTE_NO_LOADING_AT: 'notify_order_quote_no_loading_at',
    QUOTE_NO_UNLOADING_AT: 'notify_order_quote_no_un_loading_at',
    QUOTE_BOOKING_NOTIFY_LOCATION_ROUTE_COMPANY: 'notify_location_route_company_order_quote_booking',
    QUOTE_ACCEPTED: 'notify_carrier_order_quote_accepted',
    QUOTE_REJECTED: 'notify_carrier_order_quote_rejected',
    QUOTE_REJECTED_BY_SYSTEM: 'notify_carrier_order_quote_rejected_by_system',
    ORDER_PUBLISHED_FOR_QUOTE: 'notify_carrier_order_published',
    ORDER_SHARE_WITH_CARRIER: 'notify_order_share_with_carrier',
    ORDER_CARRIER_IN_PROGRESS: 'notify_customer_order_in_progress',
    NOTIFICATION_ORDER_PUBLISHED_FOR: 'notify_order_published_for',
    ORDER_NOTIFY_CUSTOMER_FINISHED: 'notify_customer_order_finished',
    ORDER_FINISHED: 'notify_user_order_finished',
    WAREHOUSE_DAILY_STATUS: 'notify_warehouse_daily_status',
    ORDER_PUBLISHED_FOR_QUOTE_REPORT: 'notify_carrier_order_published_report',
    ORDER_MISSING_BOOKING_PICKUP: 'notify_carrier_order_missing_booking_pickup',
    ORDER_MISSING_BOOKING_DELIVERY: 'notify_carrier_order_missing_booking_delivery',
    ORDER_BOOKING_PICKUP_NOT_CHANGED: 'notify_carrier_order_booking_pickup_not_changed',
    ORDER_BOOKING_DELIVERY_NOT_CHANGED: 'notify_carrier_order_booking_delivery_not_changed',
    ORDER_RESTORED: 'notify_carrier_order_restored',
    LOCATION_UPDATED_WAREHOUSE: 'notify_location_updated_warehouse',
    ORDER_QUOTE_REQUEST_CHANGE: 'notify_customer_order_quote_request_change',
    ORDER_UPDATED: 'notify_carrier_order_updated',
    ORDER_UPDATED_1: 'notify_customer_order_expired',
    ORDER_UPDATED_MANUAL: 'notify_customer_order_expired_manual',
    ORDER_DELETED: 'notify_carrier_order_removed',
    ORDER_CANCELED: 'notify_carrier_order_canceled',
    ORDER_CARRIER_APPROVED: 'notify_carrier_order_quote_lose',
    ORDER_CARRIER_APPROVED_NOTIFY_CUSTOMER: 'notify_customer_order_quote_approved',
    ORDER_CARRIER_ORDER_QUOTE_APPROVED: 'notify_carrier_order_quote_approved',
    ORDER_CARRIER_BOOKING_PASS: 'notify_carrier_order_booking_pass',
    ORDER_SENDER_CONSIGNER_ORDER_QUOTE_APPROVED: 'notify_third_party_order_quote_approved',
    ORDER_PREPARING_STATUS_UPDATED: 'notify_consignee_order_preparing_status',
    ORDER_QUOTE_CHANGES: 'order_quote_changes',
    ORDER_PAID_CHANGES: 'order_paid_changes',
    ORDER_PAID_RELEASE: 'notify_carrier_order_payment_release',
    ORDER_PAID_ACCEPT: 'notify_customer_order_payment_accept',
    ORDER_PAID_REJECT: 'notify_customer_order_payment_reject',
    ORDER_GET_PAYMENT: 'carrier_request_loan',
    CHAT_UNREAD_MESSAGES: 'notify_chat_unread_messages',
    CHAT_UNREAD_MESSAGES_LAST_HOUR: 'notify_chat_unread_messages_last_hour',
    INVOICE_CREATED: 'notify_order_invoice_created',
    INVOICE_PAYED: 'notify_order_invoice_payed',
    INVOICE_REQUEST: 'notify_order_invoice_request',
    LINK_TO_BOOK: 'booking_access_token',
    LINK_TO_VIEW_ORDER: 'view_access_token',
    COMPANY_DOCUMENT_EXPIRED: 'notify_carrier_company_document_expired',
    SOFT_INVITATION: 'notify_user_soft_invitation',
    COMPANY_REQUEST_TERM: 'notify_admin_company_term_request',
    COMPANY_REQUEST_TERM_OWNER: 'notify_owner_company_term_request',
    COMPANY_REQUEST_TERM_APPROVED: 'notify_admin_company_term_request_approved',
    COMPANY_REQUEST_TERM_DECLINED: 'notify_admin_company_term_request_declined',
    NOTIFY_ADMIN_ORDER_BOOKED_NOT_ARRIVED: 'notify_admin_order_booked_not_arrived',
    NOTIFY_CARRIER_NEED_TO_DELIVERY: 'notify_carrier_need_to_delivery',
    NOTIFY_CARRIER_NEED_TO_PICKUP: 'notify_carrier_need_to_pickup',
    NOTIFY_GENERAL: 'notify_general',
    ISSUE_CREATE: 'notify_issue_created',
    ISSUE_UPDATE: 'notify_issue_updated',
    ISSUE_DELETED: 'notify_issue_deleted',
    FAST_PAYMENT_CREATE: 'notify_fast_payment_created',
    USER_FIRST_VISIT: 'notify_user_first_visit',
    CREATE_CHAT_MESSAGE: 'create_chat_message',
    UPDATE_CACHE: 'update_cache',
    IMPORT_LOCATIONS: 'import_locations',
    IMPORT_UPLOAD: 'import_upload',
    ACCEPT_ORDER_OFFER: 'accept_order_offer',
    BACK_CHECK_MODEL_CRUD: 'BACK_CHECK_MODEL_CRUD',
    BACK_CHECK_ORDER_OFFERS: 'BACK_CHECK_ORDER_OFFERS',
    BACK_CHECK_USER_SETTINGS_ON_SHARING: 'BACK_CHECK_USER_SETTINGS_ON_SHARING',
    BACK_CHECK_ORDER_TRANSPORTATION: 'BACK_CHECK_ORDER_TRANSPORTATION',
    BACK_CHECK_ORDER_TRANSPORTATION_ROUTE: 'BACK_CHECK_ORDER_TRANSPORTATION_ROUTE',
    BACK_CHECK_ORDER_TRANSPORTATION_ROUTE_1: 'BACK_CHECK_ORDER_TRANSPORTATION_ROUTE_1',
    BACK_CHECK_ORDER_TRANSPORTATION_ROUTE_2: 'BACK_CHECK_ORDER_TRANSPORTATION_ROUTE_2',
    BACK_CHECK_ORDER_TRANSPORTATION_CARGO: 'BACK_CHECK_ORDER_TRANSPORTATION_CARGO',
    BACK_CHECK_ALL_ORDERS_WAREHOUSES: 'BACK_CHECK_ALL_ORDERS_WAREHOUSES',
    BACK_CHECK_ALL_ORDERS_WAREHOUSES_ONLY: 'BACK_CHECK_ALL_ORDERS_WAREHOUSES_ONLY',
    BACK_ON_UPDATE_ORDER_LOCATION: 'BACK_ON_UPDATE_ORDER_LOCATION',
    BACK_CHECK_ORDER_LOAN: 'BACK_CHECK_ORDER_LOAN',
    BACK_UPDATE_CHECK_TRANSPORT: 'BACK_UPDATE_CHECK_TRANSPORT',
    BACK_UPDATE_BOOKING_NOTIFICATION: 'BACK_UPDATE_BOOKING_NOTIFICATION',
    BACK_UPDATE_CHECK_ORDER_SYNC_TIMACOM: 'BACK_UPDATE_CHECK_ORDER_SYNC_TIMACOM',
    BACK_UPDATE_REMOVE_ORDER_SYNC_TIMACOM: 'BACK_UPDATE_REMOVE_ORDER_SYNC_TIMACOM',
    BACK_UPDATE_CHECK_ORDER_SYNC: 'BACK_UPDATE_CHECK_ORDER_SYNC',
    BACK_UPDATE_CHECK_LAST_ORDER_LOG: 'BACK_UPDATE_CHECK_LAST_ORDER_LOG',
    BACK_UPDATE_LOCATION_EDIT: 'BACK_UPDATE_LOCATION_EDIT',
    BACK_UPDATE_CHECK_LAST_ORDER_LOG_updateOrderAfterUpdateRelation: 'BACK_UPDATE_CHECK_LAST_ORDER_LOG_updateOrderAfterUpdateRelation',
    BACK_UPDATE_SET_OFFER_LAST_INDEX: 'BACK_UPDATE_SET_OFFER_LAST_INDEX',
    BACK_UPDATE_ON_APPROVE_OFFER: 'BACK_UPDATE_ON_APPROVE_OFFER',
    BACK_UPDATE_ON_APPROVE_OFFER_1: 'BACK_UPDATE_ON_APPROVE_OFFER_1',
    BACK_UPDATE_CHECK_LAST_ORDER_OFFER_LOG: 'BACK_UPDATE_CHECK_LAST_ORDER_OFFER_LOG',
    BACK_EXPORT_DATA: 'BACK_EXPORT_DATA',
    SOCKET_EVENT: 'socket_event',
    EXPORT_ORDERS: 'export_orders',
    PUSH_NOTIFICATIONS: 'push_notifications',
    SOFT_REFRESH_SHIPPING_DOCUMENT: 'soft_refresh_shipping_document',
    DOCUMENT_MISSING: 'notify_document_missing',
    USER_ACCOUNT_LINK_WAREHOUSE_BOOKING: 'user_account_link_warehouse_booking',
    API_ORDER_TO_PUBLISH: 'fake_order_to_publish',
    API_SESSIONS_TO_FRESH: 'SESSIONS_TO_FRESH',
    API_SOCKET_NOTIFICATION_EVENT_TO_EMIT: 'SOCKET_NOTIFICATION_EVENT_TO_EMIT',
    API_SOCKET_CHAT_EVENT_TO_EMIT: 'SOCKET_CHAT_EVENT_TO_EMIT',
    API_SOCKET_CHAT_CHECK_CHAT: 'API_SOCKET_CHAT_CHECK_CHAT',
    API_SOCKET_CHAT_CHECK_USER_UNREAD_MESSAGE: 'API_SOCKET_CHAT_CHECK_USER_UNREAD_MESSAGE',
};
