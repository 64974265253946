var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OrderStatus } from './models/Order_additional';
import { BaseUserAppTYpe } from './stores/common/types';
export var checkIfOrderDetailsIsHide = function (_a, order) {
    var _b, _c, _d, _e;
    var _f = _a.accountStore, currentUser = _f.currentUser, currentCompany = _f.currentCompany;
    try {
        var isOrderSenderConsignee = (_b = order.transportations) === null || _b === void 0 ? void 0 : _b.map(function (transportation) { return transportation.routes; }).reduce(function (a, b) { return __spread(a, b); }, []).find(function (route) { return route.email.includes(currentUser.email); });
        var curComp = (_c = currentUser.company) === null || _c === void 0 ? void 0 : _c._id.toString();
        var isOwn = curComp === ((_e = (_d = order.createdBy) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e._id.toString());
        var isCustomer = currentUser.appType[0] === BaseUserAppTYpe.CUSTOMER;
        var openOrders = [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status);
        var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
        if (isAdmin)
            return false;
        var hide = [BaseUserAppTYpe.WAREHOUSE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
            return currentUser.appType.includes(t);
        });
        if (!hide && order && [BaseUserAppTYpe.CUSTOMER].find(function (t) { return currentUser.appType.includes(t); })) {
            hide =
                !(((order.company && order.company._id) || order.company) === currentCompany._id) ||
                    (!isOwn && openOrders && isOrderSenderConsignee && isCustomer);
        }
        else if (!hide && order && [BaseUserAppTYpe.FORWARDER].find(function (t) { return currentUser.appType.includes(t); })) {
            return false;
            // if (order?.latestQuote?.createdBy.company._id === currentCompany._id  ) return false;
            // hide = !order?.forwarders?.find((e) => (e.company._id || e.company) === currentCompany._id);
        }
        return hide;
    }
    catch (e) {
        return false;
    }
};
export var getUsersFromAccess = function (allAvailableUsers, upload) {
    return allAvailableUsers.filter(function (e) {
        return e.user.appType.find(function (t) { return upload === null || upload === void 0 ? void 0 : upload.access.includes(t); }) ||
            ([BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.SENDER].find(function (t) { return upload === null || upload === void 0 ? void 0 : upload.access.includes(t); }) &&
                [BaseUserAppTYpe.CONSIGNEE, BaseUserAppTYpe.SENDER].find(function (t) { return e.user.appType.includes(t); }));
    });
};
