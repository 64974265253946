var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_CARGO_PACKAGE_BASE = "\n    " + BASE_QUERY + "\n      name\n      description \n      origin\n      approvedAt\n      createdAt\n      updatedAt\n      isGlobal\n";
export var UpdateOrderCargoPackage = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateOrderCargoPackage($input: OrderCargoPackageInputUpdate!) {\n        updateOrderCargoPackage(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCargoPackage($input: OrderCargoPackageInputUpdate!) {\n        updateOrderCargoPackage(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PACKAGE_BASE);
export var CreateOrderCargoPackage = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderCargoPackage($input: OrderCargoPackageInput!) {\n        createOrderCargoPackage(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCargoPackage($input: OrderCargoPackageInput!) {\n        createOrderCargoPackage(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PACKAGE_BASE);
export var DeleteOrderCargoPackage = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation deleteOrderCargoPackage($_id: MongoId!) {\n        deleteOrderCargoPackage(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderCargoPackage($_id: MongoId!) {\n        deleteOrderCargoPackage(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var FetchOrderCargoPackage = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query orderCargoPackages($filter:MainFilter) {\n        orderCargoPackages(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderCargoPackages($filter:MainFilter) {\n        orderCargoPackages(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_CARGO_PACKAGE_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
