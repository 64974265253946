var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../../components';
import { theme } from '../../../theme';
import { HelpIconComponent } from '../../../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import BaseChip from '../../../components/BaseChip';
export var UPLOAD_STATUS;
(function (UPLOAD_STATUS) {
    UPLOAD_STATUS[UPLOAD_STATUS["PENDING"] = 1] = "PENDING";
    UPLOAD_STATUS[UPLOAD_STATUS["DELIVERED"] = 2] = "DELIVERED";
    UPLOAD_STATUS[UPLOAD_STATUS["NOT_DELIVERED"] = 3] = "NOT_DELIVERED";
    UPLOAD_STATUS[UPLOAD_STATUS["NOT_ALL_DELIVERED"] = 4] = "NOT_ALL_DELIVERED";
})(UPLOAD_STATUS || (UPLOAD_STATUS = {}));
export var ContactStatus;
(function (ContactStatus) {
    ContactStatus[ContactStatus["ACTIVE"] = 1] = "ACTIVE";
    ContactStatus[ContactStatus["INACTIVE"] = 2] = "INACTIVE";
    ContactStatus[ContactStatus["PENDING"] = 3] = "PENDING";
})(ContactStatus || (ContactStatus = {}));
export var RecordStatusType = {
    UPLOAD: 1,
    CONTACT: 2,
};
export var ItemStatus = function (_a) {
    var record = _a.record, style = _a.style, type = _a.type;
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var ACTIVE = type === RecordStatusType.UPLOAD ? UPLOAD_STATUS.DELIVERED : ContactStatus.ACTIVE;
    var IN_ACTIVE = type === RecordStatusType.UPLOAD ? UPLOAD_STATUS.NOT_DELIVERED : ContactStatus.INACTIVE;
    return (React.createElement(BaseChip, { label: React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(TextOverflow, { style: { color: theme.palette.common.white, maxWidth: 'calc(100% - 20px)' } }, record.status === ACTIVE
                ? langOfferForm.t('offer_un_loaded')
                : record.status === IN_ACTIVE
                    ? langOrderList.t('in_progress_has_not_un_loaded')
                    : langOfferForm.t('offer_pending')),
            React.createElement(HelpIconComponent, { isBigTooltip: true, style: { maxWidth: 'calc(100vw - 50px)!important' }, title: record.status === ACTIVE
                    ? langOfferForm.t('offer_un_loaded_help')
                    : record.status === IN_ACTIVE
                        ? langOfferForm.t('offer_no_un_loaded_help')
                        : langOfferForm.t('offer_pending_help') })), style: __assign(__assign({}, style), { backgroundColor: record.status === ACTIVE
                ? theme.palette.success.light
                : record.status === IN_ACTIVE
                    ? theme.palette.error.light
                    : theme.palette.warning.light, color: theme.palette.common.white }) }));
};
