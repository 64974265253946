import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useTableStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                // maxHeight: `calc(100vh - 280px)`,
                maxWidth: "calc(100vw - 230px)",
                transform: 'rotateX(180deg)'
            },
            _a[theme.breakpoints.down('sm')] = {
                maxWidth: "calc(100vw - 20px)",
                maxHeight: "calc(100vh - 80px)",
            },
            _a['& table'] = {
                transform: 'rotateX(180deg)',
                position: 'relative',
                borderCollapse: 'collapse',
                width: '100%',
                tableLayout: 'fixed',
                '& th': {
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    backgroundColor: theme.palette.common.white,
                },
                '& .fix-cell': {
                    position: 'sticky!important',
                    left: 0,
                    width: 50,
                    backgroundColor: theme.palette.common.white,
                    zIndex: 99999,
                },
            },
            _a),
    });
});
