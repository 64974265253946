var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { priceValue } from '../PriceComponent';
import { OrderQuoteStatus, OrderRouteType } from '../../models';
import PreloadingComponent from '../PreloadingComponent';
import OrderContraQuoteAccept from '../Order/List/OrderQuoteItem/Quotes/OrderContraQuoteAccept';
import { dateViewFormat } from '../DateView';
import { BaseUserAppTYpe } from '../../stores';
import { checkIfOrderDetailsIsHide, SYSTEM_EMAIL } from '../../utils1';
import { KEYS } from '../../i18n';
import { useTranslation } from 'react-i18next';
import SectionHistoryParsedActionsItem from './SectionHistoryParsedActionsItem';
import { SectionHistoryAction } from './types';
import TermStatus from '../../pages/CompanyPaymentTermRequests/TermStatus';
import { TextOverflow } from '../TextOverflow';
import { theme } from '../../theme';
import { toDateOnly } from '../../utils';
export default function SectionHistoryParsedActions(propsAll) {
    var _a, _b, _c;
    var accountStore = propsAll.store.accountStore, record = propsAll.record, order = propsAll.order, type = propsAll.type, list = propsAll.list, isAdmin = propsAll.isAdmin, props = __rest(propsAll, ["store", "record", "order", "type", "list", "isAdmin"]);
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var langInfo = useTranslation(KEYS.INFO);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langFormLocations = useTranslation(KEYS.FORM_LOCATIONS);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var isForwarderOffer = ((_a = accountStore.currentUser.forwarders) === null || _a === void 0 ? void 0 : _a.length) > 0;
    var isCustomer = accountStore.currentUser.appType[0] === BaseUserAppTYpe.CUSTOMER;
    var isForwarder = accountStore.currentUser.appType[0] === BaseUserAppTYpe.FORWARDER;
    var is_carrier = accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isOWNCompany = accountStore.currentCompany._id === ((_b = record.createdBy) === null || _b === void 0 ? void 0 : _b.company._id);
    var is_forwarder = accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isForwarderCarrier = is_forwarder && isOWNCompany;
    var isHideOfferdetails = checkIfOrderDetailsIsHide(propsAll.store, order);
    var ownForwarderOrder = isForwarder && ((_c = order.forwarders) === null || _c === void 0 ? void 0 : _c.find(function (e) { var _a; return ((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) === accountStore.currentCompany._id; }));
    var used = false;
    var logsDetails = list.map(function (log, index) {
        var changes = {};
        try {
            changes = JSON.parse(log.changes);
            if (changes.isTrading) {
                if (log.action === 'CREATE') {
                    return [];
                }
                if (isCustomer) {
                }
                else {
                    if (log.action === 'OFFER_ASK_NEW_PRICE') {
                        // return [];
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
        var actions = [];
        var offerUpdates = function () {
            var _a, _b, _c;
            switch (log.action) {
                case 'OFFER_ASK_NEW_PRICE': {
                    actions.push.apply(actions, __spread([
                        {
                            name: 'isTrading',
                            value: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end' },
                                React.createElement(Box, { marginRight: theme.spacing(0.4) }, (typeof changes.amount !== 'undefined' &&
                                    priceValue(__assign(__assign({}, changes), (isForwarderOffer &&
                                        isCustomer &&
                                        typeof changes.forwarderAmount !== 'undefined' && {
                                        amount: changes.forwarderAmount,
                                    })))) ||
                                    ''),
                                !used &&
                                    (is_carrier || isForwarderCarrier) &&
                                    record.contraOffer &&
                                    [OrderQuoteStatus.Requested, OrderQuoteStatus.Accepted].includes(record.status) &&
                                    !record.rejectedAt &&
                                    !record.deletedAt &&
                                    record.status === OrderQuoteStatus.Requested && (React.createElement(PreloadingComponent, null,
                                    React.createElement(OrderContraQuoteAccept, __assign({}, props, { order: order, onChange: props.onChange, quote: record })))))),
                            label: langOfferForm.t('offer_form_edit_btn_new_price'),
                        },
                        ownForwarderOrder && {
                            name: 'forwarderAmount',
                            value: priceValue(__assign(__assign({}, changes), { amount: changes.forwarderAmount })),
                            label: langForm.t('form_input_forwarder_price_help'),
                        },
                        {
                            name: 'comment',
                            value: changes.comment || '',
                            label: langForm.t('form_btn_to_comment'),
                        },
                    ].filter(function (e) { return e && e.value; })));
                    used = true;
                    break;
                }
                case 'UPDATE':
                case 'FAST_PAYMENT':
                    if (!(ownForwarderOrder || is_carrier || isCustomer))
                        break;
                case 'CREATE': {
                    try {
                        actions.push.apply(actions, __spread(__spread([
                            typeof changes.amount !== 'undefined' && {
                                value: priceValue(__assign(__assign({}, changes), (isForwarderOffer &&
                                    isCustomer && {
                                    amount: typeof changes.forwarderAmount !== 'undefined' ? changes.forwarderAmount : changes.amount,
                                }))) || '',
                                name: 'price',
                                label: langApp.t('price'),
                            },
                            ownForwarderOrder && {
                                value: priceValue(__assign(__assign({}, changes), { amount: changes.forwarderAmount })),
                                name: 'forwarderAmount',
                                label: langForm.t('form_input_forwarder_price_help'),
                            }
                        ], ((changes.tempTransportations && [
                            {
                                value: dateViewFormat((_a = changes.tempTransportations[0]) === null || _a === void 0 ? void 0 : _a.startDate),
                                label: langOrderList.t('order_list_filter_sort_loading_at'),
                            },
                            {
                                value: dateViewFormat((_b = changes.tempTransportations[0]) === null || _b === void 0 ? void 0 : _b.endDate),
                                label: langOrderForm.t('create_order_route_origin_to_date'),
                            },
                        ]) ||
                            []), [
                            changes.comment && {
                                value: changes.comment || '',
                                label: langForm.t('form_btn_to_comment'),
                            }
                        ], ((log.action === 'FAST_PAYMENT' && [
                            {
                                value: langApp.t('app_fast_payment'),
                                label: langApp.t('app_action'),
                            },
                        ]) ||
                            [])).filter(function (e) { return e; })));
                    }
                    catch (e) { }
                    switch (changes.status) {
                        case OrderQuoteStatus.Requested: {
                            actions.push.apply(actions, __spread([
                                {
                                    value: langMenu.t('menu_sidebar_open_orders_offer_requested'),
                                    label: langForm.t('form_status'),
                                },
                            ]));
                            break;
                        }
                        case OrderQuoteStatus.Accepted: {
                            actions.push.apply(actions, __spread([
                                {
                                    value: langMenu.t('menu_sidebar_open_orders_offer_accepted'),
                                    label: langForm.t('form_status'),
                                },
                            ]));
                            break;
                        }
                        case OrderQuoteStatus.Approved: {
                            actions.push.apply(actions, __spread([
                                {
                                    value: langSettings.t('settings_notify_carrier_order_quote_approved'),
                                    label: langForm.t('form_status'),
                                },
                            ]));
                            break;
                        }
                        case OrderQuoteStatus.Cancelled: {
                            actions.push.apply(actions, __spread([
                                {
                                    value: (is_carrier || changes.deniedContraOfferAt) && ((_c = log.createdBy) === null || _c === void 0 ? void 0 : _c.email) === SYSTEM_EMAIL
                                        ? langInfo.t('logs_offer_auto_expired_status')
                                        : changes.deniedContraOfferAt
                                            ? langOfferForm.t('offer_form_denied_trade_request')
                                            : langSettings.t('settings_notify_carrier_order_quote_rejected'),
                                    label: langForm.t('form_status'),
                                    breakValueLine: true,
                                },
                            ]));
                            break;
                        }
                        case OrderQuoteStatus.Finished: {
                            actions.push.apply(actions, __spread([
                                {
                                    value: langOfferForm.t('offer_finished'),
                                    label: langForm.t('form_status'),
                                },
                            ]));
                            break;
                        }
                    }
                    break;
                }
                default: {
                }
            }
        };
        var historyUpdates = function () {
            var _a, _b, _c, _d;
            var collectionName = log.collectionName === 'orders' ? langApp.t('main_orders') : langApp.t(log.collectionName);
            var keys = [
                {
                    name: 'deletedAt',
                    key: langToast.t('toast_items_deleted').replace(/_X_/, ''),
                    title: collectionName,
                },
            ];
            switch (log.collectionName) {
                case 'order_quote_transports': {
                    keys = [
                        { name: 'container', title: langOfferForm.t('form_input_name_container_number') },
                        { name: 'track', title: langOfferForm.t('offer_form_truck_plate_number') },
                        { name: 'trailer', title: langOfferForm.t('offer_form_trailer_plate_number') },
                        { name: 'contactName', title: langOfferForm.t('offer_form_transport_driver') },
                        { name: 'phone', title: langOfferForm.t('offer_form_driver') },
                    ];
                    break;
                }
                case 'order_transportations': {
                    keys = [
                        { name: 'startDate', title: langOrderList.t('order_list_filter_sort_loading_at') },
                        { name: 'endDate', title: langOrderForm.t('create_order_route_origin_to_date') },
                        {
                            name: 'startTime',
                            title: langOfferForm.t('offer_loading_time'),
                            key: function (e) { return e.startTime[0] || ''; },
                        },
                        {
                            name: 'endTime',
                            title: langOfferForm.t('offer_un_loading_time'),
                            key: function (e) { return e.endTime[0] || ''; },
                        },
                        {
                            name: 'inTransitAt',
                            title: langOrderList.t('in_progress_has_loaded'),
                            key: function (e) { return dateViewFormat(e.inTransitAt, 1); },
                        },
                        {
                            name: 'unloadedAt',
                            title: langOfferForm.t('offer_un_loaded'),
                            key: function (e) { return dateViewFormat(e.unloadedAt, 1); },
                        },
                        { name: 'no_inTransitAt', title: langOrderList.t('in_progress_has_not_loaded') },
                        { name: 'no_unloadedAt', title: langOrderList.t('in_progress_has_not_un_loaded') },
                        {
                            name: 'arrivedToDelivery',
                            key: function (e) { return langApp.t('arrived'); },
                            title: [langOfferForm.t('offer_to_label')].join(', '),
                        },
                        {
                            name: 'arrivedToPickup',
                            key: function (e) { return langApp.t('arrived'); },
                            title: [langOfferForm.t('offer_from_label')].join(', '),
                        },
                    ];
                    break;
                }
                case 'order_cargoes': {
                    keys = [
                        { name: 'volume', title: langOrderForm.t('create_order_cargo_volume') },
                        { name: 'amount', title: langForm.t('form_input_help_order_cargo_amount') },
                        { name: 'weight', title: langOrderForm.t('create_order_cargo_weight') },
                        { name: 'weightMeasure', title: langForm.t('form_input_name_dimension') },
                        { name: 'height', title: langForm.t('form_input_help_order_cargo_height') },
                        { name: 'taintedCode', title: langOrderForm.t('create_order_cargo_tainted_code') },
                        { name: 'cargoId', title: langOrderForm.t('create_order_cargo_no') },
                    ];
                    break;
                }
                case 'order_routes': {
                    var label = changes.type
                        ? langOfferForm.t(changes.type === OrderRouteType.Preferred ? 'offer_from_label' : 'offer_to_label')
                        : '';
                    keys = [
                        { name: 'email', title: langFormLocations.t('form_location_pickup_email') },
                        {
                            name: 'startDate',
                            title: (label + " " + langOrderForm
                                .t('create_order_route_location_working_hours_from')
                                .toLowerCase()).trim(),
                        },
                        {
                            name: 'endDate',
                            title: (label + " " + langOrderForm.t('create_order_route_location_working_hours_to').toLowerCase()).trim(),
                        },
                        {
                            name: 'type',
                            title: langOrderForm.t('create_order_route_list_label'),
                            key: function (change) {
                                return langOrderForm.t(change.type === OrderRouteType.Delivery
                                    ? 'create_order_route_origin_to'
                                    : 'create_order_route_origin_from');
                            },
                        },
                    ];
                    break;
                }
                case 'companies':
                case 'order_payments': {
                    keys = [
                        { name: 'status', title: langForm.t('form_status') },
                        { name: 'comment', title: langForm.t('form_btn_to_comment') },
                    ];
                    break;
                }
                case 'company_term_budgets': {
                    keys = [
                        { name: 'status', title: langForm.t('form_status'), key: function (e) { return React.createElement(TermStatus, { record: e, v1: true }); } },
                        {
                            name: 'comment',
                            title: langForm.t('form_btn_to_comment'),
                            key: function (e) { return e.comment && React.createElement(TextOverflow, null, e.comment); },
                        },
                        { name: 'term', title: langForm.t('form_invoice_term') },
                        // { name: 'currency', title: langForm.t('form_invoice_currency') },
                        { name: 'budget', title: langApp.t('factoring_limit'), key: function (e) { return priceValue(__assign(__assign({}, e), { price: e.budget })); } },
                        { name: 'balance', title: langForm.t('form_balance'), key: function (e) { return priceValue(__assign(__assign({}, e), { price: e.balance })); } },
                        { name: 'parent', title: langApp.t('main_order') },
                    ];
                    break;
                }
                case 'orders': {
                    keys = [
                        {
                            name: 'publishedAt',
                            title: langForm.t('form_input_state'),
                            key: langOrderForm.t('create_order_status_published'),
                        },
                        {
                            name: 'cancelledAt',
                            key: langSettings.t('settings_notify_carrier_order_canceled'),
                            title: langForm.t('form_input_state'),
                        },
                        { name: 'loadingType', title: langOrderForm.t('create_order_transport_loading_type') },
                        { name: 'status', title: langForm.t('form_status') },
                        { name: 'price', title: langForm.t('form_input_name_price') },
                        { name: 'orderId', title: langOrderForm.t('create_order_general_id') },
                        { name: 'deliveryTerms', title: langOrderForm.t('create_order_delivery_terms') },
                        { name: 'cargoType', title: langOrderForm.t('create_order_transport_loading_type') },
                        { name: 'cancelComment', title: langForm.t('form_btn_to_comment') },
                        { name: 'comment', title: langForm.t('form_btn_to_comment') },
                        { name: 'text', title: langForm.t('form_btn_to_comment') },
                        {
                            name: 'sorting_hasRequestChangesAt',
                            title: langForm.t('form_input_actions'),
                            key: langForm.t('form_btn_order_request_change'),
                        },
                        {
                            name: 'resetCount',
                            key: langToast.t('toast_order_restored'),
                            title: langForm.t('form_input_actions'),
                        },
                    ];
                    break;
                }
                case 'order_quotes': {
                    offerUpdates();
                    break;
                }
                case 'locations': {
                    keys = [
                        { name: 'name', title: langForm.t('form_input_name') },
                        { name: 'companyName', title: langForm.t('form_input_name_company') },
                        { name: 'companyVAT', title: langForm.t('form_input_name_vat_code') },
                    ];
                    break;
                }
                case 'companies': {
                    keys = [
                        { name: 'name', title: langForm.t('form_input_name') },
                        { name: 'vatCode', title: langForm.t('form_input_name_vat_code') },
                        {
                            name: 'comment',
                            title: langForm.t('form_btn_to_comment'),
                            key: function (e) { return e.comment && React.createElement(TextOverflow, null, e.comment); },
                        },
                    ];
                    break;
                }
            }
            if (keys.length) {
                var list_1;
                if (props.v3 && ownForwarderOrder) {
                    list_1 = __spread(Object.keys(changes).map(function (title) { return ({
                        title: title,
                        key: typeof changes[title] === 'object'
                            ? JSON.stringify(changes[title])
                            : (title === null || title === void 0 ? void 0 : title.match(/At$/)) ? toDateOnly(changes[title])
                                : changes[title],
                    }); }), [
                        ((_a = changes.result) === null || _a === void 0 ? void 0 : _a.mapUrl) && {
                            title: langApp.t('app_link_to_follow'),
                            key: (React.createElement(TextOverflow, { variant: 'subtitle1' },
                                React.createElement("a", { href: (_b = changes.result) === null || _b === void 0 ? void 0 : _b.mapUrl, target: '_blank' }, langApp.t('traking_order')))),
                        },
                    ]).filter(function (e) { return e; });
                }
                else {
                    if (isHideOfferdetails) {
                        keys = keys.filter(function (e) { return !['price', 'forwarderAmount', 'amount', 'isTrading'].includes(e.name); });
                    }
                    switch (log.collectionName) {
                        case 'company_term_budgets': {
                            list_1 = keys.filter(function (e) { return typeof changes[e.name] !== 'undefined' && changes[e.name] !== null; });
                            break;
                        }
                        default: {
                            list_1 = keys.filter(function (e) { return typeof changes[e.name] !== 'undefined'; });
                        }
                    }
                    switch (log.collectionName) {
                        case 'company_term_budgets': {
                            list_1 = list_1.filter(function (e) { return (e.name === 'comment' && changes[e.name]) || e.name !== 'comment'; });
                            break;
                        }
                    }
                }
                actions.push.apply(actions, __spread(list_1.map(function (e) { return ({
                    value: typeof e.key === 'function'
                        ? e.key(changes)
                        : e.key || changes[e.name] || (e.name === 'price' ? '-' : ''),
                    label: e.title,
                }); })));
            }
            if ('order_quotes' === log.collectionName &&
                actions.length &&
                type === SectionHistoryAction.ORDER_HISTORY &&
                !(is_carrier || isForwarderCarrier) && ((_c = log.object) === null || _c === void 0 ? void 0 : _c.createdBy)) {
                var existUpdates_1 = actions.map(function (e) { return e.label; });
                actions.push.apply(actions, __spread(((((_d = log.object.tempTransportations) === null || _d === void 0 ? void 0 : _d.length) &&
                    [
                        {
                            label: langOrderList.t('order_list_filter_sort_loading_at'),
                            value: log.object.tempTransportations[0].startDate,
                        },
                        {
                            label: langOrderForm.t('create_order_route_origin_to_date'),
                            value: log.object.tempTransportations[log.object.tempTransportations.length - 1].endDate,
                        },
                    ].filter(function (e) { return !existUpdates_1.includes(e.label); })) ||
                    [])));
            }
        };
        switch (type) {
            // case SectionHistoryAction.ORDER_HISTORY: {
            //   switch (log.action) {
            //     case 'UPDATE':
            //     case 'CREATE': {
            //       historyUpdates();
            //     }
            //   }
            //   break;
            // }
            default: {
                historyUpdates();
            }
        }
        if (isHideOfferdetails) {
            actions = actions.filter(function (e) { return !['price', 'forwarderAmount', 'amount', 'isTrading'].includes(e.name); });
        }
        if (!actions.length && isAdmin) {
            var labels_1 = {
                statusV1: 'Company status',
                statusPlannedAt: new Date(),
            };
            actions.push.apply(actions, __spread(Object.keys(changes).map(function (e) { return ({
                value: typeof changes[e] === 'object'
                    ? JSON.stringify(changes[e])
                    : (e === null || e === void 0 ? void 0 : e.toString().match(/At$/)) ? toDateOnly(changes[e])
                        : JSON.stringify(changes[e]),
                label: labels_1[e] || e,
            }); })));
        }
        if (actions.length) {
            actions.splice(0, 0, {
                value: dateViewFormat(log.createdAt, 1),
                label: langApp.t('app_date'),
            });
        }
        else {
            return null;
        }
        return React.createElement(SectionHistoryParsedActionsItem, __assign({ key: log._id }, { log: log, index: index, actions: actions, total: list.length }));
    });
    if (!logsDetails.length)
        return props.noData;
    return React.createElement(React.Fragment, null, logsDetails);
}
