var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { inject, observer, useLocalStore } from 'mobx-react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { i18n, KEYS } from '../../i18n';
import { FetchAffiliateUsers, InviteTeamMembers } from '../../graphql';
import { ACTION_CHANGE, BASIC_DIALOG_ACTIONS, BasicDialog, FormikAutocompleteAsync, FormikSelect, FormikTextField, MainButton, onPreventEvent, TextOverflow, } from '../../components';
import { onlySpacesRegExp } from '../../common';
import { theme } from '../../theme';
import { optionLabelV1 } from '../../components/Order/List/utils';
import BaseChip from '../../components/BaseChip';
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import { googleTagManager } from '../../utils1';
import { BaseUserAppTYpe } from '../../stores';
function AddUser(_a) {
    var _this = this;
    var config = _a.config, props = __rest(_a, ["config"]);
    var formRef = React.useRef();
    var _b = __read(React.useState({
        emails: [],
        comment: '',
        appType: BaseUserAppTYpe.CUSTOMER,
    }), 2), values = _b[0], setValues = _b[1];
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        sent: false,
        updatedAt: 1,
        onSubmit: function (_input) {
            return __awaiter(this, void 0, void 0, function () {
                var res, variables, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.sent = false;
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            res = void 0;
                            variables = {
                                input: _input.emails.map(function (_a) {
                                    var email = _a.email;
                                    return ({
                                        user: __assign(__assign(__assign({}, props.userValue), (props.isAffiliate && { appType: [_input.appType] })), { email: email }),
                                        company: props.store.accountStore.currentCompany._id,
                                        comment: _input.comment,
                                    });
                                }),
                            };
                            googleTagManager('event', "izi-" + (props.prefix || '') + "add-user", variables);
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: InviteTeamMembers,
                                    variables: variables,
                                })];
                        case 2:
                            _a = (_b.sent());
                            _b.label = 3;
                        case 3:
                            res = (_a).data.inviteMembers;
                            localStore.sent = true;
                            toast.success(langToast.t('toast_items_sent').replace(/_X_/, langForm.t('form_input_name_email')));
                            if (props.onChange)
                                props.onChange(res, ACTION_CHANGE.UPDATED);
                            return [2 /*return*/, res];
                        case 4:
                            e_1 = _b.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            setValues({
                                emails: [],
                                comment: '',
                                appType: BaseUserAppTYpe.CUSTOMER,
                            });
                            localStore.updatedAt = Date.now();
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onClose = function () {
        localStore.sent = false;
    };
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var item, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    onPreventEvent(e);
                    if (!localStore.sent) return [3 /*break*/, 1];
                    localStore.sent = false;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, formRef.current.submitForm()];
                case 2:
                    item = _a.sent();
                    if (!item)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (props.onChange)
                        props.onChange(item, ACTION_CHANGE.UPDATED);
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    e_2 = _a.sent();
                    console.log(e_2);
                    throw BASIC_DIALOG_ACTIONS.APPROVE;
                case 5: return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var validationSchema = Yup.object()
        .shape({
        emails: Yup.array()
            .typeError(langForm
            .t('form_input_validate_required_list')
            .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
            .of(Yup.object().shape({
            email: Yup.string()
                .required()
                .typeError(langForm
                .t('form_input_validate_required_list')
                .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                .email(langForm.t('form_input_validate_invalid_email')),
        }))
            .min(1, langForm
            .t('form_input_validate_field_must_have_at_least')
            .replace(/_X_/, '1')
            .replace(/_XX_/, langForm.t('form_input_name_email').toLowerCase()))
            .max(10, langForm
            .t('form_input_validate_field_must_have_no_more')
            .replace(/_X_/, '10')
            .replace(/_XX_/, langForm.t('form_input_name_email').toLowerCase())),
        comment: Yup.string()
            .typeError(langForm.t('form_input_validate_required'))
            .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')),
    })
        .typeError(langForm.t('form_input_validate_required_list').replace('_X_', langForm.t('form_input_name_emails').toLowerCase()));
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(TextOverflow, { color: "error" }, localStore.error),
        React.createElement(Formik, { key: localStore.error + "-" + localStore.updatedAt, innerRef: formRef, initialValues: values, validationSchema: validationSchema, onSubmit: localStore.onSubmit }, function (formik) {
            var onEnter = function (e, options) {
                var _a;
                var name = e.target.name;
                var exist = values.emails.map(function (e) { return e._id; }).filter(function (e) { return e; });
                var value = __spread(values.emails, [options.find(function (e) { return !exist.includes(e._id); })]).filter(function (e) { return e; });
                formik.setFieldValue(name, value);
                setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
            };
            var onFieldChangeHandler = function (e) {
                var _a;
                var options = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    options[_i - 1] = arguments[_i];
                }
                var _b = e.target, value = _b.value, name = _b.name;
                if (['emails'].includes(name)) {
                    if (options[1] === 'select-option') {
                        var target = {
                            name: name,
                            value: (value || [])
                                .map(function (e) { return (__assign(__assign({}, e), { email: e.email || e._id })); })
                                .map(function (e) {
                                var _a, _b, _c;
                                return ({
                                    _id: ((_a = e._id) === null || _a === void 0 ? void 0 : _a.trim()) || e._id,
                                    title: ((_b = e.title) === null || _b === void 0 ? void 0 : _b.trim()) || e.title,
                                    email: ((_c = e.email) === null || _c === void 0 ? void 0 : _c.trim()) || e.email,
                                });
                            }),
                        };
                        formik.setFieldValue(name, target.value);
                        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = target.value, _a)));
                    }
                }
                else {
                    formik.handleChange(e);
                }
            };
            var onBlur = function (e) {
                var options = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    options[_i - 1] = arguments[_i];
                }
                var _a = e.target, value = _a.value, name = _a.name;
                if (['emails'].includes(name)) {
                    if (typeof formik.values.emails === 'string') {
                        formik.setFieldValue(name, values.emails);
                    }
                }
            };
            var style = { marginBottom: theme.spacing(2.5) };
            return (React.createElement(Form, { id: 'btn-invite-user', name: 'btn-invite-user', style: {
                    display: 'flex',
                    width: '100%',
                } },
                React.createElement(Grid, { container: true },
                    props.isAffiliate ? (React.createElement(Grid, { item: true, xs: 12, style: style },
                        React.createElement(FormikSelect, { required: true, variant: "outlined", title: langForm.t('form_input_name_app_type'), name: "appType", value: formik.values.appType }, [
                            { value: BaseUserAppTYpe.CUSTOMER, title: 'user_app_type_customer' },
                            { value: BaseUserAppTYpe.CARRIER, title: 'user_app_type_carrier' },
                        ].map(function (_a) {
                            var value = _a.value, title = _a.title;
                            return (React.createElement(MenuItem, { id: "menu-item-select-" + value, key: value, value: value }, langApp.t(title)));
                        })))) : null,
                    React.createElement(Grid, { item: true, xs: 12, style: style },
                        React.createElement(FormikAutocompleteAsync, { v17: true, v24: true, key: JSON.stringify(values.emails), required: true, title: langForm.t('form_input_name_emails'), variant: "outlined", margin: 'dense', fullWidth: true, canAddNew: true, canAddNewV1: true, name: "emails", client: config.client, query: FetchAffiliateUsers, variables: { filter: { fields: [{ name: 'category', value: -1 }] } }, value: formik.values.emails, autocompleteProps: {
                                multiple: true,
                                defaultValue: formik.values.emails,
                                getOptionSelected: function (option, value) {
                                    return option.email === value.email;
                                },
                                getOptionLabel: function (option) {
                                    return optionLabelV1(option);
                                },
                                renderTags: function (value, getTagProps) {
                                    var re = ((Array.isArray(value) && value) || []).filter(function (e) { return e === null || e === void 0 ? void 0 : e.email; });
                                    var optionContent = function (option, index) {
                                        return (option && (React.createElement(BaseChip, __assign({ label: React.createElement(Grid, { container: true, direction: 'column' },
                                                React.createElement(TextOverflow, { variant: 'subtitle2', style: {
                                                        lineHeight: 1.1,
                                                    } }, optionLabelV1(option)),
                                                (option.company && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, [option.company.name, option.company.type].filter(function (e) { return e; }).join(', ')))) ||
                                                    (option.value && option.value.company && (React.createElement(TextOverflow, { variant: 'caption', style: { lineHeight: 1 } }, [option.value.company.name, option.value.company.type]
                                                        .filter(function (e) { return e; })
                                                        .join(', ')))) ||
                                                    null) }, getTagProps({ index: index })))));
                                    };
                                    return re.map(optionContent);
                                },
                            }, onBlur: onBlur, onChange: onFieldChangeHandler, onEnter: onEnter })),
                    React.createElement(Grid, { item: true, xs: 12, style: style },
                        React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_btn_to_comment'), variant: "outlined", name: "comment", value: formik.values.comment })))));
        })));
    var title = langForm.t('form_btn_invite');
    var dialogTitle = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
        React.createElement(TextOverflow, { variant: 'h6' }, title)));
    var btn = (React.createElement(BasicDialog, { onOk: onOk, onClose: onClose, body: dialogBody, title: dialogTitle, dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (handleClickOpen) {
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: null, label: title, handleClickOpen: handleClickOpen }));
            if (props.btnTitle)
                return (React.createElement(TextOverflow, { variant: 'subtitle1', style: {
                        textTransform: 'uppercase',
                        fontWeight: 700,
                        color: theme.palette.primary.light,
                        marginRight: theme.spacing(1),
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }, onClick: handleClickOpen, id: 'btn-order-invite' }, props.btnTitle));
            return React.createElement(MainButton, { title: title, onClick: handleClickOpen, id: 'btn-order-invite' });
        } }));
    if (props.btnTitle)
        return btn;
    return (React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center' },
        React.createElement(BaseListTitle, { v2: props.isAffiliate, title: props.isAffiliate
                ? i18n.getResource(i18n.language, KEYS.FORM, 'form_invited_companies')
                : i18n.getResource(i18n.language, KEYS.CHAT, 'form_new_chat_input_users') }),
        React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } }, btn)));
}
export default inject('store')(observer(AddUser));
