var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY } from './base';
export var COMMENT_BASE = "\n      " + BASE_QUERY + "\n      text \n";
export var FetchComments = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query comments($filter: MainFilter) {\n    comments(filter:$filter){\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n    query comments($filter: MainFilter) {\n    comments(filter:$filter){\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), COMMENT_BASE);
export var UpdateComment = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateComment($input: CommentInputUpdate!) {\n        updateComment(input:$input){\n            ", "\n    }\n  }\n"], ["\n    mutation updateComment($input: CommentInputUpdate!) {\n        updateComment(input:$input){\n            ", "\n    }\n  }\n"])), COMMENT_BASE);
export var CreateComment = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n mutation createComment($input:CommentInput!){\n     createComment(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createComment($input:CommentInput!){\n     createComment(input:$input) { \n      ", "\n    }\n  }\n"])), BASE_QUERY);
var templateObject_1, templateObject_2, templateObject_3;
