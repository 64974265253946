var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE, UPLOAD_TYPE_BASE } from './base';
export var FetchUploadTypes = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query uploadTypes($filter:MainFilter){\n      uploadTypes(filter:$filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query uploadTypes($filter:MainFilter){\n      uploadTypes(filter:$filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), UPLOAD_TYPE_BASE);
export var CreateUploadType = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n mutation createUploadType($input:UploadTypeInput!){\n     createUploadType(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation createUploadType($input:UploadTypeInput!){\n     createUploadType(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_TYPE_BASE);
export var UpdateUploadType = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n mutation updateUploadType($input:UploadTypeInputUpdate!){\n     updateUploadType(input:$input) { \n      ", "\n    }\n  }\n"], ["\n mutation updateUploadType($input:UploadTypeInputUpdate!){\n     updateUploadType(input:$input) { \n      ", "\n    }\n  }\n"])), UPLOAD_TYPE_BASE);
export var DeleteUploadType = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n mutation deleteUploadType($_id:MongoId!){\n     deleteUploadType(_id:$_id) { \n      ", "\n    }\n  }\n"], ["\n mutation deleteUploadType($_id:MongoId!){\n     deleteUploadType(_id:$_id) { \n      ", "\n    }\n  }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
