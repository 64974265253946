var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { TextOverflow } from '../../components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useSectionStyles } from '../../styles';
import { Channels } from './Channels';
import { theme } from '../../theme';
import * as reactRoute from 'react-router-dom';
import { DefaultChanel, ChatItem } from './ChatItem';
import { GlobalChannelListSocket } from './stores/ChannelList';
var Switch = reactRoute.Switch;
var Route = reactRoute.Route;
var ChatContent = function (props) {
    var langChat = useTranslation(KEYS.CHAT);
    var classes = useSectionStyles();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var containerStyle = {};
    if (isSmall) {
        containerStyle = { display: 'flex', flexDirection: 'column-reverse' };
    }
    return (React.createElement(Grid, { container: true, direction: 'column' },
        React.createElement(Grid, { container: true, justify: 'space-between', style: { marginBottom: theme.spacing(2) } },
            React.createElement(TextOverflow, { variant: 'h6', style: { fontWeight: 600 } }, langChat.t('main_title'))),
        React.createElement(Grid, { container: true, spacing: 3, style: containerStyle },
            React.createElement(Grid, { item: true, xs: 12, md: 4 },
                React.createElement(Grid, { className: classes.section, style: { padding: theme.spacing(2) } },
                    React.createElement(GlobalChannelListSocket, { client: props.config && props.config.client, id: 'chat-page' },
                        React.createElement(Channels, __assign({}, props))))),
            React.createElement(Grid, { item: true, xs: 12, md: 8 },
                React.createElement(Grid, { className: classes.section, style: { padding: 0 } },
                    React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: { maxWidth: '100%' } },
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: "/chats/:chatId", component: function (p) { return React.createElement(ChatItem, __assign({}, props, p, { config: props.config })); } }),
                            React.createElement(Route, { path: "/", component: DefaultChanel }))))))));
};
export default ChatContent;
