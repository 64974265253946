// Object.prototype.duplicateData = function (): Object {
//   try {
//     return JSON.parse(JSON.stringify(this));
//   } catch (e) {
//     return this;
//   }
// };
String.prototype.toCapitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};
export {};
