var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import PopUpHover from '../PopupHover';
import { inject } from 'mobx-react';
import Loader from '../Loader';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { theme } from '../../theme';
import { StyledBadgeV9 } from '../StyledBadge';
import clsx from 'clsx';
import SectionHistoryParsedActions from './SectionHistoryParsedActions';
import { SectionHistoryAction } from './types';
import { BaseSelect } from '../Formik/FormikSelect';
import Pagination from '@material-ui/lab/Pagination';
import { useSectionRowStyles } from './styles';
export default inject('store')(function SectionHistory(propsAll) {
    var _a;
    var _b;
    var accountStore = propsAll.store.accountStore, record = propsAll.record, order = propsAll.order, type = propsAll.type, props = __rest(propsAll, ["store", "record", "order", "type"]);
    var _c = __read(React.useState(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState({ data: [], count: 0 }), 2), data = _d[0], setData = _d[1];
    var _e = __read(React.useState(type === SectionHistoryAction.ORDER_HISTORY ? order === null || order === void 0 ? void 0 : order.lastLog : record.lastLog), 2), lastLog = _e[0], setLastLog = _e[1];
    var _f = __read(React.useState(__assign(__assign({}, props.filters), { page: 1, fields: __spread((((_b = props === null || props === void 0 ? void 0 : props.filters) === null || _b === void 0 ? void 0 : _b.fields) || []), [!props.v3 && { name: 'history_review', value: 1 }]).filter(function (e) { return e; }) })), 2), filter = _f[0], setFilter = _f[1];
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var className = useSectionRowStyles();
    var title = langApp.t('history');
    var loadData = function (_filter) {
        setLoading(true);
        accountStore
            .loadHistory(_filter || filter)
            .then(function (res) {
            if (res === null || res === void 0 ? void 0 : res.data) {
                setData(res);
                setLastLog(res.data[0]);
            }
        })
            .finally(function () { return setLoading(false); });
    };
    var onChangePerPage = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        var s = __assign(__assign({}, filter), (_a = {}, _a[name] = parseInt(value), _a));
        setFilter(s);
        loadData(s);
    };
    var noData = React.createElement(TextOverflow, null, langOrderList.t('order_list_filter_no_data_general'));
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var content = (React.createElement(Grid, { container: true, direction: 'column', style: __assign({ position: 'relative', paddingTop: theme.spacing(1) }, (!isSmall && { maxWidth: 320 })) },
        loading ? (React.createElement(Grid, { container: true, alignItems: 'center', justify: "center", style: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: "100%",
                backgroundColor: "rgba(255,255,255,0.4)",
                zIndex: 100,
            } },
            React.createElement(Loader, null))) : null,
        ((data === null || data === void 0 ? void 0 : data.count) && (React.createElement(React.Fragment, null,
            React.createElement(SectionHistoryParsedActions, __assign({}, {
                isAdmin: props.isAdmin,
                noData: noData,
                v3: props.v3,
                config: props.config,
                store: propsAll.store,
                record: record,
                order: order,
                type: type,
                list: data.data,
                onChange: props.onChange,
            })),
            (data.count > filter.limit && (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end', className: clsx((_a = {}, _a[className.root] = true, _a)) },
                React.createElement(BaseSelect, { v18: true, fullWidth: false, name: "limit", style: { marginLeft: theme.spacing(1), width: 'initial' }, title: langOrderList.t('order_list_filter_pagination_per_page'), value: filter.limit, onChange: onChangePerPage, options: ['10', '25', '50'] }),
                React.createElement(Pagination, { style: { marginLeft: theme.spacing(1) }, count: Math.ceil(data.count / filter.limit), page: filter.page, onChange: function (e, page) { return onChangePerPage({ target: { name: 'page', value: page } }); }, color: "primary" })))) ||
                null))) ||
            (!loading && noData) ||
            null));
    var hasUpdates = !props.v3 &&
        lastLog &&
        !__spread(lastLog.review, [lastLog.createdBy]).map(function (e) { return e._id; }).includes(accountStore.currentUser._id);
    var icon = props.icon || (React.createElement(HistoryIcon, { id: 'btn-history', style: __assign(__assign({}, props.style), (hasUpdates && { color: theme.palette.success.light })) }));
    if (hasUpdates) {
        icon = React.createElement(StyledBadgeV9, { badgeContent: 1 }, icon);
    }
    return (React.createElement(PopUpHover, { v1: props.v1, v2: props.v2, maxHeight: '70vh', maxWidth: 330, mobileMinWidth: '91vw', title: title, style: __assign({}, (props.v3 && { padding: 0 })), parentStyle: __assign(__assign({}, (props.v1 && { width: 48 })), (props.v2 && { width: '100%' })), parentSpanStyle: __assign(__assign({}, (props.v1 && { marginLeft: 12 })), (props.v2 && { height: 40, width: '100%' })), show: true, icon: icon, onMouseEnter: loadData, content: content }));
});
