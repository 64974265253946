var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import ChannelItemDetailsChatHistory from './ChannelItemDetailsChatHistory';
import ChannelItemDetailsChatForm from './ChannelItemDetailsChatForm';
import { theme } from '../../theme';
import { onPreventEvent } from '../../components';
var time = {};
var ChannelItemDetailsChat = function (props) {
    var _a = __read(React.useState(false), 2), isDragging = _a[0], setIsDragging = _a[1];
    var _b = __read(React.useState(false), 2), isUploading = _b[0], setIsUploading = _b[1];
    var key = "onDragOver-chat-page";
    return (React.createElement(Grid, { container: true, justify: "flex-end", style: { padding: theme.spacing(2) }, onDragOver: function (ev) {
            if (!isUploading) {
                onPreventEvent(ev);
                setIsDragging(true);
                time[key] = Date.now();
            }
        }, onDragLeave: function () {
            if (!isUploading) {
                var onDragLeaveTime_1 = Date.now();
                setTimeout(function () {
                    if (onDragLeaveTime_1 > time[key]) {
                        setIsDragging(false);
                    }
                }, 100);
            }
        } },
        React.createElement(ChannelItemDetailsChatHistory, __assign({}, props)),
        !props.isAdmin && (React.createElement(Grid, { item: true, xs: 12, md: props.v3 ? 12 : 8 },
            React.createElement(ChannelItemDetailsChatForm, __assign({ isDragging: isDragging, setIsDragging: setIsDragging, setIsUploading: setIsUploading }, props))))));
};
export default ChannelItemDetailsChat;
