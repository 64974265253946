var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PlacesAutocompleteV, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { FormikTextField } from '../Formik';
import { useStyles } from './styles';
import { OrderLocation } from '../../models';
import { KEYS } from '../../i18n';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { GooglePath, matches, parseAddress, re } from './utils';
var PlacesAutocomplete = PlacesAutocompleteV;
export default function GoogleMapAutocomplete(_a) {
    var onChange = _a.onChange, touchedError = _a.touchedError, field = _a.field, helperText = _a.helperText, title = _a.title, inputProps = _a.inputProps, props = __rest(_a, ["onChange", "touchedError", "field", "helperText", "title", "inputProps"]);
    var langForm = useTranslation(KEYS.FORM);
    var classes = useStyles();
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var _c = __read(React.useState({
        address: typeof field.value === 'object' && Object.keys(field.value).find(function (key) { return matches.map(function (e) { return e.key; }).includes(key); })
            ? field.value.address || OrderLocation.create({ address: field.value }).fullAddress
            : '',
    }), 2), state = _c[0], setState = _c[1];
    var handleChange = function (address) {
        setState(__assign(__assign({}, state), { address: address }));
    };
    var handleSelect = function (addressString) {
        geocodeByAddress(addressString)
            .then(function (results) {
            var address = parseAddress(results[0], { addressString: addressString });
            setState(__assign(__assign({}, state), { address: OrderLocation.create({ address: address }).fullAddress }));
            if (onChange)
                onChange(address);
            return getLatLng(results[0]);
        })
            .then(function (latLng) { return console.log('Success', latLng); })
            .catch(function (error) { return console.error('Error', error); });
    };
    function onLoadScript() {
        setUpdated(Date.now());
        var script = document.getElementById('google-api-script');
        if (script) {
            script.parentNode.removeChild(script); //detach events
        }
    }
    function onErrorLoadScript() {
        toast.error('Bad loaded google map script. Please refresh the page');
    }
    React.useEffect(function () {
        if (!window.googleInit) {
            window.googleInit = 1;
            var script_1 = document.createElement('script');
            var region = (re.exec(window.navigator.language) || '')[5];
            var lang = 'en'; //window.navigator.language.split('-')[0] || '';
            var src = GooglePath() + [region && "&region=" + region, lang && "&language=" + lang].filter(function (e) { return e; }).join('') || '';
            script_1.setAttribute('src', src);
            script_1.setAttribute('type', 'text/javascript');
            script_1.setAttribute('id', 'google-api-script');
            script_1.onerror = onErrorLoadScript;
            script_1.addEventListener('load', function () {
                onLoadScript();
                script_1 && script_1.parentNode && script_1.parentNode.removeChild(script_1);
            });
            document.head.appendChild(script_1);
        }
        else {
            var script = document.getElementById('google-api-script');
            if (script) {
                script.addEventListener('load', onLoadScript);
                script.onerror = onErrorLoadScript;
            }
        }
    }, []);
    if (!window.google)
        return (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null)));
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    return (React.createElement(PlacesAutocomplete, { value: state.address, onChange: handleChange, onSelect: handleSelect }, function (_a) {
        var getInputProps = _a.getInputProps, suggestions = _a.suggestions, getSuggestionItemProps = _a.getSuggestionItemProps, loading = _a.loading;
        var titlePlace = inputProps.placeholder ||
            (typeof title === 'string' && title) ||
            langForm.t('form_input_name_address_places');
        return (React.createElement(Grid, { className: classes.autoCompleteContainer },
            React.createElement(FormikTextField, __assign({ id: props.id, variant: "outlined", value: state.address, maxLength: 250 }, getInputProps({
                placeholder: titlePlace,
                title: titlePlace,
                className: classes.locationSearchInput,
                disabled: inputProps.disabled,
            }), { style: style, name: field.name || 'address', error: !!touchedError, helperText: touchedError || helperText })),
            React.createElement("div", { className: classes.autocompleteDropdownContainer, style: suggestions.length ? { border: "1px solid #ededed" } : {} },
                loading && React.createElement("div", null, "Loading..."),
                suggestions.map(function (suggestion) {
                    var className = "google-option " + (suggestion.active ? 'suggestion-item--active' : 'suggestion-item');
                    // inline style for demonstration purpose
                    var style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    Object.assign(style, {
                        display: 'flex',
                        justify: 'flex-start',
                        alignItems: 'center',
                    });
                    return (React.createElement(Grid, __assign({ container: true }, getSuggestionItemProps(suggestion, {
                        className: className,
                        style: style,
                    })),
                        React.createElement(LocationOnIcon, null),
                        React.createElement(Typography, { variant: "body2", color: "textSecondary", style: { maxWidth: "calc(100% - 50px)" } }, suggestion.description)));
                }))));
    }));
}
