import { applySnapshot, types } from 'mobx-state-tree';
export var FastPayment = types
    .model('FastPayment', {
    _id: types.optional(types.string, ''),
    createdAt: types.maybeNull(types.optional(types.string, '')),
    amount: types.maybeNull(types.optional(types.number, 0)),
    prevAmount: types.maybeNull(types.optional(types.number, 0)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
