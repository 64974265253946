var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import ArchiveIcon from '@material-ui/icons/Archive';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import DoneIcon from '@material-ui/icons/Done';
import { useCalendarFilterStyles } from '../../../styles';
import { AssignedInTransitIcon, AssignedUnLoadedIcon, HolidayIcon } from '../../icons';
import { theme } from '../../../theme';
import { CALENDAR_ELEMENTS, CALENDAR_MODE } from '../types';
import { withCalendarStore } from '../store';
import { TextOverflow } from '../../TextOverflow';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import BaseTooltip from '../../BaseTooltip';
import { onPreventEvent } from '../../Dialog';
import BaseChip from '../../BaseChip';
export var CalendarFilters = withCalendarStore(function (_a) {
    var mode = _a.mode, calendarElements = _a.state.calendarElements, toggleCalendarElements = _a.toggleCalendarElements, style = _a.style;
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var classes = useCalendarFilterStyles();
    var disabled = [CALENDAR_MODE.day, CALENDAR_MODE.week].includes(mode);
    var elements = [
        {
            title: langApp.t('none_work_time'),
            name: CALENDAR_ELEMENTS.NONE_WORKING_DAY,
            icon: React.createElement(TimerOffIcon, null),
        },
        {
            title: langApp.t('working_plan_holidays'),
            name: CALENDAR_ELEMENTS.HOLIDAYS,
            icon: React.createElement(HolidayIcon, null),
        },
        {
            title: langOfferForm.t('offer_from_label_plan'),
            name: CALENDAR_ELEMENTS.ORDERS_PLAN_PICKUP,
            disabled: disabled,
            icon: (React.createElement(Grid, { style: { position: 'relative' }, container: true },
                React.createElement(AssignedInTransitIcon, null))),
        },
        {
            title: langOfferForm.t('offer_to_label_plan'),
            name: CALENDAR_ELEMENTS.ORDERS_PLAN_DELIVERY,
            disabled: disabled,
            icon: (React.createElement(Grid, { style: { position: 'relative' }, container: true },
                React.createElement(AssignedUnLoadedIcon, null))),
        },
        {
            title: langOfferForm.t('offer_from_label'),
            name: CALENDAR_ELEMENTS.ORDERS_PICKUP,
            icon: (React.createElement(Grid, { style: { position: 'relative' }, container: true },
                React.createElement(AssignedInTransitIcon, null),
                React.createElement(DoneIcon, { style: { position: 'absolute', right: -9, top: -7, width: 16, height: 16 } }))),
        },
        {
            title: langOfferForm.t('offer_to_label'),
            name: CALENDAR_ELEMENTS.ORDERS_DELIVERY,
            icon: (React.createElement(Grid, { style: { position: 'relative' }, container: true },
                React.createElement(AssignedUnLoadedIcon, null),
                React.createElement(DoneIcon, { style: { position: 'absolute', right: -9, top: -7, width: 16, height: 16 } }))),
        },
        {
            title: langMenu.t('menu_sidebar_archive_title'),
            name: CALENDAR_ELEMENTS.ARCHIVE,
            icon: React.createElement(ArchiveIcon, null),
        },
    ];
    var handleClick = function (ev, element) {
        toggleCalendarElements(element.name);
        return onPreventEvent(ev);
    };
    return (React.createElement(Grid, { container: true, alignItems: 'center', style: __assign({ width: 'initial' }, style) }, elements.map(function (_a) {
        var _b, _c, _d;
        var disabled = _a.disabled, e = __rest(_a, ["disabled"]);
        var exists = !calendarElements.includes(e.name);
        return (React.createElement(BaseTooltip, { title: e.title },
            React.createElement(Grid, { container: true, key: e.name, direction: 'column', className: clsx((_b = {},
                    _b[classes.disabledV1] = disabled,
                    _b)), style: { marginRight: theme.spacing(1), width: 52 } },
                React.createElement(BaseChip, { key: e.name, onClick: function (ev) { return handleClick(ev, e); }, label: e.icon, className: clsx((_c = {}, _c[classes[e.name]] = true, _c), classes.root, 'izi-chip', (_d = {},
                        _d[classes.disabled] = exists || disabled,
                        _d)) }),
                React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: 'center' } }, e.title))));
    })));
});
