var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { TextOverflow } from '../../TextOverflow';
import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useMainStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            '& *': {},
        },
        disabled: {
            opacity: '0.6',
            pointEvents: 'none',
        },
    });
});
export default function OrderRouteMenuItem(_a) {
    var _b;
    var _c;
    var icon = _a.icon, label = _a.label, handleClickOpen = _a.handleClickOpen, disabled = _a.disabled, v1 = _a.v1, v2 = _a.v2, props = __rest(_a, ["icon", "label", "handleClickOpen", "disabled", "v1", "v2"]);
    var classes = useMainStyles();
    var text = label && (React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 30px)' } }, label));
    if (props.v3 && !label) {
        return icon;
    }
    return (React.createElement(Grid, { id: (_c = ("menu-item-" + label).match(/[a-zA-Z0-9]+/g)) === null || _c === void 0 ? void 0 : _c.join('-'), container: true, justify: 'space-between', alignItems: 'center', onClick: handleClickOpen, style: __assign(__assign({}, (v1 && { width: 'max-content' })), props.style), className: clsx(classes.root, (_b = {}, _b[classes.disabled] = disabled, _b)) }, v2 ? (React.createElement(React.Fragment, null,
        icon,
        text)) : (React.createElement(React.Fragment, null,
        text,
        icon))));
}
