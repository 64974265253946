var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import moment from 'moment';
import * as material from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { OrderLocation } from '../../../../../models';
import { TextOverflow } from '../../../../TextOverflow';
import { KEYS } from '../../../../../i18n';
import { toDateOnly } from '../../../../../utils';
import { LimitDatePicker } from '../../../../LimitDatePicker';
import { theme } from '../../../../../theme';
import OrderRoutesDirection from '../../OrderRoutesDirection';
var Grid = material.Grid;
export var BaseOrderQuoteTransportationCreateForm = React.forwardRef(function (_a, formRef) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var defaultValue = _a.defaultValue, onChange = _a.onChange, disabled = _a.disabled, v1 = _a.v1, order = _a.order, props = __rest(_a, ["defaultValue", "onChange", "disabled", "v1", "order"]);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var itemRef = React.useRef();
    var loadingStart = defaultValue.minDate;
    var loadingEnd = ((_b = defaultValue.loading) === null || _b === void 0 ? void 0 : _b.allowBookingOnlyOnSelectedPeriods) ? defaultValue.loading.endDate
        : defaultValue.endDate || ((_c = defaultValue.defRouteDates) === null || _c === void 0 ? void 0 : _c.endDate);
    var unloadingStart = ((_d = defaultValue.unloading) === null || _d === void 0 ? void 0 : _d.allowBookingOnlyOnSelectedPeriods) ? defaultValue.unloading.startDate
        : defaultValue.startDate || ((_e = defaultValue.defRouteDates) === null || _e === void 0 ? void 0 : _e.startDate);
    var unloadingEnd = ((_f = defaultValue.unloading) === null || _f === void 0 ? void 0 : _f.endDate) ? moment(new Date(new Date(defaultValue.unloading.endDate).setDate(new Date(defaultValue.unloading.endDate).getDate())))
        : defaultValue.maxDate;
    if (toDateOnly(unloadingEnd) < toDateOnly(unloadingStart)) {
        unloadingEnd = unloadingStart;
    }
    if (defaultValue.minDate && toDateOnly(loadingEnd) < toDateOnly(defaultValue.minDate)) {
        loadingEnd = defaultValue.minDate;
    }
    if (toDateOnly(loadingEnd) > toDateOnly(unloadingEnd)) {
        unloadingEnd = loadingEnd;
    }
    var hundredYearsFromNow = new Date();
    hundredYearsFromNow.setFullYear(hundredYearsFromNow.getFullYear() + 100);
    var loadingSpecialStart = null;
    var loadingSpecialEnd = null;
    if (((_g = defaultValue.loading) === null || _g === void 0 ? void 0 : _g.endDate) && !((_h = defaultValue.loading) === null || _h === void 0 ? void 0 : _h.allowBookingOnlyOnSelectedPeriods)) {
        loadingStart = moment(new Date());
        if (defaultValue.endDate === '') {
            loadingEnd = hundredYearsFromNow;
        }
        else {
            loadingEnd = moment(new Date(defaultValue.endDate));
        }
        loadingSpecialStart = moment(new Date(defaultValue.loading.startDate));
        loadingSpecialEnd = moment(new Date(defaultValue.loading.endDate));
    }
    else if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.endDate) {
        if (defaultValue.endDate === '') {
            loadingEnd = hundredYearsFromNow;
        }
        else {
            loadingEnd = moment(new Date(defaultValue.endDate));
        }
    }
    var unloadingSpecialStart = null;
    var unloadingSpecialEnd = null;
    if (((_j = defaultValue.unloading) === null || _j === void 0 ? void 0 : _j.startDate) && !((_k = defaultValue.unloading) === null || _k === void 0 ? void 0 : _k.allowBookingOnlyOnSelectedPeriods)) {
        unloadingEnd = hundredYearsFromNow;
        if (defaultValue.startDate === '') {
            unloadingStart = moment(new Date());
        }
        else {
            unloadingStart = moment(new Date(defaultValue.startDate));
        }
        unloadingSpecialStart = moment(new Date(defaultValue.unloading.startDate));
        unloadingSpecialEnd = moment(new Date(defaultValue.unloading.endDate));
    }
    else if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.startDate) {
        if (defaultValue.startDate === '') {
            unloadingStart = moment(new Date());
        }
        else {
            unloadingStart = moment(new Date(defaultValue.startDate));
        }
    }
    return (React.createElement(Formik, { innerRef: formRef, initialValues: defaultValue, onSubmit: function (e) { return e; }, validationSchema: Yup.object({
            startDate: Yup.string().required(langForm.t('form_input_validate_required')),
            endDate: Yup.string().required(langForm.t('form_input_validate_required')),
        }), validateOnBlur: false }, function (formik) {
        var _a, _b;
        var onClose = function (_a) {
            var _b;
            var _c = _a.target, name = _c.name, value = _c.value;
            onChange && onChange(__assign(__assign({}, formik.values), (_b = {}, _b[name] = value, _b)));
        };
        return (React.createElement(Form, { style: { width: '100%' }, id: "form-order-offer-date-form-" + (props.index || 0) },
            React.createElement(Grid, { container: true, spacing: 1, alignItems: 'center' },
                React.createElement(Grid, { item: true, xs: 12, md: 6, container: true, alignItems: 'flex-start', justify: "center", direction: 'column' },
                    React.createElement(Grid, { container: true, alignItems: 'center' },
                        React.createElement(OrderRoutesDirection, { route: defaultValue.loading })),
                    React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 52px)' } }, (((_a = defaultValue.loading) === null || _a === void 0 ? void 0 : _a.origin) &&
                        OrderLocation.create(defaultValue.loading.origin).fullAddressV2) ||
                        '-')),
                React.createElement(Grid, { item: true, xs: 12, md: 6, container: true, alignItems: 'flex-start', justify: "center", direction: 'column' },
                    React.createElement(Grid, { container: true, alignItems: 'center' },
                        React.createElement(OrderRoutesDirection, { route: defaultValue.unloading })),
                    React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 52px)' } }, (((_b = defaultValue.unloading) === null || _b === void 0 ? void 0 : _b.origin) &&
                        OrderLocation.create(defaultValue.unloading.origin).fullAddressV2) ||
                        '-'))),
            React.createElement(Grid, { container: true, spacing: 1, alignItems: 'flex-start' },
                React.createElement(Grid, { item: true, xs: 12, md: 6, style: { minHeight: theme.spacing(12) } },
                    React.createElement(LimitDatePicker, { order: order, disabled: disabled, route: defaultValue.loading, minDate: loadingStart, maxDate: moment(toDateOnly(loadingEnd) + ' 23:59:59', 'YYYY/MM/DD hh:mm:ss'), specialEnd: loadingSpecialEnd, specialStart: loadingSpecialStart, name: "startDate", title: langOfferForm.t('offer_pickup_date_label'), value: formik.values.startDate, onChange: onClose, isFormik: true })),
                React.createElement(Grid, { item: true, xs: 12, md: 6, style: { minHeight: theme.spacing(12) } },
                    React.createElement(LimitDatePicker, { order: order, disabled: disabled, route: defaultValue.unloading, minDate: moment(unloadingStart), maxDate: moment(toDateOnly(unloadingEnd) + ' 23:59:59', 'YYYY/MM/DD hh:mm:ss'), specialStart: unloadingSpecialStart, specialEnd: unloadingSpecialEnd, name: "endDate", title: langOfferForm.t('offer_delivery_date_label'), value: formik.values.endDate, onChange: onClose, isFormik: true })))));
    }));
});
