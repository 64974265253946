var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { BASE_QUERY } from '../base';
import { gql } from '@apollo/client';
export var ORDER_REQUEST_CHANGE_BASE = "\n    " + BASE_QUERY + "\n       order{\n        _id\n       }\n       createdBy{\n        _id\n       }\n       quote{\n        _id\n       }\n  comment \n";
export var CreateOrderQuoteRequestChange = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation createOrderQuoteRequestChange($input: OrderQuoteRequestChangeInput!) {\n        createOrderQuoteRequestChange(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderQuoteRequestChange($input: OrderQuoteRequestChangeInput!) {\n        createOrderQuoteRequestChange(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_REQUEST_CHANGE_BASE);
export var FetchOrderQuoteRequestChanges = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query orderQuoteRequestChanges($filter:MainFilter) {\n        orderQuoteRequestChanges(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderQuoteRequestChanges($filter:MainFilter) {\n        orderQuoteRequestChanges(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_REQUEST_CHANGE_BASE);
var templateObject_1, templateObject_2;
