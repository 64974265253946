var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { GlobalSocketComponent } from '../../../Socket';
import { toast } from 'react-toastify';
import { ACTIONS, } from './store.types';
import { FetchOrderChats } from '../../../../graphql/chat';
import { SOCKET_CHAT_CUSTOM_EVENT_NAME } from '../../../../pages/Chat/stores/constants';
import { withBaseGlobalSocketStoreActiveClient } from '../../global/store';
var initialState = {
    data: [],
    id: 0,
    lastChannelId: '',
    loading: false,
    totalUnread: 0,
};
var SidebarChannelsStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = SidebarChannelsStore.Provider, Consumer = SidebarChannelsStore.Consumer;
var getMaxChats = function () { return (window.innerWidth <= 500 ? 1 : 3); };
var index = 1;
export var SidebarChannelsProvider = function (_a) {
    var _this = this;
    var children = _a.children, client = _a.client, socketClient = _a.socketClient;
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), { id: index++ });
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.CHANGE_DATA: {
                return __assign(__assign({}, state), action.payload);
            }
            case ACTIONS.NEW_CHAT_MESSAGE: {
                var newData = state.data;
                if (!state.data.includes(action.payload.data[0])) {
                    newData = __spread(state.data, action.payload.data);
                }
                var maxChats = getMaxChats();
                while (newData.length > maxChats)
                    newData.shift();
                return __assign(__assign(__assign({}, state), action.payload), { data: newData });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var onAdd = function (chatId, payload) { return __awaiter(_this, void 0, void 0, function () {
        var data, maxChats;
        return __generator(this, function (_a) {
            data = __spread(state.data);
            if (data.find(function (e) { return e === chatId; })) {
                data = data.filter(function (e) { return e !== chatId; });
            }
            else {
                data.push(chatId);
            }
            maxChats = getMaxChats();
            if (data.length > maxChats)
                data.shift();
            dispatch({
                type: payload ? ACTIONS.NEW_CHAT_MESSAGE : ACTIONS.CHANGE_DATA,
                payload: __assign(__assign(__assign({}, payload), { data: data }), (!payload && { lastChannelId: '' })),
            });
            return [2 /*return*/];
        });
    }); };
    var onRemove = function (chatId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch({
                type: ACTIONS.CHANGE_DATA,
                payload: {
                    data: state.data.filter(function (e) { return e !== chatId; }),
                },
            });
            return [2 /*return*/];
        });
    }); };
    var onLoadFirst = function (filter) { return __awaiter(_this, void 0, void 0, function () {
        var result, chatId, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    dispatch({
                        type: ACTIONS.CHANGE_DATA,
                        payload: {
                            loading: true,
                        },
                    });
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: FetchOrderChats,
                            variables: {
                                filter: filter,
                            },
                        })];
                case 1:
                    result = _b.sent();
                    chatId = (_a = result.data.orderChats.data[0]) === null || _a === void 0 ? void 0 : _a._id;
                    if (chatId)
                        onAdd(chatId);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 4];
                case 3:
                    dispatch({
                        type: ACTIONS.CHANGE_DATA,
                        payload: {
                            loading: false,
                        },
                    });
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var events = socketClient
        ? [
            {
                client: socketClient,
                name: 'channel',
                callback: function (data) {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _a = data.event;
                                    switch (_a) {
                                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_FINISH_TYPING: return [3 /*break*/, 1];
                                    }
                                    return [3 /*break*/, 4];
                                case 1:
                                    if (!data.data.chat.order) return [3 /*break*/, 3];
                                    return [4 /*yield*/, onAdd(data.data.chat._id, { lastChannelId: data.data.chat._id })];
                                case 2:
                                    _b.sent();
                                    _b.label = 3;
                                case 3: return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                },
            },
        ]
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('*******************____+++++++SidebarChannelsProvider no client');
        }
        else {
            console.log('=======____+++++++ SidebarChannelsProvider notifications listen');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('---------------____+++++++SidebarChannelsProvider unListen');
        }
        else {
            console.log('=======____+++++++SidebarChannelsProvider notifications un listen');
        }
    };
    var value = {
        state: state,
        onLoadFirst: onLoadFirst,
        onRemove: onRemove,
        onAdd: onAdd,
        listen: listen,
        unListen: unListen,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withSidebarChannelsStore = function (Component) {
    return function (props) {
        var _a, _b;
        return (React.createElement(Consumer, { key: ((_b = (_a = props.globalSocketState) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b._id) || 1 }, function (_a) {
            var listen = _a.listen, unListen = _a.unListen, storeProps = __rest(_a, ["listen", "unListen"]);
            return (React.createElement(Component, __assign({}, __assign(__assign({}, props), { listen: listen, unListen: unListen }), { sidebarChannelsStore: storeProps })));
        }));
    };
};
var SidebarChannelsComponentListener = withSidebarChannelsStore(GlobalSocketComponent);
export var GlobalSidebarChannelsSocket = withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    var content = React.createElement(SidebarChannelsComponentListener, __assign({}, props));
    return (React.createElement(SidebarChannelsProvider, __assign({}, p, { client: client || (props.configData && props.configData().client), socketClient: p.globalSocketState.client }), content));
});
