var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel } from '../stores/common';
import { BaseModel } from './BaseModel';
export var ISSUE_STATUS = {
    REQUESTED: 1,
    IN_REVIEW: 2,
    RESOLVED: 3,
};
export var Issues = types
    .compose(AsyncActionStatus, types.model('Issues', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    title: types.maybeNull(types.optional(types.string, '')),
    parent: types.maybeNull(MaybeEmptyModel(BaseModel)),
    parent_collection_name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.optional(types.string, '')),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    status: types.maybeNull(types.optional(types.union(types.string, types.number), '')),
}))
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(Issues.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); });
