var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as material from '@material-ui/core';
import { onPreventEvent } from './Dialog';
import React from 'react';
import BaseTooltip from './BaseTooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { TextOverflow } from './TextOverflow';
import { theme } from '../theme';
import { useSectionStyles } from '../styles';
import PaddingSpace from './Order/form/formsV1/PaddingSpce';
import { wrapTextToId } from '../utils';
import { browserStorage } from '../browserStorage';
var Box = material.Box;
var Collapse = material.Collapse, IconButton = material.IconButton;
export default function ExpandFormItem(_a) {
    var open = _a.open, hideSpace = _a.hideSpace, help = _a.help, additionalHelp = _a.additionalHelp, isRemember = _a.isRemember, props = __rest(_a, ["open", "hideSpace", "help", "additionalHelp", "isRemember"]);
    var _b = __read(React.useState(typeof open === 'boolean' ? open : browserStorage.get("expand-form-item-" + props.title) === '1'), 2), isOpen = _b[0], setIsOpen = _b[1];
    var langApp = useTranslation(KEYS.APP);
    var classes = useSectionStyles();
    var toggleCollapse = function (e) {
        setIsOpen(!isOpen);
        if (isRemember)
            browserStorage.set("expand-form-item-" + props.title, isOpen ? '0' : '1');
        return onPreventEvent(e);
    };
    var collapseItem = (React.createElement(BaseTooltip, { title: (isOpen ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
        React.createElement(IconButton, { onMouseEnter: onPreventEvent, onClick: toggleCollapse, color: 'primary', style: { padding: 0, marginRight: theme.spacing(1) } }, isOpen ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null))));
    var collapseMainId = ['container', wrapTextToId(props.title)].join('-');
    var collapseItemId = ['item', wrapTextToId(props.title)].join('-');
    var collapseItemAreaId = ['area', wrapTextToId(props.title)].join('-');
    var collapse = (React.createElement(Collapse, { in: isOpen, timeout: "auto", style: { width: '100%' }, "data-id": collapseItemAreaId }, props.children || null));
    if (props.v1) {
        return (React.createElement("div", { style: { width: '100%' }, "data-id": collapseMainId },
            React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', "data-id": collapseItemId },
                React.createElement(Box, { display: 'flex', alignItems: 'center' },
                    (!hideSpace && React.createElement(PaddingSpace, null)) || null,
                    collapseItem,
                    React.createElement(TextOverflow, { className: classes.btnTitle, onClick: toggleCollapse, style: { marginRight: theme.spacing(0.5) } }, props.title),
                    help,
                    (isOpen && additionalHelp) || null),
                isOpen && props.tableHeader ? (React.createElement(Box, { display: 'flex', alignItems: 'flex-end', marginRight: 4.2, height: '100%' }, props.tableHeader)) : null),
            collapse));
    }
    return (React.createElement("div", { style: { width: '100%' }, "data-id": collapseMainId },
        React.createElement(Grid, { container: true, alignItems: 'center', "data-id": collapseItemId },
            (!hideSpace && React.createElement(PaddingSpace, null)) || null,
            collapseItem,
            React.createElement(TextOverflow, { onClick: toggleCollapse, className: classes.btnTitle, style: { marginRight: theme.spacing(0.5) } }, props.title),
            help),
        React.createElement(Collapse, { in: isOpen, timeout: "auto", style: { width: '100%' }, "data-id": collapseItemAreaId }, props.children || null)));
}
