var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_QUOTE_TRANSPORT_BASE = "\n    " + BASE_QUERY + "\n       quote{\n            _id\n       }\n       track \n       container \n       contactName \n      trailer \n      phone\n";
export var FetchOrderQuoteTransports = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderQuoteTransports($filter: MainFilter) {\n        orderQuoteTransports(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderQuoteTransports($filter: MainFilter) {\n        orderQuoteTransports(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_QUOTE_TRANSPORT_BASE);
export var CreateOrderQuoteTransport = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderQuoteTransport($input: OrderQuoteTransportInput!) {\n        createOrderQuoteTransport(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderQuoteTransport($input: OrderQuoteTransportInput!) {\n        createOrderQuoteTransport(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_TRANSPORT_BASE);
export var UpdateOrderQuoteTransport = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateOrderQuoteTransport($input: OrderQuoteTransportInputUpdate!) {\n        updateOrderQuoteTransport(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderQuoteTransport($input: OrderQuoteTransportInputUpdate!) {\n        updateOrderQuoteTransport(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_TRANSPORT_BASE);
export var DeleteOrderQuoteTransport = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderQuoteTransport($_id: MongoId!) {\n        deleteOrderQuoteTransport(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderQuoteTransport($_id: MongoId!) {\n        deleteOrderQuoteTransport(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
