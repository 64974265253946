import React from 'react';
import { Grid } from '@material-ui/core';
import { NoData } from '../components/NoData';
import { KEYS, i18n } from '../i18n';
import { MainButton, TextOverflow } from '../components';
export var WaitingPage = function () {
    return (React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center', style: { height: '100%' } },
        React.createElement(NoData, { noData: React.createElement(TextOverflow, { variant: "h5", style: { textAlign: 'center' } }, i18n.getResource(i18n.language, KEYS.APP, 'main_status_offline')) }),
        React.createElement(MainButton, { click: function () { return window.location.reload(); }, title: i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_fresh') })));
};
export default WaitingPage;
