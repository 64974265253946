var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { getTextWidth, wrapTextToId } from '../../utils';
import { useSectionRowStyles } from './styles';
import AdditionalRquirementsSectionRow from './SectionDetailsAdditionalRequirements';
import CargoSectionRow from './SectionDetailsCargoSettingsRow';
import { useAnimationStyles } from '../../styles/main';
export default function SectionRow(p) {
    var _a, _b;
    var _c;
    var name = p.name, value = p.value, sizeName = p.sizeName, sizeValue = p.sizeValue, v1 = p.v1, props = __rest(p, ["name", "value", "sizeName", "sizeValue", "v1"]);
    var className = useSectionRowStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var style = '400 12px Montserrat';
    var classesAnimation = useAnimationStyles();
    var nameStyle = { fontWeight: 700, display: 'block' };
    if (props.isAdditionalRquirements && props.parentWidth * 0.5 < (getTextWidth(value, style) || 0)) {
        return React.createElement(AdditionalRquirementsSectionRow, __assign({}, p, { nameStyle: nameStyle }));
    }
    var part = sizeValue ? sizeValue / 12 : 0.5;
    if (props.isCargo && props.parentWidth * part < (getTextWidth(value, style) || 0)) {
        return React.createElement(CargoSectionRow, __assign({}, p, { nameStyle: nameStyle, isSmall: isSmall }));
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: clsx((_a = {}, _a[className.root] = !v1, _a)), id: "row-" + (typeof name === 'string' && wrapTextToId(name)) },
        React.createElement(Grid, { item: true, xs: 12, md: sizeName || 6, container: true, alignItems: 'center', style: {
                width: 'initial',
            } },
            React.createElement(TextOverflow, { variant: 'caption', style: nameStyle }, name),
            p.additional),
        React.createElement(Grid, __assign({ item: true, xs: 12, md: sizeValue || 6, className: clsx((_b = {},
                _b[classesAnimation.pulse] = props.hasUpdates,
                _b)) }, (((_c = p.attributes) === null || _c === void 0 ? void 0 : _c.length) &&
            props.attributes.map(function (e) {
                var _a;
                return (_a = {}, _a["data-" + e.name] = e.value, _a);
            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { style: {
                zIndex: 1,
                position: 'relative',
                display: 'grid',
            } }),
            React.createElement(TextOverflow, { variant: 'caption', style: { textAlign: isSmall ? 'left' : 'right' } }, value))));
}
