var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_CARGO_PALLET_TYPE_BASE = "\n    " + BASE_QUERY + "\n      dimension\n      name\n      length\n      width\n      isGlobal\n      origin\n      approvedAt\n";
export var UpdateOrderCargoPalletType = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateOrderCargoPalletType($input: OrderCargoPalletTypeInputUpdate!) {\n        updateOrderCargoPalletType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCargoPalletType($input: OrderCargoPalletTypeInputUpdate!) {\n        updateOrderCargoPalletType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PALLET_TYPE_BASE);
export var CreateOrderCargoPalletType = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderCargoPalletType($input: OrderCargoPalletTypeInput!) {\n        createOrderCargoPalletType(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCargoPalletType($input: OrderCargoPalletTypeInput!) {\n        createOrderCargoPalletType(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_PALLET_TYPE_BASE);
export var FetchOrderCargoPalletType = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderCargoPalletTypes($filter:MainFilter) {\n        orderCargoPalletTypes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n            hasMore\n        }\n    }\n"], ["\n    query orderCargoPalletTypes($filter:MainFilter) {\n        orderCargoPalletTypes(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n            hasMore\n        }\n    }\n"])), ORDER_CARGO_PALLET_TYPE_BASE);
export var DeleteOrderCargoPalletType = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderCargoPalletType($_id: MongoId!) {\n        deleteOrderCargoPalletType(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderCargoPalletType($_id: MongoId!) {\n        deleteOrderCargoPalletType(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
