var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, LIST_SIMPLE_DATA } from '../base';
import { ORDER_CARGO_PACKAGE_BASE } from './orderCargoPackage';
import { ORDER_CARGO_PALLET_TYPE_BASE } from './orderCargoPalletType';
import { ORDER_CARGO_PRODUCT_TYPE_BASE } from './orderCargoProductType';
export var ORDER_CARGO_BASE = "\n    " + BASE_QUERY + "\n       order{\n        _id\n       }\n       palletType{\n        " + ORDER_CARGO_PALLET_TYPE_BASE + "\n       }\n       dimension\n       package{\n        " + ORDER_CARGO_PACKAGE_BASE + "\n       }\n       productType{\n        " + ORDER_CARGO_PRODUCT_TYPE_BASE + "\n       }\n       \n       orderTransportation{\n        _id\n       }\n       isStackable\n       amount\n       height\n       volume\n       taintedCode\n       weightMeasure\n       weight\n       cargoId\n";
export var OrderCargoHeight = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderCargoHeight($height:Int) {\n        orderCargoHeight(height: $height) {\n            ", "\n        }\n    }\n"], ["\n    query orderCargoHeight($height:Int) {\n        orderCargoHeight(height: $height) {\n            ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
export var OrderCargoPalletField = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query orderCargoPalletField($field:String) {\n        orderCargoPalletField(field: $field) {\n            ", "\n        }\n    }\n"], ["\n    query orderCargoPalletField($field:String) {\n        orderCargoPalletField(field: $field) {\n            ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
export var OrderCargoProductTaintedCode = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderCargoProductTaintedCode($value:String) {\n        orderCargoProductTaintedCode(value: $value) {\n            ", "\n        }\n    }\n"], ["\n    query orderCargoProductTaintedCode($value:String) {\n        orderCargoProductTaintedCode(value: $value) {\n            ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
export var CreateOrderCargo = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation createOrderCargo($input: OrderCargoInput!) {\n        createOrderCargo(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderCargo($input: OrderCargoInput!) {\n        createOrderCargo(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_BASE);
export var UpdateOrderCargo = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation updateOrderCargo($input: OrderCargoInputUpdate!) {\n        updateOrderCargo(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderCargo($input: OrderCargoInputUpdate!) {\n        updateOrderCargo(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_CARGO_BASE);
export var DeleteOrderCargo = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation deleteOrderCargo($_id: MongoId!) {\n    deleteOrderCargo(_id: $_id){\n        ", "\n    }\n  }\n"], ["\n  mutation deleteOrderCargo($_id: MongoId!) {\n    deleteOrderCargo(_id: $_id){\n        ", "\n    }\n  }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
