var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { BasicDialog, FilePreview } from '../Dialog';
import { TextOverflow } from '../TextOverflow';
import { theme } from '../../theme';
import { BaseList, BaseListItem } from '../BasicList';
import { dateViewFormat } from '../DateView';
import { FetchUploads } from '../../graphql';
export function AttachmentList(props) {
    var _a;
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var _config = {
        id: 'attachments',
        title: '',
        hideSort: true,
        hideSearch: true,
        hasHeaderSort: true,
        headerStyle: { padding: theme.spacing(0, 1) },
        headerColumns: [
            {
                size: 6,
                name: langForm.t('form_input_name_created_at'),
                sortable: true,
                sortKey: 'createdAt',
            },
            {
                size: 6,
                name: langForm.t('form_input_actions'),
                style: { display: 'flex', justifyContent: 'flex-end' },
            },
        ].filter(function (e) { return e; }),
        rowContent: function (record, onChange) { return (React.createElement(BaseListItem, { record: record, onChange: onChange },
            React.createElement(Grid, { container: true, alignItems: 'center', style: { padding: theme.spacing(1) } },
                React.createElement(Grid, { item: true, xs: 12, md: 6 },
                    React.createElement(TextOverflow, { variant: 'caption' }, [record.createdAt]
                        .filter(function (e) { return e; })
                        .map(function (e) { return dateViewFormat(e, 1); })
                        .join('/'))),
                React.createElement(Grid, { item: true, xs: 12, md: 6, container: true, justify: 'flex-end' },
                    React.createElement(FilePreview, { item: record, title: "" + langForm.t('form_btn_view'), config: props.config }))))); },
    };
    var defaultFilter = __assign({}, props.defaultFilter);
    var baseListProps = {
        client: (_a = props.config) === null || _a === void 0 ? void 0 : _a.client,
        query: FetchUploads,
        defaultFilter: defaultFilter,
    };
    return React.createElement(BaseList, { options: baseListProps, title: langApp.t('main_terms'), config: _config });
}
export default function Attachments(props) {
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var actonInit = props.actionInit
        ? props.actionInit
        : function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: langOfferForm.t('offer_document_attached') },
            React.createElement(IconButton, { onClick: handleClickOpen, style: __assign({}, props.style) },
                React.createElement(AttachFileIcon, null)))); };
    var body = function (open) {
        if (open) {
            return React.createElement(AttachmentList, __assign({}, props));
        }
        else {
            return null;
        }
    };
    return (React.createElement(BasicDialog, __assign({ title: langOfferForm.t('offer_document_attached'), withoutWrapper: true, fullWidth: true, dialogProps: {
            fullWidth: true,
            maxWidth: 'md',
        } }, props, { actionInit: actonInit, body: body })));
}
