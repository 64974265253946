var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { LANG_BASE } from './base';
import { BASE_RESPONSE, LIST_SIMPLE_DATA } from '../base';
export var FetchLangs = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query langs($filter:MainFilter){\n        langs(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query langs($filter:MainFilter){\n        langs(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), LANG_BASE);
export var FetchAllLang = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query allLang($filter: MainFilter) {\n    allLang(filter: $filter) {\n      keys\n    }\n  }\n"], ["\n  query allLang($filter: MainFilter) {\n    allLang(filter: $filter) {\n      keys\n    }\n  }\n"])));
export var FetchAllLangs = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query allLangList($filter: MainFilter) {\n    allLangList(filter: $filter) {\n      keys\n    }\n  }\n"], ["\n  query allLangList($filter: MainFilter) {\n    allLangList(filter: $filter) {\n      keys\n    }\n  }\n"])));
export var CreateLang = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation createLang($input:LangInput!){\n        createLang(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createLang($input:LangInput!){\n        createLang(input:$input) {\n            ", "\n        }\n    }\n"])), LANG_BASE);
export var UpdateLang = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation updateLang($input:LangInputUpdate!){\n        updateLang(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateLang($input:LangInputUpdate!){\n        updateLang(input:$input) {\n            ", "\n        }\n    }\n"])), LANG_BASE);
export var DeleteLang = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation deleteLang ($_id: MongoId!) {\n        deleteLang(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteLang ($_id: MongoId!) {\n        deleteLang(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var FetchLangPrefixes = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    query langPrefixes ($_id: String) {\n        langPrefixes(_id:$_id) {\n             ", "\n        }\n    }\n"], ["\n    query langPrefixes ($_id: String) {\n        langPrefixes(_id:$_id) {\n             ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
