var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { LOCATION_ADDRESS } from './base';
export var GLOBAL_SEARCH_ORDER_BASE = "\n_id\norderId\norderCustomId\nstatus\nmanager{\n    fullName\n    company{\n        name\n    }\n}\ntemplateName\nsorting_pickupAt\nsorting_deliveryAt\nsorting_totalWeight\nisTemplate\nlatestQuote{\n    inProgressStatus\n}\ntransportations{\n          routes{\n              type\n            origin{\n                companyName\n                companyId{\n                    name\n                }\n                address{\n                    " + LOCATION_ADDRESS + "\n                }\n            }\n        }\n      }\n";
export var GLOBAL_SEARCH_BASE = "\n  orders{\n    " + GLOBAL_SEARCH_ORDER_BASE + "\n  }\n";
export var FetchGlobalSearch = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query globalSearch($filter: MainFilter) {\n    globalSearch(filter: $filter) {\n      ", "\n    }\n  }\n"], ["\n  query globalSearch($filter: MainFilter) {\n    globalSearch(filter: $filter) {\n      ", "\n    }\n  }\n"])), GLOBAL_SEARCH_BASE);
var templateObject_1;
