var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { dayInEn, toDateOnly } from '../../../utils';
import { BaseUserAppTYpe } from '../../../stores';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme';
import MenuListItem from '../../MenuListItem';
import AddNoneWorkingTime from './AddNoneWorkingTime';
import AddOrder from './AddOrder';
import React from 'react';
import { inject } from 'mobx-react';
import { withCalendarStore } from '../store';
import moment from 'moment';
function DayOptions(_a) {
    var _b, _c, _d;
    var _e = _a.store, _f = _e.accountStore, currentCompany = _f.currentCompany, currentUser = _f.currentUser, warehouseStore = _e.warehouseStore, _g = _a.state, ramps = _g.ramps, workingDays = _g.workingDays, holidays = _g.holidays, warehouse = _g.warehouse, isPublic = _g.isPublic, day = _a.day, props = __rest(_a, ["store", "state", "day"]);
    var currentDay = toDateOnly(day);
    var currentDayMoment = moment(toDateOnly(day));
    var currentWeekDay = dayInEn(currentDayMoment);
    var _holidays = holidays.data.filter(function (e) {
        return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
            moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
            e.warehouse._id === (warehouse === null || warehouse === void 0 ? void 0 : warehouse._id);
    });
    var isForwarder = currentCompany.type === BaseUserAppTYpe.FORWARDER;
    var isForwarderForManager = isForwarder && ((_d = (_c = (_b = warehouseStore.item) === null || _b === void 0 ? void 0 : _b.manager) === null || _c === void 0 ? void 0 : _c.forwarders) === null || _d === void 0 ? void 0 : _d.find(function (e) { var _a; return ((_a = e === null || e === void 0 ? void 0 : e._id) === null || _a === void 0 ? void 0 : _a.toString()) === currentUser._id.toString(); }));
    var isAvailable = workingDays.data.find(function (e) { return e.day === currentWeekDay && e.maxTime && e.maxTime._id; }) &&
        !_holidays.length;
    // console.log(_holidays, isAvailable, workingDays.data.length, currentWeekDay);
    var isNotPast = toDateOnly(currentDay) >= toDateOnly();
    if (isPublic) {
        return ((isNotPast && isAvailable && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginRight: theme.spacing(1) } },
            React.createElement(MenuListItem, { show: true, list: [
                    {
                        content: React.createElement(AddOrder, { isPickup: true, day: day }),
                        available: ramps.count,
                    },
                    {
                        content: React.createElement(AddOrder, { day: day }),
                        available: ramps.count,
                    },
                ] })))) ||
            null);
    }
    return ((isNotPast &&
        !isForwarderForManager &&
        [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.WAREHOUSE].includes(currentCompany.type) &&
        isAvailable && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', marginRight: theme.spacing(1) } },
        React.createElement(MenuListItem, { show: true, list: [
                {
                    content: React.createElement(AddNoneWorkingTime, __assign({}, props, { label: true, day: day, noneWorking: true })),
                    available: true,
                },
                {
                    content: React.createElement(AddOrder, { isPickup: true, day: day }),
                    available: ramps.count,
                },
                {
                    content: React.createElement(AddOrder, { day: day }),
                    available: ramps.count,
                },
            ].filter(function (e) { return e.available; }) })))) ||
        null);
}
export default inject('store')(withCalendarStore(DayOptions));
