var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { CHAT_MESSAGE_BASE } from './types';
export var FetchOrderChatMessages = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query orderChatMessages($filter: MainFilter) {\n        orderChatMessages(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderChatMessages($filter: MainFilter) {\n        orderChatMessages(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), CHAT_MESSAGE_BASE);
export var CreateOrderChatMessage = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderChatMessage($input: OrderChatMessageInput!) {\n        createOrderChatMessage(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderChatMessage($input: OrderChatMessageInput!) {\n        createOrderChatMessage(input: $input) {\n            ", "\n        }\n    }\n"])), CHAT_MESSAGE_BASE);
export var ReadOrderChatMessage = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation readOrderChatMessage($_id: MongoId!) {\n        readOrderChatMessage(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation readOrderChatMessage($_id: MongoId!) {\n        readOrderChatMessage(_id: $_id) {\n            ", "\n        }\n    }\n"])), CHAT_MESSAGE_BASE);
var templateObject_1, templateObject_2, templateObject_3;
