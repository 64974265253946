var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, flow } from 'mobx-state-tree';
import { toast } from 'react-toastify';
import { cast } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel } from './common';
import { i18n, KEYS } from '../i18n';
import { CreateOrderChat, DeleteOrderChat, FetchChat, UpdateOrderChat } from '../graphql/chat';
import { CreateOrderChatMessage, FetchOrderChatMessages } from '../graphql/chat/message';
import { OrderChatMessage } from '../models';
export var ChatStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model('ChatStore', {
        data: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderChatMessage)), [])),
        count: types.optional(types.number, 0),
        isUpdating: types.optional(types.boolean, false),
        isLoading: types.optional(types.boolean, false),
        isHistoryLoading: types.optional(types.boolean, false),
        isMessageCreating: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        loadChatHistory: flow(function (filter) {
            var result, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isHistoryLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchOrderChatMessages,
                                variables: {
                                    input: { filter: filter },
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        // console.log(
                        //   result.data.orderChatMessages,
                        //   result.data.orderChatMessages.data.map((e) => cast(e))
                        // );
                        (_a = self.data).push.apply(_a, __spread(result.data.orderChatMessages.data.map(function (e) { return cast(e); })));
                        self.count = result.data.orderChatMessages.count;
                        return [2 /*return*/, result.data.orderChatMessages];
                    case 2:
                        e_1 = _b.sent();
                        toast.error(e_1.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isHistoryLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderChatMessage: flow(function (input) {
            var result, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isMessageCreating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderChatMessage,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        self.data.splice(0, 0, cast(result.data.createOrderChatMessage));
                        return [2 /*return*/, result.data.createOrderChatMessage];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isMessageCreating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadOne: flow(function (_id) {
            var result, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchChat,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderChat];
                    case 2:
                        e_3 = _a.sent();
                        self.isLoading = false;
                        toast.error(e_3.message);
                        throw e_3;
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createChat: flow(function (_a) {
            var result, e_4;
            var _id = _a._id, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderChat,
                                variables: {
                                    input: Object.assign(__assign(__assign({ _id: _id, name: name,
                                        description: description }, (input.users && { users: input.users.map(function (e) { return e._id || e; }) })), (input.order && { order: input.order._id || input.order }))),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_chat_title')));
                        return [2 /*return*/, result.data.createOrderChat];
                    case 2:
                        e_4 = _b.sent();
                        self.isUpdating = false;
                        toast.error(e_4.message);
                        console.log(e_4);
                        throw e_4;
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateChat: flow(function (_input) {
            var _a, _id, createdBy, createdAt, updatedBy, __typename, name_1, description, input, result, e_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        _a = JSON.parse(JSON.stringify(_input)), _id = _a._id, createdBy = _a.createdBy, createdAt = _a.createdAt, updatedBy = _a.updatedBy, __typename = _a.__typename, name_1 = _a.name, description = _a.description, input = __rest(_a, ["_id", "createdBy", "createdAt", "updatedBy", "__typename", "name", "description"]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderChat,
                                variables: {
                                    input: Object.assign(__assign(__assign({ _id: _id, name: name_1,
                                        description: description }, (input.users && { users: input.users.map(function (e) { return e._id || e; }) })), (input.order && { order: input.order._id || input.order }))),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        //
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_chat_title')));
                        return [2 /*return*/, result.data.updateOrderChat];
                    case 2:
                        e_5 = _b.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        deleteChat: flow(function (input) {
            var e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteOrderChat,
                                variables: {
                                    _id: input._id,
                                },
                            })];
                    case 1:
                        _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_chat_title')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_6 = _a.sent();
                        toast.error(e_6.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .named('ChatStore');
};
