var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withBaseListStore } from './store';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { MainButton } from '../MainButton';
import { inject } from 'mobx-react';
function ResetFilter(_a) {
    var _b, _c, _d, _e;
    var state = _a.state, store = _a.store, config = _a.config, onResetFilter = _a.onResetFilter, uselessFilter = _a.uselessFilter, props = __rest(_a, ["state", "store", "config", "onResetFilter", "uselessFilter"]);
    var uselessFieldNames = ((_b = uselessFilter === null || uselessFilter === void 0 ? void 0 : uselessFilter.fields) === null || _b === void 0 ? void 0 : _b.map(function (ee) { return ee.name; })) || [];
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var isUsedAnyFilter = ((_e = (_d = (_c = state === null || state === void 0 ? void 0 : state.filter) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.filter(function (e) { return !uselessFieldNames.includes(e.name); })) === null || _e === void 0 ? void 0 : _e.length) > 0;
    var theme = useTheme();
    var _onResetFilter = function (e) { return onResetFilter(e, { currentUser: store.accountStore.currentUser }); };
    return config.hideNoDataIconV1 || !isUsedAnyFilter
        ? null
        : (!config.hideNoDataIcon && (React.createElement(MainButton, { onClick: _onResetFilter, variant: "outlined", color: 'primary', title: langOrderList.t('order_list_filter_reset_filters'), style: props.style || { marginTop: theme.spacing(1) } }))) || (React.createElement(BaseTooltip, { title: langOrderList.t('order_list_filter_reset_filters') },
            React.createElement(IconButton, { onClick: _onResetFilter },
                React.createElement(ReplayIcon, { color: 'primary' })))) ||
            null;
}
export default inject('store')(withBaseListStore(ResetFilter));
