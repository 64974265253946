var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { KEYS } from '../../i18n';
import { MainTabsRoutesVIew } from '../../components';
import PreloadingComponent from '../../components/PreloadingComponent';
import BasicBreadcrumbs from '../../components/BreadCrumbs';
var AccountPage = React.lazy(function () { return import('./AccountPage'); });
var UserSettingsPaymentTermContent = React.lazy(function () { return import('../../components/UserSettingsPaymentTerm/UserSettingsPaymentTermContent'); });
var SettingsContent = React.lazy(function () { return import('../../components/Settings/SettingsContent'); });
import { BaseUserAppTYpe } from '../../stores';
var MainTabsRoutes = function (_a) {
    var _b;
    var config = _a.config, props = __rest(_a, ["config"]);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var routes = props.onlySelectedRoutes
        ? props.routes
        : __spread((props.routes || []), [
            {
                name: langOfferForm.t('offer_overview'),
                path: "/account/details",
                url: "/account/details",
                component: function (p) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(AccountPage, __assign({}, p, { config: config })))); },
            },
            {
                name: langMenu.t('menu_sidebar_settings_title'),
                path: "/account/settings",
                url: "/account/settings",
                component: function (p) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(SettingsContent, __assign({}, p, { config: config })))); },
            },
            BaseUserAppTYpe.CUSTOMER === props.appType &&
                [BaseUserAppTYpe.CUSTOMER].includes(props.store.accountStore.currentCompany.type) && {
                name: langForm.t('form_invoice_term'),
                path: "/account/payment-term",
                url: "/account/payment-term",
                component: function (p) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(UserSettingsPaymentTermContent, __assign({}, p, { config: config })))); },
            },
        ]).filter(function (e) { return e; });
    var rootPath = window.location.pathname.split('/')[1].toLowerCase();
    var title = (_b = routes.find(function (e) { var _a; return ((_a = e.path) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === window.location.pathname.toLowerCase(); })) === null || _b === void 0 ? void 0 : _b.name;
    return (React.createElement(MainTabsRoutesVIew, { v1: true, routes: routes, title: React.createElement(PreloadingComponent, null,
            React.createElement(BasicBreadcrumbs, { style: { paddingLeft: 0 }, list: [{ path: "/" + rootPath + "/details", title: 'Account' }, { title: title }] })) }));
};
export default inject('store')(observer(MainTabsRoutes));
