var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { createStyles, Grid, IconButton } from '@material-ui/core';
import { TextOverflow } from '../../TextOverflow';
import BaseTooltip from '../../BaseTooltip';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { usePrevNextButtonStyles } from '../../Buttons/PrevNextButton';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { makeStyles } from '@material-ui/core/styles';
import { StyledBadgeV15, StyledBadgeV16, StyledBadgeV4 } from '../../StyledBadge';
import { withCalendarStore } from '../store';
import moment from 'moment';
import { toDateOnly } from '../../../utils';
import { DayHolidayDetails } from '../MonthViewV1/DayHolidayDetails';
import { theme } from '../../../theme';
import clsx from 'clsx';
import { OrderRouteType } from '../../../models';
import { PopupDayRampsDetails } from '../MonthViewV1/MonthDayAll';
var useRampsSwitcherStyles = makeStyles(function (theme) {
    return createStyles({
        rootV1: {
            '& *': {
                color: theme.palette.common.black,
            },
        },
        opened: {
            background: theme.palette.primary.main,
            '& *': {
                color: theme.palette.common.white + "!important",
            },
        },
        root: {
            background: theme.palette.common.white,
            border: '1px solid #BAB8B8',
            boxSizing: 'border-box',
        },
    });
});
export default withCalendarStore(function (_a) {
    var _b;
    var _c, _d;
    var record = _a.record, show = _a.show, total = _a.total, open = _a.open, onToggleShow = _a.onToggleShow, style = _a.style, _e = _a.state, transportations = _e.transportations, holidays = _e.holidays, calendarState = __rest(_e, ["transportations", "holidays"]), props = __rest(_a, ["record", "show", "total", "open", "onToggleShow", "style", "state"]);
    var classesV = useRampsSwitcherStyles();
    var classes = usePrevNextButtonStyles();
    var langApp = useTranslation(KEYS.APP);
    var date = record.id.split('-')[0];
    var nonBookedFilter = function (e) {
        var _a, _b, _c, _d;
        var routes = e.routes.map(function (e) { return e.type; });
        if (!routes.length)
            return false;
        var isInner = routes.length === 2;
        return isInner
            ? ((_a = e === null || e === void 0 ? void 0 : e.startTime) === null || _a === void 0 ? void 0 : _a.length) === 0 && ((_b = e === null || e === void 0 ? void 0 : e.endTime) === null || _b === void 0 ? void 0 : _b.length) === 0
            : routes.includes(OrderRouteType.Preferred)
                ? ((_c = e === null || e === void 0 ? void 0 : e.startTime) === null || _c === void 0 ? void 0 : _c.length) === 0
                : ((_d = e === null || e === void 0 ? void 0 : e.endTime) === null || _d === void 0 ? void 0 : _d.length) === 0;
    };
    var nonBookedTransportations = open
        ? (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items
            .filter(function (e) { return e.routes.map(function (e) { return e.origin.warehouse._id; }).includes(record.ramp._id); })
            .filter(nonBookedFilter)
        : (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items.filter(nonBookedFilter);
    var notBookedBadge = nonBookedTransportations.length;
    var bookedFilter = function (e) {
        var _a, _b, _c, _d;
        var routes = e.routes.map(function (e) { return e.type; });
        if (!routes.length)
            return false;
        var isInner = routes.length === 2;
        return isInner
            ? ((_a = e === null || e === void 0 ? void 0 : e.startTime) === null || _a === void 0 ? void 0 : _a.length) !== 0 || ((_b = e === null || e === void 0 ? void 0 : e.endTime) === null || _b === void 0 ? void 0 : _b.length) !== 0
            : routes.includes(OrderRouteType.Preferred)
                ? ((_c = e === null || e === void 0 ? void 0 : e.startTime) === null || _c === void 0 ? void 0 : _c.length) !== 0
                : ((_d = e === null || e === void 0 ? void 0 : e.endTime) === null || _d === void 0 ? void 0 : _d.length) !== 0;
    };
    var bookedBadge = open
        ? (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items
            .filter(function (e) { return e.routes.map(function (e) { return e.origin.warehouse._id; }).includes(record.ramp._id); })
            .filter(bookedFilter).length
        : (transportations.data.find(function (e) { return e.date === date; }) || { items: [] }).items.filter(bookedFilter).length;
    var currentDayMoment = moment(date);
    var _holidays = holidays.data.filter(function (e) {
        return moment(toDateOnly(e.start)).isSameOrBefore(currentDayMoment) &&
            moment(toDateOnly(e.end)).isSameOrAfter(currentDayMoment) &&
            e.warehouse._id === record.ramp._id;
    });
    var container = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', className: clsx((_b = {}, _b[classesV.opened] = open, _b)) },
        React.createElement(Grid, { className: clsx(classesV.rootV1), container: true, direction: 'column', style: { maxWidth: 'calc(100% - 40px)', padding: theme.spacing(1, 0) } },
            React.createElement(TextOverflow, { v4: true, variant: 'subtitle1' }, record.ramp.name),
            React.createElement(TextOverflow, { v4: true, variant: 'caption' }, record.ramp.description)),
        (total > 1 && show && (React.createElement(BaseTooltip, { title: langApp.t(open ? 'collapse_less' : 'collapse_more') },
            React.createElement(IconButton, { onClick: onToggleShow, className: classes.buttonV1, style: { width: 24, height: 24 } }, open ? React.createElement(ArrowBackIosIcon, null) : React.createElement(ArrowForwardIosIcon, null))))) ||
            null));
    var notBookedContent = notBookedBadge && (React.createElement(BaseTooltip, { interactive: true, title: React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
            React.createElement(TextOverflow, { primary: true }, langApp.t('app_total_not_booked_orders')),
            React.createElement(PopupDayRampsDetails, __assign({}, {
                dayTransportations: nonBookedTransportations,
                config: props.config,
                currentDay: date,
                ramps: (_d = (_c = calendarState === null || calendarState === void 0 ? void 0 : calendarState.ramps) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.filter(function (e) { var _a; return props === null || props === void 0 ? void 0 : props.rampsId.includes((_a = e._id) === null || _a === void 0 ? void 0 : _a.toString()); }),
                onTogglePopup: function () { return 1; },
                noneWorkingTime: [],
                history: props.history,
            }))) },
        React.createElement("div", null, notBookedBadge)));
    var bookedContent = bookedBadge && (React.createElement(BaseTooltip, { title: React.createElement(TextOverflow, { primary: true }, langApp.t('app_total_booked_orders')) },
        React.createElement("div", null, bookedBadge)));
    return (React.createElement("th", { style: __assign({ top: 60, padding: 0 }, style), className: classesV.root, "data-Id": props.index },
        (_holidays.length && React.createElement(DayHolidayDetails, { holidays: _holidays })) || null,
        (bookedBadge && notBookedBadge && (React.createElement(StyledBadgeV15, { badgeContent: notBookedContent },
            React.createElement(StyledBadgeV4, { badgeContent: bookedContent }, container)))) ||
            (bookedBadge && React.createElement(StyledBadgeV4, { badgeContent: bookedContent }, container)) ||
            (notBookedBadge && React.createElement(StyledBadgeV16, { badgeContent: notBookedContent }, container)) ||
            container));
});
