var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
var initialState = {
    _id: 0,
    client: null,
    list: [],
    newUpdates: 0,
    newDocumentSigned: 0,
    updatedAt: 0,
};
var OfferTimeBookingStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = OfferTimeBookingStore.Provider, Consumer = OfferTimeBookingStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["REVIEW"] = "REVIEW";
    ACTIONS["LOADED"] = "LOADED";
})(ACTIONS || (ACTIONS = {}));
export var OfferTimeBookingProvider = function (_a) {
    var children = _a.children, client = _a.client;
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), { client: client, _id: Math.random() });
    var _b = __read(useReducer(function (state, action) {
        var payload = action.payload;
        switch (action.type) {
            case ACTIONS.REVIEW: {
                return __assign(__assign({}, state), { newUpdates: 0 });
            }
            case ACTIONS.LOADED: {
                return __assign(__assign({}, state), payload);
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var review = function () {
        dispatch({
            type: ACTIONS.REVIEW,
        });
    };
    var events = client
        ? [
        // SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_ORDER_RESTORED,
        // SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_ORDER_CANCELED,
        // SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_CHANGED,
        // SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_BOOKED,
        // SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_CHANGED,
        // SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_BOOKED, //TODO: need only for related quote/warehouse, right now it will refresh from anywhere
        ].map(function (e) { return ({
            client: client,
            name: "action-" + e,
            callback: function (data) {
                var payload = { updatedAt: Date.now() };
                dispatch({
                    type: ACTIONS.LOADED,
                    payload: payload,
                });
            },
        }); })
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!client) {
            console.error('*******************OfferTimeBooking no client');
        }
        else {
            console.log('=======OfferTimeBookings listen');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!client) {
            console.error('*******************OfferTimeBooking un listen');
        }
        else {
            console.log('=======OfferTimeBookings un listen');
        }
    };
    var value = {
        state: state,
        listen: listen,
        unListen: unListen,
        review: review,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withOfferTimeBookingStore = function (Component) {
    return function (props) {
        var _a, _b, _c;
        return (React.createElement(Consumer, { key: ((_c = (_b = (_a = props.globalSocketState) === null || _a === void 0 ? void 0 : _a.auth) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c._id) || -1 }, function (storeProps) {
            return (React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps), { key: (storeProps.state && storeProps.state.updatedAt) || 1 })));
        }));
    };
};
