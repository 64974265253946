var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { applySnapshot, types } from 'mobx-state-tree';
import { MaybeEmptyModel } from '../stores/common';
import { OrderCargoProductType } from './OrderCargoProductType';
import { OrderCargoPalletType } from './OrderCargoPalletType';
import { OrderCargoPackage } from './OrderCargoPackage';
import { ORDER_CARGO_WEIGHT_MEASURE, OrderCargoSizeType } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderCargo = types
    .model('OrderCargo', {
    _id: types.optional(types.string, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    cargoId: types.maybeNull(types.string),
    orderTransportation: types.optional(types.model({ _id: types.optional(types.string, '') }), function () { return ({ _id: '' }); }),
    package: types.maybeNull(types.optional(MaybeEmptyModel(OrderCargoPackage), function () { return OrderCargoPackage.create(); })),
    palletType: types.maybeNull(types.optional(MaybeEmptyModel(OrderCargoPalletType), function () { return OrderCargoPalletType.create(); })),
    dimension: types.maybeNull(types.union.apply(types, __spread(Object.values(OrderCargoSizeType).map(function (e) { return types.literal(e); })))),
    productType: types.maybeNull(types.optional(MaybeEmptyModel(OrderCargoProductType), function () { return OrderCargoProductType.create(); })),
    isStackable: types.maybeNull(types.boolean),
    weightMeasure: types.maybeNull(types.optional(types.enumeration(is_USA_APP ? ORDER_CARGO_WEIGHT_MEASURE.Pounds : ORDER_CARGO_WEIGHT_MEASURE.Tonnes, __spread([
        'lb'
    ], Object.values(ORDER_CARGO_WEIGHT_MEASURE))), is_USA_APP ? ORDER_CARGO_WEIGHT_MEASURE.Pounds : ORDER_CARGO_WEIGHT_MEASURE.Tonnes)),
    taintedCode: types.maybeNull(types.optional(types.string, '')),
    volume: types.maybeNull(types.number),
    height: types.maybeNull(types.number),
    weight: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
