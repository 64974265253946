export var onPreventEvent = function (e) {
    if (!e)
        return false;
    if (e.persist) {
        e.persist();
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
};
