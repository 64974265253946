export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["MANUFACTURING_DRAFT"] = "manufacturing_draft";
    OrderStatus["MANUFACTURING_CONFIRMED"] = "manufacturing_confirmed";
    OrderStatus["MANUFACTURING_IN_PROCESS"] = "manufacturing_in_process";
    OrderStatus["WaitingQuotes"] = "requested";
    OrderStatus["HasQuotes"] = "quoted";
    OrderStatus["AcceptedQuote"] = "accepted";
    OrderStatus["ApprovedQuote"] = "approved";
    OrderStatus["Finished"] = "finished";
    OrderStatus["Draft"] = "Draft";
})(OrderStatus || (OrderStatus = {}));
