var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { types, applySnapshot, flow, getSnapshot } from 'mobx-state-tree';
import { toast } from 'react-toastify';
import { AsyncActionStatus, BaseUser } from './common';
import { CheckEmailExists, UpdateCurrentUser } from '../graphql/user';
import { ToggleAllVehicleTypes, UpdateUserSettings } from '../graphql/userSettings';
import { i18n, KEYS } from '../i18n';
import { cloneDeep } from '../lodashUtils';
export var CurrentUserFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, BaseUser, types.model({
        isPreventLogin: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        updateUser: flow(function (input) {
            var result, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCurrentUser,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_user')));
                        return [2 /*return*/, result.data.updateUser];
                    case 2:
                        e_1 = _a.sent();
                        toast.error((e_1 && e_1.message) || e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        refresh: flow(function (updates) {
            return __generator(this, function (_a) {
                Object.assign(self, updates);
                return [2 /*return*/];
            });
        }),
        update: flow(function (input, opt) {
            var userSnap, result, userSnap, userSnap, e_2;
            if (opt === void 0) { opt = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!self._id) {
                            userSnap = __assign(__assign({}, getSnapshot(self)), input);
                            return [2 /*return*/, applySnapshot(self, userSnap)];
                        }
                        if (!!opt.locally) return [3 /*break*/, 2];
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCurrentUser,
                                variables: {
                                    input: __assign(__assign({}, input), { _id: self._id }),
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        userSnap = __assign({}, cloneDeep(result.data.updateUser));
                        applySnapshot(self, userSnap);
                        if (!opt.withoutToast) {
                            toast.success(i18n
                                .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'profile_label')));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        userSnap = __assign(__assign({}, getSnapshot(self)), input);
                        return [2 /*return*/, applySnapshot(self, userSnap)];
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        toast.error((e_2 && e_2.message) || e_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }),
        updateSettings: flow(function (input, setting, opt, withoutLogging) {
            var result, userSettingsSnap, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateUserSettings,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        userSettingsSnap = __assign(__assign({}, getSnapshot(self)), { settings: __assign({}, cloneDeep(result.data.updateUserSettings)) });
                        applySnapshot(self, __assign(__assign({}, userSettingsSnap), opt));
                        if (!withoutLogging) {
                            toast.success(i18n
                                .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                .replace(/_X_/, setting || i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_settings_title')));
                        }
                        return [2 /*return*/, result.data.updateUserSettings];
                    case 2:
                        e_3 = _a.sent();
                        toast.error((e_3 && e_3.message) || e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateSettingsLocally: flow(function (input) {
            var userSettingsSnap;
            return __generator(this, function (_a) {
                try {
                    userSettingsSnap = __assign(__assign({}, getSnapshot(self)), { settings: __assign(__assign({}, self.settings), input) });
                    applySnapshot(self, __assign({}, userSettingsSnap));
                }
                catch (e) {
                    toast.error((e && e.message) || e);
                }
                return [2 /*return*/];
            });
        }),
        checkEmailExists: flow(function (email) {
            var res, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.query({
                                query: CheckEmailExists,
                                variables: {
                                    email: email,
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.checkEmailExists];
                    case 2:
                        e_4 = _a.sent();
                        toast.error((e_4 && e_4.message) || e_4);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        toggleAllVehicleTypes: flow(function (input) {
            var result, userSettingsSnap, e_5;
            if (input === void 0) { input = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: ToggleAllVehicleTypes,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        userSettingsSnap = __assign(__assign({}, getSnapshot(self)), { settings: __assign({}, cloneDeep(result.data.toggleAllVehicleTypes)) });
                        applySnapshot(self, userSettingsSnap);
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_settings_title')));
                        return [2 /*return*/, result.data.toggleAllVehicleTypes];
                    case 2:
                        e_5 = _a.sent();
                        toast.error((e_5 && e_5.message) || e_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        clear: function (opt) {
            var snap = getSnapshot(CurrentUserFactory({ client: client }).create(opt));
            applySnapshot(self, snap);
        },
        reset: function (snapshot) {
            applySnapshot(self, snapshot);
        },
    }); })
        .named('CurrentUser');
};
