var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderCargoSizeType;
(function (OrderCargoSizeType) {
    OrderCargoSizeType["MM"] = "mm";
    OrderCargoSizeType["CM"] = "cm";
    OrderCargoSizeType["M"] = "m";
    OrderCargoSizeType["Inch"] = "inch";
})(OrderCargoSizeType || (OrderCargoSizeType = {}));
if (is_USA_APP) {
    var list = ['MM', 'CM', 'M'];
    for (var i = 0; i < list.length; i++) {
        // delete OrderCargoSizeType[list[i]];
    }
}
export var OrderCargoType;
(function (OrderCargoType) {
    OrderCargoType["FullTrackLoad"] = "FullTrackLoad";
    OrderCargoType["PTLTrackLoad"] = "PTLTrackLoad";
})(OrderCargoType || (OrderCargoType = {}));
export var ORDER_CARGO_WEIGHT_MEASURE;
(function (ORDER_CARGO_WEIGHT_MEASURE) {
    ORDER_CARGO_WEIGHT_MEASURE["Tonnes"] = "t";
    ORDER_CARGO_WEIGHT_MEASURE["Kilogram"] = "kg";
    ORDER_CARGO_WEIGHT_MEASURE["Gram"] = "g";
    ORDER_CARGO_WEIGHT_MEASURE["Pounds"] = "lbs";
})(ORDER_CARGO_WEIGHT_MEASURE || (ORDER_CARGO_WEIGHT_MEASURE = {}));
export var OrderInProgressStatus;
(function (OrderInProgressStatus) {
    OrderInProgressStatus["AssignedTransport"] = "AssignedTransport";
    OrderInProgressStatus["AssignedLoadingTime"] = "AssignedLoadingTime";
    OrderInProgressStatus["AssignedInTransit"] = "AssignedInTransit";
    OrderInProgressStatus["AssignedUnLoadingTime"] = "AssignedUnLoadingTime";
    OrderInProgressStatus["AssignedUnLoaded"] = "AssignedUnLoaded";
})(OrderInProgressStatus || (OrderInProgressStatus = {}));
export var OrderCurrency;
(function (OrderCurrency) {
    OrderCurrency["USD"] = "USD";
    OrderCurrency["CAD"] = "CAD";
    OrderCurrency["EUR"] = "EUR";
    OrderCurrency["RUB"] = "RUB";
    OrderCurrency["BYR"] = "BYR";
    OrderCurrency["KZT"] = "KZT";
    OrderCurrency["PLN"] = "PLN";
    OrderCurrency["UAH"] = "UAH";
    OrderCurrency["UZS"] = "UZS";
})(OrderCurrency || (OrderCurrency = {}));
if (is_USA_APP) {
    try {
        Object.keys(OrderCurrency).forEach(function (key) {
            if (!['USD', 'CAD'].includes(key))
                delete OrderCurrency[key];
        });
    }
    catch (e) { }
}
export var SYNC_ACTION_TYPE = {
    VALIDATE_ADDRESS: 1,
    CREATE_SHIPMENT: 3,
    REMOVE_SHIPMENT: 4,
    VALIDATE_TRANSPORT: 2,
    VALIDATE_PICKUP_DATE: 5,
    VALIDATE_DELIVERY_DATE: 6,
};
