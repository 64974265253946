var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { Grid, useMediaQuery } from '@material-ui/core';
import { FormikAutocomplete, formikAutocompleteMultiOptionsProps } from '../../components/Formik';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useChipStyles } from '../../styles/form';
import { i18n, KEYS } from '../../i18n';
import { CircularIndeterminate, FormikSingleDatePicker, TextOverflow, FormikAutocompleteAsync } from '../../components';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { theme } from '../../theme';
import { toDateOnly } from '../../utils';
import { FormikSliderRange } from '../../components/Formik/FormikSliderRange';
import BaseChip from '../../components/BaseChip';
import { FetchOrders } from '../../graphql';
export var getLangInvoiceStatus = function (status) {
    switch (status) {
        case 'PAID': {
            return i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_payment_paid');
        }
        case 'UNPAID': {
            return i18n.getResource(i18n.language, KEYS.FORM, 'form_invoice_status_unpaid');
        }
        case 'DRAFT': {
            return i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_Draft_as_template');
        }
    }
};
export var InvoiceFilters = function (props) {
    var filter = props.filter, onChange = props.onChange, _a = props.options, inputStyles = _a.inputStyles, baseFiltersContent = _a.baseFiltersContent, selectedSearch = _a.selectedSearch, config = props.config, defaultFilter = props.defaultFilter, emptyFilter = props.emptyFilter, statsQuery = props.statsQuery;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(null), 2), stats = _c[0], setStats = _c[1];
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var classesChip = useChipStyles();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var initialValues = {
        totals: [0, 1],
        startDate: null,
        endDate: null,
        order: [],
        process: [],
    };
    var orders = filter.fields.find(function (e) { return e.name === 'order'; });
    var status = filter.fields.find(function (e) { return e.name === 'status'; });
    var process = filter.fields.find(function (e) { return e.name === 'process'; });
    var optionsClients = ((stats && stats.clients) || []).filter(function (e) { return e._id; });
    var optionsSellers = ((stats && stats.sellers) || []).filter(function (e) { return e._id; });
    var optionsTypes = ((stats && stats.types) || []).filter(function (e) { return e._id; });
    if (orders) {
        Object.assign(initialValues, {
            order: orders.value.map(function (e) {
                return {
                    label: [e.orderId, e.status].filter(function (e) { return e; }).join(','),
                    value: e,
                };
            }),
        });
    }
    var companies = [
        {
            name: 'seller_id',
            options: optionsSellers,
        },
        {
            name: 'client_id',
            options: optionsClients,
        },
        {
            name: 'type',
            label: langForm.t('form_invoice_type_invoice'),
            options: optionsTypes,
        },
    ];
    if (stats) {
        initialValues.totals = [stats.rangeTotal.min, stats.rangeTotal.max];
        var totals = filter.fields.find(function (e) { return e.name === 'totals'; });
        if (totals)
            initialValues.totals = totals.value;
        if (status) {
            Object.assign(initialValues, {
                status: stats.status
                    .filter(function (e) { return status.value.includes(e._id); })
                    .map(function (e) {
                    return {
                        label: getLangInvoiceStatus(e._id) + "(" + e.count + ")",
                        value: e._id,
                    };
                }),
            });
        }
        companies.forEach(function (_a) {
            var _b;
            var name = _a.name, options = _a.options;
            var data = filter.fields.find(function (e) { return e.name === name; });
            Object.assign(initialValues, __assign({}, (data && (_b = {},
                _b[name] = options
                    .filter(function (e) { return data.value.includes(e._id._id); })
                    .map(function (e) {
                    return {
                        label: e._id.name + "(" + e.count + ")",
                        value: e._id._id,
                    };
                }),
                _b))));
        });
    }
    var loadStats = useCallback(function () {
        var fetchStats = function () {
            return __awaiter(this, void 0, void 0, function () {
                var data, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 3, 4, 5]);
                            setLoading(true);
                            _a = config;
                            if (!_a) return [3 /*break*/, 2];
                            return [4 /*yield*/, config.client.query({
                                    variables: { filter: defaultFilter },
                                    query: statsQuery,
                                })];
                        case 1:
                            _a = (_b.sent());
                            _b.label = 2;
                        case 2:
                            data = (_a).data;
                            setStats(data[Object.keys(data)[0]]);
                            return [3 /*break*/, 5];
                        case 3:
                            e_1 = _b.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 5];
                        case 4:
                            setLoading(false);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        };
        fetchStats();
    }, [statsQuery]);
    React.useEffect(function () {
        loadStats();
    }, [loadStats]);
    var startProps = { minDate: new Date(0) };
    var endProps = { minDate: new Date(0) };
    if (initialValues.endDate) {
        startProps.maxDate = new Date(initialValues.endDate);
    }
    if (initialValues.startDate) {
        endProps.minDate = new Date(initialValues.startDate);
    }
    var _onChangeDate = function (e, formik) {
        var _a = e.target, name = _a.name, value = _a.value;
        var existValue = (filter.fields || []).filter(function (e) { return e.name !== name; });
        if (value)
            existValue.push({
                name: name,
                value: toDateOnly(value),
            });
        onChange({
            target: {
                name: 'fields',
                value: existValue,
            },
        });
        formik.setFieldValue(name, value);
    };
    ['endDate', 'startDate'].forEach(function (e, index) {
        var _a, _b;
        var val = filter.fields.find(function (item) { return item.name === e; });
        if (val) {
            Object.assign(initialValues, (_a = {}, _a[e] = val.value, _a));
        }
        else if (defaultFilter.fields.find(function (e) { return e.name === e; })) {
            Object.assign(initialValues, (_b = {}, _b[e] = defaultFilter.fields.find(function (e) { return e.name === e; }).value, _b));
        }
    });
    var processes = [
        {
            label: i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_un_loaded'),
            value: 'unloaded-orders',
        },
        {
            label: i18n.getResource(i18n.language, KEYS.APP, 'not_sent'),
            value: 'no-sent',
        },
    ];
    if (process) {
        initialValues.process = process.value.map(function (e) { return processes.find(function (f) { return f.value === e; }); });
    }
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: function (e) { return e; }, key: JSON.stringify(filter) + "-" + JSON.stringify(stats) }, function (formik) {
        var _a, _b;
        var generalStyles = { minWidth: 130 };
        var _orderProps = formikAutocompleteMultiOptionsProps(formik.values.order, function () { return 0; });
        delete _orderProps.autocompleteProps.freeSolo;
        return (React.createElement(Form, null,
            React.createElement(Grid, { container: true, direction: "row" },
                React.createElement(Grid, { item: true, container: true, alignItems: 'flex-end', style: __assign(__assign({}, inputStyles), { width: isSmall ? undefined : 'initial' }) },
                    React.createElement(Grid, { container: true, direction: 'column', style: isSmall ? {} : { width: 'initial', maxWidth: 140 } },
                        React.createElement(FormikSingleDatePicker, __assign({ v1: true, v14: true }, startProps, { name: "startDate", help: langForm.t('form_invoice_from_issue_date'), variant: "outlined", title: langForm.t('form_invoice_issue_date_invoice'), placeholder: langForm.t('form_invoice_issue_date_invoice'), value: formik.values.startDate, onClose: function (value) { return _onChangeDate({ target: { name: 'startDate', value: value } }, formik); } }))),
                    !isSmall ? (React.createElement(ArrowRightAltIcon, { style: { margin: theme.spacing(0, 1), height: 30, color: theme.palette.common.white } })) : null,
                    React.createElement(Grid, { container: true, direction: 'column', style: isSmall ? {} : { width: 'initial', maxWidth: 140 } },
                        React.createElement(FormikSingleDatePicker, __assign({ v1: true, v14: true }, endProps, { name: "endDate", variant: "outlined", title: langForm.t('form_invoice_term'), help: langForm.t('form_invoice_from_to_term'), placeholder: langForm.t('form_invoice_term'), value: formik.values.endDate, onClose: function (value) { return _onChangeDate({ target: { name: 'endDate', value: value } }, formik); } })))),
                baseFiltersContent,
                loading ? (React.createElement(CircularIndeterminate, null)) : ((stats && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, container: true, alignItems: 'center', style: isSmall ? { marginRight: theme.spacing(1) } : __assign(__assign({}, inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocomplete, { v14: true, v17: true, onChageText: function () { return 0; }, autocompleteProps: {
                                    multiple: true,
                                    value: formik.values.status,
                                    className: clsx((_a = {},
                                        _a[classesChip.chipParentRootActive] = formik.values.status && formik.values.status.length,
                                        _a)),
                                    onChange: function (e) {
                                        var value = (filter.fields || []).filter(function (e) { return e.name !== 'status'; });
                                        if (Array.isArray(e.target.value) && e.target.value.length) {
                                            value.push({
                                                name: 'status',
                                                value: e.target.value.map(function (e) { return e.value; }),
                                            });
                                        }
                                        onChange({
                                            target: {
                                                name: 'fields',
                                                value: value,
                                            },
                                        });
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.value) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values.status)) {
                                            var values_1 = formik.values.status.map(function (e) { return e && (e.value || e); });
                                            return res.filter(function (e) { return e && !values_1.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    freeSolo: false,
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) {
                                            return option && (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index }), { color: 'primary', className: clsx(classesChip.chipRoot, classesChip.chipRootActive) })));
                                        });
                                    },
                                }, title: langForm.t('form_status'), variant: "outlined", name: 'status', style: __assign({}, generalStyles), value: formik.values.status, options: stats.status.map(function (e) { return ({
                                    value: e._id,
                                    label: getLangInvoiceStatus(e._id) + "(" + e.count + ")",
                                }); }) }))),
                    companies.map(function (_a) {
                        var _b;
                        var name = _a.name, options = _a.options, label = _a.label;
                        return (React.createElement(Grid, { item: true, container: true, alignItems: 'center', style: isSmall ? { marginRight: theme.spacing(1) } : __assign(__assign({}, inputStyles), { width: 'initial' }), key: name },
                            React.createElement(Grid, { container: true, direction: 'column', style: isSmall ? {} : { width: 'initial' } },
                                React.createElement(FormikAutocomplete, { v14: true, v17: true, onChageText: function () { return 0; }, style: __assign({}, generalStyles), autocompleteProps: {
                                        className: clsx((_b = {},
                                            _b[classesChip.chipParentRootActive] = formik.values[name] && formik.values[name].length,
                                            _b)),
                                        multiple: true,
                                        value: formik.values[name],
                                        onChange: function (e) {
                                            var value = (filter.fields || []).filter(function (e) { return e.name !== name; });
                                            if (Array.isArray(e.target.value) && e.target.value.length) {
                                                value = value.filter(function (e) { return !(filter.fields || []).map(function (f) { return f.name; }).includes(e.name); });
                                                value.push({
                                                    name: name,
                                                    value: e.target.value.map(function (e) { return e.value; }),
                                                });
                                            }
                                            var v = __spread((filter.fields || []).filter(function (e) { return e.name !== name; }), (emptyFilter.fields || []).filter(function (field) { return !(filter.fields || []).map(function (e) { return e.name; }).includes(field.name); })).filter(function (e) { return e; });
                                            if (v.length)
                                                value.push.apply(value, __spread(v));
                                            onChange({
                                                target: {
                                                    name: 'fields',
                                                    value: value,
                                                },
                                            });
                                        },
                                        filterOptions: function (options, params) {
                                            var fields = (options.length && Object.keys(options[0])) || [];
                                            var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                            var res = filterText && fields.length
                                                ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.value) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().match(filterText); })
                                                : options; //filterOptions(options, params);
                                            if (Array.isArray(formik.values[name])) {
                                                var values_2 = formik.values[name].map(function (e) { return e && (e.value || e); });
                                                return res.filter(function (e) { return e && !values_2.includes(e.value); });
                                            }
                                            return res;
                                        },
                                        getOptionLabel: function (option) { return option && option.label; },
                                        renderOption: function (option) { return option && option.label; },
                                        freeSolo: false,
                                        renderTags: function (value, getTagProps) {
                                            var re = (Array.isArray(value) && value) || [];
                                            return re.map(function (option, index) {
                                                return option && (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index }), { color: 'primary', className: clsx(classesChip.chipRoot, classesChip.chipRootActive) })));
                                            });
                                        },
                                    }, title: label || langForm.t("form_invoice_" + name.replace(/_id/, '')), variant: "outlined", name: name, value: formik.values[name], options: options.map(function (_a) {
                                        var _id = _a._id, count = _a.count;
                                        return ({
                                            label: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
                                                React.createElement(TextOverflow, null, _id.name),
                                                React.createElement(TextOverflow, null, count))),
                                            value: _id._id,
                                        });
                                    }) }))));
                    }),
                    React.createElement(Grid, { item: true, container: true, alignItems: 'center', style: __assign(__assign({}, inputStyles), { width: !isSmall ? 'initial' : undefined }) },
                        React.createElement(Grid, { container: true, direction: 'column' },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, v14: true, v17: true, filterFields: ['orderId'] }, _orderProps, { query: FetchOrders, variables: {
                                    filter: {
                                        fields: [
                                            {
                                                name: 'all-approved',
                                                value: 1,
                                            },
                                        ],
                                    },
                                }, client: config.client, title: langApp.t('main_orders'), placeholder: langApp.t('main_orders'), name: "order", variant: "outlined", multiple: true, value: formik.values.order, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'order'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'order',
                                            value: e.target.value.map(function (e) { return e.value || e; }),
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } })))),
                    (!props.isTemplate && (React.createElement(Grid, { item: true, container: true, alignItems: 'center', style: __assign(__assign({}, inputStyles), { width: !isSmall ? 'initial' : undefined }) },
                        React.createElement(Grid, { container: true, direction: 'column', style: { marginRight: theme.spacing(1) } },
                            React.createElement(FormikAutocomplete, { v14: true, v17: true, v19: true, style: { minWidth: 130 }, onChageText: function () { return 0; }, autocompleteProps: {
                                    multiple: true,
                                    value: formik.values.process,
                                    className: clsx((_b = {},
                                        _b[classesChip.chipParentRootActive] = formik.values.process && formik.values.process.length,
                                        _b)),
                                    onChange: function (e) {
                                        var value = (filter.fields || []).filter(function (e) { return e.name !== 'process'; });
                                        if (Array.isArray(e.target.value) && e.target.value.length) {
                                            value.push({
                                                name: 'process',
                                                value: e.target.value.map(function (e) { return e.value; }),
                                            });
                                        }
                                        onChange({
                                            target: {
                                                name: 'fields',
                                                value: value,
                                            },
                                        });
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.value) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values.process)) {
                                            var values_3 = formik.values.process.map(function (e) { return e && (e.value || e); });
                                            return res.filter(function (e) { return e && !values_3.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    freeSolo: false,
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) {
                                            return option && (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index }), { color: 'primary', style: __assign({}, (re.length > 1 ? { marginRight: theme.spacing(0.5) } : {})), className: clsx(classesChip.chipRoot, classesChip.chipRootActive) })));
                                        });
                                    },
                                }, title: langApp.t('main_process'), help: langApp.t('main_process_help'), variant: "outlined", name: 'process', value: formik.values.process, options: processes }))))) ||
                        null,
                    React.createElement(Grid, { item: true, container: true, alignItems: 'center', style: __assign(__assign({}, inputStyles), { minWidth: !isSmall ? 220 : undefined, width: !isSmall ? 'initial' : undefined, paddingLeft: !isSmall ? theme.spacing(1) : 0 }) },
                        React.createElement(Grid, { container: true, direction: 'column', className: 'input-label', style: { marginRight: theme.spacing(1) } },
                            React.createElement(FormikSliderRange, { v14: true, max: stats.rangeTotal.max, title: langForm.t('form_invoice_total_sum'), variant: "outlined", name: "totals", value: formik.values.totals, style: { paddingBottom: 0, paddingTop: theme.spacing(1) }, onChangeCommitted: function (e, value) {
                                    var _value = [{ name: 'totals', value: value }];
                                    var v = __spread((filter.fields || []).filter(function (e) { return e.name !== 'totals'; }), (emptyFilter.fields || []).filter(function (field) { return !(filter.fields || []).map(function (e) { return e.name; }).includes(field.name); })).filter(function (e) { return e; });
                                    if (v.length)
                                        _value.push.apply(_value, __spread(v));
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: _value,
                                        },
                                    });
                                } }),
                            React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: { marginTop: theme.spacing(0.9) } },
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(TextOverflow, { variant: 'caption' }, formik.values.totals[0])),
                                React.createElement(Grid, { item: true, xs: 6, container: true, justify: 'flex-end' },
                                    React.createElement(TextOverflow, { variant: 'caption' }, formik.values.totals[1])))))))) ||
                    null),
                selectedSearch)));
    }));
};
