var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Upload } from '../../models/Upload';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { AddFormDialog } from './AddFormDialog';
import { getSnapshot } from 'mobx-state-tree';
import { inject } from 'mobx-react';
import { AddFormButton } from '../AddFormButton';
import { useTranslation } from 'react-i18next';
import { KEYS, i18n } from '../../i18n';
import { toast } from 'react-toastify';
import { uploadEvent } from '../../api/apolloClient';
import { NotifyOnUploadFinish, UpdateUpload } from '../../graphql';
var tempNewItem = JSON.parse(JSON.stringify(getSnapshot(Upload.create())));
var lastChanged = 1;
export var UploadList = inject('store')(function (_a) {
    var config = _a.config, parent = _a.parent, _b = _a.folder, folder = _b === void 0 ? '' : _b, parent_collection_name = _a.parent_collection_name, onChange = _a.onChange, accountStore = _a.store.accountStore, record = _a.record, props = __rest(_a, ["config", "parent", "folder", "parent_collection_name", "onChange", "store", "record"]);
    if (props.isPreparation)
        parent_collection_name = 'orders';
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(0), 2), progress = _d[0], setProgress = _d[1];
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var isPlaceholder = !(record === null || record === void 0 ? void 0 : record.path);
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    /*eslint-disable */
    React.useEffect(function () {
        Object.assign(tempNewItem, { parent: parent, folder: folder }, (parent_collection_name && { parent_collection_name: parent_collection_name }) || null);
        return function () {
            tempNewItem = JSON.parse(JSON.stringify(getSnapshot(Upload.create())));
        };
    }, []);
    /*eslint-enable */
    if (!accountStore.currentUser._id) {
        return null;
    }
    var change = function (e, opt) {
        if (opt === void 0) { opt = {}; }
        if (Date.now() - lastChanged < 1000 * 4)
            return;
        lastChanged = Date.now();
        onChange && onChange(e, opt);
    };
    var onUploadFiles = function (_a, opt) {
        var _b, _c, _d;
        if (opt === void 0) { opt = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var details, uploads, changeData, updates, input, newFile_1, _e, newFiles, _loop_1, i, e_1, error;
            var _id = _a._id, createdBy = _a.createdBy, updatedAt = _a.updatedAt, files = _a.files, document = __rest(_a, ["_id", "createdBy", "updatedAt", "files"]);
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        document.users = (document.users || []).map(function (_a) {
                            var company = _a.company, _b = _a.user, encoded = _b.encoded, cleanUser = __rest(_b, ["encoded"]);
                            return ({
                                user: cleanUser,
                                company: company,
                            });
                        });
                        if (!(files === null || files === void 0 ? void 0 : files.length))
                            return [2 /*return*/, console.warn('no files')];
                        details = '';
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 15, 16, 17]);
                        setLoading(true);
                        setProgress(0);
                        uploads = [];
                        changeData = null;
                        if (!(record && !(files[0].file && isPlaceholder))) return [3 /*break*/, 7];
                        updates = {};
                        if (record === null || record === void 0 ? void 0 : record._id)
                            uploads.push(record._id);
                        if (!files[0].file) return [3 /*break*/, 3];
                        input = __assign(__assign(__assign(__assign(__assign(__assign({}, ['access', 'users', 'emails', 'sendEmail', 'type']
                            .filter(function (e) { return document && document[e]; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = document[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), (props.isBatch && { isBatch: props.isBatch })), (props.lastActiveStep && { orderPreparingStep: props.lastActiveStep })), ((record === null || record === void 0 ? void 0 : record._id) && { _id: record._id })), (_id && { _id: _id })), { type: (_b = document.type) === null || _b === void 0 ? void 0 : _b._id, folder: folder, isUserAction: true, file: files[0].file, needSign: !!files[0].needSign });
                        return [4 /*yield*/, uploadEvent(input, function (progress) { return setProgress(progress.toFixed(2)); })];
                    case 2:
                        newFile_1 = _f.sent();
                        uploads.push(newFile_1._id);
                        updates = __assign({}, ['path', 'name', 'mimeType', 'needSign', 'encoding', 'size']
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = newFile_1[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        return [3 /*break*/, 4];
                    case 3:
                        if (files[0].path === record.path) {
                            updates = {
                                needSign: !!files[0].needSign,
                            };
                        }
                        _f.label = 4;
                    case 4:
                        _e = config;
                        if (!_e) return [3 /*break*/, 6];
                        return [4 /*yield*/, config.client.mutate({
                                mutation: UpdateUpload,
                                variables: {
                                    input: __assign(__assign(__assign({ _id: record._id }, ['access', 'emails', 'users', 'sendEmail', 'type']
                                        .map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = document[e], _a);
                                    })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { type: (_c = document.type) === null || _c === void 0 ? void 0 : _c._id, folder: folder }), updates),
                                },
                            })];
                    case 5:
                        _e = (_f.sent());
                        _f.label = 6;
                    case 6:
                        _e;
                        return [3 /*break*/, 12];
                    case 7:
                        newFiles = [];
                        _loop_1 = function (i) {
                            var input, item, keys, newFile;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        input = Object.assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, ['access', 'users', 'emails', 'sendEmail', 'type', '_id']
                                            .filter(function (e) { return document && document[e]; })
                                            .map(function (e) {
                                            var _a;
                                            return (_a = {}, _a[e] = document[e], _a);
                                        })
                                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { type: (_d = document.type) === null || _d === void 0 ? void 0 : _d._id, parent: parent }), (parent_collection_name && { parent_collection_name: parent_collection_name })), { folder: folder }), ((record === null || record === void 0 ? void 0 : record._id) && { _id: record._id })), (_id && { _id: _id })), { isUserAction: true }), (props.isBatch && { isBatch: props.isBatch })), (props.lastActiveStep && { orderPreparingStep: props.lastActiveStep })), { file: files[i].file, needSign: !!files[i].needSign }));
                                        item = void 0;
                                        keys = [
                                            '_id',
                                            'isUserAction',
                                            'updatedAt',
                                            'size',
                                            'access',
                                            'name',
                                            'parent_collection_name',
                                            'parent',
                                            'path',
                                            'isAssigned',
                                            'createdBy',
                                            'type',
                                        ];
                                        details = files[i].name;
                                        return [4 /*yield*/, uploadEvent(input, function (progress) {
                                                setProgress(((i / files.length) * progress).toFixed(2));
                                            })];
                                    case 1:
                                        newFile = _a.sent();
                                        item = __assign({}, keys.map(function (e) {
                                            var _a;
                                            return (_a = {}, _a[e] = newFile[e], _a);
                                        }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                        if (item.createdBy && !item.createdBy._id)
                                            item.createdBy = { _id: item.createdBy._id };
                                        uploads.push(item._id);
                                        files[i]._uid = item._id;
                                        newFiles.push(item);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _f.label = 8;
                    case 8:
                        if (!(i < files.length)) return [3 /*break*/, 11];
                        return [5 /*yield**/, _loop_1(i)];
                    case 9:
                        _f.sent();
                        _f.label = 10;
                    case 10:
                        i++;
                        return [3 /*break*/, 8];
                    case 11:
                        changeData = newFiles;
                        _f.label = 12;
                    case 12:
                        if (!(!props.noNotify && props.isBatch && uploads.length)) return [3 /*break*/, 14];
                        return [4 /*yield*/, (config === null || config === void 0 ? void 0 : config.client.mutate({
                                mutation: NotifyOnUploadFinish,
                                variables: {
                                    input: {
                                        uploads: uploads,
                                    },
                                },
                            }))];
                    case 13:
                        _f.sent();
                        _f.label = 14;
                    case 14:
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_file')));
                        change(changeData, opt);
                        return [3 /*break*/, 17];
                    case 15:
                        e_1 = _f.sent();
                        console.log('===', e_1);
                        error = (e_1.message || 'Not able to upload the file') + (". " + details);
                        toast.error(error);
                        throw error;
                    case 16:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    var onFresh = function () { return change(null); };
    return (React.createElement(Grid, { container: true, alignItems: 'center', style: __assign(__assign({}, (isSmall ? { justifyContent: 'center' } : {})), { position: 'relative' }) },
        React.createElement(AddFormDialog, __assign({}, props, { loading: loading, progress: progress, config: config, parent_collection_name: parent_collection_name, onFresh: onFresh, onChange: onUploadFiles, record: record, actionInit: props.actionInit ||
                (function (onShowModal) { return (React.createElement(AddFormButton, { onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') })); }) }))));
});
