import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../i18n';
import { theme } from '../../../../theme';
import { TextOverflow } from '../../../TextOverflow';
export default function ChatTotalMessages(_a) {
    var state = _a.state;
    var langApp = useTranslation(KEYS.APP);
    return ((state.count && (React.createElement(TextOverflow, { variant: 'caption', style: {
            position: 'absolute',
            bottom: 0,
            padding: theme.spacing(0, 0.5),
            right: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(1),
            zIndex: 1,
            background: theme.palette.common.white,
        } },
        state.data.length,
        "/",
        state.count,
        " ",
        langApp.t('message')))) ||
        null);
}
