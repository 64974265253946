(function () {
    if (![].toReversed) {
        Array.prototype.toReversed = function () {
            var self = this;
            for (var i = self.length - 1, arr = []; i >= 0; --i) {
                arr.push(self[i]);
            }
            return arr;
        };
    }
})();
export var dummy = {};
