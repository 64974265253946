var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import moment from 'moment';
import { Grid, Button, useMediaQuery, Box, IconButton, SwipeableDrawer } from '@material-ui/core';
import { MonthView } from './MonthViewV1';
import { PrevNextButton } from '../Buttons/PrevNextButton';
import { theme } from '../../theme';
import { CALENDAR_MODE } from './types';
import { CalendarProvider, withCalendarStore } from './store';
import { DaysView } from './DaysView';
import { CalendarFilters } from './Filters';
import { useCalendarStyles } from './style';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { withRouter } from 'react-router-dom';
import { getQueryParams, globalUtils, isMobileView, toDateOnly } from '../../utils';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseSingleDatePicker } from '../DatePicker';
import { TextOverflow } from '../TextOverflow';
import { BaseSelect, FormikAutocompleteAsync } from '../Formik';
import PreloadingComponent from '../PreloadingComponent';
import MenuCopyCurrentLocation from './Filters/MenuCopyCurrentLocation';
import { inject } from 'mobx-react';
var MobileMonthView = React.lazy(function () { return import('./MobileMonthView'); });
var FormFilters = React.lazy(function () { return import('./Filters/FormFilters'); });
var BookingNewOrder = React.lazy(function () { return import('./BookingNewOrder'); });
import SettingsIcon from '@material-ui/icons/Settings';
import { SOUND_KEY } from '../Sounds';
import clsx from 'clsx';
import { useSettingsStyles } from '../Settings/styles';
import { browserStorage } from '../../browserStorage';
import { BaseUserAppTYpe } from '../../stores';
import { FetchOrders } from '../../graphql';
import { Form, Formik } from 'formik';
import { OrderRouteType } from '../../models';
var CalendarSettingsContent = React.lazy(function () { return import('./CalendarSettingsContent'); });
export var getDefaultCalendarMode = function (isPublic) {
    if (isPublic === void 0) { isPublic = false; }
    return (isPublic && CALENDAR_MODE.day) || CALENDAR_MODE.week;
};
export var SAVABLE_CALENDAR_FIELDS = ['mode', 'ramps'];
var BaseCalendarComponent = withRouter(function (_a) {
    var _b, _c;
    var _d = _a.state, loading = _d.loading, isPublic = _d.isPublic, storeData = __rest(_d, ["loading", "isPublic"]), history = _a.history, config = _a.config, store = _a.store, props = __rest(_a, ["state", "history", "config", "store"]);
    var warehouseStore = store.warehouseStore;
    var classes = useCalendarStyles();
    var langApp = useTranslation(KEYS.APP);
    var isSmall = useMediaQuery(theme.breakpoints.down('sm')) || isMobileView();
    var isMiddle = useMediaQuery(theme.breakpoints.down('md'));
    var query = getQueryParams(history.location.search);
    var classesSettings = useSettingsStyles();
    var modes = [
        // { label: 'Year', name: 'year' },
        { label: langApp.t('calendar_label_month'), name: CALENDAR_MODE.month },
        !isSmall && { label: langApp.t('calendar_label_week'), name: CALENDAR_MODE.week },
        { label: langApp.t('calendar_label_day'), name: CALENDAR_MODE.day },
    ].filter(function (e) { return e; });
    var _e = __read(React.useState(new Date(query.date || Date.now())), 1), current = _e[0];
    var _f = __read(React.useState(1), 2), updatedAt = _f[0], setUpdated = _f[1];
    var _g = __read(React.useState({
        right: false,
    }), 2), drawerState = _g[0], setDrawerState = _g[1];
    var localStoreKey = "calendar-v2-" + props.match.params.warehouseId;
    var search = browserStorage.getUserItem(localStoreKey);
    var defaultMode = getDefaultCalendarMode();
    React.useEffect(function () {
        if (!history.location.search.length) {
            if (!search && storeData.ramps.count) {
                search = new URLSearchParams({
                    mode: defaultMode,
                    ramps: storeData.ramps.data.map(function (e) { return e._id; }) || '',
                }).toString();
            }
            if (search) {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + search,
                });
            }
        }
    }, [storeData.ramps.count]);
    var clearOrderSearch = function () {
        var params = __assign({}, getQueryParams(history.location.search));
        if (params.order) {
            delete params.order;
            history.push({
                pathname: history.location.pathname,
                search: '?' +
                    new URLSearchParams(__assign({}, params)).toString(),
            });
        }
    };
    React.useEffect(function () {
        var _a, _b;
        if (((_a = storeData.transportations.data) === null || _a === void 0 ? void 0 : _a.length) && ((_b = query.order) === null || _b === void 0 ? void 0 : _b.length)) {
            var order = storeData.transportations.data
                .map(function (e) { return e.items; })
                .reduce(function (a, b) { return __spread(a, b); }, [])
                .map(function (e) { return e.order; })
                .find(function (e) { var _a; return ((_a = e._id) === null || _a === void 0 ? void 0 : _a.toString()) === query.order; });
            if (order) {
                setValues(__assign(__assign({}, values), { order: order }));
            }
            else {
                clearOrderSearch();
            }
        }
    }, [query.order, storeData.transportations.count]);
    var toggleDrawer = function (anchor, open) { return function (event) {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState(function (prevState) {
            var _a;
            return __assign(__assign({}, prevState), (_a = {}, _a[anchor] = open, _a));
        });
        if (open) {
            globalUtils.sounds.play(SOUND_KEY.MODAL_SETTINGS_OPEN);
        }
        else {
            globalUtils.sounds.play(SOUND_KEY.MODAL_CLOSE);
        }
    }; };
    var _h = __read(React.useState((modes.map(function (e) { return e.name; }).includes(query.mode) && query.mode) || defaultMode), 2), mode = _h[0], setMode = _h[1];
    var _j = __read(React.useState({}), 2), values = _j[0], setValues = _j[1];
    React.useEffect(function () {
        if (isSmall && !modes.find(function (e) { return e.name === mode; })) {
            setMode(CALENDAR_MODE.month);
        }
    }, [isSmall]);
    var onMove = function (e, dir) {
        var v;
        switch (mode) {
            case CALENDAR_MODE.year: {
                v = moment(current).add(dir, 'year').toDate();
                break;
            }
            case CALENDAR_MODE.month: {
                v = moment(current).add(dir, 'month').toDate();
                break;
            }
            case CALENDAR_MODE.week: {
                v = moment(current).add(dir, 'weeks').toDate();
                break;
            }
            case CALENDAR_MODE.day: {
                v = moment(current).add(dir, 'days').toDate();
                break;
            }
        }
        if (v) {
            history.push({
                pathname: history.location.pathname,
                search: '?' +
                    new URLSearchParams(__assign(__assign({}, getQueryParams(history.location.search)), { date: toDateOnly(v).replace(/\//gi, '-') })).toString(),
            });
        }
    };
    var onReset = function () {
        history.push({
            pathname: history.location.pathname,
            search: '',
        });
    };
    var onChangeDate = function (date) {
        history.push({
            pathname: history.location.pathname,
            search: '?' +
                new URLSearchParams(__assign(__assign({}, getQueryParams(history.location.search)), { date: toDateOnly(date).replace(/\//gi, '-') })).toString(),
        });
    };
    var onSwitchMode = function (e) {
        var _a, _b;
        var _c;
        var query = getQueryParams(history.location.search);
        history.push({
            pathname: history.location.pathname,
            search: '?' +
                new URLSearchParams(__assign(__assign({}, query), (_a = {}, _a[e.target.name] = e.target.value.name, _a))).toString(),
        });
        if (warehouseStore.item) {
            var localStoreKey_1 = "calendar-v2-" + ((_c = warehouseStore.item) === null || _c === void 0 ? void 0 : _c._id);
            var storageValue = new URLSearchParams(__assign(__assign({}, SAVABLE_CALENDAR_FIELDS.map(function (field) {
                var _a;
                return (_a = {},
                    _a[field] = query[field] || '',
                    _a);
            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), (_b = {}, _b[e.target.name] = e.target.value.name, _b))).toString();
            browserStorage.setUserItem(localStoreKey_1, storageValue);
        }
    };
    var onChangeMode = function (e, mode, day) {
        history.push({
            pathname: history.location.pathname,
            search: '?' +
                new URLSearchParams(__assign(__assign({}, getQueryParams(history.location.search)), { mode: mode, date: toDateOnly(day).replace(/\//gi, '-') })).toString(),
        });
    };
    var onUpdate = function () {
        setUpdated(Date.now());
    };
    var currentMode = modes.find(function (e) { return e.name === mode; });
    var mainStyle = { marginRight: theme.spacing(1) };
    var isForwarder = store.accountStore.currentCompany.type === BaseUserAppTYpe.FORWARDER;
    var isCarrier = store.accountStore.currentCompany.type === BaseUserAppTYpe.CARRIER;
    var isForwarderForManager = isForwarder &&
        warehouseStore.item.manager.forwarders.find(function (e) { return e._id.toString() === store.accountStore.currentUser._id.toString(); });
    var settingsComponent = !isForwarderForManager && !isPublic && (React.createElement(IconButton, { onClick: toggleDrawer('right', true) },
        React.createElement(SettingsIcon, null)));
    var rampFilters = (React.createElement(Grid, { container: true, alignItems: 'center', justify: isSmall ? 'flex-start' : 'center' },
        React.createElement(TextOverflow, { color: 'primary', style: { marginRight: theme.spacing(2) } }, langApp.t('warehouse_ramps')),
        React.createElement(Grid, { container: true, style: { width: 'initial' } },
            React.createElement(PreloadingComponent, null,
                React.createElement(FormFilters, { config: config, isPublic: isPublic })))));
    var warehouseNameComponent = (React.createElement(TextOverflow, { style: { color: theme.palette.secondary['900'], fontWeight: 600 } }, warehouseStore.item.name));
    var baseFilters = (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
        React.createElement(PrevNextButton, { v1: true, label: langApp.t('calendar_label_today'), nextLabel: (langApp.t('calendar_label_next_period') || '').replace(/_X_/gi, currentMode === null || currentMode === void 0 ? void 0 : currentMode.label), prevLabel: langApp.t('calendar_label_previous_period').replace(/_X_/gi, currentMode === null || currentMode === void 0 ? void 0 : currentMode.label), onChange: onMove, onReset: onReset, style: mainStyle },
            React.createElement(BaseSingleDatePicker, { value: current, defaultValue: current, v1: true, v2: true, inputFormat: "DD MMM YYYY", style: { width: 'initial' }, minDate: new Date(0), onChange: onChangeDate }),
            React.createElement(Box, { style: { height: 25, borderLeft: "3px solid " + theme.palette.secondary.dark, borderRadius: 1 } }),
            React.createElement(Button, { onClick: onReset, color: 'primary', style: __assign({ textTransform: 'capitalize' }, mainStyle) },
                React.createElement(TextOverflow, { variant: 'subtitle1', style: { textDecoration: 'underline' } }, langApp.t('calendar_label_today'))))));
    var modesContent = (React.createElement(Grid, { container: true, style: __assign({ width: 'initial' }, (isSmall && { margin: theme.spacing(0.5, 0) })), alignItems: 'center', justify: isSmall ? 'flex-start' : 'flex-end' }, (!props.isPublic && !isSmall && React.createElement(CalendarFilters, { style: mainStyle, mode: mode })) || null));
    var modeSelectComponent = (React.createElement(BaseSelect, { style: __assign({ width: 'initial' }, (!isSmall && { minWidth: 100 })), margin: "dense", variant: "outlined", name: 'mode', onChange: onSwitchMode, options: modes.map(function (e) { return (__assign(__assign({}, e), { value: e })); }), value: modes.find(function (e) { return e.name === mode; }) }));
    var orderSearch = (!isCarrier && (React.createElement(Grid, { style: __assign({ minWidth: 'fit-content', zIndex: 0 }, (isForwarder && { marginRight: theme.spacing(3) })) },
        React.createElement(Formik, { initialValues: values, onSubmit: function (e) { return e; }, key: ((_c = (_b = values === null || values === void 0 ? void 0 : values.order) === null || _b === void 0 ? void 0 : _b._id) === null || _c === void 0 ? void 0 : _c.toString()) || '' }, function (formik) {
            return (React.createElement(Form, null,
                React.createElement(FormikAutocompleteAsync, { v17: true, title: langApp.t('main_order'), variant: "outlined", type: "text", margin: 'dense', fullWidth: true, name: "order", client: config.client, query: FetchOrders, filterFields: ['orderId'], variables: {
                        filter: {
                            fields: [{ name: 'warehouse-search', value: storeData.ramps.data.map(function (e) { return e._id; }) }],
                        },
                    }, autocompleteProps: {
                        value: formik.values.order,
                        getOptionSelected: function (option, value) {
                            var _a, _b;
                            return ((_a = option._id) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = value._id) === null || _b === void 0 ? void 0 : _b.toString());
                        },
                        getOptionLabel: function (option) { return [option.templateName || option.orderId].filter(function (e) { return e; }).join('. '); },
                    }, onChange: function (e, value) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                        if ((value === null || value === void 0 ? void 0 : value._id) && ((_a = value === null || value === void 0 ? void 0 : value.transportations) === null || _a === void 0 ? void 0 : _a.length)) {
                            var firstTr = value.transportations[0];
                            var lastTr = value.transportations[value.transportations.length - 1];
                            var pickupRampId = (_d = (_c = (_b = firstTr.routes
                                .find(function (e) { return e.type === OrderRouteType.Preferred; })
                                .origin) === null || _b === void 0 ? void 0 : _b.warehouse) === null || _c === void 0 ? void 0 : _c._id) === null || _d === void 0 ? void 0 : _d.toString();
                            var rampsIds = storeData.ramps.data.map(function (e) { var _a; return (_a = e._id) === null || _a === void 0 ? void 0 : _a.toString(); });
                            var isPickup = rampsIds.includes(pickupRampId);
                            var rampId = '';
                            var date = '';
                            if (isPickup) {
                                rampId = (_g = (_f = (_e = firstTr.routes
                                    .find(function (e) { return e.type === OrderRouteType.Preferred; })
                                    .origin) === null || _e === void 0 ? void 0 : _e.warehouse) === null || _f === void 0 ? void 0 : _f._id) === null || _g === void 0 ? void 0 : _g.toString();
                                date = toDateOnly(firstTr.startDate).replace(/\//gi, '-');
                            }
                            else {
                                rampId = (_k = (_j = (_h = lastTr.routes
                                    .find(function (e) { return e.type === OrderRouteType.Delivery; })
                                    .origin) === null || _h === void 0 ? void 0 : _h.warehouse) === null || _j === void 0 ? void 0 : _j._id) === null || _k === void 0 ? void 0 : _k.toString();
                                date = toDateOnly(lastTr.endDate).replace(/\//gi, '-');
                            }
                            setValues(__assign(__assign({}, values), { order: value }));
                            formik.setFieldValue(e.target.name, value);
                            history.push({
                                pathname: history.location.pathname,
                                search: '?' +
                                    new URLSearchParams(__assign(__assign({}, getQueryParams(history.location.search)), { mode: CALENDAR_MODE.day, ramps: rampId, date: date, order: value._id })).toString(),
                            });
                        }
                    }, onBlur: function (e) {
                        var _a = e.target, value = _a.value, name = _a.name;
                        if (typeof value === 'string') {
                            if (values.order) {
                                formik.setFieldValue(name, values.order);
                            }
                            else {
                                formik.setFieldValue(name, '');
                            }
                        }
                    }, onClear: clearOrderSearch })));
        })))) ||
        null;
    var isWarehouseOwner = warehouseStore.item.company._id === store.accountStore.currentCompany._id;
    return (React.createElement(React.Fragment, null,
        React.createElement(PreloadingComponent, null,
            React.createElement(BookingNewOrder, __assign({}, props, { onChange: onUpdate, config: config }))),
        React.createElement(Grid, { className: classes.root, container: true, style: { position: 'relative' }, alignItems: 'flex-start', key: mode + current.toString() + updatedAt },
            loading && (React.createElement(Grid, { container: true, justify: "center", alignItems: 'center', style: {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: '100%',
                    background: 'rgba(255,255,255,0.7)',
                    zIndex: 1,
                } },
                React.createElement(CircularIndeterminate, null))),
            isSmall ? (React.createElement(React.Fragment, null,
                baseFilters,
                modesContent,
                rampFilters,
                mode === CALENDAR_MODE.month && (React.createElement(PreloadingComponent, null,
                    React.createElement(MobileMonthView, __assign({}, { mode: mode, current: current, config: config, onChangeDate: onChangeDate })))),
                (mode === CALENDAR_MODE.day && React.createElement(DaysView, __assign({}, { mode: mode, current: current, config: config }, { countDays: 1 }))) || null)) : (React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, alignItems: 'center' }, isMiddle ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 12, justify: "center" }, warehouseNameComponent),
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 6 }, baseFilters),
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 6, justify: "flex-end", alignItems: "center" },
                        orderSearch,
                        settingsComponent,
                        modeSelectComponent))) : (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 12, lg: 4 }, baseFilters),
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 12, lg: 4, justify: "center" }, warehouseNameComponent),
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 12, lg: 4, justify: "flex-end", alignItems: "center" },
                        orderSearch,
                        settingsComponent,
                        modeSelectComponent)))),
                React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center', style: { marginTop: theme.spacing(1) } },
                    (isWarehouseOwner && (React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: 4 },
                        React.createElement(MenuCopyCurrentLocation, { v1: true })))) ||
                        null,
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12, md: isWarehouseOwner ? 8 : 12, justify: "flex-end" }, modesContent)),
                React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center', style: { marginBottom: theme.spacing(1) } },
                    React.createElement(Grid, { container: true, item: true, xs: 12, sm: 12 }, rampFilters)),
                (mode === CALENDAR_MODE.month && (React.createElement(MonthView, __assign({}, { mode: mode, current: current, config: config, history: history }, { onChangeMode: onChangeMode })))) ||
                    null,
                (mode === CALENDAR_MODE.week && React.createElement(DaysView, __assign({}, { mode: mode, current: current, config: config, history: history }, { countDays: 7 }))) ||
                    null,
                (mode === CALENDAR_MODE.day && React.createElement(DaysView, __assign({}, { mode: mode, current: current, config: config, history: history }, { countDays: 1 }))) || null,
                React.createElement(SwipeableDrawer, { anchor: 'right', open: drawerState.right, PaperProps: { className: clsx(classesSettings.paper, classesSettings.rootV2) }, onClose: toggleDrawer('right', false), onOpen: function () { return 1; } }, drawerState.right ? (React.createElement(PreloadingComponent, null,
                    React.createElement(CalendarSettingsContent, __assign({}, props, { config: config, toggleDrawer: toggleDrawer, isPublic: isPublic })))) : null))))));
});
var BaseCalendarComponentStore = inject('store')(withCalendarStore(BaseCalendarComponent));
export var BaseCalendar = function (_a) {
    var options = _a.options, props = __rest(_a, ["options"]);
    return (React.createElement(CalendarProvider, __assign({}, options),
        React.createElement(BaseCalendarComponentStore, __assign({}, props))));
};
