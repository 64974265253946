var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { BASIC_DIALOG_ACTIONS, BasicDialog, onPreventEvent } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { OrderStatus } from '../../../models';
import { ACTION_CHANGE } from '../../BasicList';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { theme } from '../../../theme';
import { TextOverflow } from '../../TextOverflow';
import { ArchiveOrder } from '../../../graphql';
import ArchiveIcon from '@material-ui/icons/Archive';
import { FormikTextField } from '../../Formik';
import * as Yup from 'yup';
import { onlySpacesRegExp } from '../../../common';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Loader from '../../Loader';
function OrderArchiveComponent(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var config = _a.config, order = _a.order, store = _a.store, props = __rest(_a, ["config", "order", "store"]);
    var _k = store.accountStore, currentUser = _k.currentUser, currentCompany = _k.currentCompany;
    var formRef = React.useRef();
    var langToast = useTranslation(KEYS.TOAST);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var localStore = useLocalStore(function () { return ({
        error: '',
        order: null,
        isLoading: false,
        loadingOrder: false,
        onSubmit: function (_a) {
            var _input = __rest(_a, []);
            return __awaiter(this, void 0, void 0, function () {
                var res, _b, e_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 4, 5, 6]);
                            res = void 0;
                            _b = config;
                            if (!_b) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: ArchiveOrder,
                                    variables: {
                                        input: {
                                            order: order._id,
                                            comment: _input.comment,
                                        },
                                    },
                                })];
                        case 2:
                            _b = (_c.sent());
                            _c.label = 3;
                        case 3:
                            res = (_b).data.archiveOrder;
                            toast.success(langToast.t('toast_order_archived'));
                            if (props.onChange)
                                props.onChange(order, ACTION_CHANGE.DELETED);
                            return [2 /*return*/, res];
                        case 4:
                            e_1 = _c.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        onOpen: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (![OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status)) return [3 /*break*/, 1];
                            this.loadingOrder = true;
                            localStore.order = order;
                            this.loadingOrder = false;
                            return [3 /*break*/, 3];
                        case 1:
                            this.loadingOrder = true;
                            _a = localStore;
                            return [4 /*yield*/, store.orderStore.loadOrder(order._id, true)];
                        case 2:
                            _a.order = _b.sent();
                            this.loadingOrder = false;
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var canArchive = (((_c = (_b = order.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c._id) || ((_d = order.createdBy) === null || _d === void 0 ? void 0 : _d.company) || ((_e = order.company) === null || _e === void 0 ? void 0 : _e._id) || order.company) ===
        currentCompany._id ||
        (((_g = (_f = order.manager) === null || _f === void 0 ? void 0 : _f.company) === null || _g === void 0 ? void 0 : _g._id) || ((_h = order.manager) === null || _h === void 0 ? void 0 : _h.company) || ((_j = order.company) === null || _j === void 0 ? void 0 : _j._id) || order.company) ===
            currentCompany._id ||
        order.forwarders.find(function (forwarder) { var _a; return ((_a = forwarder === null || forwarder === void 0 ? void 0 : forwarder.company) === null || _a === void 0 ? void 0 : _a._id) === currentCompany._id; });
    if (order.publishedAt &&
        canArchive &&
        [OrderStatus.ApprovedQuote, OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status)) {
    }
    else {
        return null;
    }
    var isApproved = [OrderStatus.ApprovedQuote].includes(order.status);
    var onOk = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    res = _a.sent();
                    if (!res)
                        throw BASIC_DIALOG_ACTIONS.WAIT;
                    return [4 /*yield*/, localStore.onSubmit(res)];
                case 2:
                    _a.sent();
                    if (props.onChange)
                        props.onChange(order, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var steps = isApproved
        ? [
            {
                successText: langOfferForm.t('offer_un_loaded'),
                errorText: langOrderList.t('in_progress_has_not_un_loaded'),
                validate: function () { return !order.transportations.find(function (e) { return !e.unloadedAt; }); },
            },
            {
                successText: langOrderList.t('in_progress_has_cmr'),
                errorText: langOrderList.t('in_progress_has_not_cmr'),
                validate: function () { return order.sorting_hasCMR; },
            },
            {
                successText: langOrderList.t('in_progress_has_invoice'),
                errorText: langOrderList.t('in_progress_has_not_invoice'),
                validate: function () { return order.sorting_hasInvoice; },
            },
        ]
        : [];
    var dialogBody = localStore.loadingOrder ? (React.createElement(Loader, null)) : (localStore.order && (React.createElement(React.Fragment, null,
        React.createElement(Formik, { innerRef: formRef, initialValues: { comment: '' }, validationSchema: Yup.object({
                comment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: function (e) { return e; } }, function (formik) {
            return (React.createElement(Form, { id: 'archive-form', name: 'archive-form' },
                React.createElement(Grid, { container: true, direction: "column", style: { marginBottom: theme.spacing(2) } }, steps.map(function (e) {
                    var valid = e.validate();
                    return (React.createElement(Grid, { key: e.successText, container: true, alignItems: 'center' },
                        valid ? (React.createElement(CheckCircleOutlineIcon, { style: {
                                color: theme.palette.success.light,
                            } })) : (React.createElement(HighlightOffIcon, { style: {
                                color: theme.palette.error.main,
                            } })),
                        React.createElement(TextOverflow, { style: {
                                maxWidth: 'calc(100% - 40px)',
                                color: valid ? theme.palette.success.light : theme.palette.error.main,
                            } }, valid ? e.successText : e.errorText)));
                })),
                React.createElement(Grid, { container: true, direction: "column", style: { marginBottom: theme.spacing(2) } },
                    React.createElement(FormikTextField, { autoFocus: true, multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment }))));
        }))));
    var title = langOrderList.t('order_list_archive_order');
    var dialogTitle = React.createElement(TextOverflow, { variant: 'h6' }, title);
    return ((config && (React.createElement(BasicDialog, { shouldNotPrevent: true, hidActionOkBth: !!steps.find(function (e) { return !e.validate(); }), onOk: onOk, onOpen: localStore.onOpen, body: dialogBody, title: dialogTitle, dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { onClick: handleClickOpen, id: 'btn-order-archive' },
                    React.createElement(ArchiveIcon, { color: 'primary' }))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen }));
            return icon;
        } }))) ||
        null);
}
export default inject('store')(observer(OrderArchiveComponent));
