var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, USER_BASE_I, ORDER_LESS_BASE } from './base';
export var USER_RATING_BASE = "\n " + BASE_QUERY + "\n       comment\n       approvedAt\n       options{\n            name\n            value\n       }\n       approvedAt\n       user{\n            " + USER_BASE_I + "\n       }\n";
export var FetchUserOrderRatings = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query userRatings($filter: MainFilter) {\n        userRatings(filter: $filter) {\n            data {\n                ", "\n                parent{\n                   ", "\n                }\n            }\n            count\n        }\n    }\n"], ["\n    query userRatings($filter: MainFilter) {\n        userRatings(filter: $filter) {\n            data {\n                ", "\n                parent{\n                   ", "\n                }\n            }\n            count\n        }\n    }\n"])), USER_RATING_BASE, ORDER_LESS_BASE);
export var CreateUserRating = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createUserRating($input: UserRatingInput!) {\n        createUserRating(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createUserRating($input: UserRatingInput!) {\n        createUserRating(input: $input) {\n            ", "\n        }\n    }\n"])), USER_RATING_BASE);
export var ApproveUserRating = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation approveUserRating($input: UserRatingInputUpdate!) {\n        approveUserRating(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation approveUserRating($input: UserRatingInputUpdate!) {\n        approveUserRating(input: $input) {\n            ", "\n        }\n    }\n"])), USER_RATING_BASE);
export var DeleteUserRating = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation deleteUserRating($_id: MongoId!) {\n    deleteUserRating(_id: $_id) {\n      status\n    }\n  }\n"], ["\n  mutation deleteUserRating($_id: MongoId!) {\n    deleteUserRating(_id: $_id) {\n      status\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
