import { types, castToSnapshot, applySnapshot } from 'mobx-state-tree';
import { AsyncActionStatus, BaseUser, MaybeEmptyModel } from '../stores/common';
import { BaseModel } from './BaseModel';
export var UploadItem = types
    .compose(AsyncActionStatus, types
    .model('UploadItem', {
    _id: types.optional(types.string, function () { return ''; }),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    size: types.maybeNull(types.optional(types.number, 0)),
    access: types.maybeNull(types.optional(types.array(types.string), [])),
    name: types.maybeNull(types.optional(types.string, '')),
    parent_collection_name: types.maybeNull(types.optional(types.string, '')),
    parent: types.maybeNull(types.union(types.optional(types.string, ''), MaybeEmptyModel(BaseModel))),
    path: types.maybeNull(types.optional(types.string, '')),
    isAssigned: types.maybeNull(types.optional(types.boolean, false)),
    createdBy: types.maybeNull(types.union(MaybeEmptyModel(BaseModel), MaybeEmptyModel(BaseUser))),
    type: types.maybeNull(MaybeEmptyModel(BaseModel)),
})
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(UploadItem.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); })
    .views(function (self) { return ({}); }))
    .named('UploadItem');
