var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { WarehouseCreateEditForm } from './Form';
import { useLocalStore } from 'mobx-react-lite';
import { inject, observer } from 'mobx-react';
import { WarehouseListItem } from './ListItem';
import { KEYS } from '../../i18n';
import { FetchWarehouses } from '../../graphql';
import { BaseList, BaseListItem } from '../../components';
import { Warehouse } from '../../models/Warehouse';
import { getSnapshot } from 'mobx-state-tree';
import { BaseUserAppTYpe } from '../../stores/common';
import { theme } from '../../theme';
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
var WarehousePage = inject('store')(observer(function (_a) {
    var store = _a.store, config = _a.config, isTab = _a.isTab;
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langMenu = useTranslation(KEYS.MENU);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var localStore = useLocalStore(function () { return ({
        key: 1,
        onChange: function () {
            localStore.key = Date.now();
        },
    }); });
    var defaultFilter = {
        fields: [
            { name: 'status', value: ['no generated'] },
            { name: 'onlyList', value: 1 },
        ],
    };
    var baseListProps = __assign(__assign({}, config), { defaultFilter: defaultFilter, query: FetchWarehouses, searchFields: [
            'name',
            'address.area',
            'address.country',
            'address.postal',
            'address.streetName',
            'address.state',
            'address.streetNumber',
        ], sortFields: [
            { name: langOrderList.t('order_list_filter_sort_created_at'), value: 'createdAt' },
            { name: langForm.t('form_input_name'), value: 'name' },
            { name: langForm.t('form_input_name_emails'), value: 'emails' },
        ] });
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var actionTab = [];
    if (!isTab) {
        actionTab.push({
            size: 1,
            name: langForm.t('form_input_actions'),
            style: { display: 'flex', justifyContent: isSmall ? 'flex-start' : 'flex-end' },
        });
    }
    var style = { padding: theme.spacing(0, isSmall ? 0 : 1) };
    var headerColumns = __spread([
        { size: 2, name: langForm.t('form_input_name_warehouse'), sortable: true, sortKey: 'name' },
        { size: isTab ? 4 : 4, name: langForm.t('form_warehouse_address') },
        { size: isTab ? 4 : 3, name: langForm.t('form_warehouse_emails'), sortable: true, sortKey: 'emails', style: style },
        { size: 2, name: langForm.t('form_warehouse_manager'), style: style }
    ], actionTab);
    var canCreateEdit = [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.WAREHOUSE].find(function (t) {
        return store.accountStore.currentUser.appType.includes(t);
    });
    var _config = {
        id: langMenu.t('menu_sidebar_warehouse'),
        title: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between' },
            React.createElement(BaseListTitle, { title: langMenu.t('menu_sidebar_warehouse') }),
            React.createElement("div", null, (canCreateEdit && (React.createElement(WarehouseCreateEditForm, { item: getSnapshot(Warehouse.create()), onChange: localStore.onChange, config: config }))) ||
                null))),
        noData: (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(5), flexDirection: 'column', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'subtitle1', style: { margin: theme.spacing(2, 0), textAlign: 'center' } }, canCreateEdit ? langForm.t('form_no_warehouse_text') : langForm.t('form_no_warehouse_short_text')),
            (canCreateEdit && (React.createElement(WarehouseCreateEditForm, { item: getSnapshot(Warehouse.create()), onChange: localStore.onChange, config: config }))) ||
                null)),
        hideSort: !isSmall,
        hasHeaderSort: true,
        searchMDSize: 4,
        headerColumns: headerColumns,
        rowContent: function (record, onChange) {
            return (React.createElement(BaseListItem, { record: record },
                React.createElement(WarehouseListItem, { headerColumns: headerColumns, isTab: isTab, config: config, record: record, onChange: onChange })));
        },
    };
    return React.createElement(BaseList, { key: localStore.key, options: baseListProps, config: _config });
}));
export default WarehousePage;
