var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { BasicDialog, BASIC_DIALOG_ACTIONS, FormikAutocomplete, formikAutocompleteMultiOptionsProps, FormikTextField, TextOverflow, } from '../../../components';
import { Form, Formik } from 'formik';
import { BaseUserAppTYpe } from '../../../stores';
import DocumentItemVisibleTo from './DocumentItemVisibleTo';
import InputTitle from '../../../components/Formik/InputTitle';
import { documentInitialValues } from '../../../components/Uploads/utils';
import { inject } from 'mobx-react';
import UploadType from '../dialogs/UploadType';
import clsx from 'clsx';
import { getSnapshot } from 'mobx-state-tree';
import { UploadItem } from '../../../models/UploadItem';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { RequestDocument } from '../../../graphql';
import { getUsersFromAccess } from '../../../utils2';
var useStyles = makeStyles(function (theme) { return ({
    form: {
        padding: theme.spacing(2),
    },
}); });
export default inject('store')(function (_a) {
    var item = _a.item, currentUser = _a.store.accountStore.currentUser, defaultValue = _a.defaultValue, props = __rest(_a, ["item", "store", "defaultValue"]);
    var _b = __read(React.useState({}), 2), updates = _b[0], setUpdates = _b[1];
    var _c = __read(React.useState({}), 2), localValues = _c[0], setLocalValues = _c[1];
    var langToast = useTranslation(KEYS.TOAST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var theme = useTheme();
    var classes = useStyles();
    var langMenu = useTranslation(KEYS.MENU);
    var formRef = React.useRef();
    var onSubmit = function (model) { return model; };
    var order = props.quote.order;
    var allAvailableUsers = documentInitialValues(order, currentUser, props).users;
    if (!defaultValue)
        defaultValue = getSnapshot(UploadItem.create());
    var _d = __read(React.useState(__assign(__assign(__assign(__assign({}, defaultValue), { files: [], access: (item === null || item === void 0 ? void 0 : item.access) || __spread(getSnapshot(currentUser).appType), users: getUsersFromAccess(allAvailableUsers, item) || [], emails: [] }), ((item === null || item === void 0 ? void 0 : item.type) ? { type: item === null || item === void 0 ? void 0 : item.type } : {})), { comment: '' })), 2), initialValues = _d[0], setInitialValues = _d[1];
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem) {
                        throw Error(BASIC_DIALOG_ACTIONS.WAIT);
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, props.config.client.mutate({
                            mutation: RequestDocument,
                            variables: {
                                input: __assign(__assign({ _id: item._id }, ['emails', 'comment']
                                    .map(function (e) {
                                    var _a;
                                    return (_a = {}, _a[e] = createdItem[e], _a);
                                })
                                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { users: createdItem['users'].map(function (user) {
                                        return { email: user.user.email };
                                    }), type: createdItem['type']._id }),
                            },
                        })];
                case 3:
                    _a.sent();
                    toast.success(langToast.t('toast_items_request_document'));
                    setLocalValues({});
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(Grid, { container: true },
        React.createElement(Formik, { innerRef: formRef, initialValues: initialValues, validationSchema: Yup.object({
                emails: Yup.array()
                    .typeError(langForm
                    .t('form_input_validate_required_list')
                    .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                    .of(Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .email(langForm.t('form_input_validate_invalid_email')))
                    .max(10, langForm
                    .t('form_input_validate_field_must_have_no_more')
                    .replace('_X_', '10')
                    .replace('_XX_', langForm.t('form_input_name_emails').toLowerCase())),
                type: Yup.object({
                    _id: Yup.string(),
                }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                    return !!val._id;
                }),
                comment: Yup.string().required(langForm.t('form_input_validate_required')),
            }), onSubmit: onSubmit }, function (formik) {
            var _onChange = function (e) {
                var _a;
                var _b = e.target, value = _b.value, name = _b.name;
                if (name === 'access') {
                    var users = getUsersFromAccess(allAvailableUsers, { access: value });
                    formik.setFieldValue('users', users);
                    formik.setFieldValue(name, value);
                }
                else if (name === 'sendEmail') {
                    formik.setFieldValue(name, !formik.values[name]);
                }
                else {
                    formik.setFieldValue(name, value);
                }
                if (['emails'].includes(name) && typeof value !== 'string') {
                    setLocalValues(__assign(__assign({}, localValues), (_a = {}, _a[name] = value, _a)));
                    formik.validateField(e.target.name);
                    if (formik.errors[e.target.name]) {
                        return formik.setFieldTouched(e.target.name);
                    }
                }
            };
            var dataUser = formikAutocompleteMultiOptionsProps(formik.values.users, null, null, [], {
                disabled: true,
            });
            var onChangeUploadType = function (val) {
                formik.setFieldValue('type', val);
                try {
                    var list_1 = [];
                    order.transportations.forEach(function (e) {
                        var item = e.routes
                            .filter(function (e) { return e.origin.locationAdvanced.find(function (e) { return e.documentType && e.documentType._id; }); })
                            .map(function (e) { return e.origin.locationAdvanced.filter(function (e) { return e.documentType && e.documentType._id; }); })
                            .reduce(function (a, b) { return __spread(a, b); }, []);
                        if (item && item.length) {
                            list_1.push.apply(list_1, __spread(item));
                        }
                    });
                    list_1 = list_1.filter(function (e) { return e.documentType._id === val._id; });
                    if (list_1.length) {
                        var access = __spread(new Set(__spread((formik.values.access || []), list_1.map(function (e) { return e.access || []; }).reduce(function (a, b) { return __spread(a, b); }))));
                        list_1 = __spread((formik.values.emails || []), list_1.map(function (e) { return e.email; }).reduce(function (a, b) { return __spread(a, b); })).filter(function (e) { return e; });
                        formik.setFieldValue('access', access);
                        formik.setFieldValue('emails', list_1);
                        formik.setFieldValue('updatedAt', new Date().toString());
                    }
                }
                catch (e) {
                    // console.log(order);
                    // console.log(e, order);
                }
            };
            var onBlur = function (e) {
                var _a;
                var options = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    options[_i - 1] = arguments[_i];
                }
                var _b = e.target, value = _b.value, name = _b.name;
                var _value = [];
                if (['emails'].includes(name)) {
                    if (typeof formik.values[name] !== 'string') {
                        _value = formik.values[name];
                        _onChange({ target: { name: name, value: _value } });
                    }
                    else {
                        var newValue = value.trim();
                        _value = __spread((localValues[name] || []), [newValue]);
                        if (newValue !== '') {
                            _onChange({ target: { name: name, value: _value } });
                        }
                    }
                }
                setUpdates(__assign(__assign({}, updates), (_a = {}, _a[name] = Date.now(), _a)));
            };
            var onDeleteEmail = function (email, name) {
                var _a;
                var value = formik.values[name].filter(function (e) { return e !== email; });
                _onChange({ target: { name: name, value: value } });
                setUpdates(__assign(__assign({}, updates), (_a = {}, _a[name] = Date.now(), _a)));
            };
            var styles = { marginBottom: theme.spacing(2) };
            return (React.createElement(Form, { className: clsx(classes.form), id: 'form-document-request' },
                React.createElement(Grid, { container: true, direction: "column", style: styles },
                    React.createElement(UploadType, { title: langForm.t('form_input_upload_type'), name: "type", defaultValue: formik.values.type, config: props.config, onChange: onChangeUploadType, autocompleteProps: { multiline: true } })),
                ![BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (e) { return currentUser.appType.includes(e); }) ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { container: true, direction: "column", key: JSON.stringify(formik.values.access) || 1, style: styles },
                        React.createElement(FormikAutocomplete, __assign({}, dataUser, { disabled: true, name: "users", onChange: _onChange, value: formik.values.users, help: langToast.t('toast_users_to_notify_help'), title: langToast.t('toast_users_to_notify') }))),
                    React.createElement(Grid, { container: true, direction: "column", key: formik.values.updatedAt || '', style: styles },
                        React.createElement(FormikAutocomplete, __assign({}, formikAutocompleteMultiOptionsProps(formik.values.emails, onBlur, function (email) { return onDeleteEmail(email, 'emails'); }, []), { key: updates['emails'], name: "emails", placeholder: formik.values.emails
                                ? langForm.t('form_input_name_emails')
                                : langForm.t('form_input_name_emails_placeholder'), onChange: _onChange, value: formik.values.emails, help: langToast.t('toast_email_to_notify_help'), title: langToast.t('toast_email_to_notify') }))),
                    props.parent_collection_name === 'order_transportations' ? null : (React.createElement(Grid, { container: true, key: formik.values.access, style: { marginBottom: theme.spacing(1) } },
                        React.createElement(InputTitle, { title: langOfferForm.t('offer_form_document_visible'), style: { width: 'initial', paddingRight: theme.spacing(1) } }),
                        React.createElement(DocumentItemVisibleTo, { quote: props.quote, isEditable: true, item: formik.values, record: formik.values, onChange: _onChange }))))) : null,
                React.createElement(Grid, { container: true, direction: "column", key: formik.values.updatedAt || '', style: styles },
                    React.createElement(FormikTextField, { multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment })),
                React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(1) } })));
        })));
    return (React.createElement(BasicDialog, { withoutWrapper: true, title: React.createElement(TextOverflow, null, langMenu.t('menu_request_document_title')), body: dialogBody, onOk: onClick, onClose: function () { return setLocalValues({}); }, dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: props.actionInit }));
});
