var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OrderStatus } from '../../../models/Order_additional';
import { CALENDAR_ELEMENTS } from '../types';
export var OrderRouteType;
(function (OrderRouteType) {
    OrderRouteType["Preferred"] = "Preferred";
    OrderRouteType["Delivery"] = "Delivery";
})(OrderRouteType || (OrderRouteType = {}));
export var orderDayDetailsMap = function (_a) {
    var hour = _a.hour, item = _a.item, noneWorkingDayTime = _a.noneWorkingDayTime, currentDay = _a.currentDay, dayTransportations = _a.dayTransportations, warehouse = _a.warehouse, ramps = _a.ramps, calendarElements = _a.calendarElements, classes = _a.classes, v1 = _a.v1, noneWorkingTime = _a.noneWorkingTime, query = _a.query, props = _a.props, i18n = _a.i18n, KEYS = _a.KEYS;
    var daysWithOrder = [];
    var loadIcon = null;
    var unLoadIcon = null;
    var _hour = parseFloat((hour || '').toString().replace(':', '.'));
    var _noneWorkingDayTime = (item &&
        item.ramp &&
        noneWorkingDayTime.data.filter(function (e) {
            var _start = parseFloat((e.time || '').toString().replace(':', '.'));
            return (e.warehouse._id === item.ramp._id &&
                e.date === currentDay &&
                (typeof hour === 'undefined' || ((_start || Infinity) <= _hour + 0.29 && _hour <= (_start || -1))));
        })) ||
        [];
    for (var i = 0; i < dayTransportations.length; i++) {
        var transportation = dayTransportations[i];
        var _id = transportation._id, startDate = transportation.startDate, endDate = transportation.endDate, order = transportation.order, endTime = transportation.endTime, startTime = transportation.startTime, routes = transportation.routes;
        var _start = startTime && startTime[0] && parseFloat((startTime[0] || '').toString().replace(':', '.'));
        var _end = endTime && endTime[0] && parseFloat((endTime[0] || '').toString().replace(':', '.'));
        if (!order) {
            continue;
        }
        var _routes = routes.filter(function (e) { return e.origin.warehouse && __spread([warehouse === null || warehouse === void 0 ? void 0 : warehouse._id], ramps.data.map(function (e) { return e._id; })).includes(e.origin.warehouse._id); });
        var pickUp = _routes.find(function (f) { return f.type === OrderRouteType.Preferred; });
        var delivery = _routes.find(function (f) { return f.type === OrderRouteType.Delivery; });
        var cancelled = order.status === OrderStatus.Finished && order.cancelComment;
        var avaiable = (cancelled && calendarElements.includes(CALENDAR_ELEMENTS.ARCHIVE)) || !cancelled; //|| 1;
        // if (currentDay === '2022/04/29') {
        // debugger;
        // }
        // if (currentDay === '2022/04/26' && _hour >= 9 && _hour <= 10) {
        //   debugger;
        // }
        // if ('CO.OS.00590' === order.orderId)
        //   console.log(order.orderId, cancelled, avaiable, calendarElements, startDate, endDate, currentDay);
        if (pickUp &&
            // !(order.noQuotes && endTime && endTime[0]) &&
            startDate === currentDay &&
            avaiable &&
            (typeof hour === 'undefined' || ((_start || Infinity) <= _hour + 0.29 && _hour <= (_start || -1))) &&
            calendarElements.includes((startTime && startTime[0] && CALENDAR_ELEMENTS.ORDERS_PICKUP) || CALENDAR_ELEMENTS.ORDERS_PLAN_PICKUP)) {
            // console.log(order.orderId, 'pickUp');
            daysWithOrder.push(__assign({ _id: _id + "-startDate", date: startDate, order: order,
                item: item,
                transportation: transportation, route: pickUp, icon: loadIcon, classes: (startTime && startTime[0] && classes.orders_pickup) || classes.orders_plan_pickup, orderSort: (startTime && startTime[0] && 1) || 3, title: (startTime && startTime[0] && i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_from_label')) ||
                    i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_from_label_plan') }, (startTime && startTime[0] && { time: startTime })));
        }
        if (delivery &&
            // !(order.noQuotes && startTime && startTime[0]) &&
            avaiable &&
            endDate === currentDay &&
            (typeof hour === 'undefined' || ((_end || Infinity) <= _hour + 0.29 && _hour <= (_end || -1))) &&
            calendarElements.includes((endTime && endTime[0] && CALENDAR_ELEMENTS.ORDERS_DELIVERY) || CALENDAR_ELEMENTS.ORDERS_PLAN_DELIVERY)) {
            // console.log(order.orderId, 'delivery');
            daysWithOrder.push(__assign({ _id: _id + "-endDate", date: endDate, order: order, route: delivery, item: item,
                transportation: transportation, icon: unLoadIcon, orderSort: (endTime && endTime[0] && 2) || 4, classes: (endTime && endTime[0] && classes.orders_delivery) || classes.orders_plan_delivery, title: (endTime && endTime[0] && i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_to_label')) ||
                    i18n.getResource(i18n.language, KEYS.OFFER_FORM, 'offer_to_label_plan') }, (endTime && endTime[0] && { time: endTime })));
        }
    }
    if (!daysWithOrder.length &&
        _noneWorkingDayTime.length &&
        calendarElements.includes(CALENDAR_ELEMENTS.NONE_WORKING_DAY)) {
        daysWithOrder.push.apply(daysWithOrder, __spread(_noneWorkingDayTime.map(function (e) {
            return ({
                _id: e._id + "-none-working",
                date: e.date,
                item: item,
                routes: [],
                record: e,
                order: { timeToSpend: e.timeToSpend, routes: [] },
                title: e.description,
                name: e.name,
                time: [e.time],
            });
        })));
    }
    var list = __spread(daysWithOrder, (v1 ? noneWorkingTime : [])).sort(function (a, b) {
        return a.time > b.time || (!a.time && a.orderSort > b.orderSort) ? 1 : -1;
    });
    var listTemp = list.filter(function (e, i) { return (props.maxOrderShort ? i < props.maxOrderShort : true); });
    var dayWithSearchOrder = daysWithOrder.find(function (e) { var _a, _b; return [(_b = (_a = e.order) === null || _a === void 0 ? void 0 : _a._id) === null || _b === void 0 ? void 0 : _b.toString()].includes(query.order); });
    if (props.maxOrderShort &&
        dayWithSearchOrder &&
        !listTemp.find(function (e, index) { var _a, _b; return index < (props.maxOrderShort || 5) && [(_b = (_a = e.order) === null || _a === void 0 ? void 0 : _a._id) === null || _b === void 0 ? void 0 : _b.toString()].includes(query.order); })) {
        listTemp.pop();
        listTemp.push(dayWithSearchOrder);
    }
    return listTemp;
};
