var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { COMPANY_TERM_BUDGET } from './base';
export var FetchCompanyTermBudget = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query companyTermBudget($filter: MainFilter) {\n        companyTermBudget(filter:$filter){\n         ", "\n    }\n  }\n"], ["\n    query companyTermBudget($filter: MainFilter) {\n        companyTermBudget(filter:$filter){\n         ", "\n    }\n  }\n"])), COMPANY_TERM_BUDGET);
export var FetchCompanyTermBudgetList = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query companyTermBudgets($filter: MainFilter) {\n        companyTermBudgets(filter:$filter){\n          data {\n            ", "\n          }\n          count\n    }\n  }\n"], ["\n    query companyTermBudgets($filter: MainFilter) {\n        companyTermBudgets(filter:$filter){\n          data {\n            ", "\n          }\n          count\n    }\n  }\n"])), COMPANY_TERM_BUDGET);
export var CreateCompanyTermBudget = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n mutation createCompanyTermBudget($input:CompanyTermBudgetInput!){\n     createCompanyTermBudget(input:$input) {\n      ", "\n    }\n  }\n"], ["\n mutation createCompanyTermBudget($input:CompanyTermBudgetInput!){\n     createCompanyTermBudget(input:$input) {\n      ", "\n    }\n  }\n"])), COMPANY_TERM_BUDGET);
export var UpdateCompanyTermBudget = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n mutation updateCompanyTermBudget($input:CompanyTermBudgetInputUpdate!){\n     updateCompanyTermBudget(input:$input) {\n      ", "\n    }\n  }\n"], ["\n mutation updateCompanyTermBudget($input:CompanyTermBudgetInputUpdate!){\n     updateCompanyTermBudget(input:$input) {\n      ", "\n    }\n  }\n"])), COMPANY_TERM_BUDGET);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
