export var CALENDAR_ELEMENTS = {
    HOLIDAYS: 'holidays',
    NONE_WORKING_DAY: 'none_working_days',
    ORDERS_PLAN_PICKUP: 'orders_plan_pickup',
    ORDERS_PLAN_DELIVERY: 'orders_plan_delivery',
    ORDERS_PICKUP: 'orders_pickup',
    ORDERS_DELIVERY: 'orders_delivery',
    ARCHIVE: 'cancelled',
};
export var CALENDAR_MODE = {
    year: 'year',
    month: 'month',
    week: 'week',
    day: 'day',
};
