var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { INVOICE_TYPE_BASE } from './base';
export var InvoiceTypes = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query invoiceTypes($filter: MainFilter) {\n        invoiceTypes(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query invoiceTypes($filter: MainFilter) {\n        invoiceTypes(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), INVOICE_TYPE_BASE);
export var CreateInvoiceType = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createInvoiceType($input:InvoiceTypeInput!){\n        createInvoiceType(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createInvoiceType($input:InvoiceTypeInput!){\n        createInvoiceType(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_TYPE_BASE);
export var UpdateInvoiceType = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateInvoiceType($input:InvoiceTypeInputUpdate!){\n        updateInvoiceType(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateInvoiceType($input:InvoiceTypeInputUpdate!){\n        updateInvoiceType(input:$input) {\n            ", "\n        }\n    }\n"])), INVOICE_TYPE_BASE);
export var DeleteInvoiceType = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteInvoiceType($_id: MongoId!) {\n        deleteInvoiceType(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteInvoiceType($_id: MongoId!) {\n        deleteInvoiceType(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
