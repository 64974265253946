var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { theme } from '../../../../../theme';
import { toast } from 'react-toastify';
import { ACTION_CHANGE } from '../../../../BasicList';
import { AcceptContraOrderQuote, DenyContraOrderQuote } from '../../../../../graphql/order';
import { BasicDialog } from '../../../../Dialog';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../../../i18n';
import OrderRouteMenuItem from '../../OrderRoutesMenuItem';
import BaseTooltip from '../../../../BaseTooltip';
import { priceValue } from '../../../../PriceComponent';
import { inject } from 'mobx-react';
import { MainButton } from '../../../../MainButton';
function OrderContraQuoteAccept(_a) {
    var _this = this;
    var quote = _a.quote, order = _a.order, onChange = _a.onChange, config = _a.config, props = __rest(_a, ["quote", "order", "onChange", "config"]);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var onOk = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, 6, 7]);
                    setLoading(true);
                    if (!config) return [3 /*break*/, 4];
                    if (!props.isDeny) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.query({
                            query: DenyContraOrderQuote,
                            variables: {
                                parentId: quote.parent._id,
                            },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, config.client.mutate({
                        mutation: AcceptContraOrderQuote,
                        variables: { _id: quote._id },
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (props.isDeny) {
                        if (props.onChangeV1)
                            props.onChangeV1();
                    }
                    else {
                        if (onChange)
                            onChange(quote, ACTION_CHANGE.UPDATED);
                    }
                    return [3 /*break*/, 7];
                case 5:
                    e_1 = _a.sent();
                    toast.error(e_1.message);
                    onClose();
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onClose = function () { return onChange && onChange(order, ACTION_CHANGE.ADDED); };
    return (React.createElement(React.Fragment, null,
        React.createElement(BasicDialog, { onOk: onOk, title: props.isDeny ? langOfferForm.t('offer_form_deny_new_price') : langOfferForm.t('offer_form_edit_btn_new_price'), actionOkTitle: props.isDeny ? langApp.t('app_deny') : langOfferForm.t('offer_to_accept'), actionCancelTitle: langApp.t('modal_actions_close'), isMiddleBody: true, withoutWrapper: true, body: React.createElement(React.Fragment, null,
                React.createElement(Typography, { color: 'primary', variant: 'subtitle1', style: { textAlign: 'center', width: '100%' }, dangerouslySetInnerHTML: {
                        __html: __spread((props.isDeny
                            ? [langOfferForm.t('offer_form_deny_new_price')]
                            : [langOfferForm.t('offer_form_accept_new_price')]), [
                            priceValue(quote),
                        ]).join(' '),
                    } })), actionInit: function (onOpen) {
                var _onOpen = function () {
                    var e = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        e[_i] = arguments[_i];
                    }
                    onOpen.apply(void 0, __spread(e));
                };
                var icon = (React.createElement(BaseTooltip, { title: props.isDeny
                        ? langOfferForm.t('offer_form_deny_new_price')
                        : langOfferForm.t('offer_form_accept_new_price') },
                    React.createElement(MainButton, { disabled: loading || props.disabled, onClick: _onOpen, title: props.isDeny ? langApp.t('app_deny') : langOfferForm.t('offer_to_accept'), disabledStyle: { pointerEvents: 'none' }, style: __assign(__assign({ height: 25, backgroundColor: theme.palette.success.light }, ((!props.disabled && { padding: theme.spacing(0), color: theme.palette.success.main }) || {
                            padding: theme.spacing(0),
                        })), (props.isDeny && { backgroundColor: theme.palette.primary.main, marginLeft: theme.spacing(1) })) })));
                return props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: langOfferForm.t('offer_to_accept'), handleClickOpen: _onOpen }))) : (icon);
            } })));
}
export default inject('store')(OrderContraQuoteAccept);
