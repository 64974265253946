import { get, has, isEmpty } from '../lodashUtils';
import { googleTagManager } from '../utils1';
export var formUserErrorMessage = function (error) {
    googleTagManager('event', 'izi_error', { value: error.message || error, event_name: 'error' });
    var userErrorMessage = '';
    if (isEmpty(error))
        return userErrorMessage;
    var isAppoloError = has(error, 'message');
    if (isAppoloError) {
        userErrorMessage = get(error, 'message');
    }
    else {
        userErrorMessage = 'Ups.Something went wrong...';
        console.log('error: ' + JSON.stringify(error));
    }
    return userErrorMessage;
};
