import { applySnapshot, types } from 'mobx-state-tree';
import { MaybeEmptyModel } from '../stores/common';
import { OrderVehicleType } from './OrderVehicleType';
import { OrderCargoSizeType } from './types';
var is_USA_APP = parseInt(process.env.REACT_APP_APP_FOR_USA || '0') === 1;
export var OrderVehicle = types
    .model('OrderVehicle', {
    _id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.optional(types.string, '')),
    type: types.maybeNull(MaybeEmptyModel(OrderVehicleType)),
    origin: types.maybeNull(types.optional(types.string, '')),
    track: types.maybeNull(types.optional(types.string, '')),
    trailer: types.maybeNull(types.optional(types.string, '')),
    trailerHeight: types.maybeNull(types.optional(types.number, 0)),
    trailerLength: types.maybeNull(types.optional(types.number, 0)),
    trailerWidth: types.maybeNull(types.optional(types.number, 0)),
    measure: types.maybeNull(types.optional(types.enumeration(is_USA_APP ? OrderCargoSizeType.Inch : OrderCargoSizeType.M, Object.values(OrderCargoSizeType)), is_USA_APP ? OrderCargoSizeType.Inch : OrderCargoSizeType.M)),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
