var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var COUNTRY_BASE = "\n      _id\n      name\n      code\n      ";
export var FetchCountries = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query countries($filter: MainFilter) {\n          countries(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query countries($filter: MainFilter) {\n          countries(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), COUNTRY_BASE);
export var FetchOrderListFilters = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query orderListFilters($filter: MainFilter) {\n    orderListFilters(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query orderListFilters($filter: MainFilter) {\n    orderListFilters(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), COUNTRY_BASE);
export var FetchCarrierDirListFilters = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query userListFilters($filter: MainFilter) {\n    userListFilters(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query userListFilters($filter: MainFilter) {\n    userListFilters(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), COUNTRY_BASE);
var templateObject_1, templateObject_2, templateObject_3;
