var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, UPLOAD_BASE } from './base';
export var GUIDE_BASE = "\n    " + BASE_QUERY + "\n    name\n    description\n    type\n";
export var GUIDE_BASE_V1 = "\n    " + GUIDE_BASE + "\n    files{\n        " + UPLOAD_BASE + "      \n    }\n";
export var FetchGuides = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query guides($filter: MainFilter) {\n        guides(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query guides($filter: MainFilter) {\n        guides(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), GUIDE_BASE_V1);
export var FetchGuide = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query guide($type: String!) {\n        guide(type: $type) {\n            ", "\n        }\n    }\n"], ["\n    query guide($type: String!) {\n        guide(type: $type) {\n            ", "\n        }\n    }\n"])), GUIDE_BASE_V1);
export var CreateGuide = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation createGuide($input: GuideInput!) {\n        createGuide(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createGuide($input: GuideInput!) {\n        createGuide(input: $input) {\n            ", "\n        }\n    }\n"])), GUIDE_BASE);
export var UpdateGuide = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation updateGuide($input: GuideInputUpdate!) {\n        updateGuide(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateGuide($input: GuideInputUpdate!) {\n        updateGuide(input: $input) {\n            ", "\n        }\n    }\n"])), GUIDE_BASE);
export var DeleteGuide = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation deleteGuide($_id: MongoId!) {\n        deleteGuide(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteGuide($_id: MongoId!) {\n        deleteGuide(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
