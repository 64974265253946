var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applySnapshot, castToSnapshot, types } from 'mobx-state-tree';
import { AsyncActionStatus, MaybeEmptyModel } from '../stores/common';
import { InvoiceServiceUnit } from './InvoiceServiceUnit';
import { InvoiceServiceVAT } from './InvoiceServiceVAT';
export var InvoiceService = types
    .compose(AsyncActionStatus, types.model('InvoiceService', {
    _id: types.optional(types.identifier, ''),
    updatedAt: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    description: types.maybeNull(types.optional(types.string, '')),
    unit: types.maybeNull(MaybeEmptyModel(InvoiceServiceUnit)),
    vat: types.maybeNull(MaybeEmptyModel(InvoiceServiceVAT)),
    quantity: types.maybeNull(types.optional(types.number, 1)),
    price: types.maybeNull(types.optional(types.number, 0)),
    total: types.maybeNull(types.optional(types.number, 0)),
    vatAmount: types.maybeNull(types.optional(types.number, 0)),
    discount: types.maybeNull(types.optional(types.number, 0)),
    showDescriptionInPdf: types.maybeNull(types.optional(types.boolean, true)),
    discountUnit: types.maybeNull(types.optional(types.string, '%')),
}))
    .actions(function (self) { return ({
    clear: function () {
        var snap = castToSnapshot(InvoiceService.create());
        applySnapshot(self, snap);
    },
    reset: function (snapshot) {
        applySnapshot(self, __assign(__assign({}, snapshot), { updatedAt: new Date().toString() }));
    },
}); });
