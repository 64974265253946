var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { withOfferTimeBookingStore, OfferTimeBookingProvider } from './store';
import { withBaseGlobalSocketStoreActiveClient } from '../global/store';
export var EventsComponent = function (_a) {
    var listen = _a.listen, unListen = _a.unListen, children = _a.children;
    /*eslint-disable */
    useEffect(function () {
        listen();
        return function () {
            unListen();
        };
    }, []);
    /*eslint-enable */
    return children;
};
export var OfferTimeBookingEventsComponentWithStore = withOfferTimeBookingStore(EventsComponent);
export var OfferTimeBookingEventsComponentSocket = withBaseGlobalSocketStoreActiveClient(function (p) {
    var _a;
    var client = p.client, props = __rest(p, ["client"]);
    var content = React.createElement(OfferTimeBookingEventsComponentWithStore, __assign({}, props));
    return React.createElement(OfferTimeBookingProvider, { client: (_a = p.globalSocketState) === null || _a === void 0 ? void 0 : _a.client }, content);
});
