var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useObserver } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { LanguageMenuShort } from './LanguageMenuShort';
import { ErrorBoundary } from './ErrorBoundary';
import { theme } from '../theme';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        '@global': {
            body: {},
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            minHeight: '100%',
            background: "linear-gradient(90deg, " + theme.palette.success.light + " 0%, " + theme.palette.primary.light + " 100%)",
        },
        paper: (_a = {
                display: 'flex',
                // flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                marginTop: -138
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: theme.spacing(3),
            },
            _a),
        toolbar: __assign(__assign({ maxWidth: 300, padding: theme.spacing(1), textAlign: 'right' }, theme.mixins.toolbar), { display: 'flex' }),
    });
});
export var EmptyLayout = function (props) {
    var children = props.children, v2 = props.v2;
    var classes = useStyles();
    return useObserver(function () { return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: classes.background, style: __assign({}, (v2 && { overflow: 'hidden' })) },
            React.createElement(Grid, { container: true, alignItems: 'center', style: { padding: theme.spacing(2) }, justify: 'space-between' },
                React.createElement("span", null),
                (!props.hideLng && React.createElement(LanguageMenuShort, null)) || null),
            React.createElement(Container, __assign({ component: "main" }, props, { style: __assign({ maxWidth: 600, padding: 0 }, props.style) }),
                React.createElement("div", { className: classes.paper, style: __assign({}, props.paperStyle) }, children))))); });
};
