var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_MAIN_QUERY, USER_BASE_I } from './base';
export var USER_SUBSCRIPTION_BASE = "\n    " + BASE_MAIN_QUERY + "\n    endpoint\n    auth_token\n    p256dh_token\n    IP\n    osName\n    browserName\n    isActive\n    createdBy{\n        " + USER_BASE_I + "\n    }\n";
export var FetchUserSubscriptions = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query userSubscriptions($filter: MainFilter) {\n        userSubscriptions(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query userSubscriptions($filter: MainFilter) {\n        userSubscriptions(filter: $filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), USER_SUBSCRIPTION_BASE);
export var CreateUserSubscription = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createUserSubscription($input: UserSubscriptionInput!) {\n        createUserSubscription(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createUserSubscription($input: UserSubscriptionInput!) {\n        createUserSubscription(input: $input) {\n            ", "\n        }\n    }\n"])), USER_SUBSCRIPTION_BASE);
export var UpdateUserSubscription = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateUserSubscription($input: UserSubscriptionInputUpdate!) {\n        updateUserSubscription(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateUserSubscription($input: UserSubscriptionInputUpdate!) {\n        updateUserSubscription(input: $input) {\n            ", "\n        }\n    }\n"])), USER_SUBSCRIPTION_BASE);
var templateObject_1, templateObject_2, templateObject_3;
