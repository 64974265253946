var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import { createStyles, Grid } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { HelpIconComponent } from '../HelpIcon';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        help: {
            color: 'initial',
            height: 20,
            opacity: 0.3,
            '&:hover': {
                opacity: 1,
            },
        },
        root: {
            '& label': {
                '& span': {
                    paddingBottom: 0,
                    paddingTop: 0,
                },
            },
        },
    });
});
export default (function (_a) {
    var title = _a.title, help = _a.help, helpAdditional = _a.helpAdditional, style = _a.style, noStyle = _a.noStyle, additionalStyle = _a.additionalStyle, required = _a.required, v1 = _a.v1, v14 = _a.v14, v15 = _a.v15, v16 = _a.v16, props = __rest(_a, ["title", "help", "helpAdditional", "style", "noStyle", "additionalStyle", "required", "v1", "v14", "v15", "v16"]);
    var className = useStyles();
    var langForm = useTranslation(KEYS.FORM);
    if (!(v1 || v14))
        return null;
    var _title = title && (React.createElement(TextOverflow, { style: __assign(__assign({}, (help &&
            !noStyle &&
            ((!required && { maxWidth: "calc(100% - 24px)" }) || { maxWidth: "calc(100% - 31px)" }))), props.headerStyle) }, title));
    var __title = (React.createElement(React.Fragment, null,
        _title,
        React.createElement("span", { title: langForm.t('form_input_validate_required'), style: { color: theme.palette.error.light } }, "*")));
    return ((title && (React.createElement(Grid, { container: true, alignItems: 'center', className: clsx('input-label', className.root), style: style },
        v16 ? null : required ? (props.v11 ? (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } }, __title)) : (__title)) : (_title),
        help && !v15 && (React.createElement("span", { className: className.help },
            React.createElement(HelpIconComponent, { title: help, isBigTooltip: props.isBigTooltip }))),
        helpAdditional && (React.createElement("span", { style: __assign(__assign({ width: '100%' }, (!v16 && { marginLeft: 8 })), additionalStyle) }, helpAdditional))))) ||
        null);
});
export var InputLabelHelp = function (_a) {
    var help = _a.help, props = __rest(_a, ["help"]);
    var className = useStyles();
    return (React.createElement("span", { className: className.help },
        React.createElement(HelpIconComponent, __assign({ title: help }, props))));
};
