var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { MainFilterSortDir } from '../../../components/BasicList';
import { withBaseGlobalSocketStoreActiveClient } from '../../../components/Socket/global/store';
import { GlobalSocketComponent } from '../../../components/Socket';
import { FetchOrderChats } from '../../../graphql/chat';
import { SOCKET_CHAT_CUSTOM_EVENT_NAME } from './constants';
var initialState = {
    loading: false,
    usersTyping: [],
    loaded: false,
    error: null,
    search: '',
    count: 0,
    totalUnread: 0,
    allUsers: [],
    data: [],
    searchFields: [],
    filter: {
        fields: [],
        sortBy: 'createdAt',
        sortDir: MainFilterSortDir.DESC,
        limit: 10,
        page: 1,
        skip: 0,
    },
};
var ChatStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = ChatStore.Provider, Consumer = ChatStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["START_LOAD_DATA"] = "START_LOAD_DATA";
    ACTIONS["FINISH_LOAD_DATA"] = "FINISH_LOAD_DATA";
    ACTIONS["LOAD_DATA_ERROR"] = "LOAD_DATA_ERROR";
    ACTIONS["CHANGE_FILTERS"] = "CHANGE_FILTERS";
    ACTIONS["USER_IS_TYPING"] = "USER_IS_TYPING";
    ACTIONS["USER_IS_TYPING_REMOVE"] = "USER_IS_TYPING_REMOVE";
})(ACTIONS || (ACTIONS = {}));
export var ChatProvider = function (_a) {
    var children = _a.children, client = _a.client, socketClient = _a.socketClient;
    var initState = __assign({}, JSON.parse(JSON.stringify(initialState)));
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.START_LOAD_DATA: {
                return __assign(__assign({}, state), { error: null, loading: true });
            }
            case ACTIONS.USER_IS_TYPING: {
                return __assign(__assign({}, state), { usersTyping: __spread(state.usersTyping.filter(function (e) {
                        return e.user._id + "_" + e.chat._id !== action.payload.item.user._id + "_" + action.payload.item.chat._id;
                    }), [
                        action.payload.item,
                    ]) });
            }
            case ACTIONS.USER_IS_TYPING_REMOVE: {
                return __assign(__assign({}, state), { usersTyping: state.usersTyping.filter(function (e) {
                        return e.user._id + "_" + e.chat._id !==
                            (action.payload.item.user || action.payload.item.createdBy)._id + "_" + action.payload.item.chat._id;
                    }) });
            }
            case ACTIONS.LOAD_DATA_ERROR:
            case ACTIONS.FINISH_LOAD_DATA: {
                return __assign(__assign(__assign({}, state), action.payload), { loading: false });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var loadData = function (filter) { return __awaiter(void 0, void 0, void 0, function () {
        var result, chat, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: ACTIONS.START_LOAD_DATA,
                    });
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: FetchOrderChats,
                            variables: {
                                filter: __assign(__assign({}, state.filter), filter),
                            },
                        })];
                case 1:
                    result = _a.sent();
                    chat = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign(__assign({ loaded: true, data: __spread(state.data, chat.data.reverse()) }, chat)),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_1.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onChangeFilter = function (filter) {
        var updateFilter = __assign(__assign({}, state.filter), filter);
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: {
                filter: updateFilter,
            },
        });
        loadData(updateFilter);
    };
    var delays = {
        typing: null,
    };
    var events = socketClient
        ? [
            {
                client: socketClient,
                name: 'channel',
                callback: function (data) {
                    // console.log(data);
                    switch (data.event) {
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_FINISH_TYPING: {
                            dispatch({
                                type: ACTIONS.USER_IS_TYPING_REMOVE,
                                payload: {
                                    item: data.data,
                                },
                            });
                            break;
                        }
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_STATUS: {
                            dispatch({
                                type: ACTIONS.FINISH_LOAD_DATA,
                                payload: {
                                    allUsers: data.users,
                                },
                            });
                            break;
                        }
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_USER_TYPING: {
                            if (delays.typing)
                                clearTimeout(delays.typing);
                            dispatch({
                                type: ACTIONS.USER_IS_TYPING,
                                payload: Object.assign({
                                    item: data.data,
                                }),
                            });
                            delays.typing = setTimeout(function () {
                                dispatch({
                                    type: ACTIONS.USER_IS_TYPING_REMOVE,
                                    payload: {
                                        item: data.data,
                                    },
                                });
                            }, 15000);
                            break;
                        }
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_TOTAL_UNREAD_MESSAGES: {
                            dispatch({
                                type: ACTIONS.FINISH_LOAD_DATA,
                                payload: data,
                            });
                            break;
                        }
                    }
                    // const payload: ChannelStoreState = { updatedAt: Date.now() } as ChannelStoreState;
                    // if (data.event === SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_ITEM_DELETED && 0) {
                    // } else {
                    //     const list = [data].splice(0, 20);
                    //     Object.assign(payload, {
                    //         list,
                    //         newUpdates: Math.min(state.newUpdates + 1, list.length),
                    //     });
                    // }
                    //
                    // dispatch({
                    //     type: ACTIONS.LOADED,
                    //     payload,
                    // });
                },
            },
        ]
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('%c*******************channels new Chat no client', 'color:orange;font-size:18px');
        }
        else {
            console.log('%c======= channels new Chat notifications listen', 'color:green;font-size:22px');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('%c*******************channels  new Chat un listen', 'color:orange;font-size:18px');
        }
        else {
            console.log('%c=======channels  new Chat notifications un listen', 'color:red;font-size:22px');
        }
    };
    var value = {
        state: state,
        listen: listen,
        unListen: unListen,
        loadData: loadData,
        onChangeFilter: onChangeFilter,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withChannelStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, { key: props.socketClient ? 1 : -1 }, function (_a) {
        var listen = _a.listen, unListen = _a.unListen, storeProps = __rest(_a, ["listen", "unListen"]);
        return (React.createElement(Component, __assign({}, __assign(__assign({}, props), { listen: listen, unListen: unListen }), { channelStore: storeProps })));
    })); };
};
var ChannelComponent = withChannelStore(GlobalSocketComponent);
export var GlobalChannelSocket = withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    var content = React.createElement(ChannelComponent, __assign({}, props));
    return (React.createElement(ChatProvider, __assign({}, p, { client: client, socketClient: p.globalSocketState.client }), content));
});
