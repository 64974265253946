var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { withChannelListStore } from '../../../../pages/Chat/stores/ChannelList';
import { StyledBadgeV5 } from '../../../StyledBadge';
import { useTranslation } from 'react-i18next';
import MessageIcon from '@material-ui/icons/Message';
import { KEYS } from '../../../../i18n';
import BaseTooltip from '../../../BaseTooltip';
var OrderMessagesUnread = function (allProps) {
    var channelListStore = allProps.channelListStore, order = allProps.order, label = allProps.label, withIcon = allProps.withIcon, props = __rest(allProps, ["channelListStore", "order", "label", "withIcon"]);
    var langApp = useTranslation(KEYS.APP);
    var langChat = useTranslation(KEYS.CHAT);
    if (!channelListStore || !channelListStore.state || !channelListStore.state.channelsTotalUnread.length)
        return label || null;
    var orderUnread = channelListStore.state.channelsTotalUnread.filter(function (e) { return e._id === order._id && e.totalUnread; });
    if (!orderUnread.length)
        return label || null;
    return (React.createElement(BaseTooltip, { title: langApp.t('unread_messages'), style: { marginRight: 8 } },
        React.createElement("span", __assign({}, props),
            React.createElement(StyledBadgeV5, { badgeContent: orderUnread.map(function (e) { return e.totalUnread; }).reduce(function (a, b) { return a + b; }, 0), color: "primary" },
                React.createElement("span", __assign({}, props), (withIcon && React.createElement(MessageIcon, { style: { width: 22 } })) || label || '')))));
};
export default withChannelListStore(OrderMessagesUnread);
