var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var EMAIL_TEMPLATE_BASE = "\n    _id\n    type\n    html\n";
export var FetchEmailTemplate = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query emailTemplate($_id: MongoId!) {\n        emailTemplate(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    query emailTemplate($_id: MongoId!) {\n        emailTemplate(_id: $_id) {\n            ", "\n        }\n    }\n"])), EMAIL_TEMPLATE_BASE);
export var FetchEmailTemplates = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query emailTemplates($filter: MainFilter!) {\n        emailTemplates(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query emailTemplates($filter: MainFilter!) {\n        emailTemplates(filter: $filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), EMAIL_TEMPLATE_BASE);
export var UpdateEmailTemplate = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateEmailTemplate($input: EmailTemplateInputUpdate!) {\n        updateEmailTemplate(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateEmailTemplate($input: EmailTemplateInputUpdate!) {\n        updateEmailTemplate(input: $input) {\n            ", "\n        }\n    }\n"])), EMAIL_TEMPLATE_BASE);
var templateObject_1, templateObject_2, templateObject_3;
