import { OrderRouteType } from '../../../models';
export var optionLabelV1 = function (option) {
    return (option.fullName || '').trim() ||
        [option.firstName, option.lastName]
            .filter(function (e) { return e; })
            .join(' ')
            .trim() ||
        ((option.value && [option.value.firstName, option.value.lastName].filter(function (e) { return e; }).join(' ')) || '').trim() ||
        option.label ||
        option.email ||
        option._id ||
        '';
};
export var orderRoutesList = function (_a) {
    var order = _a.order, canViewAdditional = _a.canViewAdditional;
    var routes = typeof order.firstRoute !== 'undefined' || typeof order.lastRoute !== 'undefined'
        ? { routes: [order.firstRoute, order.lastRoute] }
        : [] || [];
    (order.transportations || []).forEach(function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate, startTime = _a.startTime, endTime = _a.endTime, routes = _a.routes;
        routes.forEach(function (f) {
            return Object.assign(f.orderTransportation, {
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
            });
        });
    });
    var _routesList = JSON.parse(JSON.stringify(((routes.length && routes) || order.transportations || [])));
    var routesList = _routesList.filter(function (e, index) { return canViewAdditional || (!canViewAdditional && index === 0); });
    if (!routesList[0])
        routesList.push({ routes: [] });
    if (!canViewAdditional) {
        var endDelivery = _routesList.length &&
            _routesList[_routesList.length - 1].routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
        if (endDelivery) {
            routesList[0].routes = [routesList[0].routes.find(function (e) { return e.type !== OrderRouteType.Delivery; })];
            routesList[0].routes.push(endDelivery);
        }
    }
    if (canViewAdditional) {
        routesList.forEach(function (tr) {
            if (!tr.routes)
                tr.routes = [];
            if (!tr.routes.find(function (e) { return e.type === OrderRouteType.Preferred; }))
                tr.routes.push({ type: OrderRouteType.Preferred, orderTransportation: tr._id });
            if (!tr.routes.find(function (e) { return e.type === OrderRouteType.Delivery; }))
                tr.routes.push({ type: OrderRouteType.Delivery, orderTransportation: tr._id });
        });
    }
    if (routesList[0].routes.length < 2) {
        routesList[0].routes.push({ orderTransportation: routesList[0]._id });
    }
    if (routesList[0].routes.length < 2) {
        routesList[0].routes.push({ orderTransportation: routesList[0]._id });
    }
    return { routesList: routesList };
};
