var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
export var CMRInput = function (_a) {
    var defaultValue = _a.defaultValue, label = _a.label, labelStyle = _a.labelStyle, style = _a.style, parentStyle = _a.parentStyle, props = __rest(_a, ["defaultValue", "label", "labelStyle", "style", "parentStyle"]);
    var _b = __read(React.useState(defaultValue), 2), value = _b[0], setValue = _b[1];
    var onChange = function (e) { return setValue(e.target.value); };
    return (React.createElement("div", { style: __assign({ display: 'flex', width: '100%', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }, parentStyle) },
        label && React.createElement("span", { style: __assign({ fontSize: '0.45rem', paddingLeft: '10px' }, labelStyle) }, label),
        React.createElement("input", __assign({}, props, { onChange: onChange, value: value, style: __assign({ fontSize: '0.56rem', width: '100%', padding: '0 10px', flex: '1 0 auto', height: '100%', border: 'none' }, style) }))));
};
export var CMRInputArea = function (_a) {
    var children = _a.children, defaultValue = _a.defaultValue, rows = _a.rows, label = _a.label, style = _a.style, parentStyle = _a.parentStyle, labelStyle = _a.labelStyle;
    var _b = __read(React.useState(defaultValue), 2), value = _b[0], setValue = _b[1];
    var _onChange = function (e) {
        setValue(e.target.value);
    };
    return (React.createElement("div", { style: __assign({ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }, parentStyle) },
        label && React.createElement("span", { style: __assign({ fontSize: '0.56rem', paddingLeft: 10 }, labelStyle) }, label),
        React.createElement("textarea", { name: "textarea", rows: rows, value: value, style: __assign({ fontSize: '0.65rem', width: '100%', padding: '0 10px', flex: '1 0 auto', height: '100%', border: 'none' }, style), onChange: _onChange })));
};
export var Caption = function (_a) {
    var children = _a.children, style = _a.style;
    return (React.createElement("span", { style: __assign({ fontSize: '0.4rem', lineHeight: 1.05 }, style) }, children));
};
export var Header = function (_a) {
    var children = _a.children, style = _a.style;
    return React.createElement("h4", { style: __assign({ margin: 0, color: 'black' }, style) }, children);
};
export var HeaderH5V1 = function (_a) {
    var children = _a.children;
    return React.createElement("h5", { style: { margin: 0, color: 'black' } }, children);
};
export var HeaderH5 = function (_a) {
    var children = _a.children, style = _a.style;
    return (React.createElement("h6", { style: __assign({ margin: 0, color: 'black' }, style) }, children));
};
export var HeaderBody = function (_a) {
    var children = _a.children;
    return (React.createElement("span", { style: { margin: 0, color: 'black', fontSize: '1rem' } }, children));
};
export var TableTr = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("tr", __assign({ style: { padding: 0 } }, props), children));
};
export var TableTd = function (_a) {
    var children = _a.children, style = _a.style, props = __rest(_a, ["children", "style"]);
    return (React.createElement("td", __assign({}, props, { style: __assign({ width: '50%', height: '100%', padding: 0, borderBottom: '1px solid black' }, style) }), children));
};
export var OrderTableTr = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("tr", __assign({ style: { padding: 0 } }, props), children));
};
export var OrderTableTrTd = function (_a) {
    var children = _a.children, style = _a.style, props = __rest(_a, ["children", "style"]);
    return (React.createElement("td", __assign({}, props, { style: __assign({ height: '100%', margin: 0, padding: '0px 2px', border: '1px solid #000' }, style) }), children));
};
export var OrderTableTrTdV1 = function (_a) {
    var children = _a.children, style = _a.style, props = __rest(_a, ["children", "style"]);
    return (React.createElement("td", __assign({}, props, { style: __assign({ height: '100%', margin: 0, padding: '0px 2px' }, style) }), children));
};
export var CaptionV1 = function (_a) {
    var children = _a.children, style = _a.style;
    return (React.createElement(Caption, { style: __assign({ color: '#000', padding: '2px 0' }, style) }, children));
};
export var CaptionV2 = function (_a) {
    var children = _a.children, style = _a.style;
    return (React.createElement(Caption, { style: __assign({ color: '#000', padding: '2px 0', fontSize: '0.55rem' }, style) }, children));
};
export var GridV1 = function (_a) {
    var style = _a.style, v1 = _a.v1, children = _a.children;
    return (React.createElement("div", { style: __assign(__assign({ display: 'flex', width: '100%', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }, style), (v1 && { width: 'initial', height: 'initial' })) }, children));
};
