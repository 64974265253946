import { applySnapshot, types } from "mobx-state-tree";
export var OrderQuoteDriver = types
    .model("OrderQuoteDriver", {
    _id: types.optional(types.string, ""),
    updatedAt: types.maybeNull(types.optional(types.string, "")),
    phone: types.maybeNull(types.optional(types.string, "")),
    name: types.maybeNull(types.optional(types.string, "")),
    origin: types.maybeNull(types.optional(types.string, "")),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
