var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BasicDialog, FilePreview, onPreventEvent } from '../../../Dialog';
import { TextOverflow } from '../../../TextOverflow';
import { KEYS } from '../../../../i18n';
import BaseTooltip from '../../../BaseTooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import OrderRouteMenuItem from '../../../Order/List/OrderRoutesMenuItem';
import { MainButton } from '../../../MainButton';
import PreloadingComponent from '../../../PreloadingComponent';
import { BaseList } from '../../../BasicList';
import { dateViewFormat } from '../../../DateView';
import { FetchUploads } from '../../../../graphql';
import { BaseListItemV1 } from '../../../BasicList/ListItem/BaseListItemV1';
import OrderRoutesCreatedBy from '../../../Order/List/OrderRoutesCreatedBy';
import MenuListItem from '../../../MenuListItem';
import DownLoadDocument from '../../../Dialog/DownLoadFile';
import FolderIcon from '@material-ui/icons/Folder';
import { theme } from '../../../../theme';
function AllUploadList(_a) {
    var record = _a.record, onChange = _a.onChange, isAdmin = _a.isAdmin, props = __rest(_a, ["record", "onChange", "isAdmin"]);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var title = langApp.t('main_documents');
    var _config = {
        dontSaveFilters: true,
        hasHeaderSort: true,
        hideSort: true,
        headerStyle: { padding: theme.spacing(0, 1) },
        headerColumns: [
            {
                name: langForm.t('form_input_name'),
                sortable: true,
                sortKey: 'name',
                size: 4,
            },
            {
                name: langForm.t('form_input_name_created_at'),
                sortable: true,
                sortKey: 'createdAt',
                size: 2,
            },
            {
                name: langForm.t('form_invoice_issued_by'),
                size: 4,
            },
            {
                style: { display: 'flex', justifyContent: 'flex-end' },
                name: langForm.t('form_input_actions'),
                size: 2,
            },
        ],
        title: title,
        hideTitleHeader: props.hideHeader,
        rowContent: function (record) { return (React.createElement(BaseListItemV1, { childs: [
                {
                    value: (React.createElement(FilePreview, { item: record, title: langForm.t('form_btn_view'), config: props.config, actionInit: function (onClick) { return (React.createElement("a", { style: { maxWidth: '100%' }, href: "#", onClick: onClick },
                            React.createElement(TextOverflow, null, record.name))); } })),
                },
                { value: dateViewFormat(record.createdAt, 1) },
                {
                    value: (React.createElement(PreloadingComponent, null,
                        React.createElement(OrderRoutesCreatedBy, { createdBy: record.createdBy, v1: true }))),
                },
                {
                    value: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end' },
                        React.createElement(MenuListItem, { show: true, list: [
                                {
                                    content: React.createElement(DownLoadDocument, __assign({ item: record }, props, { label: true })),
                                    available: true,
                                },
                            ] }))),
                },
            ], headerColumns: _config.headerColumns })); },
        noData: (React.createElement(React.Fragment, null,
            React.createElement(TextOverflow, { variant: 'subtitle1' }, langOrderList.t('order_list_filter_no_data_general')))),
    };
    var dialogBody = (React.createElement(BaseList, { options: {
            client: props.config.client,
            query: FetchUploads,
            defaultFilter: __assign({}, props.defaultFilter),
            searchFields: ['name'],
        }, config: _config }));
    return (React.createElement(BasicDialog, { askCanClose: !props.v3, withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, hidActionOkBth: true, body: dialogBody, v1: true, onClose: function (e) {
            onPreventEvent(e);
        }, title: React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center', direction: 'column' },
            React.createElement(TextOverflow, null, title)), actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: title },
                React.createElement(IconButton, { onClick: handleClickOpen, style: __assign({}, ((props.v4 || props.v5) && { padding: theme.spacing(0.5) })) }, props.v3 ? (React.createElement(FolderIcon, { color: 'primary', style: __assign({}, (props.v4 && { color: theme.palette.common.white })) })) : (React.createElement(AttachFileIcon, { color: 'primary' })))));
            return (record && record._id) || props.label || props.v3 ? (props.v5 ? (React.createElement(MenuItem, { onClick: handleClickOpen },
                React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen })))) : props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: handleClickOpen }))) : (icon)) : (React.createElement(MainButton, { style: { width: '100%' }, onClick: handleClickOpen, color: 'primary', title: title }));
        } }));
}
export default AllUploadList;
