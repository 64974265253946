var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { withRouter } from 'react-router';
import { CircularIndeterminate, TextOverflow } from '../../components';
import { inject } from 'mobx-react';
import { QuoteDetailsComponentOverview } from '../Quotes/Overview';
import ChannelItemDetailsChat from './ChannelItemDetailsChat';
import { withChatStore } from './stores/ChannelChat';
var ChannelItemDetails = inject('store')(function (_a) {
    var match = _a.match, record = _a.record, chatStore = _a.chatStore, props = __rest(_a, ["match", "record", "chatStore"]);
    React.useEffect(function () {
        chatStore.loadOne((record && record._id) || match.params.chatId);
    }, [(record && record._id) || match.params.chatId]);
    if (!chatStore.state.item || chatStore.state.loading)
        return React.createElement(CircularIndeterminate, null);
    return (React.createElement(React.Fragment, null,
        (!record &&
            ((!(chatStore.state.item && chatStore.state.item.order) && (React.createElement(TextOverflow, { variant: 'h6', color: 'error' }, "Order removed"))) || (React.createElement(QuoteDetailsComponentOverview, __assign({}, props, { config: props.config, onClick: function () { return 0; }, order: chatStore.state.item && chatStore.state.item.order, canViewAdditional: true, v1: false, v2: true, v7: true, v8: true, hideActions: false }))))) ||
            null,
        React.createElement(ChannelItemDetailsChat, __assign({}, props))));
});
export default withChatStore(withRouter(ChannelItemDetails));
