var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE } from '../base';
export var ORDER_VEHICLE_BASE = "\n    " + BASE_QUERY + "\n      origin\n      approvedAt\n      name\n      description\n      track \n      trailer \n      trailerHeight \n      trailerLength \n      trailerWidth \n      measure \n";
export var UpdateOrderVehicle = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateOrderVehicle($input: OrderVehicleInputUpdate!) {\n        updateOrderVehicle(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateOrderVehicle($input: OrderVehicleInputUpdate!) {\n        updateOrderVehicle(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_VEHICLE_BASE);
export var CreateOrderVehicle = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createOrderVehicle($input: OrderVehicleInput!) {\n        createOrderVehicle(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createOrderVehicle($input: OrderVehicleInput!) {\n        createOrderVehicle(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_VEHICLE_BASE);
export var FetchOrderVehicle = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderVehicles($filter:MainFilter){\n        orderVehicles(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderVehicles($filter:MainFilter){\n        orderVehicles(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), ORDER_VEHICLE_BASE);
export var DeleteOrderVehicle = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteOrderVehicle($_id: MongoId!) {\n        deleteOrderVehicle(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrderVehicle($_id: MongoId!) {\n        deleteOrderVehicle(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
