var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useObserver } from 'mobx-react';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as material from '@material-ui/core';
import { MainLayout } from '../components/MainLayout';
var Hidden = material.Hidden;
var makeStyles = material.makeStyles, useMediaQuery = material.useMediaQuery, Container = material.Container, Typography = material.Typography, Paper = material.Paper;
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        '@global': {
            body: {
                backgroundColor: [theme.palette.grey['100'], '!important'],
            },
        },
        content: (_a = {},
            _a[theme.breakpoints.up('sm')] = {
                marginTop: theme.spacing(3),
            },
            _a[theme.breakpoints.down('xs')] = {
                margin: 0,
                padding: 0,
            },
            _a),
        appBarTitle: {},
        paper: (_b = {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5)
            },
            _b[theme.breakpoints.up('sm')] = {
                paddingLeft: theme.spacing(10),
                paddingRight: theme.spacing(10),
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: 0,
            },
            _b[theme.breakpoints.down('xs')] = {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            _b),
        paperTitle: {
            padding: theme.spacing(3, 0),
        },
    });
});
export function PaperTitle(props) {
    var children = props.children;
    var classes = useStyles();
    return useObserver(function () { return (React.createElement("div", { className: classes.paperTitle },
        React.createElement(Hidden, { smDown: true, lgUp: true },
            React.createElement(Typography, { variant: "h3" }, children)),
        React.createElement(Hidden, { mdDown: true },
            React.createElement(Typography, { variant: "h3" }, children)))); });
}
PaperTitle.propTypes = {
    children: PropTypes.node.isRequired,
};
export function AppBarTitle(props) {
    var children = props.children;
    var classes = useStyles();
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(Hidden, { mdUp: true, xsDown: true },
            React.createElement(Typography, { variant: "h5", className: classes.appBarTitle }, children)),
        React.createElement(Hidden, { smUp: true },
            React.createElement(Typography, { variant: "h5", className: classes.appBarTitle, align: "center" }, children)))); });
}
AppBarTitle.propTypes = {
    children: PropTypes.node.isRequired,
};
export function MainContent(props) {
    var children = props.children;
    var classes = useStyles();
    return useObserver(function () { return (React.createElement(Container, { component: "main", maxWidth: "md", className: classes.content }, children)); });
}
MainContent.propTypes = {
    children: PropTypes.node.isRequired,
};
export function ContentPaper(props) {
    var children = props.children;
    var theme = useTheme();
    var elevation = useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 1;
    return useObserver(function () { return React.createElement(Paper, __assign({}, __assign({ elevation: elevation }, props)), children); });
}
ContentPaper.propTypes = {
    children: PropTypes.node.isRequired,
};
export function CompletePaperLayout(_a) {
    var title = _a.title, props = __rest(_a, ["title"]);
    var titleContent = title && React.createElement(PaperTitle, null, title);
    return React.createElement(MainLayout, __assign({}, props, { titleContent: titleContent }));
}
