var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_QUERY, BASE_RESPONSE, UPLOAD_BASE } from './base';
export var ISSUE_BASE = "\n      " + BASE_QUERY + "\n      title\n      description\n      status\n      chat{\n        _id\n      }\n      files{\n        " + UPLOAD_BASE + "\n      }\n      parent_collection_name\n      parent{\n            _id\n            orderId\n            sorting_totalWeight\n            sorting_deliveryAt\n            sorting_countryDelivery\n            sorting_countryPickup\n            sorting_pickupAt\n      }\n";
export var FetchIssues = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query issues($filter:MainFilter){\n        issues(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query issues($filter:MainFilter){\n        issues(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), ISSUE_BASE);
export var CreateIssue = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createIssue($input:IssueInput!){\n        createIssue(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createIssue($input:IssueInput!){\n        createIssue(input:$input) {\n            ", "\n        }\n    }\n"])), ISSUE_BASE);
export var UpdateIssue = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateIssue($input:IssueInputUpdate!){\n        updateIssue(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateIssue($input:IssueInputUpdate!){\n        updateIssue(input:$input) {\n            ", "\n        }\n    }\n"])), ISSUE_BASE);
export var DeleteIssue = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteIssue($_id:MongoId!){\n        deleteIssue(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteIssue($_id:MongoId!){\n        deleteIssue(_id:$_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
