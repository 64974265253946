import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { inject, observer, useLocalStore } from 'mobx-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formUserErrorMessage, pswRegExp } from '../common';
import { WholePageFormLayout, FormikTextFieldForPassword, EmptyLayout } from '../components';
import { makeStyles } from '@material-ui/core/styles';
import { KEYS } from '../i18n';
import browserHistory from '../browserHistory';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { googleTagManager, useQuery } from '../utils1';
import { browserStorage } from '../browserStorage';
export var useStyles = makeStyles(function (theme) { return ({
    message: {
        color: theme.palette.primary.main,
    },
}); });
export default inject('store')(observer(function (_a) {
    var store = _a.store, noRegister = _a.noRegister, noApps = _a.noApps;
    var langAuth = useTranslation(KEYS.AUTH);
    var langForm = useTranslation(KEYS.FORM);
    var preLoginStore = store.preLoginStore;
    var isAsyncActionInProgress = false; //preLoginStore.isAsyncActionInProgress;
    var validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, langForm.t('form_input_validate_min_char').replace(/_X_/g, '8'))
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
            .matches(pswRegExp, langForm.t('form_input_validate_invalid_psw'))
            .required(langForm.t('form_input_validate_required')),
        confirmPassword: Yup.string().when('password', {
            is: function (val) { return (val && val.length > 0 ? true : false); },
            then: Yup.string().oneOf([Yup.ref('password')], langForm.t('form_input_validate_match')),
        }),
    });
    var query = useQuery();
    var queryToken = query.get('token');
    var localStore = useLocalStore(function () { return ({
        values: {
            password: '',
            confirmPassword: '',
            isAutoLogin: !!query.get('autoLogin') ? 1 : 0,
        },
        userErrorMessage: '',
        passwordChanged: false,
        loading: false,
        onChangePasswordWithTokenRequest: function (data) {
            localStore.userErrorMessage = '';
            googleTagManager('event', 'izi_form_user_set_password', {
                event_name: "user_set_password",
                value: data,
            });
            localStore.loading = true;
            preLoginStore
                .changePasswordWithToken(data)
                .then(function (res) {
                if (store.accountStore.isLoggedIn) {
                    store.accountStore.logout();
                    setTimeout(function () { return browserHistory.push('/login'); }, 400);
                }
                localStore.passwordChanged = true;
                if (res.data.ChangePasswordWithToken.token) {
                    browserStorage.setItem("token", res.data.ChangePasswordWithToken.token);
                    setTimeout(function () {
                        store.accountStore.relogin();
                        browserHistory.push('/open-orders');
                    }, 1000);
                }
            })
                .catch(function (error) {
                localStore.userErrorMessage = formUserErrorMessage(error);
            })
                .finally(function () {
                localStore.loading = false;
            });
        },
        clearUserErrorMessage: function () {
            localStore.userErrorMessage = '';
        },
    }); });
    useEffect(function () {
        localStore.clearUserErrorMessage();
    }, [preLoginStore, queryToken, localStore]);
    if (isAsyncActionInProgress)
        return React.createElement(CircularProgress, { disableShrink: true });
    var error = localStore.userErrorMessage;
    var passwordChanged = localStore.passwordChanged;
    return (React.createElement(EmptyLayout, { key: isAsyncActionInProgress || localStore.loading ? 1 : -1 },
        React.createElement(Formik, { initialValues: localStore.values, validationSchema: validationSchema, onSubmit: function (values, _a) {
                var setSubmitting = _a.setSubmitting;
                localStore.onChangePasswordWithTokenRequest({
                    newPassword: values.password,
                    isAutoLogin: values.isAutoLogin,
                    token: queryToken,
                });
            } }, function (formik) {
            var onFieldChangeHandler = function (e) {
                localStore.clearUserErrorMessage();
                formik.handleChange(e);
            };
            return (React.createElement(WholePageFormLayout, { noApps: noApps, noRegister: noRegister, isLoading: isAsyncActionInProgress || localStore.loading, error: error, wholePageFormBackButtonVisible: false, wholePageFormTitle: passwordChanged
                    ? {
                        text: langAuth.t('set_new_password_page_success_explanatory_text'),
                        style: { textAlign: 'center' },
                    }
                    : {
                        text: langAuth.t('set_new_password_page_set_password_explanatory_text'),
                    }, submitButtonWithLoading: {
                    text: langForm.t('form_btn_confirm'),
                }, wholePageFormFooterNavLink: {
                    paragraphText: langAuth.t('login_page_i_have_account_explanatory_text'),
                    navPath: '/login',
                    linkText: langAuth.t('login_page_log_in'),
                }, wholePageFormHelperNavLinkVisible: false, wholePageFormFooterNavLinkVisible: true, successfulRequest: passwordChanged, message: React.createElement(CheckCircleOutlineOutlinedIcon, { color: 'primary', style: { width: 160, height: 160 } }), subText: langAuth.t('you_have_set_new_password_page_click_a_link_below_explanatory_text'), buttonText: langAuth.t('sign_up_page_log_in_explanatory_text'), path: '/login' },
                React.createElement(FormikTextFieldForPassword, { v3: true, name: "password", label: langForm.t('form_input_name_password'), value: formik.values.password, onChange: onFieldChangeHandler }),
                React.createElement(FormikTextFieldForPassword, { v3: true, name: "confirmPassword", label: langForm.t('form_input_name_password_confirm'), value: formik.values.confirmPassword, onChange: onFieldChangeHandler })));
        })));
}));
