import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { Grid } from '@material-ui/core';
import React from 'react';
import PopUpHover from '../../../PopupHover';
import { theme } from '../../../../theme';
var emojis = [
    0x1f600,
    0x1f604,
    0x1f34a,
    0x1f344,
    0x1f37f,
    0x1f363,
    0x1f370,
    0x1f355,
    0x1f354,
    0x1f35f,
    0x1f6c0,
    0x1f48e,
    0x1f5fa,
    0x23f0,
    0x1f579,
    0x1f4da,
    0x1f431,
    0x1f42a,
    0x1f439,
    0x1f424,
];
export default function Emoji(_a) {
    var onChange = _a.onChange;
    return (React.createElement(PopUpHover, { show: true, minWidth: 160, MenuStyles: { zIndex: 1302 }, parentStyle: {
            background: theme.palette.common.white,
            position: 'absolute',
            right: 36,
            top: 12,
            maxWidth: 150,
            zIndex: 1,
        }, icon: React.createElement(SentimentVerySatisfiedIcon, null), content: React.createElement(Grid, { container: true, wrap: 'wrap', justify: 'center' }, emojis.map(function (e) { return (React.createElement("div", { key: e, onClick: function () { return onChange(String.fromCodePoint(e)); }, style: { width: 24, height: 24, fontSize: '1.2rem', cursor: 'pointer' } }, String.fromCodePoint(e))); })) }));
}
