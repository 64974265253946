import clsx from 'clsx';
import { toDateOnly } from '../../../utils';
import moment from 'moment';
import React from 'react';
import { useMonthDayStyles } from './style';
function MonthDayLabel(_a) {
    var _b;
    var onClick = _a.onClick, day = _a.day, currentDay = _a.currentDay, current = _a.current, isCurrentMonth = _a.isCurrentMonth, isAvailable = _a.isAvailable, style = _a.style, hasOrders = _a.hasOrders;
    var className = useMonthDayStyles();
    return (React.createElement("span", { onClick: onClick, style: style, className: clsx(className.label, (_b = {},
            _b[className.current] = isAvailable && (currentDay === toDateOnly() || currentDay === toDateOnly(current)),
            _b[className.currentMonth] = isCurrentMonth && isAvailable,
            _b['has-orders'] = hasOrders,
            _b)) }, moment(day).format('DD')));
}
export default MonthDayLabel;
