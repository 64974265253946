var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { BASE_RESPONSE } from '../base';
import { WAREHOUSE_WORKING_DAY_PLAN_BASE } from './types';
export var FetchWarehouseWorkingDayPlans = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query warehouseWorkingDayPlans($filter: MainFilter)  {\n        warehouseWorkingDayPlans(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query warehouseWorkingDayPlans($filter: MainFilter)  {\n        warehouseWorkingDayPlans(filter:$filter) {\n            data {\n                ", "\n            }\n            count\n        }\n    }\n"])), WAREHOUSE_WORKING_DAY_PLAN_BASE);
export var CreateWarehouseWorkingDayPlan = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation createWarehouseWorkingDayPlan($input: WarehouseWorkingDayPlanInput!)  {\n        createWarehouseWorkingDayPlan(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation createWarehouseWorkingDayPlan($input: WarehouseWorkingDayPlanInput!)  {\n        createWarehouseWorkingDayPlan(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_WORKING_DAY_PLAN_BASE);
export var UpdateWarehouseWorkingDayPlan = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation updateWarehouseWorkingDayPlan($input: WarehouseWorkingDayPlanInputUpdate!)  {\n        updateWarehouseWorkingDayPlan(input:$input) {\n            ", "\n        }\n    }\n"], ["\n    mutation updateWarehouseWorkingDayPlan($input: WarehouseWorkingDayPlanInputUpdate!)  {\n        updateWarehouseWorkingDayPlan(input:$input) {\n            ", "\n        }\n    }\n"])), WAREHOUSE_WORKING_DAY_PLAN_BASE);
export var DeleteWarehouseWorkingDayPlan = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteWarehouseWorkingDayPlan($_id: MongoId!)  {\n        deleteWarehouseWorkingDayPlan(_id:$_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteWarehouseWorkingDayPlan($_id: MongoId!)  {\n        deleteWarehouseWorkingDayPlan(_id:$_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
