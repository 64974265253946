import { makeStyles, createStyles } from '@material-ui/core/styles';
export var useCalendarStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            '& *': {
                transition: '0.3s all',
            },
        },
    });
});
