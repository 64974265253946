export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["START_LOAD_DATA"] = "START_LOAD_DATA";
    ACTIONS["FINISH_LOAD_DATA"] = "FINISH_LOAD_DATA";
    ACTIONS["LOAD_DATA_ERROR"] = "LOAD_DATA_ERROR";
    ACTIONS["CHANGE_FILTERS"] = "CHANGE_FILTERS";
    ACTIONS["SET_SOFT_REFRESH"] = "SET_SOFT_REFRESH";
    ACTIONS["USER_IS_TYPING"] = "USER_IS_TYPING";
    ACTIONS["USER_IS_TYPING_REMOVE"] = "USER_IS_TYPING_REMOVE";
    ACTIONS["REFRESH_LIST"] = "REFRESH_LIST";
})(ACTIONS || (ACTIONS = {}));
