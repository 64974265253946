var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { LOCATION_ADDRESS, USER_COMPANY_BASE, USER_COMPANY_MORE, USER_WITH_COMPANY_BASE_I } from './base';
import { COMPANY_DOCUMENT_BASE } from './companyDocument';
export var UpdateCurrentCompany = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation updateCompany($input: CompanyInputUpdate!) {\n    updateCompany(input: $input) {\n     ", "\n    }\n  }\n"], ["\n  mutation updateCompany($input: CompanyInputUpdate!) {\n    updateCompany(input: $input) {\n     ", "\n    }\n  }\n"])), USER_COMPANY_BASE);
export var MergeCompany = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation mergeCompany($input: ModelMergeInput!) {\n    mergeCompany(input: $input) {\n      _id\n    }\n  }\n"], ["\n  mutation mergeCompany($input: ModelMergeInput!) {\n    mergeCompany(input: $input) {\n      _id\n    }\n  }\n"])));
export var CreateCompany = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation createCompany($input: CompanyInput!) {\n      createCompany(input: $input) {\n     ", "\n    }\n  }\n"], ["\n  mutation createCompany($input: CompanyInput!) {\n      createCompany(input: $input) {\n     ", "\n    }\n  }\n"])), USER_COMPANY_BASE);
export var DeleteCompany = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation deleteCompany($_id: MongoId!) {\n    deleteCompany(_id: $_id) {\n      status\n    }\n  }\n"], ["\n  mutation deleteCompany($_id: MongoId!) {\n    deleteCompany(_id: $_id) {\n      status\n    }\n  }\n"])));
export var RemoveFromCompany = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation removeFromCompany($input: InputSourceTargetInput!) {\n    removeFromCompany(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation removeFromCompany($input: InputSourceTargetInput!) {\n    removeFromCompany(input: $input) {\n      status\n    }\n  }\n"])));
export var FetchCompanies = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                latestVisitAt\n                latestActionAt\n                latestAction\n            }\n            count\n        }\n    }\n"], ["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                latestVisitAt\n                latestActionAt\n                latestAction\n            }\n            count\n        }\n    }\n"])), USER_COMPANY_MORE);
export var FetchCompaniesMore = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                status\n                statusV1{\n                  _id\n                  name\n                }\n                statusPlannedAt\n                manager{\n                  _id\n                  firstName\n                  lastName\n                  email\n                  company{\n                   _id\n                  }\n               }\n                latestVisitAt\n                latestActionAt\n                latestAction\n                cmrInsurance {\n                  ", "\n                }\n                sorting_hasCmrInsurance\n            }\n            count\n        }\n    }\n"], ["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                status\n                statusV1{\n                  _id\n                  name\n                }\n                statusPlannedAt\n                manager{\n                  _id\n                  firstName\n                  lastName\n                  email\n                  company{\n                   _id\n                  }\n               }\n                latestVisitAt\n                latestActionAt\n                latestAction\n                cmrInsurance {\n                  ", "\n                }\n                sorting_hasCmrInsurance\n            }\n            count\n        }\n    }\n"])), USER_COMPANY_MORE, COMPANY_DOCUMENT_BASE);
export var FetchLocationCompanies = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query loadLocationCompanies($filter: MainFilter) {\n      loadLocationCompanies(filter: $filter) {\n      data {\n          ", "\n          isNotEditable\n        place_id\n      }\n      count\n    }\n  }\n"], ["\n  query loadLocationCompanies($filter: MainFilter) {\n      loadLocationCompanies(filter: $filter) {\n      data {\n          ", "\n          isNotEditable\n        place_id\n      }\n      count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var FetchForwarderCompanies = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                forwarders{\n                    ", "\n                }\n                latestVisitAt\n                latestActionAt\n                latestAction\n            }\n            count\n        }\n    }\n"], ["\n    query companies($filter: MainFilter) {\n        companies(filter: $filter) {\n            data {\n                ", "\n                forwarders{\n                    ", "\n                }\n                latestVisitAt\n                latestActionAt\n                latestAction\n            }\n            count\n        }\n    }\n"])), USER_COMPANY_BASE, USER_WITH_COMPANY_BASE_I);
export var FetchCompaniesShort = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query companies($filter: MainFilter) {\n    companies(filter: $filter) {\n      data {\n        _id\n        name\n        vatCode\n        companyCode\n      }\n      count\n    }\n  }\n"], ["\n  query companies($filter: MainFilter) {\n    companies(filter: $filter) {\n      data {\n        _id\n        name\n        vatCode\n        companyCode\n      }\n      count\n    }\n  }\n"])));
export var CompaniesExport = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  query companiesExport($filter: MainFilter) {\n    companiesExport(filter: $filter) {\n      filePath\n    }\n  }\n"], ["\n  query companiesExport($filter: MainFilter) {\n    companiesExport(filter: $filter) {\n      filePath\n    }\n  }\n"])));
export var LoadCompaniesOrders = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  query loadCompaniesOrders($filter: MainFilter) {\n      loadCompaniesOrders(filter: $filter) {\n       data{\n           _id{\n               month\n               year\n           }\n           company{\n               ", "\n           }\n         \n           count\n       }\n          count\n    }\n  }\n"], ["\n  query loadCompaniesOrders($filter: MainFilter) {\n      loadCompaniesOrders(filter: $filter) {\n       data{\n           _id{\n               month\n               year\n           }\n           company{\n               ", "\n           }\n         \n           count\n       }\n          count\n    }\n  }\n"])), USER_COMPANY_BASE);
export var LocationGeoCode = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  query locationGeoCode($filter: MainFilter) {\n      locationGeoCode(filter: $filter) {\n        ", "\n    }\n  }\n"], ["\n  query locationGeoCode($filter: MainFilter) {\n      locationGeoCode(filter: $filter) {\n        ", "\n    }\n  }\n"])), LOCATION_ADDRESS);
export var LocationGeoCodes = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  query locationGeoCodes($filter: MainFilter) {\n      locationGeoCodes(filter: $filter) {\n        ", "\n    }\n  }\n"], ["\n  query locationGeoCodes($filter: MainFilter) {\n      locationGeoCodes(filter: $filter) {\n        ", "\n    }\n  }\n"])), LOCATION_ADDRESS);
export var GooglePlaces = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query googlePlaces($filter: MainFilter) {\n    googlePlaces(filter: $filter) {\n      data {\n        _id\n        place_id\n        name\n        createdAt\n        source\n      }\n      count\n    }\n  }\n"], ["\n  query googlePlaces($filter: MainFilter) {\n    googlePlaces(filter: $filter) {\n      data {\n        _id\n        place_id\n        name\n        createdAt\n        source\n      }\n      count\n    }\n  }\n"])));
export var JoinCompanyByVatCode = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation joinCompanyByVatCode($input: CompanyInputUpdate!) {\n    joinCompanyByVatCode(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation joinCompanyByVatCode($input: CompanyInputUpdate!) {\n    joinCompanyByVatCode(input: $input) {\n      ", "\n    }\n  }\n"])), USER_COMPANY_BASE);
export var CheckVatCodeExists = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  query checkVatCodeExists($filter: MainFilter) {\n    checkVatCodeExists(filter: $filter) {\n      ", "\n      isNotEditable\n    }\n  }\n"], ["\n  query checkVatCodeExists($filter: MainFilter) {\n    checkVatCodeExists(filter: $filter) {\n      ", "\n      isNotEditable\n    }\n  }\n"])), USER_COMPANY_BASE);
export var ApproveJoinCompany = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation approveJoinCompany($input: CompanyJoinInput!) {\n    approveJoinCompany(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation approveJoinCompany($input: CompanyJoinInput!) {\n    approveJoinCompany(input: $input) {\n      ", "\n    }\n  }\n"])), USER_COMPANY_BASE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
